import { generatePath, useNavigate } from 'react-router-dom';
import styles from './SignUpRiskScreening.module.scss';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import Loading from 'components/Loading/Loading';
import { useState, useEffect } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'Select/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import { notification } from 'antd';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import SelectTimer from 'Select/components/SelectTimer/SelectTimer';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectRiskScreeningForm from './components/RiskScreeningForm/RiskScreeningForm';
import { initialRiskScreeningValues } from './components/RiskScreeningForm/constants';
import { useSelectToken } from 'Select/utils/hooks/useSelectToken';
import { useRedirectLoginClientNextEngageStep } from 'Select/utils/hooks/useRedirectLoginClientNextEngageStep';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { patchClientFundingStream } from 'Select/utils/http/clinicianProfileService';
import { fetchClaimReservation } from 'Select/utils/api/fetchClaimReservation';

const SignUpRiskScreening = () => {
  const navigate = useNavigate();

  const { PRACTITIONER, SIGNUP } = useSelectRoutesGenerator();
  const { token, isInitialised } = useSelectToken();
  const { accountId, clinicianId, reserveId } = useReserveAppointmentData();
  const { redirectLoginClientNextEngageStep } = useRedirectLoginClientNextEngageStep();

  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!reserveId || !clinicianId) {
      navigate(PRACTITIONER.LISTING);

      if (clinicianId) {
        const detailsPagePath = generatePath(PRACTITIONER.DETAILS, { clinicianId });
        navigate(detailsPagePath);
      } else {
        navigate(PRACTITIONER.LISTING);
      }
    } else if (isInitialised && !token) {
      navigate(SIGNUP.BASE);
    }
  }, [PRACTITIONER.DETAILS, PRACTITIONER.LISTING, SIGNUP.BASE, clinicianId, isInitialised, navigate, reserveId, token]);

  const onSubmitHandle = async (values: typeof initialRiskScreeningValues) => {
    if (!token) {
      console.error('Token is missing');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await patchClientFundingStream(token, values);

      if (response.statusCode === 204) {
        await fetchClaimReservation({
          accountId: accountId!,
          reserveId: reserveId!,
          authToken: token,
          shouldGenerateCheckoutSession: false
        });
        redirectLoginClientNextEngageStep({
          skipRiskScreeningCheck: true,
          noLoginRedirectPath: SIGNUP.CONFIRM_BOOKING
        });
      } else {
        const responseText = await response.text();
        console.error(`Error when saving risk screening for Select: ${responseText}`);
        notification.error({
          message: 'Something went wrong while trying to save your answers. Please try again.'
        });
      }

      setIsSubmitting(false);
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to save your answers.'
      });
    }
  };

  return fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <SelectHelmetWrapper title={'Select Psychology | Risk Screening'}>
      <SelectContentLayout className="select-theme">
        <SelectHeader className={styles.header} withPadding withMenu />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <SelectRiskScreeningForm isSubmitting={isSubmitting} onSubmit={onSubmitHandle} />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.notice}>
              <div className={styles.noticeMsg}>
                <div>To confirm these appointments you need to complete registration and payment within 15 minutes</div>
              </div>
              <SelectTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
            </div>
          </div>
        </div>
      </SelectContentLayout>
      <TacklitFooter darkFooter />
    </SelectHelmetWrapper>
  );
};

export default SignUpRiskScreening;
