import { useAuth0 } from '@auth0/auth0-react';

import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';

import styles from './ConsentHeader.module.scss';

const ConsentHeader = () => {
  const { user } = useAuth0();
  const { initialsName } = useFetchAvatar();

  return (
    <div className={styles.container} id={'header'}>
      <div className={styles.top}>
        <div className={styles.profilePic}>
          <ClientProfileAvatar
            avatarUrl={user?.picture}
            initialsName={initialsName}
            initialClassName={styles.initial}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsentHeader;
