import { DatePicker } from 'antd';
import classnames from 'classnames';
import moment, { Moment } from 'moment';

import './FormikDatePicker.scss';
import styles from './DateSelector.module.scss';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useEffect } from 'react';
import { formatInputDatePicker } from 'utils/date';

const DATE_FORMAT = 'DD/MM/YYYY';

interface DateSelectorProps {
  id: string;
  className?: string;
  name: string;
  inputClass?: string;
  labelClass?: string;
  label?: string;
  error?: string;
  onChange: any;
  value: any;
  placeholder?: string;
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined;
  format?: string;
  inputReadOnly?: boolean;
  disabled?: boolean;
}

const DateSelector = ({
  id,
  className,
  label,
  name,
  error,
  onChange,
  value,
  placeholder,
  inputClass,
  labelClass,
  picker,
  format,
  inputReadOnly = true,
  disabled
}: DateSelectorProps) => {
  const handleOnChange = (e: Moment | null) => {
    onChange(e ? moment(e).format(format || DATE_FORMAT) : '');
  };

  const handleKeyUp = (event: KeyboardEvent | any) => {
    const value = (event.target as HTMLInputElement).value;
    const formattedValue = formatInputDatePicker(value, event);
    (event.target as HTMLInputElement).value = formattedValue;
  };

  useEffect(() => {
    const antdPickerElem: HTMLCollectionOf<Element> = document.getElementsByClassName('ant-picker-input');
    const antdPickerElemList = Array.from(antdPickerElem);
    if (antdPickerElem && !inputReadOnly) {
      antdPickerElemList?.forEach((el) => {
        const antdInput = el.getElementsByTagName('input');
        const input = antdInput[0];
        input.addEventListener('keyup', handleKeyUp);
        input.maxLength = 10;
      });
    }
    return () => {
      if (antdPickerElem && !inputReadOnly) {
        antdPickerElemList?.forEach((el) => {
          const antdInput = el.getElementsByTagName('input');
          antdInput[0].removeEventListener('keyup', handleKeyUp);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classnames(styles.container, className)}>
      <DatePicker
        picker={picker}
        id={id}
        name={name}
        inputReadOnly={inputReadOnly}
        className={classnames(inputClass, error && 'error', IS_SOMEONE_HEALTH_APP && 'someone-health-theme')}
        placeholder={placeholder || ''}
        value={value && moment(value, format || DATE_FORMAT)}
        format={format || DATE_FORMAT}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {label && <div className={classnames(labelClass, styles.label)}>{label}</div>}
    </div>
  );
};

export default DateSelector;
