import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { parseAUGooglePlaceAddress, parseUKGooglePlaceAddress } from './useAddressSuggestionHelper';

export interface Address {
  description: string;
  details: {
    line1: string;
    line2: string;
    state: string;
    suburb: string;
    postcode: string;
    country: string;
  };
}

export const useAddressSuggestion = (keyword: string, region: string = 'au') => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    debounce: 500,
    options: {
      types: ['address'],
      input: keyword,
      componentRestrictions: {
        country: region
      }
    }
  });

  useEffect(() => {
    if (keyword) {
      getPlacePredictions({ input: keyword });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    // Get details for the address
    const getAddressDetails = async () => {
      try {
        const addresses = await Promise.all<google.maps.places.PlaceResult | null>(
          placePredictions.map((prediction) => {
            return new Promise((resolve) => {
              placesService?.getDetails(
                {
                  placeId: prediction.place_id
                },
                (placeDetails) => {
                  resolve(placeDetails);
                }
              );
            });
          })
        );

        if (!addresses.length) {
          return;
        }

        setAddresses(
          addresses
            .filter((address) => address !== null)
            .map(process.env.REACT_APP_GAE_REGION === 'gb' ? parseUKGooglePlaceAddress : parseAUGooglePlaceAddress)
        );
      } catch (exception) {
        console.error(exception);
      }
    };

    if (placePredictions.length) {
      getAddressDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions]);

  return {
    addresses: addresses,
    isLoading: isPlacePredictionsLoading
  };
};
