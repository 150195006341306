import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { consentFormInterface, getConsentFormDetailsInterface } from 'interfaces/ConsentForm/consentForm';
import { getConsentFormFacilitated } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

export const useFetchConsentOnboarding = (token: string) => {
  const navigate = useNavigate();
  const { FACILITATED } = useRoutesGenerator();
  const [consentFormData, setConsentFormData] = useState({} as consentFormInterface);
  const [isConsentFormDataLoading, setIsConsentFormDataLoading] = useState(true);

  const fetchConsentDetails = async (token: string) => {
    try {
      setIsConsentFormDataLoading(true);

      const res = await getConsentFormFacilitated(token);

      if (res.statusCode === 200) {
        const consentData = (await res.json()) as getConsentFormDetailsInterface;

        const massageData = {
          _id: consentData._id,
          body: consentData.body,
          name: consentData.name,
          clientName: consentData.clientName,
          signature: {
            ...(consentData.signatureRequired.client && {
              client: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            }),
            ...(consentData.signatureRequired.other && {
              other: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            })
          },
          practice: consentData.practice,
          consentFormAssignmentId: consentData.consentFormAssignmentId
        };

        setConsentFormData(massageData as consentFormInterface);
      } else {
        navigate(`${FACILITATED.ONBOARDING}?token=${token}`);
      }
    } catch (ex) {
      notification.error({ message: 'Something went wrong while trying to get consent form details' });
    }
    setIsConsentFormDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentDetails(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { consentFormData, isConsentFormDataLoading };
};
