import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'utils/UserContextProvider';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useSelectRoutesGenerator } from '../path/SelectRoutesGenerator';

export const useRedirectLoginClientNextEngageStep = () => {
  const navigate = useNavigate();

  const { clientProfile } = useContext(UserContext);
  const { isClientProfileLoading } = useGetClientProfileDetails();

  const { SIGNUP } = useSelectRoutesGenerator();

  const redirectLoginClientNextEngageStep = useCallback(
    ({
      noLoginRedirectPath,
      skipRiskScreeningCheck
    }: {
      noLoginRedirectPath?: string;
      skipRiskScreeningCheck?: boolean;
    }) => {
      // if client is logged in, check if should redirect to emergency contact path
      if (clientProfile) {
        if (!skipRiskScreeningCheck && !clientProfile.isRiskScreeningComplete) {
          navigate(SIGNUP.RISK_SCREENING);
          return;
        }
      }

      // only called if:
      // 1. client is logged in, and has the requisite details
      // 2. client is not logged in, and there is no noLoginRedirectPath (last page before saved card only)
      if (clientProfile || !noLoginRedirectPath) {
        navigate(SIGNUP.CONFIRM_BOOKING);
        return;
      }

      // else if there's noLoginRedirectPath, redirect to that instead
      if (noLoginRedirectPath) {
        navigate(noLoginRedirectPath);
        return;
      }
    },
    [SIGNUP.CONFIRM_BOOKING, SIGNUP.RISK_SCREENING, clientProfile, navigate]
  );

  return { isPreparingRedirect: isClientProfileLoading, redirectLoginClientNextEngageStep };
};
