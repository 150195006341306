import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetchInvitedClinicianDetails } from 'pages/SignUp/hooks/getInvitedClinicianDetails';
import Loading from 'components/Loading/Loading';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import Header from 'layouts/NoAuthLayout/components/Header/Header';
import TaskBox from 'components/TaskBox/TaskBox';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

import styles from './WelcomeTaskList.module.scss';
import { useTranslation } from 'react-i18next';

const WelcomeTaskList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianSlugUrlOrId = '' } = useParams() as { clinicianSlugUrlOrId: string };
  const { SIGNUP } = useRoutesGenerator(clinicianSlugUrlOrId);
  const { invitedClinicianDetail, isInvitedCLoading } = useFetchInvitedClinicianDetails(clinicianSlugUrlOrId);
  const [t] = useTranslation();

  const handleNextPath = () => {
    navigate(`${SIGNUP.POLICY}${location.search}`);
  };

  return isInvitedCLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <ContentLayout className={styles.body}>
        <div className={styles.headerContainer}>
          <Header whiteLogo logoClassName={styles.logo} />
        </div>
        <div className={styles.content}>
          <div>
            <div className={styles.title}>Onwards.</div>
            <div className={styles.desc}>
              <strong>{invitedClinicianDetail.practice?.name || invitedClinicianDetail.name}</strong> has asked you to
              please review and complete the following <strong>3 actions</strong>.
            </div>
          </div>
          <div className={styles.taskBox}>
            <div className={styles.labelTitle}>Firstly,</div>
            <TaskBox title={'Set up your free account'} desc={'3 minutes'} onClick={handleNextPath} />
          </div>
          <div className={styles.taskBox}>
            <div className={styles.labelTitle}>Then you can,</div>
            <TaskBox title={'Initial consultation background'} desc={'4 questions'} />
            <TaskBox title={t('label.esign_client_consent_form')} desc={'Read and agree'} />
          </div>
        </div>
      </ContentLayout>
    </div>
  );
};

export default WelcomeTaskList;
