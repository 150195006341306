import FlexBox from 'components/FlexBox/FlexBox';

import styles from '../../InvoiceViewWithTemplate.module.scss';
import { Invoice, PAYMENT_TYPE_LABELS } from 'interfaces/Invoice/Invoice';
import PaymentMethodDetail from '../PaymentMethodDetail/PaymentMethodDetail';

const PaymentDetailsSection = ({ invoice, isStripeActive }: { invoice: Invoice; isStripeActive: boolean }) => {
  return (
    <FlexBox direction="column" spacing={4} className={styles.wrapper}>
      {invoice.paymentMethods.length > 1 && (
        <div className={styles.title}>{isStripeActive ? 'Other ways to pay' : 'Payment via'}</div>
      )}
      <FlexBox direction="row" spacing={20} className={styles.flexWrap}>
        {invoice.paymentMethods
          .filter(({ paymentType }) => paymentType !== 'stripe')
          .map((paymentMethod, index) => (
            <div className={styles.width350} key={index}>
              <span className={styles.paymentMethodTitle}>{PAYMENT_TYPE_LABELS[paymentMethod.paymentType]}</span>
              <PaymentMethodDetail paymentMethod={paymentMethod} />
            </div>
          ))}
      </FlexBox>
    </FlexBox>
  );
};

export default PaymentDetailsSection;
