import { useAuth0 } from '@auth0/auth0-react';
import styles from './ReferralDetail.module.scss';
import ReferralDetailForm from 'helm/pages/ReferralDetail/components/ReferralDetailForm/ReferralDetailForm';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

const ReferralDetail = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <SomeoneHealthHelmetWrapper title="someone.health - Referral">
      <div className={styles.container}>
        <SomeoneHealthHeader withPadding withMenu homeLink />
        <SomeoneHealthContentLayout>
          <div className={styles.title}>Add Referral Details</div>
          <div className={styles.content}>
            <div className={styles.description}>
              Complete and share details of your referral with someone.health. This is required for any Medicare claims.
            </div>
            <ReferralDetailForm />
          </div>
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default ReferralDetail;
