import * as yup from 'yup';
import { SecurityErrorInterface } from '../../../SignUpFormInterface';
import { postCheckEmailInvite } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { notification } from 'antd';

const securityFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter your email')
    .email(
      'Something in this email looks invalid. Please ensure there are no spaces and it includes the @domain.com format.'
    )
    .matches(
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
      'Something in this email looks invalid. Please ensure there are no spaces and it includes the @domain.com format.'
    ),
  password: yup.string().when('passwordScore', {
    is: (value: number) => value === 4,
    then: yup
      .string()
      .required('Please choose a password')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,}/g,
        'Password must include uppercase letters, lowercase letters, and numbers'
      )
      .matches(/.*[!@#$%^&*-]+.*/g, 'Password must include special characters (!@#$%^&*-)'),
    otherwise: yup.string().test({
      name: 'passwordScoreBelow4Test',
      test: () => false,
      message: 'Password must be strong'
    })
  })
});

export const validationSecurityForm = (securityFormVal: SecurityErrorInterface) => {
  const validationErrors = {
    email: '',
    password: ''
  } as SecurityErrorInterface;
  try {
    securityFormSchema.validateSync(securityFormVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.map((error: any) => {
        if (error.path) {
          // @ts-ignore
          validationErrors[error.path] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};

interface emailValidate {
  used: boolean;
  invited: boolean;
}

export const validateEmailInvite = async (accountId: string, email: string) => {
  try {
    const body = {
      email
    };
    const res = (await (await postCheckEmailInvite(accountId, body)).json()) as emailValidate;
    return {
      invited: res.invited,
      used: res.used
    } as emailValidate;
  } catch (ex) {
    console.error(ex);
    notification.error({
      message: 'Check invite email fail',
      duration: 2,
      closeIcon: <span className="success">OK</span>
    });
  }
};
