import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/Loading/Loading';

import styles from './SignUpWrapper.module.scss';

interface SignUpWrapperProps {
  children?: any;
}

const SignUpWrapper = ({ children }: SignUpWrapperProps) => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  if (!isLoading && isAuthenticated) {
    navigate('/');
  }

  return isLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>{children}</div>
  );
};

export default SignUpWrapper;
