import styles from './PractitionerDetails.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useFetchPractitionerDetails } from './hooks/getPractitionerDetails';
import HelmFooter from 'helm/components/HelmFooter/HelmFooter';
import PractitionerTimeSlot from './components/PractitionerTimeSlot/PractitionerTimeSlot';
import PractitionerFAQ from './components/PractitionerFAQ/PractitionerFAQ';
import { useParams } from 'react-router-dom';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { helmFAQ } from './PractitionerDetails';

const CounsellorCalendarDetails = () => {
  const { clinicianId } = useParams<{ clinicianId: string }>();

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;

  return isPractitionerDetailsLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <HelmHelmetWrapper title={`HELM - ${practitionerName} Calendar`}>
      <PractitionerTimeSlot helmDetails={practitionerDetails} />
      <PractitionerFAQ data={helmFAQ} id={'HelmFAQ'} />
      <HelmFooter />
    </HelmHelmetWrapper>
  );
};

export default CounsellorCalendarDetails;
