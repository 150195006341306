import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './SignUp.module.scss';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { UserSessionStorage, useCawUserSession } from 'CaW/pages/SignUp/helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode, useEffect } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'CaW/utils/hooks/appointment';
import { cawTimeZoneLocalStorage } from '../ClinicianListing/ClinicianListing';
import { getAuMobile, getInitialCustomer } from 'CaW/pages/SignUp/components/CaWCreateProfileForm/constants';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import CaWTimer from 'CaW/components/CaWTimer/CaWTimer';
import { fetchClaimingReservation } from 'CaW/utils/hooks/useClaimReservationWithoutCheckoutSession';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWContentLayout from '../../components/CaWContentLayout/CaWContentLayout';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import CaWCreateProfileForm from './components/CaWCreateProfileForm/CaWCreateProfileForm';
import { EmployerCode, useGetEmployerCodesQuery } from 'redux/endpoints/clinicianProfileServices/employerCode';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import queryString from 'query-string';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';

const SignUp = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const { emp_code } = queryString.parse(location.search) as { emp_code?: string };
  const clientTimeZone = localStorage.getItem(cawTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;

  const { SIGNUP, HOME, PRACTITIONER } = useCaWRoutesGenerator();
  const { CaWHomeURL } = cawEnvironment();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo, presetEmployerCode } = useReserveAppointmentData();
  const { storeUserSession } = useCawUserSession();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  const { data: employerCodes, isLoading: isEmployerCodesLoading } = useGetEmployerCodesQuery(null);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const { data: practiceInfoData } = useGetPracticeInfoQuery({ slugUrlOrAccountId: accountId || 'caw' });

  // flow 2: user come from caw website => booking flow, but already signup
  //   - check client record already exist
  //   - this should be before redirect to this page

  const [isSignupWithoutReservation, setIsSignupWithoutReservation] = useState(false);

  useEffect(() => {
    if ((!reserveId || (!fetching && !appointments.length)) && !isEmployerCodesLoading) {
      if (emp_code && employerCodes) {
        const foundEmployerCode = employerCodes.find((obj) => obj.code === emp_code.toUpperCase());

        if (foundEmployerCode?.allowSignupWithoutReservation) {
          setIsSignupWithoutReservation(true);
          return;
        }
      }

      if (isDevelopmentENV()) {
        navigate(PRACTITIONER.LISTING);
      } else {
        window.location.href = `${CaWHomeURL}counsellor`;
      }
    }
  }, [
    CaWHomeURL,
    PRACTITIONER.LISTING,
    appointments.length,
    emp_code,
    employerCodes,
    fetching,
    isEmployerCodesLoading,
    navigate,
    reserveId
  ]);

  if (isAuthenticated) {
    navigate(HOME);
  }

  const proceedToNextStep = (value: UserSessionStorage, dob?: string, employerCode?: EmployerCode) => {
    storeUserSession(value, dob);
    scrollToView('CaWHeader');

    if (employerCode?.noReservationAssessmentFormId && !reserveId) {
      navigate(SIGNUP.ASSESSMENT, { state: { formId: employerCode.noReservationAssessmentFormId } });
    } else {
      navigate(SIGNUP.EMERGENCY_CONTACT);
    }
  };

  const onSubmitHandle = async (values: ReturnType<typeof getInitialCustomer>) => {
    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: practiceInfoData?.accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const {
            email,
            firstName,
            lastName,
            password,
            receiveSMS,
            postcode,
            employerCode: formEmployerCode,
            issueSource,
            directorate,
            department,
            serviceFor,
            serviceForOther,
            initialPresentingIssue
          } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
          const communicationPreferenceLogic = receiveSMS
            ? CommunicationPreference.NoPreference
            : CommunicationPreference.Email;

          const employerCode = (emp_code || presetEmployerCode)?.toUpperCase() || formEmployerCode;
          const foundEmployerCode = employerCodes?.find((obj) => obj.code === employerCode);

          const payloadMassage = {
            clinicianId: clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode,
              employerCode,
              issueSource,
              directorate,
              department,
              serviceFor,
              serviceForOther,
              initialPresentingIssue,
              timeZone: clientTimeZone || AU_TIME_ZONE_LIST[0].id,
              communicationPreference: communicationPreferenceLogic,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
              tag: foundEmployerCode?.name
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(
            practiceInfoData?.accountId!,
            payloadMassage
          );

          const { clientRecord, authToken } = await callPatientSignUp.json();
          await fetchClaimingReservation(reserveId!, practiceInfoData?.accountId!, authToken);
          setIsSubmitting(false);
          proceedToNextStep({ clientRecord, authToken }, dob, foundEmployerCode);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(<div>Email address is already used.</div>);
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(<div>Mobile number not recognized. Please check for typos and try again.</div>);
    }
  };

  return isLoading || (!isSignupWithoutReservation && fetching) || isEmployerCodesLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <CaWHelmetWrapper title={'Caraniche at Work - Sign Up'}>
      <CaWContentLayout>
        <CaWHeader withPadding classNames={styles.header} blueMenu />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <CaWCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
              employerCodes={employerCodes?.map((item) => ({ value: item.code, label: item.code })) || []}
              presetEmployerCode={emp_code || presetEmployerCode}
            />
          </div>
          {!isSignupWithoutReservation && (
            <div className={styles.rightContent}>
              <div className={styles.timerContainer}>
                <div className={styles.timerLabel}>
                  To confirm these appointments you need to complete registration and payment within 15 minutes
                </div>
                <CaWTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
              </div>
            </div>
          )}
        </div>
      </CaWContentLayout>
      <TacklitFooter />
    </CaWHelmetWrapper>
  );
};

export default SignUp;
