import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

export const PARTNER_CODE = [
  ...(isDevelopmentENV()
    ? [
        {
          accessToken: '686868',
          partnerCode: 'VTPHNA'
        }
      ]
    : []),
  {
    accessToken: 'TV46HN',
    partnerCode: 'VTPHNA'
  }
];
