import styles from './NoAvailableMessageBox.module.scss';
import classnames from 'classnames';
import ButtonRecharge from '../ButtonRecharge/ButtonRecharge';
import RechargeContentLayout from '../RechargeContentLayout/RechargeContentLayout';

interface NoAvailableMessageBoxProps {
  title: string;
  desc: string | JSX.Element;
  buttonLabel: string;
  onClick: () => void;
  titleClassName?: string;
}

const NoAvailableMessageBox = ({ title, desc, buttonLabel, onClick, titleClassName }: NoAvailableMessageBoxProps) => (
  <RechargeContentLayout>
    <div className={styles.container}>
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.buttonWrapper}>
        <ButtonRecharge onClick={onClick}>{buttonLabel}</ButtonRecharge>
      </div>
    </div>
  </RechargeContentLayout>
);

export default NoAvailableMessageBox;
