import styles from './SelectFooter.module.scss';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import {
  SelectMatchingWizardUrl,
  SelectSocialMediaUrls,
  SelectSupportEmail,
  SelectSupportMobile,
  SelectUrl
} from 'Select/utils/env/SelectEnv';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import SelectLogo from 'Select/assets/images/logo/logo.png';
import FacebookLogo from 'assets/icons/Facebook';
import InstagramLogo from 'assets/icons/Instagram';
import LinkedInLogo from 'assets/icons/LinkedIn';

const SelectFooter = () => {
  return (
    <>
      <div className={styles.footerContainer}>
        <SelectContentLayout className={styles.container}>
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <a href={SelectUrl}>
                <img src={SelectLogo} className={styles.logo} alt="Select link" />
              </a>
              <div className={styles.text}>
                Select Psychology is a member of the Professional Practice of Charted Clinical Psychologists offering
                face-to-face and online talking therapies to help people to find a way forward with emotional and
                psychological difficulties.
              </div>
              <div className={styles.socialMedia}>
                <a href={SelectSocialMediaUrls.facebook}>
                  <FacebookLogo />
                </a>
                <a href={SelectSocialMediaUrls.instagram}>
                  <InstagramLogo />
                </a>
                <a href={SelectSocialMediaUrls.linkedIn}>
                  <LinkedInLogo />
                </a>
              </div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.links}>
                <a href={`${SelectUrl}our-talking-therapy-services`}>Our Services</a>
                <a href={`${SelectUrl}about`}>About Us</a>
                <a href={SelectMatchingWizardUrl}>Book an Appointment</a>
                <a href={`${SelectUrl}about/team/`}>Practitioner Directory</a>
              </div>
              <div className={styles.contactUs}>
                <div className={styles.heading}>Contact Us</div>
                <a href={`tel:${SelectSupportMobile}`}>
                  <i className={`material-icons-outlined ${styles.icon}`}>call</i>
                  <span className={styles.text}>{SelectSupportMobile}</span>
                </a>
                <a href={`mailto:${SelectSupportEmail}`}>
                  <i className={`material-icons-outlined ${styles.icon}`}>mail</i>
                  <span className={styles.text}>{SelectSupportEmail}</span>
                </a>
                <a href={`${SelectUrl}clinic-locations/`}>
                  <i className={`material-icons-outlined ${styles.icon}`}>location_on</i>
                  <span className={styles.text}>1 TYNEMOUTH ROAD, TYNEMOUTH, TYNE & WEAR, NE30 4AY</span>
                </a>
              </div>
              <div className={styles.socialMedia}>
                <a href={SelectSocialMediaUrls.facebook}>
                  <FacebookLogo />
                </a>
                <a href={SelectSocialMediaUrls.instagram}>
                  <InstagramLogo />
                </a>
                <a href={SelectSocialMediaUrls.linkedIn}>
                  <LinkedInLogo />
                </a>
              </div>
            </div>
          </div>
        </SelectContentLayout>
      </div>
      <TacklitFooter darkFooter />
    </>
  );
};

export default SelectFooter;
