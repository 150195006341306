import styles from './PsychologistSpecialisation.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface PsychologistSpecialisationProps {
  label: string;
  specialisationsDetails: string[];
  limit?: number;
  detailPath?: string;
  selectedValue?: string[];
  vertical?: boolean;
  badgeClassName?: string;
  matchedSpecialisations?: string[];
}

const PsychologistSpecialisation = ({
  label,
  specialisationsDetails,
  limit,
  detailPath,
  selectedValue,
  vertical,
  badgeClassName,
  matchedSpecialisations
}: PsychologistSpecialisationProps) => {
  const formattedList = specialisationsDetails.sort((a) => (selectedValue?.includes(a) ? -1 : 1));
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div
        className={classNames(vertical ? styles.specialisationBadgeVerticalWrapper : styles.specialisationBadgeWrapper)}
      >
        {limit
          ? formattedList.map((obj, index) => (
              <div key={index}>
                {index < limit ? (
                  <div
                    key={index}
                    className={classNames(
                      selectedValue?.includes(obj) || matchedSpecialisations?.includes(obj)
                        ? styles.specialisationBadgeSelected
                        : styles.specialisationBadge,
                      badgeClassName
                    )}
                  >
                    {obj}
                  </div>
                ) : index === limit ? (
                  <Link key={index} to={detailPath || ''} className={styles.seeMoreSlot}>
                    ...+{formattedList.length - limit} MORE
                  </Link>
                ) : null}
              </div>
            ))
          : formattedList.map((obj, index) => (
              <div
                key={index}
                className={classNames(
                  selectedValue?.includes(obj) ? styles.specialisationBadgeSelected : styles.specialisationBadge,
                  badgeClassName
                )}
              >
                {obj}
              </div>
            ))}
      </div>
    </div>
  );
};

export default PsychologistSpecialisation;
