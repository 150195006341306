import { Signature } from 'interfaces/signature';

export enum SmpStatus {
  draft = 'draft',
  submitted = 'submitted'
}

export enum SMPType {
  G24 = 'g24',
  IP6 = 'ip6'
}

export type GoalNumber = 1 | 2 | 3;

export interface SMPGoal {
  description: string;
  steps: string[];
}

export interface SMPMethods {
  callSupport: string;
  drawStrength: string;
  helpfulThought: string;
  unhelpfulThought: string;
  difficultEmotion: string;
  emotionManagement: string;
}

export interface SMPRelapsePreventions {
  warningSign: string;
  cravingManagement: string;
  reductionStrategy: string;
}

export interface SMPForm {
  goals: { goal1: SMPGoal; goal2: SMPGoal; goal3: SMPGoal };
  methods: SMPMethods;
  relapsePreventions: SMPRelapsePreventions;
}

export interface SMP {
  _id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  submittedAt?: string;
  submittedBy?: string;
  signature?: Signature;
  ownerAuth0Id: string;
  status: SmpStatus;
  type: SMPType;
  form: SMPForm;
  attachment: { _id: string; title: string };
}

export type SMPFormInputs = Pick<SMP, 'form' | 'signature'>;

export enum SMPUpdateAction {
  save = 'save',
  submit = 'submit'
}

export type SMPUpdatePayload = { form: SMPForm; action: SMPUpdateAction; printPayload?: Node };
