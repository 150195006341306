import { Helmet, HelmetProvider } from 'react-helmet-async';

interface CaWHelmetWrapperProps {
  title?: string;
  children?: any;
  hubspot?: boolean;
}

const faviconUrl = '/favCaW.png';

const CaWHelmetWrapper = ({ children, title }: CaWHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'We help Australian organisations create thriving cultures of wellness and productivity. Call us on 03 8417 0501 to discuss your needs today.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default CaWHelmetWrapper;
