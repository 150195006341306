import { useAuth0 } from '@auth0/auth0-react';

import styles from './SurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

const SurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <SelectContentLayout className={`${styles.container} select-theme`}>
      <div className={styles.headerWrapper}>
        <SelectHeader withPadding withMenu homeLink whiteFont />
      </div>
      <div className={styles.contentWrapper}>
        <SurveyList />
      </div>
    </SelectContentLayout>
  );
};

export default SurveyListWrapper;
