import styles from './PsychologistExtraService.module.scss';

interface PsychologistExtraServiceProps {
  label: string;
  icon: string;
  servicesAvailable: string[];
}

const PsychologistExtraService = ({ label, icon, servicesAvailable }: PsychologistExtraServiceProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.serviceDetails}>
        {servicesAvailable?.map((item, index) => (
          <div key={index} className={styles.details}>
            <i className={`material-icons ${styles.icon}`}>{icon}</i>
            <div>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PsychologistExtraService;
