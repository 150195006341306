import { ChangeEvent, KeyboardEvent } from 'react';
import styles from './ContactForm.module.scss';
import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import {
  IS_EASE_APP,
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import HelmMobileMaterialInput from 'helm/components/Input/HelmMobileMaterialInput';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import {
  HELM_REFERRAL_SOURCE_OPTIONS,
  INFORMATION_OPTIONS,
  REFERRAL_RECORD_TYPE,
  REFERRAL_SOURCE_OPTIONS
} from 'components/ReferralForm/constants';
import HelpOutLineWithTooltips from 'components/HelpOutlineTooltip/HelpOutlineTooltip';
import classNames from 'classnames';
import { convertDateFormat } from 'pages/SignUp/SignUpForm/components/BasicDetails/actions/dateChecker';
import Radio from 'components/Radio/Radio';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import EaseMobileMaterialInput from 'Ease/components/EaseMobileFormikInput/EaseMobileMaterialInput';
import SelectMobileMaterialInput from 'Select/components/SelectMobileFormikInput/SelectMobileMaterialInput';

interface InformationFormProps {
  formValues: any;
  setFormValues: any;
  errorMessage: any;
  setInvalidMobileNumber?: (val: boolean) => void;
  invalidMobileNumber?: boolean;
  referralDetailOption?: any;
  resetErrors?: (key: string) => void;
}

const isDevEnv = isDevelopmentENV();

const REFERRAL_OPTIONS = IS_HELM_APP ? HELM_REFERRAL_SOURCE_OPTIONS : REFERRAL_SOURCE_OPTIONS;

const ContactForm = ({
  formValues,
  setFormValues,
  errorMessage,
  setInvalidMobileNumber,
  invalidMobileNumber,
  referralDetailOption,
  resetErrors
}: InformationFormProps) => {
  const handleDateChange = (dateValue: string, id: 'referralDoB' | 'dateOfBirth') => {
    const newDateFormat = convertDateFormat(dateValue);
    const newFormValues = {
      ...formValues,
      [id]: newDateFormat
    };
    setFormValues(newFormValues);
  };

  const handleKeyDownDateChange = (event: KeyboardEvent<HTMLInputElement>, id: 'referralDoB' | 'dateOfBirth') => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = formValues.referralDoB?.slice(0, formValues.referralDoB.length - 1);
      const newFormValues = {
        ...formValues,
        [id]: newValue
      };
      setFormValues(newFormValues);
      resetErrors && resetErrors(id);
    }
  };

  const handleToggleYourInformation = (name: string) => {
    if (name === 'guardianInformation') {
      setFormValues((prevFormValues: any) => {
        return {
          ...prevFormValues,
          isUseGuardianInformation: name,
          firstName: prevFormValues.guardianFirstName,
          lastName: prevFormValues.guardianLastName,
          emailAddress: prevFormValues.guardianEmail,
          contactNumber: prevFormValues.guardianContactNumber
        };
      });
    } else {
      setFormValues({ ...formValues, isUseGuardianInformation: name });
    }
    resetErrors && resetErrors(name);
  };

  return (
    <div>
      <div className={styles.title}>Your information</div>
      {!formValues.isForMySelf && ['child', 'youngPerson'].includes(formValues.referralRecordType) && (
        <div className={classNames(styles.fieldContainer, styles.useGuardianInformation)}>
          <Radio
            vertical={IS_SOMEONE_HEALTH_APP}
            name={'useGuardianInformation'}
            options={INFORMATION_OPTIONS}
            value={formValues.isUseGuardianInformation}
            radioWrapperClass={styles.radioWrapperClass}
            radioLabelClass={styles.radioLabelClass}
            onChange={(event) => {
              handleToggleYourInformation(event.target.value);
            }}
          />
        </div>
      )}
      {formValues.isUseGuardianInformation === 'otherInformation' && (
        <div>
          {formValues.isForMySelf && (
            <div className={styles.fieldContainer}>
              <MaterialSelect
                id={'referralRecordType'}
                label="I am seeking support as a*"
                isSearchable={false}
                optionList={REFERRAL_RECORD_TYPE}
                value={formValues.referralRecordType}
                onChange={(event: string) => {
                  setFormValues({ ...formValues, referralRecordType: event });
                }}
                isError={!!errorMessage?.referralRecordType}
              />
              {errorMessage?.referralRecordType && (
                <div className={styles.fieldError}>{errorMessage?.referralRecordType}</div>
              )}
            </div>
          )}
          <div className={classnames(styles.fieldContainer, errorMessage?.firstName && styles.fieldError)}>
            <MaterialInput
              label={'First Name*'}
              id={'firstName'}
              value={formValues.firstName}
              onChange={(event) => {
                setFormValues({ ...formValues, firstName: event.target.value });
              }}
              maxLength={200}
              required
            />
            {errorMessage?.firstName && <div className={styles.fieldError}>{errorMessage?.firstName}</div>}
          </div>

          <div className={classnames(styles.fieldContainer, errorMessage?.lastName && styles.fieldError)}>
            <MaterialInput
              label={'Last Name*'}
              id={'lastName'}
              value={formValues.lastName}
              onChange={(event) => {
                setFormValues({ ...formValues, lastName: event.target.value });
              }}
              maxLength={200}
              required
            />
            {errorMessage?.lastName && <div className={styles.fieldError}>{errorMessage?.lastName}</div>}
          </div>

          <div className={classnames(styles.fieldContainer, errorMessage?.emailAddress && styles.fieldError)}>
            <MaterialInput
              label={'Email Address*'}
              id={'emailAddress'}
              value={formValues.emailAddress}
              onChange={(event) => {
                setFormValues({ ...formValues, emailAddress: event.target.value });
                resetErrors && resetErrors('emailAddress');
              }}
              maxLength={200}
              required
            />
            {errorMessage?.emailAddress && <div className={styles.fieldError}>{errorMessage?.emailAddress}</div>}
          </div>
          <div className={styles.phoneContainer}>
            {IS_HELM_APP || IS_SOMEONE_HEALTH_APP || IS_RECHARGE_APP ? (
              <HelmMobileMaterialInput
                inputClass={styles.phoneInput}
                id={`contactNumber`}
                name={'contactNumber'}
                label={'Contact number*'}
                mobilePattern={isDevEnv ? '+__ __-____-____' : '____ ___ ___'}
                placeholder={IS_HELM_APP ? '0482 666 666' : '0482 555 444'}
                phonePrefix={'07'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({ ...formValues, contactNumber: e.target.value });
                  setInvalidMobileNumber && setInvalidMobileNumber(false);
                  resetErrors && resetErrors('contactNumber');
                }}
                error={errorMessage?.contactNumber}
                isMobileValid={!invalidMobileNumber}
                required
              />
            ) : IS_EASE_APP ? (
              <EaseMobileMaterialInput
                inputClass={styles.phoneInput}
                id={`contactNumber`}
                name={'contactNumber'}
                label={'Contact number*'}
                mobilePattern={isDevEnv ? '+__ __-____-____' : '_____ ___ ___'}
                placeholder={'07712 555 444'}
                phonePrefix={'07'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({ ...formValues, contactNumber: e.target.value });
                  setInvalidMobileNumber && setInvalidMobileNumber(false);
                  resetErrors && resetErrors('contactNumber');
                }}
                error={errorMessage?.contactNumber}
                isMobileValid={!invalidMobileNumber}
                required
              />
            ) : IS_SELECT_APP ? (
              <SelectMobileMaterialInput
                inputClass={styles.phoneInput}
                id={`contactNumber`}
                name={'contactNumber'}
                label={'Contact number*'}
                mobilePattern={isDevEnv ? '+__ __-____-____' : '_____ ___ ___'}
                placeholder={'07712 555 444'}
                phonePrefix={'07'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({ ...formValues, contactNumber: e.target.value });
                  setInvalidMobileNumber && setInvalidMobileNumber(false);
                  resetErrors && resetErrors('contactNumber');
                }}
                error={errorMessage?.contactNumber}
                isMobileValid={!invalidMobileNumber}
                required
              />
            ) : (
              <>
                <MaterialPhoneInput
                  id={`contactNumber`}
                  label={'Contact number*'}
                  onChange={(e: string) => {
                    setFormValues({ ...formValues, contactNumber: e });
                  }}
                  value={formValues.contactNumber}
                  isError={!!errorMessage?.contactNumber}
                />
                {errorMessage?.contactNumber && <div className={styles.fieldError}>{errorMessage?.contactNumber}</div>}
              </>
            )}
          </div>
          {formValues.isForMySelf && (
            <div className={classNames(styles.fieldContainer, styles.fieldWithTooltip)}>
              <div className={styles.leftContainer}>
                <MaterialInput
                  placeholder={'DD/MM/YYYY'}
                  id={`dateOfBirth`}
                  label={'Your Date of Birth'}
                  onKeyDown={(e) => handleKeyDownDateChange(e, 'dateOfBirth')}
                  onChange={(e) => handleDateChange(e.target.value, 'dateOfBirth')}
                  value={formValues.dateOfBirth}
                  type={'tel'}
                  pattern={'[0-9\\/]*'}
                  maxLength={10}
                  autoComplete={'off'}
                />
                {errorMessage?.dateOfBirth && <div className={styles.fieldError}>{errorMessage?.dateOfBirth}</div>}
              </div>
              <HelpOutLineWithTooltips
                id="dateOfBirth-tooltip"
                text="Why we ask?"
                desc={<p>We ask this to help us match you to services that are age appropriate</p>}
                hideIcon
              />
            </div>
          )}
          {formValues.isForMySelf && (
            <div className={classNames(styles.fieldContainer, styles.fieldWithTooltip)}>
              <div className={styles.leftContainer}>
                <MaterialInput
                  id={`postcode`}
                  label={'Your Post Code'}
                  onChange={(e) => setFormValues({ ...formValues, postcode: e.target.value })}
                  value={formValues.postcode}
                  maxLength={7}
                  autoComplete={'off'}
                />
                {errorMessage?.postcode && <div className={styles.fieldError}>{errorMessage?.postcode}</div>}
              </div>
              <HelpOutLineWithTooltips
                id="postcode-tooltip"
                text="Why we ask?"
                desc={
                  <p>We ask this to help us determine different ways we might support you based on your location</p>
                }
                hideIcon
              />
            </div>
          )}
          {!formValues.isForMySelf && formValues.referralRecordType !== 'adult' && (
            <div className={styles.selectContainer}>
              <MaterialSelect
                id={'whoseDetails'}
                label={`Your relationship to ${formValues.referralFirstName}`}
                isSearchable={false}
                optionList={referralDetailOption}
                value={formValues.whoseDetails}
                onChange={(event: string) => {
                  setFormValues({ ...formValues, whoseDetails: event });
                }}
                isError={!!errorMessage?.whoseDetails}
              />
              {errorMessage?.whoseDetails && <div className={styles.fieldError}>{errorMessage?.whoseDetails}</div>}
            </div>
          )}
        </div>
      )}
      {IS_HELM_APP && (
        <div className={styles.toggleSwitchWrapper}>
          <div className={styles.fieldLabel}>
            Are you joining the waitlist for the Power to Change Program specifically?
          </div>
          <ToggleSwitch
            buttonWidth={313}
            id={'joinPowerToChange'}
            isCheckedProps={formValues.customFields.helmInterestedWithPowerToChange}
            unCheckLabel={'No'}
            checkLabel={'Yes'}
            onChangeProps={(event) => {
              setFormValues({
                ...formValues,
                customFields: {
                  ...formValues.customFields,
                  helmInterestedWithPowerToChange: event.target.checked
                }
              });
            }}
          />
        </div>
      )}
      <div className={styles.selectContainer}>
        <MaterialSelect
          id={'referralSource'}
          label={'Where did you hear about our service?'}
          isSearchable={false}
          optionList={REFERRAL_OPTIONS}
          value={formValues.referralSource}
          onChange={(event: string) => {
            setFormValues({ ...formValues, referralSource: event });
          }}
          isError={!!errorMessage?.referralSource}
        />
        {errorMessage?.referralSource && <div className={styles.fieldError}>{errorMessage?.referralSource}</div>}
      </div>
      {formValues.referralSource &&
        REFERRAL_OPTIONS.filter((option) => option.needDetails)
          .map((option) => option.value)
          .includes(formValues.referralSource) && (
          <div className={styles.fieldContainer}>
            <MaterialInput
              label={
                formValues.referralSource === 'other'
                  ? `Can you provide the service name?`
                  : `Can you provide the name of the ${formValues.referralSource.replace('other ', '')}?`
              }
              id={'referralDetail'}
              value={formValues.referralDetail}
              onChange={(event) => {
                setFormValues({ ...formValues, referralDetail: event.target.value });
              }}
              maxLength={200}
            />
          </div>
        )}
    </div>
  );
};

export default ContactForm;
