import classNames from 'classnames';

import styles from './SomeoneHealthContentLayout.module.scss';

interface SomeoneHealthContentLayoutProps {
  children?: any;
  className?: string;
}

const SomeoneHealthContentLayout = ({ children, className }: SomeoneHealthContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default SomeoneHealthContentLayout;
