import styles from './MyPsychologist.module.scss';
import MyPsychologistDetail from './components/MyPsychologistDetail/MyPsychologistDetail';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';

const MyPsychologist = () => {
  return (
    <RechargeHelmetWrapper title={'Recharge Wellness | My Psychologist'}>
      <RechargeContentLayout className={`${styles.container} recharge-theme`}>
        <RechargeHeader withPadding withMenu homeLink whiteMenu />
        <MyPsychologistDetail />
      </RechargeContentLayout>
    </RechargeHelmetWrapper>
  );
};

export default MyPsychologist;
