import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { SurveyAssessment } from 'interfaces/Assessment/SurveyInterfaces';
import { getSurveyQuestions } from '../../../../utils/http/CheckInService/Surveys/surveys';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useFetchSurveyQuestion = (token: string, surveyId: string) => {
  const navigate = useNavigate();
  const [surveyQuestion, setSurveyQuestion] = useState<SurveyAssessment>({} as SurveyAssessment);
  const [isSurveyQuestionLoading, setIsSurveyQuestionLoading] = useState(true);

  const fetchSurveyQuestions = async () => {
    setIsSurveyQuestionLoading(true);

    try {
      const response = await (await getSurveyQuestions(token, surveyId)).json();

      setSurveyQuestion(response);
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Fail to get survey questions.',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        navigate('/404');
        return;
      }
    }

    setIsSurveyQuestionLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchSurveyQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { surveyQuestion, isSurveyQuestionLoading, setSurveyQuestion };
};
