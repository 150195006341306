import { Skeleton } from 'antd';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import moment from 'moment';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppointmentAvailability } from 'utils/hooks/appointment';
import { Clinician } from 'utils/hooks/clinician';
import { AppointmentSlot } from 'utils/hooks/appointment';

import styles from './AvailabilityListing.module.scss';
import AvailabilityNotAvailable from './components/AvailabilityNotAvailable/AvailabilityNotAvailable';
import momentTz from 'moment-timezone';

export interface Availability {
  date: string;
  appointmentSlots: AppointmentSlot[];
  blocked: boolean;
  occupancy: number;
}

interface AvailabilityProps {
  appointmentType?: AppointmentAvailability['appointmentType'];
  availabilities?: AppointmentAvailability['timeSlots'];
  practiceProfile?: PublicPracticeProfile;
  clinician?: Clinician;
  isAvailabilityListEmpty: boolean;
  isAvailabilityListLoading: boolean;
  isNewClient?: boolean;
  paymentRequired?: boolean;
  timezone: string;
}

const AvailabilityListing = ({
  appointmentType,
  availabilities,
  practiceProfile,
  clinician,
  isAvailabilityListEmpty,
  isAvailabilityListLoading,
  isNewClient,
  paymentRequired,
  timezone
}: AvailabilityProps) => {
  const { slugUrl = '' } = useParams<{ slugUrl: string }>();
  const [selectedWeek, setSelectedWeek] = useState('thisWeek');

  const handleScroll = () => {
    const availabilityListPosition = document?.getElementById('availabilityList')?.offsetTop;
    const nextWeekPos = document.getElementsByClassName('nextWeek')[0].getClientRects()[0].y;
    const twoWeeksOutPos = document.getElementsByClassName('twoWeeksOut')[0].getClientRects()[0].y;
    const threeWeeksOutPos = document.getElementsByClassName('threeWeeksOut')[0].getClientRects()[0].y;
    const fourWeeksOutPos = document.getElementsByClassName('fourWeeksOut')[0].getClientRects()[0].y;

    if (availabilityListPosition! >= fourWeeksOutPos) {
      setSelectedWeek('fourWeeksOut');
    } else if (availabilityListPosition! >= threeWeeksOutPos) {
      setSelectedWeek('threeWeeksOut');
    } else if (availabilityListPosition! >= twoWeeksOutPos) {
      setSelectedWeek('twoWeeksOut');
    } else if (availabilityListPosition! >= nextWeekPos) {
      setSelectedWeek('nextWeek');
    } else {
      setSelectedWeek('thisWeek');
    }
  };

  const scrollIn = (selectedWeek: string): void => {
    document.getElementsByClassName(selectedWeek)?.[0]?.scrollIntoView({
      behavior: 'smooth'
    });

    return;
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <div className={styles.filterWrapper}>
          <div
            className={selectedWeek === 'thisWeek' ? styles.filterDateActive : styles.filterDate}
            onClick={() => scrollIn('thisWeek')}
          >
            This week
          </div>
          <div
            className={selectedWeek === 'nextWeek' ? styles.filterDateActive : styles.filterDate}
            onClick={() => scrollIn('nextWeek')}
          >
            Next week
          </div>
          <div
            className={selectedWeek === 'twoWeeksOut' ? styles.filterDateActive : styles.filterDate}
            onClick={() => scrollIn('twoWeeksOut')}
          >
            2 weeks out
          </div>
          <div
            className={selectedWeek === 'threeWeeksOut' ? styles.filterDateActive : styles.filterDate}
            onClick={() => scrollIn('threeWeeksOut')}
          >
            3 weeks out
          </div>
          <div
            className={selectedWeek === 'fourWeeksOut' ? styles.filterDateActive : styles.filterDate}
            onClick={() => scrollIn('fourWeeksOut')}
          >
            4 weeks out
          </div>
        </div>
        <div className={styles.divider} />
      </div>
      {isAvailabilityListLoading ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : isAvailabilityListEmpty ? (
        <AvailabilityNotAvailable clinician={clinician} practiceProfile={practiceProfile} isNewClient={isNewClient} />
      ) : (
        <div className={styles.availabilityList} id="availabilityList" onScroll={handleScroll}>
          {availabilities &&
            Object.entries(availabilities).map(([date, availability], index) => {
              const timeSlots = availability.timeSlots ?? [];
              let weekIndent;
              if (index < 7) {
                weekIndent = 'thisWeek';
              } else if (index < 14) {
                weekIndent = 'nextWeek';
              } else if (index < 21) {
                weekIndent = 'twoWeeksOut';
              } else if (index < 28) {
                weekIndent = 'threeWeeksOut';
              } else if (index <= 35) {
                weekIndent = 'fourWeeksOut';
              }
              return (
                <div
                  className={`${styles.calendarContainer} ${weekIndent} ${practiceProfile ? styles.practice : ''}`}
                  key={date}
                >
                  <div className={styles.dateWrapper}>
                    <div className={styles.date}>
                      <span>{moment(date).format('DD')}</span>
                      <span className={styles.dotWrapper}>
                        <span className={timeSlots.length > 0 ? styles.availDot : styles.noAvailDot} />
                      </span>
                    </div>
                    <div className={styles.month}>{moment(date).format('MMM')}</div>
                    <div className={styles.countDotContainer}>
                      <div className={styles.countDotWrapper}>
                        {timeSlots.map((_, index) => {
                          return <div key={index} className={styles.appCountDot} />;
                        })}
                      </div>
                    </div>
                  </div>
                  <div className={styles.calendarWrapper}>
                    <div className={styles.day}>{moment(date).format('dddd')}</div>
                    <div className={styles.desc}>{timeSlots.length} Open appointments</div>
                    <div className={styles.calendarBox}>
                      {timeSlots.map((slot, index) => {
                        return (
                          <Link
                            to={{
                              pathname: clinician
                                ? `/${clinician._id}/appointment/mode`
                                : `/p/${slugUrl}/appointment/mode`
                            }}
                            state={{
                              clinician,
                              practiceProfile,
                              slot: {
                                date,
                                endDate: date,
                                ...slot,
                                ...appointmentType
                              },
                              isNewClient,
                              paymentRequired
                            }}
                            key={index}
                            className={`${styles.calendar} ${practiceProfile ? styles.practicePill : ''}`}
                          >
                            {momentTz.tz(slot.startDateTime, timezone).format('hh:mm')} to{' '}
                            {momentTz.tz(slot.endDateTime, timezone).format('hh:mm A')}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default AvailabilityListing;
