import { notification } from 'antd';
import classNames from 'classnames';
import DateSelector from 'components/DatePicker/DateSelector';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import { ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';
import { useState, ChangeEvent } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { putClientReferralDetail } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import * as Yup from 'yup';
import styles from './ReferralDetailForm.module.scss';
import { checkReferralFileSize } from 'Ease/utils/fileSize';
import ButtonEase, { ButtonStatusType } from 'Ease/components/ButtonEase/ButtonEase';

interface EaseReferralDetail {
  code: string;
  name?: string;
  practiceName?: string;
  files?: File[];
  currentFiles?: {
    bucketName: string;
    fileName: string;
    fileUrl: string;
  }[];
  date?: string;
}

const validatorSchema = Yup.object().shape({
  name: Yup.string().required('Name of GP is required'),
  practiceName: Yup.string().required('Practice name is required'),
  date: Yup.string().required('Referral date is required')
});

const initialFormState: EaseReferralDetail = { code: 'no', name: '', practiceName: '' };

const radioOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

const ReferralDetailForm = () => {
  const { token } = useGetAccessToken();

  const [values, setValues] = useState<EaseReferralDetail>(initialFormState);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');
  const [touchedInputs, setTouchedInputs] = useState<string[]>([]);
  const [errors, setErrors] = useState<{
    GPName?: string;
    practiceName?: string;
    referralDate?: string;
  }>({});

  const handleChangeValue = (val: EaseReferralDetail) => {
    setValues(val);
    validateData(val);
  };

  const resetForm = () => {
    setValues(initialFormState);
  };

  const validateData = (val: EaseReferralDetail) => {
    const validate = (values: EaseReferralDetail) => {
      try {
        validatorSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (ex) {
        const validationErrors = {} as any;
        if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
          ex.inner.map((error: any) => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
            return validationErrors;
          });
          return validationErrors;
        }
      }
    };

    const error = validate(val);

    setErrors({
      practiceName: error.practiceName,
      GPName: error.name,
      referralDate: error.date
    });
  };

  const isButtonDisabled = () => {
    if (buttonStatus !== '') {
      return true;
    }

    if (values.code === 'yes' && (!values.name || !values.practiceName || !values.date)) {
      return true;
    }

    if (values.code === 'no' && !values.files) {
      return true;
    }

    return false;
  };

  const handleUploadReferral = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const validatedFiles: File[] = [];
      files.forEach((file) => {
        const fileSizeValidate = checkReferralFileSize(file);
        if (!fileSizeValidate.valid) {
          notification.error({ message: fileSizeValidate.message });
        } else {
          validatedFiles.push(file);
        }
      });
      setValues((values) => ({
        ...values,
        files: [...validatedFiles]
      }));
    }
    e.target.value = '';
  };

  const handleSubmit = async () => {
    setButtonStatus('active');
    try {
      const payload: ReferralDetailUpdateInterface = {
        files: [],
        treatmentPlanFile: undefined,
        name: values.name,
        practiceName: values.practiceName,
        isReferredByGP: values.code === 'yes',
        date: values.date,
        isHaveTreatmentPlan: false
      };

      const referralFiles = values.files;
      if (referralFiles?.length) {
        payload.files = await Promise.all(
          referralFiles.map(
            (file) =>
              uploadDocumentWithEncryption(token, file) as Promise<{
                bucketName: string;
                fileName: string;
                fileUrl: string;
              }>
          )
        );
      }

      const res = await putClientReferralDetail(token, payload);

      if (res.statusCode === 204) {
        notification.success({ message: 'Your referral detail has been saved!' });
        setButtonStatus('finished');
        resetForm();
      } else if (res.statusCode === 400) {
        notification.error({
          message: await res.text(),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    } catch (e) {
      notification.error({
        message: 'Referral details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <div className={styles.content}>
      <Radio
        label={'Have you been able to speak with a GP about your mental health?'}
        variant={'button'}
        id={'referralByGP'}
        name={'referralByGP'}
        options={radioOptions}
        radioLabelClass={styles.radioLabel}
        value={values.code}
        onChange={(e) => (e.target.value === 'yes' ? setValues({ ...values, code: e.target.value }) : resetForm())}
      />

      <div className={styles.inputs}>
        <div className={classNames(values.code === 'no' && styles.disabled)}>
          <div className={styles.row}>
            <MaterialInput
              className={styles.input}
              label="Name of GP's surgery / practice"
              id={'practiceName'}
              onChange={(e) => handleChangeValue({ ...values, practiceName: e.target.value })}
              onBlur={() =>
                !touchedInputs.includes('practiceName') && setTouchedInputs([...touchedInputs, 'practiceName'])
              }
              value={values.practiceName}
              maxLength={80}
              disabled={values.code === 'no'}
              required
            />
          </div>
          <ErrorMessage
            className={styles.error}
            error={errors.practiceName}
            visible={touchedInputs.includes('practiceName') && !!errors.practiceName}
          />
        </div>

        <div className={classNames(values.code === 'no' && styles.disabled)}>
          <div className={styles.row}>
            <MaterialInput
              className={styles.input}
              label={'Name of GP'}
              id={'GPName'}
              onChange={(e) => handleChangeValue({ ...values, name: e.target.value })}
              onBlur={() => !touchedInputs.includes('GPName') && setTouchedInputs([...touchedInputs, 'GPName'])}
              value={values.name}
              maxLength={80}
              disabled={values.code === 'no'}
              required
            />
          </div>
          <ErrorMessage
            className={styles.error}
            error={errors.GPName}
            visible={touchedInputs.includes('GPName') && !!errors.GPName}
          />
        </div>

        <div className={classNames(styles.row, values.code === 'no' && styles.disabled)}>
          <DateSelector
            id={'referralDate'}
            name={'referralDate'}
            className={styles.datePicker}
            inputClass={styles.input}
            label={'Date of referral'}
            onChange={(e: string) => {
              if (!touchedInputs.includes('referralDate')) {
                setTouchedInputs([...touchedInputs, 'referralDate']);
              }
              handleChangeValue({ ...values, date: e });
            }}
            value={values.date}
            disabled={values.code === 'no'}
          />
        </div>
        {values.code !== 'no' && (
          <ErrorMessage
            className={styles.error}
            error={errors.referralDate}
            visible={touchedInputs.includes('referralDate') && !!errors.referralDate}
          />
        )}
      </div>

      <div className={styles.uploadReferralContainer}>
        <input
          className={styles.uploadContainerInput}
          type={'file'}
          id={'referralFile'}
          onChange={handleUploadReferral}
          accept={'.pdf,.docx,image/png,image/jpeg'}
          multiple
        />
        <label className={styles.uploadContainerBtn} htmlFor={'referralFile'}>
          <i className={`material-icons ${styles.publishIcon}`}>upload</i>
          <div className={styles.publishLabel}>UPLOAD ANY OTHER REFERRAL DOCUMENTS</div>
        </label>
        {values.files && values.files.length > 0 ? (
          <div className={styles.uploadedFiles}>
            <div>
              {values.files.map((item, index) => (
                <div className={styles.uploadedFile} key={index}>
                  <span>{item.name}</span>
                  <span>{(item.size / (1024 * 1024)).toFixed(2)} MB</span>
                </div>
              ))}
            </div>
            <button
              className={styles.removeFile}
              onClick={() => {
                setValues({ ...values, files: undefined });
              }}
            >
              <i className="material-icons-outlined">delete</i>
              Remove
            </button>
          </div>
        ) : (
          values.currentFiles && (
            <div className={styles.uploadedFiles}>
              <div>
                {values.currentFiles.map((item, index) => (
                  <div className={styles.uploadedFile} key={index}>
                    <span>{item.fileName}</span>
                  </div>
                ))}
              </div>
              <button
                className={styles.removeFile}
                onClick={() => {
                  setValues({ ...values, currentFiles: undefined });
                }}
              >
                <i className="material-icons-outlined">delete</i>
                Remove
              </button>
            </div>
          )
        )}
      </div>
      <div className={styles.submitButtonContainer}>
        <ButtonEase
          disabled={isButtonDisabled()}
          className={styles.submitBtn}
          status={buttonStatus}
          onClick={handleSubmit}
        >
          SAVE REFERRAL DETAILS
        </ButtonEase>
      </div>
    </div>
  );
};

export default ReferralDetailForm;
