import { Invoice } from 'interfaces/Invoice/Invoice';
import styles from './ItemsList.module.scss';
import { config } from 'config/config';

const MONEY_SYMBOL = config.currencySymbol;
const TAX_LABEL = config.taxLabel;

const calculateDiscount = (items: Invoice['items'], discountType?: 'percent' | 'amount', discountValue?: number) => {
  if (!discountType || discountValue === undefined || isNaN(Number(discountValue))) {
    return undefined;
  }

  const totalCost = items
    .map((item) => Number(item.cost))
    .reduce((finalTotal, currentCost) => finalTotal + currentCost, 0);

  if (discountType === 'percent' && discountValue <= 100) {
    return (totalCost * Math.round(discountValue)) / 100;
  } else if (discountType === 'amount' && discountValue <= totalCost) {
    return discountValue;
  }
};

interface ItemsListProps {
  discount: Invoice['discount'];
  items: Invoice['items'];
  taxRate: Invoice['taxRate'];
  amountChanged?: boolean;
}

const ItemsList = ({ discount, items, taxRate, amountChanged }: ItemsListProps) => {
  const discountValue = calculateDiscount(items, discount?.type, discount?.value);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Items</div>
      <div className={styles.content}>
        <div className={styles.headingContainer}>
          <span className={styles.heading}>#</span>
          <span className={styles.heading}>OVERVIEW</span>
          {!amountChanged && (
            <>
              <span className={styles.heading}>COST</span>
              <span className={styles.heading}>
                {TAX_LABEL}
                {taxRate ? ` (${taxRate}%)` : ''}
              </span>
              <span className={styles.heading}>TOTAL</span>
            </>
          )}
        </div>
        {items.map(({ cost, tax, total, overview, tags = [] }, index) => (
          <div className={styles.itemContainer} key={index}>
            <span className={styles.index}>{index + 1}</span>
            <span className={styles.label}>
              {overview}
              {tags.length > 0 && (
                <div className={styles.tags}>
                  {tags.map((status, index) => (
                    <div className={styles.tag} key={index}>
                      {status}
                    </div>
                  ))}
                </div>
              )}
            </span>
            {!amountChanged && (
              <>
                <span className={styles.cost}>
                  {MONEY_SYMBOL}
                  {cost.toFixed(2)}
                </span>
                <span className={styles.tax}>
                  {MONEY_SYMBOL}
                  {tax.toFixed(2)}
                </span>
                <span className={styles.total}>
                  {MONEY_SYMBOL}
                  {total.toFixed(2)}
                </span>
              </>
            )}
          </div>
        ))}
        {!amountChanged && discount && (
          <div className={styles.discountContainer}>
            <span className={styles.label}>Discount</span>
            <span className={styles.discount}>
              - {MONEY_SYMBOL}
              {discountValue?.toFixed(2)}
            </span>
            <div className={styles.discount} />
            <div className={styles.discount} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemsList;
