import styles from './SomeoneHealthTimer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimExpireAlert from 'components/TimeExpireAlert/TimeExpireAlert';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { AppointmentSlot } from 'utils/hooks/appointment';

interface SomeoneHealthTimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
  reversedAppointmentId?: string;
}

const SomeoneHealthTimer = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  hideTimer,
  onlyText,
  reversedAppointmentId
}: SomeoneHealthTimerProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();
  const { clinicianId, reserveId } = useReserveAppointmentData(reversedAppointmentId);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
    clinicianId
  });

  if (!reserveId) {
    if (clinicianId) {
      navigate(detailsPagePath);
    } else {
      navigate(PRACTITIONER.LISTING);
    }
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 0) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);
  const returnRoutes = clinicianId ? detailsPagePath : PRACTITIONER.LISTING;

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#F11D66'}
      />
      <TimExpireAlert showExpiredAlert={showExpiredAlert} returnRoute={returnRoutes} />
    </div>
  );
};

export default SomeoneHealthTimer;
