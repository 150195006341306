import kickstartLogo from 'assets/images/smp/kickstartLogo.png';
import smpCoverBg from 'assets/images/smp/smpCoverBg.png';
import moment from 'moment';
import { SMPType } from 'pages/SMP/interfaces/smp';
import styles from './SMPCover.module.scss';

interface SMPCoverProps {
  type?: SMPType;
  submittedAt?: string;
  submittedBy?: string;
}

const SMPCover = ({ submittedAt, submittedBy, type }: SMPCoverProps): JSX.Element => {
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${window.location.origin + smpCoverBg})` }}>
      <div>
        <div className={styles.date}>{moment(submittedAt || new Date()).format('MMMM DD, YYYY')}</div>
        <div className={styles.title}>My Self Management Plan</div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.subtitle}>
          {type === SMPType.G24
            ? `Live a life free from substance use and the criminal justice system`
            : `This personalised plan will help you stay on track after you finish the Power to Change program`}
        </div>
        <div className={styles.createdByContainer}>
          <div>Created by</div>
          <div className={styles.value}>{submittedBy || ''}</div>
        </div>
        {type !== SMPType.IP6 && (
          <>
            <div className={styles.createdByContainer}>
              <div>Program</div>
              <div className={styles.value}>Kickstart</div>
            </div>
            <img src={kickstartLogo} alt="KickStart Logo" className={styles.logo} />
          </>
        )}
      </div>
    </div>
  );
};

export default SMPCover;
