import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './resources';

const defaultNS = 'translations';

export { resources, defaultNS };

i18n.use(initReactI18next).init({
  lng: 'en',
  resources,
  fallbackLng: 'en',
  debug: false,

  ns: ['translations', 'terminology'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  }
});

export default i18n;
