import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from 'antd';
import moment from 'moment';
import { get } from 'lodash';

import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import PLACEHOLDER_IMG from 'assets/images/patientMock.png';

import styles from './Header.module.scss';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { Clinician } from 'utils/hooks/clinician';

interface HeaderProps {
  isLoading?: boolean;
  appointment?: AppointmentSlot;
  clinician: Clinician;
}

const Header = ({ isLoading, appointment, clinician }: HeaderProps) => {
  const { user } = useAuth0();

  const { deliveryType, startTime, endTime, date, otherInstructions } = appointment || {};
  const appointmentDate = moment(date).format('LL');

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Appointment Reschedule</div>
      </div>
      <div className={styles.detail}>
        <div className={styles.patientWrapper}>
          {isLoading && [
            <Skeleton.Image className={styles.loadingProfileImg} />,
            <span className={styles.patientName}>
              <Skeleton.Input style={{ width: '90px', marginTop: '8px' }} size="small" active />
            </span>
          ]}
          {!isLoading && (
            <>
              <div className={styles.profileImg}>
                <ClientProfileAvatar avatarUrl={user?.picture} initialsName={''} />
              </div>
              <span className={styles.patientName}>{user?.name}</span>
            </>
          )}
        </div>
        <div className={styles.lineWrapper}>
          <div className={`${styles.line} ${isLoading ? styles.lineLoading : ''}`} />
        </div>
        <div className={styles.patientWrapper}>
          {isLoading && [
            <Skeleton.Image className={styles.loadingProfileImg} />,
            <span className={styles.patientName}>
              <Skeleton.Input style={{ width: '90px', marginTop: '8px' }} size="small" active />
            </span>
          ]}
          {!isLoading && [
            <img src={get(clinician, 'avatar', PLACEHOLDER_IMG)} className={styles.profileImg} alt={'ptProfileImg'} />,
            <span className={styles.patientName}>{get(clinician, 'name')}</span>
          ]}
        </div>
      </div>
      <div className={styles.appDetails}>
        {isLoading && (
          <div className={styles.date}>
            <Skeleton.Input style={{ width: '280px' }} active size="default" />
          </div>
        )}
        {!isLoading && <div className={styles.date}>{appointmentDate}</div>}

        <div className={styles.timeWrapper}>
          <div className={styles.timeBox}>
            <div className={styles.timeText}>Start</div>
            {isLoading && <Skeleton.Button active />}
            {!isLoading && <div className={styles.time}>{startTime}</div>}
          </div>

          <div className={styles.timeBox}>
            <div className={styles.timeText}>End</div>
            {isLoading && <Skeleton.Button active />}
            {!isLoading && <div className={styles.time}>{endTime}</div>}
          </div>
        </div>

        <div className={styles.deliveryMethod}>
          {isLoading && <Skeleton.Input active style={{ width: '115px' }} />}
          {!isLoading && (
            <span className={styles.deliveryMethodContent}>
              {getDeliveryTypeLabel(deliveryType, otherInstructions)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
