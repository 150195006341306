import { useEffect, useState } from 'react';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';

import styles from './PractitionerTimeSlot.module.scss';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import StepTitle from './components/StepTitle/StepTitle';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import Calendar from './components/Calendar/Calendar';
import BookingSession from './components/BookingSession/BookingSession';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AvailabilityAppointmentTypes,
  DeliveryType,
  TimeSlotsWithDateInterface,
  useAvailabilityWithTimeZone,
  useFetchAvailabilityAppointmentTypes
} from 'utils/hooks/appointment';
import { notification, Skeleton } from 'antd';
import { timeZoneLocalStorage } from 'helm/pages/PractitionerListing/PractitionerListing';
import { scrollToView } from 'utils/scrollToView';
import { postReservedAppointment } from 'utils/http/appointment';
import { useReserveAppointmentData, useSetReserveAppointmentData } from 'helm/utils/hooks/localData';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { massageTimeSlotReverse } from 'helm/pages/PractitionerListing/hooks/getPractitionerList';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { InitialSessionType, SpecialistTheme } from 'helm/interfaces/Filter/searchFilter';
import NoAvailableMessageBox from 'helm/components/NoAvailableMessageBox/NoAvailableMessageBox';
import { gaEventPush } from 'utils/GAEvent';
import { listInterface } from './components/Calendar/components/TimeFilterDropdown/TimeFilterDropdown';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { AU_TIME_ZONE_LIST, massageDateTimeFromSourceTzToTargetTz } from 'utils/constants/timeZone';
import { getAppointmentTypePhoneCallDeliveryMode, getDeliveryTypeLabel } from 'utils/appointment';

export interface TimeSlotBooking {
  accountId: string;
  sessionTypeId: string;
  slots: TimeSlotsWithDateInterface[];
  returnUrl: string;
  cancelUrl: string;
}

const deliveryFilter: listInterface[] = [
  {
    id: DeliveryType.VideoCall,
    label: 'Video Call'
  },
  {
    id: DeliveryType.FaceToFace,
    label: 'Face to face'
  },
  {
    id: DeliveryType.PhoneCall,
    label: 'Phone Call'
  },
  {
    id: DeliveryType.Other,
    label: 'Other'
  }
];

interface PractitionerTimeSlotProps {
  helmDetails: PractitionerDetailsInterface;
  overlayImage?: boolean;
}

const PractitionerTimeSlot = ({ helmDetails, overlayImage }: PractitionerTimeSlotProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { setReserveAppointmentData } = useSetReserveAppointmentData();

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();

  const { availabilityAppointmentTypes: fullAvailabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } =
    useFetchAvailabilityAppointmentTypes({
      accountId: helmDetails.accountId,
      clinicianId: helmDetails._id
    });
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<AvailabilityAppointmentTypes[]>([]);
  const [isNewClient, setIsNewClient] = useState<boolean>();
  const { SIGNUP, WAITLIST, PRACTITIONER } = useHelmRoutesGenerator();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { HelmContactUsURL } = helmEnvironment();
  const {
    selectedDateTime,
    selectedSessionType,
    theme,
    sid,
    showAllAppt
  }: { selectedDateTime?: string; selectedSessionType?: string; theme?: string; sid?: string; showAllAppt?: string } =
    queryString.parse(search);
  const selectedAppointmentTypeParams = helmDetails.helmControl.isAdvisor
    ? InitialSessionType.AdvisorySession
    : selectedSessionType || InitialSessionType.InitialConsult;

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AvailabilityAppointmentTypes | undefined>();
  const [deliveryTypeList, setDeliveryTypeList] = useState<listInterface[]>(deliveryFilter);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType>(DeliveryType.VideoCall);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);
  const [isInitParamsUsed, setIsInitParamsUsed] = useState(false);
  const { helmSupportStaffProfile } = useReserveAppointmentData();
  const [isProcessingReservation, setIsProcessingReservation] = useState(false);

  const handleChangeAppointmentType = (valItem: AvailabilityAppointmentTypes) => {
    if (valItem.name !== selectedAppointmentType?.name) {
      setSelectedTimeSlots([]);
    }
    setSelectedAppointmentType(valItem);
  };

  const clientTimeZone = localStorage.getItem(timeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const [selectedTimeZone, setSelectedTimezone] = useState(
    clientTimeZone ||
      // use browser timezone if browser timezone is supported
      AU_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      AU_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      AU_TIME_ZONE_LIST[0].id
  );

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailabilityWithTimeZone({
    appointmentTypeId: selectedAppointmentType?._id!,
    accountId: helmDetails?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(12, 'month').format('YYYY-MM-DD'),
    clinicianId: helmDetails._id,
    timeZone: selectedTimeZone
  });

  const handleChangeTimeZone = (val: string) => {
    if (val !== selectedTimeZone) {
      setSelectedTimezone(val);
      localStorage.setItem(timeZoneLocalStorage, val);

      if (selectedTimeSlots.length) {
        const newSelectedSlots = selectedTimeSlots.map((slot) => {
          const { date, startTime, endTime } = massageDateTimeFromSourceTzToTargetTz({
            ...slot,
            sourceTz: selectedTimeZone,
            targetTZ: val
          });
          return {
            ...slot,
            date,
            startTime,
            endTime
          };
        });
        setSelectedTimeSlots(newSelectedSlots);
      }
    }
  };

  useEffect(() => {
    if (!isAvailabilityAppointmentTypesLoading && fullAvailabilityAppointmentTypes) {
      const newFilteredAppointmentType = fullAvailabilityAppointmentTypes.filter(
        (item: AvailabilityAppointmentTypes) =>
          !IS_HELM_APP ||
          (item.claimType !== AppointmentBookingClaimType.WISE &&
            (isNewClient ? item.bookingRules?.new.available : item.bookingRules?.existing.available))
      );

      const appointmentType =
        newFilteredAppointmentType?.find(
          (item) =>
            item.name &&
            item.name[0].toLocaleLowerCase() + item.name.slice(1).split(' ').join('') === selectedAppointmentTypeParams
        ) ||
        newFilteredAppointmentType?.find((item) => item.name === 'Initial Consultation') ||
        newFilteredAppointmentType?.[0];
      setSelectedAppointmentType(appointmentType);
      const allAppointmentType: DeliveryType[] = [];
      newFilteredAppointmentType?.map((obj) => obj.deliveryOptions.map((typeObj) => allAppointmentType.push(typeObj)));
      const uniqueDeliveryType = [...new Set(allAppointmentType)];
      setAvailabilityAppointmentTypes(newFilteredAppointmentType);

      const deliveryTypeFilter: listInterface[] = [];

      if (uniqueDeliveryType.some((deliveryOption) => deliveryOption === DeliveryType.VideoCall)) {
        deliveryTypeFilter.push({
          id: DeliveryType.VideoCall,
          label: 'Video Call'
        });
      }

      const phoneCallDeliveryMode = getAppointmentTypePhoneCallDeliveryMode(uniqueDeliveryType);

      if (phoneCallDeliveryMode) {
        deliveryTypeFilter.push({
          id: DeliveryType.PhoneCall,
          label: 'Phone Call'
        });
      }

      if (uniqueDeliveryType.some((deliveryOption) => deliveryOption === DeliveryType.FaceToFace)) {
        deliveryTypeFilter.push({
          id: DeliveryType.FaceToFace,
          label: 'Face to Face'
        });
      }

      if (uniqueDeliveryType.some((deliveryOption) => deliveryOption === DeliveryType.Other)) {
        deliveryTypeFilter.push({
          id: DeliveryType.Other,
          label: 'Other'
        });
      }

      setSelectedDeliveryType((deliveryTypeFilter[0]?.id as DeliveryType) || DeliveryType.VideoCall);
      setDeliveryTypeList(deliveryTypeFilter);
    }
  }, [
    isAvailabilityAppointmentTypesLoading,
    selectedAppointmentTypeParams,
    isNewClient,
    fullAvailabilityAppointmentTypes
  ]);

  useEffect(() => {
    if (!isAvailabilityListLoading && appointmentAvailability && !isInitParamsUsed) {
      const [date, startTime, endTime] = selectedDateTime?.split(',') || [];
      if (date && startTime && endTime) {
        const getTimeSlotInfo = appointmentAvailability.timeSlots.find(
          (item) => item.date === date && item.startTime === startTime && item.endTime === endTime
        );
        getTimeSlotInfo && setSelectedTimeSlots([getTimeSlotInfo]);
      }
      setIsInitParamsUsed(true);
    }
  }, [appointmentAvailability, isAvailabilityListLoading, selectedDateTime, isInitParamsUsed]);

  useEffect(() => {
    if (!isClientBookingRuleLoading) {
      const checkNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;
      setIsNewClient(checkNewClient);
    }
  }, [clientBookingRule, isAuthenticated, isClientBookingRuleLoading]);

  const onContinue = async () => {
    if (selectedAppointmentType) {
      const deliveryType = selectedAppointmentType.deliveryOptions.includes(selectedDeliveryType)
        ? selectedDeliveryType
        : // selectedDeliveryType also filters PhoneCallDialClient appointment types
          // if PhoneCall isn't found in selectedAppointmentType.deliveryOptions, it must be PhoneCallDialClient
          selectedDeliveryType === DeliveryType.PhoneCall &&
          selectedAppointmentType.deliveryOptions.includes(DeliveryType.PhoneCallDialClient) &&
          DeliveryType.PhoneCallDialClient;

      if (!deliveryType) {
        console.error(
          `Failed to reserve appointment for appointment type ${selectedAppointmentType._id} with delivery type ${selectedDeliveryType}`
        );
        notification.error({
          message: `Unable to create a ${getDeliveryTypeLabel(
            selectedDeliveryType,
            selectedAppointmentType.otherInstructions
          )} reservation for this appointment.`
        });
        return;
      }

      setIsProcessingReservation(true);
      try {
        const res = await postReservedAppointment(helmDetails.accountId, {
          isNewClient: clientBookingRule === BookingRuleType.New || !isAuthenticated,
          slots: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, clientTimeZone)),
          deliveryType,
          appointmentTypeId: selectedAppointmentType._id,
          ...(!selectedAppointmentType.isAdvisory && {
            clinicianId: helmDetails._id
          })
        });
        if (res.statusCode === 200) {
          const reservedAppointmentData = await res.json();
          gaEventPush('onSuccessReservedAppointment', {
            clinicianName: helmDetails.name,
            sid: sid || '',
            appointmentInfo: {
              name: !selectedAppointmentType.isAdvisory ? selectedAppointmentType.name : helmSupportStaffProfile.name,
              rate: selectedAppointmentType.rate || 0,
              deliveryType,
              timeslot: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, clientTimeZone))
            }
          });
          setReserveAppointmentData({
            reserveId: reservedAppointmentData.reserveId,
            clinicianId: reservedAppointmentData.clinicianId,
            accountId: helmDetails.accountId,
            theme: theme || SpecialistTheme.All,
            sid: sid || '',
            appointmentTypeInfo: {
              name: selectedAppointmentType.name || '',
              description: selectedAppointmentType.description || '',
              rate: selectedAppointmentType.rate || 0,
              isAdvisory: selectedAppointmentType.isAdvisory || false,
              deliveryType,
              otherInstructions: selectedAppointmentType.otherInstructions,
              timeSlot: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, clientTimeZone)),
              sessionTypeId: selectedAppointmentType._id
            },
            programId: selectedAppointmentType.programId
          });
          navigate(`${SIGNUP.BASE}${sid ? `?sid=${sid}` : ''}`);
        } else if (res.statusCode === 409) {
          const conflictAppointmentData = await res.json();
          const conflictSlotList: TimeSlotsWithDateInterface[] = conflictAppointmentData.conflictingSlots || [];

          const timeSlotWithStatus = selectedTimeSlots.map((appointmentListObj) => {
            const startTimeSlot = moment(appointmentListObj.startTime, 'hh:mmA').format('HH:mm');
            const endTimeSlot = moment(appointmentListObj.endTime, 'hh:mmA').format('HH:mm');

            const isConflictSlot = conflictSlotList.some(
              (slotObj) =>
                slotObj.date === appointmentListObj.date &&
                slotObj.startTime === startTimeSlot &&
                slotObj.endTime === endTimeSlot
            );

            return {
              ...appointmentListObj,
              isConflict: isConflictSlot
            };
          });
          setSelectedTimeSlots(timeSlotWithStatus);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to reserve the appointment' });
      }
      setIsProcessingReservation(false);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!selectedTimeSlots.includes(val)) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.indexOf(slot);
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  const handleSelectDeliveryType = (val: DeliveryType) => {
    setSelectedDeliveryType(val);
    setSelectedTimeSlots([]);
    const filterByDeliveryType = availabilityAppointmentTypes?.filter((item) => item.deliveryOptions.includes(val));
    setSelectedAppointmentType(filterByDeliveryType?.[0]);
  };

  const checkSignUpClaimForSameClinician = isAuthenticated
    ? user?.['https://tacklit.com/attachedClinicians'].includes('') ||
      user?.['https://tacklit.com/attachedClinicians'].includes(helmDetails._id)
    : true;

  const isNewClientHasNoSlots =
    !isAuthenticated && helmDetails.helmControl?.shouldUseCaseLoad && (helmDetails.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div id={'HelmTimeSlot'}>
      {isNewClientHasNoSlots ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              <span>
                Unfortunately {helmDetails.name} is not open to new clients directly via our website at this time. If
                you'd like to register your interest you can join the waitlist
                <Link className={styles.joinWaitListHere} to={WAITLIST}>
                  {' '}
                  here{' '}
                </Link>
                or contact our friendly team to discuss working with us.
              </span>
            }
            buttonLabel={'Contact Helm Team'}
            onClick={() => (window.location.href = HelmContactUsURL)}
          />
        </div>
      ) : !checkSignUpClaimForSameClinician ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${helmDetails.name}?`}
            desc={
              'As you are an existing Helm customer please contact our support team to discuss working with a new counsellor'
            }
            buttonLabel={'Contact Helm Team'}
            onClick={() => (window.location.href = HelmContactUsURL)}
          />
        </div>
      ) : (
        <>
          <div className={styles.appointmentSlotContainer}>
            <HelmContentLayout>
              <div className={styles.content}>
                <div className={styles.header}>
                  <div className={styles.leftContent}>
                    <div className={styles.title}>Book time with me</div>
                    {helmDetails.helmControl.appointmentPhoto && (
                      <img className={styles.profile} alt={'profile'} src={helmDetails.helmControl.appointmentPhoto} />
                    )}
                    <div className={styles.desc} onClick={() => scrollToView('HelmFAQ')}>
                      <div className={styles.text}>GOT A QUESTION? READ OUR FAQS</div>
                      <i className={`material-icons ${styles.arrowIcon}`}>arrow_downward</i>
                    </div>
                  </div>
                  <div className={styles.rightContent}>
                    <div className={styles.rightProfileWrapper}>
                      <img
                        className={overlayImage ? styles.overlayRightProfile : styles.rightProfile}
                        alt={'profile'}
                        src={helmDetails.helmControl.appointmentPhoto}
                      />
                    </div>
                    {!isAuthenticated && (
                      <div
                        onClick={() =>
                          loginWithRedirect({
                            loginType: 'patient',
                            redirectUri: window.location.origin + PRACTITIONER.LISTING,
                            appState: { returnTo: window.location.pathname }
                          })
                        }
                        className={styles.loginIfExistingClient}
                      >
                        <div className={styles.text}>EXISTING CLIENT OF {helmDetails.name}? LOGIN</div>
                        <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward</i>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.appointmentContentWrapper}>
                  <div>
                    <StepTitle no={'1'} title={'Select appointment'} />
                    <div className={styles.appointmentType}>
                      {isAvailabilityAppointmentTypesLoading ? (
                        <div className={styles.loadingWrapper}>
                          {[...Array(4)].map((obj, i) => (
                            <Skeleton key={i} className={styles.loading} />
                          ))}
                        </div>
                      ) : (
                        availabilityAppointmentTypes
                          ?.filter(
                            (item) =>
                              (!selectedDeliveryType ||
                                item.deliveryOptions.includes(selectedDeliveryType) ||
                                (selectedDeliveryType === DeliveryType.PhoneCall &&
                                  item.deliveryOptions.includes(DeliveryType.PhoneCallDialClient))) &&
                              (showAllAppt || !item.shouldHideFromListing)
                          )
                          .map((item, index) => (
                            <AppointmentTypeCard
                              key={index}
                              appointmentTypeData={item}
                              onClick={() => !isAvailabilityListLoading && handleChangeAppointmentType(item)}
                              selected={selectedAppointmentType?.name === item.name}
                              disable={isAvailabilityListLoading}
                            />
                          ))
                      )}
                    </div>
                  </div>
                  <div>
                    <StepTitle no={'2'} title={'Select day and time'} />
                    <Calendar
                      onSlotClick={onTimeSlotsChange}
                      timeSlots={appointmentAvailability?.timeSlots || []}
                      remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
                      selectedTimeSlots={selectedTimeSlots}
                      isAvailabilityListLoading={isAvailabilityListLoading}
                      onDeliveryFilterChange={handleSelectDeliveryType}
                      hideDeliveryTypeFilter={helmDetails.helmControl.isAdvisor}
                      deliveryFilter={deliveryTypeList}
                      selectedTimeZone={selectedTimeZone}
                      handleChangeTimeZone={handleChangeTimeZone}
                    />
                  </div>
                </div>
              </div>
            </HelmContentLayout>
          </div>
          <BookingSession
            selectedAppointmentType={selectedAppointmentType}
            selectedTimeSlots={selectedTimeSlots}
            isAvailabilityListLoading={isAvailabilityListLoading}
            isProcessingReservation={isProcessingReservation}
            onContinue={onContinue}
            onRemoveSelectedSlot={onRemoveSelectedSlot}
          />
        </>
      )}
    </div>
  );
};

export default PractitionerTimeSlot;
