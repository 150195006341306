import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import styles from './DownloadButton.module.scss';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

interface DownloadButtonProps {
  status?: HelmBtnStatus;
  onClick?: () => void;
  children: string;
  disabled?: boolean;
  customClass?: string;
  isDownloading?: boolean;
}

const DownloadButton = ({ status, onClick, children, disabled, customClass, isDownloading }: DownloadButtonProps) => {
  return IS_HELM_APP ? (
    <ButtonHelm status={status} onClick={onClick} variant="outlined" disabled={disabled} className={customClass}>
      <div className={styles.center}>
        <i className={`material-icons-outlined ${styles.icon}`}>download</i>
        {isDownloading ? 'Downloading...' : children}
      </div>
    </ButtonHelm>
  ) : (
    <Button status={status} onClick={onClick} variant="secondary" disabled={disabled} className={customClass}>
      <div className={styles.center}>
        <i className={`material-icons-outlined ${styles.icon}`}>download</i>
        {isDownloading ? 'Downloading...' : children}
      </div>
    </Button>
  );
};

export default DownloadButton;
