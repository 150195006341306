import { claimReservedAppointments } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { notification } from 'antd';

/**
 * @param shouldGenerateCheckoutSession if `true`, and URLs passed, this function returns a string
 * @param cancelUrl required if `shouldGenerateCheckoutSession: true`
 * @param returnUrl required if `shouldGenerateCheckoutSession: true`
 */
export const fetchClaimReservation = async ({
  accountId,
  reserveId,
  authToken,
  shouldGenerateCheckoutSession,
  cancelUrl,
  returnUrl
}: {
  accountId: string;
  authToken: string;
  reserveId: string;
  shouldGenerateCheckoutSession: boolean;
  cancelUrl?: string;
  returnUrl?: string;
}): Promise<string | undefined | never> => {
  if (reserveId && accountId && authToken) {
    try {
      const { statusCode, response } = await claimReservedAppointments({
        accountId,
        reservationId: reserveId,
        authToken,
        shouldGenerateCheckoutSession,
        cancelUrl,
        returnUrl
      });

      if (statusCode !== 200) {
        const errorMessage = `Claim reservation (ID: ${reserveId}) failed with status. ${statusCode}`;
        console.error(errorMessage);
        notification.error({ message: errorMessage });
      } else {
        return response?.checkoutUrl;
      }
    } catch (ex) {
      console.error(ex);
    }
  } else {
    console.error(`Missing either reserveId: ${reserveId}, accountId: ${accountId}, or authToken: ${authToken}`);
  }
};
