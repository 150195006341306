import Loading from 'components/Loading/Loading';
import { useFetchReservedAppointmentsWithTimeZone } from 'SomeoneHealth/utils/hooks/appointment';
import { useContext, useEffect, useRef, useState } from 'react';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import Summary from 'SomeoneHealth/pages/BookingConfirmation/components/Summary/Summary';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './BookingConfirmation.module.scss';
import SomeoneHealthTimer from '../SomeoneHealthTimer/SomeoneHealthTimer';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import { UserContext } from 'utils/UserContextProvider';
import { putProcessWithoutPayment } from 'utils/http/appointment';
import { message } from 'antd';
import { useSomeoneHealthToken } from '../../utils/hooks/useSomeoneHealthToken';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import classNames from 'classnames';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import PaymentPolicy from 'SomeoneHealth/components/PaymentPolicy/PaymentPolicy';

const BookingConfirmation = () => {
  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;
  const { token } = useSomeoneHealthToken();
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { SIGNUP } = useSomeoneHealthRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [processingPolicyAccepted, setProcessingPolicyAccepted] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        await fetchClaimingReservation(reserveId, accountId, token);
        const res = await putProcessWithoutPayment(accountId, reserveId, token);

        if (res.statusCode === 204) {
          setConfirmBookingStatus('finished');
          navigate(SIGNUP.WELCOME_BOOKING);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <SomeoneHealthHelmetWrapper title={'someone.health - Booking confirmation'}>
        <SomeoneHealthHeader withPadding pinkLogo withMenu pinkMenu />
        <SomeoneHealthContentLayout className={styles.layoutClassName}>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'someone-health-theme')}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <div className={styles.greet}>
                    Thanks{' '}
                    <div className={styles.name}>
                      {clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}
                    </div>
                  </div>
                  <Summary
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    showPayment
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <PaymentPolicy
                    policyAccepted={policyAccepted}
                    onSetPolicyAccepted={setPolicyAccepted}
                    processingPolicyAccepted={processingPolicyAccepted}
                    onSetProcessingPolicyAccepted={setProcessingPolicyAccepted}
                  />
                  <ButtonSH
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!policyAccepted || !processingPolicyAccepted || !token}
                    className={styles.submitButton}
                  >
                    Confirm your booking
                  </ButtonSH>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <SomeoneHealthTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                    />
                    <div className={styles.noticeMsg}>
                      <div>
                        To confirm these appointments you need to complete registration and payment within 15 minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </SomeoneHealthHelmetWrapper>
    </div>
  );
};

export default BookingConfirmation;
