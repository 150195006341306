import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import { clientProfilesInterface } from '../interfaces/Clients/clientsRecord';

export const getName = (
  clientData: { firstName?: string; lastName?: string; name: string },
  firstNameOnly?: boolean
): string => {
  const { firstName, lastName, name } = clientData;
  if (firstName && lastName) {
    return firstNameOnly ? firstName : `${firstName} ${lastName}`;
  }
  return name;
};

export const combineName = (
  clientProfiles: Pick<clientProfilesInterface, 'firstName' | 'lastName' | 'name'>[],
  firstNameOnly?: boolean,
  useSymbol?: boolean
) => {
  return clientProfiles
    .map((val) => getName(val, firstNameOnly))
    .join(', ')
    .replace(/,(?!.*,)/gim, ` ${useSymbol ? '&' : ' and'}`);
};

/**
 * Truncate a long string by provide max length and don't break wording
 *
 * @param value
 * @param maxLength
 * @returns
 */
export const truncateStringByMaxCharacter = (value: string, maxLength: number) => {
  if (value.length <= maxLength) {
    return value;
  }
  const shorterValue = value.substring(0, maxLength);
  if (value[maxLength] === ' ') {
    return shorterValue;
  }
  return shorterValue.substring(0, Math.min(shorterValue.length, shorterValue.lastIndexOf(' ')));
};

//referring from url-regex-safe strict mode
const urlRegex =
  /(?:(?:(?:[a-z]+:)?\/\/)|www\.)(?:localhost|(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:(?:[/?#][^\s")']*[^\s")'.?!])|\/)?/i;

export const linkify = (text?: string) =>
  text?.replace(urlRegex, function (url) {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });

export const getTaskListTitleFromClinicianAccountSettings = (attachedClinicianDetail?: GetAttachedClinicianDetails) =>
  attachedClinicianDetail?.clinician?.accountSettings?.clientPortalCustomisations &&
  'taskListTitle' in attachedClinicianDetail.clinician.accountSettings.clientPortalCustomisations
    ? attachedClinicianDetail.clinician.accountSettings.clientPortalCustomisations.taskListTitle
    : 'Onwards';

export const formatActionCount = ({
  count,
  hideNumberWhenSingular
}: {
  count: number;
  hideNumberWhenSingular?: boolean;
}) => {
  const numberPart = hideNumberWhenSingular && count === 1 ? '' : `${count} `;
  return `${numberPart}${count === 1 ? 'action' : 'actions'}`.trim();
};
