import { useState } from 'react';
import styles from './SelectAvatar.module.scss';
import { postPatientAvatar, putPatient } from 'utils/http/PatientProfileService/Patient/patient';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Personalisation from 'pages/SignUp/SignUpForm/components/Personalisation/Personalisation';
import ButtonEase from '../../components/ButtonEase/ButtonEase';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';

const SelectAvatar = () => {
  const { token } = useGetAccessToken();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [value, setValue] = useState({
    nickname: user?.name || '',
    avatar: user?.picture || ''
  });

  const [loading, setLoading] = useState(false);
  const handleOnSubmit = async () => {
    try {
      setLoading(true);
      const { avatar, nickname } = value;
      const avatarUrl = await (await postPatientAvatar({ avatar })).text();
      await putPatient(token, { avatar: avatarUrl, name: nickname });
      setLoading(false);
      navigate('/');
      navigate(0);
    } catch (ex) {
      setLoading(false);
      notification.error({ message: 'Something went wrong!' });
    }
  };

  return (
    <EaseHelmetWrapper title="EASE Wellbeing | Therapy & Counselling Experts in London - Avatar">
      <div className={`${styles.container} ease-theme`}>
        <EaseHeader withPadding withMenu homeLink greenMenu />
        <div className={styles.content}>
          {/* <div className={styles.skipWrapper}>
            <Link to={'/'} className={styles.skipBtn}>
              Skip
              <i className={`material-icons-outlined ${styles.arrowIcon}`}>keyboard_double_arrow_right</i>
            </Link>
          </div> */}
          <Personalisation
            checkValidation
            title={<div className={styles.title}>You can personalise and set up your profile. </div>}
            description={
              <div className={styles.heading}>
                We are committed to your privacy so we ask you to set up a nickname and avatar to represent you
                anonymously in our system.
              </div>
            }
            personalisationValue={value}
            onChangePersonalisationField={setValue}
            inputClass={styles.input}
            inputContainerClass={styles.inputBox}
          />
          <ButtonEase className={styles.submitBtn} disabled={loading} onClick={handleOnSubmit}>
            SET UP YOUR PROFILE
          </ButtonEase>
        </div>
      </div>
    </EaseHelmetWrapper>
  );
};

export default SelectAvatar;
