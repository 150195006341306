import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import styles from './SMPFooter.module.scss';

interface SMPFooterProps {
  submittedBy?: string;
}

const SMPFooter = ({ submittedBy }: SMPFooterProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.createdByContainer}>
        <div>Created by</div>
        <div className={styles.value}>{submittedBy}</div>
      </div>

      {!IS_HELM_APP && (
        <div>
          <div>Program</div>
          <div className={styles.value}>Kickstart</div>
        </div>
      )}
    </div>
  );
};

export default SMPFooter;
