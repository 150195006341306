import moment from 'moment';

import styles from './TaskBox.module.scss';
import classNames from 'classnames';

interface TaskBoxProps {
  title: string;
  desc?: string;
  onClick?: () => void;
  complete?: boolean;
  createdAt?: string;
  containerClassName?: string;
}

const TaskBox = ({ title, desc, onClick, complete, createdAt, containerClassName }: TaskBoxProps) => {
  return complete ? (
    <div className={styles.completeContainer}>
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
        {createdAt && <div className={styles.time}>Sent on {moment(createdAt).format('DD MMM YYYY hh:mma')}</div>}
      </div>
      <i className={`material-icons ${styles.successIcon}`}>done</i>
    </div>
  ) : (
    <div
      className={classNames(onClick ? styles.container : styles.noLinkContainer, containerClassName)}
      onClick={onClick}
    >
      <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        {desc && <div className={styles.desc}>{desc}</div>}
        {createdAt && <div className={styles.time}>Sent on {moment(createdAt).format('DD MMM YYYY hh:mma')}</div>}
      </div>
      <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward</i>
    </div>
  );
};

export default TaskBox;
