import classNames from 'classnames';

import styles from './CaWContentLayout.module.scss';

interface CaWContentLayoutProps {
  children?: any;
  className?: string;
}

const CaWContentLayout = ({ children, className }: CaWContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default CaWContentLayout;
