import { scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import queryString from 'query-string';
import { GPAppointmentAvailabilityWithTimeZone } from 'SomeoneHealth/pages/Booking/components/BookingFlow/hooks/useFetchGPAvailabilityWithTimeZone';

export enum BookingType {
  BOOKING = 'booking',
  RESCHEDULE = 'reschedule'
}

interface GetPublicAvailabilityByAppointmentTypeIdRequest {
  appointmentTypeId: string;
  accountId: string;
  from: string;
  to: string;
  type: BookingType;
}

export const availabilityAppointmentsByAppointmentTypeId = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailabilityAppointmentsByAppointmentTypeId: builder.query<
      GPAppointmentAvailabilityWithTimeZone,
      GetPublicAvailabilityByAppointmentTypeIdRequest
    >({
      query: ({ appointmentTypeId, accountId, from, to, type }) => {
        const queryParam = queryString.stringify({ accountId, date: from, to, type });
        return {
          url: `/appointment-types/${appointmentTypeId}/availabilities/public?${queryParam}`
        };
      },
      providesTags: ['AvailabilityAppointmentsByAppointmentTypeId']
    })
  })
});

export const { useGetAvailabilityAppointmentsByAppointmentTypeIdQuery } = availabilityAppointmentsByAppointmentTypeId;
