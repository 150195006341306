import { ReactElement } from 'react';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import styles from './CreateProfileRemainingTime.module.scss';
import classnames from 'classnames';
import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { convertTimeSlot } from 'helm/utils/convertTimezone';
import { timeZoneLocalStorage } from 'helm/pages/PractitionerListing/PractitionerListing';
import { DefaultClientTimeZone } from 'helm/utils/HelmEnv/helmEnv';

interface CreateProfileRemainingTimeProps {
  practitionerName: string;
  appointments?: AppointmentSlot[];
  practitionerAvatar?: string;
  showSlotCard?: boolean;
  clientTestimonialCard?: ReactElement;
  isBookingRuleError?: boolean;
}

export const getReservedAppointmentTimeString = (value: AppointmentSlot) => {
  const clientTimeZone = localStorage.getItem(timeZoneLocalStorage) || DefaultClientTimeZone;
  const massageTimeZone = convertTimeSlot(
    value.startTime,
    value.endTime,
    moment(value.date).format('YYYY-MM-DD'),
    clientTimeZone
  );
  return `${moment(value.date).format('DD MMMM YYYY')}, ${moment(massageTimeZone.startTime, 'hh:mmA').format(
    'hh:mm'
  )} to ${massageTimeZone.endTime}`;
};

const CreateProfileRemainingTime = ({
  practitionerName,
  appointments = [],
  practitionerAvatar,
  showSlotCard,
  clientTestimonialCard,
  isBookingRuleError
}: CreateProfileRemainingTimeProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useHelmRoutesGenerator();
  const onTimeExpire = () => {
    notification.info({ message: 'Your reserved appointments are expired.', duration: 5 });
    navigate(PRACTITIONER.LISTING);
  };

  return (
    <div className={styles.container}>
      {!isBookingRuleError && (
        <>
          <div className={styles.notice}>
            To confirm these appointments you need to complete registration and payment within 15 minutes
            <div className={styles.mobileTimer}>
              <CircularTimer
                startTime={appointments?.[0]?.createdAt}
                onlyText
                minutes={10}
                timeLabelClass={styles.timeLabel}
              />
            </div>
          </div>
          <div className={styles.circularTimer}>
            <CircularTimer
              startTime={appointments?.[0]?.createdAt}
              minutes={15}
              timeLabelClass={styles.timeLabel}
              onExpire={onTimeExpire}
              strokeColor={'#243062'}
            />
          </div>
        </>
      )}
      {showSlotCard ? (
        !isBookingRuleError && (
          <div className={styles.holdingSlotCard}>
            <div className={styles.practitionerAvatarRow}>
              <div className={styles.leftCol}>
                <img src={practitionerAvatar} alt="avatar" />
              </div>
              <div className={styles.rightCol}>
                We are holding your selected appointment slots with <b>{practitionerName}</b>.
              </div>
            </div>
            <div className={styles.appointmentsRow}>
              <div className={classnames(styles.leftCol, styles.grayText)}>Holding:</div>
              <div className={styles.rightCol}>
                {appointments.map((item, index) => (
                  <div key={index}>{getReservedAppointmentTimeString(item)}</div>
                ))}
              </div>
            </div>
          </div>
        )
      ) : (
        <div className={styles.clientTestimonialCard}>{clientTestimonialCard}</div>
      )}
    </div>
  );
};

export default CreateProfileRemainingTime;
