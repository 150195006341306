import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styles from './ClientProfileAvatar.module.scss';
import classnames from 'classnames';

const CLIENT_AVATAR_COLOR = '#8AA8D3';
interface ClientProfileAvatarProps {
  avatarUrl?: string;
  initialsName?: string;
  initialClassName?: string;
  tokenUser?: boolean;
  fullAvatarBg?: boolean;
}

const ClientProfileAvatar = ({
  avatarUrl,
  initialsName,
  initialClassName,
  tokenUser,
  fullAvatarBg
}: ClientProfileAvatarProps) => {
  return tokenUser && initialsName ? (
    <div
      style={{ backgroundColor: CLIENT_AVATAR_COLOR }}
      className={classnames(styles.initialProfile, initialClassName)}
    >
      {initialsName}
    </div>
  ) : avatarUrl ? (
    <img src={avatarUrl} className={classnames(styles.imgProfile, fullAvatarBg && styles.avatarBg)} alt={'profile'} />
  ) : (
    <div className={styles.initialProfile}>
      <FontAwesomeIcon icon={faUser} className={styles.icon} />
    </div>
  );
};

export default ClientProfileAvatar;
