import styles from './RiskScreeningForm.module.scss';
import { Formik } from 'formik';
import Form from 'antd/lib/form/Form';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import {
  RISK_SCREENING_OPTIONS,
  formDataValidationSchema,
  initialRiskScreeningValues,
  otherConcernsOptions
} from './constants';
import { scrollErrorsIntoView } from 'utils/scrollToView';
import FormikRadio from 'components/Radio/FormikRadio';
import FormikTextArea from 'components/v2/TextArea/FormikTextArea';
import FormikSelect from 'components/Select/FormikSelect';

interface SelectRiskScreeningFormProps {
  isSubmitting: boolean;
  onSubmit: (value: typeof initialRiskScreeningValues) => void;
}

const SelectRiskScreeningForm = ({ isSubmitting, onSubmit }: SelectRiskScreeningFormProps) => (
  <>
    {isSubmitting && (
      <div className={styles.loading}>
        <Loading />
      </div>
    )}
    <div className={classNames(styles.container, 'select-theme')}>
      <div className={styles.header}>
        <div className={styles.headerText}>Helpful Information For Your Therapist</div>
      </div>
      <Formik
        initialValues={initialRiskScreeningValues}
        onSubmit={onSubmit}
        validationSchema={formDataValidationSchema}
      >
        {({ submitForm, validateForm }) => (
          <Form noValidate>
            <div className={styles.fixCustomInput}>
              <FormikRadio
                id={'thoughtsOfSuicide'}
                name={'thoughtsOfSuicide'}
                variant="button"
                radioClassName={styles.radioButton}
                radioLabelClass={styles.radioLabel}
                label="Have you experienced any thoughts of suicide/self harm in the last 6 months"
                options={RISK_SCREENING_OPTIONS}
              />
              <FormikRadio
                id={'treatmentForSuicide'}
                name={'treatmentForSuicide'}
                variant="button"
                radioClassName={styles.radioButton}
                radioLabelClass={styles.radioLabel}
                label="Have you had any hospital admissions related to your mental health over the past 2 years?"
                options={RISK_SCREENING_OPTIONS}
              />
              <FormikRadio
                id={'drugsOrAlcohol'}
                name={'drugsOrAlcohol'}
                variant="button"
                radioClassName={styles.radioButton}
                radioLabelClass={styles.radioLabel}
                label="Do you depend on drugs or alcohol to manage your mental health?"
                options={RISK_SCREENING_OPTIONS}
              />
              <FormikRadio
                id={'involvedInLegalCase'}
                name={'involvedInLegalCase'}
                variant="button"
                radioClassName={styles.radioButton}
                radioLabelClass={styles.radioLabel}
                label="Are you involved in any ongoing legal cases?"
                options={RISK_SCREENING_OPTIONS}
              />
              <FormikRadio
                id={'treatedByOtherService'}
                name={'treatedByOtherService'}
                variant="button"
                radioClassName={styles.radioButton}
                radioLabelClass={styles.radioLabel}
                label="Are you receiving treatment for your mental health from any other services?"
                options={RISK_SCREENING_OPTIONS}
              />
              <FormikSelect
                id="otherConcerns"
                name="otherConcerns"
                labelClass={styles.selectInputLabel}
                label="Do you have any other concerns that you would like us to consider?"
                placeholder={'Select'}
                isSearchable={false}
                options={otherConcernsOptions}
                styles={{
                  valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                }}
              />
              <FormikTextArea
                name="otherInformation"
                label="Is there any information in relation to the questions above that you think would be helpful for us to know? Please note that, on rare occasions, the team may need to contact you prior to your first appointment to gather some additional information and ensure that your chosen therapist is the best fit for your current needs."
                placeholder="This is a space to add context or background to help"
              />
            </div>
            <ButtonSelect
              className={styles.submitButton}
              onClick={() => {
                submitForm();
                validateForm().then((errors) => scrollErrorsIntoView(errors));
              }}
            >
              Submit
            </ButtonSelect>
          </Form>
        )}
      </Formik>
    </div>
  </>
);

export default SelectRiskScreeningForm;
