import { Modal } from 'antd';
import styles from './FitFilterMobilePopup.module.scss';
import FitFilterMobileItem, { OptionItem } from '../FitFilterMobileItem/FitFilterMobileItem';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';
import { useEffect, useState } from 'react';
import { FitDaysList, FitDeliveryList, FitFundingList, FitTimesList } from 'helm/interfaces/Filter/fitFilter';
import { PractitionerFitFilter } from '../../FitFilter';

export interface FitFilterInterface {
  day: OptionItem | undefined;
  time: OptionItem | undefined;
  delivery: OptionItem | undefined;
  funding: OptionItem | undefined;
}

interface FitFilterMobilePopupProps {
  visible: boolean;
  filterIsSelected: string;
  dayOptions: OptionItem[];
  timeOptions: OptionItem[];
  deliveryOptions: OptionItem[];
  styleOptions: OptionItem[];
  selectedFiltersProp: FitFilterInterface;
  onView: (value: PractitionerFitFilter) => void;
  onClearAll: () => void;
  onCancel: () => void;
}

const FitFilterMobilePopup = ({
  visible,
  filterIsSelected,
  dayOptions,
  timeOptions,
  deliveryOptions,
  styleOptions,
  selectedFiltersProp,
  onView,
  onClearAll,
  onCancel
}: FitFilterMobilePopupProps) => {
  const [selectedFilters, setSelectedFilters] = useState<FitFilterInterface>(selectedFiltersProp);

  useEffect(() => {
    setSelectedFilters(selectedFiltersProp);
  }, [selectedFiltersProp]);

  const handleChangeFilters = (value: OptionItem, key: string) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value
    });
  };

  const handleView = () => {
    const values: PractitionerFitFilter = {
      day: selectedFilters.day?.id as FitDaysList,
      time: selectedFilters.time?.id as FitTimesList,
      deliveryMode: selectedFilters.delivery?.id as FitDeliveryList,
      funding: selectedFilters.funding?.id as FitFundingList
    };

    onView(values);
    onCancel();
  };

  const handleClearAll = () => {
    onClearAll();
    onCancel();
  };

  const { day, time, delivery, funding } = selectedFilters;

  return (
    <Modal
      visible={visible}
      destroyOnClose
      zIndex={2147483648} // Chat Widget z-index is 2147483647
      onCancel={onCancel}
      className={styles.modalClass}
      width={'100%'}
      style={{ top: 0, margin: 0, maxWidth: '100%', padding: 0 }}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>Refine your fit</div>
          <div className={styles.subTitle}>Filter psychologists by...</div>
        </div>
      }
      footer={
        <div className={styles.modalFooter}>
          <ButtonHelm fullWidth onClick={handleView}>
            View
          </ButtonHelm>
          <ButtonHelm variant={'outlined'} className={styles.clearAll} fullWidth onClick={handleClearAll}>
            Clear all
          </ButtonHelm>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div id="funding" className={styles.filterItem}>
            <FitFilterMobileItem
              id="funding"
              classname={styles.filter}
              optionList={styleOptions}
              filterLabel="Funding"
              selectedValue={funding}
              isExpandProp={filterIsSelected === 'style'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'style');
              }}
            />
          </div>
          <div id="day" className={styles.filterItem}>
            <FitFilterMobileItem
              id="day"
              optionList={dayOptions}
              filterLabel="Days"
              selectedValue={day}
              isExpandProp={filterIsSelected === 'day'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'day');
              }}
            />
          </div>
          <div id="time" className={styles.filterItem}>
            <FitFilterMobileItem
              id="item"
              optionList={timeOptions}
              filterLabel="Times"
              selectedValue={time}
              isExpandProp={filterIsSelected === 'time'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'time');
              }}
            />
          </div>
          <div id="delivery" className={styles.filterItem}>
            <FitFilterMobileItem
              id="delivery"
              optionList={deliveryOptions}
              filterLabel="Delivery"
              selectedValue={delivery}
              isExpandProp={filterIsSelected === 'delivery'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'delivery');
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FitFilterMobilePopup;
