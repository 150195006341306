import { ChangeEventHandler, InputHTMLAttributes } from 'react';
import styles from './ToggleSwitch.module.scss';

export interface ToggleSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  checkLabel: string;
  unCheckLabel: string;
  buttonWidth?: number;
  isCheckedProps: boolean;
  onChangeProps: ChangeEventHandler<HTMLInputElement>;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {
  const { id, checkLabel, unCheckLabel, buttonWidth, isCheckedProps, onChangeProps } = props;

  return (
    <div className={styles.toggleSwitch}>
      <input
        type="checkbox"
        id={`toggleSwitch-${id}`}
        checked={isCheckedProps}
        onChange={onChangeProps}
        disabled={props.disabled}
      />
      <label
        htmlFor={`toggleSwitch-${id}`}
        style={{ width: buttonWidth }}
        className={styles.wrapper}
        data-checked={checkLabel}
        data-unchecked={unCheckLabel}
      >
        <div className={styles.switch} />
      </label>
    </div>
  );
};

export default ToggleSwitch;
