import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

export interface UserSessionStorage {
  clientRecord: clientRecordsInterface;
  authToken: string;
  firstName?: string;
}

const SIGN_UP_CREATED_USER = 'CAW_SIGN_UP_CREATED_USER' as const;

export const useCawUserSession = () => {
  const [cookies, setCookie, removeCookie] = useCookies<
    typeof SIGN_UP_CREATED_USER,
    { [SIGN_UP_CREATED_USER]?: UserSessionStorage }
  >([SIGN_UP_CREATED_USER]);

  const clearUserSession = useCallback(() => removeCookie(SIGN_UP_CREATED_USER), [removeCookie]);

  const retrieveUserSession = useCallback(() => cookies[SIGN_UP_CREATED_USER], [cookies]);

  const storeUserSession = useCallback(
    (session: UserSessionStorage, dateOfBirthValue?: string) => {
      const massageClientProfiles = (data: clientProfilesInterface[]) => {
        const massageClientList: clientProfilesInterface[] = [];
        for (let obj of data) {
          massageClientList.push({
            ...obj,
            dateOfBirth: dateOfBirthValue
          });
        }
        return massageClientList;
      };

      const massageSessionData: UserSessionStorage = {
        ...session,
        clientRecord: {
          ...session.clientRecord,
          clientProfiles: massageClientProfiles(session.clientRecord.clientProfiles)
        }
      };

      setCookie(SIGN_UP_CREATED_USER, massageSessionData, { maxAge: 3600, path: '/' });
    },
    [setCookie]
  );

  return {
    clearUserSession,
    retrieveUserSession,
    storeUserSession
  };
};
