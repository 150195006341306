import * as Yup from 'yup';

export const EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS = [
  'Partner',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Parent',
  'Friend',
  'Other Family Member',
  'Other'
];

export const initialContact = {
  firstName: '',
  lastName: '',
  relationship: '',
  mobileNumber: ''
};

export const formDataValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter contact first name'),
  lastName: Yup.string().required('Please enter contact last name'),
  relationship: Yup.string().required('Please select relationship').oneOf(EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS),
  mobileNumber: Yup.string().required('Please enter mobile number')
});
