import { useNavigate } from 'react-router-dom';
import styles from './TaskButton.module.scss';

interface TaskButtonProps {
  item: { name: string | React.ReactNode; url: string; isExternal: boolean };
}

const TaskButton = ({ item }: TaskButtonProps) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    const { url, isExternal } = item;
    if (isExternal) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <div className={styles.taskButton} onClick={navigateTo}>
      <div className={styles.text}>{item.name}</div>
      <div className={`material-icons ${styles.arrowIcon}`}>arrow_forward</div>
    </div>
  );
};

export default TaskButton;
