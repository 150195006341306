import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './PsychologistDirectory.module.scss';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SHSearchBar from 'SomeoneHealth/components/SHSearchBar/SHSearchBar';
import { useEffect, useMemo, useState } from 'react';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SHAlphabetFilter from 'SomeoneHealth/components/SHAlphabetFilter/SHAlphabetFilter';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import queryString from 'query-string';
import SomeoneHealthFooter from 'SomeoneHealth/components/SomeoneHealthFooter/SomeoneHealthFooter';
import SomeoneHealthFindRightFit from 'SomeoneHealth/components/SomeoneHealthFindRightFit/SomeoneHealthFindRightFit';
import PsychologistAlphabetList from './components/PsychologistAlphabetList/PsychologistAlphabetList';
import { useFetchPsychologistsMinified } from './hooks/useFetchPsychologistsMinified';
import { Skeleton } from 'antd';

const Alphabets = new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i));

const PsychologistDirectory = () => {
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const { filter } = useParams<{ filter: string }>();
  const { search } = queryString.parse(queryParam);

  const [searchValue, setSearchValue] = useState<string>((search as string) || '');
  const [selectedAlphabetFilter, setSelectedAlphabetFilter] = useState<string>(filter?.toUpperCase() || '');

  const { isLoading, psychologistsMinified } = useFetchPsychologistsMinified();

  useEffect(() => {
    setSearchValue((search as string) || '');
    setSelectedAlphabetFilter(filter?.toUpperCase() || '');
  }, [search, filter]);

  const onChangeSearch = (value: string) => {
    setSearchValue(value);
    navigate(`${PRACTITIONER.DIRECTORY}${value ? `?search=${value}` : ''} `);

    if (selectedAlphabetFilter) {
      setSelectedAlphabetFilter('');
    }
  };

  const onChangeAlphabetFilter = (value: string) => {
    setSelectedAlphabetFilter(value);
    navigate(`${PRACTITIONER.DIRECTORY}/${value.toLowerCase()}`);

    if (searchValue) {
      setSearchValue('');
    }
  };

  const { availablePsychologists } = useMemo(() => {
    const result = new Map();
    Alphabets.forEach((char) => {
      const psychologistsHasNameStartWithChar = psychologistsMinified.filter((psychologist) =>
        psychologist.name.startsWith(char)
      );
      if (psychologistsHasNameStartWithChar.length) {
        result.set(char, psychologistsHasNameStartWithChar);
      }
    });
    return { availablePsychologists: result };
  }, [psychologistsMinified]);

  return (
    <SomeoneHealthHelmetWrapper title="someone.health - Talk to someone about your mental health today">
      <div className={styles.headerContainer}>
        <SomeoneHealthHeader
          withPadding
          whiteFont
          withMenu
          classNames={styles.header}
          showDirectorySwitch
          isDirectory
        />

        {/* Search bar */}
        <SomeoneHealthContentLayout className={styles.searchBar}>
          <SHSearchBar showIcon label="SEARCH BY PSYCHOLOGIST NAME" value={searchValue} onChange={onChangeSearch} />
        </SomeoneHealthContentLayout>

        {/* Alphabet bar */}
        <div className={styles.alphabetFilterBarContainer}>
          <SomeoneHealthContentLayout className={styles.alphabetFilterBar}>
            <SHAlphabetFilter
              label="BROWSE"
              subLabel="By Psychologist first name"
              showClearButton
              value={selectedAlphabetFilter}
              onChange={onChangeAlphabetFilter}
              availableOptions={Array.from(availablePsychologists.keys())}
            />
          </SomeoneHealthContentLayout>
        </div>
      </div>

      <SomeoneHealthContentLayout className={styles.result}>
        {isLoading ? (
          <div className={styles.loadingWrapper}>
            {[...Array(10)].map((_, i) => (
              <div key={i} className={styles.loadingItem}>
                <Skeleton.Input active className={styles.loadingContent} />
              </div>
            ))}
          </div>
        ) : (
          <PsychologistAlphabetList
            searchValue={searchValue}
            selectedAlphabetFilter={selectedAlphabetFilter}
            availablePsychologists={availablePsychologists}
          />
        )}
      </SomeoneHealthContentLayout>

      <SomeoneHealthContentLayout className={styles.landingContent}>
        <SomeoneHealthFindRightFit />
      </SomeoneHealthContentLayout>
      <SomeoneHealthFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default PsychologistDirectory;
