import classnames from 'classnames';
import FilterDropdown, { listInterface } from 'components/FilterDropdown/FilterDropdown';
import FilterDropdownMultiSelect from 'components/FilterDropdown/components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import { generateAgeRangeList, generateSomeoneHealthMentalHealthList } from '../../utils/generateListingFilters';
import styles from './FilterDropdownWrapper.module.scss';

const MY_SITUATION_OPTIONS = generateSomeoneHealthMentalHealthList();

interface FilterDropdownWrapperProps {
  classNames?: string;
  mentalHealthConcernValue?: listInterface[];
  onChangeMentalHealthConcernValue: (value: listInterface) => void;
  ageRangeValue?: listInterface;
  onChangeAgeRangeValue: (value: listInterface | undefined) => void;
  handleResetValue?: () => void;
  isLoading?: boolean;
}

const FilterDropdownWrapper = ({
  classNames,
  mentalHealthConcernValue,
  onChangeMentalHealthConcernValue,
  ageRangeValue,
  onChangeAgeRangeValue,
  handleResetValue,
  isLoading
}: FilterDropdownWrapperProps) => {
  return (
    <div className={classnames(styles.container, classNames)}>
      <FilterDropdownMultiSelect
        id={'mentalValue'}
        label="My situation"
        listData={MY_SITUATION_OPTIONS}
        onChangeValue={onChangeMentalHealthConcernValue}
        selectedMultipleValue={mentalHealthConcernValue || []}
        className={styles.mentalHealthConcern}
        withOtherSection
        withSearch
        ableToSearchAll
        disabled={isLoading}
        otherSectionLabel="Have a more specific concern, please check our detailed condition list"
      />
      <div className={styles.secondLayerFilterWrapper}>
        <FilterDropdown
          label={'My age is'}
          listData={generateAgeRangeList()}
          onChangeValue={onChangeAgeRangeValue}
          selectedValue={ageRangeValue}
          className={styles.ageRange}
          disabled={isLoading}
        />
        {handleResetValue && (
          <div
            className={classnames(styles.reset, isLoading && styles.disabled)}
            onClick={() => !isLoading && handleResetValue()}
          >
            Reset
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterDropdownWrapper;
