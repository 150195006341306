import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getPsychologistsMinified } from 'SomeoneHealth/utils/http/ClinicianProfileService/Someone-Health/someoneHealth';
import queryString from 'query-string';

export interface PsychologistMinified {
  _id: string;
  avatar: string;
  name: string;
  title: string;
  slugUrl: string;
}

interface PsychologistMinifiedResponse {
  clinicians: PsychologistMinified[];
  paging: {
    page: number;
    perPage: number;
    totalItems: number;
  };
}

export const useFetchPsychologistsMinified = (searchValue?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [psychologistsMinified, setPsychologistsMinified] = useState<PsychologistMinified[]>([]);

  const fetchPsychologistsMinified = useCallback(async () => {
    const queryParam = {
      ...(searchValue && {
        searchValue
      }),
      includeUnlistedProfiles: -1,
      excludeGP: 1,
      status: 'active'
    };
    try {
      setIsLoading(true);
      const response: PsychologistMinifiedResponse = await (
        await getPsychologistsMinified(queryString.stringify(queryParam))
      ).json();

      // There are cases where the name has a space at the beginning
      const sortedData = response.clinicians
        .map((clinician) => ({ ...clinician, name: clinician.name.trim() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      return setPsychologistsMinified(sortedData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get Psychologists minified' });
    } finally {
      setIsLoading(false);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchPsychologistsMinified();
  }, [fetchPsychologistsMinified]);

  return { isLoading, psychologistsMinified };
};
