import { CircleFlag } from 'react-circle-flags';
import styles from './CountryCodeDetails.module.scss';
import { CountryCodeOptionInterface } from '../../CountryCodeSelect';
import classNames from 'classnames';

interface CountryCodeDetailsProps {
  data: CountryCodeOptionInterface;
  alignRight?: boolean;
}

const CountryCodeDetails = ({ data, alignRight }: CountryCodeDetailsProps) => {
  return (
    <div className={classNames(styles.container, alignRight && styles.flexEnd)}>
      <CircleFlag countryCode={data.value.toLowerCase()} height={16} />
      <span className={styles.label}>{data.label}</span>
    </div>
  );
};

export default CountryCodeDetails;
