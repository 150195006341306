import styles from './EaseLoginWrapper.module.scss';

interface EaseLoginWrapperProps {
  children?: any;
}

const EaseLoginWrapper = ({ children }: EaseLoginWrapperProps) => (
  <div className={styles.container}>
    <div className={styles.background1} />
    <div className={styles.background2} />
    <div className={styles.content}>{children}</div>
  </div>
);

export default EaseLoginWrapper;
