import styles from './PractitionerAvatar.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface PractitionerAvatarProps {
  profileImg: string;
  shapeType?: string;
  className?: string;
  classNameImage?: string;
  detailPath: string;
}

const PractitionerAvatar = ({
  profileImg,
  shapeType,
  className,
  classNameImage,
  detailPath
}: PractitionerAvatarProps) => {
  return (
    <div className={classnames(styles.container, className && className)}>
      <div className={classnames(styles.mask, shapeType && styles[`maskShape${shapeType}`])}>
        <Link to={detailPath}>
          <img
            className={classnames(styles.image, classNameImage && classNameImage)}
            src={profileImg}
            alt={'profile'}
          />
        </Link>
      </div>
    </div>
  );
};

export default PractitionerAvatar;
