import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import styles from './CordsSurveyQuestion.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';

const CordsSurveyQuestion = () => {
  return (
    <CORDSContentWrapper title={'CORDS - Survey Question'} fullwidth contentClassName={styles.container}>
      <div className={styles.content}>
        <SurveyQuestion />
      </div>
    </CORDSContentWrapper>
  );
};

export default CordsSurveyQuestion;
