import { useState, useEffect, useCallback } from 'react';
import { getPsychometricList } from 'utils/http/CheckInService/Psychometrics/psychometrics';

interface PsychometricListInterface {
  _id: string;
  clinicalAssessmentIds: string[];
  clinicianAuth0Id: string;
}

export const useFetchPsychometricList = (token: string) => {
  const [psychometricList, setPsychometricList] = useState<PsychometricListInterface[]>([]);
  const [isPsychometricListLoading, setIsPsychometricListLoading] = useState(false);

  const fetchPsychometricList = useCallback(async (token: string) => {
    setIsPsychometricListLoading(true);
    try {
      const response = await (await getPsychometricList(token)).json();
      setPsychometricList(response);
      setIsPsychometricListLoading(false);
    } catch (ex) {
      setIsPsychometricListLoading(false);
      console.error(ex);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchPsychometricList(token);
    }
  }, [token, fetchPsychometricList]);

  return { psychometricList, isPsychometricListLoading };
};
