import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';

import styles from './PractitionerQuote.module.scss';

interface PractitionerQuoteProps {
  quote: string;
}

const PractitionerQuote = ({ quote }: PractitionerQuoteProps) => (
  <div className={styles.container}>
    <HelmContentLayout>
      <div className={styles.quoteContent}>
        <div className={styles.quote}>
          <div className={styles.commaContainer}>
            <svg className={styles.comma} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.95397 29.0781C4.48774 29.0781 0.53771 24.5663 0.541946 17.8899C0.573229 8.18913 7.87631 1.32563 18.4119 0.00754166C19.3881 -0.113026 19.7629 1.24123 18.8665 1.63747C14.8226 3.43685 12.7808 5.71295 12.5178 7.96657C12.3213 9.6545 13.2353 11.1287 14.3902 11.405C17.3731 12.1219 19.3702 15.8321 19.3702 19.6713C19.3702 24.8645 15.1572 29.0781 9.95397 29.0781Z"
                fill="#f9e048"
              />
            </svg>
            <svg className={styles.comma} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.95397 29.0781C4.48774 29.0781 0.53771 24.5663 0.541946 17.8899C0.573229 8.18913 7.87631 1.32563 18.4119 0.00754166C19.3881 -0.113026 19.7629 1.24123 18.8665 1.63747C14.8226 3.43685 12.7808 5.71295 12.5178 7.96657C12.3213 9.6545 13.2353 11.1287 14.3902 11.405C17.3731 12.1219 19.3702 15.8321 19.3702 19.6713C19.3702 24.8645 15.1572 29.0781 9.95397 29.0781Z"
                fill="#f9e048"
              />
            </svg>
          </div>
          {quote}
          <div className={styles.endCommaContainer}>
            <svg className={styles.comma} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.4191 0.453125C15.8853 0.453125 19.8353 4.96493 19.8311 11.6414C19.7998 21.3421 12.4967 28.2056 1.96116 29.5237C0.984898 29.6443 0.610164 28.29 1.50659 27.8938C5.55047 26.0944 7.59228 23.8183 7.85524 21.5647C8.05174 19.8767 7.13771 18.4026 5.98288 18.1262C2.99999 17.4094 1.00282 13.6992 1.00282 9.85993C1.00282 4.66677 5.21581 0.453125 10.4191 0.453125Z"
                fill="#f9e048"
              />
            </svg>
            <svg className={styles.comma} viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.4191 0.453125C15.8853 0.453125 19.8353 4.96493 19.8311 11.6414C19.7998 21.3421 12.4967 28.2056 1.96116 29.5237C0.984898 29.6443 0.610164 28.29 1.50659 27.8938C5.55047 26.0944 7.59228 23.8183 7.85524 21.5647C8.05174 19.8767 7.13771 18.4026 5.98288 18.1262C2.99999 17.4094 1.00282 13.6992 1.00282 9.85993C1.00282 4.66677 5.21581 0.453125 10.4191 0.453125Z"
                fill="#f9e048"
              />
            </svg>
          </div>
        </div>
      </div>
    </HelmContentLayout>
  </div>
);

export default PractitionerQuote;
