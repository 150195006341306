import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

import { ClinicalAssessmentResult } from '../../PatientQuestionForm';

import { Clinician } from 'utils/hooks/clinician';

import Button from 'components/Button/Button';
import PizzaChart from './components/PizzaChart1/PizzaChart1';

import styles from './ClinicalAssessmentResults.module.scss';
import ClientProfileAvatar from '../../../ClientProfileAvatar/ClientProfileAvatar';
import { GetAttachedClinicianDetails } from '../../../../interfaces/Clinician/clinicianDetails';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';

interface ClinicalAssessmentResultsProps {
  clinicalAssessmentResults: ClinicalAssessmentResult[];
  clinician?: Clinician | GetAttachedClinicianDetails['clinician'];
  clinicianName: string;
  defaultPatientDetails?: { name: string; picture: string };
  onUpdateStatusDone: () => void;
}

const ClinicalAssessmentResults = ({
  clinicalAssessmentResults,
  clinicianName,
  defaultPatientDetails,
  onUpdateStatusDone
}: ClinicalAssessmentResultsProps) => {
  const { user } = useAuth0();

  const date = useMemo(() => {
    const today = moment();

    const date = today.format('D');
    const month = today.format('MMMM').substring(0, 3).toUpperCase();

    return `${date} ${month}`;
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.top}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <div>{user?.name || defaultPatientDetails?.name}</div>
              <div>{date}</div>
            </div>
            <div className={styles.profilePic}>
              <ClientProfileAvatar avatarUrl={user?.picture || defaultPatientDetails?.picture} initialsName={''} />
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.complete}>
            <span>COMPLETE</span>
          </div>
        </div>
      </div>
      <div className={styles.heading}>Based on your answers,</div>
      <div className={styles.description}>This is the score based on your symptoms</div>
      {clinicalAssessmentResults.map((clinicalAssessmentResult, index) => (
        <div className={styles.clinicalAssessmentResult} key={index}>
          <div className={styles.clinicalAssessmentTitle}>{clinicalAssessmentResult.title}</div>
          {clinicalAssessmentResult.charts.map(
            (chart, index) => index === 0 && <PizzaChart key={index} chart={chart} />
          )}
        </div>
      ))}
      <div className={styles.heading}>
        {clinicalAssessmentResults[0].charts[0].label === 'None' ? (
          <>Please reach out to {clinicianName} if you want to discuss this in more detail.</>
        ) : (
          <>{clinicianName} will be in touch to go through everything in details and next steps</>
        )}
      </div>
      {IS_SOMEONE_HEALTH_APP ? (
        <ButtonSH className={styles.button} onClick={onUpdateStatusDone}>
          Done
        </ButtonSH>
      ) : (
        <Button className={styles.button} variant="primary" onClick={onUpdateStatusDone}>
          Done
        </Button>
      )}
    </div>
  );
};

export default ClinicalAssessmentResults;
