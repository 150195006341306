import { BentResponse } from 'bent';
import { httpClient } from '../../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const postStripePaymentMethod = ({
  token,
  payload
}: {
  token: string;
  payload: { returnUrl: string; cancelUrl: string };
}) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/client/me/stripe/payment-method`,
    payload
  ) as Promise<BentResponse>;
