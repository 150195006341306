import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import SomeoneHealthRoutes from './SomeoneHealthRoutes';

const SomeoneHealthRoutesCollection = () => (
  <Routes>
    <Route path={'/facilitated/*'} element={<FacilitatedRoutes />} />
    <Route path={'/*'} element={<SomeoneHealthRoutes />} />
  </Routes>
);

export default SomeoneHealthRoutesCollection;
