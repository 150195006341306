import { ChangeEvent, KeyboardEvent } from 'react';
import classnames from 'classnames';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Input from 'components/Input/Input';

import styles from './TwoFAInput.module.scss';

interface TwoFAInputProps {
  error: string;
  value: [string, string, string, string, string, string];
  isTouched: boolean;
  isVerified?: boolean;
  isVerifying?: boolean;
  isText?: boolean;
  onChangeValue: (value: [string, string, string, string, string, string]) => void;
  onSubmitOtp?: (otp: string) => void;
  verifyingClassName?: string;
  verifiedClassName?: string;
  inputContainerClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string;
}

const TwoFAInput = ({
  error,
  value,
  isTouched,
  isVerified,
  isVerifying,
  isText,
  onChangeValue,
  onSubmitOtp,
  verifyingClassName,
  verifiedClassName,
  inputContainerClassName,
  inputWrapperClassName,
  inputClassName,
  placeholder
}: TwoFAInputProps) => {
  const onChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value?.toUpperCase();

    if (!isText && inputValue && isNaN(Number(inputValue))) {
      return;
    }

    if (inputValue.length === 6) {
      onChangeValue(inputValue.split('') as [string, string, string, string, string, string]);

      document.getElementById('2fa-5')?.focus();

      onSubmitOtp && onSubmitOtp(inputValue);
    } else {
      const actualInputValue = inputValue.charAt(inputValue.length - 1);

      const newValue = [...value];
      newValue.splice(index, 1, actualInputValue);
      onChangeValue(newValue as [string, string, string, string, string, string]);

      if (actualInputValue) {
        document.getElementById(`2fa-${index + 1}`)?.focus();
      }

      const mfaCodeValue = newValue.join('');
      if (mfaCodeValue.length === 6) {
        onSubmitOtp && onSubmitOtp(mfaCodeValue);
      }
    }
  };

  const onKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && value[index] === '') {
      e.preventDefault();

      document.getElementById(`2fa-${index - 1}`)?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={classnames(
          styles.inputContainer,
          inputContainerClassName,
          isVerifying && (verifyingClassName || styles.verifying),
          isVerified && (verifiedClassName || styles.verified),
          isTouched && !!error && styles.errored
        )}
      >
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-0"
          value={value[0]}
          onKeyDown={onKeyDown(0)}
          onChange={onChange(0)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-1"
          value={value[1]}
          onKeyDown={onKeyDown(1)}
          onChange={onChange(1)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-2"
          value={value[2]}
          onKeyDown={onKeyDown(2)}
          onChange={onChange(2)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-3"
          value={value[3]}
          onKeyDown={onKeyDown(3)}
          onChange={onChange(3)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-4"
          value={value[4]}
          onKeyDown={onKeyDown(4)}
          onChange={onChange(4)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
        <Input
          type={isText ? 'text' : 'tel'}
          id="2fa-5"
          value={value[5]}
          onKeyDown={onKeyDown(5)}
          onChange={onChange(5)}
          hasError={isTouched && !!error}
          disabled={isVerifying}
          noSpacing
          className={inputWrapperClassName}
          inputClass={inputClassName}
          placeholder={placeholder}
          autoComplete={'off'}
        />
      </div>
      <ErrorMessage error={error} visible={isTouched} />
    </div>
  );
};

export default TwoFAInput;
