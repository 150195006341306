import { Link, useParams } from 'react-router-dom';

import clockImg from 'assets/images/clockImg.png';

import { Clinician } from 'utils/hooks/clinician';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';

import styles from './AvailabilityNotAvailable.module.scss';

interface AvailabilityNotAvailableProps {
  clinician?: Clinician;
  practiceProfile?: PublicPracticeProfile;
  isNewClient?: boolean;
}

const AvailabilityNotAvailable = ({ clinician, practiceProfile, isNewClient }: AvailabilityNotAvailableProps) => {
  const { slugUrl = '' } = useParams<{ slugUrl: string }>();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={clockImg} alt={'clockImg'} />
        </div>
        <div className={styles.descWrapper}>
          <div className={styles.text}>
            Sorry. {clinician ? `${clinician.title} ${clinician.name}` : `${practiceProfile?.name}`} doesn’t have any
            availability matching your requirements.
          </div>
          <div className={styles.text}>
            You can look for alternative sessions or practitioners. Or send a message to make a request.
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Link
          className={styles.button}
          to={{
            pathname:
              clinician && clinician._id ? `/${clinician._id}/appointment/request` : `/p/${slugUrl}/appointment/request`
          }}
          state={{ practiceProfile, isNewClient, clinician }}
        >
          <i className={`material-icons ${styles.icon}`}>send</i>
          Create appointment request
        </Link>
      </div>
    </div>
  );
};

export default AvailabilityNotAvailable;
