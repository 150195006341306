import styles from './AvailabilityPill.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';

export interface AvailabilityPillProps {
  children: ReactNode;
  classNames?: string;
  darkYellowPill?: boolean;
  disabled?: boolean;
}

const AvailabilityPill = ({ children, classNames, darkYellowPill, disabled }: AvailabilityPillProps) => {
  return (
    <button
      className={classnames(
        styles.container,
        darkYellowPill && styles.darkYellowContainer,
        disabled && styles.disabled,
        classNames
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default AvailabilityPill;
