import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import styles from './SectionLoader.module.scss';

interface SectionLoaderProps {
  variant?: 'dot';
}

const SectionLoader = ({ variant }: SectionLoaderProps) => {
  return <div className={styles.container}>{variant === 'dot' ? <LoadingDot /> : <LoadingCircle />}</div>;
};

export default SectionLoader;
