import { combineReducers } from 'redux';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import gpBookingSlice from './globalStore/gpBookingSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';

const rootReducer = combineReducers({
  gpBookingSlice: gpBookingSlice,
  [clinicianProfileServicesApiSlice.reducerPath]: clinicianProfileServicesApiSlice.reducer,
  [scheduleServicesApiSlice.reducerPath]: scheduleServicesApiSlice.reducer,
  [checkInServicesApiSlice.reducerPath]: checkInServicesApiSlice.reducer
});

export default rootReducer;
