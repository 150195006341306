import { setResourceTerminology } from 'i18n/resources/en/terminology';
import { ClinicianAccountSettings } from 'interfaces/Clinician/clinicianDetails';
import { CustomFeature } from 'interfaces/Practice/practice';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getClinician } from 'utils/http/ClinicianProfileService/Clinician/clinician';

export interface Clinician {
  _id: string;
  accountId: string;
  avatar: string;
  bio: string;
  name: string;
  title: string;
  specialisations: [string];
  slugUrl?: string;
  practice?: {
    name: string;
    mobileNumber: string;
    locations: string[];
    logo: string;
    slugUrl: string;
    welcomeMessage?: string;
  };
  accountSettings?: ClinicianAccountSettings;
  practices: {
    name: string;
    mobileNumber: string;
    streetAddress: string;
    postcode: string;
    logo: string;
  }[];
  customisations?: Record<string, any>;
  edge: boolean;
  workTimeZone?: string;

  // from account
  customFeatures?: CustomFeature[];
}

export const useFetchClinician = (clinicianId: string, isPracticeOrNewClient?: boolean) => {
  const [clinician, setClinician] = useState<Clinician>({} as Clinician);
  const [isClinicianLoading, setIsClinicianLoading] = useState(true);

  const navigate = useNavigate();
  const [, i18n] = useTranslation();

  const fetchClinician = async () => {
    try {
      const result = await getClinician(clinicianId);

      const data = (await result.json()) as Clinician;

      setResourceTerminology(i18n, data.accountSettings);

      setClinician(data);
      setIsClinicianLoading(false);
    } catch (ex) {
      console.error(`User redirected from ${window.location.pathname} to /404`);
      navigate('/404');
    }
  };

  useEffect(() => {
    if (!isPracticeOrNewClient) {
      fetchClinician();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicianId]);

  return { clinician, isClinicianLoading };
};
