import styles from './PsychologistAvailabilityCalendar.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AvailabilityPill from 'SomeoneHealth/components/AvailabilityPill/AvailabilityPill';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { AppointmentType, AppointmentTypeList } from 'SomeoneHealth/interfaces/mentalHealth';
import ReactTooltip from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { isNoSlotForNewClient } from '../../utils/utils';

interface PractitionerAvailabilityCalendarProps {
  practitionerDetails: PractitionerDetailsInterface;
  nextAvailabilityDetails: PractitionerDetailsInterface['nextAvailabilities'];
  detailPath: string;
  onShowWaitlistForm: () => void;
  selectedSessionType?: AppointmentType;
  isDarkYellowPill: boolean;
  noFilter?: boolean;
}

const todayDate = moment();

const PsychologistAvailabilityCalendar = ({
  practitionerDetails,
  nextAvailabilityDetails,
  detailPath,
  onShowWaitlistForm,
  selectedSessionType,
  isDarkYellowPill,
  noFilter
}: PractitionerAvailabilityCalendarProps) => {
  const { isAuthenticated } = useAuth0();
  const selectedAppointmentTypeName =
    (selectedSessionType &&
      AppointmentTypeList.find((obj) => encodeURIComponent(obj.id) === encodeURIComponent(selectedSessionType))
        ?.label) ||
    AppointmentTypeList[0].label;

  const massageAvailabilityData = noFilter
    ? nextAvailabilityDetails
    : nextAvailabilityDetails?.filter(
        (obj) =>
          encodeURIComponent(obj.appointmentTypeName?.toLowerCase()) ===
          encodeURIComponent(selectedAppointmentTypeName.toLowerCase())
      );

  const noSlotForNewClient = isNoSlotForNewClient(
    isAuthenticated,
    practitionerDetails.helmControl?.shouldUseCaseLoad,
    practitionerDetails.caseLoad?.remainingSlots
  );

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        LIVE AVAILABILITY
        <i data-tip data-for={practitionerDetails._id} className={`material-icons-outlined ${styles.helpIcon}`}>
          help_outline
        </i>
        <ReactTooltip id={practitionerDetails._id} className={styles.tooltip}>
          someone.health shows real-time availability for our psychologists. Know exactly who you will see, and when.
        </ReactTooltip>
      </div>
      <div className={styles.calendarListWrapper}>
        <div className={styles.calendarContent}>
          {noSlotForNewClient ? (
            <div className={isDarkYellowPill ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
              <div className={styles.noAvailableCard}>
                <div className={styles.messageWrapper}>
                  <div className={styles.redDotWrapper}>
                    <div className={styles.redDot} />
                  </div>
                  <div className={styles.message}>
                    <div className={styles.desc}>Sorry...</div>
                    <div className={styles.noAvailDesc}>
                      I am not available to work with new clients at this time. <br />
                      If you'd like to join my waitlist should a spot open up please add your details here
                    </div>
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  <ButtonSH
                    icon={'post_add'}
                    iconPostFix
                    variant={'outlined'}
                    onClick={onShowWaitlistForm}
                  >{`Join ${practitionerDetails.name} Waitlist`}</ButtonSH>
                </div>
              </div>
            </div>
          ) : massageAvailabilityData &&
            massageAvailabilityData.length &&
            massageAvailabilityData.some(({ availabilities }) => availabilities.length) ? (
            <>
              {massageAvailabilityData?.map((nextAvailabilityObj, index) => (
                <div className={styles.calendarCardWrapper} key={index}>
                  {nextAvailabilityObj.availabilities.slice(0, 2).map((availabilitiesObj, index) => {
                    const availabilityDate = moment(availabilitiesObj.date);
                    const dayDifference = Math.floor(availabilityDate.diff(todayDate, 'days', true)) + 1;

                    const massageAvailabilityTimeSlot =
                      dayDifference <= 0
                        ? availabilitiesObj.timeSlots.filter((obj) =>
                            moment(obj.startTime, 'hh:mma').isAfter(todayDate)
                          )
                        : availabilitiesObj.timeSlots;

                    return (
                      massageAvailabilityTimeSlot.length > 0 && (
                        <div
                          className={isDarkYellowPill ? styles.calendarCardOdd : styles.calendarCardEven}
                          key={index}
                        >
                          <div className={styles.cardContent}>
                            <div className={styles.calendarInfo}>
                              <div className={styles.greenDot} />
                              <div className={styles.calendarDateInfo}>
                                <div className={styles.date}>{moment(availabilitiesObj.date).format('DD')}</div>
                                <div className={styles.month}>{moment(availabilitiesObj.date).format('MMM')}</div>
                              </div>
                              <div className={styles.calendarDateInfo}>
                                <div className={styles.day}>{moment(availabilitiesObj.date).format('dddd')}</div>
                                <div className={styles.openAppointment}>
                                  {massageAvailabilityTimeSlot.length || 0} open appointments
                                </div>
                              </div>
                            </div>
                            <div className={styles.timeslotWrapper}>
                              {massageAvailabilityTimeSlot.map((timeSlotObj, index) => {
                                const startTime = moment(timeSlotObj.startTime, 'hh:mmA').format('h:mm');
                                const endTime = moment(timeSlotObj.endTime, 'hh:mmA').format('h:mmA');
                                const selectedDateTime = `selectedDateTime=${availabilitiesObj.date},${timeSlotObj.startTime},${timeSlotObj.endTime}`;
                                const newDetailPath = `${detailPath}?${selectedDateTime}`;

                                return index <= 2 ? (
                                  <Link to={newDetailPath} key={index}>
                                    <AvailabilityPill classNames={styles.timeslot} darkYellowPill={isDarkYellowPill}>
                                      {startTime} to {endTime}
                                    </AvailabilityPill>
                                  </Link>
                                ) : index === 3 ? (
                                  <Link to={detailPath} key={index} className={styles.seeMoreSlot}>
                                    ...+{availabilitiesObj.timeSlots.length - 3} MORE TIMES
                                  </Link>
                                ) : null;
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
            </>
          ) : (
            <div className={isDarkYellowPill ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
              <div className={styles.noAvailableCard}>
                <div className={styles.messageWrapper}>
                  <div className={styles.redDotWrapper}>
                    <div className={styles.redDot} />
                  </div>
                  <div className={styles.message}>
                    <div className={styles.desc}>Sorry...</div>
                    <div className={styles.noAvailDesc}>
                      My time for&nbsp;<strong>next 4 weeks</strong>&nbsp;is fully booked.
                    </div>
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  <ButtonSH
                    icon={'post_add'}
                    iconPostFix
                    variant={'outlined'}
                    onClick={onShowWaitlistForm}
                  >{`Join ${practitionerDetails.name} Waitlist`}</ButtonSH>
                </div>
              </div>
            </div>
          )}
          <ButtonSH icon="navigate_next" className={styles.moreAvailableBtn} iconPostFix to={detailPath}>
            See all {practitionerDetails.name} Availability
          </ButtonSH>
        </div>
      </div>
    </div>
  );
};

export default PsychologistAvailabilityCalendar;
