import { useRef, useState } from 'react';

import styles from './TimeZoneDropdown.module.scss';
import classNames from 'classnames';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

interface listInterface {
  id: string;
  label: string;
  desc?: string;
}

interface TimeZoneDropdownProps {
  listData: listInterface[];
  selectedValue: listInterface;
  onChangeValue: (val: listInterface) => void;
  whiteFont?: boolean;
  disabled?: boolean;
  selectClassName?: string;
  direction?: 'vertical';
  className?: string;
}

const TimeZoneDropdown = ({
  listData,
  selectedValue,
  onChangeValue,
  whiteFont = true,
  disabled,
  selectClassName,
  direction,
  className
}: TimeZoneDropdownProps) => {
  const timeZoneMenuRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const handleCloseMenu = () => {
    setShowList(false);
  };

  const handleSelectButtonClick = () => {
    if (disabled) {
      return;
    }

    if (showList) {
      handleCloseMenu();
    } else {
      setShowList(true);
    }
  };

  const clickOutsideHandler = () => {
    handleCloseMenu();
  };

  useOnClickOutside(timeZoneMenuRef, clickOutsideHandler);

  const handleChangeFilter = (val: listInterface) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  return (
    <div ref={timeZoneMenuRef} className={classNames(styles.container, className)}>
      <div
        className={classNames(
          styles.selectButton,
          selectClassName,
          disabled && styles.disabled,
          direction === 'vertical' && styles.vertical
        )}
        onClick={handleSelectButtonClick}
      >
        <div className={whiteFont ? styles.selectedValueWhite : styles.selectedValue}>
          Time in {selectedValue.label}
        </div>
        <div className={whiteFont ? styles.changeButtonWhite : styles.changeButton}>Change</div>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {listData.map((listObj, index) => (
            <div key={index} className={styles.listBox} onClick={() => handleChangeFilter(listObj)}>
              <div className={styles.title}>{listObj.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeZoneDropdown;
