import Button from 'components/v2/Button/Button';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import InsightsLineChart from 'pages/ReportViewPage/components/ReportDraftContent/components/ReportContentBody/components/WidgetComponents/ChartComponents/InsightsLineChart/InsightsLineChart';
import { useNavigate } from 'react-router-dom';

import { useFetchOverallCheckInGraph } from 'utils/hooks/microJournal/getMicroJournalOverallInsights';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './YourMoodTracking.module.scss';

const YourMoodTracking = () => {
  const navigate = useNavigate();
  const { token } = useGetAccessToken();
  const { MICRO_JOURNAL_INSIGHTS } = useHelmRoutesGenerator();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const clinicianSlugUrl = attachedClinicianDetail?.clinician?.slugUrl;
  const clinicianId = attachedClinicianDetail?.clinician?._id || clinicianSlugUrl || '';
  const { completedCheckInStatus: isAssignedMCI } = useFetchCompletedCheckInStatus(token, clinicianId);
  const { overallTitle, overallCheckInGraph, isOverallCheckInGraphLoading } = useFetchOverallCheckInGraph({
    token
  });

  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <div className={styles.title}>{overallTitle}</div>
        <div className={styles.graphContainer}>
          <InsightsLineChart
            data={overallCheckInGraph}
            yDomain={[1, 11]}
            yTicks={[1, 6, 11]}
            isLoading={isOverallCheckInGraphLoading}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {isAssignedMCI && clinicianSlugUrl && (
          <Button onClick={() => navigate(`/${clinicianSlugUrl}/checkIn`)}>
            New journal entry<i className={`material-icons-outlined`}>navigate_next</i>
          </Button>
        )}
        <Button className={styles.secondaryButton} variant="secondary" onClick={() => navigate(MICRO_JOURNAL_INSIGHTS)}>
          View trend<i className={`material-icons-outlined`}>navigate_next</i>
        </Button>
      </div>
    </div>
  );
};

export default YourMoodTracking;
