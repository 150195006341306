import classNames from 'classnames';
import styles from './StepTitle.module.scss';

interface StepTitleProps {
  number?: string;
  title: string;
  numberBubbleClassName?: string;
  titleClassName?: string;
}

const StepTitle = ({ number, title, numberBubbleClassName, titleClassName }: StepTitleProps) => (
  <div className={styles.container}>
    {number && <div className={classNames(styles.numberBubble, numberBubbleClassName)}>{number}</div>}
    <div className={classNames(styles.stepTitle, titleClassName)}>{title}</div>
  </div>
);

export default StepTitle;
