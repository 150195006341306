import { Helmet, HelmetProvider } from 'react-helmet-async';

interface EaseHelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favEase.ico`;

const EaseHelmetWrapper = ({ children, title }: EaseHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'Welcome to EASE Wellbeing talking therapies - We help clients live happier & healthier; providing confidential therapy, led by mental health experts across London.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default EaseHelmetWrapper;
