export const usePortobelloRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    SIGNUP: {
      BASE: `${initPath}/signup`,
      INFO_SETUP: `${initPath}/additional-info`,
      WELCOME: `${initPath}/welcome`
    },
    // others
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    REPORT: {
      VIEW: `${initPath}/report/:reportId`
    },
    LETTER: {
      VIEW: `${initPath}/accounts/:accountId/letters/:letterId`
    },
    AVATAR: `${initPath}/avatar`,
    UPDATE_PROFILE: `${initPath}/profile`,
    WAITLIST: `${initPath}/waitlist`,
    HOME: '/',
    PENDING_ACTIONS: `${initPath}/pending-actions`,
    APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`,
    MY_PSYCHOLOGIST: `${initPath}/my-psychologist`,
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`,
      DETAILS: `${initPath}/onboarding/:surveyId/:type`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    REFERRAL: `${initPath}/referral`,
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`,
    FORM: {
      BASE: `${initPath}/forms`,
      DETAILS: `${initPath}/forms/:formId`
    }
  };
};
