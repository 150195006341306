import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './DecoupleGPAddPaymentPage.module.scss';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import AddPayment from '../../GPAddPaymentPage/components/AddPayment/AddPayment';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { postStripePaymentMethod } from 'utils/http/BillingService/stripe';
import { retrieveUserSession } from '../../SignUp/helper/userSession';
import { useState } from 'react';
import { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';

const DecoupleGPAddPaymentPage = () => {
  const signedUpUser = retrieveUserSession();
  const { SIGNUP_DECOUPLE_GP } = useSomeoneHealthRoutesGenerator();
  const { token } = useGetAccessToken(false, true);

  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  const onAddPaymentDetails = async () => {
    setButtonStatus('active');
    try {
      const response = await postStripePaymentMethod({
        token: signedUpUser.authToken || token,
        payload: {
          returnUrl: `${window.location.origin}${SIGNUP_DECOUPLE_GP.WELCOME}`,
          cancelUrl: `${window.location.origin}${SIGNUP_DECOUPLE_GP.ADD_PAYMENT}`
        }
      });
      setButtonStatus('finished');
      window.location.href = await response.text();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to generate the link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Add payment'}>
      <div className={styles.pageContainer}>
        <SomeoneHealthHeader pinkLogo withPadding />
        <SomeoneHealthContentLayout className={styles.content}>
          <AddPayment
            buttonStatus={buttonStatus}
            name={signedUpUser.firstName || ''}
            onAddPaymentDetails={onAddPaymentDetails}
          />
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default DecoupleGPAddPaymentPage;
