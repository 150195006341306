import { Route, Routes } from 'react-router-dom';
import appRoute from '../components/AppRoute/AppRoute';
import Forbidden403 from '../pages/403/403';
import NoAuthLayout from '../layouts/NoAuthLayout/NoAuthLayout';
import NotFound404 from '../pages/404/404';
import Unverified from '../pages/Unverified/Unverified';
import { CLIENT_BRAND, ClientBrand } from '../interfaces/ClientBrand';
import HelmRoutes from './HelmRoutes';
import CORDSRoutes from './CORDSRoutes';
import SomeoneHealthRoutesCollection from './SomeoneHealthRoutes/SomeoneHealthRoutesCollection';
import CaWRoutesCollection from './CawRoutes/CaWRoutesCollection';
import AuthRoutes from './AuthRoutes';
import { security } from '../utils/security';
import { useGetAccessToken } from 'utils/hooks/token';
import SomeoneHealthPartnerRoutes from './SomeoneHealthPartnerRoutes/SomeoneHealthPartnerRoutes';
import HelmPartnerRoutes from './HelmPartnerRoutes/HelmPartnerRoutes';
import EaseRoutesCollection from './EaseRoutes/EaseRoutesCollection';
import RechargeRoutesCollection from './RechargeRoutes/RechargeCollection';
import SelectRoutesCollection from './SelectRoutes/SelectCollection';
import PortobelloRoutesCollection from './PortobelloRoutes/PortobelloCollection';

const RouteCollection = () => {
  const { fetchAccessTokenAndInfo } = useGetAccessToken(true, true);
  security.setAccessTokenSilently(fetchAccessTokenAndInfo);
  return (
    <Routes>
      {appRoute({ path: '/403', component: Forbidden403, layout: NoAuthLayout })}
      {appRoute({ path: '/404', component: NotFound404, layout: NoAuthLayout })}
      {appRoute({ path: '/unverified', component: Unverified, layout: NoAuthLayout })}
      {CLIENT_BRAND === ClientBrand.HELM ? (
        <Route path={'/*'} element={<HelmRoutes />} />
      ) : CLIENT_BRAND === ClientBrand.HELM_PARTNER ? (
        <Route path={'/*'} element={<HelmPartnerRoutes />} />
      ) : CLIENT_BRAND === ClientBrand.CORDS ? (
        <Route path={'/*'} element={<CORDSRoutes />} />
      ) : CLIENT_BRAND === ClientBrand.SOMEONE_HEALTH ? (
        <Route path={'/*'} element={<SomeoneHealthRoutesCollection />} />
      ) : CLIENT_BRAND === ClientBrand.CAW ? (
        <Route path={'/*'} element={<CaWRoutesCollection />} />
      ) : CLIENT_BRAND === ClientBrand.SOMEONE_HEALTH_PARTNER ? (
        <Route path={'/*'} element={<SomeoneHealthPartnerRoutes />} />
      ) : CLIENT_BRAND === ClientBrand.EASE ? (
        <Route path={'/*'} element={<EaseRoutesCollection />} />
      ) : CLIENT_BRAND === ClientBrand.RECHARGE ? (
        <Route path={'/*'} element={<RechargeRoutesCollection />} />
      ) : CLIENT_BRAND === ClientBrand.SELECT ? (
        <Route path={'/*'} element={<SelectRoutesCollection />} />
      ) : CLIENT_BRAND === ClientBrand.PORTOBELLO ? (
        <Route path={'/*'} element={<PortobelloRoutesCollection />} />
      ) : (
        <Route path={'/*'} element={<AuthRoutes />} />
      )}
    </Routes>
  );
};

export default RouteCollection;
