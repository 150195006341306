import IconButton from 'components/IconButton/IconButton';
import { ReportInterface } from 'interfaces/report';
import styles from './SignatureDisplay.module.scss';

export interface SignatureDisplayProps {
  signVal: ReportInterface['clinicianSignature'];
  editable: boolean;
  onEditClick: () => void;
  extraDetailsVal?: ReportInterface['clinicianSignatureExtraDetails'];
}

const SignatureDisplay = ({ signVal, editable, onEditClick, extraDetailsVal }: SignatureDisplayProps) => {
  return (
    <div className={styles.container}>
      {extraDetailsVal?.valediction && <div className={styles.signLabel}>{extraDetailsVal.valediction}</div>}

      <div className={styles.signatureContainer}>
        {editable && <IconButton iconName="edit" onClick={onEditClick} />}

        <div className={styles.signature}>
          {signVal.type === 'typeVer' ? (
            signVal.textVal
          ) : (
            <img className={styles.signatureImg} alt={'signature'} src={signVal.drawVal} />
          )}
        </div>
      </div>

      {extraDetailsVal && (
        <div className={styles.extraDetailsWrapper}>
          {extraDetailsVal.name && <div className={styles.extraDetails}>{extraDetailsVal.name}</div>}
          {extraDetailsVal.mobileNumber && <div className={styles.extraDetails}>{extraDetailsVal.mobileNumber}</div>}
          {extraDetailsVal.email && <div className={styles.extraDetails}>{extraDetailsVal.email}</div>}
          {extraDetailsVal.other && <div className={styles.extraDetails}>{extraDetailsVal.other}</div>}
        </div>
      )}
    </div>
  );
};

export default SignatureDisplay;
