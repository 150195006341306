/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';

import { Question } from 'interfaces/Assessment/OnboardingAssessment';

import FreeText from './components/FreeText/FreeText';
import MultipleAnswer from './components/MultipleAnswers/MultipleAnswers';
import MultipleChoice from './components/MultipleChoice/MultipleChoice';
import MultipleChoiceFreetext from './components/MultipleChoiceFreeText/MultipleChoiceFreeText';
import Numeric from './components/Numeric/Numeric';
import SelectOther from './components/SelectOther/SelectOther';
import Vote from './components/Vote/Vote';
import SimpleSlider from './components/SimpleSlider/SimpleSlider';

import styles from './Question.module.scss';
import classNames from 'classnames';
import ScaleWrapper from './components/ScaleWrapper/ScaleWrapper';
import PhoneNumber from './components/PhoneNumber/PhoneNumber';
import Email from './components/Email/Email';
import Date from './components/Date/Date';
import BarSlider from './components/BarSlider/BarSlider';
import HexSlider from './components/HexSlider/HexSlider';
import { CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrand';

interface QuestionComponentInterface {
  question: Question;
  questionId?: string;
  title?: string;
  reverseStemAndHideTitle?: boolean;
  defaultPatientDetails?: { name: string; picture: string };
}

const getInputComponent = (
  questionType: Question['questionType']
): ((props: {
  question: Question;
  defaultPatientDetails?: { name: string; picture: string };
  questionId?: string;
}) => JSX.Element) => {
  switch (questionType) {
    case 'barSlider':
      return BarSlider;
    case 'hexSlider':
      return HexSlider;
    case 'freeText':
    case 'longText':
      return FreeText;
    case 'multipleAnswers':
      return MultipleAnswer;
    case 'multipleChoice':
      return MultipleChoice;
    case 'multipleChoiceFreeText':
      return MultipleChoiceFreetext;
    case 'numeric':
      return Numeric;
    case 'selectOther':
      return SelectOther;
    case 'vote':
      return Vote;
    case 'simpleSlider':
      return SimpleSlider;
    case 'scale':
      return ScaleWrapper;
    case 'phone':
      return PhoneNumber;
    case 'email':
      return Email;
    case 'date':
      return Date;
    default:
      throw new Error(`Question type ${questionType} not supported`);
  }
};

const QuestionComponent = ({
  question,
  questionId,
  title,
  reverseStemAndHideTitle,
  defaultPatientDetails
}: QuestionComponentInterface) => {
  const { questionType, stem, stimulus } = question;

  const InputComponent = useMemo(() => getInputComponent(questionType), [questionType]);
  return (
    <div
      className={classNames(
        styles.container,
        styles[CLIENT_BRAND_LOWER_CASE],
        `${CLIENT_BRAND_LOWER_CASE}-assessment-question`
      )}
    >
      <div className={styles.title}>{reverseStemAndHideTitle && stem ? stem : stimulus ? stem : title}</div>
      {!reverseStemAndHideTitle && stimulus ? (
        <span className={styles.stem}>{stimulus}</span>
      ) : !reverseStemAndHideTitle ? (
        <span className={styles.stem}>{stem}</span>
      ) : null}
      <InputComponent question={question} questionId={questionId} defaultPatientDetails={defaultPatientDetails} />
    </div>
  );
};

export default QuestionComponent;
