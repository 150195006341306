export const useRoutesGenerator = (slugUrl?: string) => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    SIGNUP: {
      BASE: `${initPath}/${slugUrl}/signup`,
      TASK: `${initPath}/${slugUrl}/signup/task`,
      POLICY: `${initPath}/${slugUrl}/signup/policy`,
      NEW: `${initPath}/${slugUrl}/signup/new`,
      SUCCESS: `${initPath}/${slugUrl}/signup/success`
    },
    CONSENT: {
      BASE: '/consent'
    },
    ONBOARDING: {
      BASE: '/onboarding'
    },
    FACILITATED: {
      ONBOARDING: `/facilitated/onboarding`,
      CONSENT: `/facilitated/consent`
    },
    SURVEY: {
      BASE: '/surveys'
    },
    PSYCHOMETRIC: {
      BASE: '/psychometrics'
    },
    UPDATE_PROFILE: `${initPath}/profile`,
    APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`,
    HOME: initPath,
    SMP: {
      LIST: '/smp'
    }
  };
};
