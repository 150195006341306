import { notification } from 'antd';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import { getInvoice } from 'utils/http/invoice';
import InvoiceView from 'components/InvoiceView/InvoiceView';

import logoBlue from 'assets/images/tacklit-logo-blue.svg';
import logoWhite from 'assets/images/tacklit-logo-white.svg';
import styles from './InvoiceViewPage.module.scss';
import { Invoice } from 'interfaces/Invoice/Invoice';
import { setResourceTerminology } from 'i18n/resources/en/terminology';
import { useTranslation } from 'react-i18next';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import classNames from 'classnames';
import { IS_EASE_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import InvoiceViewWithTemplate from 'components/InvoiceViewWithTemplate/InvoiceViewWithTemplate';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';

export const useFetchInvoice = ({ invoiceIdArg, shouldPause }: { invoiceIdArg?: string; shouldPause?: boolean }) => {
  const [invoice, setInvoice] = useState<Invoice>();
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const { invoiceId: invoiceIdParam } = useParams() as { invoiceId: string };
  const invoiceId = invoiceIdArg ? invoiceIdArg : invoiceIdParam;
  const [, i18n] = useTranslation();

  const fetchInvoice = async () => {
    try {
      setIsInvoiceLoading(true);
      const invoiceResponse: Invoice = await (await getInvoice(invoiceId)).json();

      setResourceTerminology(i18n, invoiceResponse.accountSettings);
      setInvoice(invoiceResponse);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'There was an error getting this invoice.' });
    } finally {
      setIsInvoiceLoading(false);
    }
  };

  useEffect(() => {
    if (!shouldPause) {
      fetchInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, shouldPause]);

  return { invoice, isInvoiceLoading, fetchInvoice };
};

const InvoiceViewPage = () => {
  const location = useLocation();
  const { stripePaymentSuccess }: { stripePaymentSuccess?: string } = queryString.parse(location.search);
  const [isPausing, setIsPausing] = useState<boolean>(stripePaymentSuccess === '1');
  const { invoice, isInvoiceLoading, fetchInvoice } = useFetchInvoice({ shouldPause: isPausing });

  useEffect(() => {
    if (isPausing) {
      setTimeout(() => {
        setIsPausing(false);
      }, 5000);
    }
  }, [isPausing, stripePaymentSuccess]);

  const isStripeActive = !!invoice?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentType === 'stripe' && paymentMethod.stripeStatus === 'completed'
  );

  return !isPausing && !isInvoiceLoading && invoice?.invoiceId ? (
    <div
      className={classNames(
        styles.container,
        IS_EASE_APP && 'ease-theme',
        IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme'
      )}
    >
      <div className={styles.header} />
      {invoice.template?._id ? (
        <InvoiceViewWithTemplate invoice={invoice} isStripeActive={isStripeActive} refetchInvoice={fetchInvoice} />
      ) : (
        <InvoiceView invoice={invoice} isStripeActive={isStripeActive} refetchInvoice={fetchInvoice} />
      )}
      {IS_SOMEONE_HEALTH_APP ? (
        <TacklitFooter />
      ) : (
        <div className={styles.footer}>
          <img alt={'tacklit logo'} className={styles.logo} src={IS_EASE_APP ? logoBlue : logoWhite} />
          <span>
            Invoice powered by Tacklit.&nbsp;&nbsp;
            <strong>Received in error?</strong>
            &nbsp;&nbsp;
            <a href="https://www.tacklit.com/contact-us">Contact us</a>
          </span>
        </div>
      )}
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header} />
      <div className={styles.loadingContainer}>
        <LoadingDot />
      </div>
    </div>
  );
};

export default InvoiceViewPage;
