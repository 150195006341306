import { Helmet, HelmetProvider } from 'react-helmet-async';

interface HelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = '/favhelmicon.svg';

const HelmHelmetWrapper = ({ children, title }: HelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={'HELM - Behavioural Heath Specialists'} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default HelmHelmetWrapper;
