import { IS_RECHARGE_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { getBentJsonResponse } from 'utils/httpClient';
import { putMethod } from '../helper';

export interface ClaimReservationResponse {
  checkoutUrl?: string;
  isBookingRuleError?: boolean;
}

export enum CheckoutSessionMode {
  Payment = 'payment',
  Setup = 'setup'
}

export const claimReservedAppointments = async ({
  accountId,
  reservationId,
  authToken,
  cancelUrl,
  returnUrl,
  shouldGenerateCheckoutSession,
  voucherCode
}: {
  accountId: string;
  reservationId: string;
  authToken: string;
  cancelUrl?: string;
  returnUrl?: string;
  shouldGenerateCheckoutSession: boolean;
  voucherCode?: string;
}): Promise<{ statusCode: number; response: ClaimReservationResponse | null }> => {
  const httpResponse = await putMethod(authToken, [200, 403, 404, 500])(
    `/accounts/${accountId}/reserved-appointments/${reservationId}:claimReservation`,
    {
      returnUrl,
      cancelUrl,
      mode: IS_SOMEONE_HEALTH_APP || IS_RECHARGE_APP ? CheckoutSessionMode.Setup : CheckoutSessionMode.Payment,
      shouldGenerateCheckoutSession,
      voucherCode
    }
  );

  return {
    statusCode: httpResponse.statusCode,
    response:
      httpResponse.statusCode === 200 || httpResponse.statusCode === 403
        ? ((await getBentJsonResponse(httpResponse)) as ClaimReservationResponse)
        : null
  };
};
