import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { Clinician } from 'utils/hooks/clinician';

export const clinicianDetails = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountClinicianDetailsById: builder.query<PractitionerDetailsInterface, { clinicianId: string }>({
      query: ({ clinicianId }) => ({
        url: `/accounts/someone-health/clinicians/${clinicianId}`
      }),
      providesTags: ['AttachedClinicianDetails']
    }),
    getClinicianDetailsById: builder.query<Clinician, { clinicianId: string }>({
      query: ({ clinicianId }) => ({
        url: `/clinician/${clinicianId}`
      }),
      providesTags: ['ClinicianDetails']
    })
  })
});

export const { useGetAccountClinicianDetailsByIdQuery, useGetClinicianDetailsByIdQuery } = clinicianDetails;
