import styles from './HelmPartnerCreateProfileRemainingTime.module.scss';
import classnames from 'classnames';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { sortByCreatedAt } from '../../../PartnerBookingSummary/components/PartnerBookingSummaryItem/PartnerBookingSummaryItem';
import HelmTimer from 'helm/components/HelmTimer/HelmTimer';
import { reservedHelmPartnerReferral } from 'utils/hooks/EngageReserved/reservedAppointment';
import moment from 'moment/moment';

interface HelmPartnerCreateProfileRemainingTimeProps {
  practitionerName: string;
  appointments?: AppointmentSlot[];
  practitionerAvatar?: string;
}

const HelmPartnerCreateProfileRemainingTime = ({
  practitionerName,
  appointments = [],
  practitionerAvatar
}: HelmPartnerCreateProfileRemainingTimeProps) => (
  <div className={styles.container}>
    <div className={styles.notice}>To confirm a booking, you need to complete registration in 15 minutes</div>
    <div className={styles.circularTimer}>
      <HelmTimer
        isReservedAppointmentFetching={false}
        reservedAppointments={appointments}
        onlyText={false}
        reversedAppointmentId={reservedHelmPartnerReferral}
      />
    </div>
    <div className={styles.holdingSlotCard}>
      <div className={styles.practitionerAvatarRow}>
        {practitionerAvatar && (
          <div className={styles.leftCol}>
            <img src={practitionerAvatar} alt="avatar" />
          </div>
        )}
        <div className={styles.rightCol}>
          We are holding your booking with <b>{practitionerName}</b>.
        </div>
      </div>
      <div className={styles.appointmentsRow}>
        <div className={classnames(styles.leftCol, styles.grayText)}>Holding:</div>
        <div className={styles.rightCol}>
          {appointments.sort(sortByCreatedAt).map((appointmentObj, index) => (
            <div key={index}>{`${moment(appointmentObj.date).format('DD MMMM YYYY')}, ${appointmentObj.startTime} to ${
              appointmentObj.endTime
            }`}</div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default HelmPartnerCreateProfileRemainingTime;
