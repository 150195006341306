import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import CaWContentLayout from '../../components/CaWContentLayout/CaWContentLayout';
import CaWHeader from '../../components/CaWHeader/CaWHeader';

const ConsentListWrapper = () => (
  <div className={styles.container}>
    <CaWContentLayout className={styles.headerContent}>
      <div className={styles.headerWrapper}>
        <CaWHeader withPadding withMenu blueMenu />
      </div>
    </CaWContentLayout>
    <CaWContentLayout className={styles.content}>
      <ConsentList />
    </CaWContentLayout>
  </div>
);

export default ConsentListWrapper;
