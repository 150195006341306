export enum MentalHealthList {
  // Main filters
  AttentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  AngerManagement = 'angerManagement',
  Anxiety = 'anxiety',
  BipolarAffectiveDisorder = 'bipolarAffectiveDisorder',
  BorderlinePersonalityDisorder = 'borderlinePersonalityDisorder',
  DepressionAndMood = 'depressionAndMood',
  EatingDisorders = 'eatingDisorders',
  GriefAndBereavement = 'griefAndBereavement',
  RelationshipCounselling = 'relationshipCounselling',
  StressManagement = 'stressManagement',
  TraumaAndPtsd = 'traumaAndPtsd',

  // Others(hidden)
  AdjustmentDisorder = 'adjustmentDisorder',
  AlcoholAndOtherDrugs = 'alcoholAndOtherDrugs',
  Autism = 'autism',
  BodyDysmorphia = 'bodyDysmorphia',
  Burnout = 'burnout',
  Career = 'career',
  ChronicPain = 'chronicPain',
  Communication = 'communication',
  Confidence = 'confidence',
  DissociativeDisorders = 'dissociativeDisorders',
  DomesticViolence = 'domesticViolence',
  Dyslexia = 'dyslexia',
  Family = 'family',
  FertilityAPerinatal = 'fertilityAPerinatal',
  FinancialStress = 'financialStress',
  GamblingAddiction = 'gamblingAddiction',
  GenderAndSexualIdentity = 'genderAndSexualIdentity',
  HealthAndLifestyle = 'healthAndLifestyle',
  InfantSleep = 'infantSleep',
  LifeTransition = 'lifeTransition',
  Loneliness = 'loneliness',
  LowMood = 'lowMood',
  MeaningValue = 'meaningValue',
  Narcissism = 'narcissism',
  NewParent = 'newParent',
  Ocd = 'ocd',
  PainManagement = 'painManagement',
  PanicAttacks = 'panicAttacks',
  ParentTherapy = 'parentTherapy',
  Perfectionism = 'perfectionism',
  Performance = 'performance',
  PerinatalAndPostnatalDepressionAndAnxiety = 'perinatalAndPostnatalDepressionAndAnxiety',
  PersonalityDisorder = 'personalityDisorder',
  Phobias = 'phobias',
  PornographySexualDifficulties = 'pornographySexualDifficulties',
  Ptsd = 'ptsd',
  RiskyBehaviours = 'riskyBehaviours',
  SelfDevelopment = 'selfDevelopment',
  SelfEsteem = 'selfEsteem',
  SexualAbuse = 'sexualAbuse',
  SexualHealth = 'sexualHealth',
  SleepInsomnia = 'sleepInsomnia',
  SmokingCessation = 'smokingCessation',
  SocialAnxiety = 'socialAnxiety',
  Tinnitus = 'tinnitus',
  Violence = 'violence',
  WeightManagement = 'weightManagement',
  WorkplaceIncidentTherapy = 'workplaceIncidentTherapy',
  WorkplaceRelations = 'workplaceRelations',
  WorkStress = 'workStress'
}

export const MENTAL_HEALTH_LIST_LABELS: Record<MentalHealthList, string> = {
  // Main filters
  [MentalHealthList.AttentionDeficitHyperactivityDisorder]: 'ADD/ADHD',
  [MentalHealthList.AngerManagement]: 'Anger',
  [MentalHealthList.Anxiety]: 'Anxiety',
  [MentalHealthList.BipolarAffectiveDisorder]: 'Bipolar',
  [MentalHealthList.BorderlinePersonalityDisorder]: 'Borderline Personality Disorder',
  [MentalHealthList.DepressionAndMood]: 'Depression',
  [MentalHealthList.EatingDisorders]: 'Eating Disorder',
  [MentalHealthList.GriefAndBereavement]: 'Grief & Loss',
  [MentalHealthList.RelationshipCounselling]: 'Relationship Issues',
  [MentalHealthList.StressManagement]: 'Stress',
  [MentalHealthList.TraumaAndPtsd]: 'Trauma',

  // Others(hidden)
  [MentalHealthList.AdjustmentDisorder]: 'Adjustment disorder',
  [MentalHealthList.AlcoholAndOtherDrugs]: 'Alcohol and Other Drugs (AOD)',
  [MentalHealthList.Autism]: 'Autism',
  [MentalHealthList.BodyDysmorphia]: 'Body Dysmorphia',
  [MentalHealthList.Burnout]: 'Burnout',
  [MentalHealthList.Career]: 'Career',
  [MentalHealthList.ChronicPain]: 'Chronic Pain',
  [MentalHealthList.Communication]: 'Communication',
  [MentalHealthList.Confidence]: 'Confidence',
  [MentalHealthList.DissociativeDisorders]: 'Dissociative disorders',
  [MentalHealthList.DomesticViolence]: 'Domestic Violence',
  [MentalHealthList.Dyslexia]: 'Dyslexia',
  [MentalHealthList.Family]: 'Family',
  [MentalHealthList.FertilityAPerinatal]: 'Fertility & Perinatal',
  [MentalHealthList.FinancialStress]: 'Financial stress',
  [MentalHealthList.GamblingAddiction]: 'Gambling Addiction',
  [MentalHealthList.GenderAndSexualIdentity]: 'Gender & Sexual Identity',
  [MentalHealthList.HealthAndLifestyle]: 'Health & Lifestyle',
  [MentalHealthList.InfantSleep]: 'Infant Sleep',
  [MentalHealthList.LifeTransition]: 'Life Transition',
  [MentalHealthList.Loneliness]: 'Loneliness',
  [MentalHealthList.LowMood]: 'Low mood',
  [MentalHealthList.MeaningValue]: 'Meaning & value',
  [MentalHealthList.Narcissism]: 'Narcissism',
  [MentalHealthList.NewParent]: 'New Parent',
  [MentalHealthList.Ocd]: 'OCD',
  [MentalHealthList.PainManagement]: 'Pain Management',
  [MentalHealthList.PanicAttacks]: 'Panic attacks',
  [MentalHealthList.ParentTherapy]: 'Parent therapy',
  [MentalHealthList.Perfectionism]: 'Perfectionism',
  [MentalHealthList.Performance]: 'Performance',
  [MentalHealthList.PerinatalAndPostnatalDepressionAndAnxiety]: 'Perinatal and postnatal depression and anxiety',
  [MentalHealthList.PersonalityDisorder]: 'Personality disorder',
  [MentalHealthList.Phobias]: 'Phobias',
  [MentalHealthList.PornographySexualDifficulties]: 'Pornography/ sexual difficulties',
  [MentalHealthList.Ptsd]: 'PTSD',
  [MentalHealthList.RiskyBehaviours]: 'Risky behaviours',
  [MentalHealthList.SelfDevelopment]: 'Self development',
  [MentalHealthList.SelfEsteem]: 'Self esteem',
  [MentalHealthList.SexualAbuse]: 'Sexual Abuse',
  [MentalHealthList.SexualHealth]: 'Sexual health',
  [MentalHealthList.SleepInsomnia]: 'Sleep/ Insomnia',
  [MentalHealthList.SmokingCessation]: 'Smoking cessation',
  [MentalHealthList.SocialAnxiety]: 'Social anxiety',
  [MentalHealthList.Tinnitus]: 'Tinitus',
  [MentalHealthList.Violence]: 'Violence',
  [MentalHealthList.WeightManagement]: 'Weight Management',
  [MentalHealthList.WorkplaceIncidentTherapy]: 'Workplace incident therapy',
  [MentalHealthList.WorkplaceRelations]: 'Workplace relations',
  [MentalHealthList.WorkStress]: 'Work stress'
};

export const HIDDEN_SPECIALISATIONS_LIST = [
  MentalHealthList.AdjustmentDisorder,
  MentalHealthList.AlcoholAndOtherDrugs,
  MentalHealthList.Autism,
  MentalHealthList.BodyDysmorphia,
  MentalHealthList.Burnout,
  MentalHealthList.Career,
  MentalHealthList.ChronicPain,
  MentalHealthList.Communication,
  MentalHealthList.Confidence,
  MentalHealthList.DissociativeDisorders,
  MentalHealthList.DomesticViolence,
  MentalHealthList.Dyslexia,
  MentalHealthList.Family,
  MentalHealthList.FertilityAPerinatal,
  MentalHealthList.FinancialStress,
  MentalHealthList.GamblingAddiction,
  MentalHealthList.GenderAndSexualIdentity,
  MentalHealthList.HealthAndLifestyle,
  MentalHealthList.InfantSleep,
  MentalHealthList.LifeTransition,
  MentalHealthList.Loneliness,
  MentalHealthList.LowMood,
  MentalHealthList.MeaningValue,
  MentalHealthList.Narcissism,
  MentalHealthList.NewParent,
  MentalHealthList.Ocd,
  MentalHealthList.PainManagement,
  MentalHealthList.PanicAttacks,
  MentalHealthList.ParentTherapy,
  MentalHealthList.Perfectionism,
  MentalHealthList.Performance,
  MentalHealthList.PerinatalAndPostnatalDepressionAndAnxiety,
  MentalHealthList.PersonalityDisorder,
  MentalHealthList.Phobias,
  MentalHealthList.PornographySexualDifficulties,
  MentalHealthList.Ptsd,
  MentalHealthList.RiskyBehaviours,
  MentalHealthList.SelfDevelopment,
  MentalHealthList.SelfEsteem,
  MentalHealthList.SexualAbuse,
  MentalHealthList.SexualHealth,
  MentalHealthList.SleepInsomnia,
  MentalHealthList.SmokingCessation,
  MentalHealthList.SocialAnxiety,
  MentalHealthList.Tinnitus,
  MentalHealthList.Violence,
  MentalHealthList.WeightManagement,
  MentalHealthList.WorkStress,
  MentalHealthList.WorkplaceIncidentTherapy,
  MentalHealthList.WorkplaceRelations
];
