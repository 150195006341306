import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import classNames from 'classnames';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';

const UpdateProfile = () => {
  return (
    <EaseHelmetWrapper title={`EASE Wellbeing | Therapy & Counselling Experts in London - Profile`}>
      <EaseContentLayout className={`${styles.container} ease-theme`}>
        <EaseHeader withPadding withMenu homeLink greenMenu />
        <UpdateProfileContent contentClassName={classNames(styles.content, 'ease-theme')} />
      </EaseContentLayout>
    </EaseHelmetWrapper>
  );
};

export default UpdateProfile;
