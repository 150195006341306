import { useMemo, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

type AuthTokenType = {
  'https://tacklit.com/clinicianId': string;
};
type QueryTokenType = {
  'https://tacklit.com/clinicianId': string;
  surveyAssignmentId: string;
  gad7AssignmentId: string;
  phq9AssignmentId: string;
};

export interface BazaarToken {
  authToken: string;
  clinicianId: string;
  surveyAssignmentId: string;
  gad7AssignmentId: string;
  phq9AssignmentId: string;
}

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const useBazaarTokens = () => {
  const [cookies] = useCookies(['bazaar-tokens']);

  // To handle Safari preventing cross domain cookies.
  const hasStorageApi =
    typeof document.hasStorageAccess === 'function' && typeof document.requestStorageAccess === 'function';

  const [hasStorageAccess, setHasStorageAccess] = useState<boolean>(false);
  const [hasFailedToRequestStorage, setHasFailedToRequestStorage] = useState<boolean>(false);
  const [requestStorageCount, setRequestStorageCount] = useState<number>(0);

  const [token, setToken] = useState<{
    authToken: string;
    clinicianId: string;
    surveyAssignmentId: string;
    gad7AssignmentId: string;
    phq9AssignmentId: string;
  }>();

  const query = useQuery();

  useEffect(() => {
    if (query.get('token')) {
      try {
        const authToken = query.get('token') || '';
        const decodedToken = jwt_decode(authToken) as QueryTokenType;
        setToken({
          authToken,
          clinicianId: decodedToken?.['https://tacklit.com/clinicianId'],
          surveyAssignmentId: decodedToken?.['surveyAssignmentId'],
          gad7AssignmentId: decodedToken?.['gad7AssignmentId'],
          phq9AssignmentId: decodedToken?.['phq9AssignmentId']
        });
      } catch (e) {
        console.error('invalid tokens');
      }
    } else if (cookies['bazaar-tokens']) {
      const tokens = cookies['bazaar-tokens'];
      try {
        const decodedToken = jwt_decode(tokens.authToken) as AuthTokenType;
        setToken({
          authToken: tokens['authToken'],
          clinicianId: decodedToken?.['https://tacklit.com/clinicianId'],
          surveyAssignmentId: tokens['surveyAssignmentId'],
          gad7AssignmentId: tokens['gad7AssignmentId'],
          phq9AssignmentId: tokens['phq9AssignmentId']
        });
      } catch (e) {
        console.error('invalid tokens');
      }
    }
  }, [cookies, hasStorageAccess, query]);

  useEffect(() => {
    if (hasStorageApi) {
      (async () => {
        const currentStorageAccessRight = await document.hasStorageAccess();
        setHasStorageAccess(currentStorageAccessRight);
      })();
    }
  });

  const requestStorageAccess = async () => {
    try {
      await document.requestStorageAccess();
      document.location.reload();
    } catch (err) {
      const nextRequestStorageCount = requestStorageCount + 1;
      if (nextRequestStorageCount < 2) {
        setRequestStorageCount(requestStorageCount + 1);
      } else {
        setHasFailedToRequestStorage(true);
      }
    }
  };

  return {
    token,
    hasFailedToRequestStorage,
    requestStorageAccess: hasStorageApi && !hasStorageAccess ? requestStorageAccess : undefined
  };
};

export default useBazaarTokens;
