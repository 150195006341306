import styles from './SomeoneHealthMeetTeamMenu.module.scss';
import gradientArrow from '../../../../assets/images/landingPage/Arrow.svg';

export interface SomeoneHealthMeetTeamMenuProps {
  mobileCard?: boolean;
  isSelected: boolean;
  avatar: string;
  name: string;
  desc?: string;
  onSelect: () => void;
}

const SomeoneHealthMeetTeamMenu = ({
  mobileCard,
  isSelected,
  avatar,
  name,
  desc,
  onSelect
}: SomeoneHealthMeetTeamMenuProps) => {
  return (
    <div className={isSelected ? styles.containerSelected : styles.container} onClick={onSelect}>
      <img className={isSelected ? styles.menuImageSelected : styles.menuImage} src={avatar} alt={`${name} avatar`} />
      <div className={styles.detailWrapper}>
        <div className={isSelected ? styles.titleSelected : styles.title}>{name}</div>
        <div className={isSelected ? styles.descSelected : styles.desc}>{desc}</div>
      </div>
      {mobileCard ? (
        <div className={isSelected ? styles.mobileArrowWrapper : styles.hideMobileArrowIcon}>
          <img className={styles.mobileArrow} src={gradientArrow} alt="An Arrow" />
        </div>
      ) : (
        <div className={isSelected ? styles.arrowIcon : styles.hideArrowIcon}>
          <img src={gradientArrow} alt="An Arrow" />
        </div>
      )}
    </div>
  );
};

export default SomeoneHealthMeetTeamMenu;
