import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';

interface tokenInterface {
  'https://tacklit.com/clientRecordId': string;
  'https://tacklit.com/clinicianId': string;
  'https://tacklit.com/initials': string;
  'https://tacklit.com/initialsBackgroundColor': string;
}

export const useFetchAvatar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParam: { token?: string } = queryString.parse(location.search);

  if (queryParam.token) {
    try {
      const decodeObj = jwt_decode(queryParam.token) as tokenInterface;
      return {
        initialsName: decodeObj['https://tacklit.com/initials'],
        initialsBackgroundColor: decodeObj['https://tacklit.com/initialsBackgroundColor']
      };
    } catch (ex) {
      console.error(ex);
      navigate('/404');
    }
  }

  return {
    initialsName: '',
    initialsBackgroundColor: ''
  };
};
