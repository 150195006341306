import styles from './Finished.module.scss';

const Finished = () => {
  return (
    <div className={styles.finished}>
      <i className={`material-icons ${styles.icon}`}>done</i>
    </div>
  );
};

export default Finished;
