import styles from './AvailabilityPill.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';

export interface AvailabilityPillProps {
  children: ReactNode;
  classNames?: string;
  isDarkerBackground?: boolean;
}

const AvailabilityPill = ({ children, classNames, isDarkerBackground }: AvailabilityPillProps) => {
  return (
    <div className={classnames(isDarkerBackground ? styles.darkYellowContainer : styles.container, classNames)}>
      {children}
    </div>
  );
};

export default AvailabilityPill;
