import { useRef, useEffect } from 'react';
import ReportContentHeader from './components/ReportContentHeader/ReportContentHeader';
import ReportContentBody from './components/ReportContentBody/ReportContentBody';
import SignatureSection from './components/SignatureSection/SignatureSection';

import styles from './ReportDraftContent.module.scss';
import { debounce } from 'lodash';
import { ReportInterface } from 'interfaces/report';

interface ReportDraftContentProps {
  data: ReportInterface;
  clinicianDetails: any;
  contentHeight?: any;
}

const ReportDraftContent = ({ data, clinicianDetails, contentHeight }: ReportDraftContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getContentHeight = debounce(() => {
      if (contentRef.current !== null) {
        const { height, width } = contentRef.current.getBoundingClientRect();
        contentHeight(height, width);
      }
    }, 300);

    window.addEventListener('resize', getContentHeight);
    getContentHeight();
    return () => {
      window.removeEventListener('resize', getContentHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={contentRef} className={styles.container}>
      <div className={styles.contentWrapper}>
        <ReportContentHeader
          reportName={data.reportName}
          clinicianDetails={clinicianDetails}
          clinicianContactData={data.contactDetails}
          issueDate={data.issueDate}
        />
        <ReportContentBody dndDimension={data.template.dimensions} reportItems={data.items} />
        <SignatureSection signVal={data.clinicianSignature} extraDetailsVal={data.clinicianSignatureExtraDetails} />
      </div>
    </div>
  );
};

export default ReportDraftContent;
