import styles from './PractitionerSpecialisation.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

interface PractitionerSpecialisationProps {
  specialisationsDetails: PractitionerDetailsInterface['specialisations'];
  vertical?: boolean;
}

const PractitionerSpecialisation = ({ specialisationsDetails, vertical }: PractitionerSpecialisationProps) => (
  <div className={styles.container}>
    <div className={styles.label}>SPECIALISATIONS</div>
    <div className={vertical ? styles.specialisationBadgeVerticalWrapper : styles.specialisationBadgeWrapper}>
      {specialisationsDetails.map((obj, index) => (
        <div key={index} className={styles.specialisationBadge}>
          {obj}
        </div>
      ))}
    </div>
  </div>
);

export default PractitionerSpecialisation;
