const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const uploadDocumentWithEncryption = async (token: string, file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  const request = new XMLHttpRequest();
  request.responseType = 'json';

  return new Promise((resolve: (value: {}) => void, reject) => {
    request.open('POST', `${url}/patientUpload/encrypted`, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.response);
      } else if (request.status === 400) {
        reject('Your file is too big. Please select a file that is smaller than 10MB');
      }
    };

    request.send(formData);
  });
};

export const uploadDocumentWithEncryptionWithoutSignin = async (token: string, file: File) => {
  const formData = new FormData();

  formData.append('file', file);

  const request = new XMLHttpRequest();
  request.responseType = 'json';

  return new Promise((resolve: (value: {}) => void, reject) => {
    request.open('POST', `${url}/patientUpload/encrypted`, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    request.onload = () => {
      if (request.status === 200) {
        resolve(request.response);
      } else if (request.status === 400) {
        reject('Your file is too big. Please select a file that is smaller than 10MB');
      }
    };

    request.send(formData);
  });
};
