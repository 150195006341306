import * as Yup from 'yup';

export enum NDIS_TYPE {
  self = 'self',
  plan = 'plan',
  ndia = 'ndia'
}

export interface NDISInterface {
  plan: NDIS_TYPE;
  number: string;
  name?: string;
  abn?: string;
  email?: string;
}

export const NDIS_DEFAULT_VALUE: NDISInterface = {
  plan: NDIS_TYPE.self,
  number: '',
  name: '',
  abn: '',
  email: ''
};

export const NDIS_PLAN_LIST = [
  {
    value: NDIS_TYPE.self,
    label: 'Self Managed'
  },
  {
    value: NDIS_TYPE.ndia,
    label: 'NDIA'
  },
  {
    value: NDIS_TYPE.plan,
    label: 'Plan Managed'
  }
];

export interface NDISPlanProps {
  ndisDetails: NDISInterface;
  onChangeNDISDetails: (fieldName: string, value: string) => void;
  setIsValidNDIS: (val: boolean) => void;
}

export interface ErrorsFields {
  number: string;
  name: string;
  email: string;
}

export interface TouchedFields {
  number: boolean;
  name: boolean;
  email: boolean;
}

export const planValidationSchema = Yup.object().shape({
  number: Yup.string().required('Please enter client NDIS number'),
  name: Yup.string().when('plan', {
    is: 'plan',
    then: Yup.string().required('Please enter client name of plan manager'),
    otherwise: Yup.string()
  }),
  email: Yup.string().when('plan', {
    is: 'plan',
    then: Yup.string()
      .required('Please enter client plan manager email')
      .email('Email must be in valid example@gmail.com format.'),
    otherwise: Yup.string()
  })
});
