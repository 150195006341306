import { ChangeEvent } from 'react';
import { useField } from 'formik';

import { Question } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import CheckboxRadio from '../CheckboxRadio/CheckboxRadio';

interface MultipleChoiceProps {
  question: Question;
  questionId?: string;
}

const MultipleChoice = ({ question, questionId }: MultipleChoiceProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField({
    name: `${question.id}.value`,
    multiple: question.maxSelection === 0
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (question.maxSelection === 0) {
      if (Array.isArray(value)) {
        if (value.includes(e.target.value)) {
          setValue(value.filter((value: string) => value !== e.target.value));
        } else {
          setValue([...value, e.target.value]);
        }
      } else {
        setValue([e.target.value]);
      }
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <>
      <CheckboxRadio
        questionId={questionId}
        question={question}
        hasError={touched && !!error}
        value={value}
        onChange={handleChange}
      />
      <ErrorMessage error={error} visible={touched} />
    </>
  );
};

export default MultipleChoice;
