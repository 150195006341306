import styles from './PracticeInformation.module.scss';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

interface PracticeInformationProps {
  listAppointmentLength: number;
  profileName: string;
  description: string;
  profileImage: string;
}

const PracticeInformation = ({
  profileName,
  profileImage,
  description,
  listAppointmentLength
}: PracticeInformationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.profile}>
          <ClientProfileAvatar avatarUrl={profileImage} />
        </div>
        <div className={styles.info}>
          <div className={styles.text}>{profileName}</div>
          <div className={styles.desc}>{description}</div>
        </div>
      </div>
      <div className={styles.count}>
        <div className={styles.number}>{listAppointmentLength}</div>
        <div className={styles.content}>&nbsp;Available Appointments</div>
      </div>
    </div>
  );
};

export default PracticeInformation;
