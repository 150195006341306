import { notification } from 'antd';
import EditSavedCardButton from 'components/EditSavedCardButton/EditSavedCardButton';
import { useState } from 'react';
import ButtonRecharge, { ButtonStatusType } from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { useGetAccessToken } from 'utils/hooks/token';
import { postRetryPayment } from 'utils/http/BillingService/payment';
import styles from './PaymentFailureTaskBox.module.scss';

interface PaymentFailureTaskBoxProps {
  amount: number;
  paymentRequestId: string;
}

const PaymentFailureTaskBox = ({ amount, paymentRequestId }: PaymentFailureTaskBoxProps) => {
  const { token } = useGetAccessToken();
  const [retryPaymentButtonStatus, setRetryPaymentButtonStatus] = useState<ButtonStatusType>('');

  const handleRetryPaymentClick = async () => {
    setRetryPaymentButtonStatus('active');

    try {
      await postRetryPayment({ token, paymentRequestId });
      notification.success({ message: 'Request to retry payment submitted.' });
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to retry payment.' });
    }

    setRetryPaymentButtonStatus('finished');
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <span className="material-icons-outlined">error_outline</span>
        <div className={styles.title}>Payment Failure</div>
      </div>
      <div className={styles.desc}>
        We couldn't take the payment of ${amount} from your saved card. To fix it, you need to update your card
        information or try again. Once you update, the system will try to make the payment again, which might take a few
        minutes. If you don't update within 24 hours, we'll try again automatically.
      </div>
      <div className={styles.action}>
        <EditSavedCardButton />
        <ButtonRecharge
          finishedClassName={styles.retryFinished}
          status={retryPaymentButtonStatus}
          completedWord="Request submitted"
          onClick={handleRetryPaymentClick}
        >
          Retry payment
        </ButtonRecharge>
      </div>
    </div>
  );
};

export default PaymentFailureTaskBox;
