import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { HOME } = useSelectRoutesGenerator();

  useEffect(() => {
    navigate(HOME, { replace: true });
  }, [HOME, navigate]);

  return <></>;
};

export default LoginPage;
