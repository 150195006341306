import styles from './HelpOutlineTooltip.module.scss';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

interface TooltipsProps {
  id: string;
  text?: string;
  desc: React.ReactNode;
  iconClass?: string;
  tooltipsClass?: string;
  hideIcon?: boolean;
}

const HelpOutLineWithTooltips = ({ id, text, desc, iconClass, tooltipsClass, hideIcon }: TooltipsProps) => {
  return (
    <>
      <div className={styles.tooltipWrapper} data-tip data-for={id}>
        {text && <p>{text}</p>}
        {!hideIcon && <i className={`material-icons ${styles.toolTipsIcon} ${iconClass}`}>help_outline</i>}
      </div>
      <ReactTooltip id={id} className={classnames(styles.tooltip, tooltipsClass)}>
        {desc}
      </ReactTooltip>
    </>
  );
};

export default HelpOutLineWithTooltips;
