import { useState } from 'react';
import { Menu, Row, Col, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './CardHeader.module.scss';

interface CardHeaderProps {
  defaultDateRange?: number;
  isHideFilter?: boolean;
  title: string;
  onSelectDateRange: (dateRange: number) => void;
}

const DATE_RANGE_OPTIONS = [
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' }
];

const CardHeader = ({ defaultDateRange, isHideFilter, title, onSelectDateRange }: CardHeaderProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange?.toString() || '30');

  const handleDateRangeSelect = (key: string) => {
    const dateRangeNumber = Number.parseInt(key, 10);

    if (!isNaN(dateRangeNumber)) {
      setSelectedDateRange(key);
      onSelectDateRange(dateRangeNumber);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      {isHideFilter ? (
        <></>
      ) : (
        <div className={styles.filter}>
          <Row gutter={8} align="middle">
            <Col>Show</Col>
            <Col>
              <Dropdown
                overlay={
                  <Menu onClick={({ key }) => handleDateRangeSelect(String(key))}>
                    {DATE_RANGE_OPTIONS.map(({ label, value }) => (
                      <Menu.Item key={value}>{label}</Menu.Item>
                    ))}
                  </Menu>
                }
                trigger={['click']}
              >
                <span className={styles.headerFilterDropdown} onClick={(e) => e.preventDefault()}>
                  {DATE_RANGE_OPTIONS.find((option) => option.value === selectedDateRange)?.label} <DownOutlined />
                </span>
              </Dropdown>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default CardHeader;
