import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';
import { RechargePracticeSlugUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getRechargeClinicianListing = (queryParam?: string) =>
  httpClient(
    'GET',
    url!,
    200
  )(`/accounts/${RechargePracticeSlugUrl}/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getRechargeClinicianDetails = (clinicianId: string) =>
  httpClient(
    'GET',
    url!,
    200
  )(`/accounts/${RechargePracticeSlugUrl}/clinicians/${clinicianId}`) as Promise<BentResponse>;

export const getPsychologistsMinified = (queryParam?: string) =>
  httpClient(
    'GET',
    url!,
    200
  )(`/accounts/${RechargePracticeSlugUrl}/clinicians/minified?${queryParam}`) as Promise<BentResponse>;
