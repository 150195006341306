import { configInterface } from './config';
import AUPrivacy from 'pages/SignUp/PolicyPage/components/AUPrivacy';

export const configNZ: configInterface = {
  countryCode: 'nz',
  moneyIcon: 'monetization_on',
  currencySymbol: '$',
  currencyCode: 'nzd',
  taxLabel: 'TAX',
  taxRateLabel: 'GST',
  codeLabel: '',
  mobileCountryCode: '64',
  defaultTimezone: 'Pacific/Auckland',
  contactMaskNumber: '021 123 4567',
  contactMaskNumberWithX: '02x xxx xxxx',
  emergencyNumber: '111',
  lifeLineNumber: '0800 543 354',
  practiceSettings: {
    registrationNumberLabel: 'Company Number'
  },
  terms: {
    privacy: <AUPrivacy />
  }
};
