import { ProgramQuoteInterface } from 'helm/interfaces/program/program';

export const ip6Quotes: ProgramQuoteInterface[] = [
  {
    id: '1',
    referenceBy: 'Arthur Ashe',
    reference: '“Start where you are. Use what you have. Do what you can.”'
  },
  {
    id: '2',
    referenceBy: 'Estée Lauder',
    reference: '“I never dreamed about success. I worked for it.”'
  },
  {
    id: '3',
    referenceBy: 'Paulo Coelho',
    reference: '“When we strive to become better than we are, everything around us becomes better too.”'
  },
  {
    id: '4',
    referenceBy: 'Confucius',
    reference: '“He who conquers himself is the mightiest warrior.”'
  },
  {
    id: '5',
    referenceBy: 'Dalai Lama',
    reference: '“Just one small positive thought in the morning can change your whole day.”'
  }
];
