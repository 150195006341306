import PracticeLayout from 'layouts/PracticeLayout/PracticeLayout';
import { useParams } from 'react-router-dom';
import styles from './PracticeLandingPage.module.scss';
import PracticeAppointmentTypesList from './components/PracticeAppointmentTypesList/PracticeAppointmentTypesList';
import { useFetchAvailabilityAppointmentTypes } from 'utils/hooks/appointment';
import { useFetchPublicPracticeProfile } from 'utils/hooks/accounts';
import PracticeInformation from './components/PracticeInformation/PracticeInformation';
import { Skeleton } from 'antd';

const PracticeLandingPage = () => {
  const { slugUrl = '' } = useParams<{ slugUrl: string }>();
  const { practiceProfile, isPracticeProfileLoading } = useFetchPublicPracticeProfile(slugUrl);
  const { availabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } = useFetchAvailabilityAppointmentTypes({
    accountId: practiceProfile?.accountId
  });

  const appointmentTypes = availabilityAppointmentTypes?.filter(
    (appointmentType) => appointmentType.bookingRules?.new.available
  );

  return (
    <PracticeLayout>
      {isPracticeProfileLoading || isAvailabilityAppointmentTypesLoading || !practiceProfile || !appointmentTypes ? (
        <div className={styles.loading}>
          <Skeleton avatar active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <>
          <div>
            <PracticeInformation
              profileName={practiceProfile.name}
              profileImage={practiceProfile.logo}
              description={practiceProfile.description}
              listAppointmentLength={appointmentTypes.length}
            />
          </div>
          <div className={styles.divider} />
          <PracticeAppointmentTypesList
            practiceProfile={practiceProfile}
            slugUrl={slugUrl}
            availabilityAppointmentTypes={appointmentTypes}
          />
        </>
      )}
    </PracticeLayout>
  );
};
export default PracticeLandingPage;
