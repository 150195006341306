import { Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import { UserContextProvider } from 'utils/UserContextProvider';
import SomeoneHealthWrapper from 'SomeoneHealth/components/SomeoneHealthWrapper/SomeoneHealthWrapper';
import { useSomeoneHealthPartnerRoutesGenerator } from 'SomeoneHealth/PartnerReferral/utils/Path/SomeoneHealthPartnerRoutesGenerator';
import TokenAccess from 'SomeoneHealth/PartnerReferral/pages/TokenAccess/TokenAccess';
import PartnerPsychologistListing from 'SomeoneHealth/PartnerReferral/pages/PartnerPsychologistListing/PartnerPsychologistListing';
import PartnerPsychologistDetails from 'SomeoneHealth/PartnerReferral/pages/PartnerPsychologistDetails/PartnerPsychologistDetails';
import PartnerSignUp from 'SomeoneHealth/PartnerReferral/pages/PartnerSignUp/PartnerSignUp';
import PartnerBookingSummary from 'SomeoneHealth/PartnerReferral/pages/PartnerBookingSummary/PartnerBookingSummary';
import PartnerConfirmPage from 'SomeoneHealth/PartnerReferral/pages/PartnerConfirmPage/PartnerConfirmPage';
import AccessTokenAuthWrapper from '../../SomeoneHealth/PartnerReferral/components/AccessTokenAuthWrapper/AccessTokenAuthWrapper';

const SomeoneHealthPartnerRoutes = () => {
  const { TOKEN, PRACTITIONER, SIGNUP } = useSomeoneHealthPartnerRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: TOKEN.BASE, component: TokenAccess, layout: SomeoneHealthWrapper })}
        {appRoute({
          path: PRACTITIONER.LISTING,
          component: PartnerPsychologistListing,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.LISTING_WITH_FILTER,
          component: PartnerPsychologistListing,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PartnerPsychologistDetails,
          layout: AccessTokenAuthWrapper
        })}
        {appRoute({ path: SIGNUP.BASE, component: PartnerSignUp, layout: AccessTokenAuthWrapper })}
        {appRoute({ path: SIGNUP.SUMMARY_BOOKING, component: PartnerBookingSummary, layout: AccessTokenAuthWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: PartnerConfirmPage, layout: AccessTokenAuthWrapper })}
      </Routes>
    </UserContextProvider>
  );
};

export default SomeoneHealthPartnerRoutes;
