import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from '../../components/SomeoneHealthHeader/SomeoneHealthHeader';

const UpdateProfile = () => {
  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Profile`}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <SomeoneHealthHeader withPadding withMenu homeLink />
          <UpdateProfileContent contentClassName={styles.content} />
        </div>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default UpdateProfile;
