import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './GPAddPaymentPage.module.scss';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import AddPayment from './components/AddPayment/AddPayment';
import { useState } from 'react';
import { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { useGetAccessToken } from 'utils/hooks/token';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { notification } from 'antd';
import { useReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import Loading from 'components/Loading/Loading';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';

const GPAddPaymentPage = () => {
  const { token } = useGetAccessToken();
  const navigate = useNavigate();

  const { GP, HOME } = useSomeoneHealthRoutesGenerator();
  const { reserveId, accountId } = useReserveGPAppointmentData();

  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  if (!token || !reserveId || !accountId) {
    navigate(HOME);
  }

  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const currentUrl = window.location.origin;

  const onSubmit = async () => {
    setButtonStatus('active');
    try {
      const res = await putProcessWithPaymentMethod(
        accountId || '',
        reserveId || '',
        { cancelUrl: `${currentUrl}${GP.ADD_PAYMENT}`, returnUrl: `${currentUrl}${GP.COMPLETE}` },
        token
      );

      if (res.statusCode === 200) {
        setButtonStatus('finished');
        const responseData = await res.json();
        if (responseData.checkoutUrl) {
          window.location.href = responseData.checkoutUrl;
        }
      } else if (res.statusCode === 204) {
        setButtonStatus('finished');
        navigate(GP.COMPLETE);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to generate the link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Add payment'}>
      <div className={styles.pageContainer}>
        <SomeoneHealthHeader pinkLogo noLogin classNames={styles.header} />
        <SomeoneHealthContentLayout className={styles.content}>
          {isClientProfileLoading || !clientProfileData ? (
            <Loading />
          ) : (
            <AddPayment
              buttonStatus={buttonStatus}
              name={clientProfileData.firstName || ''}
              onAddPaymentDetails={onSubmit}
            />
          )}
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default GPAddPaymentPage;
