import { notification } from 'antd';
import DownloadButton from 'components/DownloadButton/DownloadButton';
import SectionLoader from 'components/SectionLoader/SectionLoader';
import SMPForm from 'components/SMP/SMPForm/SMPForm';
import SMPLayoutWeb from 'components/SMP/SMPLayoutWeb/SMPLayoutWeb';
import { HelmBtnStatus } from 'components/v2/Button/Button';
import moment from 'moment';
import { SmpStatus } from 'pages/SMP/interfaces/smp';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useDownloadSmp, useSmpDetails } from 'utils/hooks/smp';
import styles from './SMPDetailsPage.module.scss';

const downloadPdf = (file: Blob, fileName: string): void => {
  const hiddenLink = document.createElement('a');
  hiddenLink.href = window.URL.createObjectURL(file);
  hiddenLink.download = `${fileName}.pdf`;
  hiddenLink.click();
  hiddenLink.remove();
};

const PDF_FILE_NAME = IS_HELM_APP ? 'SMP' : 'Kickstart SMP';

const SMPDetailsPage = () => {
  const { smpId } = useParams();
  const { smpDetails, refetchSmpDetails, isLoading } = useSmpDetails(smpId);

  const { downloadSmp } = useDownloadSmp(smpId);
  const [isSmpDownloading, setIsSmpDownloading] = useState(false);
  const [downloadButtonState, setDownloadButtonState] = useState<HelmBtnStatus>('');
  const smpPrintPayloadRef = useRef(null);

  const handleDownload = async () => {
    if (smpPrintPayloadRef?.current && smpDetails?.attachment?._id) {
      setIsSmpDownloading(true);
      setDownloadButtonState('active');

      const retrievedPDF = await downloadSmp();
      if (retrievedPDF) {
        downloadPdf(retrievedPDF, PDF_FILE_NAME);
      }

      setDownloadButtonState('finished');
      setIsSmpDownloading(false);

      setTimeout(() => {
        setDownloadButtonState('');
      }, 1000);
    } else {
      notification.error({
        message: 'Failed to download SMP. Kindly contact us at support@tacklit.com to resolve this.'
      });
    }
  };

  return (
    <SMPLayoutWeb title={'Tacklit - SMP'}>
      {isLoading ? (
        <SectionLoader variant="dot" />
      ) : (
        <div className={IS_HELM_APP ? 'helm-theme' : undefined}>
          <div className={styles.topContainer}>
            <div className={styles.status}>
              {smpDetails?.status === SmpStatus.submitted
                ? `SMP submitted ${moment(smpDetails?.submittedAt).fromNow()}`
                : `SMP draft last saved ${moment(smpDetails?.updatedAt).fromNow()}`}
            </div>
            {smpDetails?.status === SmpStatus.submitted && (
              <DownloadButton
                status={downloadButtonState}
                onClick={handleDownload}
                disabled={isSmpDownloading}
                customClass={styles.downloadButton}
              >
                Download SMP
              </DownloadButton>
            )}
          </div>
          <SMPForm
            smpDetails={smpDetails}
            refetchSmpDetails={refetchSmpDetails}
            smpPrintPayloadRef={smpPrintPayloadRef}
          />
        </div>
      )}
    </SMPLayoutWeb>
  );
};

export default SMPDetailsPage;
