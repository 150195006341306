import { useEffect } from 'react';

const hubSpotId = process.env.REACT_APP_HUB_SPOT_ID;

const getHubSpotIframe = () => document.getElementById('hubspot-messages-iframe-container');

const FloatingHelpBtnHubSpot = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `//js-na1.hs-scripts.com/${hubSpotId}.js`;
    script.async = true;

    document.body.appendChild(script);

    getHubSpotIframe()?.style.setProperty('display', 'block', 'important');

    return () => {
      getHubSpotIframe()?.style.setProperty('display', 'none', 'important');
    };
  }, []);

  return null;
};

export default FloatingHelpBtnHubSpot;
