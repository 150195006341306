import { Helmet, HelmetProvider } from 'react-helmet-async';

interface RechargeHelmetWrapperProps {
  title?: string;
  children?: any;
  hubspot?: boolean;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favRecharge.png`;

const RechargeHelmetWrapper = ({ children, title, hubspot }: RechargeHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'Recharge conceptualises and delivers innovative opportunities, for everyday Australians to get support for their mental health with a therapist.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default RechargeHelmetWrapper;
