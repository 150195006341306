import { BackgroundStatus } from '../../components/PatientQuestionForm/AssessmentInterfaces';

export interface Option {
  key: string;
  description: string;
  value: string | number;
}

export interface SubQuestion {
  id: string;
  stem: string;
  options: Option[];
}

export enum ScaleType {
  AgreeDisagree = 'agreeDisagree',
  Satisfaction = 'satisfaction',
  Likelihood = 'likelihood',
  GoodBadRating = 'goodBadRating',
  Frequency = 'frequency',
  Priority = 'priority',
  ImpactOfChange = 'impactOfChange',
  Recency = 'recency',
  _1_5 = '_1_5',
  _1_10 = '_1_10',
  EmojiRating = 'emojiRating',
  EmojiReaction = 'emojiReaction'
}

export interface Question {
  id: string;
  questionType:
    | 'barSlider'
    | 'freeText'
    | 'longText'
    | 'hexSlider'
    | 'multipleAnswers'
    | 'multipleChoice'
    | 'multipleChoiceFreeText'
    | 'numeric'
    | 'selectOther'
    | 'vote'
    | 'simpleSlider'
    | 'scale'
    | 'phone'
    | 'email'
    | 'date';
  stem: string;
  stimulus?: string;
  maxSelection?: number;
  options?: Option[] | SubQuestion[];
  response?: {
    value: any;
    otherValue?: string;
  };
  scales?: {
    key: string;
    value: number;
    colour: string;
  }[];
  scaleType?: ScaleType;
  structuredQuestionId?: string;
}

export interface Section {
  name?: string;
  description?: string;
  questions: Question[];
}

export interface CheckInQuestion {
  id: string;
  title: string;
  sections: Section[];
}

export interface ClinicalAssessment {
  id: string;
  title: string;
  description?: string;
  stimulus: string;
  questionType: 'questionSet';
  metadata: Record<string, any>;
  sections: Section[];
  scales?: {
    key?: string;
    ranges: { min: number; max: number; label: string }[];
  }[];
}

export interface MicroJournalQuestion {
  id: string;
  title: string;
  sections: Section[];
}

export interface OnboardingQuestion {
  id: string;
  title: string;
  sections: Section[];
}

export interface BackgroundResponse {
  status?: BackgroundStatus;
  summary: { isShow: boolean; content?: string };
  medication: {
    isShow: boolean;
    code: string;
    medications: {
      label: string;
      value: string;
    }[];
  };
  referral: {
    isShow: boolean;
    code: string;
    date?: string;
    name?: string;
    file?: {
      bucketName: string;
      fileName: string;
      fileUrl: string;
    };
  };
  therapist: {
    isShow: boolean;
    code: string;
    similarExperience?: string;
  };
}
export interface OnboardingAssessment {
  _id: string;
  clinicianId?: string;
  patientId?: string;
  clientProfileId: string;
  clientRecordId: string;
  status?: string;
  assessment: {
    checkInQuestions: CheckInQuestion[];
    clinicalAssessments: ClinicalAssessment[];
    microJournalQuestions: MicroJournalQuestion[];
    onboardingQuestions: {
      assessmentField: OnboardingQuestion[];
      backgroundResponse: BackgroundResponse;
    };
  };
  note?: string;
}

export interface FacilitatedTokenInterface {
  'https://tacklit.com/clientRecordId': string;
  'https://tacklit.com/clinicianId': string;
  'https://tacklit.com/initials': string;
  'https://tacklit.com/initialsBackgroundColor': string;
  'https://tacklit.com/senderName': string;
  'https://tacklit.com/childFirstName'?: string;
}
