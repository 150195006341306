import { useState } from 'react';
import SignatureBox from 'components/SignatureBox/SignatureBox';
import Button from 'components/v2/Button/Button';
import { consentFormInterface, SignatureInterface } from 'interfaces/ConsentForm/consentForm';
import moment from 'moment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import styles from './ConsentContent.module.scss';

interface signatureErrorMessage {
  clientSignature: boolean;
  otherSignature: boolean;
}

interface ConsentContentProps {
  consentFormDetail: consentFormInterface;
  handleChangeClientSignature: (val: SignatureInterface) => void;
  handleChangeOtherSignature: (val: SignatureInterface) => void;
  handleSubmitConsentForm: () => void;
  submitBtnStatus: '' | 'active' | 'finished';
  errorMessage: signatureErrorMessage;
  noBackground?: boolean;
  classNames?: string;
}

const ConsentContent = ({
  consentFormDetail,
  handleChangeClientSignature,
  handleChangeOtherSignature,
  handleSubmitConsentForm,
  submitBtnStatus,
  errorMessage,
  noBackground,
  classNames
}: ConsentContentProps) => {
  const [showSignatureClient, setShowSignatureClient] = useState(false);
  const [showSignatureOther, setShowSignatureOther] = useState(false);
  const [t] = useTranslation();

  return (
    <div className={classnames(noBackground ? styles.contentContainerWithNoBg : styles.contentContainer, classNames)}>
      <div className={styles.practiceInfoWrapper}>
        {consentFormDetail.practice?.logo && (
          <img className={styles.image} src={consentFormDetail.practice?.logo} alt="Practice logo" />
        )}
        <div className={styles.practiceDetails}>
          <div className={styles.name}>{consentFormDetail.practice?.name}</div>
          {consentFormDetail.practice?.locations && consentFormDetail.practice?.locations.length > 0 && (
            <div className={styles.detail}>{consentFormDetail.practice?.locations[0]}</div>
          )}
          {consentFormDetail.practice?.mobileNumber && (
            <div className={styles.detail}>{consentFormDetail.practice?.mobileNumber}</div>
          )}
        </div>
      </div>
      <div className={styles.clientInfoWrapper}>
        <div>
          <span className={styles.clientLabel}>{t('label.client_name')}:</span>{' '}
          <span>{consentFormDetail.clientName}</span>
        </div>
        <div>
          <span className={styles.clientLabel}>Date of Consent Form:</span> <span>{moment().format('DD/MM/YYYY')}</span>
        </div>
      </div>
      <div className={styles.contentBodyWrapper}>
        <div dangerouslySetInnerHTML={{ __html: consentFormDetail.body }} />
      </div>
      <div className={styles.signatureContainer}>
        {consentFormDetail.signature && consentFormDetail.signature.client && (
          <div className={styles.signatureWrapper}>
            <div className={styles.signLabel}>{t('label.client_signed')}:</div>
            {consentFormDetail.signature.client?.type ? (
              <div className={styles.previewSignature} onClick={() => setShowSignatureClient(true)}>
                <div className={styles.signature}>
                  {consentFormDetail.signature.client.type === 'typeVer' ? (
                    consentFormDetail.signature.client.textVal
                  ) : (
                    <img
                      className={styles.signatureImg}
                      alt={'signature'}
                      src={consentFormDetail.signature.client.drawVal}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.signatureBox} onClick={() => setShowSignatureClient(true)}>
                {t('label.click_to_sign_by_client')}
              </div>
            )}
            <ErrorMessage error={'Please sign the signature before proceed'} visible={errorMessage.clientSignature} />
          </div>
        )}
        {consentFormDetail.signature && consentFormDetail.signature.other && (
          <div className={styles.signatureWrapper}>
            <div className={styles.signLabel}>Parent or Guardian Signed:</div>
            {consentFormDetail.signature.other?.type ? (
              <div className={styles.previewSignature} onClick={() => setShowSignatureOther(true)}>
                <div className={styles.signature}>
                  {consentFormDetail.signature.other.type === 'typeVer' ? (
                    consentFormDetail.signature.other.textVal
                  ) : (
                    <img
                      className={styles.signatureImg}
                      alt={'signature'}
                      src={consentFormDetail.signature.other.drawVal}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.signatureBox} onClick={() => setShowSignatureOther(true)}>
                Click to sign by Parent or Guardian signature
              </div>
            )}
            <ErrorMessage error={'Please sign the signature before proceed'} visible={errorMessage.otherSignature} />
          </div>
        )}
        <SignatureBox
          title={t('label.client_signature')}
          id={'clientSignature'}
          visible={showSignatureClient}
          onCloseSignature={() => setShowSignatureClient(false)}
          onChangeSignatureValue={handleChangeClientSignature}
        />
        <SignatureBox
          title={'Parent or Guardian Signature'}
          id={'parentSignature'}
          visible={showSignatureOther}
          onCloseSignature={() => setShowSignatureOther(false)}
          onChangeSignatureValue={handleChangeOtherSignature}
        />
      </div>
      <div className={styles.submitConsentFormWrapper}>
        <Button status={submitBtnStatus} className={styles.signatureBtn} onClick={handleSubmitConsentForm}>
          Submit Consent Form
        </Button>
      </div>
    </div>
  );
};

export default ConsentContent;
