import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getCaWClinicianListing = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/caw/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getCaWClinicianDetails = (clinicianId: string) =>
  httpClient('GET', url!, 200)(`/accounts/caw/clinicians/${clinicianId}`) as Promise<BentResponse>;

export const getCaWEmployerCodes = () =>
  httpClient('GET', url!, 200)(`/accounts/employerCodes`) as Promise<BentResponse>;
