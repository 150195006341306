import classNames from 'classnames';

import styles from './RechargeContentLayout.module.scss';

interface RechargeContentLayoutProps {
  children?: any;
  className?: string;
}

const RechargeContentLayout = ({ children, className }: RechargeContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default RechargeContentLayout;
