import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './GPFlowConfirmationPage.module.scss';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import Confirmation from './components/Confirmation/Confirmation';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

const GPFlowConfirmationPage = () => {
  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Confirm the booking`}>
      <div className={styles.container}>
        <SomeoneHealthHeader withPadding withMenu />
        <SomeoneHealthContentLayout className={styles.contentLayoutWrapper}>
          <div className={styles.welcomeMessage}>GP Appointment</div>
          <Confirmation />
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default GPFlowConfirmationPage;
