import Icon from 'components/Icon/Icon';
import styles from './IconButton.module.scss';

type IconButtonProps = {
  onClick?: () => void;
  iconName: string;
};

const IconButton = ({ onClick, iconName }: IconButtonProps) => {
  return (
    <button className={styles.button} onClick={onClick}>
      <Icon name={iconName} />
    </button>
  );
};

export default IconButton;
