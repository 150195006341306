import { stringEnumValidator } from '../utils/enumValidator';

export enum ClientBrand {
  HELM = 'HELM',
  CORDS = 'CORDS',
  SOMEONE_HEALTH = 'SOMEONE_HEALTH',
  TACKLIT = 'TACKLIT',
  CAW = 'CAW',
  SOMEONE_HEALTH_PARTNER = 'SOMEONE_HEALTH_PARTNER',
  HELM_PARTNER = 'HELM_PARTNER',
  EASE = 'EASE',
  RECHARGE = 'RECHARGE',
  SELECT = 'SELECT',
  PORTOBELLO = 'PORTOBELLO'
}

export const CLIENT_BRAND_LABEL: Record<ClientBrand, string> = {
  HELM: 'helm',
  CORDS: 'CORDS',
  SOMEONE_HEALTH: 'someone.health',
  TACKLIT: 'Tacklit',
  CAW: 'Caraniche at Work',
  SOMEONE_HEALTH_PARTNER: 'someone.health',
  HELM_PARTNER: 'helm',
  EASE: 'EASE Wellbeing',
  RECHARGE: 'Recharge Wellness',
  SELECT: 'Select Psychology',
  PORTOBELLO: 'Portobello Behavioural Health'
};

const isClientBrandEnum = stringEnumValidator(ClientBrand);

export const CLIENT_BRAND =
  (isClientBrandEnum(process.env.REACT_APP_CLIENT_BRAND) && process.env.REACT_APP_CLIENT_BRAND) || ClientBrand.TACKLIT;

export const CLIENT_BRAND_LOWER_CASE = CLIENT_BRAND.toLowerCase();

// From Tacklit
export const getClientDomainUrlFromBrand = (brand?: string) => {
  if (brand && process.env?.[`REACT_APP_CLIENT_DOMAIN_${brand}`]) {
    return process.env?.[`REACT_APP_CLIENT_DOMAIN_${brand}`];
  }
  return process.env.REACT_APP_CLIENT_DOMAIN;
};
