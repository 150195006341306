import smpListBullet from 'assets/images/smp/smpListBullet.png';
import smpOverviewBg from 'assets/images/smp/smpOverviewBg.png';
import SMPLayoutPdf from 'components/SMP/SMPLayoutPdf/SMPLayoutPdf';
import styles from './SMPPlanOverview.module.scss';

const listStyleImg = { listStyleImage: `url(${smpListBullet})` };

interface SMPPlanOverviewProps {
  submittedBy?: string;
}

const SMPPlanOverview = ({ submittedBy }: SMPPlanOverviewProps): JSX.Element => {
  return (
    <SMPLayoutPdf
      className={styles.container}
      header
      footer
      style={{ backgroundImage: `url(${window.location.origin + smpOverviewBg})` }}
      submittedBy={submittedBy}
    >
      <div className={styles.innerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Plan Overview</div>
          <div className={styles.subtitle}>
            This document will become a key tool for you to use to reflect post program
          </div>
        </div>
        <ul className={styles.list}>
          <li className={styles.listItem} style={listStyleImg}>
            <div className={styles.label}>Your Goals</div>
            <div>
              Thinking about your own use, what are some things you can do to reduce your risk of harm? For instance,
              this could be quitting, using less, not mixing drugs, or using more safely.
            </div>
          </li>
          <li className={styles.listItem} style={listStyleImg}>
            <div className={styles.label}>Your Relapse Signs</div>
            <div>
              By now you will have a better understanding of your own warning signs for lapsing or relapsing, including
              your high-risk situations, unhelpful thoughts, difficult or triggering emotions.
            </div>
          </li>
          <li className={styles.listItem} style={listStyleImg}>
            <div className={styles.label}>Your Supports and Strategies</div>
            <div>
              Some things I can do to feel good, enjoy myself and help with my goals. Including things you can do to
              avoid using or minimise your risk so you can stay on track to achieve your goals.
            </div>
          </li>
        </ul>
      </div>
    </SMPLayoutPdf>
  );
};

export default SMPPlanOverview;
