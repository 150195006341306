import { useCallback, useEffect, useState } from 'react';

import styles from './Security.module.scss';
import { ClientSignUpInterface, SecurityErrorInterface } from '../../SignUpFormInterface';
import classnames from 'classnames';
import MaterialInput from '../../../../../components/MaterialInput/MaterialInput';
import PasswordRules from './components/PasswordRules/PasswordRules';
import { validateEmailInvite, validationSecurityForm } from './validation/SecurityValidation';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { debounce } from 'lodash';

interface SecurityProps {
  accountId: string;
  invitationFromName: string;
  securityDetailsValue: ClientSignUpInterface;
  checkValidation: boolean;
  onChangeSecurityField: (val: ClientSignUpInterface) => void;
}

const Security = ({
  securityDetailsValue,
  checkValidation,
  onChangeSecurityField,
  accountId,
  invitationFromName
}: SecurityProps) => {
  const [securityDetailsField, setSecurityDetailsField] = useState(securityDetailsValue);

  const [isNotInvitedEmail, setIsNotInvitedEmail] = useState(false);
  const [isEmailUsed, setIsEmailUsed] = useState(false);

  const [isCheckingInviteEmail, setIsCheckingInviteEmail] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    email: '',
    password: ''
  } as SecurityErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      const validate = validateSecurityField(securityDetailsValue);
      if (validate?.email === '') {
        validateInvitedEmail(securityDetailsValue.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation, securityDetailsValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckDuplicate = useCallback(
    debounce(async (value) => {
      const invitedOrUsed = await validateEmailInvite(accountId, value);
      if (invitedOrUsed) {
        setIsNotInvitedEmail(!invitedOrUsed.invited);
        setIsEmailUsed(invitedOrUsed.used);
      }
      setIsCheckingInviteEmail(false);
    }, 1000),
    [accountId]
  );

  const validateInvitedEmail = async (emailValue: string) => {
    if (accountId) {
      setIsCheckingInviteEmail(true);
      await debouncedCheckDuplicate(emailValue);
    }
  };

  const validateSecurityField = (BasicDetailsVal: ClientSignUpInterface) => {
    const validate = validationSecurityForm(BasicDetailsVal);
    setErrorMessage(validate as SecurityErrorInterface);
    return validate;
  };

  const handleChangeEmail = async (value: string) => {
    setIsNotInvitedEmail(false);
    setIsEmailUsed(false);
    const newClientField = {
      ...securityDetailsField,
      email: value
    };
    setSecurityDetailsField(newClientField);
    onChangeSecurityField(newClientField);
    if (checkValidation) {
      const validate = validateSecurityField(newClientField);
      if (validate?.email === '') {
        await validateInvitedEmail(value);
      }
    }
  };

  const handleChangePassword = async (value: string) => {
    const newClientField = {
      ...securityDetailsField,
      password: value
    };
    setSecurityDetailsField(newClientField);
    onChangeSecurityField(newClientField);
    if (checkValidation) {
      validateSecurityField(newClientField);
    }
  };

  const handlePasswordScore = (value: number) => {
    const newClientField = {
      ...securityDetailsField,
      passwordScore: value
    };
    setSecurityDetailsField(newClientField);
    onChangeSecurityField(newClientField);
    if (checkValidation) {
      validateSecurityField(newClientField);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Create your <strong>free</strong> and <strong>secure</strong> account
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.subHeading}>
          <div className={styles.subHeadingTitle}>Confirm your email address</div>
          <div className={styles.subHeadingDesc}>
            It must match the invitation from <strong>{invitationFromName}</strong>
          </div>
        </div>
        <div
          className={classnames(
            styles.fieldContainer,
            checkValidation && (errorMessage.email || isNotInvitedEmail || isEmailUsed) && styles.fieldError
          )}
        >
          <MaterialInput
            label={'Email address*'}
            id={'email'}
            onChange={(e) => handleChangeEmail(e.target.value)}
            value={securityDetailsField.email}
            maxLength={120}
            rightComponent={
              isCheckingInviteEmail && (
                <div className={styles.loadingWrapper}>
                  <div className={styles.loadingBox}>
                    <LoadingCircle />
                  </div>
                </div>
              )
            }
            required
          />
          {checkValidation && errorMessage.email ? (
            <div className={styles.fieldError}>{errorMessage.email}</div>
          ) : isEmailUsed ? (
            <div className={styles.fieldError}>Email already in use, please contact an administrator</div>
          ) : isNotInvitedEmail ? (
            <div className={styles.fieldError}>Email does not belong to an existing invitation</div>
          ) : undefined}
        </div>
        <div className={styles.passwordSubheading}>
          <div className={styles.subHeadingTitle}>Set up a secure password</div>
          <div className={styles.subHeadingDesc}>It must meet the following password rules:</div>
        </div>
        <PasswordRules password={securityDetailsField.password} onChangePasswordScore={handlePasswordScore} />
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.password && styles.fieldError)}
        >
          <div>
            <MaterialInput
              label={'Set Secure Password*'}
              id={'password'}
              onChange={(e) => handleChangePassword(e.target.value)}
              value={securityDetailsField.password}
              maxLength={120}
              type={passwordVisibility ? 'text' : 'password'}
              rightComponent={
                <div className={styles.eyeIconWrapper}>
                  <div
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    className={`material-icons-outlined ${styles.eyeIcon}`}
                  >
                    {passwordVisibility ? 'visibility' : 'visibility_off'}
                  </div>
                </div>
              }
              required
            />
          </div>
          {checkValidation && errorMessage.password && <div className={styles.fieldError}>{errorMessage.password}</div>}
        </div>
      </div>
    </div>
  );
};

export default Security;
