import {
  AgeRangeList,
  MENTAL_HEALTH_LIST_LABELS,
  MentalHealthList,
  NUMBER_AGE_RANGE_LIST_LABELS,
  SpecialisationOptionList
} from 'SomeoneHealth/interfaces/mentalHealth';

export const generateAgeRangeList = () => {
  return Object.keys(AgeRangeList)
    .map((listObj) => ({
      id: listObj,
      label: NUMBER_AGE_RANGE_LIST_LABELS[listObj as keyof typeof AgeRangeList]
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const generateMentalHealthList = () => {
  return Object.values(MentalHealthList).map((item) => ({
    id: item,
    label: MENTAL_HEALTH_LIST_LABELS[item],
    inOtherList: SpecialisationOptionList.some((obj) => obj === item)
  }));
};

export const generateSomeoneHealthMentalHealthList = () => {
  return Object.values(MentalHealthList).map((item) => ({
    id: item,
    label: MENTAL_HEALTH_LIST_LABELS[item],
    inOtherList: SpecialisationOptionList.some((obj) => obj === item)
  }));
};
