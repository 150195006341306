import styles from './CaWFooter.module.scss';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import moment from 'moment';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';

const CaWFooter = () => {
  const URL = cawEnvironment();
  return (
    <footer className={styles.footerContainer}>
      <CaWContentLayout className={styles.container}>
        <div className={styles.footerTop}>
          <nav className={styles.list}>
            <ul className={styles.listGroup}>
              <li className={styles.text}>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWHomeURL}>
                  Home
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWAboutURL} aria-current="page">
                  About
                </a>
                <ul>
                  <li>
                    <a rel="noreferrer" target={'_blank'} href={URL.CaWOurApproachURL}>
                      Our Approach
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" target={'_blank'} href={URL.CaWOurPeopleURL}>
                      Our People
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWOurServiceURL}>
                  Services
                </a>
                <ul>
                  <li>
                    <a rel="noreferrer" target={'_blank'} href={URL.CaWOurEnhanceURL}>
                      Enhance
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" target={'_blank'} href={URL.CaWOurSupportURL}>
                      Support
                    </a>
                  </li>
                  <li>
                    <a rel="noreferrer" target={'_blank'} href={URL.CaWOurConnectURL}>
                      Connect
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWOurResourcesURL}>
                  Resources
                </a>
              </li>
            </ul>
          </nav>
          <nav className={styles.list}>
            <ul>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWTrainingURL}>
                  Training
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWCareersURL}>
                  Careers
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWContactUsURL}>
                  Contact
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWFeedbackURL}>
                  Feedback
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWYourPrivacyNConfidentialityURL}>
                  Your Privacy &amp; Confidentiality
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWChildSafetyURL}>
                  Child safety and our statement of commitment
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWTermAndConditions}>
                  Website Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a rel="noreferrer" target={'_blank'} href={URL.CaWPrivacyPolicy}>
                  Website Privacy Policy
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.footerMiddle}>
          <div className={styles.infoWrapper}>
            <div className={styles.box}>
              <div className={styles.item}>
                General enquiries:&nbsp;<a href="tel:0384170500">(03) 8417 0500</a>
              </div>
              <div className={styles.item}>
                Support for existing customers:&nbsp;<a href="tel:1800099444">1800 099 444</a>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.item}>
                Fax:&nbsp;<a href="tel:0394170181">(03) 9417 0181</a>
              </div>
              <div className={styles.item}>Post: Level 1/260 Hoddle St, Abbotsford VIC 3067</div>
            </div>
          </div>
          <ul className={styles.socialIcons}>
            <div>
              <a
                className={styles.icon}
                rel={'noreferrer'}
                href="https://www.facebook.com/Caraniche-at-Work-1530587317018042/"
                target="_blank"
              >
                f
              </a>
            </div>
            <div>
              <a
                className={styles.icon}
                rel={'noreferrer'}
                href="https://www.linkedin.com/company/caraniche-at-work/"
                target="_blank"
              >
                i
              </a>
            </div>
          </ul>
        </div>
        <div className={styles.footerBottom}>
          <span>
            © Copyright Caraniche at Work {moment().format('YYYY')} | We acknowledge and pay respect to the Traditional
            Owners of the lands upon which our offices are situated.
          </span>
        </div>
      </CaWContentLayout>
    </footer>
  );
};

export default CaWFooter;
