import styles from './CaWHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import CaWContentLayout from '../CaWContentLayout/CaWContentLayout';
import LoginAvatar from 'CaW/components/LoginAvatar/LoginAvatar';
import LOGO from 'CaW/assets/images/logo/logo.png';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';

interface CaWHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  classNames?: string;
  loginTextClass?: string;
  blueMenu?: boolean;
}

const CaWHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  classNames,
  loginTextClass,
  blueMenu
}: CaWHeaderProps) => {
  const navigate = useNavigate();
  const URLs = cawEnvironment();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = URLs.CaWHomeURL;
    }
  };

  return (
    <CaWContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, classNames)}
    >
      <div id={'CaWHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img className={styles.logo} alt={'logo'} src={LOGO} />
        </div>
        {!noLogin && (
          <LoginAvatar whiteFont={whiteFont} pinkMenu={blueMenu} withMenu={withMenu} loginTextClass={loginTextClass} />
        )}
      </div>
    </CaWContentLayout>
  );
};

export default CaWHeader;
