import styles from './CORDSHeader.module.scss';
import CORDSLogo from 'CORDS/assets/images/logo/CORDS-Logo.png';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface CORDSHeaderProps {
  contentClassNames?: string;
}

const CORDSHeader = ({ contentClassNames }: CORDSHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={classnames(contentClassNames)}>
        <Link to={'/'} className={styles.logoBox}>
          <img className={styles.logo} src={CORDSLogo} alt={'logo'} />
        </Link>
      </div>
    </div>
  );
};

export default CORDSHeader;
