import styles from './AppointmentTypeCard.module.scss';
import { AvailabilityAppointmentTypes } from 'utils/hooks/appointment';
import classNames from 'classnames';

interface AppointmentTypeCardProps {
  appointmentTypeData: AvailabilityAppointmentTypes;
  onClick: () => void;
  selected: boolean;
  disable?: boolean;
}

const AppointmentTypeCard = ({ appointmentTypeData, onClick, selected, disable }: AppointmentTypeCardProps) => (
  <div
    className={classNames(styles.container, selected && styles.selected, disable && styles.disable)}
    onClick={() => onClick()}
  >
    <div className={styles.header}>
      <div className={styles.title}>{appointmentTypeData.name}</div>
      {appointmentTypeData.isAdvisory && <div className={`material-icons ${styles.advisorIcon}`}>support_agent</div>}
    </div>
    <div className={styles.desc}>{appointmentTypeData.description}</div>
    <div className={styles.detailWrapper}>
      <div className={styles.detailCard}>
        <div className={`material-icons ${styles.icon}`}>query_builder</div>
        <div>{appointmentTypeData.duration + ' minutes'}</div>
      </div>
      {appointmentTypeData?.rate !== undefined && appointmentTypeData?.rate > 0 && (
        <div className={styles.detailCard}>
          <div className={`material-icons-outlined ${styles.icon}`}>currency_pound</div>
          <div>
            {appointmentTypeData.rate}
            {appointmentTypeData.slotCount &&
              appointmentTypeData.slotCount > 1 &&
              ` for ${appointmentTypeData.slotCount} sessions`}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default AppointmentTypeCard;
