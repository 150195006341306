import { useRef, useEffect } from 'react';

import styles from './LetterDraftContent.module.scss';
import { debounce } from 'lodash';
import { Letter } from 'interfaces/letter';
import ReportContentHeader from 'pages/ReportViewPage/components/ReportDraftContent/components/ReportContentHeader/ReportContentHeader';
import SignatureSection from 'pages/ReportViewPage/components/ReportDraftContent/components/SignatureSection/SignatureSection';

interface LetterDraftContentProps {
  data: Letter;
  clinicianDetails: any;
  contentHeight?: any;
}

const LetterDraftContent = ({ data, clinicianDetails, contentHeight }: LetterDraftContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getContentHeight = debounce(() => {
      if (contentRef.current !== null) {
        const { height, width } = contentRef.current.getBoundingClientRect();
        contentHeight(height, width);
      }
    }, 300);

    window.addEventListener('resize', getContentHeight);
    getContentHeight();
    return () => {
      window.removeEventListener('resize', getContentHeight);
    };
  }, [contentHeight]);

  return (
    <div ref={contentRef} className={styles.container}>
      <div className={styles.contentWrapper}>
        <ReportContentHeader
          reportName={data.letterName}
          clinicianDetails={clinicianDetails}
          clinicianContactData={data.contactDetails}
          issueDate={data.issueDate}
        />
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: data.body }} />
        <SignatureSection signVal={data.clinicianSignature} extraDetailsVal={data.clinicianSignatureExtraDetails} />
      </div>
    </div>
  );
};

export default LetterDraftContent;
