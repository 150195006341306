import { clinicianProfileServicesApiSlice } from '../../services/clinicianProfileServicesApiSlice';

export interface EmployerCode {
  code: string;
  name: string;
  maxSession: number;
  renewalDate: string; // DD/MM
  groupId: string;
  clientRecordTagId: string;
  noReservationAssessmentFormId?: string; // paperform id
  allowSignupWithoutReservation?: boolean;
}

export const employerCodesApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployerCodes: builder.query<EmployerCode[], null>({
      query: () => ({
        url: '/accounts/employerCodes'
      }),
      providesTags: ['EmployerCodes']
    })
  })
});

export const { useGetEmployerCodesQuery } = employerCodesApiSlice;
