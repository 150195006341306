import { Route, Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import { UserContextProvider } from 'utils/UserContextProvider';
import PortobelloLoginWrapper from 'Portobello/components/Layout/PortobelloLoginWrapper/PortobelloLoginWrapper';
import PortobelloWrapper from 'Portobello/components/Layout/PortobelloWrapper/PortobelloWrapper';
import SignUp from 'Portobello/pages/SignUp/SignUp';
import PsychometricList from 'pages/Psychometric/PsychometricList/PsychometricList';
import PatientAdHocAssessment from 'pages/PatientAdHocAssessment/PatientAdHocAssessment';
import { usePortobelloRoutesGenerator } from 'Portobello/utils/path/PortobelloRoutesGenerator';
import InfoSetup from 'Portobello/pages/InfoSetup/InfoSetup';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import Welcome from 'Portobello/pages/Welcome/Welcome';
import PortobelloHomePage from 'Portobello/pages/PortobelloHomePage/PortobelloHomePage';
import UpdateProfile from 'Portobello/pages/UpdateProfile/UpdateProfile';
import AppointmentsOverview from 'Portobello/pages/AppointmentsOverview/AppointmentsOverview';
import PatientCheckIn from 'pages/PatientCheckIn/PatientCheckIn';
import Onboarding from 'pages/OnboardingAssessment/Onboarding/Onboarding';
import SurveyListWrapper from 'Portobello/pages/Survey/SurveyListWrapper/SurveyListWrapper';
import SurveyQuestionWrapper from 'Portobello/pages/Survey/SurveyQuestionWrapper/SurveyQuestionWrapper';
import ConsentListWrapper from 'Portobello/pages/ConsentListWrapper/ConsentListWrapper';
import ConsentFormSignUp from 'Portobello/pages/ConsentFormSignUp/ConsentFormSignUp';
import FormsPage from 'Portobello/pages/FormsPage/FormsPage';

const PortobelloRoutes = () => {
  const {
    SIGNUP,
    // AVATAR,
    HOME,
    // PENDING_ACTIONS,
    UPDATE_PROFILE,
    // MY_PSYCHOLOGIST,
    // REFERRAL,
    APPOINTMENT_OVERVIEW,
    ONBOARDING,
    SURVEY,
    PSYCHOMETRIC,
    CHECKIN,
    CONSENT,
    // INVOICE,
    // REPORT,
    // LETTER,
    FORM
  } = usePortobelloRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: PortobelloWrapper })}
        {appRoute({
          path: SIGNUP.INFO_SETUP,
          component: InfoSetup,
          layout: (props) => <PortobelloWrapper {...props} colorBg />
        })}
        {appRoute({
          path: SIGNUP.WELCOME,
          component: Welcome,
          layout: (props) => <PortobelloWrapper {...props} colorBg />
        })}

        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: PortobelloLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: PortobelloLoginWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: SurveyListWrapper,
          layout: PortobelloLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: SurveyQuestionWrapper,
          layout: PortobelloLoginWrapper
        })}

        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: HOME, component: PortobelloHomePage, layout: PortobelloLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: PortobelloLoginWrapper })}
          {appRoute({
            path: APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: PortobelloLoginWrapper
          })}

          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: PortobelloLoginWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: PortobelloLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: PortobelloLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: PortobelloLoginWrapper })}
          {appRoute({ path: FORM.DETAILS, component: FormsPage, layout: PortobelloLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default PortobelloRoutes;
