import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { retrieveUserSession } from 'Ease/pages/SignUp/helper/userSession';

export const useEaseToken = (signUpSessionId?: string) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const loginToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          setToken(loginToken);
        })();
      } else {
        const storedTempAuthToken = retrieveUserSession(signUpSessionId).authToken;
        if (storedTempAuthToken) {
          setToken(storedTempAuthToken);
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, signUpSessionId]);

  return { token };
};
