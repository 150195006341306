import { useAuth0 } from '@auth0/auth0-react';
import { MouseEvent, useState } from 'react';
import { useLogout } from 'utils/hooks/logout';
import styles from './HeaderMenu.module.scss';
import TaskButton from 'Select/components/TaskButton/TaskButton';

interface HeaderMenuProps {
  children: JSX.Element;
  menuList: {
    name: string;
    url: string;
    isExternal: boolean;
  }[];
}

const HeaderMenu = ({ children, menuList }: HeaderMenuProps) => {
  const { user } = useAuth0();
  const { logout } = useLogout();
  const [showMenu, setShowMenu] = useState(false);

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuTrigger} onClick={() => setShowMenu(true)}>
        {children}
      </div>

      {showMenu && (
        <>
          <div className={styles.overlay} onClick={() => setShowMenu(false)} />
          <div className={styles.menuBox}>
            <div className={styles.greeting} onClick={stopPropagation}>
              Hi, {user?.name}!
            </div>
            {menuList.map((item, index) => (
              <TaskButton key={index} item={item} />
            ))}
            <div className={styles.signOut} onClick={() => logout()}>
              Sign Out
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
