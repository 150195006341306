import { scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { AvailabilityAppointmentTypes } from 'utils/hooks/appointment';

interface GetAppointmentTypesRequest {
  accountId: string;
  clinicianId?: string;
  isGP?: boolean;
}

export const appointmentTypes = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentTypes: builder.query<AvailabilityAppointmentTypes[], GetAppointmentTypesRequest>({
      query: ({ accountId, clinicianId, isGP }) => ({
        url: `/availability/appointment-types/public?accountId=${accountId}${
          clinicianId ? `&clinicianId=${clinicianId}` : ''
        }${isGP ? '&isGP=1' : ''}`
      }),
      providesTags: ['AppointmentTypes']
    })
  })
});

export const { useGetAppointmentTypesQuery } = appointmentTypes;
