import ButtonSelect, { ButtonStatusType } from 'Select/components/ButtonSelect/ButtonSelect';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectTimer from 'Select/components/SelectTimer/SelectTimer';
import { useFetchReservedAppointmentsWithTimeZone } from 'Select/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import { useSelectToken } from 'Select/utils/hooks/useSelectToken';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { message } from 'antd';
import classNames from 'classnames';
import Loading from 'components/Loading/Loading';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useContext, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { UserContext } from 'utils/UserContextProvider';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './SignUpBookingConfirmation.module.scss';
import PaymentPolicy from './components/PaymentPolicy/PaymentPolicy';
import Summary from './components/Summary/Summary';
import { putProcessWithPaymentMethod, putProcessWithoutPayment } from 'utils/http/appointment';
import { CheckoutSessionMode } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { BookingRuleType } from 'utils/hooks/clientRecords';

const SignUpBookingConfirmation = () => {
  const { token, isInitialised } = useSelectToken();
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { PRACTITIONER, SIGNUP } = useSelectRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
  const { accountId, clinicianId, reserveId, clientBookingRule } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!
  });

  useEffect(() => {
    if (!reserveId || !clinicianId) {
      navigate(PRACTITIONER.LISTING);

      if (clinicianId) {
        const detailsPagePath = generatePath(PRACTITIONER.DETAILS, { clinicianId });
        navigate(detailsPagePath);
      } else {
        navigate(PRACTITIONER.LISTING);
      }
    } else if (isInitialised && !token) {
      navigate(SIGNUP.BASE);
    }
  }, [PRACTITIONER.DETAILS, PRACTITIONER.LISTING, SIGNUP.BASE, clinicianId, isInitialised, navigate, reserveId, token]);

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isPaymentNotRequired = clientBookingRule === BookingRuleType.Existing;

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        const res = await (isPaymentNotRequired
          ? putProcessWithoutPayment(accountId, reserveId, token)
          : putProcessWithPaymentMethod(
              accountId,
              reserveId,
              {
                mode: CheckoutSessionMode.Payment,
                cancelUrl: window.location.href,
                returnUrl: `${window.location.origin}${SIGNUP.WELCOME}`,
                saveCardForFutureUse: true
              },
              token
            ));

        if (res.statusCode === 200) {
          const responseData = await res.json();

          if (responseData.checkoutUrl) {
            window.location.href = responseData.checkoutUrl;
          }
        } else if (res.statusCode === 204) {
          navigate(SIGNUP.WELCOME);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <SelectHelmetWrapper title={'Select Psychology | Booking confirmation'}>
        <SelectHeader withPadding withMenu />
        <SelectContentLayout className={styles.layoutClassName}>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'select-theme')}>
              <div className={styles.content}>
                {/* {!isAuthenticated && (
                  <div className={styles.clientTestimonialCard}>
                    <ClientTestimonial
                      avatar="https://storage.googleapis.com/clinician-avatars/e160a4d42e82e0c300359427b45eb0c9-auth0|61137cdc838de9006a4ca594-profile-avatar.png"
                      name="Select Client"
                      message='" Incredibly supportive in a non-judgmental space. My therapist helped me recognise the value of who I am as a person and to acknowledge my value. Also, helped me understand why I have acted and responded the way I have. It has provided me with an opportunity to learn and grow from my experiences. "'
                    />
                  </div>
                )} */}
                <div className={styles.leftContent}>
                  <div className={styles.greet}>
                    Thanks {clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}
                  </div>
                  <Summary
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    isPaymentNotRequired={isPaymentNotRequired}
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <PaymentPolicy policyAccepted={policyAccepted} onSetPolicyAccepted={setPolicyAccepted} />
                  <ButtonSelect
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!policyAccepted || !token}
                    className={styles.submitButton}
                  >
                    {isPaymentNotRequired ? 'Confirm Appointment' : 'Continue to payment'}
                  </ButtonSelect>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <div className={styles.noticeMsg}>
                      <div>
                        To confirm these appointments you need to{' '}
                        {isPaymentNotRequired ? 'finalise the booking' : 'complete registration and payment'} within 15
                        minutes
                      </div>
                    </div>
                    <SelectTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                    />
                  </div>
                  {/* {!mobileTimer && !isAuthenticated && (
                    <ClientTestimonial
                      avatar="https://storage.googleapis.com/clinician-avatars/auth0|6581174c855740bf71437d9c/c41a22d7a24c8e860b5b2ee958022cbc-5.png"
                      name="Select Client"
                      message={`" Therapy has been a game-changer for me. In a supportive environment, I found valuable tools to navigate life's challenges. Grateful for the positive impact on my well-being. "`}
                    />
                  )} */}
                </div>
              </div>
            </div>
          )}
        </SelectContentLayout>
        <TacklitFooter darkFooter />
      </SelectHelmetWrapper>
    </div>
  );
};

export default SignUpBookingConfirmation;
