import { useEffect, useMemo, useState } from 'react';
import styles from './PsychologistListing.module.scss';
import { defaultClinicianTimeZone, useFetchPractitionerList } from './hooks/getPsychologistList';
import { useParams, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Skeleton } from 'antd';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeFooter from 'Recharge/components/RechargeFooter/RechargeFooter';
import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import ListingFilter from 'Recharge/components/RechargeFilter/ListingFilter/ListingFilter';
import FitFilter from 'Recharge/components/RechargeFilter/FitFilter/FitFilter';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment/moment';
import {
  FitPsychologistGenderList,
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitStyleList,
  FitTimesList
} from 'Recharge/interfaces/fitFilter';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Recharge/interfaces/mentalHealth';
import { useAuth0 } from '@auth0/auth0-react';
import PsychologistBasicDetails from './components/PsychologistBasicDetails/PsychologistBasicDetails';
import PsychologistBonaFides from 'Recharge/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistAvatar from 'Recharge/components/PsychologistAvatar/PsychologistAvatar';
import PsychologistAvailabilityCalendar from './components/PsychologistAvailabilityCalendar/PsychologistAvailabilityCalendar';
import PsychologistSpecialisation from './components/PsychologistSpecialisation/PsychologistSpecialisation';
import queryString from 'query-string';
import JoinWaitlistForm from 'Recharge/components/JoinWaitlistForm/JoinWaitlistForm';
import momentTz from 'moment-timezone';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { RECHARGE_TIME_ZONE_LIST, rechargeTimeZoneLocalStorage } from 'utils/constants/timeZone';
import classNames from 'classnames';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

export interface PsychologistFitFilter {
  gender?: FitPsychologistGenderList;
  day?: FitDaysList;
  time?: FitTimesList;
  deliveryMode?: FitDeliveryList;
  language?: FitLanguageList;
  style?: FitStyleList;
}

const getTimeFilterInMelbourneTimezone = ({ time, timezone }: { time?: FitTimesList; timezone: string }) => {
  if (time === FitTimesList.morning) {
    return {
      startTime: '00:00',
      endTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.afternoon) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.evening) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: '24:00'
    };
  }

  return {};
};

const PsychologistListing = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const rechargeClientTimeZone = localStorage.getItem(rechargeTimeZoneLocalStorage);
  const { filter } = useParams<{ filter: string }>();
  const getPathFilter = filter?.split('-') || [];

  const getMentalConcernFilter: MentalHealthList[] = Object.values(MentalHealthList).filter((obj) =>
    getPathFilter.includes(obj)
  ) as MentalHealthList[];

  const { gender, day, time, deliveryMode, language, style }: PsychologistFitFilter = queryString.parse(queryParam);

  const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] =
    useState<MentalHealthList[]>(getMentalConcernFilter);
  const [selectedFitFilter, setSelectedFitFilter] = useState<PsychologistFitFilter>({
    gender: gender || undefined,
    day: day || undefined,
    time: time || undefined,
    deliveryMode: deliveryMode || undefined,
    language: language || undefined,
    style: style || undefined
  });

  const [showWaitlistForm, setShowWaitlistForm] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerDetailsInterface | undefined>(undefined);

  useEffect(() => {
    // Listen to path change
    setSelectedMentalHealthConcern(getMentalConcernFilter);
    setSelectedFitFilter({
      gender: gender || undefined,
      day: day || undefined,
      time: time || undefined,
      deliveryMode: deliveryMode || undefined,
      language: language || undefined,
      style: style || undefined
    });
    // will hit infinity loop if we listen to filter value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();

  const [selectedTimezone, setSelectedTimezone] = useState(
    rechargeClientTimeZone ||
      // use browser timezone if browser timezone is supported
      RECHARGE_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      RECHARGE_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      RECHARGE_TIME_ZONE_LIST[0].id
  );

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  const filterQuery = useMemo(() => {
    const { day, ...selectedFitFilterRest } = selectedFitFilter;

    return {
      ...selectedFitFilterRest,
      clientMentalHealthConcern: selectedMentalHealthConcern.join(','),
      days: day,
      deliveryMode: selectedFitFilter.deliveryMode,
      ...getTimeFilterInMelbourneTimezone({ time: selectedFitFilter.time, timezone: selectedTimezone }),
      isNewClient
    };
  }, [selectedFitFilter, selectedMentalHealthConcern, selectedTimezone, isNewClient]);

  const {
    psychologistList,
    isPractitionerListLoading,
    loadMorePsychologists,
    hasMorePsychologists,
    matchedSpecialisations
  } = useFetchPractitionerList({
    clientTimeZone: selectedTimezone,
    filterQuery,
    shouldFetch: !isClientBookingRuleLoading
  });

  const noResultFilterQuery = useMemo(
    () => ({
      isNewClient,
      perPage: 10
    }),
    [isNewClient]
  );

  const { psychologistList: psychologistListNoResult } = useFetchPractitionerList({
    clientTimeZone: selectedTimezone,
    filterQuery: noResultFilterQuery
  });

  const handleChangeTimeZone = (val: string) => {
    setSelectedTimezone(val);
    localStorage.setItem(rechargeTimeZoneLocalStorage, val);
  };

  const handleNavigatePath = (value: string[], queryParamValue?: PsychologistFitFilter) => {
    const generateFilterPath = value.filter((obj) => obj !== undefined).join('-');
    const newPath = generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
      filter: generateFilterPath
    });
    const generateQueryParam = queryString.stringify(queryParamValue || selectedFitFilter, { sort: false });
    navigate(`${newPath}${generateQueryParam ? `?${generateQueryParam}` : ''}`);
  };

  const handleMentalHealthChange = (value: listInterface) => {
    const checkIfDuplicate = selectedMentalHealthConcern.some((mhObj) => mhObj === value.id);

    const newCollection: MentalHealthList[] = checkIfDuplicate
      ? selectedMentalHealthConcern.filter((mhObj) => mhObj !== value.id)
      : [...selectedMentalHealthConcern, value.id as MentalHealthList];

    setSelectedMentalHealthConcern(() => newCollection);
    handleNavigatePath(newCollection);
  };

  const handleFitResetValue = () => {
    const defaultValue = {
      gender: undefined,
      day: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath(selectedMentalHealthConcern, defaultValue);
  };

  const clearAllFilter = () => {
    setSelectedMentalHealthConcern([]);
    const defaultValue = {
      gender: undefined,
      day: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath([], defaultValue);
  };

  const handleChangeFit = (fitValue: PsychologistFitFilter) => {
    setSelectedFitFilter(fitValue);
    handleNavigatePath(selectedMentalHealthConcern, fitValue);
  };

  const psychologistListCard = (psychologistList: PractitionerDetailsInterface[], reverseBg?: boolean) => (
    <div className={styles.container}>
      <InfiniteScroll
        loadMore={loadMorePsychologists}
        initialLoad={false}
        pageStart={0}
        hasMore={hasMorePsychologists}
        loader={
          <div className={styles.loadMoreWrapper} key={'loading'}>
            <LoadingDot />
          </div>
        }
      >
        {psychologistList.map((obj, i) => {
          const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
            clinicianId: obj.slugUrl
          });
          const detailPath = `${detailsPagePath}`;
          const detailPathWithTimeSlot = `${detailsPagePath}`;
          const matchedSecondarySpecialisations =
            matchedSpecialisations &&
            matchedSpecialisations.length > 0 &&
            obj.helmControl.secondarySpecialisations &&
            obj.helmControl.secondarySpecialisations.length > 0
              ? obj.helmControl.secondarySpecialisations
                  .filter((item) => matchedSpecialisations.includes(item))
                  .map((item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList])
              : [];

          return (
            <div className={reverseBg ? styles.contentWrapperRs : styles.contentWrapper} key={i}>
              <RechargeContentLayout>
                <div className={styles.content}>
                  <PsychologistBasicDetails
                    practitionerDetails={obj}
                    detailPath={detailPath}
                    className={styles.mobileDetails}
                    selectedValue={[selectedFitFilter.deliveryMode || '', selectedFitFilter.language || '']}
                  />
                  <div className={styles.profileWrapper}>
                    <PsychologistAvatar
                      className={styles.profile}
                      classNameImage={styles.profileImg}
                      profileImg={obj.avatar}
                      detailPath={detailPath}
                    />
                    <PsychologistBonaFides limitDisplayQualifications bonaFidesDetails={obj.helmControl.bonaFides} />
                  </div>
                  <div className={styles.detailsContainer}>
                    <PsychologistBasicDetails
                      practitionerDetails={obj}
                      detailPath={detailPath}
                      className={styles.details}
                      selectedValue={[selectedFitFilter.deliveryMode || '', selectedFitFilter.language || '']}
                    />
                    {obj.helmControl.primarySpecialisations && obj.helmControl.primarySpecialisations.length > 0 && (
                      <PsychologistSpecialisation
                        label={'MAIN AREAS OF PRACTICE'}
                        specialisationsDetails={obj.helmControl.primarySpecialisations.map(
                          (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                        )}
                        selectedValue={selectedMentalHealthConcern.map(
                          (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                        )}
                        limit={5}
                        matchedSpecialisations={matchedSpecialisations.map(
                          (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                        )}
                        isDarkerBackground={i % 2 === 1}
                      />
                    )}

                    {/*  Show this only if any in secondary specialisations matched with selected filters
                     *   and show only matched value
                     */}
                    {matchedSecondarySpecialisations.length > 0 && (
                      <PsychologistSpecialisation
                        label={'I CAN ALSO HELP WITH'}
                        specialisationsDetails={matchedSecondarySpecialisations}
                        badgeClassName={styles.highlightSecondarySpecs}
                        isDarkerBackground={i % 2 === 1}
                      />
                    )}
                    <PsychologistAvailabilityCalendar
                      practitionerDetails={obj}
                      detailPath={detailPathWithTimeSlot}
                      nextAvailabilityDetails={obj.nextAvailabilities?.filter((item) =>
                        isNewClient
                          ? item.appointmentTypeBookingRules?.new.available
                          : item.appointmentTypeBookingRules?.existing.available
                      )}
                      isDarkerBackground={i % 2 === 1}
                      onShowWaitlistForm={() => {
                        setSelectedPractitioner(obj);
                        setShowWaitlistForm(true);
                      }}
                    />
                  </div>
                </div>
              </RechargeContentLayout>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );

  return (
    <RechargeHelmetWrapper title={'Recharge Wellness | Mental Health, Psychologists, Therapy'} hubspot>
      <div className="recharge-theme">
        <div className={classNames(styles.headerContainer)}>
          <RechargeHeader withPadding whiteFont withMenu whiteMenu />
          <ListingFilter
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            onChangeMentalHealthConcern={handleMentalHealthChange}
            selectedTimezone={selectedTimezone}
            onChangeTimeZone={handleChangeTimeZone}
          />
        </div>
        <FitFilter
          selectedFitValue={selectedFitFilter}
          onChangeFitValue={handleChangeFit}
          handleFitResetValue={handleFitResetValue}
        />
        {isPractitionerListLoading || isClientBookingRuleLoading ? (
          <div className={styles.container}>
            {[...Array(4)].map((_, i) => (
              <div key={i} className={styles.contentWrapper}>
                <RechargeContentLayout>
                  <div className={styles.content}>
                    <div className={styles.loadingWrapper}>
                      <div className={styles.profileWrapper}>
                        <Skeleton.Avatar active size={70} className={styles.headerLoading} />
                        <div className={styles.bonaFidesLoadingWrapper}>
                          <Skeleton.Button active className={styles.bonaFidesLoading} />
                          <Skeleton.Button active className={styles.bonaFidesLoading} />
                        </div>
                      </div>
                      <div className={styles.detailsContainer}>
                        <Skeleton active />
                      </div>
                    </div>
                  </div>
                </RechargeContentLayout>
              </div>
            ))}
          </div>
        ) : psychologistList.length > 0 ? (
          psychologistListCard(psychologistList)
        ) : (
          <div className={styles.container}>
            <div className={styles.contentWrapper}>
              <RechargeContentLayout>
                <div className={styles.noResultWrapper}>
                  <div className={styles.noResultTitle}>Can’t find what you’re looking for?</div>
                  <div className={styles.noResultDesc}>
                    Sorry, we’re unable to provide a match. Please adjust your criteria or{' '}
                    <span className={styles.clearAllFiltersButton} onClick={clearAllFilter}>
                      clear all filters
                    </span>
                  </div>
                </div>
              </RechargeContentLayout>
            </div>
            <div className={styles.contentWrapper}>
              <RechargeContentLayout>
                <div className={styles.recommendWrapper}>
                  <div className={styles.trustLabel}>
                    <span className={styles.label}>These trusted clinicians are ready for you</span>
                  </div>
                </div>
              </RechargeContentLayout>
              {psychologistListCard(psychologistListNoResult, true)}
            </div>
          </div>
        )}
        <RechargeFooter />

        <Modal
          width={700}
          visible={showWaitlistForm && !!selectedPractitioner}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          {selectedPractitioner && (
            <JoinWaitlistForm
              onComplete={() => {
                setShowWaitlistForm(false);
              }}
              practitionerDetails={selectedPractitioner}
            />
          )}
        </Modal>
      </div>
    </RechargeHelmetWrapper>
  );
};

export default PsychologistListing;
