import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import moment from 'moment';
import SelectSlot from '../SelectSlot/SelectSlot';
import Button from 'helm/components/Button/Button';
import styles from './BookingSession.module.scss';
import { AvailabilityAppointmentTypes, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import classNames from 'classnames';
interface BookingSessionProps {
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  onRemoveSelectedSlot: (slot: TimeSlotsWithDateInterface) => void;
  isAvailabilityListLoading: boolean;
  isProcessingReservation: boolean;
  onContinue: () => void;
  isAdvisorySession?: boolean;
  continueLabelText?: string;
  continueBtnText?: string;
  btnWrapperClassName?: string;
  className?: string;
  customDesc?: string;
}
const BookingSession = ({
  selectedAppointmentType,
  selectedTimeSlots,
  onRemoveSelectedSlot,
  isAvailabilityListLoading,
  isProcessingReservation,
  onContinue,
  isAdvisorySession,
  continueLabelText = 'Your appointment will only be held for 15 mins',
  continueBtnText = 'Continue to pay and confirm',
  btnWrapperClassName,
  className,
  customDesc
}: BookingSessionProps) => (
  <div className={classNames(styles.bookingContainer, isAdvisorySession && styles.isAdvisorySession, className)}>
    <HelmContentLayout>
      <div className={styles.bookingContent}>
        <div className={styles.bookingDesc}>
          <div className={styles.bookingTitle}>You are booking</div>
          <div className={styles.bookingDescSummary}>
            {`${selectedAppointmentType?.name} | ${selectedAppointmentType?.duration} minutes ${
              selectedAppointmentType?.rate !== undefined && selectedAppointmentType?.rate > 0
                ? `| $${selectedAppointmentType?.rate}`
                : ''
            }`}
            <span className={styles.bookingOnLabel}>&nbsp;on</span>
          </div>
        </div>
        <div className={styles.slotContainer}>
          {selectedTimeSlots.filter((obj) => obj.isConflict).length > 0 && (
            <div className={styles.blocked}>
              Our counsellors are in high demand. Some times are no longer available. Please select another time.
            </div>
          )}
          <div className={styles.slotWrapper}>
            {selectedTimeSlots.map((timeSlotObj, index) => (
              <SelectSlot
                key={index}
                date={moment(timeSlotObj.date)}
                time={`${moment(timeSlotObj.startTime, 'hh:mmA').format('hh:mm')} - ${timeSlotObj.endTime}`}
                onDelete={() => onRemoveSelectedSlot(timeSlotObj)}
                blockSlot={timeSlotObj.isConflict}
              />
            ))}
            {Array.from(Array((selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length)).map((obj, i) => (
              <SelectSlot key={i} />
            ))}
          </div>
          <div className={styles.slotLabelMobile}>
            {customDesc || 'You can manage or change appointments in line with our cancellation policy'}
          </div>
        </div>
        <div className={classNames(styles.continueBtnWrapper, btnWrapperClassName)}>
          <Button
            className={styles.continueBtn}
            onClick={onContinue}
            disabled={
              isAvailabilityListLoading ||
              isProcessingReservation ||
              selectedTimeSlots.length < (selectedAppointmentType?.slotCount || 1)
            }
          >
            {continueBtnText} <i className={`material-icons ${styles.nextBtnIcon}`}>navigate_next</i>
          </Button>
          <div className={styles.continueLabel}>{continueLabelText}</div>
        </div>
      </div>
      <div className={styles.slotLabel}>
        {customDesc || 'You can manage or change appointments in line with our cancellation policy'}
      </div>
    </HelmContentLayout>
  </div>
);

export default BookingSession;
