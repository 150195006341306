import { Route, Routes } from 'react-router-dom';
import { UserContextProvider } from 'utils/UserContextProvider';
import CaWRoutes from './CaWRoutes';
import FacilitatedRoutes from '../FacilitatedRoutes';

const CaWRoutesCollection = () => (
  <UserContextProvider>
    <Routes>
      <Route path={'/facilitated/*'} element={<FacilitatedRoutes />} />
      <Route path={'/*'} element={<CaWRoutes />} />
    </Routes>
  </UserContextProvider>
);

export default CaWRoutesCollection;
