import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './PsychologistDetails.module.scss';
import { useFetchPractitionerDetails } from './hooks/getPsychologistDetails';
import PsychologistDetailsHeader from './components/PsychologistDetailsHeader/PsychologistDetailsHeader';
import PsychologistBonaFides from 'SomeoneHealth/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistSpecialisation from 'SomeoneHealth/pages/PsychologistListing/components/PsychologistSpecialisation/PsychologistSpecialisation';
import PsychologistQuote from './components/PsychologistQuote/PsychologistQuote';
import PsychologistTimeSlot from './components/PsychologistTimeSlot/PsychologistTimeSlot';
import PsychologistWelcomeNote from './components/PsychologistWelcomeNote/PsychologistWelcomeNote';
import PsychologistFAQ from './components/PsychologistFAQ/PsychologistFAQ';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import SomeoneHealthFooter from 'SomeoneHealth/components/SomeoneHealthFooter/SomeoneHealthFooter';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import PsychologistExtraService from './components/PsychologistExtraService/PsychologistExtraService';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import PractitionerImageSlideshow from 'components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'SomeoneHealth/interfaces/mentalHealth';
import { Modal } from 'antd';
import JoinWaitlistForm from 'SomeoneHealth/components/JoinWaitlistForm/JoinWaitlistForm';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

export interface PsychologistQueryParam {
  timeSlotSection?: boolean;
}

const { CancellationFee, AppointmentNoticePeriodInHour } = someoneHealthEnvironment();

export const SHFAQ = {
  title: 'Frequently Asked Questions',
  desc: "We're here to listen and help you through whatever mental health challenges you're facing.",
  faqData: [
    {
      title: 'Can I change my appointments?',
      desc: `Yes! We know sometimes plans need to change and so have a flexible policy that allows you to reschedule an appointment as long as you give us at least ${AppointmentNoticePeriodInHour} hours notice.  You can manage your appointments in your someone.health account.`
    },
    {
      title: 'Do I need a Medicare Card?',
      desc: `We don’t need the physical card, but you will need to know your Medicare card number.<br/>If you don’t already have a Medicare card you can find out about enrolling into Medicare with Services Australia. If you’ve lost your card or forgotten your card number, you can jump onto MyGov to find your details.`
    },
    {
      title: 'Do I need a GP referral?',
      desc: `In order to access your two bulk billed and receive rebates on additional appointments you do need a GP referral. You can either upload a referral from your own GP or you can book a video appointment with a someone.health GP.<br/><br/>You will be able to select a time to jump on a video call with one of our GPs once you’ve chosen your Psychologist appointment time. Our GP appointments are fully bulk billed and only take 20 minutes.`
    },
    {
      title: 'What costs are involved?',
      desc: `Your first two sessions are fully bulk billed. For sessions 3 to 10 you’ll need to pay the full amount first and then you will get your Medicare rebate soon after the appointment (if you are eligible). This means the amount you’ll pay after the Medicare rebate is just $40.<br/><br/>The only other fee is if you don't show up. Hopefully this doesn’t happen to you. But if you do miss an appointment or don’t give us at least ${AppointmentNoticePeriodInHour} hours heads-up, there’s a $${CancellationFee} fee.`
    }
  ]
};

const PsychologistDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('SomeoneHealthTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return (
    <SomeoneHealthHelmetWrapper
      title={`someone.health - ${practitionerName ? `${practitionerName} Profile` : 'psychologist profile'}`}
      hubspot
    >
      {isPractitionerDetailsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <PsychologistDetailsHeader practitionerDetails={practitionerDetails} listingLink={backToListingLink} />
          <div className={styles.container}>
            <SomeoneHealthContentLayout>
              <div className={styles.detailsContentWrapper}>
                {/* Left content */}
                <div className={styles.leftContent}>
                  <PsychologistWelcomeNote practitionerDetails={practitionerDetails} />

                  {/* Just show on mobile view */}
                  <div className={styles.appointmentBtnWrapper}>
                    <ButtonSH onClick={() => scrollToView('SomeoneHealthTimeSlot')} className={styles.appointmentBtn}>
                      Schedule Appointment
                    </ButtonSH>
                  </div>
                  {practitionerDetails.helmControl?.primarySpecialisations &&
                    practitionerDetails.helmControl.primarySpecialisations.length > 0 && (
                      <div>
                        <PsychologistSpecialisation
                          label={'MAIN AREAS OF PRACTICE'}
                          specialisationsDetails={practitionerDetails.helmControl.primarySpecialisations.map(
                            (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                          )}
                          badgeClassName={styles.mainAreaBadge}
                        />
                      </div>
                    )}
                  {practitionerDetails.helmControl?.secondarySpecialisations &&
                    practitionerDetails.helmControl.secondarySpecialisations.length > 0 && (
                      <div>
                        <PsychologistSpecialisation
                          label={'I CAN ALSO HELP WITH'}
                          specialisationsDetails={practitionerDetails.helmControl.secondarySpecialisations.map(
                            (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                          )}
                          badgeClassName={styles.secondaryAreaBadge}
                        />
                      </div>
                    )}
                  {practitionerDetails.helmControl?.styles && practitionerDetails.helmControl.styles.length > 0 && (
                    <div>
                      <PsychologistSpecialisation
                        label={'TRAINED IN'}
                        specialisationsDetails={practitionerDetails.helmControl.styles}
                        badgeClassName={styles.techniqueBadge}
                      />
                    </div>
                  )}
                </div>

                {/* Right content */}
                <div className={styles.rightContent}>
                  {practitionerDetails.helmControl?.additionalPhotos?.length > 0 && (
                    <div className={styles.additionalPhoto}>
                      <div className={styles.additionalPhotoCard}>
                        <PractitionerImageSlideshow
                          classNames={styles.slideShow}
                          slidePhoto={practitionerDetails.helmControl.additionalPhotos}
                        />
                      </div>
                    </div>
                  )}
                  <div className={styles.detailsWrapper}>
                    {/* Just show on desktop view */}
                    <div className={styles.appointmentBtnWrapper}>
                      <ButtonSH onClick={() => scrollToView('SomeoneHealthTimeSlot')} className={styles.appointmentBtn}>
                        Schedule Appointment
                      </ButtonSH>
                    </div>
                    {practitionerDetails.helmControl?.bonaFides && (
                      <PsychologistBonaFides
                        role={practitionerDetails.medicare?.role}
                        helmControl={practitionerDetails.helmControl}
                      />
                    )}
                    {practitionerDetails.helmControl?.fundingMethods &&
                      practitionerDetails.helmControl.fundingMethods?.length > 0 && (
                        <PsychologistExtraService
                          label={'FUNDING METHODS ACCEPTED'}
                          icon={'credit_card'}
                          servicesAvailable={practitionerDetails.helmControl.fundingMethods}
                        />
                      )}
                    {practitionerDetails.helmControl?.servicesAvailable &&
                      practitionerDetails.helmControl.servicesAvailable?.length > 0 && (
                        <PsychologistExtraService
                          label={'SERVICES AVAILABLE'}
                          icon={'summarize'}
                          servicesAvailable={practitionerDetails.helmControl.servicesAvailable}
                        />
                      )}
                  </div>
                </div>
              </div>
            </SomeoneHealthContentLayout>
          </div>
          {practitionerDetails.helmControl?.quote && (
            <PsychologistQuote quote={practitionerDetails.helmControl.quote} />
          )}
          <PsychologistTimeSlot
            helmDetails={practitionerDetails}
            onShowWaitlistForm={() => setShowWaitlistForm(true)}
          />
          <PsychologistFAQ data={SHFAQ} id={'SomeoneHealthFAQ'} />
          <SomeoneHealthFooter />
        </>
      )}

      {/* Join waitlist modal */}
      <Modal
        width={700}
        visible={showWaitlistForm}
        onCancel={() => {
          setShowWaitlistForm(false);
        }}
        bodyStyle={{ padding: '40px 80px', top: 50 }}
        footer={null}
        destroyOnClose
      >
        <JoinWaitlistForm
          onComplete={() => {
            setShowWaitlistForm(false);
          }}
          practitionerDetails={practitionerDetails}
        />
      </Modal>
    </SomeoneHealthHelmetWrapper>
  );
};

export default PsychologistDetails;
