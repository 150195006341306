import styles from './NoAvailableMessageBox.module.scss';
import classnames from 'classnames';
import ButtonEase from '../ButtonEase/ButtonEase';
import EaseContentLayout from '../EaseContentLayout/EaseContentLayout';

interface NoAvailableMessageBoxProps {
  title: string;
  desc: string | JSX.Element;
  buttonLabel: string;
  onClick: () => void;
  titleClassName?: string;
}

const NoAvailableMessageBox = ({ title, desc, buttonLabel, onClick, titleClassName }: NoAvailableMessageBoxProps) => (
  <EaseContentLayout>
    <div className={styles.container}>
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.buttonWrapper}>
        <ButtonEase onClick={onClick}>{buttonLabel}</ButtonEase>
      </div>
    </div>
  </EaseContentLayout>
);

export default NoAvailableMessageBox;
