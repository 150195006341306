import { Route, Routes } from 'react-router-dom';
import PracticeClientRoutes from './PracticeClientRoutes';
import ClientRoutes from './ClientRoutes';
import FacilitatedRoutes from './FacilitatedRoutes';
import BazaarRoutes from './BazaarRoutes';
import SMPRoutes from './SMPRoutes';
import RedirectClinician from 'components/RedirectClinician/RedirectClinician';
import { UserContextProvider } from '../utils/UserContextProvider';
import ZaiPayment from 'pages/ZaiPayment/ZaiPayment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const AuthRoutes = () => {
  return (
    <UserContextProvider>
      <Routes>
        <Route path={'/p/*'} element={<PracticeClientRoutes />} />
        <Route path={'/facilitated/*'} element={<FacilitatedRoutes />} />
        <Route path={'/bazaar/*'} element={<BazaarRoutes />} />
        {isDevelopmentENV() && <Route path={'/checkout/easykindZai'} element={<ZaiPayment />} />}
        <Route
          path={'/smp/*'}
          element={
            <RedirectClinician>
              <SMPRoutes />
            </RedirectClinician>
          }
        />
        <Route path={'/*'} element={<ClientRoutes />} />
      </Routes>
    </UserContextProvider>
  );
};

export default AuthRoutes;
