import { Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import { UserContextProvider } from 'utils/UserContextProvider';
import { useHelmPartnerRoutesGenerator } from '../../helm/PartnerReferral/utils/Path/HelmPartnerRoutesGenerator';
import TokenAccess from '../../helm/PartnerReferral/pages/TokenAccess/TokenAccess';
import HelmTokenAuthWrapper from '../../helm/PartnerReferral/components/HelmTokenAuthWrapper/HelmTokenAuthWrapper';
import HelmWrapper from 'helm/components/HelmWrapper/HelmWrapper';
import PartnerPractitionerListing from 'helm/PartnerReferral/pages/PartnerPractitionerListing/PartnerPractitionerListing';
import PartnerPractitionerDetails from 'helm/PartnerReferral/pages/PartnerPractitionerDetails/PartnerPractitionerDetails';
import HelmPartnerConfirmPage from '../../helm/PartnerReferral/pages/PartnerConfirmPage/PartnerConfirmPage';
import PartnerBookingSummary from 'helm/PartnerReferral/pages/PartnerBookingSummary/PartnerBookingSummary';
import PartnerSignUp from 'helm/PartnerReferral/pages/PartnerSignUp/PartnerSignUp';
import PartnerFundingStream from 'helm/PartnerReferral/pages/PartnerFundingStream/PartnerFundingStream';

const HelmPartnerRoutes = () => {
  const { TOKEN, PRACTITIONER, SIGNUP } = useHelmPartnerRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: '/', component: TokenAccess, layout: HelmWrapper })}
        {appRoute({ path: TOKEN.BASE, component: TokenAccess, layout: HelmWrapper })}
        {appRoute({
          path: PRACTITIONER.LISTING,
          component: PartnerPractitionerListing,
          layout: HelmTokenAuthWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PartnerPractitionerDetails,
          layout: HelmTokenAuthWrapper
        })}
        {appRoute({
          path: SIGNUP.BASE,
          component: PartnerSignUp,
          layout: HelmTokenAuthWrapper
        })}
        {appRoute({
          path: SIGNUP.FUNDING_STREAM,
          component: PartnerFundingStream,
          layout: HelmTokenAuthWrapper
        })}
        {appRoute({
          path: SIGNUP.SUMMARY_BOOKING,
          component: PartnerBookingSummary,
          layout: HelmTokenAuthWrapper
        })}
        {appRoute({
          path: SIGNUP.CONFIRM_BOOKING,
          component: HelmPartnerConfirmPage,
          layout: HelmTokenAuthWrapper
        })}
      </Routes>
    </UserContextProvider>
  );
};

export default HelmPartnerRoutes;
