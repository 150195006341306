import styles from './PractitionerWelcomeNote.module.scss';

import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

interface PractitionerWelcomeNoteProps {
  practitionerDetails: PractitionerDetailsInterface;
}

const PractitionerWelcomeNote = ({ practitionerDetails }: PractitionerWelcomeNoteProps) => (
  <>
    <div className={styles.greet}>Hi, I'm {practitionerDetails.name}</div>
    <div className={styles.greetDesc} dangerouslySetInnerHTML={{ __html: practitionerDetails.bio }} />
    {practitionerDetails.helmControl.video && (
      <div className={styles.videoWrapper}>
        <div className={styles.title}>My Approach</div>
        <video
          poster={practitionerDetails.helmControl.videoPoster}
          className={styles.video}
          controls
          src={practitionerDetails.helmControl.video}
        />
      </div>
    )}
    {practitionerDetails.helmControl.bioPhoto && (
      <div className={styles.bioPhotoWrapper}>
        <img className={styles.bioPhoto} alt={'clinician_photo'} src={practitionerDetails.helmControl.bioPhoto} />
      </div>
    )}
  </>
);

export default PractitionerWelcomeNote;
