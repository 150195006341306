import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import classNames from 'classnames';

const ConsentListWrapper = () => (
  <div className={classNames(styles.container, 'recharge-theme')}>
    <RechargeContentLayout className={styles.headerContent}>
      <div className={styles.headerWrapper}>
        <RechargeHeader withPadding withMenu whiteFont whiteMenu />
      </div>
    </RechargeContentLayout>
    <RechargeContentLayout className={styles.content}>
      <ConsentList />
    </RechargeContentLayout>
  </div>
);

export default ConsentListWrapper;
