import { useField } from 'formik';

import { Option, Question } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import EmojiButton from './components/EmojiButton/EmojiButton';

import styles from './Rate.module.scss';

interface RateProps {
  question: Question;
}

const Rate = ({ question }: RateProps) => {
  const [, { error, touched }] = useField(`${question.id}.value`);

  return (
    <>
      <div className={styles.container}>
        {(question.options as Option[]).map(({ key, description, value }) => (
          <EmojiButton value={value} key={key} label={description} name={key} questionId={question.id} />
        ))}
      </div>
      {typeof error === 'string' && <ErrorMessage error={error} visible={touched && !!error} />}
    </>
  );
};

export default Rate;
