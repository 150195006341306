import styles from './ScaleBox.module.scss';

interface ScaleBoxProps {
  colour: string;
  formValue: number;
  value: number;
  onChange: (value: number) => void;
}

const ScaleBox = ({ colour, formValue, value, onChange }: ScaleBoxProps) => {
  return (
    <div className={styles.container} onClick={() => onChange(value)}>
      {formValue < value && (
        <div style={{ zIndex: 1 }}>
          <div style={{ borderBottomColor: colour }} className={styles.pointTop_Top} />
          <div style={{ background: colour }} className={styles.scaleDown}>
            <div className={styles.scaleTextTop}>{value}</div>
          </div>
          <div className={styles.pointTop_Down} />
        </div>
      )}
      {formValue === value && (
        <div style={{ zIndex: value }}>
          <div style={{ borderBottomColor: colour }} className={styles.pointTop_Top} />
          <div style={{ background: colour }} className={styles.scaleDown}>
            <div className={styles.scaleTextMiddle}>{value}</div>
          </div>
          <div style={{ borderTopColor: colour }} className={styles.pointBottom_Down} />
        </div>
      )}
      {formValue > value && (
        <div style={{ zIndex: value }}>
          <div className={styles.pointBottom_Top} />
          <div style={{ background: colour }} className={styles.scaleDown}>
            <div className={styles.scaleText}>{value}</div>
          </div>
          <div style={{ borderTopColor: colour }} className={styles.pointBottom_Down} />
        </div>
      )}
    </div>
  );
};

export default ScaleBox;
