import Button from 'components/v2/Button/Button';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useNavigate } from 'react-router-dom';
import styles from './PowerToChangePlan.module.scss';

const PowerToChangePlan = () => {
  const navigate = useNavigate();
  const { SMP } = useHelmRoutesGenerator();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Power to Change Plan</div>
      <div className={styles.descriptionContainer}>
        <div className={styles.description}>
          In each session, you will be asked to complete at least one activity that will be populated into your personal
          “Power to Change Plan”, which you will come back to and review in the final session.
        </div>
        <div>
          Even if you have discussed or thought about the answers, we encourage you to complete the activities. Writing
          down your thoughts can make the learning more concrete, supporting you in the change journey.
        </div>
        <div>
          Bringing these together in the final session also gives you the opportunity to see your learning over the
          Power to Change program and make decisions about your next steps.
        </div>
      </div>
      <Button className={styles.button} onClick={() => navigate(SMP.LISTING)}>
        View and edit my plan<i className={`material-icons-outlined`}>navigate_next</i>
      </Button>
    </div>
  );
};

export default PowerToChangePlan;
