import styles from './AppointmentHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

interface AppointmentHeaderProps {
  profileName: string;
  profileImg: string;
  label?: string;
}

const AppointmentHeader = ({ profileName, profileImg, label }: AppointmentHeaderProps) => {
  const navigate = useNavigate();

  const onClickBackBtn = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.backBtnWrapper} onClick={() => onClickBackBtn()}>
        <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
        STEP BACK
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.info}>
          <div className={styles.text}>{profileName}</div>
          <div className={styles.text}>{label}</div>
        </div>
        <div className={styles.profile}>
          <ClientProfileAvatar avatarUrl={profileImg} />
        </div>
      </div>
    </div>
  );
};

export default AppointmentHeader;
