import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { RechargeContactUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';
import styles from './QuestionBox.module.scss';

const QuestionBox = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Have a question?</div>
      <div className={styles.contact}>
        Jump into to our <span className={styles.highlight}>Help Centre</span> and our team will be happy to assist.
        We’re available for live-chat 7 days a week, Monday - Friday 8am - 8pm, and Saturday - Sunday 9am - 12pm AEST.
      </div>
      <ButtonRecharge
        className={styles.contactBtn}
        variant="outlined"
        onClick={() => (window.location.href = RechargeContactUrl)}
      >
        Get in touch with our support squad
      </ButtonRecharge>
    </div>
  );
};

export default QuestionBox;
