import { checkInServicesApiSlice } from 'redux/services/checkInServicesApiSlice';

interface ISurvey {
  _id: string;
  title: string;
  isGPSurvey: boolean;
  totalQuestions: number;
  createdAt: string;
}

export const surveyApiSlice = checkInServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSurveys: builder.query<ISurvey[], void>({
      query: () => ({
        url: '/client-records/me/surveys'
      }),
      providesTags: ['Surveys']
    })
  })
});

export const { useGetAllSurveysQuery } = surveyApiSlice;
