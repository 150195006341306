import styles from './JournalCommentWidget.module.scss';

interface JournalCommentWidgetProps {
  data: any;
}

const JournalCommentWidget = ({ data }: JournalCommentWidgetProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.date}>{data.date}</div>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.desc}>{data.response}</div>
      <div className={styles.emotionWrapper}>
        {data.tags.map((obj: any, index: any) => (
          <div className={obj.polarity === 'positive' ? styles.badge : styles.badgeNegative} key={index}>
            {obj.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JournalCommentWidget;
