import { BentResponse } from 'bent';
import { httpClient } from '../httpClient';
import queryString from 'query-string';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const checkClinicianOnboardingAssessmentCreated = (slugOrId: string, token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clinician/${slugOrId}/check-onboarding-assessment-created`
  ) as Promise<BentResponse>;

export const getCheckInInsightsByPatientIdAndType = (
  token: string,
  patientId: string,
  startDate: string,
  endDate: string,
  type: string
) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${patientId}/check-in-insights?startDate=${startDate}&endDate=${endDate}&type=${type}`
  ) as Promise<BentResponse>;

export const getCheckInInsightsByType = (token: string, startDate: string, endDate: string, type: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/check-in-insights?startDate=${startDate}&endDate=${endDate}&type=${type}`
  ) as Promise<BentResponse>;

export const getClinicalAssessmentResponsesListByPatientId = (token: string, patientId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${patientId}/clinical-assessment-responses`
  ) as Promise<BentResponse>;

export const getClinicalAssessmentResponseById = (token: string, patientId: string, id: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${patientId}/clinical-assessment-response/${id}`
  ) as Promise<BentResponse>;

export const getReportsListByPatientId = (token: string, patientId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(`/${patientId}/reports`) as Promise<BentResponse>;

export const getInitialOnboardingAssessmentByPatientId = (token: string, patientId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${patientId}/initial-onboarding-assessment`
  ) as Promise<BentResponse>;

export const getCheckIn = (token: string, clinicianId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${clinicianId}/questions-responses/check-in`
  ) as Promise<BentResponse>;

export const getMicroCheckIns = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/micro-check-ins?maxRecords=30`
  ) as Promise<BentResponse>;

export const getAdHocAssessment = (token: string, clinicianId: string, adHocAssesmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${clinicianId}/questions-responses/ad-hoc-assessment/${adHocAssesmentId}`
  ) as Promise<BentResponse>;

export const getMicroJournalEntriesByPatientId = (
  token: string,
  patientId: string,
  startDate: string,
  endDate: string
) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${patientId}/micro-journal-entries?startDate=${startDate}&endDate=${endDate}`
  ) as Promise<BentResponse>;

export const putQuestionsResponses = (
  token: string,
  clinicianId: string,
  questionsResponsesId: string,
  body: Record<string, any>
) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${clinicianId}/questions-responses/${questionsResponsesId}`,
    body
  ) as Promise<BentResponse>;

export const postAdHocAssessment = (token: string, payload: Record<string, any>) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/ad-hoc-assessment`,
    payload
  ) as Promise<BentResponse>;

export const postAssignFirstAssessment = (token: string, body: Record<string, any>) =>
  httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(`/first-assessments/assign`, body);

export const getQuestionsResponseCount = (clinicianId: string, token: string) => {
  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/${clinicianId}/questions-responses/count`
  );
};

export const getAssignedMCIByPatientId = (token: string, patientId: string) => {
  return httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/micro-check-ins/client/${patientId}`
  ) as Promise<BentResponse>;
};

export const putAssignedMCIByPatientId = (token: string, patientId: string, microCheckInId: number) => {
  return httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(`/micro-check-ins/client/${patientId}`, {
    microCheckInId
  });
};

export const getGoogleCloudStorageObjects = (bucketName: string, clientId: string) =>
  httpClient(
    'GET',
    'https://storage.googleapis.com',
    200
  )(`/storage/v1/b/${bucketName}/o?prefix=${clientId}`) as Promise<BentResponse>;

export const getClientCompletedCheckIn = (token: string, clinicianSlugUrlOrId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-check-in-status?clinicianId=${clinicianSlugUrlOrId}`
  ) as Promise<BentResponse>;

export const getMicroJournalEntries = ({
  token,
  queryParam
}: {
  token: string;
  queryParam: {
    startDate: string;
    endDate: string;
  };
}) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clients/me/micro-journal-entries?${queryString.stringify(queryParam)}`
  ) as Promise<BentResponse>;

export const getCheckInInsights = ({
  token,
  queryParam
}: {
  token: string;
  queryParam: {
    startDate: string;
    endDate: string;
    type: string;
  };
}) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/clients/me/check-in-insights?${queryString.stringify(queryParam)}`
  ) as Promise<BentResponse>;

export const assignGPSurvey = ({ token }: { token: string }) =>
  httpClient('POST', url!, [200, 201], { Authorization: `Bearer ${token}` })(
    '/client-records/me/surveys/gp'
  ) as Promise<BentResponse>;
