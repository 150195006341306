import { ChangeEvent, Fragment, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './CheckboxGroup.module.scss';

interface CheckboxGroupOptions extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    label: string;
    value: string | number;
  }[];
  value?: string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
}

const CheckboxGroup = ({ name, hasError, options, value: formValue, onChange, ...rest }: CheckboxGroupOptions) => {
  const containerStyles = classnames(styles.container, hasError && styles.error);

  return (
    <div className={containerStyles}>
      {options.map(({ label, value }, index) => (
        <Fragment key={index}>
          <input
            className={styles.checkbox}
            id={`checkbox-${name}-${value}`}
            type="checkbox"
            name={name}
            value={value}
            onChange={onChange}
            {...(Array.isArray(formValue) && { checked: formValue.includes(value.toString()) })}
            {...rest}
          />
          <label className={styles.label} htmlFor={`checkbox-${name}-${value}`}>
            <div className={styles.fakeCheckbox} />
            {label}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

export default CheckboxGroup;
