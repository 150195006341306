import styles from './PsychologistBonaFides.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { useMemo } from 'react';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';

interface PsychologistBonaFidesProps {
  bonaFidesDetails: PractitionerDetailsInterface['helmControl']['bonaFides'];
  limitDisplayQualifications?: boolean;
}

const PsychologistBonaFides = ({ bonaFidesDetails, limitDisplayQualifications }: PsychologistBonaFidesProps) => {
  const qualificationValue = limitDisplayQualifications
    ? bonaFidesDetails?.qualifications?.value?.slice(0, 1) ?? []
    : bonaFidesDetails?.qualifications?.value;

  const hasBonaFides = useMemo(
    () => bonaFidesDetails && Object.values(bonaFidesDetails).some(({ active }) => active),
    [bonaFidesDetails]
  );

  return (
    <div className={styles.container}>
      {hasBonaFides && (
        <>
          <div className={styles.label}>BONA FIDES</div>
          <div className={styles.bonaFideDetails}>
            {bonaFidesDetails?.qualifications?.active &&
              qualificationValue?.map((qualificationObj, index) => (
                <div key={index} className={styles.details}>
                  <div className={`material-icons ${styles.icon}`}>school</div>
                  <div>{qualificationObj}</div>
                </div>
              ))}
            {bonaFidesDetails?.yearsOfExperience?.active && (
              <div className={styles.details}>
                <i className={`material-icons ${styles.icon}`}>work</i>
                <div>{bonaFidesDetails.yearsOfExperience.value} Years Experience</div>
              </div>
            )}
            {bonaFidesDetails?.languages?.active && (
              <div className={styles.details}>
                <i className={`material-icons ${styles.icon}`}>language</i>
                <div>
                  {bonaFidesDetails.languages.value
                    ?.map((obj) => LANGUAGE_COLLECTION[obj as keyof typeof LANGUAGE_COLLECTION])
                    .join(' | ')}
                </div>
              </div>
            )}
            {bonaFidesDetails?.medicare?.active && (
              <div className={styles.details}>
                <i className={`material-icons ${styles.icon}`}>badge</i>
                <div>{bonaFidesDetails.medicare?.value}</div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PsychologistBonaFides;
