import { useState } from 'react';
import * as Yup from 'yup';

export const useFormError = <T>() => {
  const [errors, setErrors] = useState<{ [key in keyof T]?: string }>();

  const validate = (shape: Yup.ObjectSchema<any>, values: T, touchingKey?: keyof T) => {
    try {
      shape.validateSync(values, { abortEarly: false });
      setErrors({});
    } catch (ex) {
      let validationErrors: { [key in keyof T]?: string } = {};

      if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
        ex.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof T] = error.message;
          }
        });

        if (touchingKey) {
          setErrors({ ...errors, [touchingKey]: validationErrors[touchingKey] || '' });
        } else {
          setErrors(validationErrors);
        }
      }
    }
  };

  return { errors, setErrors, validate };
};
