import enUK from './en/en-uk.json';
import enAU from './en/en-au.json';
import enNZ from './en/en-nz.json';
import enSA from './en/en-sa.json';
import { getTerminology } from './en/terminology';
import { config } from 'config/config';

const { countryCode } = config;

type TranslationFile = typeof enUK | typeof enAU | typeof enNZ | typeof enSA;

const translationFiles: Record<string, TranslationFile> = {
  gb: enUK,
  au: enAU,
  nz: enNZ,
  sa: enSA
};

const resources = {
  en: {
    translations: translationFiles[countryCode],
    terminology: getTerminology()
  }
};

export default resources;
