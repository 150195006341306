import tacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import ContentLayout from '../../../../components/ContentLayout/ContentLayout';
import styles from './Header.module.scss';
import LogoSkeleton from 'components/LogoSkeleton/LogoSkeleton';

const Header = () => {
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { data: practiceInfoData, isLoading: practiceInfoDataLoading } = useGetPracticeInfoQuery(
    { slugUrlOrAccountId: attachedClinicianDetail?.practice?.slugUrl || '' },
    { skip: !attachedClinicianDetail?.practice?.slugUrl }
  );

  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.header}>
          {isAttachedClinicianDetailLoading || practiceInfoDataLoading ? (
            <LogoSkeleton />
          ) : practiceInfoData?.accountSettings?.logo ? (
            <img
              className={styles.customLogoImage}
              src={practiceInfoData.accountSettings.logo.dark}
              alt={`${practiceInfoData.name} logo`}
            />
          ) : (
            <img className={styles.logoImage} src={tacklitLogo} alt={'tacklitLogo'} />
          )}
        </div>
      </ContentLayout>
    </div>
  );
};

export default Header;
