import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const getConsentFormAssigned = (token: string, consentId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentId}:getAssigned`
  ) as Promise<BentResponse>;

export const getConsentFormFacilitated = (token: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/consent-forms:onboarding`
  ) as Promise<BentResponse>;

export const getConsentFormSignUp = (token: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/consent-forms:signup`
  ) as Promise<BentResponse>;

export const getConsentFormSignUpV2 = (token: string, consentFormId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentFormId}:signup`
  ) as Promise<BentResponse>;

export const postAdHocConsentForm = (token: string, consentId: string, payload: any) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/consent-forms/${consentId}:postAdHocResponse`,
    payload
  ) as Promise<BentResponse>;

export const postConsentFormOnboarding = (token: string, payload: any) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/consent-form-responses:onboarding`,
    payload
  ) as Promise<BentResponse>;

export const postConsentFormSignUp = (token: string, payload: any) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/consent-form-responses:signup`,
    payload
  ) as Promise<BentResponse>;

export const postConsentFormSendOtp = (consentId: string) =>
  httpClient('POST', url!, [200, 204])(`/consent-forms/${consentId}:sendOtp`) as Promise<BentResponse>;

export const postConsentFormVerifyOtp = (consentId: string, code: number) =>
  httpClient(
    'POST',
    url!,
    [200, 204]
  )(`/consent-forms/${consentId}:verifyOtp`, {
    code
  }) as Promise<BentResponse>;
