import { Modal } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './ToggleSwitchV2.module.scss';

export interface toggleListType {
  id: string;
  label: string;
  count?: number;
  isActive: boolean;
  color?: string;
  isIcon?: boolean;
}

interface ToggleSwitchV2Props {
  id: string;
  toggleList: toggleListType[];
  onChangeStatus: (value: toggleListType) => void;
  isLoading?: boolean;
  alertOnClick?: string;
  className?: string;
  wrapperClassName?: string;
  indicatorClassName?: string;
  labelClassName?: string;
  labelActiveClassName?: string;
}

const ToggleSwitchV2 = ({
  id,
  toggleList,
  onChangeStatus,
  isLoading,
  alertOnClick,
  className,
  wrapperClassName,
  indicatorClassName,
  labelClassName,
  labelActiveClassName
}: ToggleSwitchV2Props) => {
  const [indicatorLocation, setIndicatorLocation] = useState(0);
  const [switchList, setSwitchList] = useState(toggleList);

  // need to do this as Modal from 'antd' called multiple times
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSwitchList(toggleList);
    const getCurrentActiveValue = toggleList.find((listObj) => listObj.isActive) as toggleListType;
    const indicatorLocation = parseFloat(`${(toggleList.indexOf(getCurrentActiveValue) / toggleList.length) * 100}`);
    setIndicatorLocation(indicatorLocation);
  }, [toggleList]);

  const pillWidth = 100 / toggleList.length;

  const mapActiveToggle = (selectedValue: toggleListType) => {
    return toggleList.map((obj) => {
      return {
        id: obj.id,
        label: obj.label,
        count: obj.count,
        isActive: selectedValue.id === obj.id,
        color: obj.color,
        isIcon: obj.isIcon
      };
    });
  };

  const handleConfirmClick = (selectedValue: toggleListType) => {
    // need to do this as Modal from 'antd' called multiple times
    if (isModalOpen) {
      return;
    }
    const getCurrentActiveValue = switchList.find((listObj) => listObj.isActive);
    if (getCurrentActiveValue && getCurrentActiveValue.id === selectedValue.id) {
      return;
    }

    setIsModalOpen(true);
    Modal.confirm({
      title: alertOnClick,
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => {
        handleChange(selectedValue);
      },
      afterClose: () => {
        setIsModalOpen(false);
      }
    });
  };

  const handleChange = (selectedValue: toggleListType) => {
    const getCurrentActiveValue = switchList.find((listObj) => listObj.isActive);
    if ((getCurrentActiveValue && getCurrentActiveValue.id) !== selectedValue.id) {
      const indicatorLocation = parseFloat(`${(switchList.indexOf(selectedValue) / switchList.length) * 100}`);
      setIndicatorLocation(indicatorLocation);
      const newData = mapActiveToggle(selectedValue);
      setSwitchList(newData);
      onChangeStatus(selectedValue);
    }
  };

  return (
    <div className={classNames(styles.container, className)} id={id}>
      <div className={classNames(styles.wrapper, wrapperClassName)}>
        {switchList.map((item, index) => (
          <span
            onClick={() => (!isLoading ? (alertOnClick ? handleConfirmClick(item) : handleChange(item)) : false)}
            key={index}
            className={isLoading ? styles.pillWrapperDisable : styles.pillWrapper}
            style={{ width: `${pillWidth}%` }}
          >
            <input type={'radio'} name={'option'} value={item.id} id={`toggleSwitch-${item.id}-${index}`} />
            <label
              htmlFor={`toggleSwitch-${item.id}-${index}`}
              className={classNames(
                item.isActive ? [styles.labelActive, labelActiveClassName] : [styles.label, labelClassName]
              )}
              style={{
                color: item.isActive && item.color ? 'white' : undefined
              }}
            >
              {item.isIcon ? <i className={`material-icons`}>{item.label}</i> : item.label}
              {item.count !== undefined && <div className={styles.count}>{item.count}</div>}
            </label>
          </span>
        ))}
        <div
          style={{
            width: `${pillWidth}%`,
            left: `${indicatorLocation}%`,
            background: switchList.filter((list) => list.isActive)?.[0]?.color
          }}
          className={classNames(styles.indicator, indicatorClassName)}
        />
      </div>
    </div>
  );
};

export default ToggleSwitchV2;
