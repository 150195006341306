import { useState } from 'react';

import styles from './SurveyQuestionForm.module.scss';
import { SurveyAssessment } from 'interfaces/Assessment/SurveyInterfaces';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import SQQuestion from './components/SQQuestion/SQQuestion';
import SQBackground from './components/SQBackground/SQBackground';
import SQIntro from './components/SQIntro/SQIntro';
import SQDone from './components/SQDone/SQDone';

interface SurveyQuestionFormProps {
  surveyData: SurveyAssessment;
  token: string;
  clinicianDetail: GetAttachedClinicianDetails;
  clinicianName: string;
  setSurveyQuestion: (val: SurveyAssessment) => void;
  totalQuestions?: number;
  type?: string;
}

export interface SurveyStep {
  step: 'intro' | 'background' | 'surveyQuestion' | 'finish';
}

const SurveyQuestionForm = ({
  surveyData,
  clinicianDetail,
  clinicianName,
  token,
  setSurveyQuestion,
  type
}: SurveyQuestionFormProps) => {
  const surveyQuestion = surveyData.questionsResponses;
  const isBgQuestionExist =
    surveyQuestion?.assessment.onboardingQuestions.backgroundResponse &&
    surveyQuestion?.assessment.onboardingQuestions.backgroundResponse.status !== 'done';

  const isSurveyQuestionExist =
    surveyQuestion?.assessment.onboardingQuestions.assessmentField &&
    surveyQuestion?.assessment.onboardingQuestions.assessmentField.length > 0;

  const isSurveyPending = surveyQuestion?.status === 'pending';

  const [surveyStep, setSurveyStep] = useState<SurveyStep['step']>(
    isBgQuestionExist || (isSurveyQuestionExist && isSurveyPending) ? 'intro' : 'finish'
  );

  const handleStartSurvey = () => {
    if (isBgQuestionExist) {
      setSurveyStep('background');
    } else {
      setSurveyStep('surveyQuestion');
    }
  };

  const getSurveyContent = (surveyStep: SurveyStep['step']) => {
    switch (surveyStep) {
      case 'intro':
        return (
          <SQIntro
            introNote={surveyData?.survey?.note}
            clinicianName={clinicianName}
            handleStartSurvey={handleStartSurvey}
          />
        );
      case 'surveyQuestion':
        return (
          <SQQuestion
            clinicianName={clinicianName}
            clinicianDetail={clinicianDetail}
            token={token}
            surveyData={surveyData}
            setSurveyQuestion={setSurveyQuestion}
            setSurveyStep={setSurveyStep}
            type={type}
          />
        );
      case 'background':
        return (
          <SQBackground
            clinicianDetail={clinicianDetail}
            token={token}
            surveyData={surveyData}
            setSurveyStep={setSurveyStep}
            type={type}
          />
        );
      case 'finish':
        return <SQDone totalQuestion={surveyData?.survey?.totalQuestions} />;
      default:
        return <></>;
    }
  };

  return <div className={styles.container}>{getSurveyContent(surveyStep)}</div>;
};

export default SurveyQuestionForm;
