import moment from 'moment';
import SelectSlot from './components/SelectSlot/SelectSlot';
import styles from './BookingSession.module.scss';
import { AvailabilityAppointmentTypes, DeliveryType, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import classNames from 'classnames';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import CancellationPolicyModal from './components/CancellationPolicyModal/CancellationPolicyModal';
import { useMemo, useState } from 'react';
import { generateEngageConsultPreferences, getDeliveryTypeLabel } from 'utils/appointment';

interface BookingSessionProps {
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  onRemoveSelectedSlot: (slot: TimeSlotsWithDateInterface) => void;
  isAvailabilityListLoading: boolean;
  isProcessingReservation: boolean;
  onContinue: () => void;
  isAdvisorySession?: boolean;
  selectedDeliveryPreference: DeliveryType;
  onChangeDeliveryPreference: (value: DeliveryType) => void;
  classname?: string;
}

const BookingSession = ({
  selectedAppointmentType,
  selectedTimeSlots,
  onRemoveSelectedSlot,
  isAvailabilityListLoading,
  isProcessingReservation,
  onContinue,
  isAdvisorySession,
  selectedDeliveryPreference,
  onChangeDeliveryPreference,
  classname
}: BookingSessionProps) => {
  const [isCancellationPolicyVisible, setIsCancellationPolicyVisible] = useState(false);

  const consultPreferences = useMemo(
    () =>
      generateEngageConsultPreferences({
        selectedAppointmentType,
        selectedDeliveryPreference
      }),
    [selectedAppointmentType, selectedDeliveryPreference]
  );

  return (
    <>
      <CancellationPolicyModal
        visible={isCancellationPolicyVisible}
        onClose={() => setIsCancellationPolicyVisible(false)}
      />
      <div className={classNames(styles.bookingContainer, isAdvisorySession && styles.isAdvisorySession, classname)}>
        <EaseContentLayout className={styles.contentLayout}>
          <div className={styles.background} />
          <div className={styles.bookingContent}>
            {/* Description */}
            <div className={styles.bookingDesc}>
              <div className={styles.bookingTitle}>You are booking</div>
              <div className={styles.bookingDescSummary}>
                {`${selectedAppointmentType?.name} | ${selectedAppointmentType?.duration} minutes ${
                  selectedAppointmentType?.rate !== undefined && selectedAppointmentType?.rate > 0
                    ? `| £${selectedAppointmentType?.rate}`
                    : ''
                }`}
                <span className={styles.bookingOnLabel}>{selectedTimeSlots.length > 0 ? ' on' : ''}</span>
              </div>
            </div>

            <div className={styles.middleContent}>
              <div className={styles.slotWrapper}>
                {/* Slot */}
                <div className={styles.slotContainer}>
                  {selectedTimeSlots.filter((obj) => obj.isConflict).length > 0 && (
                    <div className={styles.blocked}>
                      Our counsellors are in high demand. Some times are no longer available. Please select another
                      time.
                    </div>
                  )}
                  <div className={styles.timeslotWrapper}>
                    {selectedTimeSlots.map((timeSlotObj, index) => (
                      <SelectSlot
                        key={index}
                        date={moment(timeSlotObj.date)}
                        time={`${moment(timeSlotObj.startTime, 'hh:mmA').format('hh:mm')} - ${timeSlotObj.endTime}`}
                        onDelete={() => onRemoveSelectedSlot(timeSlotObj)}
                        blockSlot={timeSlotObj.isConflict}
                      />
                    ))}
                    {Array.from(Array((selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length)).map(
                      (_, i) => (
                        <SelectSlot key={i} />
                      )
                    )}
                  </div>
                </div>
                <div className={styles.importantInfoContainer}>
                  <div className={styles.importantInfoContent}>
                    You can manage or change appointments in line with our&nbsp;
                    <ButtonEase
                      className={styles.cancellationPolicyButton}
                      variant="text"
                      onClick={() => setIsCancellationPolicyVisible(true)}
                    >
                      cancellation policy
                    </ButtonEase>
                    .
                  </div>
                </div>
              </div>

              {/* Consult Preferences */}
              {consultPreferences && consultPreferences.length > 1 && (
                <div className={styles.consultPreferenceWrapper}>
                  <div className={styles.consultPreference}>
                    <div className={styles.consultPreferenceLabel}>CONSULT PREFERENCE</div>
                    <ToggleSwitchV2
                      id="consultPreference"
                      toggleList={consultPreferences}
                      onChangeStatus={(value) => {
                        onChangeDeliveryPreference(value.id as DeliveryType);
                      }}
                      className={styles.consultPreferenceButton}
                      wrapperClassName={styles[`optionButton${consultPreferences?.length}`]}
                      indicatorClassName={styles.indicator}
                      labelClassName={styles.label}
                      labelActiveClassName={styles.labelActive}
                    />
                    <div className={styles.selectedPreference}>
                      <b>
                        {getDeliveryTypeLabel(
                          selectedDeliveryPreference,
                          selectedAppointmentType?.otherInstructions
                        ).toUpperCase()}
                      </b>
                      {` preferred`}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Next button */}
            <div className={styles.continueBtnWrapper}>
              <ButtonEase
                className={styles.continueBtn}
                onClick={onContinue}
                disabled={
                  isAvailabilityListLoading ||
                  isProcessingReservation ||
                  selectedTimeSlots.length < (selectedAppointmentType?.slotCount || 1)
                }
              >
                CONTINUE TO PAY AND CONFIRM
              </ButtonEase>
              <div className={styles.continueLabel}>Your appointment will only be held for 15 minutes</div>
            </div>
          </div>
        </EaseContentLayout>
      </div>
    </>
  );
};

export default BookingSession;
