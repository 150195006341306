import moment from 'moment';
import momentTz from 'moment-timezone';
import { RescheduleRules } from './hooks/appointment';

export enum RescheduleAction {
  NotAllowed = 'notAllowed',
  LimitExceeded = 'limitExceeded',
  NotInRange = 'notInRange',
  Instant = 'instant',
  Request = 'request',
  ShortNotice = 'shortNotice'
}

export const checkRescheduleRules = (
  appointmentTime: moment.Moment,
  rescheduleRules: RescheduleRules | undefined,
  rescheduledCount: number,
  clientTimeZone?: string,
  clinicianTimeZone?: string
): RescheduleAction => {
  if (!rescheduleRules || !rescheduleRules.active) {
    return RescheduleAction.NotAllowed;
  }

  const { selfManage, requestChanges, limit } = rescheduleRules;

  if (limit > 0 && rescheduledCount >= limit) {
    return RescheduleAction.LimitExceeded;
  }

  const timeZone = clientTimeZone || clinicianTimeZone;
  const dateNow = timeZone ? momentTz.tz(moment(), timeZone) : moment();
  const timeDiff = appointmentTime.diff(dateNow, 'minutes');

  const firstFrom = selfManage.active
    ? selfManage.value.from
    : requestChanges.active
    ? requestChanges.value.from
    : requestChanges.value.to;

  if (firstFrom !== -1 && timeDiff > firstFrom) {
    return RescheduleAction.NotInRange;
  }

  if (
    selfManage.active &&
    timeDiff > selfManage.value.to &&
    (selfManage.value.from === -1 || selfManage.value.from >= timeDiff)
  ) {
    return RescheduleAction.Instant;
  }

  if (
    requestChanges.active &&
    timeDiff > requestChanges.value.to &&
    (requestChanges.value.from === -1 || requestChanges.value.from >= timeDiff)
  ) {
    return RescheduleAction.Request;
  }

  return RescheduleAction.ShortNotice;
};
