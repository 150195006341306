import styles from './PracticeLayout.module.scss';
import tacklitLogo from 'assets/images/tacklitLogo.png';

interface PracticeLayoutProps {
  children?: any;
}

const PracticeLayout = ({ children }: PracticeLayoutProps) => {
  return (
    <div className={styles.container}>
      {children}
      <div className={styles.footer}>
        <div className={styles.poweredBy}>POWERED BY</div>
        <a href="https://tacklit.com">
          <img alt="logo" src={tacklitLogo} className={styles.logo} />
        </a>
      </div>
    </div>
  );
};

export default PracticeLayout;
