import { useMemo, useRef, useState } from 'react';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import styles from './Calendar.module.scss';
import './MobiscrollCustom.scss';
import moment from 'moment/moment';
import SlotPicker from './components/SlotPicker/SlotPicker';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import { TimeSlotBooking } from '../../PracticeTimeSlot';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface CalendarProps {
  onSlotClick: (e: TimeSlotsWithDateInterface) => void;
  timeSlots: TimeSlotBooking['slots'];
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  isAvailabilityListLoading: boolean;
  remainSlots: number;
  calendarWrapperClassName?: string;
  calendarCardClassName?: string;
  slotPickerCardClassName?: string;
}

const maxDate = moment().add(12, 'month');
const minDate = moment();

const allDates = (() => {
  let x = moment();
  let res: any = [];
  while (x.isBefore(maxDate)) {
    res = [...res, x.format('YYYY-MM-DD')];
    x = x.add(1, 'days');
  }
  return res;
})();

const Calendar = ({
  onSlotClick,
  selectedTimeSlots,
  timeSlots,
  isAvailabilityListLoading,
  remainSlots,
  calendarWrapperClassName,
  calendarCardClassName,
  slotPickerCardClassName
}: CalendarProps) => {
  const { search } = useLocation();
  const { selectedDateTime: selectedDateTimeParams } = queryString.parse(search);
  const [selectedDate, setSelectedDate] = useState<string>(
    (selectedDateTimeParams as string)?.split(',')[0] || moment().format('YYYY-MM-DD')
  );

  const invalidDates = useRef<Set<string>>(new Set<string>());

  const filteredData = useMemo(() => {
    const filterTimeSlotByDate = (selectedDate: string) => {
      return timeSlots.filter((timeSlotObj) => timeSlotObj.date === selectedDate);
    };
    invalidDates.current = new Set<string>(allDates);
    const map = new Map<string, TimeSlotsWithDateInterface[]>();
    if (!timeSlots) {
      return map;
    }
    timeSlots.forEach((item) => {
      if (!map.has(item.date)) {
        const values = filterTimeSlotByDate(item.date);
        map.set(item.date, values);
        if (values.length > 0) {
          invalidDates.current.delete(item.date);
        }
      }
    });
    return map;
  }, [timeSlots]);

  return (
    <div className={`${styles.container} ease-theme`}>
      <div className={classNames(styles.calendarWrapper, calendarWrapperClassName)}>
        <div className={classNames(styles.calendarCard, calendarCardClassName)}>
          <Datepicker
            calendarType={'month'}
            firstDay={1}
            pages={1}
            responsive={{
              custom: {
                breakpoint: 820,
                pages: 2
              }
            }}
            controls={['calendar']}
            showInput={false}
            isOpen
            min={minDate.format('YYYY-MM-DD')}
            max={maxDate.format('YYYY-MM-DD')}
            invalid={isAvailabilityListLoading ? Array.from(allDates) : [...Array.from(invalidDates.current)]}
            marked={selectedTimeSlots.map((item) => ({ date: moment(item.date).toDate() }))}
            value={selectedDate}
            onChange={(e) => setSelectedDate(moment(e.value).format('YYYY-MM-DD'))}
            theme={'ios'}
            themeVariant={'light'}
            display="inline"
          />
        </div>
        <div className={classNames(styles.slotPickerCard, slotPickerCardClassName)}>
          <SlotPicker
            remainSlots={remainSlots}
            selectedDate={selectedDate}
            slots={filteredData.get(selectedDate)}
            selectedSlots={selectedTimeSlots}
            isLoading={isAvailabilityListLoading}
            onClickSlot={onSlotClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
