import { useAuth0 } from '@auth0/auth0-react';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import TaskButton from 'SomeoneHealth/components/TaskButton/TaskButton';
import { useSomeoneHealthPractitionerUrl } from 'SomeoneHealth/utils/Path/useSomeoneHealthPractitionerUrl';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useLogout } from 'utils/hooks/logout';
import styles from './HeaderMenu.module.scss';

interface HeaderMenuProps {
  children: JSX.Element;
  menuList: {
    name: string;
    url: string;
    isExternal: boolean;
  }[];
}

const HeaderMenu = ({ children, menuList }: HeaderMenuProps) => {
  const { user } = useAuth0();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { logout } = useLogout();
  const [showMenu, setShowMenu] = useState(false);
  const { SomeoneHealthFindGPURL } = someoneHealthEnvironment();

  const navigate = useNavigate();

  const navigateTo = (url: string, isExternal: boolean) => {
    if (isExternal) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const bookNewAppointmentUrl = useSomeoneHealthPractitionerUrl({
    clinicianIdOrSlugUrl: attachedClinicianDetail?.clinician?._id
  });

  return (
    <div className={styles.container}>
      <div className={styles.menuTrigger} onClick={() => setShowMenu(true)}>
        {children}
      </div>

      {showMenu && (
        <>
          <div className={styles.overlay} onClick={() => setShowMenu(false)} />
          <div className={styles.menuBox}>
            <div className={styles.greeting} onClick={stopPropagation}>
              Hi {user?.name}
            </div>
            {bookNewAppointmentUrl && (
              <ButtonSH
                className={styles.bookAppointment}
                icon="event"
                onClick={() => navigateTo(bookNewAppointmentUrl, true)}
              >
                Book new appointment
              </ButtonSH>
            )}
            <ButtonSH
              variant={'outlined'}
              className={styles.bookAppointment}
              onClick={() => navigateTo(SomeoneHealthFindGPURL, true)}
            >
              Book GP Appointment
            </ButtonSH>
            {menuList.map((item, index) => (
              <TaskButton key={index} item={item} />
            ))}
            <div className={styles.signOut} onClick={() => logout()}>
              Sign Out
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
