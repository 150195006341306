import styles from './PsychologistAvatar.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface PsychologistAvatarProps {
  profileImg: string;
  className?: string;
  classNameImage?: string;
  classNameFrame?: string;
  detailPath: string;
}

const PsychologistAvatar = ({
  profileImg,
  className,
  classNameImage,
  classNameFrame,
  detailPath
}: PsychologistAvatarProps) => {
  return (
    <div className={classnames(styles.container, className && className)}>
      <Link to={detailPath}>
        <div className={classNameFrame}>
          <img
            className={classnames(styles.image, classNameImage && classNameImage)}
            src={profileImg}
            alt={'profile'}
          />
        </div>
      </Link>
    </div>
  );
};

export default PsychologistAvatar;
