import styles from './NDISPlan.module.scss';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import {
  ErrorsFields,
  NDISPlanProps,
  NDIS_PLAN_LIST,
  NDIS_TYPE,
  TouchedFields,
  planValidationSchema
} from './contants';
import * as yup from 'yup';

const NDISPlan = ({ ndisDetails, onChangeNDISDetails, setIsValidNDIS }: NDISPlanProps) => {
  const [errors, setError] = useState<ErrorsFields>({
    number: '',
    name: '',
    email: ''
  });
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({ number: false, name: false, email: false });
  const handleOnBlurNDISDetails = (fieldName: string) => {
    setTouchedFields((pre) => ({
      ...pre,
      [fieldName]: true
    }));
  };

  useEffect(() => {
    try {
      planValidationSchema.validateSync(ndisDetails, { abortEarly: false });
      setError({
        number: '',
        name: '',
        email: ''
      });
      setIsValidNDIS(true);
    } catch (validationErrors) {
      setIsValidNDIS(false);
      const errorList: any = { number: '', name: '', email: '' };
      if (
        validationErrors instanceof yup.ValidationError &&
        validationErrors.inner &&
        validationErrors.inner.length !== 0
      ) {
        validationErrors.inner.forEach((error: any) => {
          errorList[error.path] = error.message;
        });
        setError(errorList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ndisDetails]);

  return (
    <div className={styles.container}>
      <Select
        className={styles.customSelect}
        labelClass={styles.selectInputLabel}
        label={'How does the client manage their NDIS plan?'}
        placeholder={'Select '}
        defaultValue={NDIS_PLAN_LIST.find((option) => option.value === ndisDetails.plan)}
        isSearchable={false}
        name="plan"
        options={NDIS_PLAN_LIST}
        styles={{
          container: (base) => ({
            ...base,
            width: '100%'
          }),
          control: (controlBase: any) => ({
            ...controlBase,
            minHeight: '30px',
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: 0,
            boxShadow: 'none',
            borderBottom: '1px solid #d3d8dd',
            '&:hover': {
              borderColor: '#818e9b'
            }
          }),
          valueContainer: (base) => ({ ...base, paddingLeft: 0 })
        }}
        onChange={(e) => onChangeNDISDetails('plan', e?.value || '')}
      />
      <Input
        inputClass={styles.customInput}
        label="Participant reference number or NDIS number*"
        name="number"
        required
        labelClass={styles.inputLabel}
        onChange={(e) => onChangeNDISDetails('number', e.target.value)}
        value={ndisDetails.number}
        onBlur={() => handleOnBlurNDISDetails('number')}
        hasError={!!errors.number && touchedFields.number}
      />
      <ErrorMessage error={errors.number} visible={!!errors.number && touchedFields.number} />

      {ndisDetails.plan === NDIS_TYPE.plan && (
        <>
          <Input
            inputClass={styles.customInput}
            label="Name of Plan Manager*"
            name="name"
            required
            labelClass={styles.inputLabel}
            onChange={(e) => onChangeNDISDetails('name', e.target.value)}
            value={ndisDetails.name}
            onBlur={() => handleOnBlurNDISDetails('name')}
            hasError={!!errors.name && touchedFields.name}
          />
          <ErrorMessage error={errors.name} visible={!!errors.name && touchedFields.name} />
          <Input
            inputClass={styles.customInput}
            label="Plan Manager ABN"
            name="abn"
            required
            labelClass={styles.inputLabel}
            onChange={(e) => onChangeNDISDetails('abn', e.target.value)}
            value={ndisDetails.abn}
          />
          <Input
            inputClass={styles.customInput}
            label="Plan Manager email*"
            name="email"
            required
            labelClass={styles.inputLabel}
            onChange={(e) => onChangeNDISDetails('email', e.target.value)}
            value={ndisDetails.email}
            onBlur={() => handleOnBlurNDISDetails('email')}
            hasError={!!errors.email && touchedFields.email}
          />
          <ErrorMessage error={errors.email} visible={!!errors.email && touchedFields.email} />
        </>
      )}
    </div>
  );
};

export default NDISPlan;
