import { notification } from 'antd';
import Form from 'antd/lib/form/Form';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCardV2/PatientFooterCard';
import { Formik } from 'formik';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import PracticeLayout from 'layouts/PracticeLayout/PracticeLayout';
import { useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { useFetchClinician } from 'utils/hooks/clinician';
import {
  postProspectAppointments,
  postProspectPaymentRequiredAppointment,
  postProspectRequests
} from 'utils/http/appointment';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import * as Yup from 'yup';

import AppointmentHeader from '../components/AppointmentHeader/AppointmentHeader';
import styles from './AppointmentBookingNewCustomer.module.scss';
import NewCustomerForm from './components/NewCustomerForm/NewCustomerForm';

const initialCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  referralSource: '',
  mobileNumber: ''
};

const validateData = Yup.object().shape({
  firstName: Yup.string().required('Please enter your First Name'),
  lastName: Yup.string().required('Please input your Last Name'),
  email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
  mobileNumber: Yup.string().min(7, 'Mobile number has to be at least 7 characters')
});

const AppointmentBookingNewCustomer = () => {
  const { clinicianId, slugUrl = '' } = useParams<{ clinicianId: string; slugUrl: string }>();
  const location = useLocation();

  const navigate = useNavigate();
  const [isBookAppointmentButtonLoading, setIsBookAppointmentButtonLoading] = useState(false);

  const { bookingRule, deliveryOption, slot, accountId, practiceProfile, message, paymentRequired } = useMemo(() => {
    const { bookingRule, deliveryOption, slot, accountId, practiceProfile, message, paymentRequired } =
      (location.state as {
        bookingRule: string;
        deliveryOption: string;
        slot: AppointmentSlot;
        accountId: string;
        practiceProfile: PublicPracticeProfile;
        message: string;
        paymentRequired?: boolean;
      }) || {};

    if (!message) {
      if (!slot) {
        slugUrl ? navigate(`/p/${slugUrl}`) : navigate(`/${clinicianId}/appointment/new`);
      }
    }

    return { bookingRule, deliveryOption, slot, accountId, practiceProfile, message, paymentRequired };
  }, [clinicianId, navigate, location.state, slugUrl]);

  const { clinician, isClinicianLoading } = useFetchClinician(clinicianId!, !!practiceProfile);

  const handleOnSubmit = async (values: typeof initialCustomer) => {
    setIsBookAppointmentButtonLoading(true);

    try {
      if (bookingRule === 'instant' || bookingRule === 'request') {
        if (paymentRequired) {
          notification.info({
            message: 'Redirecting to payment page... Please do not refresh/go back.',
            duration: 5
          });
          const callPostProspectPaymentRequiredAppointment = await postProspectPaymentRequiredAppointment({
            clinicianId,
            deliveryOption,
            slot,
            accountId: clinician?.accountId || practiceProfile?.accountId,
            bookingRule,
            returnUrl: clinicianId
              ? `${window.location.origin}/${clinicianId}/appointment/confirmed`
              : `${window.location.origin}/p/${slugUrl}/appointment/confirmed`,
            cancelUrl: clinicianId
              ? `${window.location.origin}/${clinicianId}/appointment/new`
              : `${window.location.origin}/p/${slugUrl}`,
            contactDetails: {
              clinicianOrAccountId: clinicianId || practiceProfile?.accountId,
              ...values
            }
          });
          const { url, appointment } = await callPostProspectPaymentRequiredAppointment.json();
          localStorage.setItem(appointment._id, JSON.stringify({ clinician, appointment }));
          window.location.href = url;
          return;
        }
        const callPostProspectAppointment = await postProspectAppointments({
          deliveryOption,
          slot,
          accountId,
          clinicianId,
          bookingRule,
          contactDetails: {
            clinicianOrAccountId: clinicianId || practiceProfile?.accountId,
            ...values
          }
        });
        const createdAppointment = await callPostProspectAppointment.json();

        const getPathname = (clinicianId?: string) => {
          if (clinicianId) {
            if (bookingRule === 'instant') {
              return `/${clinicianId}/appointment/confirmed`;
            } else {
              return `/${clinicianId}/appointment/request/sent`;
            }
          } else {
            if (bookingRule === 'instant') {
              return `/p/${slugUrl}/appointment/confirmed`;
            } else {
              return `/p/${slugUrl}/appointment/request/sent`;
            }
          }
        };

        navigate(
          {
            pathname: getPathname(clinicianId)
          },
          {
            state: { appointment: createdAppointment, clinician, practiceProfile }
          }
        );
      } else {
        const callPostProspectRequest = await postProspectRequests({
          accountId,
          clinicianId,
          contactDetails: {
            clinicianOrAccountId: clinicianId || practiceProfile?.accountId,
            ...values
          },
          message
        });
        await callPostProspectRequest.json();
        navigate(
          {
            pathname: clinicianId
              ? `/${clinicianId}/appointment/request/sent`
              : `/p/${slugUrl}/appointment/request/sent`
          },
          { state: { clinician, practiceProfile } }
        );
      }
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 409) {
        notification.error({
          message: 'The slot you are trying to book has since been taken, please try another session.'
        });
      } else {
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to book your appointment. Please try again later.'
        });
      }

      setIsBookAppointmentButtonLoading(false);
    }
  };

  return clinician && !practiceProfile ? (
    <PatientContentLayout>
      {isClinicianLoading || !clinician ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={styles.contentWarraper}>
            <AppointmentHeader profileName={clinician.name} profileImg={clinician.avatar} label="Availability" />
          </div>
          <PatientFooterCard containerClassName={styles.fullHeightFooter} className={styles.cardContainer}>
            <div className={styles.form}>
              <Formik initialValues={initialCustomer} validationSchema={validateData} onSubmit={handleOnSubmit}>
                {({ values }) => (
                  <Form noValidate>
                    <ContentLayout>
                      <NewCustomerForm
                        isBookAppointmentButtonLoading={isBookAppointmentButtonLoading}
                        paymentRequired={paymentRequired}
                      />
                    </ContentLayout>
                  </Form>
                )}
              </Formik>
            </div>
          </PatientFooterCard>
        </>
      )}
    </PatientContentLayout>
  ) : (
    <PracticeLayout>
      <div className={styles.cardContainer}>
        <div className={styles.form}>
          <Formik initialValues={initialCustomer} validationSchema={validateData} onSubmit={handleOnSubmit}>
            {({ values }) => (
              <Form noValidate>
                <ContentLayout>
                  <NewCustomerForm
                    isBookAppointmentButtonLoading={isBookAppointmentButtonLoading}
                    paymentRequired={paymentRequired}
                  />
                </ContentLayout>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </PracticeLayout>
  );
};

export default AppointmentBookingNewCustomer;
