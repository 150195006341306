import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styles from './TimeExpireAlert.module.scss';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';

interface TimeExpireAlertProps {
  showExpiredAlert: boolean;
}
const TimeExpireAlert = ({ showExpiredAlert }: TimeExpireAlertProps) => {
  const { GP } = useSomeoneHealthRoutesGenerator();

  return (
    <Modal
      className={styles.modalContainer}
      bodyStyle={{ padding: 0 }}
      visible={showExpiredAlert}
      footer={null}
      closable={false}
    >
      <div className={styles.container}>
        <i className={`material-icons-outlined ${styles.infoIcon}`}>info</i>
        <div className={styles.alertContent}>
          <div className={styles.title}>Your appointment hold has timed out.</div>
          <p>
            Because of high demand for our GP we can only reserve your appointment time for 15 minutes for you to
            complete your booking. This is to ensure fairness for everyone trying to find an available slot.{' '}
          </p>
          <p>
            You may find the same appointment slot is still available, so please go back and select an open appointment.
          </p>
          <Link to={GP.BASE} className={styles.link}>
            Return to GP booking page
            <i className={`material-icons-outlined ${styles.backIcon}`}>arrow_back</i>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default TimeExpireAlert;
