import { useAuth0 } from '@auth0/auth0-react';

import styles from './SurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';

const SurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <div className={`${styles.container} select-theme`}>
      <SelectContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <SelectHeader className={styles.header} withPadding withMenu homeLink />
        </div>
      </SelectContentLayout>
      <SelectContentLayout className={styles.contentWrapper}>
        <SurveyQuestion />
      </SelectContentLayout>
    </div>
  );
};

export default SurveyQuestionWrapper;
