import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { getCaWClinicianDetails } from 'CaW/utils/http/ClinicianProfileService/CaW/caw';

export const useFetchClinicianDetails = (clinicianId: string) => {
  const [practitionerDetails, setPractitionerDetails] = useState<PractitionerDetailsInterface>(
    {} as PractitionerDetailsInterface
  );
  const [isPractitionerDetailsLoading, setIsPractitionerDetailsLoading] = useState(true);

  const fetchPractitionerDetails = useCallback(async () => {
    if (clinicianId) {
      try {
        setIsPractitionerDetailsLoading(true);
        const response: PractitionerDetailsInterface = await (await getCaWClinicianDetails(clinicianId)).json();

        setPractitionerDetails(response);
      } catch (ex) {
        console.log(ex);
        notification.error({ message: 'Something went wrong while trying to get CaW practitioner details' });
      }
      setIsPractitionerDetailsLoading(false);
    }
  }, [clinicianId]);

  useEffect(() => {
    fetchPractitionerDetails();
  }, [fetchPractitionerDetails]);

  return { practitionerDetails, isPractitionerDetailsLoading, fetchPractitionerDetails };
};
