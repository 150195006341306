import Loading from 'components/Loading/Loading';
import { useFetchReservedAppointmentsWithTimeZone } from 'Ease/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import { useContext, useEffect, useRef, useState } from 'react';
import ButtonEase, { ButtonStatusType } from 'Ease/components/ButtonEase/ButtonEase';
import Summary from './components/Summary/Summary';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './SignUpBookingConfirmation.module.scss';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import { UserContext } from 'utils/UserContextProvider';
import { message } from 'antd';
import { useEaseToken } from '../../utils/hooks/useEaseToken';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PaymentPolicy from 'Ease/components/PaymentPolicy/PaymentPolicy';
import EaseTimer from 'Ease/components/EaseTimer/EaseTimer';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import ClientTestimonial from 'Ease/components/ClientTestimonial/ClientTestimonial';
import { useAuth0 } from '@auth0/auth0-react';
import { CheckoutSessionMode } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';

const BookingConfirmation = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { token } = useEaseToken();
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { BOOKING, SIGNUP } = useEaseRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!
  });

  useEffect(() => {
    if (!reserveId || !accountId) {
      navigate(BOOKING.BASE);
    }
  }, [BOOKING.BASE, accountId, navigate, reserveId]);

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        const res = await putProcessWithPaymentMethod(
          accountId,
          reserveId,
          {
            mode: CheckoutSessionMode.Payment,
            cancelUrl: window.location.href,
            returnUrl: `${window.location.origin}${SIGNUP.WELCOME_BOOKING}`,
            saveCardForFutureUse: true
          },
          token
        );

        if (res.statusCode === 200) {
          const responseData = await res.json();

          if (responseData.checkoutUrl) {
            window.location.href = responseData.checkoutUrl;
          }
        } else if (res.statusCode === 204) {
          navigate(SIGNUP.WELCOME_BOOKING);
        }
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <EaseHelmetWrapper title={'EASE Wellbeing | Therapy & Counselling Experts in London - Booking confirmation'}>
        <EaseHeader withPadding withMenu greenMenu />
        <EaseContentLayout className={styles.layoutClassName}>
          {fetching || isLoading ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'ease-theme')}>
              <div className={styles.content}>
                {!isAuthenticated && (
                  <div className={styles.clientTestimonialCard}>
                    <ClientTestimonial
                      avatar="https://storage.googleapis.com/clinician-avatars/e160a4d42e82e0c300359427b45eb0c9-auth0|61137cdc838de9006a4ca594-profile-avatar.png"
                      name="EASE Client"
                      message='" Incredibly supportive in a non-judgmental space. My therapist helped me recognise the value of who I am as a person and to acknowledge my value. Also, helped me understand why I have acted and responded the way I have. It has provided me with an opportunity to learn and grow from my experiences. "'
                    />
                  </div>
                )}
                <div className={styles.leftContent}>
                  <div className={styles.greet}>
                    Thanks {clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}
                  </div>
                  <Summary
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    showPayment
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <PaymentPolicy policyAccepted={policyAccepted} onSetPolicyAccepted={setPolicyAccepted} />
                  <ButtonEase
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!policyAccepted || !token}
                    className={styles.submitButton}
                  >
                    CREATE YOUR PROFILE
                  </ButtonEase>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <div className={styles.noticeMsg}>
                      <div>
                        To confirm these appointments you need to complete registration and payment within 15 minutes
                      </div>
                    </div>
                    <EaseTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                    />
                  </div>
                  {!mobileTimer && !isAuthenticated && (
                    <ClientTestimonial
                      avatar="https://storage.googleapis.com/clinician-avatars/e160a4d42e82e0c300359427b45eb0c9-auth0|61137cdc838de9006a4ca594-profile-avatar.png"
                      name="EASE Client"
                      message='" Incredibly supportive in a non-judgmental space. My therapist helped me recognise the value of who I am as a person and to acknowledge my value. Also, helped me understand why I have acted and responded the way I have. It has provided me with an opportunity to learn and grow from my experiences. "'
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </EaseContentLayout>
        <TacklitFooter linkToTacklit />
      </EaseHelmetWrapper>
    </div>
  );
};

export default BookingConfirmation;
