import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { store } from 'redux/store';

import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.min.css';
import './index.scss';

import App from 'App';
import 'i18n';

const SENTRY_HOST = process.env.REACT_APP_SENTRY_HOST;

const sentryBrowserTracing = new Integrations.BrowserTracing();
const sentryCaptureConsole = new CaptureConsole({ levels: ['error'] });

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_HOST,
    integrations: [sentryBrowserTracing, sentryCaptureConsole],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
