import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import RechargeHelmetWrapper from '../../components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeHeader from '../../components/RechargeHeader/RechargeHeader';
import classNames from 'classnames';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';

const UpdateProfile = () => {
  return (
    <RechargeHelmetWrapper title={`Recharge | Profile`}>
      <RechargeContentLayout className={styles.container}>
        <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
        <UpdateProfileContent contentClassName={classNames(styles.content, 'recharge-theme')} />
      </RechargeContentLayout>
    </RechargeHelmetWrapper>
  );
};

export default UpdateProfile;
