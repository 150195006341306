import bent, { BentResponse } from 'bent';
import { SMPType, SMPUpdateAction, SMPUpdatePayload } from 'pages/SMP/interfaces/smp';
import { httpClient } from '../../httpClient';

const url = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const getSmpList = (token: string, clientRecordId: string, clientProfileId: string) =>
  httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp`
  ) as Promise<BentResponse>;

export const getSmpDetails = (token: string, clientRecordId: string, clientProfileId: string, smpId: string) =>
  httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp/${smpId}`
  ) as Promise<BentResponse>;

export const postSmp = ({
  token,
  blankPlan,
  type,
  clientRecordId,
  clientProfileId
}: {
  token: string;
  blankPlan?: boolean;
  type: SMPType;
  clientRecordId: string;
  clientProfileId: string;
}) =>
  httpClient('POST', url!, [201], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp?type=${type}&blankPlan=${blankPlan}`
  ) as Promise<BentResponse>;

export const putSmp = ({
  token,
  smpId,
  payload,
  clientRecordId,
  clientProfileId
}: {
  token: string;
  smpId: string;
  payload: SMPUpdatePayload;
  clientRecordId: string;
  clientProfileId: string;
}) => {
  if (payload.action === SMPUpdateAction.submit && payload.printPayload) {
    const xmlSerializer = new XMLSerializer();
    const printPayload = xmlSerializer.serializeToString(payload.printPayload);

    return httpClient('PUT', url!, [204], { Authorization: `Bearer ${token}` })(
      `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp/${smpId}`,
      { ...payload, printPayload }
    ) as Promise<BentResponse>;
  }

  return httpClient('PUT', url!, [204], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp/${smpId}`,
    payload
  ) as Promise<BentResponse>;
};

export const downloadPrintableSmp = async ({
  token,
  smpId,
  clientRecordId,
  clientProfileId
}: {
  token: string;
  smpId: string;
  clientRecordId: string;
  clientProfileId: string;
}) => {
  const response = await bent('GET', 'buffer', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/client-records/${clientRecordId}/client-profiles/${clientProfileId}/smp/${smpId}/printable-pdf`);

  return new Blob([response], { type: 'application/pdf' });
};
