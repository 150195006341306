import styles from './SHGPReferral.module.scss';

const SHGPReferral = () => {
  return (
    <div className={styles.container}>
      <div className={styles.shape2} />
      <div className={styles.shape4} />
      <div className={styles.cardBody}>
        <div className={styles.cardTitle}>GP Referral</div>
        <div className={styles.cardContent}>
          <p>On the next page you’ll be asked to upload your GP referral if your GP hasn’t already sent it to us.</p>
          <p>
            If you haven’t already got a referral from a GP you can book in a time with our own GP for a bulk billed
            video consultation after completing a short questionnaire on the next page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SHGPReferral;
