import tacklitLogo from 'assets/images/tacklitLogo.png';
import styles from './HelmSignUpFooter.module.scss';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import classNames from 'classnames';

interface HelmSignUpFooterProps {
  className?: string;
}

const HelmSignUpFooter = ({ className }: HelmSignUpFooterProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <HelmContentLayout className={styles.signUpFooter}>
        <img alt={'tackilit logo'} className={styles.tacklitLogo} src={tacklitLogo} />
        <div className={styles.copyrightSymbol}>©</div> Powered by Tacklit
      </HelmContentLayout>
    </div>
  );
};

export default HelmSignUpFooter;
