import { FC } from 'react';
import styles from './NoPsychologistBanner.module.scss';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

interface NoPsychologistBannerProps {
  clearAllFilter: () => void;
}

const NoPsychologistBanner: FC<NoPsychologistBannerProps> = ({ clearAllFilter }) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <SomeoneHealthContentLayout>
          <div className={styles.noResultWrapper}>
            <div className={styles.noResultTitle}>Can’t find what you’re looking for?</div>
            <div className={styles.noResultDesc}>
              Sorry, we’re unable to provide a match. Please adjust your criteria or{' '}
              <span className={styles.clearAllFiltersButton} onClick={clearAllFilter}>
                clear all filters
              </span>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      </div>
      <div className={styles.contentWrapper}>
        <SomeoneHealthContentLayout>
          <div className={styles.recommendWrapper}>
            <div className={styles.trustLabel}>
              <span className={styles.label}>These trusted clinicians ready for you</span>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      </div>
    </div>
  );
};

export default NoPsychologistBanner;
