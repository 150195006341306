import { useEffect, useState } from 'react';

import Button from 'components/v2/Button/Button';

import styles from './ResendOtp.module.scss';

interface ResendOtpProps {
  sendOtp: () => Promise<void>;
}

const ResendOtp = ({ sendOtp }: ResendOtpProps) => {
  const [timeUntilAllowResend, setTimeUntilAllowResend] = useState(0);
  const [, setIntervalId] = useState<number>();
  const [resendButtonStatus, setResendButtonStatus] = useState<'' | 'active' | 'finished'>('');

  const resetInterval = () => {
    setTimeUntilAllowResend(60);

    const intervalId = window.setInterval(() => {
      setTimeUntilAllowResend((timeUntilAllowResend) => {
        if (timeUntilAllowResend > 1) {
          return timeUntilAllowResend - 1;
        } else {
          setIntervalId(() => {
            window.clearInterval(intervalId);
            return undefined;
          });
          return 0;
        }
      });
    }, 1000);

    setIntervalId(intervalId);
  };

  useEffect(
    resetInterval,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleResendOtp = async () => {
    setResendButtonStatus('active');

    await sendOtp();
    resetInterval();
    setResendButtonStatus('finished');

    setTimeout(() => {
      setResendButtonStatus('');
    }, 2000);
  };

  return (
    <>
      <div className={styles.description}>
        New code available{timeUntilAllowResend ? ` in ${timeUntilAllowResend} seconds` : ''}
      </div>
      <Button
        className={styles.resendButton}
        status={resendButtonStatus}
        variant="primary"
        disabled={!!resendButtonStatus || !!timeUntilAllowResend}
        onClick={handleResendOtp}
      >
        Resend Code
      </Button>
    </>
  );
};

export default ResendOtp;
