import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import { Clinician } from 'utils/hooks/clinician';

import PatientContentLayout from '../../../components/PatientContentLayout/PatientContentLayout';
import AppointmentPatientHeader from '../components/AppointmentPatientHeader/AppointmentPatientHeader';

import styles from './AppointmentRequestSent.module.scss';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';

const AppointmentRequestSent = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianId, slugUrl = '' } = useParams<{ clinicianId: string; slugUrl: string }>();

  const { clinician, practiceProfile } = useMemo(() => {
    const { clinician, practiceProfile } =
      (location.state as { clinician: Clinician; practiceProfile: PublicPracticeProfile } | undefined) || {};

    if (!practiceProfile) {
      if (!clinician) {
        navigate(`/${clinicianId}/appointment`);
      }
    }

    return { clinician, practiceProfile };
  }, [clinicianId, navigate, location.state]);

  return (
    <PatientContentLayout>
      <div className={styles.contentWrapper}>
        {user ? <AppointmentPatientHeader profileImg={user?.picture} /> : null}
        <div className={styles.content}>
          <div className={styles.heading}>Your appointment request has been sent</div>
          {user ? (
            <div className={styles.text}>
              Thanks {user?.name}, we have shared your request for an appointment with {clinician?.title}{' '}
              {clinician?.name}. We will be in contact shortly.
            </div>
          ) : (
            <div className={styles.text}>
              Thank you, we have shared your request for an appointment with{' '}
              {practiceProfile ? practiceProfile.name : `${clinician?.title} ${clinician?.name}`}. We will be in contact
              shortly.
            </div>
          )}
          <div className={styles.text}>You can now:</div>
          <div>
            <Link className={styles.suggestionList} to={practiceProfile ? `/p/${slugUrl}` : `/profile/${clinicianId}`}>
              Look for another appointment
              <i className={`material-icons ${styles.suggestionIcon}`}>arrow_forward</i>
            </Link>
          </div>
        </div>
      </div>
    </PatientContentLayout>
  );
};

export default AppointmentRequestSent;
