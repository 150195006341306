import { ReactNode, useState } from 'react';
import styles from './RechargeCreateProfileForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import {
  formDataValidationSchema,
  howDidYouHearAboutUsOptions,
  initialCustomer,
  passwordValidationSchema
} from './constants';
import FormikRadio from 'components/Radio/FormikRadio';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { RechargePrivacyPolicyUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';
import RechargeMobileFormikInput from '../RechargeMobileFormikInput/RechargeMobileFormikInput';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';
import FormikSelect from 'components/Select/FormikSelect';
import { useAuth0 } from '@auth0/auth0-react';
import { useRechargeRoutesGenerator } from 'Recharge/utils/Path/RechargeRoutesGenerator';

interface RechargeCreateProfileFormProps {
  isSubmitting: boolean;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialCustomer) => void;
  subHeader?: ReactNode;
}

const RechargeCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  subHeader
}: RechargeCreateProfileFormProps) => {
  const { loginWithRedirect } = useAuth0();
  const { isDoDPath } = useRechargeDoDOrNonDoDPathGenerator();
  const { LOGIN } = useRechargeRoutesGenerator();

  const [pwScore, setPwScore] = useState(0);
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [showPwError, setShowPwError] = useState(false);

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialCustomer>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'recharge-theme')}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            Welcome to Recharge Wellness
            <div className={styles.subHeader}>
              {subHeader ? subHeader : 'Create your secure account and take your first step to a better future.'}
            </div>
          </div>
        </div>
        <div className={styles.loginText}>
          Already have an account?{' '}
          <span
            onClick={() =>
              loginWithRedirect({
                loginType: 'patient',
                redirectUri:
                  (process.env.REACT_APP_CLIENT_DOMAIN_RECHARGE || 'https://my.rechargewellness.com.au') + LOGIN,
                appState: { returnTo: window.location.pathname }
              })
            }
            className={styles.loginLink}
          >
            Login instead
          </span>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={formDataValidationSchema(!isDoDPath).concat(passwordValidationSchema(pwScore))}
          onSubmit={onSubmit}
        >
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                {!isDoDPath && (
                  <FormikSelect
                    className={styles.customSelect}
                    labelClass={styles.selectInputLabel}
                    label={'How did you hear about us?'}
                    placeholder={'Select'}
                    isSearchable={false}
                    name="hearAboutSource"
                    options={howDidYouHearAboutUsOptions}
                    styles={{
                      valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                    }}
                  />
                )}
                <RechargeMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={'0482 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                  labelClass={styles.inputLabel}
                  allowShowPassword
                  showPasswordButtonClass={styles.showPasswordButtonClass}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.ageConfirm}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="ageConfirm"
                    value={ageConfirm}
                    onChange={(e) => setAgeConfirm(e.target.checked)}
                    className={styles.checkBox}
                    label={`I agree to Recharge Wellness' <a target="_blank" href="${RechargePrivacyPolicyUrl}">Privacy Policy and Terms & Conditions</a>`}
                  />
                </div>
              </div>
              <div className={styles.communicationPreferences}>
                <div className={styles.title}>Communication Preferences</div>
                <div className={styles.text}>
                  We will send you relevant information regarding your appointment via email and SMS. This includes
                  items like appointment reminders, and requests to complete documents relevant to your program. This is
                  not marketing.
                </div>
                <FormikRadio
                  id={'communicationPreference'}
                  name={'communicationPreference'}
                  options={[
                    { label: 'I would like Email correspondence only', value: CommunicationPreference.Email },
                    { label: 'I would like SMS correspondence only', value: CommunicationPreference.SMS },
                    { label: 'SMS and / or Email', value: CommunicationPreference.NoPreference }
                  ]}
                  radioLabelClass={styles.radioLabel}
                  vertical
                />
              </div>

              <ButtonRecharge
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, initialCustomer) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit(values);
                    }
                  });
                }}
              >
                Create Your Profile
              </ButtonRecharge>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RechargeCreateProfileForm;
