import { createSlice } from '@reduxjs/toolkit';
import { GPTimeSlotsWithDateInterface } from 'SomeoneHealth/pages/Booking/components/BookingFlow/hooks/useFetchGPAvailabilityWithTimeZone';
import { RootState } from 'redux/store';
import { AvailabilityAppointmentTypes, DeliveryType } from 'utils/hooks/appointment';

interface GPBookingState {
  selectedGPAppointmentType: AvailabilityAppointmentTypes | undefined;
  isTimeSlotsLoading: boolean;
  selectedTimeSlot: GPTimeSlotsWithDateInterface | undefined;
  selectedDeliveryType: DeliveryType;
}

const initialState: GPBookingState = {
  selectedGPAppointmentType: undefined,
  isTimeSlotsLoading: false,
  selectedTimeSlot: undefined,
  selectedDeliveryType: DeliveryType.VideoCall
};

export const gpBookingSlice = createSlice({
  name: 'gpBookingSlice',
  initialState,
  reducers: {
    setSelectedGPAppointmentType: (state, action) => {
      state.selectedGPAppointmentType = action.payload;
    },
    setIsTimeSlotsLoading: (state, action) => {
      state.isTimeSlotsLoading = action.payload;
    },
    setSelectedTimeSlot: (state, action) => {
      state.selectedTimeSlot = action.payload;
    },
    setSelectedDeliveryType: (state, action) => {
      state.selectedDeliveryType = action.payload;
    }
  }
});

export const selectSelectedGPAppointmentType = (state: RootState) => state.gpBookingSlice.selectedGPAppointmentType;
export const selectIsTimeSlotsLoading = (state: RootState) => state.gpBookingSlice.isTimeSlotsLoading;
export const selectSelectedTimeSlot = (state: RootState) => state.gpBookingSlice.selectedTimeSlot;
export const selectSelectedDeliveryType = (state: RootState) => state.gpBookingSlice.selectedDeliveryType;

export const { setSelectedGPAppointmentType, setIsTimeSlotsLoading, setSelectedTimeSlot, setSelectedDeliveryType } =
  gpBookingSlice.actions;

export default gpBookingSlice.reducer;
