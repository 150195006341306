import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.loading}>
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
  </div>
);

export default Loading;
