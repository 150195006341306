import { useAuth0 } from '@auth0/auth0-react';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import styles from './ReferralDetail.module.scss';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import backgroundImg from 'helm/assets/images/background/HelmClientBg.jpg';
import ReferralDetailForm from './components/ReferralDetailForm/ReferralDetailForm';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';

const ReferralDetail = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <PatientContentLayout
      className={styles.container}
      style={{
        backgroundImage: `url('${backgroundImg}')`,
        backgroundColor: 'transparent !important',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {IS_HELM_APP && (
        <div className={styles.headerWrapper}>
          <HelmHeader noWrapper whiteFont logoWithoutText whiteLogo withPadding withMenu homeLink />
        </div>
      )}
      <div className={styles.contentWrapper}>
        {!IS_HELM_APP && <ClientHeader />}
        <div className={styles.title}>Add Referral Details</div>
        <div className={styles.content}>
          <div className={styles.description}>
            Complete and share details of your referral{IS_HELM_APP ? ' with Helm' : ''}. This is required for any
            Medicare claims.
          </div>
          <ReferralDetailForm />
        </div>
      </div>
    </PatientContentLayout>
  );
};

export default ReferralDetail;
