import { useEffect } from 'react';
import styles from './PartnerPractitionerDetails.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useFetchPractitionerDetails } from 'helm/pages/PractitionerDetails/hooks/getPractitionerDetails';
import HelmFooter from 'helm/components/HelmFooter/HelmFooter';
import PractitionerTimeSlot from './components/PractitionerTimeSlot/PractitionerTimeSlot';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import queryString from 'query-string';
import { PractitionerQueryParam } from 'helm/pages/PractitionerListing/PractitionerListing';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import HelmHeader from '../../../components/HelmHeader/HelmHeader/HelmHeader';

const PartnerPractitionerDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();

  const { timeSlotSection }: PractitionerQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('HelmTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return isPractitionerDetailsLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <HelmHelmetWrapper title={`HELM - ${practitionerName} Profile`}>
      <div className={styles.headerContainer}>
        <HelmHeader withPadding whiteFont withMenu classNames={styles.header} noLogin />
      </div>
      <PractitionerTimeSlot helmDetails={practitionerDetails} overlayImage />
      <HelmFooter hideProgram />
    </HelmHelmetWrapper>
  );
};

export default PartnerPractitionerDetails;
