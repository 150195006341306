import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import styles from './CordsSurveyList.module.scss';

const CordsSurveyList = () => (
  <CORDSContentWrapper title={'CORDS - Survey List'} fullwidth contentClassName={styles.container}>
    <div className={styles.content}>
      <SurveyList />
    </div>
  </CORDSContentWrapper>
);

export default CordsSurveyList;
