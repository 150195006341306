import styles from './ReferralDetail.module.scss';
import ReferralDetailForm from './components/ReferralDetailForm/ReferralDetailForm';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';

const ReferralDetail = () => {
  return (
    <EaseContentLayout className={`${styles.container} ease-theme`}>
      <EaseHeader withPadding withMenu homeLink greenMenu />
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Add Referral Details</div>
        <div className={styles.content}>
          <div className={styles.description}>Complete and share details of your referral with EASE Wellbeing.</div>
          <ReferralDetailForm />
        </div>
      </div>
    </EaseContentLayout>
  );
};

export default ReferralDetail;
