import styles from './EaseHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LoginAvatar from 'Ease/components/LoginAvatar/LoginAvatar';
import EaseContentLayout from '../EaseContentLayout/EaseContentLayout';
import easeFullLogo from 'Ease/assets/images/logo/logo-full.png';
import { EaseUrl } from 'Ease/utils/EaseEnv/EaseEnv';

interface EaseHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  classNames?: string;
  loginTextClass?: string;
  greenMenu?: boolean;
}

const EaseHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  classNames,
  loginTextClass,
  greenMenu
}: EaseHeaderProps) => {
  const navigate = useNavigate();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = EaseUrl;
    }
  };

  return (
    <EaseContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, classNames)}
    >
      <div id={'EaseHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img alt={'logo'} src={easeFullLogo} />
        </div>
        <div className={styles.rightContent}>
          {!noLogin && (
            <LoginAvatar
              whiteFont={whiteFont}
              greenMenu={greenMenu}
              withMenu={withMenu}
              loginTextClass={loginTextClass}
            />
          )}
        </div>
      </div>
    </EaseContentLayout>
  );
};

export default EaseHeader;
