import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import Radio from 'components/Radio/Radio';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAdHocAssessment, putQuestionsResponses } from '../../../../utils/http/checkIn';
import styles from './Assessment.module.scss';

type AssessmentProps = {
  code: string;
  adHocAssessmentId: string;
  clinicianId: string;
  authToken: string;
  resize: () => void;
};
type QuestionProps = {
  id: string;
  stem: string;
  options: Array<{
    key: string;
    description: string;
    value: number;
  }>;
};

type AssessmentDataProps = {
  _id: string;
  clinicianId: string;
  assessment: {
    clinicalAssessments: Array<{
      id: string;
      title: string;
      sections: Array<{
        questions: Array<QuestionProps>;
      }>;
    }>;
  };
};

const Assessment = ({ code, resize, adHocAssessmentId, clinicianId, authToken }: AssessmentProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<QuestionProps[]>();
  const [values, setValues] = useState<string[]>([]);
  const [selected, setSelected] = useState('');
  const [status, setStatus] = useState('progressing');
  const [submitting, setSubmitting] = useState(false);
  const [assessmentData, setAssessmentData] = useState<AssessmentDataProps>();

  const params: { type?: string } = useParams<{ type: string }>();

  useEffect(() => {
    if (adHocAssessmentId && clinicianId && authToken) {
      (async () => {
        const response = await (await getAdHocAssessment(authToken, clinicianId, adHocAssessmentId)).json();
        setAssessmentData(response);
        const questions = response.assessment.clinicalAssessments[0].sections[0].questions;
        setValues(new Array(questions.length).fill(''));
        setQuestions(questions);
      })();
    }
  }, [adHocAssessmentId, clinicianId, authToken]);

  useEffect(() => {
    if (values) {
      setSelected(values[currentQuestionIndex]);
    }
  }, [currentQuestionIndex, setSelected, values]);

  useEffect(() => {
    resize();
  });

  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (values) {
      setSelected(e.target.value);
      setValues(
        values.map((value, index) => {
          if (index === currentQuestionIndex) {
            return e.target.value;
          }
          return value;
        })
      );
    }
  };

  const submitForm = async () => {
    if (assessmentData && values && questions) {
      setSubmitting(true);
      const responses = values.map((value, index) => ({
        assessmentId: assessmentData.assessment.clinicalAssessments[0].id,
        questionId: questions[index].id,
        response: {
          value
        }
      }));
      try {
        await putQuestionsResponses(authToken, assessmentData.clinicianId, assessmentData._id, {
          responses,
          status: 'done'
        });
        setSubmitting(false);
        setStatus('completed');
      } catch (e) {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      {status === 'progressing' && (
        <>
          {!params.type && <div className={styles.assessmentTitle}>{code}</div>}
          <div className={styles.assessmentSubtitle}>
            Over the last 2 weeks, how often have you been bothered by any of the following problems?
          </div>
          <div className={styles.assessmentContainer}>
            <>
              <div className={styles.progressBarContainer}>
                <div
                  className={styles.progressBar}
                  style={{
                    width: `${(values.filter((value) => value).length / values.length) * 100}%`
                  }}
                />
                <div
                  className={styles.remainingProgressBar}
                  style={{
                    width: `${(values.filter((value) => !value).length / values.length) * 100}%`
                  }}
                />
              </div>
              <div className={styles.body}>
                <div className={styles.description}>{questions?.[currentQuestionIndex].stem || ''}</div>
                <Radio
                  id={code}
                  vertical
                  options={
                    questions?.[currentQuestionIndex].options.map((option) => ({
                      label: option.description,
                      value: option.key
                    })) ?? []
                  }
                  value={selected}
                  onChange={onSelect}
                  className={styles.radio}
                />
                <div className={styles.navButtons}>
                  <div>
                    <button
                      className={styles.button}
                      onClick={() => {
                        setCurrentQuestionIndex(currentQuestionIndex - 1);
                      }}
                      disabled={currentQuestionIndex === 0 || submitting}
                    >
                      BACK
                    </button>
                  </div>
                  <div>
                    {questions && currentQuestionIndex === questions.length - 1 ? (
                      <button
                        className={styles.button}
                        onClick={() => {
                          submitForm();
                        }}
                        disabled={selected === '' || submitting}
                        style={{ width: '150px' }}
                      >
                        {submitting ? <LoadingCircle /> : 'SUBMIT'}
                      </button>
                    ) : (
                      <button
                        className={styles.button}
                        onClick={() => {
                          setCurrentQuestionIndex(currentQuestionIndex + 1);
                        }}
                        disabled={selected === ''}
                      >
                        NEXT
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      )}
      {status === 'completed' && (
        <div className={styles.completedText}>
          Well done! You’ve completed this assessment.
          <br />
          Thank you.
        </div>
      )}
    </div>
  );
};

export default Assessment;
