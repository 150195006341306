import styles from './CompletePage.module.scss';
import classNames from 'classnames';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import { retrieveUserSession } from 'SomeoneHealth/pages/SignUp/helper/userSession';
import { useFetchPractitionerDetails } from 'SomeoneHealth/pages/PsychologistDetails/hooks/getPsychologistDetails';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useAuth0 } from '@auth0/auth0-react';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const CompletePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { HOME } = useSomeoneHealthRoutesGenerator();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];
  const { clinicianId } = useReserveAppointmentData();
  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  const handleGoToAccount = () => {
    removeReserveAppointmentData();
    if (!isDevelopmentENV()) {
      window.location.href = process.env.REACT_APP_CLIENT_DOMAIN_SOMEONE_HEALTH || 'https://my.someone.health';
    } else {
      navigate(HOME);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - welcome'}>
      <div className={classNames(styles.container, 'someone-health-theme')}>
        <SomeoneHealthHeader withPadding pinkLogo withMenu pinkMenu />
        <SomeoneHealthContentLayout>
          <div className={styles.content}>
            <div className={styles.title}>YOUR BOOKING IS CONFIRMED</div>
            <div className={styles.welcome}>
              <div>
                <div className={styles.welcomeTitle}>
                  {isAuthenticated ? 'Hi' : 'Welcome'} {user?.name}
                </div>
                {practitionerDetails.helmControl?.welcomeNote && (
                  <div className={styles.message}>{practitionerDetails.helmControl.welcomeNote}</div>
                )}
                <div className={styles.messageEnding}>
                  Kind Regards,
                  <br />
                  <div className={styles.clinicianName}>{clinicianName}</div>
                </div>
              </div>
              <div className={styles.avatar}>
                <img src={clinicianAvatar} alt={'Avatar'} />
              </div>
            </div>

            <div className={styles.whatNext}>
              {!isAuthenticated && (
                <>
                  <div className={styles.label}>You can now access your secure account.</div>
                  <div className={styles.radioLabel}>
                    Here we will post notifications and requests for you to complete forms or assessments from time to
                    time. If something new is requested we will also send you an email to let you know.
                  </div>
                  <div className={styles.radioLabel}>
                    You can also manage all your appointments here directly should you need to make a change.
                  </div>
                </>
              )}
              <ButtonSH onClick={handleGoToAccount} className={styles.goToMyAccountBtn}>
                Go to my account
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default CompletePage;
