import { components } from 'react-select';
import CountryCodeDetails from '../CountryCodeDetails/CountryCodeDetails';

const CountryCodeOption = ({ children, ...props }: any) => {
  return (
    <components.Option {...props}>
      <CountryCodeDetails data={props.data} />
    </components.Option>
  );
};

export default CountryCodeOption;
