import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export const scheduleServicesApiSlice = createApi({
  reducerPath: 'scheduleServiceApis',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SCHED_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently().catch(() => '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['AppointmentTypes', 'AvailabilityAppointmentsByAppointmentTypeId', 'ReservedAppointmentByIdRequest'],
  endpoints: () => ({})
});
