import styles from './PsychologistAvailabilityCalendar.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AvailabilityPill from 'Select/components/AvailabilityPill/AvailabilityPill';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import { SelectContactUrl } from 'Select/utils/env/SelectEnv';

interface PractitionerAvailabilityCalendarProps {
  practitionerDetails: PractitionerDetailsInterface;
  nextAvailabilityDetails: PractitionerDetailsInterface['nextAvailabilities'];
  detailPath: string;
  onShowWaitlistForm: () => void;
  isDarkerBackground: boolean;
}

const todayDate = moment();

const PsychologistAvailabilityCalendar = ({
  practitionerDetails,
  nextAvailabilityDetails,
  detailPath,
  onShowWaitlistForm,
  isDarkerBackground
}: PractitionerAvailabilityCalendarProps) => {
  const { isAuthenticated } = useAuth0();

  const massageAvailabilityData =
    // (!) uncomment this if select needs a certain appointment type to be filtered over others
    // change import to Select/ and move to top
    // import { practitionerListingAppointmentTypeNames } from 'Recharge/interfaces/appointmentType';
    // nextAvailabilityDetails?.find(
    //   (obj) =>
    //     obj.availabilities?.length &&
    //     practitionerListingAppointmentTypeNames.includes(encodeURIComponent(obj.appointmentTypeName?.toLowerCase()))
    // ) ||
    nextAvailabilityDetails?.[0];

  const isNewClientHasNoSlots =
    !isAuthenticated &&
    practitionerDetails.helmControl?.shouldUseCaseLoad &&
    (practitionerDetails.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div className={styles.container}>
      <div className={styles.calendarContent}>
        {isNewClientHasNoSlots ? (
          <div className={isDarkerBackground ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
            <div className={styles.noAvailableCard}>
              <div className={styles.messageWrapper}>
                <div className={styles.redDotWrapper}>
                  <div className={styles.redDot} />
                </div>
                <div className={styles.message}>
                  <div className={styles.desc}>Sorry...</div>
                  <div className={styles.noAvailDesc}>
                    I am sorry but I have no appointments available to book online right now, you can{' '}
                    <a href={SelectContactUrl}>contact</a> the office for more availability. Alternatively you can add
                    yourself to the waiting list and a member of our team will be in touch within 1 working day to
                    discuss your requirements.
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonSelect icon={'post_add'} iconPostFix variant={'outlined'} onClick={onShowWaitlistForm}>
                  Join Waitlist
                </ButtonSelect>
              </div>
            </div>
          </div>
        ) : massageAvailabilityData ? (
          <div className={styles.calendarCardWrapper}>
            {massageAvailabilityData.availabilities.slice(0, 2).map((availabilitiesObj, index) => {
              const availabilityDate = moment(availabilitiesObj.date);
              const dayDifference = Math.floor(availabilityDate.diff(todayDate, 'days', true)) + 1;

              const massageAvailabilityTimeSlot =
                dayDifference <= 0
                  ? availabilitiesObj.timeSlots.filter((obj) => moment(obj.startTime, 'hh:mma').isAfter(todayDate))
                  : availabilitiesObj.timeSlots;

              return (
                massageAvailabilityTimeSlot.length > 0 && (
                  <div className={isDarkerBackground ? styles.calendarCardOdd : styles.calendarCardEven} key={index}>
                    <div className={styles.cardContent}>
                      <div className={styles.calendarInfo}>
                        <div className={styles.greenDot} />
                        <div className={styles.calendarDateInfo}>
                          <div className={styles.date}>{moment(availabilitiesObj.date).format('DD')}</div>
                          <div className={styles.month}>{moment(availabilitiesObj.date).format('MMM')}</div>
                        </div>
                        <div className={styles.calendarDateInfo}>
                          <div className={styles.day}>{moment(availabilitiesObj.date).format('dddd')}</div>
                          <div className={styles.openAppointment}>
                            {massageAvailabilityTimeSlot.length || 0} open appointments
                          </div>
                        </div>
                      </div>
                      <div className={styles.timeslotWrapper}>
                        {massageAvailabilityTimeSlot.map((timeSlotObj, index) => {
                          const startTime = moment(timeSlotObj.startTime, 'hh:mmA').format('h:mm');
                          const endTime = moment(timeSlotObj.endTime, 'hh:mmA').format('h:mmA');
                          const selectedDateTime = `selectedDateTime=${availabilitiesObj.date},${timeSlotObj.startTime},${timeSlotObj.endTime}`;
                          const newDetailPath = `${detailPath}?${selectedDateTime}`;

                          return index <= 2 ? (
                            <Link to={newDetailPath} key={index}>
                              <AvailabilityPill classNames={styles.timeslot}>
                                {startTime} to {endTime}
                              </AvailabilityPill>
                            </Link>
                          ) : index === 3 ? (
                            <Link to={detailPath} key={index} className={styles.seeMoreSlot}>
                              ...+{availabilitiesObj.timeSlots.length - 3} MORE TIMES
                            </Link>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        ) : (
          <div className={isDarkerBackground ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
            <div className={styles.noAvailableCard}>
              <div className={styles.messageWrapper}>
                <div className={styles.redDotWrapper}>
                  <div className={styles.redDot} />
                </div>
                <div className={styles.message}>
                  <div className={styles.desc}>Sorry...</div>
                  <div className={styles.noAvailDesc}>
                    My time for&nbsp;<strong>next 4 weeks</strong>&nbsp;is fully booked.
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonSelect icon={'post_add'} iconPostFix variant={'outlined'} onClick={onShowWaitlistForm}>
                  Join Waitlist
                </ButtonSelect>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychologistAvailabilityCalendar;
