import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

export const getSomeoneHealthSupportEmail = (code?: AppointmentBookingClaimType) => {
  const { VTPHNAEmail, someoneHealthEmail } = someoneHealthEnvironment();
  switch (code) {
    case AppointmentBookingClaimType.VTPHNA:
      return VTPHNAEmail;
    default:
      return someoneHealthEmail;
  }
};
