import momentTz from 'moment-timezone';

const clinicianTimeZone = 'Australia/Melbourne';

export const convertTimeSlot = (startTime: string, endTime: string, date: string | Date, clientTimeZone: string) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, clinicianTimeZone);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, clientTimeZone).format('hh:mmA');
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, clinicianTimeZone);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, clientTimeZone).format('hh:mmA');
  return {
    startTime: clientStartTimeZone,
    endTime: clientEndTimeZone
  };
};
