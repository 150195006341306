import styles from './YourProgressContent.module.scss';
import { ProgramInterface, ProgramQuoteInterface } from 'helm/interfaces/program/program';
import mountainImg from 'helm/assets/images/ip6/progressMountain.png';
import classnames from 'classnames';

interface YourProgressContentProps {
  data: ProgramInterface['chapters'];
  quote: ProgramQuoteInterface;
}

const YourProgressContent = ({ data, quote }: YourProgressContentProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.bgImage} />
        <div className={styles.sessionContainer}>
          {data.map((chapterObj, index) => (
            <div className={styles.sessionCard} key={index}>
              <div
                className={classnames(
                  styles.sessionDot,
                  !chapterObj.completed && chapterObj.link && styles.sessionActiveDot,
                  chapterObj.completed && [styles.completeDot, 'material-icons']
                )}
              >
                {chapterObj.completed ? 'flag' : index + 1}
              </div>
              <div>
                Session {index + 1}, {chapterObj.name}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.quoteContainer}>
          <div className={styles.quoteContent}>
            <div className={styles.quote}>{quote.reference}</div>
            <div className={styles.quoteBy}>-- {quote.referenceBy}</div>
          </div>
        </div>
        <div className={styles.bgContainer}>
          <div className={styles.dotContainer}>
            {data.map((chapterObj, index) => (
              <div
                key={index}
                className={classnames(
                  styles[`dot${index + 1}`],
                  !chapterObj.completed && chapterObj.link && styles.activeDot,
                  chapterObj.completed && [styles.completeDot, 'material-icons']
                )}
              >
                {chapterObj.completed ? 'flag' : index + 1}
              </div>
            ))}
            <div className={styles.supportIconWrapper}>
              {[...Array(5)].map((obj, i) => (
                <div key={i} className={`material-icons-outlined ${styles[`taskIcon${i + 1}`]}`}>
                  support
                </div>
              ))}
            </div>
          </div>
          <img alt={'progressImg'} draggable={false} className={styles.image} src={mountainImg} />
        </div>
      </div>
    </div>
  );
};

export default YourProgressContent;
