import { skipToken } from '@reduxjs/toolkit/dist/query';
import darkTacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import whiteTacklitLogo from 'assets/images/tacklit-logo-white.svg';
import { useLocation, useParams } from 'react-router-dom';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import queryString from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';

export const useCustomLogo = () => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();
  const { token } = queryString.parse(location.search) as { token?: string };

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { clinicianSlugUrlOrId, slugUrl } = useParams();

  const slugUrlOrAccountId =
    (isAuthenticated && user?.['https://tacklit.com/roles'].includes('clinician')) || token
      ? attachedClinicianDetail?.practice?.slugUrl ||
        attachedClinicianDetail?.accountId ||
        clinicianSlugUrlOrId ||
        slugUrl
      : !isAuthenticated && !token
      ? clinicianSlugUrlOrId || slugUrl
      : attachedClinicianDetail?.practice?.slugUrl ||
        attachedClinicianDetail?.accountId ||
        clinicianSlugUrlOrId ||
        slugUrl;

  const { data: practiceInfoData, isLoading: practiceInfoDataLoading } = useGetPracticeInfoQuery(
    slugUrlOrAccountId ? { slugUrlOrAccountId } : skipToken
  );

  return {
    darkLogo: practiceInfoData?.accountSettings?.logo?.dark || darkTacklitLogo,
    lightLogo: practiceInfoData?.accountSettings?.logo?.light || whiteTacklitLogo,
    logoAlt: `${practiceInfoData?.name} logo`,
    isCustomLogo: practiceInfoData?.accountSettings?.logo,
    isLogoLoading: isAttachedClinicianDetailLoading || practiceInfoDataLoading
  };
};
