import { FC } from 'react';
import { Skeleton } from 'antd';
import styles from './PsychologistSkeleton.module.scss';
import classNames from 'classnames';

const PsychologistGridSkeleton: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <Skeleton.Avatar active size={100} />
        <Skeleton.Input active className={classNames(styles.name, styles.rounded)} />
        <Skeleton.Input active className={classNames(styles.desc, styles.rounded)} />
        <Skeleton.Input active className={classNames(styles.intro, styles.rounded)} />
        <Skeleton.Input active className={classNames(styles.intro, styles.rounded)} />
        <Skeleton.Input active className={classNames(styles.intro, styles.rounded)} />
      </div>
      <div className={styles.specialisationContainer}>
        <Skeleton.Input active size={'small'} className={classNames(styles.title, styles.rounded)} />
        <div className={styles.specialisationList}>
          <Skeleton.Input active className={classNames(styles.mediumSpecialisation, styles.rounded)} />
          <Skeleton.Input active className={classNames(styles.smallSpecialisation, styles.rounded)} />
          <Skeleton.Input active className={classNames(styles.mediumSpecialisation, styles.rounded)} />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <Skeleton.Input active className={classNames(styles.nextAvailable, styles.rounded)} />
        <Skeleton.Input active className={classNames(styles.seeAll, styles.rounded)} />
      </div>
    </div>
  );
};

export default PsychologistGridSkeleton;
