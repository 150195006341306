import styles from './AvailabilityPill.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';

export interface AvailabilityPillProps {
  children: ReactNode;
  classNames?: string;
}

const AvailabilityPill = ({ children, classNames }: AvailabilityPillProps) => {
  return <div className={classnames(styles.container, classNames)}>{children}</div>;
};

export default AvailabilityPill;
