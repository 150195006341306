import styles from './Tag.module.scss';

interface TagProps {
  label: string;
}

const Tag = ({ label }: TagProps) => {
  return <div className={styles.container}>{label}</div>;
};

export default Tag;
