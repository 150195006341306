import { Route, Routes } from 'react-router-dom';
import WelcomePage from '../pages/SignUp/WelcomePage/WelcomePage';
import WelcomeTaskList from '../pages/SignUp/WelcomeTaskList/WelcomeTaskList';
import PolicyPage from '../pages/SignUp/PolicyPage/PolicyPage';
import SignUpForm from '../pages/SignUp/SignUpForm/SignUpForm';
import SuccessSignUpPage from '../pages/SignUp/SuccessSignUpPage/SuccessSignUpPage';
import appRoute from '../components/AppRoute/AppRoute';
import SignUpWrapper from '../pages/SignUp/components/SignUpWrapper/SignUpWrapper';
import ReferralPage from 'pages/ReferralPage/ReferralPage';
import PracticeAvailability from 'pages/PracticeAvailability/PracticeAvailability';
import PracticeLandingPage from 'pages/PracticeLandingPage/PracticeLandingPage';
import AppointmentBookingNewCustomer from 'pages/PatientAppointment/AppointmentBookingNewCustomer/AppointmentBookingNewCustomer';
import AppointmentBookingType from 'pages/PatientAppointment/AppointmentBookingType/AppointmentBookingType';
import AppointmentConfirmed from 'pages/PatientAppointment/AppointmentConfirmed/AppointmentConfirmed';
import AppointmentRequest from 'pages/PatientAppointment/AppointmentRequest/AppointmentRequest';
import AppointmentRequestSent from 'pages/PatientAppointment/AppointmentRequestSent/AppointmentRequestSent';

const PracticeClientRoutes = ({ match }: any) => {
  const initPath = match && match.path && match.path !== '/' ? match.path : '';
  return (
    <Routes>
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/welcome`,
        component: WelcomePage,
        layout: SignUpWrapper
      })}
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/task`,
        component: WelcomeTaskList,
        layout: SignUpWrapper
      })}
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/policy`,
        component: PolicyPage,
        layout: SignUpWrapper
      })}
      {appRoute({ path: `${initPath}/:clinicianSlugUrlOrId/signup/new`, component: SignUpForm, layout: SignUpWrapper })}
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/success`,
        component: SuccessSignUpPage,
        layout: SignUpWrapper
      })}
      <Route path={`${initPath}/:slugUrl`} element={<PracticeLandingPage />} />
      <Route path={`${initPath}/:slugUrl/referral`} element={<ReferralPage />} />
      <Route path={`${initPath}/:slugUrl/availability`} element={<PracticeAvailability />} />
      <Route path={`${initPath}/:slugUrl/appointment/mode`} element={<AppointmentBookingType />} />
      <Route path={`${initPath}/:slugUrl/appointment/contact`} element={<AppointmentBookingNewCustomer />} />
      <Route path={`${initPath}/:slugUrl/appointment/confirmed`} element={<AppointmentConfirmed />} />
      <Route path={`${initPath}/:slugUrl/appointment/request`} element={<AppointmentRequest />} />
      <Route path={`${initPath}/:slugUrl/appointment/request/sent`} element={<AppointmentRequestSent />} />
    </Routes>
  );
};

export default PracticeClientRoutes;
