import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import RechargeRoutes from './RechargeRoutes';
import appRoute from '../../components/AppRoute/AppRoute';
import RechargeLoginWrapper from 'Recharge/components/RechargeLoginWrapper/RechargeLoginWrapper';

const RechargeRoutesCollection = () => (
  <Routes>
    {appRoute({ path: '/facilitated/*', component: FacilitatedRoutes, layout: RechargeLoginWrapper })}
    <Route path={'/*'} element={<RechargeRoutes />} />
  </Routes>
);

export default RechargeRoutesCollection;
