import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './ClinicianDetails.module.scss';
import { useFetchClinicianDetails } from './hooks/getClinicianDetails';
import ClinicianTimeSlot from './components/ClinicianTimeSlot/ClinicianTimeSlot';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { Modal } from 'antd';
import JoinWaitlistForm from 'CaW/components/JoinWaitlistForm/JoinWaitlistForm';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWFooter from '../../components/CaWFooter/CaWFooter';
import CaWHeader from '../../components/CaWHeader/CaWHeader';

interface PsychologistQueryParam {
  timeSlotSection?: boolean;
  emp_code?: string;
}

const ClinicianDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();

  const { emp_code, timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchClinicianDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('CaWTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return isPractitionerDetailsLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <CaWHelmetWrapper title={`Caraniche at Work - ${practitionerName} Profile`}>
      <CaWHeader withMenu blueMenu classNames={styles.header} />
      <div className={styles.content}>
        <ClinicianTimeSlot
          helmDetails={practitionerDetails}
          presetEmployerCode={emp_code}
          onShowWaitlistForm={() => setShowWaitlistForm(true)}
        />
      </div>
      <CaWFooter />
      {/* Join waitlist modal */}
      <Modal
        width={700}
        visible={showWaitlistForm}
        onCancel={() => {
          setShowWaitlistForm(false);
        }}
        bodyStyle={{ padding: '40px 80px', top: 50 }}
        footer={null}
      >
        <JoinWaitlistForm
          onComplete={() => {
            setShowWaitlistForm(false);
          }}
          practitionerDetails={practitionerDetails}
        />
      </Modal>
    </CaWHelmetWrapper>
  );
};

export default ClinicianDetails;
