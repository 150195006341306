import { useAuth0 } from '@auth0/auth0-react';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';

import ClientHeader from 'components/ClientHeader/ClientHeader';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import styles from './SurveyQuestionWrapper.module.scss';

const SurveyQuestionWrapper = () => {
  const { user } = useAuth0();

  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );

  return (
    <PatientContentLayout
      className={styles.container}
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : {}
      }
    >
      <div className={styles.contentWrapper}>
        <ClientHeader withMenu />
        <SurveyQuestion />
      </div>
    </PatientContentLayout>
  );
};

export default SurveyQuestionWrapper;
