import styles from './SignUpCompleteBooking.module.scss';
import classNames from 'classnames';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import { useContext } from 'react';
import { UserContext } from 'utils/UserContextProvider';
import { useFetchPractitionerDetails } from '../PsychologistDetails/hooks/getPsychologistDetails';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const SignUpCompleteBooking = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { clientProfile } = useContext(UserContext);

  const { AVATAR, HOME } = useSelectRoutesGenerator();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];
  const { clinicianId, psychologistName } = useReserveAppointmentData();
  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();

  const handleGoToAccount = () => {
    removeReserveAppointmentData();

    const clientPortalPath = clientProfile?.avatar ? HOME : AVATAR;

    // this redirects from selectpsychology.co.uk to my.selectpsychology.co.uk
    // uncomment if engage flow should be hosted on selectpsychology.co.uk, but
    // client-ui is on our own domain
    if (!isDevelopmentENV()) {
      window.location.href = `${
        process.env.REACT_APP_CLIENT_DOMAIN_SELECT || 'https://my.selectpsychology.co.uk'
      }${clientPortalPath}`;
    } else {
      navigate(clientPortalPath);
    }
  };

  return (
    <SelectHelmetWrapper title={'Select Psychology | Welcome'}>
      <div className={classNames(styles.container, 'select-theme')}>
        <SelectHeader withPadding withMenu />
        {isPractitionerDetailsLoading ? (
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
        ) : (
          <SelectContentLayout>
            <div className={styles.content}>
              {practitionerDetails?.avatar && (
                <div className={styles.avatar}>
                  <img src={practitionerDetails?.avatar} alt={'Avatar'} />
                </div>
              )}
              <div className={styles.title}>Welcome to Select Psychology</div>
              <div className={styles.welcome}>
                <div>
                  <div className={styles.messageHeading}>Hello {user?.name}</div>
                  <div className={styles.message}>
                    {practitionerDetails?.helmControl?.welcomeNote ? (
                      practitionerDetails?.helmControl.welcomeNote
                    ) : (
                      <>
                        Thank you for choosing to book your first appointment with me. In order to help you get the most
                        out of our time together it would be really helpful for me to learn a little more about what has
                        bought you to therapy and what you are hoping to get out of our sessions together. To this end,
                        could you please complete the form below prior to our first session.
                      </>
                    )}
                  </div>
                  <div className={styles.messageEnding}>
                    I look forward to meeting you. Warm Regards,
                    <br />
                    {practitionerDetails?.name || psychologistName}
                  </div>
                </div>
              </div>

              <div className={styles.whatNext}>
                {!isAuthenticated && (
                  <>
                    <div className={styles.heading}>Preparation for our 1st appointment</div>
                    <div className={styles.tasks}>
                      <ul>
                        <li>Pre Consultation info</li>
                      </ul>
                    </div>
                  </>
                )}
                <ButtonSelect onClick={handleGoToAccount} className={styles.goToMyAccountBtn}>
                  Go to your account
                </ButtonSelect>
              </div>
            </div>
          </SelectContentLayout>
        )}
        <TacklitFooter darkFooter />
      </div>
    </SelectHelmetWrapper>
  );
};

export default SignUpCompleteBooking;
