import { notification } from 'antd';
import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOnboardingAssessment } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useFetchFacilitatedOA2 = (
  token: string,
  setViewState: Dispatch<
    SetStateAction<'done' | 'saveAndExit' | 'welcomeBack' | 'questions' | 'background' | 'welcomeSurvey'>
  >,
  clientRecordId: string
) => {
  const navigate = useNavigate();
  const [facilitatedOADetails, setFacilitatedOADetails] = useState<FirstAssessment | undefined>(undefined);
  const [surveyNote, setSurveyNote] = useState<string>('');
  const [isFacilitatedOALoading, setIsFacilitatedOALoading] = useState(true);

  const fetchFirstAssessment = async () => {
    setIsFacilitatedOALoading(true);

    try {
      const response = await getOnboardingAssessment(token, clientRecordId);

      if (response.statusCode === 204) {
        setViewState('done');
      } else {
        const onboardingAssessment = await response.json();

        const bgRes = onboardingAssessment.questionsResponses.assessment.onboardingQuestions.backgroundResponse;

        let isHaveBgQuestion = false;

        for (const key in bgRes) {
          if (Object.prototype.hasOwnProperty.call(bgRes, key)) {
            if (bgRes[key].isShow) {
              isHaveBgQuestion = true;
            }

            bgRes[key] =
              key !== 'status'
                ? key === 'therapist'
                  ? {
                      ...bgRes[key],
                      ...(!bgRes[key].code && { code: 'no', similarExperience: 'no' })
                    }
                  : key === 'summary'
                  ? { ...bgRes[key] }
                  : { ...bgRes[key], ...(!bgRes[key].code && { code: 'no' }) }
                : bgRes[key];
          }
        }

        onboardingAssessment.questionsResponses.assessment.onboardingQuestions.backgroundResponse = bgRes;

        const callOAData = onboardingAssessment.questionsResponses;

        setSurveyNote(onboardingAssessment?.survey?.note);

        if (bgRes?.status === 'done' || !isHaveBgQuestion) {
          const isHaveQuestion = callOAData.assessment.onboardingQuestions.assessmentField.find((obj: any) => {
            return obj.sections && obj.sections.find((sec: any) => sec.questions.length > 0);
          });
          if (isHaveQuestion) {
            setViewState('welcomeBack');
          } else {
            setViewState('done');
          }
        }

        if (callOAData && (callOAData.status === 'done' || callOAData.status === 'none')) {
          setViewState('done');
        }

        setFacilitatedOADetails(callOAData);
      }
    } catch (ex) {
      console.error(ex);

      notification.error({
        message: 'Fail to get facilitated onboarding assessments',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        navigate('/404');
        return;
      }
      setViewState('done');
    }

    setIsFacilitatedOALoading(false);
  };

  useEffect(() => {
    if (clientRecordId) {
      fetchFirstAssessment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRecordId]);

  return { facilitatedOADetails, surveyNote, isFacilitatedOALoading, setFacilitatedOADetails };
};
