import styles from './PractitionerAvailabilityCalendar.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import CalendarBox from 'helm/components/CalendarBox/CalendarBox';
import { Link } from 'react-router-dom';
import { InitialSessionType } from 'helm/interfaces/Filter/searchFilter';
import { sessionList } from 'helm/components/HelmHeader/HelmFilterHeader/HelmFilterHeader';
import AvailabilityPill from 'helm/components/AvailabilityPill/AvailabilityPill';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

interface PractitionerAvailabilityCalendarProps {
  practitionerDetails?: PractitionerDetailsInterface;
  nextAvailabilityDetails: PractitionerDetailsInterface['nextAvailabilities'];
  detailPath: string;
  onShowWaitlistForm?: () => void;
  selectedSessionType?: InitialSessionType;
  isDarkYellowPill: boolean;
  noFilter?: boolean;
}

const todayDate = moment();

const PractitionerAvailabilityCalendar = ({
  practitionerDetails,
  nextAvailabilityDetails,
  detailPath,
  selectedSessionType,
  onShowWaitlistForm,
  isDarkYellowPill,
  noFilter
}: PractitionerAvailabilityCalendarProps) => {
  const { isAuthenticated } = useAuth0();
  const selectedAppointmentTypeName =
    (selectedSessionType &&
      sessionList.find((obj) => encodeURIComponent(obj.id) === encodeURIComponent(selectedSessionType))?.label) ||
    sessionList[0].label;

  const massageAvailabilityData = noFilter
    ? nextAvailabilityDetails
    : nextAvailabilityDetails?.filter(
        (obj) =>
          encodeURIComponent(obj.appointmentTypeName?.toLowerCase()) ===
          encodeURIComponent(selectedAppointmentTypeName.toLowerCase())
      );

  const isNewClientHasNoSlots =
    !isAuthenticated &&
    practitionerDetails?.helmControl?.shouldUseCaseLoad &&
    (practitionerDetails?.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div className={styles.container}>
      <div className={styles.label}>NEXT AVAILABLE</div>
      <div className={styles.calendarListWrapper}>
        <div className={styles.calendarContent}>
          {isNewClientHasNoSlots ? (
            <div className={isDarkYellowPill ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
              <div className={styles.noAvailableCard}>
                <div className={styles.messageWrapper}>
                  <div className={styles.redDotWrapper}>
                    <div className={styles.redDot} />
                  </div>
                  <div className={styles.message}>
                    <div className={styles.desc}>Sorry...</div>
                    <div className={styles.noAvailDesc}>
                      I am not available to work with new clients at this time. <br />
                      If you'd like to join my waitlist should a spot open up please add your details here
                    </div>
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  <ButtonHelm
                    icon={'post_add'}
                    iconPostFix
                    variant={'outlined'}
                    onClick={onShowWaitlistForm}
                  >{`Join ${practitionerDetails.name} Waitlist`}</ButtonHelm>
                </div>
              </div>
            </div>
          ) : massageAvailabilityData && massageAvailabilityData.length > 0 ? (
            <>
              {massageAvailabilityData?.map((nextAvailabilityObj, index) => (
                <div className={styles.calendarCardWrapper} key={index}>
                  {nextAvailabilityObj.availabilities.slice(0, 3).map((availabilitiesObj, index) => {
                    const availabilityDate = moment(availabilitiesObj.date);
                    const dayDifference = Math.floor(availabilityDate.diff(todayDate, 'days', true)) + 1;
                    const days = moment(availabilitiesObj.date).format('dddd');

                    const massageAvailabilityTimeSlot =
                      dayDifference <= 0
                        ? availabilitiesObj.timeSlots.filter((obj) =>
                            moment(obj.startTime, 'hh:mma').isAfter(todayDate)
                          )
                        : availabilitiesObj.timeSlots;

                    const dayLabel = dayDifference <= 0 ? 'Today' : dayDifference === 1 ? 'Tomorrow' : days;
                    return (
                      massageAvailabilityTimeSlot.length > 0 && (
                        <div className={styles.calendarCard} key={index}>
                          <div className={styles.cardContent}>
                            <CalendarBox
                              date={availabilitiesObj.date}
                              label={dayLabel}
                              desc={dayDifference > 1 ? `in ${dayDifference} days` : ''}
                              center
                            />
                            <div className={styles.timeslotWrapper}>
                              {massageAvailabilityTimeSlot.map((timeSlotObj, index) => {
                                const startTime = moment(timeSlotObj.startTime, 'hh:mmA').format('hh:mm');
                                const endTime = moment(timeSlotObj.endTime, 'hh:mmA').format('hh:mma');
                                const selectedDateTime = `selectedDateTime=${availabilitiesObj.date},${timeSlotObj.startTime},${timeSlotObj.endTime}`;
                                const newDetailPath = `${detailPath}?${selectedDateTime}`;

                                return index <= 2 ? (
                                  <Link to={newDetailPath} key={index}>
                                    <AvailabilityPill classNames={styles.timeslot} darkYellowPill={isDarkYellowPill}>
                                      {startTime} to {endTime}
                                    </AvailabilityPill>
                                  </Link>
                                ) : index === 3 ? (
                                  <Link to={detailPath} key={index} className={styles.seeMoreSlot}>
                                    See {availabilitiesObj.timeSlots.length - 3} More Availability
                                  </Link>
                                ) : null;
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              ))}
            </>
          ) : (
            <div className={styles.noAvailableWrapper}>
              <div className={styles.noAvailableCard}>
                <div className={styles.calendarBadge}>
                  <i className={`material-icons ${styles.closeIcon}`}>close</i>
                </div>
                <div className={styles.desc}>Sorry...</div>
              </div>
              <div className={styles.noAvailDesc}>
                My time for <strong>next 4 weeks</strong> is fully booked.
              </div>
            </div>
          )}
          {!isNewClientHasNoSlots && (
            <Link to={detailPath} className={styles.nextAvailabilityCard}>
              <div className={styles.nextAvailabilityLabel}>
                <div>SEE ALL</div>
                <div>AVAILABILITY</div>
              </div>
              <i className={`material-icons ${styles.nextIcon}`}>arrow_forward</i>
            </Link>
          )}
        </div>
        <Link to={detailPath} className={styles.mobileNextAvailabilityCard}>
          <div className={styles.nextAvailabilityLabel}>
            <div>SEE ALL AVAILABILITY</div>
          </div>
          <i className={`material-icons ${styles.nextIcon}`}>arrow_forward</i>
        </Link>
      </div>
    </div>
  );
};

export default PractitionerAvailabilityCalendar;
