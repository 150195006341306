import styles from './ProgramCard.module.scss';
import { ChapterInterface, ProgramAppointment } from 'helm/interfaces/program/program';
import moment from 'moment';
import { checkIsTodayOrYesterday } from '../../../../constants';
import HowToConnect from '../HowToConnect/HowToConnect';
import classnames from 'classnames';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { useMemo } from 'react';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

interface ProgramCardProps {
  id: string;
  chapterData: ChapterInterface;
  activeCard: boolean;
}

const generateDesc = (appointmentData: ProgramAppointment, completed: boolean, chapterId?: number) => {
  return completed
    ? `Completed via
          ${
            appointmentData.deliveryType &&
            getDeliveryTypeLabel(appointmentData.deliveryType, appointmentData.otherInstructions)
          } on ${appointmentData.date && checkIsTodayOrYesterday(appointmentData.date)}.`
    : `${chapterId ? `Session ${chapterId},` : ''} ${appointmentData.duration} minutes,
          ${
            appointmentData.deliveryType &&
            getDeliveryTypeLabel(appointmentData.deliveryType, appointmentData.otherInstructions)
          } booked
          for ${appointmentData.date && checkIsTodayOrYesterday(appointmentData.date)}
          ${moment(appointmentData.startTime, 'hh:mm').format('hh:mmA')}.`;
};

const ProgramCard = ({ id, chapterData, activeCard }: ProgramCardProps) => {
  const appointmentDescription = useMemo(
    () =>
      chapterData.appointment &&
      generateDesc(chapterData.appointment, chapterData.completed, activeCard ? chapterData.chapterId : undefined),
    [activeCard, chapterData.appointment, chapterData.chapterId, chapterData.completed]
  );

  return (
    <div
      className={classnames(styles.container, activeCard && styles.active, chapterData.completed && styles.completed)}
      data-chapter={chapterData.chapterId}
    >
      <img className={styles.image} alt={chapterData.name} src={chapterData.image} />
      <div className={styles.programWrapper}>
        <div>
          <div className={styles.title}>{activeCard ? 'NEXT SESSION' : `SESSION ${id}`}</div>
          {appointmentDescription && <div className={styles.sessionDesc}>{appointmentDescription}</div>}
        </div>
        <div>
          <div className={styles.chapterTitle}>{chapterData.name}</div>
          <div className={styles.chapterDesc}>{chapterData.description}</div>
        </div>
      </div>
      {chapterData.completed ? (
        <div className={styles.connectWrapper}>
          <div className={styles.moduleButtonWrapper}>
            <ButtonHelm
              variant={'outlined'}
              onClick={() => (window.location.href = chapterData.link || '')}
              className={styles.moduleButton}
            >
              Revisit this content
            </ButtonHelm>
          </div>
        </div>
      ) : (
        <>
          {chapterData.link && (
            <div className={styles.connectWrapper}>
              {chapterData.appointment && <HowToConnect appointmentData={chapterData.appointment} />}
              <div className={styles.moduleButtonWrapper}>
                <ButtonHelm
                  onClick={() => (window.location.href = chapterData.link || '')}
                  className={styles.moduleButton}
                >
                  Start this module
                </ButtonHelm>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProgramCard;
