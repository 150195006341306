import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import styles from './CordsHome.module.scss';

const CordsHome = () => {
  return (
    <CORDSContentWrapper title={'CORDS - Home'} fullwidth contentClassName={styles.container}>
      <div className={styles.content}>
        CORDS Landing Page.
      </div>
    </CORDSContentWrapper>
  );
};

export default CordsHome;
