import TacklitLayout from 'components/TacklitLayout/TacklitLayout';
import SMPListPage from 'pages/SMP/pages/SMPListPage/SMPListPage';
import SMPNewPage from 'pages/SMP/pages/SMPNewPage/SMPNewPage';
import SMPPreviewPage from 'pages/SMP/pages/SMPPreviewPage/SMPPreviewPage';
import { Route, Routes } from 'react-router-dom';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import appRoute from '../components/AppRoute/AppRoute';
import SMPDetailsPage from '../pages/SMP/pages/SMPDetailsPage/SMPDetailsPage';
import { UserContextProvider } from '../utils/UserContextProvider';

const isDevEnv = isDevelopmentENV();

const SMPRoutes = () => (
  <UserContextProvider>
    <Routes>
      {appRoute({ path: '/', component: SMPListPage, layout: TacklitLayout })}
      {appRoute({ path: '/new', component: SMPNewPage, layout: TacklitLayout })}
      {isDevEnv && <Route path={'/preview/:smpId'} element={<SMPPreviewPage />} />}
      {appRoute({ path: '/:smpId', component: SMPDetailsPage, layout: TacklitLayout })}
    </Routes>
  </UserContextProvider>
);

export default SMPRoutes;
