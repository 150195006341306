import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './PsychologistLanding.module.scss';
import SomeoneHealthContentLayout from '../../components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from '../../components/SomeoneHealthHeader/SomeoneHealthHeader';
import LandingFilter from '../../components/SomeoneHealthFilter/LandingFilter/LandingFilter';
import RecommendPsychologist from './components/RecommendPsychologist/RecommendPsychologist';
import SomeoneHealthHowItWorks from 'SomeoneHealth/components/SomeoneHealthHowItWorks/SomeoneHealthHowItWorks';
import SomeoneHealthFooter from 'SomeoneHealth/components/SomeoneHealthFooter/SomeoneHealthFooter';
import SomeoneHealthFindRightFit from 'SomeoneHealth/components/SomeoneHealthFindRightFit/SomeoneHealthFindRightFit';
import SomeoneHealthMeetTeam from 'SomeoneHealth/components/SomeoneHealthMeetTeam/SomeoneHealthMeetTeam';

const PsychologistLanding = () => {
  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Talk to someone about your mental health today'} hubspot>
      <div className={styles.headerContainer}>
        <SomeoneHealthHeader withPadding whiteFont classNames={styles.header} />
        <SomeoneHealthContentLayout className={styles.homePageContainer}>
          <div className={styles.heroTextWrapper}>
            <div className={styles.heroText}>Talk to someone about your mental health today</div>
          </div>
          <LandingFilter />
        </SomeoneHealthContentLayout>
      </div>
      <RecommendPsychologist />
      <SomeoneHealthContentLayout className={styles.landingContent}>
        <SomeoneHealthHowItWorks />
        <SomeoneHealthMeetTeam />
        <SomeoneHealthFindRightFit />
      </SomeoneHealthContentLayout>
      <SomeoneHealthFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default PsychologistLanding;
