import { useState } from 'react';

import FilterDropdown, { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './FitFilter.module.scss';
import {
  FIT_FUNDING_LIST_LABELS,
  FIT_DAYS_LIST_LABELS,
  FIT_DELIVERY_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FitFundingList,
  FitDaysList,
  FitDeliveryList,
  FitTimesList
} from 'helm/interfaces/Filter/fitFilter';
import TimeZoneDropdown from 'components/TimeZoneDropdown/TimeZoneDropdown';
import FitFilterMobilePopup, { FitFilterInterface } from './components/FitFilterMobilePopup/FitFilterMobilePopup';
import HelmContentLayout from '../../../components/HelmContentLayout/HelmContentLayout';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

export interface PractitionerFitFilter {
  funding?: FitFundingList;
  day?: FitDaysList;
  time?: FitTimesList;
  deliveryMode?: FitDeliveryList;
}

const generateDaysList = () => {
  return Object.keys(FitDaysList).map((listObj) => ({
    id: listObj,
    label: FIT_DAYS_LIST_LABELS[listObj as keyof typeof FitDaysList]
  }));
};

const generateTimesList = () => {
  return Object.keys(FitTimesList).map((listObj) => ({
    id: listObj,
    label: FIT_TIMES_LIST_LABELS[listObj as keyof typeof FitTimesList]
  }));
};

const generateDeliveryList = () => {
  return Object.keys(FitDeliveryList).map((listObj) => ({
    id: listObj,
    label: FIT_DELIVERY_LIST_LABELS[listObj as keyof typeof FitDeliveryList]
  }));
};

const generateFundingList = () => {
  return Object.keys(FitFundingList).map((listObj) => ({
    id: listObj,
    label: FIT_FUNDING_LIST_LABELS[listObj as keyof typeof FitFundingList]
  }));
};

interface FitFilterProps {
  selectedFitValue?: PractitionerFitFilter;
  onChangeFitValue: (value: PractitionerFitFilter) => void;
  selectedTimezone: string;
  handleChangeTimeZone: (value: string) => void;
  handleFitResetValue: () => void;
}

const FitFilter = ({
  selectedFitValue,
  onChangeFitValue,
  selectedTimezone,
  handleChangeTimeZone,
  handleFitResetValue
}: FitFilterProps) => {
  const [isFilterModalShow, setIsFilterModalShow] = useState(false);
  const [filterIsSelected, setFilterIsSelected] = useState<string>('');

  const generateList = (id?: any, listLabel?: any, listEnum?: any) => {
    return id
      ? {
          id,
          label: listLabel[id as keyof typeof listEnum]
        }
      : undefined;
  };

  const massageFitDays = generateList(selectedFitValue?.day, FIT_DAYS_LIST_LABELS, FitDaysList);
  const massageFitTimes = generateList(selectedFitValue?.time, FIT_TIMES_LIST_LABELS, FitTimesList);
  const massageFitDeliveryMode = generateList(
    selectedFitValue?.deliveryMode,
    FIT_DELIVERY_LIST_LABELS,
    FitDeliveryList
  );
  const massageFitFunding = generateList(selectedFitValue?.funding, FIT_FUNDING_LIST_LABELS, FitFundingList);
  const [scrollCount, setScrollCount] = useState<number>(0);

  const handleChangeDays = (value: listInterface | undefined) => {
    const massageFitCollection: PractitionerFitFilter = {
      ...selectedFitValue,
      day: value?.id as FitDaysList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeTimes = (value: listInterface | undefined) => {
    const massageFitCollection: PractitionerFitFilter = {
      ...selectedFitValue,
      time: value?.id as FitTimesList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDeliveryMode = (value: listInterface | undefined) => {
    const massageFitCollection: PractitionerFitFilter = {
      ...selectedFitValue,
      deliveryMode: value?.id as FitDeliveryList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeFunding = (value: listInterface | undefined) => {
    const massageFitCollection: PractitionerFitFilter = {
      ...selectedFitValue,
      funding: value?.id as FitFundingList
    };
    onChangeFitValue(massageFitCollection);
  };

  const onFilterClickHandle = (filterName: string) => {
    setFilterIsSelected(filterName);
    setIsFilterModalShow(true);
  };

  const selectedFilters: FitFilterInterface = {
    day: massageFitDays,
    time: massageFitTimes,
    delivery: massageFitDeliveryMode,
    funding: massageFitFunding
  };

  return (
    <div className={styles.container}>
      <HelmContentLayout>
        <div className={styles.content}>
          <div className={styles.filterWrapper} onScroll={() => setScrollCount(scrollCount + 1)}>
            <div className={styles.labelWrapper}>
              <div className={styles.title}>REFINE YOUR FIT</div>
              <div className={styles.desc}>Filter specialists by...</div>
            </div>
            <div className={styles.fitFilterWrapper}>
              <FilterDropdown
                label={'Funding'}
                noFloatLabel
                badgeStyles
                listData={generateFundingList()}
                onChangeValue={handleChangeFunding}
                selectedValue={massageFitFunding}
                className={styles.style}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('funding')}
              />
              <FilterDropdown
                label={'Days'}
                noFloatLabel
                badgeStyles
                listData={generateDaysList()}
                onChangeValue={handleChangeDays}
                selectedValue={massageFitDays}
                className={styles.days}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('day')}
              />
              <FilterDropdown
                label={'Times'}
                noFloatLabel
                badgeStyles
                listData={generateTimesList()}
                onChangeValue={handleChangeTimes}
                selectedValue={massageFitTimes}
                className={styles.times}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('time')}
              />
              <FilterDropdown
                label={'Delivery'}
                noFloatLabel
                badgeStyles
                listData={generateDeliveryList()}
                onChangeValue={handleChangeDeliveryMode}
                selectedValue={massageFitDeliveryMode}
                className={styles.deliveryMode}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('delivery')}
              />
              <div className={styles.fitReset} onClick={handleFitResetValue}>
                Clear All
              </div>
            </div>
          </div>
          <div className={styles.timezone}>
            <TimeZoneDropdown
              listData={AU_TIME_ZONE_LIST}
              selectedValue={AU_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimezone) || AU_TIME_ZONE_LIST[0]}
              onChangeValue={(value) => handleChangeTimeZone(value.id)}
            />
          </div>
        </div>
        <FitFilterMobilePopup
          visible={isFilterModalShow}
          filterIsSelected={filterIsSelected}
          selectedFiltersProp={selectedFilters}
          dayOptions={generateDaysList()}
          timeOptions={generateTimesList()}
          deliveryOptions={generateDeliveryList()}
          styleOptions={generateFundingList()}
          onView={onChangeFitValue}
          onClearAll={handleFitResetValue}
          onCancel={() => setIsFilterModalShow(false)}
        />
      </HelmContentLayout>
    </div>
  );
};

export default FitFilter;
