import TaskButton from 'SomeoneHealth/components/TaskButton/TaskButton';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

import styles from './SuggestionBox.module.scss';

interface SuggestionBoxProps {
  bookNewAppointmentUrl: string;
}

const SuggestionBox = ({ bookNewAppointmentUrl }: SuggestionBoxProps) => {
  const { UPDATE_PROFILE, LOGIN } = useSomeoneHealthRoutesGenerator();
  const { SomeoneHealthFindGPURL } = someoneHealthEnvironment();

  const menuItems = [
    {
      name: 'Book a new psychology appointment',
      url: bookNewAppointmentUrl,
      isExternal: true
    },
    {
      name: 'Speak to a GP',
      url: SomeoneHealthFindGPURL,
      isExternal: true
    },
    { name: 'View my appointments & invoices', url: LOGIN.APPOINTMENT_OVERVIEW, isExternal: false },
    { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false }
  ];

  return (
    <>
      <div className={styles.actionWrapper}>
        <div className={styles.title}>Would you like to...</div>
        <div className={styles.menuItemWrapper}>
          {menuItems.slice(0, 2).map((item, index) => (
            <TaskButton key={index} item={item} />
          ))}
        </div>
      </div>
      <div className={styles.actionWrapper}>
        <div className={styles.title}>Or...</div>
        <div className={styles.menuItemWrapper}>
          {menuItems.slice(2, 4).map((item, index) => (
            <TaskButton key={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SuggestionBox;
