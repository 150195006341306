import styles from './IP6HeaderContent.module.scss';
import Button from 'components/v2/Button/Button';

interface IP6HeaderContentProps {
  onClickToNextActivity: () => void;
}

const IP6HeaderContent = ({ onClickToNextActivity }: IP6HeaderContentProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Welcome to the Power to Change program</div>
      <div className={styles.desc}>
        The Power to Change program is designed to support people to make changes to their substance use, whether it be
        alcohol or other drugs. This 6-session program focuses on strategies and skills to support behaviour change.
        Session content will become available as you move through the program.
      </div>
      <div className={styles.desc} />
      <div className={styles.desc}>For each session:</div>
      <div className={styles.desc}>
        <ol>
          <li>
            Before your scheduled appointment take a look at the pre-session activity. You can do this by clicking the
            “explore this activity” button on the pre-session activity tile. Each activity takes about 5-10 minutes to
            complete, and you can do this any time before your next scheduled session.
          </li>
          <li>
            At your scheduled appointment time, click the “start” button on the session tile to access the content and
            activities for that session. Session content becomes available 60 minutes before your appointment time.
          </li>
        </ol>
      </div>
      <div className={styles.desc}>
        If you're just getting started, don't forget to do your pre-session activity before your first appointment.
      </div>
      <div className={styles.desc} />
      <div className={styles.desc}>You can click on the button below to get to your next activity.</div>
      <div className={styles.buttonWrapper}>
        <Button onClick={onClickToNextActivity} className={styles.button}>
          JUMP TO YOUR NEXT ACTIVITY
        </Button>
      </div>
    </div>
  );
};

export default IP6HeaderContent;
