import styles from './CORDSDoneCard.module.scss';

interface CORDSDoneCardProps {
  title: string;
  desc?: string;
}

const CORDSDoneCard = ({ title, desc }: CORDSDoneCardProps) => {
  return (
    <div className={styles.container}>
      <svg
        width="280"
        height="280"
        viewBox="0 0 280 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.checkIcon}
      >
        <circle opacity="0.1" cx="140" cy="140" r="140" fill="#FFF7E5" />
        <circle opacity="0.2" cx="140" cy="140" r="120" fill="#FFF7E5" />
        <circle opacity="0.3" cx="140" cy="140" r="100" fill="#FFF7E5" />
        <circle opacity="0.5" cx="140" cy="140" r="80" fill="#FFF7E5" />
        <circle opacity="0.8" cx="140" cy="140" r="60" fill="#FFF7E5" />
        <path
          d="M140 90C112.4 90 90 112.4 90 140C90 167.6 112.4 190 140 190C167.6 190 190 167.6 190 140C190 112.4 167.6 90 140 90ZM140 180C117.95 180 100 162.05 100 140C100 117.95 117.95 100 140 100C162.05 100 180 117.95 180 140C180 162.05 162.05 180 140 180ZM162.95 117.9L130 150.85L117.05 137.95L110 145L130 165L170 125L162.95 117.9Z"
          fill="#1B365D"
        />
      </svg>
      <div className={styles.thankYouBigText}>{title}</div>
      {desc && <div className={styles.thankYouText}>{desc}</div>}
    </div>
  );
};

export default CORDSDoneCard;
