import { useState, useEffect } from 'react';
import { getClientCompletedCheckIn } from '../../../../utils/http/checkIn';

export const useFetchCompletedCheckInStatus = (token: string, clinicianId: string) => {
  const [completedCheckInStatus, setCompletedCheckInStatus] = useState<boolean>(false);
  const [isCompletedCheckInStatusLoading, setIsCompletedCheckInStatusLoading] = useState(false);

  const fetchCompletedCheckInStatus = async (token: string) => {
    if (clinicianId) {
      setIsCompletedCheckInStatusLoading(true);
      try {
        const { status } = await (await getClientCompletedCheckIn(token, clinicianId)).json();
        setCompletedCheckInStatus(status);
      } catch (ex) {
        console.error(ex);
      }
    }
    setIsCompletedCheckInStatusLoading(false);
  };

  useEffect(() => {
    if (token && clinicianId) {
      fetchCompletedCheckInStatus(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clinicianId]);

  return { completedCheckInStatus, isCompletedCheckInStatusLoading };
};
