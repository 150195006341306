import styles from './AppointmentsOverview.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import backgroundImg from 'helm/assets/images/background/HelmClientBg.jpg';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

const defaultTimeZone = 'Australia/Melbourne';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || defaultTimeZone;
  const timeZoneLocation = AU_TIME_ZONE_LIST.find(({ timezones }) => timezones.includes(timeZone))?.label;
  const { COUNSELLORS_CALENDAR } = useHelmRoutesGenerator();

  return (
    <HelmHelmetWrapper title={'HELM - Appointments'}>
      <PatientContentLayout
        className={styles.container}
        style={{
          backgroundImage: `url('${backgroundImg}')`,
          backgroundColor: 'transparent !important',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className={styles.contentWrapper}>
          <HelmHeader logoWithoutText whiteLogo whiteFont withMenu homeLink />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
              {timeZoneLocation && <div className={styles.timeZone}>Time in {timeZoneLocation}</div>}
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientProfileTimeZone={clientProfileData?.timeZone}
              newAppointmentPath={COUNSELLORS_CALENDAR.DETAILS}
            />
          </div>
        </div>
      </PatientContentLayout>
    </HelmHelmetWrapper>
  );
};

export default AppointmentsOverview;
