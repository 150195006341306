import CheckBox from 'components/CheckBox/CheckBox';

import styles from './PaymentPolicy.module.scss';

interface PaymentPolicyProps {
  policyAccepted: boolean;
  onSetPolicyAccepted: (value: boolean) => void;
}

const PaymentPolicy = ({ policyAccepted, onSetPolicyAccepted }: PaymentPolicyProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Cancellation Rules</div>
      <div className={styles.description}>
        When you schedule an appointment, you're reserving a dedicated slot for your session. Should you need to cancel
        within 48 hours of your appointment, please note that you'll be liable for the full payment.
        <br />
        <br />
        Additionally, if you're running more than 30 minutes late, we will need to reschedule your session, and you'll
        still be liable for the full cost.
      </div>
      <div className={styles.title}>Payment Terms</div>
      <div className={styles.description}>
        For any follow up appointments that you book a payment link will be emailed to you 2 days prior to your
        appointment. Payment is due by the day of the appointment.
        <br />
        <br />
        You can choose to pay by link or we will arrange for payment to be taken automatically the following working day
        using the card stored securely on our payment system.
        <br />
        <br />
        In the event that we are not able to take payment further attempts will be made over the next 72 hours, after
        which we will be in touch to request an alternative payment method.
      </div>
      <CheckBox
        id="policyAccepted"
        value={policyAccepted}
        onChange={(event) => onSetPolicyAccepted(event.target.checked)}
        label="I have read and agree to the cancellation policy and payment terms"
        labelClassName={styles.checkBoxLabel}
      />
    </div>
  );
};

export default PaymentPolicy;
