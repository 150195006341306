import { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface SMPContentWrapperProps {
  desc?: string;
  title?: string;
  children?: ReactNode;
}

const SMPLayoutWeb = ({ desc, title, children }: SMPContentWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={desc || 'SMP'} />
      </Helmet>

      {children}
    </HelmetProvider>
  );
};

export default SMPLayoutWeb;
