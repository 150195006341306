import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ResponsiveContainer, Area } from 'recharts';
import { Skeleton } from 'antd';
import moment from 'moment';

import ChartToolTip from '../ChartToolTip/ChartToolTip';

import styles from './InsightsLineChart.module.scss';
import { ClientBrand, CLIENT_BRAND } from 'interfaces/ClientBrand';
import { ChartData } from 'interfaces/MicroJournal/Chart';

interface InsightsLineChartProps {
  data: ChartData['insights'];
  yDomain: [number, number];
  yTicks: number[];
  isLoading: boolean;
  isSimplified?: boolean;
  width?: number;
  height?: number;
}

const color =
  CLIENT_BRAND === ClientBrand.HELM ? { stop: '#F9E048', stroke: '#F9E048' } : { stop: '#8884d8', stroke: '#3F52FF' };

const InsightsLineChart = ({
  data,
  yDomain,
  yTicks,
  isLoading,
  isSimplified,
  height,
  width
}: InsightsLineChartProps) => {
  const formatXAxis = (tickItem: any) => moment(tickItem).format('D MMM');

  return (
    <div className={styles.container} style={{ height: height ?? 300, width: width ?? '100%' }}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <ResponsiveContainer width="99%" height="100%">
          <AreaChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: -10 }}>
            <CartesianGrid strokeDasharray={'6 6'} stroke={'#D3D8DD'} vertical={false} />
            {isSimplified ? null : (
              <>
                <XAxis
                  dataKey="date"
                  tick={{ fill: '#9e9e9e', fontSize: '12' }}
                  tickFormatter={formatXAxis}
                  tickMargin={12}
                  stroke={'#fff'}
                />
                <YAxis tick={{ fill: '#9e9e9e', fontSize: '12' }} stroke={'#fff'} domain={yDomain} ticks={yTicks} />
              </>
            )}
            <ReferenceLine y={yDomain[0]} stroke="#61696c" />
            <Tooltip content={<ChartToolTip />} />
            <defs>
              <linearGradient id="areaChartStyle" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={color.stop} stopOpacity={0.4} />
                <stop offset="95%" stopColor={color.stop} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="uv"
              stroke={color.stroke}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#areaChartStyle)"
              dot={data.filter((d) => d.uv).length === 1}
              activeDot={{ stroke: color.stroke, strokeWidth: 3, r: 5, fill: '#fff' }}
              connectNulls
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default InsightsLineChart;
