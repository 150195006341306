import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';
import { SelectPracticeSlugUrl } from '../env/SelectEnv';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getSelectClinicianListing = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/${SelectPracticeSlugUrl}/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getSelectClinicianDetails = (clinicianId: string) =>
  httpClient('GET', url!, 200)(`/accounts/${SelectPracticeSlugUrl}/clinicians/${clinicianId}`) as Promise<BentResponse>;

export const getSelectPsychologistsMinified = (queryParam?: string) =>
  httpClient(
    'GET',
    url!,
    200
  )(`/accounts/${SelectPracticeSlugUrl}/clinicians/minified?${queryParam}`) as Promise<BentResponse>;

export const patchClientFundingStream = (token: string, body: Record<string, any>) =>
  httpClient('PATCH', url!, [204, 400], { Authorization: `Bearer ${token}` })(
    `/client-records/me/risk-screening`,
    body
  ) as Promise<BentResponse>;
