import { i18n } from 'i18next';
import { ClinicianAccountSettings } from 'interfaces/Clinician/clinicianDetails';
import { capitalize } from 'lodash';

const formatTerminology = (labels: Record<string, string>) => {
  const { client, clients, practitioner, practitioners } = labels;

  const terminology = {
    client,
    clients,
    practitioner,
    practitioners,
    'client.capitalize': capitalize(client),
    'clients.capitalize': capitalize(clients),
    'practitioner.capitalize': capitalize(practitioner),
    'practitioners.capitalize': capitalize(practitioners)
  };

  return terminology;
};

export const getTerminology = (accountSettings?: ClinicianAccountSettings) => {
  if (accountSettings && accountSettings.customLabels) {
    const { customLabels } = accountSettings;

    return formatTerminology(customLabels);
  }

  return formatTerminology({
    client: 'client',
    clients: 'clients',
    practitioner: 'practitioner',
    practitioners: 'practitioners'
  });
};

export const setResourceTerminology = (i18n: i18n, accountSettings?: ClinicianAccountSettings) => {
  i18n.addResourceBundle('en', 'terminology', getTerminology(accountSettings), true, true);
};
