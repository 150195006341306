import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import styles from './AddPayment.module.scss';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

interface AddPaymentProps {
  name: string;
  buttonStatus: ButtonStatusType;
  onAddPaymentDetails: () => void;
}

const AddPayment = ({ name, buttonStatus, onAddPaymentDetails }: AddPaymentProps) => {
  const { CancellationFee, AppointmentNoticePeriodInHour } = someoneHealthEnvironment();
  return (
    <div className={styles.container}>
      <div className={styles.title}>Add payment details</div>
      <div className={styles.content}>
        <b>Welcome {name}</b>
        <br />
        <br />
        On the next page you’ll be asked to save a payment method which will be encrypted and securely stored by Stripe,
        our payment processor.
        <br />
        <br />
        Your GP appointment is fully bulk-billed, so there won’t be any charges made to your payment method unless you
        don’t attend your appointment or cancel your appointment with less than {AppointmentNoticePeriodInHour} hours
        notice. In that case there is a ${CancellationFee} late cancellation or non-attendance fee.
        <div>
          <ButtonSH className={styles.button} onClick={onAddPaymentDetails} status={buttonStatus}>
            Securely save my payment details
          </ButtonSH>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;
