import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './PsychologistDetails.module.scss';
import { useFetchPractitionerDetails } from './hooks/getPsychologistDetails';
import PsychologistDetailsHeader from './components/PsychologistDetailsHeader/PsychologistDetailsHeader';
import PsychologistBonaFides from 'Recharge/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistSpecialisation from 'Recharge/pages/PsychologistListing/components/PsychologistSpecialisation/PsychologistSpecialisation';
import PsychologistQuote from './components/PsychologistQuote/PsychologistQuote';
import PsychologistTimeSlot from './components/PsychologistTimeSlot/PsychologistTimeSlot';
import PsychologistWelcomeNote from './components/PsychologistWelcomeNote/PsychologistWelcomeNote';
import PsychologistFAQ from './components/PsychologistFAQ/PsychologistFAQ';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PractitionerImageSlideshow from 'components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Recharge/interfaces/mentalHealth';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeFooter from 'Recharge/components/RechargeFooter/RechargeFooter';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { FitDeliveryList } from 'Recharge/interfaces/fitFilter';
import { Modal } from 'antd';
import JoinWaitlistForm from 'Recharge/components/JoinWaitlistForm/JoinWaitlistForm';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

export interface PsychologistQueryParam {
  timeSlotSection?: boolean;
}

const PsychologistDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('RechargeTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return (
    <RechargeHelmetWrapper title={`Recharge Wellness | ${practitionerName || 'Psychologist'} Profile`} hubspot>
      <div className="recharge-theme">
        {isPractitionerDetailsLoading ? (
          <div className={styles.loading}>
            <LoadingDot />
          </div>
        ) : (
          <>
            <PsychologistDetailsHeader practitionerDetails={practitionerDetails} listingLink={backToListingLink} />
            <div className={styles.container}>
              <RechargeContentLayout>
                <div className={styles.detailsContentWrapper}>
                  {/* Left content */}
                  <div className={styles.leftContent}>
                    <PsychologistWelcomeNote practitionerDetails={practitionerDetails} />

                    {/* Just show on mobile view */}
                    <div className={styles.appointmentBtnWrapper}>
                      <ButtonRecharge
                        onClick={() => scrollToView('RechargeTimeSlot')}
                        className={styles.appointmentBtn}
                      >
                        BOOK AN APPOINTMENT
                      </ButtonRecharge>
                    </div>
                  </div>

                  {/* Right content */}
                  <div className={styles.rightContent}>
                    {practitionerDetails.helmControl?.additionalPhotos?.length > 0 && (
                      <div className={styles.additionalPhoto}>
                        <div className={styles.additionalPhotoCard}>
                          <PractitionerImageSlideshow
                            classNames={styles.slideShow}
                            slidePhoto={practitionerDetails.helmControl.additionalPhotos}
                          />
                        </div>
                      </div>
                    )}
                    <div className={styles.detailsWrapper}>
                      {/* Just show on desktop view */}
                      <div className={styles.appointmentBtnWrapper}>
                        <ButtonRecharge
                          onClick={() => scrollToView('RechargeTimeSlot')}
                          className={styles.appointmentBtn}
                        >
                          BOOK AN APPOINTMENT
                        </ButtonRecharge>
                      </div>
                      {practitionerDetails.helmControl?.bonaFides && (
                        <PsychologistBonaFides bonaFidesDetails={practitionerDetails.helmControl.bonaFides} />
                      )}
                      {practitionerDetails.helmControl?.primarySpecialisations &&
                        practitionerDetails.helmControl.primarySpecialisations.length > 0 && (
                          <div>
                            <PsychologistSpecialisation
                              label={'MAIN AREAS OF PRACTICE'}
                              specialisationsDetails={practitionerDetails.helmControl.primarySpecialisations.map(
                                (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                              )}
                            />
                          </div>
                        )}
                      {practitionerDetails.helmControl?.secondarySpecialisations &&
                        practitionerDetails.helmControl.secondarySpecialisations.length > 0 && (
                          <div>
                            <PsychologistSpecialisation
                              label={'I CAN ALSO HELP WITH'}
                              specialisationsDetails={practitionerDetails.helmControl.secondarySpecialisations.map(
                                (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                              )}
                            />
                          </div>
                        )}
                      {!!practitionerDetails.helmControl?.deliveryModes?.length && (
                        <div className={styles.deliveryModeContainer}>
                          <div className={styles.deliveryModeLabel}>SERVICES DELIVERED VIA</div>
                          {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.video) && (
                            <div className={styles.deliveryModeItem}>
                              <i className={`material-icons ${styles.callIcon}`}>videocam</i>
                              <div className={styles.label}>Video Consults</div>
                            </div>
                          )}
                          {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
                            <div className={styles.deliveryModeItem}>
                              <i className={`material-icons ${styles.callIcon}`}>call</i>
                              <div className={styles.label}>Phone Consults</div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </RechargeContentLayout>
            </div>
            {practitionerDetails.helmControl?.quote && (
              <PsychologistQuote quote={practitionerDetails.helmControl.quote} />
            )}
            <PsychologistTimeSlot
              psychologistDetails={practitionerDetails}
              onShowWaitlistForm={() => setShowWaitlistForm(true)}
            />
            <PsychologistFAQ />
            <RechargeFooter />
          </>
        )}

        <Modal
          width={700}
          visible={showWaitlistForm}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          <JoinWaitlistForm
            onComplete={() => {
              setShowWaitlistForm(false);
            }}
            practitionerDetails={practitionerDetails}
          />
        </Modal>
      </div>
    </RechargeHelmetWrapper>
  );
};

export default PsychologistDetails;
