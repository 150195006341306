import { CookiesProvider } from 'react-cookie';
import { Route, Routes } from 'react-router-dom';
import BeforeWeBegin from '../pages/Bazaar/BeforeWeBegin/BeforeWeBegin';
import SelfAssessments from '../pages/Bazaar/SelfAssessments/SelfAssessments';

const BazaarRoutes = () => (
  <CookiesProvider>
    <Routes>
      <Route path={'/survey'} element={<BeforeWeBegin />} />
      <Route path={'/assessments'} element={<SelfAssessments />} />
      <Route path={'/assessments/:type'} element={<SelfAssessments />} />
    </Routes>
  </CookiesProvider>
);

export default BazaarRoutes;
