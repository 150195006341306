import { useEffect } from 'react';
import styles from './CreateProfile.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import CreateProfileForm from './components/CreateProfileForm/CreateProfileForm';
import CreateProfileRemainingTime from './components/CreateProfileRemainingTime/CreateProfileRemainingTime';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmSignUpFooter from 'helm/components/HelmFooter/HelmSignUpFooter/HelmSignUpFooter';
import { useNavigate } from 'react-router-dom';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { useFetchPractitionerDetails } from '../PractitionerDetails/hooks/getPractitionerDetails';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useFetchReservedAppointments } from 'helm/utils/hooks/appointment';
import { useAuth0 } from '@auth0/auth0-react';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { storeUserSession, UserSessionStorage } from './helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import { getHelmPractitionerDetailsPath } from 'helm/utils/Path/getHelmPractitionerDetailsPath';

const CreateProfile = () => {
  const { CHECKOUT, COUNSELLORS_CALENDAR, PRACTITIONER } = useHelmRoutesGenerator();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const { reserveId, clinicianId, sid, accountId } = useReserveAppointmentData();

  useEffect(() => {
    if (user?.['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
      window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
    }
  }, [user]);

  useEffect(() => {
    scrollToView('HelmHeader');
  }, []);

  const sidQueryParam = sid ? `?sid=${sid}` : '';

  if (!reserveId) {
    if (clinicianId) {
      const detailsPagePath = getHelmPractitionerDetailsPath({
        clinicianId,
        counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
        practitionerDetailsPath: PRACTITIONER.DETAILS
      });
      navigate(`${detailsPagePath}${sidQueryParam}`);
    } else {
      navigate(`${PRACTITIONER.LISTING}${sidQueryParam}`);
    }
  }

  if (isAuthenticated) {
    navigate(`${CHECKOUT}${sidQueryParam}`);
  }

  const proceedToCheckout = (value: UserSessionStorage) => {
    storeUserSession(value);
    scrollToView('HelmHeader');
    navigate(`${CHECKOUT}${sidQueryParam}`);
  };

  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);

  const { appointments } = useFetchReservedAppointments(reserveId!, accountId!);

  return (
    <HelmHelmetWrapper title={'HELM - Sign Up'}>
      <HelmHeader />
      <HelmContentLayout>
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <CreateProfileForm accountId={accountId || ''} onComplete={proceedToCheckout} />
          </div>
          <div className={styles.rightContent}>
            <CreateProfileRemainingTime
              practitionerAvatar={practitionerDetails.avatar}
              practitionerName={practitionerDetails.name}
              appointments={appointments}
              showSlotCard
            />
          </div>
        </div>
      </HelmContentLayout>
      <HelmSignUpFooter />
    </HelmHelmetWrapper>
  );
};

export default CreateProfile;
