import { BentResponse } from 'bent';

import { httpClient } from '../httpClient';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const getInvoice = (invoiceId: string) =>
  httpClient('GET', url!, 200)(`/invoice/${invoiceId}`) as Promise<BentResponse>;

export const getMarkInvoicePaid = (invoiceId: string) =>
  httpClient('GET', url!, 200)(`/invoice/${invoiceId}/mark-paid`) as Promise<BentResponse>;

export const getUnmarkInvoicePaid = (invoiceId: string) =>
  httpClient('GET', url!, 200)(`/invoice/${invoiceId}/unmark-paid`) as Promise<BentResponse>;
