export const helmEnvironment = () => ({
  HelmContactUsURL: 'https://takethehelm.com.au/contact',
  HelmFooterDrugURL: 'https://takethehelm.com.au/drugs/',
  HelmFooterAlcoholURL: 'https://takethehelm.com.au/alcohol/',
  HelmFooterGamblingURL: 'https://takethehelm.com.au/gambling/',
  HelmFooterEmotionsURL: 'https://takethehelm.com.au/emotions/',
  HelmFooterOtherDifficultiesURL: 'https://takethehelm.com.au/other-difficulties-in-life/',
  HelmFooterLogoURL: 'https://takethehelm.com.au/',
  HelmTermAndConditions: 'https://takethehelm.com.au/terms-and-conditions/',
  HelmPrivacyPolicy: 'https://takethehelm.com.au/privacy-policy/',
  HelmSupportMail: 'hello@takethehelm.com.au',
  HelpSupportTel: '1300 388 576',
  HelmAboutURL: 'https://takethehelm.au/about',
  HelmOurCounsellorURL: 'https://takethehelm.au/counsellors',
  HelmOurPricingURL: 'https://takethehelm.au/our-pricing',
  HelmFAQURL: 'https://takethehelm.au/faqs',
  HelmBookNowURL:
    'https://takethehelm.com.au/counsellors/?theme=all&session=initialConsultation&sid=106212c3a153cb54e8536d4b9fd4818e'
});

export const DefaultClientTimeZone = 'Australia/Melbourne';
