import styles from './Card.module.scss';
import classNames from 'classnames';
import { ReactElement } from 'react';

export interface CardProps {
  icon?: ReactElement<any, any>;
  heading?: string;
  subheading?: string;
  isBolded?: boolean;
}

const Card = ({ icon, heading, subheading, isBolded }: CardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <div className={classNames(styles.heading, isBolded && styles.bolded)}>{heading}</div>
      <div className={styles.subheading}>{subheading}</div>
    </div>
  );
};

export default Card;
