import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import styles from './RecommendPsychologist.module.scss';

import classnames from 'classnames';
import ButtonSH from '../../../../components/ButtonSH/ButtonSH';
import Tag from '../../../../components/Tag/Tag';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useFetchPractitionerList } from 'SomeoneHealth/pages/PsychologistListing/hooks/getPsychologistList';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import moment from 'moment';
import { Skeleton } from 'antd';
import { useContext, useMemo } from 'react';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'SomeoneHealth/interfaces/mentalHealth';
import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { UserContext } from 'utils/UserContextProvider';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { useAuth0 } from '@auth0/auth0-react';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';

const popularSearchData = [
  {
    mentalHealthConcernFilter: 'anxiousOrPanicky',
    label: "I'm feeling anxious or panicky"
  },
  {
    mentalHealthConcernFilter: 'difficultyInMyRelationship',
    label: "I'm having difficulty in my relationship"
  },
  {
    mentalHealthConcernFilter: 'traumaticExperience',
    label: 'A traumatic experience [past or present]'
  },
  {
    mentalHealthConcernFilter: 'troubleSleeping',
    label: "I've been having trouble sleeping"
  },
  {
    mentalHealthConcernFilter: 'addictionOrDifficultyWithSubstanceAbuse',
    label: "I'm navigating addiction or difficulty with substance abuse"
  },
  {
    mentalHealthConcernFilter: 'downOrDepressed',
    label: "I'm feeling down or depressed"
  },
  {
    mentalHealthConcernFilter: 'stressAtWorkOrSchool',
    label: "I'm dealing with stress at work or school"
  }
];

const FEATURED_PROVIDERS_CLINICIAN_IDS = isDevelopmentENV()
  ? [
      'auth0|63eb46d73bd9c33f489106e5', // wanda little
      'auth0|63e61a055246d910ef28ebf2' // jonathan mond
    ]
  : [
      'auth0|640c407361321e3bfd5bc238', // wanda little
      'auth0|640c3f78c08001d05e79cd16' // margaret carey
    ];

const showSpecialisationLimit = 3;

const RecommendPsychologist = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  const someoneHealthClientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage);

  const { clientProfile } = useContext(UserContext);
  const clientAppointmentType = clientProfile?.appointmentBookingClaimType || AppointmentBookingClaimType.BulkBill;

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  // featured providers
  const featuredProvidersListFilterQuery = useMemo(
    () => ({ clinicianIds: FEATURED_PROVIDERS_CLINICIAN_IDS.join(',') }),
    []
  );

  const { psychologistList: featuredProvidersList, isPractitionerListLoading: isFeaturedProvidersListLoading } =
    useFetchPractitionerList({
      clientTimeZone: someoneHealthClientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
      filterQuery: featuredProvidersListFilterQuery
    });

  const nextAvailablePsychologistListFilterQuery = useMemo(
    () => ({ page: 1, perPage: 10, claimType: clientAppointmentType, isNewClient }),
    [clientAppointmentType, isNewClient]
  );
  const {
    psychologistList: nextAvailablePsychologistList,
    isPractitionerListLoading: isNextAvailablePsychologistListLoading
  } = useFetchPractitionerList({
    clientTimeZone: someoneHealthClientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
    filterQuery: nextAvailablePsychologistListFilterQuery,
    shouldFetch: !isClientBookingRuleLoading
  });

  const nextAvailableDateTime = useMemo(() => {
    const nextAvailableClinician = nextAvailablePsychologistList[0];

    if (nextAvailableClinician?.nextAvailableTimeSlot?.date) {
      return moment(
        `${nextAvailableClinician.nextAvailableTimeSlot.date} ${nextAvailableClinician.nextAvailableTimeSlot.time}`,
        'YYYY-MM-DD HH:mmA'
      ).format('dddd, hh:mmA');
    }
  }, [nextAvailablePsychologistList]);

  return (
    <SomeoneHealthContentLayout className={styles.section}>
      <div className={styles.container}>
        <div className={styles.title}>Or browse our 200+ specialists</div>
        <div className={styles.contentWrapper}>
          <div className={classnames(styles.card, styles.shortCard)}>
            <div className={styles.cardTitle}>Next available</div>
            {isNextAvailablePsychologistListLoading ? (
              [...Array(3)].map((_, index) => (
                <div key={index} className={styles.nextAvailableLoading}>
                  <Skeleton.Avatar active />
                  <Skeleton.Button active />
                </div>
              ))
            ) : (
              <>
                {nextAvailableDateTime && <div className={styles.desc}>From {nextAvailableDateTime}</div>}
                <div className={styles.nextAvailableListWrapper}>
                  <div className={styles.nextAvailableList}>
                    {nextAvailablePsychologistList.map((clinician, index) => (
                      <div key={index} className={styles.nextAvailableCard}>
                        <div className={styles.infoWrapper}>
                          <img alt={'avatar'} className={styles.avatar} src={clinician.avatar} />
                          <div className={styles.name}>{clinician.name}</div>
                        </div>
                        <div
                          className={styles.nextButton}
                          onClick={() => navigate(generatePath(PRACTITIONER.DETAILS, { clinicianId: clinician._id }))}
                        >
                          <i className={`material-icons-outlined ${styles.arrow}`}>arrow_forward_ios</i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.seeMore}>
                  <ButtonSH onClick={() => navigate('/psychologists')}>See More</ButtonSH>
                </div>
              </>
            )}
          </div>
          <div className={classnames(styles.card, styles.longCard)}>
            <div className={styles.cardTitle}>Featured providers</div>
            <div className={styles.featuredCardWrapper}>
              {isFeaturedProvidersListLoading ? (
                <Skeleton active />
              ) : (
                featuredProvidersList.map((clinician, index) => (
                  <div key={index} className={styles.featuredCard}>
                    <div className={styles.upperInfo}>
                      <div className={styles.featuredInfoWrapper}>
                        <img alt={'avatar'} className={styles.avatar} src={clinician.avatar} />
                        <div className={styles.clinicianInfo}>
                          <div className={styles.clinicianName}>{clinician.name}</div>
                          <div className={styles.clinicianStyles}>{clinician.helmControl?.bonaFides?.style?.value}</div>
                        </div>
                      </div>
                      <div className={styles.tagWrapper}>
                        {clinician.helmControl.primarySpecialisations
                          ?.slice(0, showSpecialisationLimit)
                          .map((tagObj, index) => (
                            <Tag key={index} label={MENTAL_HEALTH_LIST_LABELS[tagObj as MentalHealthList]} />
                          ))}
                        {clinician.helmControl.primarySpecialisations &&
                          clinician.helmControl.primarySpecialisations.length > showSpecialisationLimit && (
                            <Link
                              key={index}
                              to={generatePath(PRACTITIONER.DETAILS, { clinicianId: clinician._id })}
                              className={styles.seeMoreSlot}
                            >
                              ...+{clinician.helmControl.primarySpecialisations.length - showSpecialisationLimit} MORE
                            </Link>
                          )}
                      </div>
                    </div>
                    <div className={styles.lowerInfo}>
                      <div className={styles.viewButton}>
                        <ButtonSH
                          className={styles.viewBtn}
                          iconClassName={styles.btnIcon}
                          icon={'arrow_forward_ios'}
                          iconPostFix
                          onClick={() => navigate(generatePath(PRACTITIONER.DETAILS, { clinicianId: clinician._id }))}
                        >
                          View
                        </ButtonSH>
                      </div>
                      <div className={styles.nextAppointment}>
                        <div className={styles.nextLabel}>NEXT AVAILABLE</div>
                        <div className={styles.appointmentDate}>
                          {clinician.nextAvailableTimeSlot
                            ? moment(
                                `${clinician.nextAvailableTimeSlot.date} ${clinician.nextAvailableTimeSlot.time}`,
                                'YYYY-MM-DD HH:mm'
                              ).format('ddd, DD MMM')
                            : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className={classnames(styles.card, styles.shortCard)}>
            <div className={styles.cardTitle}>Popular searches</div>
            <div className={styles.popularSearchWrapper}>
              {popularSearchData.map(({ mentalHealthConcernFilter, label }, index) => (
                <Link
                  className={styles.popularSearch}
                  key={index}
                  to={generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
                    filter: mentalHealthConcernFilter
                  })}
                >
                  {label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </SomeoneHealthContentLayout>
  );
};

export default RecommendPsychologist;
