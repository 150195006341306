import * as Yup from 'yup';

export const RISK_SCREENING_OPTIONS = ['Yes', 'No'].map((value) => ({ label: value, value }));

export const initialRiskScreeningValues = {
  thoughtsOfSuicide: '',
  treatmentForSuicide: '',
  drugsOrAlcohol: '',
  involvedInLegalCase: '',
  treatedByOtherService: '',
  otherConcerns: '',
  otherInformation: ''
};

export const otherConcernsOptions = [
  { label: 'I have a diagnosis of ADHD', value: 'I have a diagnosis of ADHD' },
  { label: 'I have a diagnosis of Autism', value: 'I have a diagnosis of Autism' },
  { label: 'I am struggling with an eating disorder', value: 'I am struggling with an eating disorder' },
  {
    label: 'I have a disability that I would like to be taken into consideration when attending my sessions',
    value: 'I have a disability that I would like to be taken into consideration when attending my sessions'
  }
];

export const formDataValidationSchema = Yup.object().shape({
  thoughtsOfSuicide: Yup.string().required('Please select an option'),
  treatmentForSuicide: Yup.string().required('Please select an option'),
  drugsOrAlcohol: Yup.string().required('Please select an option'),
  involvedInLegalCase: Yup.string().required('Please select an option'),
  treatedByOtherService: Yup.string().required('Please select an option'),
  otherConcerns: Yup.string(),
  otherInformation: Yup.string()
});
