import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import {
  HIDDEN_SPECIALISATIONS_LIST,
  MENTAL_HEALTH_LIST_LABELS,
  MentalHealthList
} from 'Recharge/interfaces/mentalHealth';

import styles from './FilterDropdownWrapper.module.scss';
import FilterDropdownMultiSelect from 'components/FilterDropdown/components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import classnames from 'classnames';

const generateMentalHealthList = () => {
  return Object.values(MentalHealthList)
    .filter((item) => !HIDDEN_SPECIALISATIONS_LIST.includes(item))
    .map((item) => ({
      id: item,
      label: MENTAL_HEALTH_LIST_LABELS[item]
    }));
};

interface FilterDropdownWrapperProps {
  classNames?: string;
  mentalHealthConcernValue?: listInterface[];
  onChangeMentalHealthConcernValue: (value: listInterface) => void;
}

const FilterDropdownWrapper = ({
  classNames,
  mentalHealthConcernValue,
  onChangeMentalHealthConcernValue
}: FilterDropdownWrapperProps) => (
  <div className={classnames(styles.container, classNames)}>
    <FilterDropdownMultiSelect
      id={'mentalValue'}
      label={'Topic interested'}
      listData={generateMentalHealthList()}
      onChangeValue={onChangeMentalHealthConcernValue}
      selectedMultipleValue={mentalHealthConcernValue || []}
      className={styles.mentalHealthConcern}
      withSearch
      ableToSearchAll
    />
  </div>
);

export default FilterDropdownWrapper;
