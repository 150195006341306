import styles from './LoadingCircle.module.scss';
import classNames from 'classnames';

interface LoadingCircleProps {
  className?: string;
}

const LoadingCircle = ({ className }: LoadingCircleProps) => {
  return <div className={classNames(styles.loading, className)} />;
};

export default LoadingCircle;
