import { generatePath } from 'react-router-dom';
import { PsychologistMinified } from '../../hooks/useFetchPsychologistsMinified';
import styles from './PsychologistAlphabetItem.module.scss';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import { Link } from 'react-router-dom';

interface PsychologistAlphabetItemProps {
  filterCharacter: string;
  psychologist: PsychologistMinified[];
  searchValue?: string;
}

const PsychologistAlphabetItem = ({ filterCharacter, psychologist, searchValue }: PsychologistAlphabetItemProps) => {
  const { PRACTITIONER } = useCaWRoutesGenerator();

  return (
    <div className={styles.container}>
      <div className={styles.filterCharacter}>{filterCharacter}</div>
      <div className={styles.matchedItems}>
        {psychologist.map((item, index) => {
          const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
            clinicianId: item.slugUrl
          });

          const matchedIndex = searchValue ? item.name.toLowerCase().indexOf(searchValue.toLowerCase()) : 0;

          return (
            <Link key={index} to={detailsPagePath} className={styles.item}>
              {searchValue ? (
                <>
                  {item.name.substring(0, matchedIndex)}
                  <span className={styles.matchedValue}>
                    {item.name.substring(matchedIndex, matchedIndex + searchValue.length)}
                  </span>
                  {item.name.substring(matchedIndex + searchValue.length)}
                </>
              ) : (
                item.name
              )}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PsychologistAlphabetItem;
