import { Modal } from 'antd';
import styles from './FitFilterMobilePopup.module.scss';
import FitFilterMobileItem, { OptionItem } from '../FitFilterMobileItem/FitFilterMobileItem';
import { useEffect, useState } from 'react';
import { PsychologistFitFilter } from 'Recharge/pages/PsychologistListing/PsychologistListing';
import {
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitPsychologistGenderList,
  FitStyleList,
  FitTimesList
} from 'Recharge/interfaces/fitFilter';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';

export interface FitFilterInterface {
  day: OptionItem | undefined;
  time: OptionItem | undefined;
  delivery: OptionItem | undefined;
  language: OptionItem | undefined;
  gender: OptionItem | undefined;
  style: OptionItem | undefined;
}

interface FitFilterMobilePopupProps {
  visible: boolean;
  filterIsSelected: string;
  genderOptions: OptionItem[];
  dayOptions: OptionItem[];
  timeOptions: OptionItem[];
  deliveryOptions: OptionItem[];
  languageOptions: OptionItem[];
  styleOptions: OptionItem[];
  selectedFiltersProp: FitFilterInterface;
  onView: (value: PsychologistFitFilter) => void;
  onClearAll: () => void;
  onCancel: () => void;
}

const FitFilterMobilePopup = ({
  visible,
  filterIsSelected,
  genderOptions,
  dayOptions,
  timeOptions,
  deliveryOptions,
  languageOptions,
  styleOptions,
  selectedFiltersProp,
  onView,
  onClearAll,
  onCancel
}: FitFilterMobilePopupProps) => {
  const [selectedFilters, setSelectedFilters] = useState<FitFilterInterface>(selectedFiltersProp);

  useEffect(() => {
    setSelectedFilters(selectedFiltersProp);
  }, [selectedFiltersProp]);

  const handleChangeFilters = (value: OptionItem, key: string) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: value
    });
  };

  const handleView = () => {
    const values: PsychologistFitFilter = {
      gender: selectedFilters.gender?.id as FitPsychologistGenderList,
      day: selectedFilters.day?.id as FitDaysList,
      time: selectedFilters.time?.id as FitTimesList,
      deliveryMode: selectedFilters.delivery?.id as FitDeliveryList,
      language: selectedFilters.language?.id as FitLanguageList,
      style: selectedFilters.style?.id as FitStyleList
    };

    onView(values);
    onCancel();
  };

  const handleClearAll = () => {
    onClearAll();
    onCancel();
  };

  const { gender, day, time, delivery, language, style } = selectedFilters;

  return (
    <Modal
      visible={visible}
      destroyOnClose
      zIndex={2147483648} // Chat Widget z-index is 2147483647
      onCancel={onCancel}
      className={styles.modalClass}
      width={'100%'}
      style={{ top: 0, margin: 0, maxWidth: '100%', padding: 0 }}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>Refine your fit</div>
          <div className={styles.subTitle}>Filter psychologists by...</div>
        </div>
      }
      footer={
        <div className={styles.modalFooter}>
          <ButtonRecharge fullWidth onClick={handleView}>
            View
          </ButtonRecharge>
          <ButtonRecharge variant={'outlined'} className={styles.clearAll} fullWidth onClick={handleClearAll}>
            Clear all
          </ButtonRecharge>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div id="gender" className={styles.filterItem}>
            <FitFilterMobileItem
              classname={styles.filter}
              id="gender"
              optionList={genderOptions}
              filterLabel="Gender"
              selectedValue={gender}
              isExpandProp={filterIsSelected === 'gender'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'gender');
              }}
            />
          </div>
          <div id="day" className={styles.filterItem}>
            <FitFilterMobileItem
              id="day"
              optionList={dayOptions}
              filterLabel="Days"
              selectedValue={day}
              isExpandProp={filterIsSelected === 'day'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'day');
              }}
            />
          </div>
          <div id="time" className={styles.filterItem}>
            <FitFilterMobileItem
              id="item"
              optionList={timeOptions}
              filterLabel="Times"
              selectedValue={time}
              isExpandProp={filterIsSelected === 'time'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'time');
              }}
            />
          </div>
          <div id="delivery" className={styles.filterItem}>
            <FitFilterMobileItem
              id="delivery"
              optionList={deliveryOptions}
              filterLabel="Delivery"
              selectedValue={delivery}
              isExpandProp={filterIsSelected === 'delivery'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'delivery');
              }}
            />
          </div>
          <div id="language" className={styles.filterItem}>
            <FitFilterMobileItem
              id="language"
              optionList={languageOptions}
              filterLabel="Language"
              selectedValue={language}
              isExpandProp={filterIsSelected === 'language'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'language');
              }}
            />
          </div>
          <div id="style" className={styles.filterItem}>
            <FitFilterMobileItem
              id="style"
              optionList={styleOptions}
              filterLabel="Style"
              selectedValue={style}
              isExpandProp={filterIsSelected === 'style'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'style');
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FitFilterMobilePopup;
