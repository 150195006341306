import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { SelectDefaultTimezone } from './env/SelectEnv';

export const getReservedAppointmentTimeString = (value: AppointmentSlot) => {
  const massageTimeZone = convertTimeSlot(value.startTime, value.endTime, moment(value.date).format('YYYY-MM-DD'));

  return `${moment(value.date).format('DD MMMM YYYY')}, ${moment(massageTimeZone.startTime, 'hh:mmA').format(
    'hh:mm'
  )} to ${massageTimeZone.endTime}`;
};

// NOTE: keeping this here, in case select wants different timezones other than UK
const convertTimeSlot = (startTime: string, endTime: string, date: string | Date) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, 'YYYY-MM-DD hh:mmA', SelectDefaultTimezone);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, SelectDefaultTimezone).format('hh:mmA');
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, 'YYYY-MM-DD hh:mmA', SelectDefaultTimezone);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, SelectDefaultTimezone).format('hh:mmA');
  return {
    startTime: clientStartTimeZone,
    endTime: clientEndTimeZone
  };
};
