import styles from './ClinicianInformation.module.scss';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

interface ClinicianInformationProps {
  listAppointmentLength: number;
  profileName: string;
  profileImage: string;
}
const ClinicianInformation = ({ profileName, profileImage, listAppointmentLength }: ClinicianInformationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.inforWrapper}>
        <div className={styles.profile}>
          <ClientProfileAvatar avatarUrl={profileImage} />
        </div>
        <div className={styles.info}>
          <div className={styles.text}>
            {profileName}
            <div className={styles.description}>
              <div className={styles.number}>{listAppointmentLength}</div>
              <div className={styles.content}>&nbsp;Appointment Types</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicianInformation;
