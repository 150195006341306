import smpTrophy from 'assets/images/smp/smpTrophy.png';
import { GoalNumber, SMPForm, SMPGoal as SMPGoalInterface } from 'pages/SMP/interfaces/smp';
import SMPGoal from '../SMPGoal/SMPGoal';
import styles from './SMPGoalsSetting.module.scss';

interface SMPGoalsSettingProps {
  goals: SMPForm['goals'];
  onChange: (goals: SMPForm['goals']) => void;
  isEditMode?: boolean;
}

const SMPGoalsSetting = ({ goals, onChange: onGoalsChange, isEditMode }: SMPGoalsSettingProps) => {
  const onGoalChangeGenerator = (i: GoalNumber) => (goal: SMPGoalInterface) =>
    onGoalsChange({ ...goals, [`goal${i}`]: goal });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>My Goals</div>
        <div className={styles.desc}>And the Steps I need to take are...</div>
        <img src={smpTrophy} alt="trophy" className={styles.trophy} />
      </div>
      <div className={styles.content}>
        <SMPGoal goal={goals?.goal1} num={1} onChange={onGoalChangeGenerator(1)} isEditMode={isEditMode} />
        <SMPGoal
          goal={goals?.goal2}
          num={2}
          onChange={onGoalChangeGenerator(2)}
          isEditMode={isEditMode}
          goalContainerClassName={styles.shadedItem}
        />
        <SMPGoal goal={goals?.goal3} num={3} onChange={onGoalChangeGenerator(3)} isEditMode={isEditMode} />
      </div>
    </div>
  );
};

export default SMPGoalsSetting;
