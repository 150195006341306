import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ProgramId, ProgramInterface } from 'helm/interfaces/program/program';
import { getProgramDetails } from '../../../utils/http/ClinicianProfileService/program/program';
import { useGetAccessToken } from 'utils/hooks/token';
import chapter1 from 'helm/assets/images/ip6/chapter/chapter1.svg';
import chapter2 from 'helm/assets/images/ip6/chapter/chapter2.svg';
import chapter3 from 'helm/assets/images/ip6/chapter/chapter3.svg';
import chapter4 from 'helm/assets/images/ip6/chapter/chapter4.svg';
import chapter5 from 'helm/assets/images/ip6/chapter/chapter5.svg';
import chapter6 from 'helm/assets/images/ip6/chapter/chapter6.svg';

const getChapterImage = (chapterId: number) => {
  switch (chapterId) {
    case 1:
      return chapter1;
    case 2:
      return chapter2;
    case 3:
      return chapter3;
    case 4:
      return chapter4;
    case 5:
      return chapter5;
    case 6:
      return chapter6;
    default:
      return chapter1;
  }
};

export const useFetchIP6Details = (clientRecordId: string, clientProfileId: string) => {
  const [ip6Details, setIP6Details] = useState<ProgramInterface>();
  const [isIP6DetailLoading, setIP6DetailLoading] = useState(true);
  const { token } = useGetAccessToken();

  const massageChapters = (chapterList: ProgramInterface['chapters']) =>
    chapterList.map((chapterObj, index) => ({
      ...chapterObj,
      chapterId: index + 1,
      image: getChapterImage(index + 1)
    }));

  const fetchIP6Details = useCallback(async () => {
    try {
      setIP6DetailLoading(true);
      const response: ProgramInterface = await (
        await getProgramDetails(token, clientRecordId, clientProfileId, ProgramId.Ip6)
      ).json();
      const massageData = {
        ...response,
        chapters: massageChapters(response.chapters)
      };

      setIP6Details(massageData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get IP6 Details' });
    }
    setIP6DetailLoading(false);
  }, [token, clientRecordId, clientProfileId]);

  useEffect(() => {
    if (token) {
      fetchIP6Details();
    }
  }, [token, fetchIP6Details]);

  return { ip6Details, isIP6DetailLoading, fetchIP6Details };
};
