import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import ConsentFormOnboarding from 'pages/ConsentForm/ConsentFormOnboarding/ConsentFormOnboarding';
import styles from './CordsConsentList.module.scss';

const CordsConsentList = () => {
  return (
    <CORDSContentWrapper title={'CORDS - Consent List'} fullwidth contentClassName={styles.container}>
      <ConsentFormOnboarding className={styles.content} />
    </CORDSContentWrapper>
  );
};

export default CordsConsentList;
