import Auth0ProviderWithHistory from 'components/Auth/Auth0ProviderWithHistory';
import { BrowserRouter } from 'react-router-dom';
import RouteCollection from './Routes/RouteCollection';

const App = () => (
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <RouteCollection />
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

export default App;
