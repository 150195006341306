import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import styles from './PsychologistTagCollection.module.scss';
import { CHINESE_MANDARIN, FitDeliveryList, FitLanguageList } from 'SomeoneHealth/interfaces/fitFilter';
import { LANGUAGE_COLLECTION } from 'utils/constants/language';
import classNames from 'classnames';
import { FC } from 'react';

interface PsychologistTagCollectionProps {
  practitionerDetails: PractitionerDetailsInterface;
  selectedValue?: string[];
}

const PsychologistTagCollection: FC<PsychologistTagCollectionProps> = ({ practitionerDetails, selectedValue }) => {
  return (
    <div className={styles.container}>
      {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.video) && (
        <div className={classNames(selectedValue?.includes(FitDeliveryList.video) ? styles.selectedTag : styles.tag)}>
          <i className={`material-icons ${styles.callIcon}`}>videocam</i>
          <div className={styles.label}>Video Consults</div>
        </div>
      )}
      {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
        <div className={classNames(selectedValue?.includes(FitDeliveryList.phone) ? styles.selectedTag : styles.tag)}>
          <i className={`material-icons ${styles.callIcon}`}>call</i>
          <div className={styles.label}>Phone Consults</div>
        </div>
      )}
      {practitionerDetails.helmControl?.deliveryModes &&
        practitionerDetails.helmControl.deliveryModes?.length > 0 &&
        practitionerDetails.helmControl.bonaFides?.languages?.active &&
        practitionerDetails.helmControl.bonaFides?.languages.value &&
        practitionerDetails.helmControl.bonaFides?.languages.value?.length > 0 && (
          <div className={styles.inLabel}>in</div>
        )}
      {practitionerDetails.helmControl?.bonaFides?.languages?.value?.map((languageObj, index) => {
        const languageLabel =
          languageObj === FitLanguageList.zh
            ? CHINESE_MANDARIN
            : LANGUAGE_COLLECTION[languageObj as keyof typeof LANGUAGE_COLLECTION];

        return (
          <div
            className={classNames(selectedValue?.includes(languageObj) ? styles.selectedTag : styles.tag)}
            key={index}
          >
            <div className={styles.label}>{languageLabel}</div>
          </div>
        );
      })}
    </div>
  );
};

export default PsychologistTagCollection;
