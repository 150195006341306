import { setResourceTerminology } from 'i18n/resources/en/terminology';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicPracticeProfile } from '../../interfaces/Practice/practice';
import { getPublicPracticeProfileBySlugUrl } from '../http/ClinicianProfileService/Accounts/accounts';

export const useFetchPublicPracticeProfile = (slugUrl: string) => {
  const [practiceProfile, setPracticeProfile] = useState<PublicPracticeProfile>();
  const [isPracticeProfileLoading, setIsPracticeProfileLoading] = useState(true);
  const [, i18n] = useTranslation();
  const navigate = useNavigate();

  const fetchPublicPracticeProfile = async () => {
    try {
      const callGetPublicPracticeProfileBySlugUrl = await getPublicPracticeProfileBySlugUrl(slugUrl);

      const data: PublicPracticeProfile = await callGetPublicPracticeProfileBySlugUrl.json();

      setResourceTerminology(i18n, data.accountSettings);
      setPracticeProfile(data);
      setIsPracticeProfileLoading(false);
    } catch (ex) {
      console.error(`User redirected from ${window.location.pathname} to /404`);
      navigate('/404');
    }
  };

  useEffect(() => {
    fetchPublicPracticeProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugUrl]);

  return { practiceProfile, isPracticeProfileLoading };
};
