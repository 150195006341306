import bent from 'bent';

const url = process.env.REACT_APP_BILLING_SERVICE_URL;

export const downloadInvoicePDF = async ({
  token,
  invoiceId,
  clientRecordId,
  clientProfileId,
  payload
}: {
  token: string;
  invoiceId: string;
  clientRecordId: string;
  clientProfileId: string;
  payload: any;
}) => {
  const xmlSerializer = new XMLSerializer();
  const encodedPayload = xmlSerializer.serializeToString(payload);
  const response = await bent('POST', 'buffer', url!, 200, {
    Authorization: `Bearer ${token}`
  })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/invoice/${invoiceId}/printable-pdf`,
    encodedPayload,
    {
      'Content-Type': 'text/plain',
      'Content-Length': encodedPayload.length
    }
  );

  return new Blob([response], { type: 'application/pdf' });
};
