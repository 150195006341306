import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useBazaarTokens from '../../../utils/hooks/useBazaarTokens';
import AuthErrorMessage from '../AuthErrorMessage/AuthErrorMessage';
import Form from './Form/Form';
import styles from './SelfAssessments.module.scss';

const SelfAssessments = () => {
  const { token, hasFailedToRequestStorage, requestStorageAccess } = useBazaarTokens();

  const { type }: { type?: string } = useParams<{ type: string }>();

  const ref = useRef(null);

  const getResizeComponentId = (assessmentType: string | undefined) => {
    switch (assessmentType) {
      case 'gad7':
        return 'tacklitAssessmentGad7';
      case 'phq9':
        return 'tacklitAssessmentPhq9';
      default:
        // To be deleted after Bazaar migrated to the new design.
        return 'Assessments';
    }
  };

  const resize = () => {
    // @ts-ignore
    // eslint-disable-next-line
    parent.postMessage(
      {
        resizeComponentID: getResizeComponentId(type),
        // @ts-ignore
        height: ref?.current?.offsetHeight || 0
      },
      '*'
    );
  };

  useEffect(() => {
    resize();
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.title}>
        {type === 'gad7' && 'GAD-7'}
        {type === 'phq9' && 'PHQ-9'}
      </div>
      {!token ? (
        <AuthErrorMessage
          requestStorageAccess={requestStorageAccess}
          hasFailedToRequestStorage={hasFailedToRequestStorage}
        />
      ) : (
        <Form token={token} resizeParentFrame={resize} />
      )}
    </div>
  );
};

export default SelfAssessments;
