import { useMemo } from 'react';
import { useField } from 'formik';

import FormikInput from '../Input/FormikInput';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Radio, { RadioProps } from './Radio';

interface FormikRadioProps extends Omit<RadioProps, 'hasError'> {
  name: string;
  hasOthers?: boolean;
  innerClass?: string;
}

const otherOption = {
  label: 'Other',
  value: 'other'
};

const FormikRadio = ({ className, name, options, hasOthers, innerClass, ...props }: FormikRadioProps) => {
  const [field, { error, touched }] = useField(name);
  const { value } = field;

  const optionsWithOthers = useMemo(() => {
    if (hasOthers) {
      return [...options, otherOption];
    } else {
      return options;
    }
  }, [options, hasOthers]);

  return (
    <div className={className}>
      <Radio className={innerClass} {...props} {...field} hasError={touched && !!error} options={optionsWithOthers} />
      <ErrorMessage error={error} visible={touched} />
      {hasOthers && value === otherOption.value && (
        <FormikInput
          className="fullWidth"
          name={`${name}-others`}
          label="How should we address you?"
          noSpacing
          required
        />
      )}
    </div>
  );
};

export default FormikRadio;
