import styles from './PractitionerServiceDetails.module.scss';
import { PractitionerDetailsInterface } from '../../../interfaces/PublicProfile/Practitioner/practitioner';

interface PractitionerServiceDetailsProps {
  helmDetails: PractitionerDetailsInterface;
}

const PractitionerServiceDetails = ({ helmDetails }: PractitionerServiceDetailsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>SERVICES DELIVERED VIA</div>
      <div className={styles.deliveredDetails}>
        <div className={styles.details}>
          <div>
            <div className={`material-icons ${styles.icon}`}>location_on</div>
          </div>
          <div className={styles.locationDetails}>
            <div className={styles.descLabel}>Video Call (Zoom)</div>
          </div>
        </div>
        {helmDetails.consultantLocations.length > 0 &&
          helmDetails.consultantLocations[0] !== '' &&
          helmDetails.consultantLocations.map((obj, index) => (
            <div key={index} className={styles.details}>
              <div>
                <div className={`material-icons ${styles.icon}`}>location_on</div>
              </div>
              <div className={styles.locationDetails}>
                <div className={styles.descLabel}>In person at:</div>
                <div className={styles.descLabel}>{obj}</div>
                <div>
                  <iframe
                    title="map-frame"
                    className={styles.mapWrapper}
                    width="100%"
                    height="260"
                    frameBorder="0"
                    aria-hidden="false"
                    src={`https://maps.google.com/maps?q=${obj}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PractitionerServiceDetails;
