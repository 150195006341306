import { notification } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import ContactForm from 'components/ContactForm/ContactForm';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import {
  DEFAULT_ERROR,
  DEFAULT_FORM_VALUES,
  DETAIL_OPTIONS,
  RECAPTCHA_KEY,
  validationForm
} from 'components/ReferralForm/constants';
import { buildPayload } from 'components/ReferralForm/referralFormHelpers';
import ReferralForm from 'components/ReferralForm/ReferralForm';
import { clientRecordsInterface, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { postReferral } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import styles from './JoinWaitlistForm.module.scss';
import classNames from 'classnames';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import PsychologistAvatar from 'Select/components/PsychologistAvatar/PsychologistAvatar';
import { isEmpty } from 'lodash';
import { SelectPrivacyPolicyUrl } from 'Select/utils/env/SelectEnv';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import selectLogo from 'Select/assets/images/logo/logo.png';

interface JoinWaitlistFormProps {
  onComplete: (input: { createdClientRecord: clientRecordsInterface }) => void;
  accountId: string;
}

const JoinWaitlistForm = ({ onComplete, accountId }: JoinWaitlistFormProps) => {
  const { PRACTITIONER } = useSelectRoutesGenerator();
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR);
  const [captcha, setCaptcha] = useState('');
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const onCaptchaChange = (value: string | null) => {
    if (value) {
      setCaptcha(value);
    }
  };

  const onCaptchaExpired = () => {
    setCaptcha('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const validate = validationForm(formValues);
    setErrorMessage(validate);
    const checkFieldHaveError = validate ? Object.values(validate).some((value) => value !== '') : false;
    if (checkFieldHaveError) {
      setIsSubmitting(false);
      return;
    }
    const phoneValidate = await validatePhoneNumber(
      formValues.isUseGuardianInformation === 'guardianInformation'
        ? formValues.guardianContactNumber
        : formValues.contactNumber
    );
    if (phoneValidate.valid) {
      try {
        const massagePayload = buildPayload(formValues, captcha).payload();

        const res = await postReferral(accountId, {
          ...massagePayload,
          communicationPreference: receiveSMS ? CommunicationPreference.NoPreference : CommunicationPreference.Email
        });
        setIsSubmitting(false);
        notification.success({ message: 'Joined the waitlist successfully.' });
        const { createdClientRecord } = await res.json();
        setFormValues(DEFAULT_FORM_VALUES);
        setReceiveSMS(false);
        setAgeConfirm(false);
        onComplete({ createdClientRecord });
      } catch (ex) {
        setIsSubmitting(false);
        notification.error({
          message: `Something went wrong while trying to join the waitlist.`
        });
      }
    } else {
      setIsSubmitting(false);
      setInvalidMobileNumber(true);
    }
  };

  const isBtnDisabled = () => {
    return !ageConfirm || !receiveSMS || isEmpty(captcha);
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      )}
      <div className={classNames(styles.container, 'select-theme')}>
        <div className={styles.header}>
          <PsychologistAvatar className={styles.profile} profileImg={selectLogo} detailPath={PRACTITIONER.LISTING} />

          <div className={styles.headerTitle}>
            Join the waitlist of Select Psychology.
            <div className={styles.subHeader}>Fill in your information below to join the waitlist.</div>
          </div>
        </div>

        <div className={styles.loginText}>
          Already have an account?
          <Link to={`/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </Link>
        </div>

        <ContactForm
          errorMessage={errorMessage}
          formValues={formValues}
          setFormValues={setFormValues}
          setInvalidMobileNumber={setInvalidMobileNumber}
          referralDetailOption={DETAIL_OPTIONS}
          invalidMobileNumber={invalidMobileNumber}
          resetErrors={(key: string) => setErrorMessage({ ...errorMessage, [key]: '' })}
        />

        <div className={styles.referral}>
          <ReferralForm
            formValues={formValues}
            setFormValues={setFormValues}
            referralDetailOption={DETAIL_OPTIONS}
            errorMessage={errorMessage}
          />
        </div>

        <div className={styles.ageConfirm}>
          <div className={styles.checkBoxText}>
            <CheckBox
              id="ageConfirm"
              value={ageConfirm}
              onChange={(e) => setAgeConfirm(e.target.checked)}
              className={styles.checkBox}
              label={`I agree to Select Psychology's <a target="_blank" href="${SelectPrivacyPolicyUrl}">Privacy Policy</a>`}
            />
          </div>
        </div>

        <div className={styles.communicationPreferences}>
          <div className={styles.title}>Communication Preferences</div>
          <div className={styles.text}>
            We will contact you in regards to helping set you up with an available counsellor or service. Please confirm
            you give consent for us to contact you to discuss.
          </div>
          <div className={styles.checkBoxes}>
            <div className={styles.checkBoxText}>
              <CheckBox
                id="receiveSMS"
                value={receiveSMS}
                onChange={(e) => setReceiveSMS(e.target.checked)}
                className={styles.checkBox}
                label="I am happy to be contacted by the support team"
              />
            </div>
          </div>
        </div>

        <ReCAPTCHA
          ref={reCaptchaRef}
          type={'image'}
          sitekey={RECAPTCHA_KEY}
          onChange={onCaptchaChange}
          onExpired={onCaptchaExpired}
        />

        <ButtonSelect
          className={styles.submitButton}
          disabled={isBtnDisabled()}
          onClick={() => {
            handleSubmit();
          }}
        >
          Join Waitlist
        </ButtonSelect>
      </div>
    </>
  );
};

export default JoinWaitlistForm;
