import { nextAvailability, timeSlotsInterface } from '../../../../interfaces/PublicProfile/Practitioner/practitioner';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from '../../../interfaces/mentalHealth';

export const getMentalHealthLabels = (value: string[] | MentalHealthList[]) => {
  return value.map((item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]);
};

export const getNextTimeSlot = (
  nextAvailabilityDetails: nextAvailability[]
): { date: string; timeSlot: timeSlotsInterface } | undefined => {
  if (!nextAvailabilityDetails.length) {
    return undefined;
  }

  for (const detail of nextAvailabilityDetails) {
    for (const availability of detail.availabilities) {
      if (availability && availability.timeSlots) {
        return {
          date: availability.date,
          timeSlot: availability.timeSlots[0]
        };
      }
    }
  }

  return undefined;
};

export const isNoSlotForNewClient = (
  isAuthenticated: boolean,
  shouldUseCaseLoad: boolean | undefined,
  remainingSlots: number | undefined
): boolean => !isAuthenticated && !!shouldUseCaseLoad && (remainingSlots || 0) <= 0;
