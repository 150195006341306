import styles from './RechargeHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LoginAvatar from 'Recharge/components/LoginAvatar/LoginAvatar';
import rechargeDarkLogo from 'Recharge/assets/images/logo/logo-dark-full.png';
import rechargeLightLogo from 'Recharge/assets/images/logo/logo-light-full.png';
import { RechargeUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';
import RechargeContentLayout from '../RechargeContentLayout/RechargeContentLayout';

interface RechargeHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  classNames?: string;
  loginTextClass?: string;
  whiteMenu?: boolean;
  lightLogo?: boolean;
}

const RechargeHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  classNames,
  loginTextClass,
  whiteMenu,
  lightLogo
}: RechargeHeaderProps) => {
  const navigate = useNavigate();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = RechargeUrl;
    }
  };

  return (
    <RechargeContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, classNames)}
    >
      <div id={'RechargeHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img className={styles.logo} alt={'logo'} src={lightLogo ? rechargeLightLogo : rechargeDarkLogo} />
        </div>
        <div className={styles.rightContent}>
          {!noLogin && (
            <LoginAvatar
              whiteFont={whiteFont}
              whiteMenu={whiteMenu}
              withMenu={withMenu}
              loginTextClass={loginTextClass}
            />
          )}
        </div>
      </div>
    </RechargeContentLayout>
  );
};

export default RechargeHeader;
