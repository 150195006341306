import { useAuth0 } from '@auth0/auth0-react';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import PaymentFailureTaskBox from 'Select/pages/SelectHomePage/components/PaymentFailureTaskBox/PaymentFailureTaskBox';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import TaskBox from 'components/TaskBox/TaskBox';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import styles from './SelectActionPage.module.scss';
import { formatActionCount } from 'utils/general';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const SelectActionPage = () => {
  const { user } = useAuth0();
  const clientName = user?.name || '';
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const { CONSENT, SURVEY, ONBOARDING, PSYCHOMETRIC } = useSelectRoutesGenerator();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();

  const isContentLoading = isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const clientTasksLength = clientTasks
    ? clientTasks.failedPayments.length + clientTasks.consentList.length + clientTasks.assessmentList.length
    : 0;
  const surveyListLength = surveyList ? surveyList.length : 0;
  const psychometricListLength = psychometricList ? psychometricList.length : 0;
  const totalLength = clientTasksLength + surveyListLength + psychometricListLength;

  const clinicianName = attachedClinicianDetail?.clinician?.name || attachedClinicianDetail?.practice?.name || '';

  return (
    <SelectHelmetWrapper title={`Select Psychology | Pending Actions`}>
      <SelectContentLayout className={classNames(styles.container, 'select-theme')}>
        <div className={styles.contentWrapper}>
          <SelectHeader withPadding withMenu homeLink />
          <div className={styles.content}>
            {isContentLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                <div className={styles.taskListContainer}>
                  <div className={styles.taskListTitle}>
                    <strong className={styles.title}>Welcome back {clientName}!</strong>
                    <strong className={styles.title}>Your pre session activities</strong>
                    <div>
                      {clinicianName} has asked you to complete the following{' '}
                      {formatActionCount({ count: totalLength })}.
                    </div>
                  </div>
                  <div className={styles.taskListBox}>
                    {clientTasks && (
                      <>
                        {clientTasks.failedPayments.map(({ amount, _id }) => (
                          <PaymentFailureTaskBox amount={amount} key={_id} paymentRequestId={_id} />
                        ))}
                        {clientTasks.consentList.length > 0 && (
                          <TaskBox
                            title={t('label.esign_client_consent_form')}
                            desc={formatActionCount({ count: clientTasks.consentList.length })}
                            onClick={() => navigate(CONSENT.BASE)}
                          />
                        )}
                        {clientTasks.assessmentList.length > 0 && (
                          <TaskBox
                            title={'Onboarding assessment'}
                            desc={formatActionCount({ count: clientTasks.assessmentList.length })}
                            onClick={() => navigate(ONBOARDING.BASE)}
                          />
                        )}
                      </>
                    )}
                    {surveyList && surveyList.length > 0 && (
                      <TaskBox
                        title={'Surveys'}
                        desc={formatActionCount({ count: surveyList.length })}
                        onClick={() => navigate(SURVEY.BASE)}
                      />
                    )}
                    {psychometricList.length > 0 && (
                      <TaskBox
                        title={'Psychometrics'}
                        desc={formatActionCount({ count: psychometricList.length })}
                        onClick={() => navigate(PSYCHOMETRIC.BASE)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </SelectContentLayout>
    </SelectHelmetWrapper>
  );
};

export default SelectActionPage;
