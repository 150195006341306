import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import ClientHeader from '../../components/ClientHeader/ClientHeader';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { TIMEZONE_OPTIONS } from 'utils/constants/timeZone';
import { config } from 'config/config';
import { isClientTimeZoneEnabled } from 'utils/featureToggle/featureToggle';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || config.defaultTimezone;
  const timeZoneLocation = TIMEZONE_OPTIONS.find(({ value }) => value === timeZone)?.label;
  return (
    <PatientContentLayout
      className={styles.container}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className={styles.contentWrapper}>
        <ClientHeader withMenu />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Your Appointment Overview</div>
            {timeZoneLocation && isClientTimeZoneEnabled && (
              <div className={styles.timeZone}>Time in {timeZoneLocation}</div>
            )}
          </div>
          <AppointmentList
            isPatientProfileLoading={isClientProfileLoading}
            clientProfileTimeZone={clientProfileData?.timeZone}
          />
        </div>
      </div>
    </PatientContentLayout>
  );
};

export default AppointmentsOverview;
