import styles from './PsychologistFAQ.module.scss';
import FAQBubble from './components/FAQBubble/FAQBubble';
import classnames from 'classnames';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import { RechargeContactUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';

const faqData = [
  {
    title: 'Are your psychologists registered?',
    desc: 'Yes, our Registered Psychologists and Clinical Psychologists are registered with the Australian Health Practitioner Regulation Agency (AHPRA) and are selected by Recharge.'
  },
  {
    title: 'What is a mental health care plan (MHCP)?',
    desc: 'A Mental Health Care Plan (MHCP) is a support plan written by a GP and it is available to anyone who feels they would benefit from psychological support for mental health concerns. A MHCP is usually accompanied by a referral from your GP to see a psychologist.<br><br>With a MHCP you can now access up to 00 Medicare-subsidised or bulk billed psychology sessions per calendar year.'
  },
  {
    title: 'How much is it?',
    desc: '- Provisional psychologist appointments are $125.<br>- Registered psychologist are $199.90 or $106.35 with a Mental Health Care Plan<br>- Clinical Psychologist appointments are $243.35 or $106.35 with a Mental health Care Plan<br><br>If you have private health insurance you will need to contact them to find out how much they would rebate. Please note, only one rebate type can be claimed per appointment.<br><br>See more information on our <a href="https://www.rechargewellness.com.au/pricing" class="link" tabindex="0">pricing page</a>.'
  },
  {
    title: 'Can I claim with private health insurance?',
    desc: 'Please speak to your private health insurer directly about your level of cover, to see whether you’re eligible to claim some or all of the cost of each psychology session. <br>This claim will be lodged by the client.'
  },
  {
    title: "What happens if I don't like my psychologist?",
    desc: 'We understand that sometimes you might not click with your therapist. If this happens, we will give you the option to choose another psychologist more suited towards your needs.'
  },
  {
    id: 'RechargeCancellationPolicy',
    title: 'What is the cancellation policy?',
    desc: '- Cancellation or rescheduling more than 48 hours prior to the appointment - no charge.<br>- Cancellation or rescheduling 24-48 hours prior to appointment - 25% of the total appointment charge.<br>- Cancellation or request to reschedule within 24 hours prior to appointment - 50% of the total charge.<br>- Failure to attend or same day cancellation &nbsp;- 100% of fee charged.<br><br>We understand that from time to time things can go wrong that are beyond your control and in such emergencies and extenuating circumstances, we will endeavour to respond to your situation as quickly and fairly as possible, provided that you grant us as much notice as possible'
  }
];

interface FAQProps {
  className?: string;
}

const PsychologistFAQ = ({ className }: FAQProps) => {
  return (
    <div className={classnames(styles.container, className)} id="RechargeFAQ">
      <RechargeContentLayout>
        <div className={styles.content}>
          <div className={styles.title}>Frequently Asked Questions</div>
          <div className={styles.desc}>
            Want to learn more about us and our work? <a href={RechargeContactUrl}>Contact us today</a>
          </div>
          <div className={styles.faqWrapper}>
            {faqData.map((obj, index) => (
              <FAQBubble key={index} details={obj} />
            ))}
          </div>
        </div>
      </RechargeContentLayout>
    </div>
  );
};

export default PsychologistFAQ;
