import { useAuth0 } from '@auth0/auth0-react';

import styles from './RechargeSurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';

const RechargeSurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <RechargeContentLayout className={`${styles.container} recharge-theme`}>
      <div className={styles.headerWrapper}>
        <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
      </div>
      <div className={styles.contentWrapper}>
        <SurveyList />
      </div>
    </RechargeContentLayout>
  );
};

export default RechargeSurveyListWrapper;
