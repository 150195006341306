import styles from './ReferralDetail.module.scss';
import ReferralDetailForm from './components/ReferralDetailForm/ReferralDetailForm';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';

const ReferralDetail = () => {
  return (
    <SelectContentLayout className={`${styles.container} select-theme`}>
      <SelectHeader withPadding withMenu homeLink />
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Add Referral Details</div>
        <div className={styles.content}>
          <div className={styles.description}>Complete and share details of your referral with Select Psychology.</div>
          <ReferralDetailForm />
        </div>
      </div>
    </SelectContentLayout>
  );
};

export default ReferralDetail;
