import styles from './PsychologistWelcomeNote.module.scss';

import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import {
  getRoleLabel,
  PRONOUN_OPTIONS
} from 'Recharge/pages/PsychologistListing/components/PsychologistBasicDetails/PsychologistBasicDetails';

interface PractitionerWelcomeNoteProps {
  practitionerDetails: PractitionerDetailsInterface;
}

const PsychologistWelcomeNote = ({ practitionerDetails }: PractitionerWelcomeNoteProps) => (
  <div>
    <div className={styles.greet}>Hi, I'm {practitionerDetails.name}</div>
    <div className={styles.subGreet}>
      {practitionerDetails.pronouns
        ? `${PRONOUN_OPTIONS[practitionerDetails.pronouns as keyof typeof PRONOUN_OPTIONS]}`
        : ''}
      {practitionerDetails.pronouns && practitionerDetails.medicare?.role && ' | '}
      {practitionerDetails.medicare?.role && getRoleLabel(practitionerDetails.medicare?.role)}
    </div>
    <div className={styles.descWrapper}>
      {practitionerDetails.helmControl?.shortDescription && (
        <div className={styles.desc}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.shortDescription
            }}
          />
        </div>
      )}
      {/* not enabled on clinician-ui, when enabled can show this */}
      {/* {practitionerDetails.helmControl?.credentials && (
        <div className={styles.desc}>
          <div className={styles.label}>EXPERIENCE & CREDENTIALS</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.credentials
            }}
          />
        </div>
      )} */}
    </div>
    {practitionerDetails.helmControl.bioPhoto && (
      <img
        className={styles.bioPhoto}
        src={practitionerDetails.helmControl.bioPhoto}
        alt={`${practitionerDetails.name} mid-profile`}
      />
    )}
  </div>
);

export default PsychologistWelcomeNote;
