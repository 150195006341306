import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useGetAccessToken } from './token';

const useRedirectClinician = () => {
  const { isQueryParamToken } = useGetAccessToken(true);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (
      !isQueryParamToken &&
      isAuthenticated &&
      user?.['https://tacklit.com/roles'].includes('clinician') &&
      process.env.REACT_APP_CLINICIAN_DOMAIN
    ) {
      window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
    }
  }, [isAuthenticated, user, isQueryParamToken]);
};

export default useRedirectClinician;
