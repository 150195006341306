import { PatientAddress } from 'utils/http/PatientProfileService/Patient/patient';
import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export interface PortobelloInfoErrorInterface extends PatientAddress {
  firstName: string;
  lastName: string;
  relationship: string;
}

export const formDataValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    relationship: Yup.string().required('Please select relationship'),
    line1: Yup.string().required('Please enter your address'),
    suburb: Yup.string().required(`Please enter your ${t('form.client_address_suburb').toLowerCase()}`),
    state: Yup.string().required(`Please enter your ${t('form.client_address_state').toLowerCase()}`),
    postcode: Yup.string().required('Please enter your postcode'),
    country: Yup.string().required('Please enter your country')
  });
};

export const validationInfoForm = (signUpFormVal: PortobelloInfoErrorInterface, t: TFunction) => {
  const validationErrors = {
    firstName: '',
    lastName: '',
    relationship: '',
    line1: '',
    suburb: '',
    state: '',
    country: '',
    postcode: ''
  };

  try {
    formDataValidationSchema(t).validateSync(signUpFormVal, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return {
    validationErrors,
    cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  };
};
