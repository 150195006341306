import styles from './MedicareWithCoPay.module.scss';
import MedicareCard from 'SomeoneHealth/pages/Booking/components/MedicareCard/MedicareCard';
import { useState } from 'react';
import Select from 'components/Select/Select';
import AddGPReferralForm, {
  GPReferral
} from 'SomeoneHealth/pages/Booking/components/AddGPReferralForm/AddGPReferralForm';
import { MedicareInformation } from 'components/UpdateProfileContent/interface';

export const DEFAULT_GP_OPTION = [
  {
    value: 'no',
    label: 'No'
  },
  {
    value: 'yes',
    label: 'Yes'
  }
];

interface MedicareWithCoPayProps {
  medicareCardValue: MedicareInformation;
  setMedicareCardValue: (value: MedicareInformation) => void;
  isMedicareCardValidating: boolean;
  medicareErrorMessage: MedicareInformation['status'];
  setIsMedicareValueChanged?: (val: boolean) => void;
  token: string;
  haveGPReferralPlan: string;
  setHaveGPReferralPlan: (value: string) => void;
  gpReferralValue: GPReferral;
  setGPReferralValue: (value: GPReferral) => void;
  provideReferralDocuments: string;
  setProvideReferralDocuments: (value: string) => void;
}

const MedicareWithCoPay = ({
  medicareCardValue,
  setMedicareCardValue,
  isMedicareCardValidating,
  medicareErrorMessage,
  setIsMedicareValueChanged,
  token,
  haveGPReferralPlan,
  setHaveGPReferralPlan,
  gpReferralValue,
  setGPReferralValue,
  provideReferralDocuments,
  setProvideReferralDocuments
}: MedicareWithCoPayProps) => {
  const [useSignUpInfoToggle, setUseSignUpInfoToggle] = useState<boolean>(true);

  return (
    <div className={styles.container}>
      <MedicareCard
        medicareCardValue={medicareCardValue}
        setMedicareCardValue={setMedicareCardValue}
        useSignUpInfoToggle={useSignUpInfoToggle}
        setUseSignUpInfoToggle={() => setUseSignUpInfoToggle(!useSignUpInfoToggle)}
        showCheckToggle
        isMedicareCardValidating={isMedicareCardValidating}
        medicareErrorMessage={medicareErrorMessage}
        setIsMedicareValueChanged={setIsMedicareValueChanged}
        className={styles.medicareCardContent}
        introClassName={styles.introClassName}
        verificationStatusClassName={styles.verificationStatusClassName}
        callText={'client'}
      />
      <div className={styles.gpReferralContainer}>
        <div className={styles.gpTitle}>GP Referral</div>
        <div className={styles.gpOption}>
          <Select
            className={styles.customSelect}
            labelClass={styles.selectInputLabel}
            label={'Does the client have a current GP referral and Mental Health Treatment Plan'}
            placeholder={'Select '}
            isSearchable={false}
            name="gpReferral"
            defaultValue={DEFAULT_GP_OPTION.find((option) => option.value === haveGPReferralPlan)}
            options={DEFAULT_GP_OPTION}
            styles={{
              container: (base) => ({
                ...base,
                width: '100%'
              }),
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '30px',
                backgroundColor: 'transparent',
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none',
                borderBottom: '1px solid #d3d8dd',
                '&:hover': {
                  borderColor: '#818e9b'
                }
              }),
              valueContainer: (base) => ({ ...base, paddingLeft: 0 })
            }}
            onChange={(e) => setHaveGPReferralPlan(e?.value || '')}
          />
        </div>
        {haveGPReferralPlan === 'yes' && (
          <AddGPReferralForm
            gpReferralValue={gpReferralValue}
            provideReferralDocuments={provideReferralDocuments}
            setProvideReferralDocuments={setProvideReferralDocuments}
            setGPReferralValue={setGPReferralValue}
            token={token}
            className={styles.gpForm}
            classNameUploadArea={styles.uploadAreaContainer}
            callText={'client'}
          />
        )}
      </div>
    </div>
  );
};

export default MedicareWithCoPay;
