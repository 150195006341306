import classNames from 'classnames';
import SummaryItem from './components/SummaryItem/SummaryItem';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { toWord } from 'utils/generateCamelCase';
import styles from './Summary.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';

interface SummaryProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  isPaymentNotRequired: boolean;
  containerClassName?: string;
  contentClassName?: string;
}

const Summary = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  isPaymentNotRequired,
  containerClassName,
  contentClassName
}: SummaryProps) => {
  const { psychologistName } = useReserveAppointmentData();

  const sortByCreatedAt = (a: AppointmentSlot, b: AppointmentSlot) => {
    const aCreatedAt = moment(a.createdAt);
    const bCreatedAt = moment(b.createdAt);
    return aCreatedAt.isBefore(bCreatedAt) ? 1 : aCreatedAt.isAfter(bCreatedAt) ? -1 : 0;
  };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={styles.header}>
        Here is the summary of the appointments being held for you.
        {isPaymentNotRequired
          ? " Please review the below information and finalise the booking by clicking on the 'Confirm Appointment' button below."
          : ' Please complete the payment in time to avoid missing out.'}
      </div>
      {isReservedAppointmentFetching ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <div className={classNames(styles.content, contentClassName)}>
          {reservedAppointments.sort(sortByCreatedAt).map((appointmentObj, index) => (
            <SummaryItem
              key={index}
              index={index + 1}
              bookingName={appointmentObj.sessionTypeName || ''}
              name={psychologistName || ''}
              startTime={appointmentObj.startTime}
              endTime={appointmentObj.endTime}
              date={appointmentObj?.date || ''}
              deliveryMode={appointmentObj.deliveryType ? toWord(appointmentObj.deliveryType) : ''}
              showTopSeparation={reservedAppointments.length > 1}
              isLast={!isPaymentNotRequired && reservedAppointments.length === index + 1}
              showBottomSeparation
            />
          ))}
          {!isPaymentNotRequired && (
            <div className={styles.paymentContainer}>
              <div className={styles.paymentDetails}>
                <div className={styles.label}>TOTAL TO PAY</div>
                <div className={styles.value}>
                  £ {reservedAppointments.reduce((res, item) => res + (item.rate || 0), 0).toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Summary;
