import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import styles from './AuthWhiteLayout.module.scss';

interface NoAuthLayoutProps {
  children?: any;
  noHeader?: boolean;
  noFooter?: boolean;
  autoHeight?: boolean;
}

const AuthWhiteLayout = ({ children, noHeader, noFooter, autoHeight }: NoAuthLayoutProps) => {
  return autoHeight ? (
    <div className={styles.autoHeightContainer}>
      {!noHeader && <Header />}
      {children}
      {!noFooter && <Footer />}
    </div>
  ) : (
    <div className={styles.container}>
      {!noHeader && <Header />}
      {children}
      {!noFooter && <Footer />}
    </div>
  );
};

export default AuthWhiteLayout;
