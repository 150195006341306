import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { consentFormInterface } from 'interfaces/ConsentForm/consentForm';
import { getConsentFormSignUpV2 } from 'utils/http/DocumentService/ConsentForm/consentForm';

export const useFetchConsentSignUpDetail = (token: string, consentFormId: string) => {
  const navigate = useNavigate();
  const [consentFormData, setConsentFormData] = useState({} as consentFormInterface);
  const [isConsentFormDataLoading, setIsConsentFormDataLoading] = useState(true);

  const fetchConsentDetails = async (token: string) => {
    try {
      setIsConsentFormDataLoading(true);

      const res = await getConsentFormSignUpV2(token, consentFormId);
      if (res.statusCode === 200) {
        const consentData = await res.json();
        const massageData = {
          _id: consentData._id,
          body: consentData.body,
          name: consentData.name,
          clientName: consentData.clientName,
          signature: {
            ...(consentData.signatureRequired.client && {
              client: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            }),
            ...(consentData.signatureRequired.other && {
              other: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            })
          },
          practice: consentData.practice,
          consentFormAssignmentId: consentData.consentFormAssignmentId
        };
        setConsentFormData(massageData as consentFormInterface);
      } else {
        navigate(`/`);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get consent form details' });
    }
    setIsConsentFormDataLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchConsentDetails(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { consentFormData, isConsentFormDataLoading };
};
