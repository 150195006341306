import { generatePath, useNavigate } from 'react-router-dom';
import styles from './SignUpEmergencyContact.module.scss';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode, useEffect } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'Recharge/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { notification } from 'antd';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import RechargeTimer from 'Recharge/components/RechargeTimer/RechargeTimer';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeEmergencyContactForm from './components/RechargeEmergencyContactForm/RechargeEmergencyContactForm';
import { initialContact } from './components/RechargeEmergencyContactForm/constants';
import { getAuMobile } from 'Recharge/components/RechargeCreateProfileForm/constants';
import ErrorMessageWithHelpLink from '../SignUp/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { postKeyContact } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useRechargeToken } from 'Recharge/utils/hooks/useRechargeToken';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';
import { useRedirectLoginClientNextEngageStep } from 'Recharge/utils/hooks/useRedirectLoginClientNextEngageStep';

const SignUpEmergencyContact = () => {
  const navigate = useNavigate();

  const { PRACTITIONER, SIGNUP } = useRechargeDoDOrNonDoDPathGenerator();
  const { token, isInitialised } = useRechargeToken();
  const { accountId, clinicianId, reserveId } = useReserveAppointmentData();
  const { redirectLoginClientNextEngageStep } = useRedirectLoginClientNextEngageStep();

  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  useEffect(() => {
    if (!reserveId || !clinicianId) {
      navigate(PRACTITIONER.LISTING);

      if (clinicianId) {
        const detailsPagePath = generatePath(PRACTITIONER.DETAILS, { clinicianId });
        navigate(detailsPagePath);
      } else {
        navigate(PRACTITIONER.LISTING);
      }
    } else if (isInitialised && !token) {
      navigate(SIGNUP.BASE);
    }
  }, [PRACTITIONER.DETAILS, PRACTITIONER.LISTING, SIGNUP.BASE, clinicianId, isInitialised, navigate, reserveId, token]);

  const onSubmitHandle = async (values: typeof initialContact) => {
    if (!token) {
      console.error('Token is missing');
      return;
    }

    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);

    if (phoneValidate.valid) {
      try {
        await postKeyContact(token, { ...values, tags: [values.relationship, 'Emergency Contact'] });

        await redirectLoginClientNextEngageStep({
          accountId: accountId!,
          reserveId: reserveId!,
          skipEmergencyContactCheck: true
        });

        setIsSubmitting(false);
      } catch (ex) {
        setIsSubmitting(false);
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to add emergency contact'
        });
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
      );
    }
  };

  return fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <RechargeHelmetWrapper title={'Recharge Wellness | Emergency Contact'}>
      <RechargeContentLayout className="recharge-theme">
        <RechargeHeader classNames={styles.header} withPadding withMenu lightLogo />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <RechargeEmergencyContactForm
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
            />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.notice}>
              <div className={styles.noticeMsg}>
                <div>To confirm these appointments you need to complete registration and payment within 15 minutes</div>
              </div>
              <RechargeTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
            </div>
          </div>
        </div>
      </RechargeContentLayout>
      <TacklitFooter darkFooter />
    </RechargeHelmetWrapper>
  );
};

export default SignUpEmergencyContact;
