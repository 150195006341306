import { ChangeEvent } from 'react';
import { useField } from 'formik';

import { Question } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import TextArea from 'components/TextArea/TextArea';

interface FreeTextProps {
  question: Question;
}

const FreeText = ({ question }: FreeTextProps) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(`${question.id}.value`);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      <TextArea
        label={'Tell me more'}
        name={`${question.id}.value`}
        hasError={touched && !!error}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        required
      />
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default FreeText;
