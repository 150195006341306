import moment from 'moment';
import { combineName } from 'utils/general';
import styles from './BasicDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { Invoice } from 'interfaces/Invoice/Invoice';

interface BasicDetailsProps {
  clientRecord: Invoice['clientRecord'];
  clinician: Invoice['clinician'];
  dueDate: Invoice['dueDate'];
  issueDate: Invoice['issueDate'];
  invoiceId: Invoice['invoiceId'];
  practice: Invoice['practice'];
  group?: Invoice['group'];
  serviceDeliveredDates: string[];
}

const BasicDetails = ({
  clientRecord,
  clinician,
  dueDate,
  invoiceId,
  issueDate,
  practice,
  group,
  serviceDeliveredDates
}: BasicDetailsProps) => {
  const clientName =
    clientRecord &&
    combineName(
      clientRecord.recordType === 'child' ? clientRecord.clientProfiles.slice(0, 1) : clientRecord.clientProfiles,
      clientRecord.recordType === 'couple'
    );
  const recipientName = group ? group.name : clientName;
  const [t] = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        {practice && (
          <div className={styles.practice}>
            {practice.logo && <img className={styles.image} src={practice.logo} alt="Practice logo" />}
            <div className={styles.practiceDetails}>
              <span className={styles.title}>{practice.name}</span>
              <span className={styles.description}>{practice.address}</span>
              <span className={styles.description}>{practice.mobileNumber}</span>
            </div>
          </div>
        )}
        {clinician && (
          <div className={styles.clinician}>
            {clinician.avatar && <img className={styles.image} src={clinician.avatar} alt="Clinician avatar" />}
            <div className={styles.clinicianDetails}>
              <span className={styles.title}>{clinician.name}</span>
              <span className={styles.description}>{clinician.mobileNumber}</span>
              <span className={styles.description}>{clinician.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.childRow}>
          <div className={styles.item}>
            <span className={styles.label}>{group ? t('label.group_name') : t('label.client_name')}:</span>
            <span className={styles.description}>{recipientName || '-'}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>{t('label.invoice_id')}:</span>
            <span className={styles.description}>{invoiceId || '-'}</span>
          </div>
        </div>
        <div className={styles.childRow}>
          {serviceDeliveredDates.length > 0 && (
            <div className={styles.item}>
              <span className={styles.label}>Service delivered date:</span>
              <div className={styles.description}>
                {serviceDeliveredDates.map((date) => moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY')).join(', ')}
              </div>
            </div>
          )}
          <div className={styles.item}>
            <span className={styles.label}>{t('label.date_of_issue')}:</span>
            <span className={styles.description}>{moment(issueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
          </div>
          {!IS_HELM_APP && (
            <div className={styles.item}>
              <span className={styles.label}>{t('label.due_date')}:</span>
              <span className={styles.description}>{moment(dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
