import classNames from 'classnames';
import styles from './VerificationStatus.module.scss';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { MedicareInformation, MedicareStatus } from 'components/UpdateProfileContent/interface';

interface VerificationStatusProps {
  status: MedicareInformation['status'];
  isLoading: boolean;
  hideDesc?: boolean;
  className?: string;
}

const getMedicareStatus = (status: MedicareStatus, hideDesc?: boolean) => {
  switch (status.code) {
    // verified
    case 0:
      return (
        <div className={styles.validStatus}>
          <i className={`material-icons ${styles.icon}`}>check_circle</i>
          Medicare details valid
        </div>
      );
    case 9626:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.icon}`}>check_circle</i>
            Medicare details valid
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );

    // partially verified, with some differences that have been saved in the client record
    case 8015:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your Medicare card number, first name and last name match what is printed on your card.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8016:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your IRN number and try again.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8017:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your first name matches what is printed on your card.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8018:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your date of birth and try again.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8019:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your Medicare card number and first name match what is printed on your card.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8020:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your Medicare card number and IRN and try again.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 8021:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconWarning}`}>warning</i>
            Please check your Medicare card number and date of birth and try again.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );

    // verification
    case 9633:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please ensure you are using your latest Medicare card number and try again
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 9649:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Your Medicare card can’t be verified. Please check your details match exactly what is printed on your card.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 9650:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Please check your Medicare card number is correct and your name is spelt the exactly the same as it printed
            on your Medicare card.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    case 9675:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Your Medicare card has expired.
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
    default:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            Medicare details invalid
          </div>
          {!hideDesc && status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
  }
};

const getDvaStatus = (status: MedicareStatus) => {
  switch (status.code) {
    // verified
    case 0:
      return (
        <div className={styles.status}>
          <i className={`material-icons ${styles.icon}`}>check_circle</i>
          DVA details valid
        </div>
      );

    // verification error
    case 9650:
    default:
      return (
        <>
          <div className={styles.status}>
            <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
            DVA details invalid
          </div>
          {status.message && <div className={styles.description}>{status.message}</div>}
        </>
      );
  }
};

const VerificationStatus = ({ status, isLoading, hideDesc, className }: VerificationStatusProps) => {
  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingStatus}>
          <div className={styles.loading}>
            <LoadingCircle />
          </div>
          Checking Medicare details...
        </div>
      </div>
    );
  }

  if (status) {
    return (
      <div className={classNames(styles.container, className)}>
        {status.medicare && getMedicareStatus(status.medicare, hideDesc)}
        {status.dva && getDvaStatus(status.dva)}
      </div>
    );
  }

  // if no status somehow
  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <i className={`material-icons ${styles.iconInvalid}`}>cancel</i>
        Medicare details invalid
      </div>
    </div>
  );
};

export default VerificationStatus;
