import styles from './PortobelloLoginWrapper.module.scss';

interface PortobelloLoginWrapperProps {
  children?: any;
}

const PortobelloLoginWrapper = ({ children }: PortobelloLoginWrapperProps) => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
  </div>
);

export default PortobelloLoginWrapper;
