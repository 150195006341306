import styles from './StepTitle.module.scss';

interface StepTitleProps {
  no: string;
  title: string;
}

const StepTitle = ({ no, title }: StepTitleProps) => (
  <div className={styles.container}>
    <div className={styles.numberBubble}>{no}</div>
    <div className={styles.stepTitle}>{title}</div>
  </div>
);

export default StepTitle;
