import { useParams } from 'react-router-dom';
import { BazaarToken } from '../../../../utils/hooks/useBazaarTokens';
import Assessment from '../Assessment/Assessment';
import styles from './Form.module.scss';

const Form = ({ token, resizeParentFrame }: { token: BazaarToken; resizeParentFrame: () => void }) => {
  const params: { type?: string } = useParams();
  const assessments = [
    {
      code: 'PHQ-9',
      adHocAssessmentId: token && token.phq9AssignmentId
    },
    {
      code: 'GAD-7',
      adHocAssessmentId: token && token.gad7AssignmentId
    }
  ];

  let assessmentMap;
  if (params.type === 'phq9') {
    assessmentMap = [assessments[0]];
  } else if (params.type === 'gad7') {
    assessmentMap = [assessments[1]];
  } else {
    assessmentMap = assessments;
  }

  return (
    <>
      <div className={styles.body}>
        {assessmentMap.map((assessment) => (
          <Assessment
            code={assessment.code}
            resize={resizeParentFrame}
            adHocAssessmentId={assessment.adHocAssessmentId || ''}
            clinicianId={(token && token.clinicianId) || ''}
            authToken={(token && token.authToken) || ''}
          />
        ))}
      </div>
    </>
  );
};

export default Form;
