import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import {
  FIT_LOCATION_LIST_LABELS,
  LocationList,
  MENTAL_HEALTH_LIST_LABELS,
  MentalHealthList
} from 'Select/interfaces/mentalHealth';

import styles from './FilterDropdownWrapper.module.scss';
import FilterDropdownMultiSelect from 'components/FilterDropdown/components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import classnames from 'classnames';

const locationList = Object.values(LocationList).map((item) => ({
  id: item,
  label: FIT_LOCATION_LIST_LABELS[item]
}));

const mentalHealthList = Object.values(MentalHealthList).map((item) => ({
  id: item,
  label: MENTAL_HEALTH_LIST_LABELS[item]
}));

interface FilterDropdownWrapperProps {
  classNames?: string;
  locationValue?: listInterface[];
  mentalHealthConcernValue?: listInterface[];
  onChangeLocationValue: (value: listInterface) => void;
  onChangeMentalHealthConcernValue: (value: listInterface) => void;
}

const FilterDropdownWrapper = ({
  classNames,
  locationValue,
  mentalHealthConcernValue,
  onChangeLocationValue,
  onChangeMentalHealthConcernValue
}: FilterDropdownWrapperProps) => (
  <div className={classnames(styles.container, classNames)}>
    <div className={styles.label}>SHOWING MATCHES FOR</div>
    <FilterDropdownMultiSelect
      id={'mentalValue'}
      label={'Issue I am seeking help for'}
      listData={mentalHealthList}
      onChangeValue={onChangeMentalHealthConcernValue}
      selectedMultipleValue={mentalHealthConcernValue || []}
      className={styles.mentalHealthConcern}
      withSearch
      ableToSearchAll
    />
    <div className={styles.label}>IN</div>
    <div className={styles.secondLayerFilterWrapper}>
      <FilterDropdownMultiSelect
        id={'location'}
        className={styles.location}
        label={'Location'}
        listData={locationList}
        selectedMultipleValue={locationValue || []}
        onChangeValue={onChangeLocationValue}
      />
    </div>
  </div>
);

export default FilterDropdownWrapper;
