import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SMP, SMPType, SMPUpdateAction, SMPUpdatePayload } from 'pages/SMP/interfaces/smp';
import { downloadPrintableSmp, getSmpDetails, getSmpList, postSmp, putSmp } from 'utils/http/DocumentService/smp';
import { useGetAccessToken } from './token';

export const useSmpList = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const [smpList, setSmpList] = useState<SMP[]>([]);
  const [isSMPListLoading, setIsSMPListLoading] = useState(true);

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'];

  useEffect(() => {
    const fetchSmpList = async () => {
      setIsSMPListLoading(true);

      try {
        const response = await getSmpList(token, clientRecordId, clientProfileId);
        const smpListObj = await response.json();

        setSmpList(smpListObj);
      } catch (ex) {
        notification.error({
          message: 'Failed to retrieve SMP list. Please try again later.'
        });
      } finally {
        setIsSMPListLoading(false);
      }
    };

    if (token && clientProfileId && clientRecordId) {
      fetchSmpList();
    }
  }, [clientProfileId, clientRecordId, token]);

  return { smpList, isSMPListLoading };
};

export const useSmpDetails = (smpId?: string) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [smpDetails, setSmpDetails] = useState<SMP>();
  const [isLoading, setIsLoading] = useState(true);

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'];

  const fetchSmpDetails = useCallback(async () => {
    if (smpId && clientRecordId && clientProfileId) {
      setIsLoading(true);

      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        if (token) {
          const response = await getSmpDetails(token, clientRecordId, clientProfileId, smpId);
          const smpObj = await response.json();

          setSmpDetails(smpObj);
        }
      } catch (ex) {
        navigate('/404?returnTo=home');
      } finally {
        setIsLoading(false);
      }
    }
  }, [clientProfileId, clientRecordId, getAccessTokenSilently, navigate, smpId]);

  useEffect(() => {
    fetchSmpDetails();
  }, [fetchSmpDetails]);

  return { smpDetails, refetchSmpDetails: fetchSmpDetails, isLoading };
};

export const useNewSmp = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'];

  const createSmp = async ({ blankPlan, type }: { blankPlan?: boolean; type: SMPType }) => {
    setIsLoading(true);
    let newSmp = null;

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const response = await postSmp({
        token,
        clientRecordId,
        clientProfileId,
        blankPlan,
        type
      });
      newSmp = await response.json();
    } catch (ex) {
      notification.error({
        message: 'Failed to create a new SMP. Please try again later.'
      });
    }

    setIsLoading(false);
    return newSmp;
  };

  return { createSmp, isLoading };
};

export const useUpdateSmp = (smpId?: string) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'];

  const updateSmp = async (payload: SMPUpdatePayload) => {
    if (smpId) {
      setIsLoading(true);
      let newSmp = null;

      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        await putSmp({ token, clientRecordId, clientProfileId, payload, smpId });

        notification.success({
          message: payload.action === SMPUpdateAction.save ? 'Saved successfully!' : 'Submitted sucessfully!'
        });
      } catch (ex) {
        notification.error({
          message: 'Failed to update SMP. Please try again later.'
        });
      }

      setIsLoading(false);
      return newSmp;
    }
  };

  return { updateSmp, isLoading };
};

export const useDownloadSmp = (smpId?: string) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
  const clientProfileId = user?.['https://tacklit.com/clientProfileId'];

  const downloadSmp = async () => {
    if (smpId && clientProfileId && clientRecordId) {
      setIsLoading(true);

      try {
        const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_API_AUDIENCE });

        if (token) {
          return downloadPrintableSmp({ token, smpId, clientRecordId, clientProfileId });
        }
      } catch (ex) {
        notification.error({
          message: 'Failed to download SMP. Kindly contact us at support@tacklit.com to resolve this.'
        });
        console.error(ex);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return { downloadSmp, isLoading };
};
