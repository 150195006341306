import Card, { CardProps } from './Card/Card';
import styles from './SomeoneHealthHowItWorks.module.scss';
import dottedArrow from './../../assets/images/landingPage/dotArrow.png';
import folderIcon from '../../assets/images/landingPage/Folder.svg';
import messageIcon from '../../assets/images/landingPage/Message.svg';
import searchIcon from '../../assets/images/landingPage/Search.svg';

interface SomeoneHealthHowItWorksProps {
  header?: string;
  subHeader?: string;
}

const SomeoneHealthHowItWorks = ({ header, subHeader }: SomeoneHealthHowItWorksProps) => {
  const cardInfo: CardProps[] = [
    {
      icon: <img src={searchIcon} alt="Search Icon" />,
      heading: 'Find the right psychologist',
      subheading:
        'All someone.health psychologists have had their qualifications and registrations verified and have been individually interviewed by the someone.health team to ensure they deliver high standards of practice.'
    },
    {
      icon: <img src={folderIcon} alt="Folder Icon" />,
      heading: 'Book your session',
      subheading:
        'Using our simple, power filters you can find a psychologist to work with at a time that suits you. You register your account, provide us with your Medicare details and you are all set.'
    },
    {
      icon: <img src={messageIcon} alt="Message Icon" />,
      heading: 'Get Support',
      subheading:
        'Your first two appointments are fully bulk billed, allowing you to get started with no out-of-pocket costs. Our platform ensures your private one-on-one counselling is secure and your data is kept confidential.',
      isBolded: true
    }
  ];

  const generateCards = () => {
    return cardInfo.map((item, index) => (
      <Card
        key={`card_${index}`}
        icon={item.icon}
        heading={item.heading}
        subheading={item.subheading}
        isBolded={item.isBolded}
      />
    ));
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>
          {header || `We've helped over 35,000 Australians improve their mental health`}
        </div>
        <div className={styles.subheading}>
          {subHeader ||
            `We want to connect you with the right therapist for your needs, so you can take control of your mental
          wellbeing and become a healthier you with more productive relationships.`}
        </div>
      </div>
      <div className={styles.cardWrapper}>{generateCards()}</div>
      <div className={styles.arrow}>
        <img className={styles.upArrow} src={dottedArrow} alt="up dotted arrow" />
        <img className={styles.downArrow} src={dottedArrow} alt="down dotted arrow" />
      </div>
    </>
  );
};

export default SomeoneHealthHowItWorks;
