import styles from './BodyTextWidget.module.scss';
import 'react-quill/dist/quill.snow.css';

interface BodyTextWidgetProps {
  data: any;
}

const BodyTextWidget = ({ data }: BodyTextWidgetProps) => {
  return (
    <div className={styles.container}>
      <div dangerouslySetInnerHTML={{ __html: data.title }} />
    </div>
  );
};

export default BodyTextWidget;
