import { useMemo, useState } from 'react';

import styles from './PasswordRules.module.scss';
import classnames from 'classnames';
import PasswordStrengthBar from 'react-password-strength-bar';

interface PasswordRulesProps {
  password: string;
  onChangePasswordScore: (val: number) => void;
}

const PasswordRules = ({ password, onChangePasswordScore }: PasswordRulesProps) => {
  const [passwordScore, setPasswordScore] = useState(0);

  const { passwordLengthValid, passwordIncludesLowercaseUppercaseNumber, passwordIncludesSpecialCharacters } =
    useMemo(() => {
      const passwordLengthValid = password.length >= 8;
      const passwordIncludesLowercaseUppercaseNumber = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,}/g.test(password);
      const passwordIncludesSpecialCharacters = /.*[!@#$%^&*-]+.*/g.test(password);

      return { passwordLengthValid, passwordIncludesLowercaseUppercaseNumber, passwordIncludesSpecialCharacters };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

  const handleUpdatePasswordStrength = (passwordScore: number) => {
    setPasswordScore(passwordScore);
    onChangePasswordScore(passwordScore);
  };

  const strongPassword =
    passwordScore >= 4 &&
    passwordLengthValid &&
    passwordIncludesLowercaseUppercaseNumber &&
    passwordIncludesSpecialCharacters;

  return (
    <div className={styles.container}>
      <div className={passwordIncludesLowercaseUppercaseNumber ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{passwordIncludesLowercaseUppercaseNumber ? 'done' : ''}</i>
        <span className={styles.label}>Contains uppercase characters, lowercase characters, and numbers</span>
      </div>
      <div className={passwordIncludesSpecialCharacters ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{passwordIncludesSpecialCharacters ? 'done' : ''}</i>
        <span className={styles.label}>Contains special characters (!@#$%^&*-)</span>
      </div>
      <div className={strongPassword ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{strongPassword ? 'done' : ''}</i>
        <div className={styles.passwordStrengthWrapper}>
          <div className={styles.label}>
            Password must be <strong>STRONG</strong>
          </div>
          <div className={styles.passwordStrengthBox}>
            <div className={classnames(styles.passwordStrengthIndicatorContainer, strongPassword && styles.valid)}>
              <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 1 && styles.active)} />
              <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 2 && styles.active)} />
              <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 3 && styles.active)} />
              <div className={classnames(styles.passwordStrengthIndicator, passwordScore >= 4 && styles.active)} />
            </div>
            <PasswordStrengthBar
              className={styles.passwordStrengthBar}
              minLength={8}
              password={password}
              scoreWordStyle={{
                color: '#343434',
                fontWeight: 600,
                fontSize: '13px',
                marginTop: 0,
                textTransform: 'capitalize'
              }}
              onChangeScore={handleUpdatePasswordStrength}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRules;
