import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';

import styles from './PractitionerFAQ.module.scss';
import FAQBubble from './components/FAQBubble/FAQBubble';
import classnames from 'classnames';

interface FAQProps {
  data: {
    title: string;
    desc?: string;
    faqData: {
      title: string;
      desc: string;
    }[];
  };
  className?: string;
  id: string;
}

const PractitionerFAQ = ({ data, className, id }: FAQProps) => {
  return (
    <div className={classnames(styles.container, className)} id={id}>
      <HelmContentLayout>
        <div className={styles.content}>
          <div className={styles.title}>{data.title}</div>
          {data.desc && <div className={styles.desc} dangerouslySetInnerHTML={{ __html: data.desc }} />}
          {data.faqData.length > 0 && (
            <div className={styles.faqWrapper}>
              {data.faqData.map((obj, index) => (
                <FAQBubble key={index} details={obj} />
              ))}
            </div>
          )}
        </div>
      </HelmContentLayout>
    </div>
  );
};

export default PractitionerFAQ;
