import { useField } from 'formik';
import { Question } from 'interfaces/Assessment/OnboardingAssessment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';

interface PhoneNumberProps {
  question: Question;
}

const PhoneNumber = ({ question }: PhoneNumberProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField(`${question.id}.value`);

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <>
      <MaterialPhoneInput
        id={`phoneNumber`}
        label={''}
        onChange={handleChange}
        value={value}
        isError={touched && !!error}
      />
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default PhoneNumber;
