import AssessmentWidget from './components/WidgetComponents/AssessmentWidget/AssessmentWidget';
import BarLineChartWidget from './components/WidgetComponents/BarLineChartWidget/BarLineChartWidget';
import BodyTextWidget from './components/WidgetComponents/BodyTextWidget/BodyTextWidget';
import FootNotesTextWidget from './components/WidgetComponents/FootNotesTextWidget/FootNotesTextWidget';
import HeadingWidget from './components/WidgetComponents/HeadingWidget/HeadingWidget';
import InsightsChartWidget from './components/WidgetComponents/InsightsChartWidget/InsightsChartWidget';
import JournalCommentWidget from './components/WidgetComponents/JournalCommentWidget/JournalCommentWidget';
import NotesWidget from './components/WidgetComponents/NotesWidget/NotesWidget';
import { WidgetPreviewWrapper } from './components/WidgetPreviewWrapper/WidgetPreviewWrapper';
import ClientProfileWidget from './components/WidgetComponents/ClientProfileWidget/ClientProfileWidget';
import QuestionWidget from './components/WidgetComponents/QuestionWidget/QuestionWidget';
import { ReportWidgetType } from 'interfaces/Report/reportTemplate';

import styles from './ReportContentBody.module.scss';

interface DndPreviewSectionProps {
  reportItems: any;
  dndDimension: any;
}

const ReportContentBody = ({ reportItems, dndDimension }: DndPreviewSectionProps) => {
  return (
    <div>
      <div
        className={styles.container}
        style={{
          height: dndDimension.height || 900,
          width: dndDimension.width || 840
        }}
      >
        {reportItems.map((itemObj: any, index: any) => {
          const widgetType = itemObj.details.type;
          const leftPos = itemObj.position.left;
          const topPos = itemObj.position.top;

          if (widgetType === ReportWidgetType.HeadingWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <HeadingWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.BodyTextWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <BodyTextWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.FootNotesTextWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <FootNotesTextWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.JournalCommentWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <JournalCommentWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.InsightsChartWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <InsightsChartWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.BarLineChartWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <BarLineChartWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.AssessmentWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <AssessmentWidget assessmentData={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.ClientProfileWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <ClientProfileWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          if (widgetType === ReportWidgetType.CaseNoteQuestionWidget) {
            return (
              <WidgetPreviewWrapper
                key={index}
                position={{
                  top: topPos,
                  left: leftPos
                }}
                dimensions={{
                  height: itemObj.dimensions.height,
                  width: itemObj.dimensions.width,
                  minWidth: 200,
                  maxWidth: 840
                }}
              >
                <QuestionWidget data={itemObj.details} />
              </WidgetPreviewWrapper>
            );
          }

          return (
            <WidgetPreviewWrapper
              key={index}
              position={{
                top: topPos,
                left: leftPos
              }}
              dimensions={{
                height: itemObj.dimensions.height,
                width: itemObj.dimensions.width,
                minWidth: 200,
                maxWidth: 840
              }}
            >
              <NotesWidget data={itemObj.details} />
            </WidgetPreviewWrapper>
          );
        })}
      </div>
    </div>
  );
};

export default ReportContentBody;
