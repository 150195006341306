import styles from './NoAvailableMessageBox.module.scss';
import Button from '../Button/Button';
import HelmContentLayout from '../HelmContentLayout/HelmContentLayout';
import classnames from 'classnames';

interface NoAvailableMessageBoxProps {
  title: string;
  desc: string | JSX.Element;
  buttonLabel: string;
  onClick: () => void;
  titleClassName?: string;
  buttonClassName?: string;
}

const NoAvailableMessageBox = ({
  title,
  desc,
  buttonLabel,
  onClick,
  titleClassName,
  buttonClassName
}: NoAvailableMessageBoxProps) => (
  <HelmContentLayout>
    <div className={styles.container}>
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.buttonWrapper}>
        <Button className={classnames(styles.button, buttonClassName)} onClick={onClick}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  </HelmContentLayout>
);

export default NoAvailableMessageBox;
