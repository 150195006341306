import { OtherInstructions, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';

interface ReserveAppointmentCookies {
  reserveId?: string;
  clinicianId?: string;
  accountId?: string;
  theme?: string;
  sid?: string;
  appointmentTypeInfo?: {
    name: string;
    description: string;
    rate: number;
    isAdvisory: boolean;
    deliveryType: string;
    otherInstructions?: OtherInstructions;
    timeSlot: TimeSlotsWithDateInterface[];
    sessionTypeId: string;
  };
  helmSupportStaffProfile?: {
    name: string;
    avatar: string;
  };
  programId?: string;
}

export const useReserveAppointmentData = () => {
  const helmSupportStaffProfile = {
    avatar: 'https://storage.googleapis.com/au-clinician-avatars/auth0%7C62a9ac91f788a2c31ab0dc70/appointment.png',
    name: 'Client Services Team'
  };
  const data: ReserveAppointmentCookies | undefined = JSON.parse(
    localStorage.getItem('reservedAppointmentData') || '{}'
  );

  return {
    ...data,
    helmSupportStaffProfile
  };
};

export const useSetReserveAppointmentData = () => {
  const setReserveAppointmentData = (reserveAppointment: ReserveAppointmentCookies) =>
    localStorage.setItem('reservedAppointmentData', JSON.stringify(reserveAppointment));
  return { setReserveAppointmentData };
};
