import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import SMPFooter from 'components/SMP/SMPFooter/SMPFooter';
import SMPHeader from 'components/SMP/SMPHeader/SMPHeader';
import styles from './SMPLayoutPdf.module.scss';

type SMPPdfPageLayoutProps = {
  style?: CSSProperties | undefined;
  header?: boolean;
  footer?: boolean;
  children: ReactNode;
  className?: string;
  submittedBy?: string;
};

const SMPPdfPageLayout = ({
  style,
  header,
  footer,
  children,
  className,
  submittedBy
}: SMPPdfPageLayoutProps): JSX.Element => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      {header && <SMPHeader />}
      {children}
      {footer && <SMPFooter submittedBy={submittedBy} />}
    </div>
  );
};

export default SMPPdfPageLayout;
