import { PortobelloSupportEmail } from 'Portobello/utils/env/PortobelloEnv';
import styles from './ErrorMessageWithHelpLink.module.scss';

interface ErrorMessageWithHelpLinkProps {
  errorMessage: string;
  email?: string;
  showEmailOnly?: boolean;
}

const ErrorMessageWithHelpLink = ({
  errorMessage,
  showEmailOnly,
  email = PortobelloSupportEmail
}: ErrorMessageWithHelpLinkProps) => {
  const supportEmail = email || PortobelloSupportEmail;

  return (
    <>
      {errorMessage}{' '}
      {showEmailOnly ? (
        <a className={styles.link} href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      ) : (
        <>
          Otherwise{' '}
          <a className={styles.link} href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
            contact our support team
          </a>{' '}
          for assistance.
        </>
      )}{' '}
    </>
  );
};

export default ErrorMessageWithHelpLink;
