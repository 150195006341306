import { Route, Routes } from 'react-router-dom';
import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import AdHocConsentForm from 'pages/ConsentForm/AdHocConsentForm/AdHocConsentForm';
import ConsentFormOnboarding from 'pages/ConsentForm/ConsentFormOnboarding/ConsentFormOnboarding';
import OnboardingFacilitated from 'pages/OnboardingAssessment/OnboardingFacilitated/OnboardingFacilitated';
import { OnboardingNextFacilitated } from 'pages/OnboardingAssessment/OnboardingFacilitated/OnboardingNextFacilitated';

const FacilitatedRoutes = ({ match }: any) => {
  const initPath = match && match.path && match.path !== '/' ? match.path : '';
  return (
    <Routes>
      <Route path={`${initPath}/consent/:consentId`} element={<AdHocConsentForm />} />
      <Route path={`${initPath}/consent`} element={<ConsentFormOnboarding />} />
      <Route path={`${initPath}/onboarding`} element={<OnboardingFacilitated />} />
      <Route path={`${initPath}/onboarding/next`} element={<OnboardingNextFacilitated />} />
      <Route path={'*'} element={<RedirectTo404 />} />
    </Routes>
  );
};

export default FacilitatedRoutes;
