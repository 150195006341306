import Button, { ButtonProps } from 'components/Button/Button';

import styles from './SubmitButton.module.scss';
import classNames from 'classnames';
import { CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrand';

interface SubmitButtonProps extends ButtonProps {
  floatingBtn?: boolean;
}

const SubmitButton = ({ floatingBtn, ...props }: SubmitButtonProps) => {
  return (
    <Button
      {...props}
      className={classNames(floatingBtn ? styles.floatingBtn : styles.button, styles[CLIENT_BRAND_LOWER_CASE])}
    />
  );
};

export default SubmitButton;
