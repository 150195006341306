import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './TokenAccess.module.scss';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { useState } from 'react';
import { CODE_DEFAULT_VALUE, PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthPartnerRoutesGenerator } from 'SomeoneHealth/PartnerReferral/utils/Path/SomeoneHealthPartnerRoutesGenerator';
import { someoneHealthEnvironment } from '../../../utils/SomeoneHealthEnv/SomeoneHealthEnv';
import classNames from 'classnames';
import { PARTNER_CODE } from './partnerCode';

const TokenAccess = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<typeof CODE_DEFAULT_VALUE>(CODE_DEFAULT_VALUE);
  const [error, setError] = useState<string>('');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [signInBtnStatus, setSignInBtnStatus] = useState<ButtonStatusType>('');
  const { SomeoneHealthURL, VTPHNAEmail } = someoneHealthEnvironment();
  const { PRACTITIONER } = useSomeoneHealthPartnerRoutesGenerator();

  const onSignIn = () => {
    const accessToken = code.join('');
    if (PARTNER_CODE.some((obj) => obj.accessToken === accessToken)) {
      setSignInBtnStatus('active');
      const getPartnerCode = PARTNER_CODE.find((obj) => obj.accessToken === accessToken);
      if (getPartnerCode?.partnerCode) {
        setSignInBtnStatus('finished');
        localStorage.setItem(PARTNER_CODE_STORAGE, getPartnerCode?.partnerCode);
        setError('');
        setIsTouched(false);
        setTimeout(() => {
          setSignInBtnStatus('');
          navigate(PRACTITIONER.LISTING);
        }, 3000);
      }
    } else {
      setSignInBtnStatus('');
      setError('Invalid Code.');
      setIsTouched(true);
    }
  };

  const handleChangeCode = (code: typeof CODE_DEFAULT_VALUE) => {
    setError('');
    setIsTouched(false);
    setCode(code);
  };

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.background} />
      <SomeoneHealthHeader pinkLogo withPadding noLogin />
      <SomeoneHealthHelmetWrapper title={'someone.health - Sign Up'}>
        <SomeoneHealthContentLayout>
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <div className={styles.formContent}>
                <div className={styles.welcomeText}>Welcome back</div>
                <div className={styles.text}>
                  This is the someone.health private referral channel <br />
                  Please enter your organisations access code
                </div>
                <TwoFAInput
                  isText
                  error={error}
                  value={code}
                  inputContainerClassName={styles.inputContainerClassName}
                  inputClassName={classNames(styles.inputClassName, error && styles.inputError)}
                  isTouched={isTouched}
                  onChangeValue={handleChangeCode}
                />
                <a className={styles.link} href={`mailto:${VTPHNAEmail}`}>
                  Forgot code
                </a>
                <ButtonSH status={signInBtnStatus} fullWidth className={styles.signinButton} onClick={onSignIn}>
                  Sign in
                </ButtonSH>
                <div className={styles.smallText}>
                  Organisation not registered yet?{' '}
                  <a className={styles.link} href={`mailto:${VTPHNAEmail}`}>
                    Contact us today
                  </a>
                </div>
                <div className={styles.smallText}>
                  Looking for our direct access site?{' '}
                  <a className={styles.link} href={SomeoneHealthURL}>
                    Please visit someone.health
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.infoBox}>
                <div className={styles.welcomeText}>someone.health psychologists</div>
                <label>Our Psychologists are available for video and telephone therapy 7 days a week.</label>
              </div>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      </SomeoneHealthHelmetWrapper>
    </div>
  );
};

export default TokenAccess;
