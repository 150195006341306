import { Route, Routes } from 'react-router-dom';
import appRoute from '../../components/AppRoute/AppRoute';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import EaseWrapper from '../../Ease/components/EaseWrapper/EaseWrapper';
import EaseLoginWrapper from '../../Ease/components/EaseLoginWrapper/EaseLoginWrapper';
import PracticeBookingPage from '../../Ease/pages/PracticeBookingPage/PracticeBookingPage';
import SignUp from 'Ease/pages/SignUp/SignUp';
import SignUpBookingConfirmation from 'Ease/pages/SignUpBookingConfirmation/SignUpBookingConfirmation';
import UpdateProfile from 'Ease/pages/UpdateProfile/UpdateProfile';
import ReferralDetail from 'Ease/pages/ReferralDetail/ReferralDetail';
import AppointmentsOverview from 'Ease/pages/AppointmentsOverview/AppointmentsOverview';
import SelectAvatar from 'Ease/pages/SelectAvatar/SelectAvatar';
import PsychometricList from '../../pages/Psychometric/PsychometricList/PsychometricList';
import Onboarding from '../../pages/OnboardingAssessment/Onboarding/Onboarding';
import PatientCheckIn from '../../pages/PatientCheckIn/PatientCheckIn';
import ConsentFormSignUp from 'Ease/pages/ConsentFormSignUp/ConsentFormSignUp';
import PatientAdHocAssessment from '../../pages/PatientAdHocAssessment/PatientAdHocAssessment';
import InvoiceViewPage from '../../pages/InvoiceViewPage/InvoiceViewPage';
import ReportViewPage from '../../pages/ReportViewPage/ReportViewPage';
import ConsentListWrapper from 'Ease/pages/ConsentListWrapper/ConsentListWrapper';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import SignUpCompleteBooking from 'Ease/pages/SignUpCompleteBooking/SignUpCompleteBooking';
import { EaseUserContextProvider } from 'Ease/contexts/EaseUserContextProvider';
import HomePage from 'Ease/pages/HomePage/HomePage';
import SurveyListWrapper from 'Ease/pages/Survey/SurveyListWrapper/SurveyListWrapper';
import SurveyQuestionWrapper from 'Ease/pages/Survey/SurveyQuestionWrapper/SurveyQuestionWrapper';

const EaseRoutes = () => {
  const {
    BOOKING,
    SIGNUP,
    AVATAR,
    HOME,
    UPDATE_PROFILE,
    APPOINTMENT_OVERVIEW,
    REFERRAL,
    ONBOARDING,
    CONSENT,
    PSYCHOMETRIC,
    SURVEY,
    CHECKIN,
    INVOICE,
    REPORT
  } = useEaseRoutesGenerator();

  return (
    <EaseUserContextProvider>
      <Routes>
        {appRoute({
          path: BOOKING.BASE,
          component: PracticeBookingPage,
          layout: EaseWrapper
        })}
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: EaseWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: SignUpBookingConfirmation, layout: EaseWrapper })}
        {appRoute({ path: SIGNUP.WELCOME_BOOKING, component: SignUpCompleteBooking, layout: EaseWrapper })}
        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: EaseLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: EaseLoginWrapper
        })}
        {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: EaseLoginWrapper
        })}
        {appRoute({
          path: REPORT.VIEW,
          component: ReportViewPage,
          layout: EaseLoginWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: SurveyListWrapper,
          layout: EaseLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: SurveyQuestionWrapper,
          layout: EaseLoginWrapper
        })}

        <Route element={<AuthLayout />}>
          {appRoute({ path: HOME, component: HomePage, layout: EaseLoginWrapper })}
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: EaseLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: EaseLoginWrapper })}
          {appRoute({
            path: APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: EaseLoginWrapper
          })}
          {appRoute({ path: REFERRAL, component: ReferralDetail, layout: EaseLoginWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: EaseLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: EaseLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: EaseLoginWrapper })}
          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: EaseLoginWrapper })}
        </Route>
      </Routes>
    </EaseUserContextProvider>
  );
};

export default EaseRoutes;
