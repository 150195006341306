import { Link } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { scrollToView } from 'utils/scrollToView';
import styles from './SomeoneHealthFindRightFit.module.scss';

interface LinkProps {
  linkName: string;
  linkUrl: string;
}

interface themeProps {
  name?: string;
  links?: LinkProps[];
}

const SomeoneHealthFindRightFit = () => {
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  const themeArray: themeProps[] = [
    {
      name: 'Age range specialised',
      links: [
        { linkName: 'Adults', linkUrl: `${PRACTITIONER.LISTING}/adults` },
        { linkName: 'Older Adults (65+)', linkUrl: `${PRACTITIONER.LISTING}/olderAdults` },
        { linkName: 'Children (<12)', linkUrl: `${PRACTITIONER.LISTING}/children` },
        { linkName: 'Teenagers (12-16)', linkUrl: `${PRACTITIONER.LISTING}/teenagers` },
        { linkName: 'Youth (17-25)', linkUrl: `${PRACTITIONER.LISTING}/youth` }
      ]
    },
    {
      name: 'Gender identity specialised',
      links: [
        { linkName: 'Woman', linkUrl: `${PRACTITIONER.LISTING}/woman` },
        { linkName: 'Man', linkUrl: `${PRACTITIONER.LISTING}/man` },
        { linkName: 'Transgender Woman', linkUrl: `${PRACTITIONER.LISTING}/transgenderWoman` },
        { linkName: 'Transgender Man', linkUrl: `${PRACTITIONER.LISTING}/transgenderMan` },
        { linkName: 'Non-Binary', linkUrl: `${PRACTITIONER.LISTING}/nonBinary` },
        { linkName: 'Agender', linkUrl: `${PRACTITIONER.LISTING}/agender` },
        { linkName: 'Other', linkUrl: `${PRACTITIONER.LISTING}/genderNotListed` }
      ]
    },
    {
      name: 'Topic specialised',
      links: [
        { linkName: 'Anxiety', linkUrl: `${PRACTITIONER.LISTING}/anxiety` },
        { linkName: 'Depression', linkUrl: `${PRACTITIONER.LISTING}/depression` },
        { linkName: 'Low Mood', linkUrl: `${PRACTITIONER.LISTING}/lowMood` },
        { linkName: 'Anger', linkUrl: `${PRACTITIONER.LISTING}/anger` },
        { linkName: 'Eating Disorders', linkUrl: `${PRACTITIONER.LISTING}/eatingDisorders` },
        { linkName: 'OCD', linkUrl: `${PRACTITIONER.LISTING}/ocd` },
        { linkName: 'Work Stress', linkUrl: `${PRACTITIONER.LISTING}/workStress` }
      ]
    },
    {
      name: 'Language',
      links: [
        { linkName: 'English', linkUrl: `${PRACTITIONER.LISTING}?language=en` },
        { linkName: 'Arabic', linkUrl: `${PRACTITIONER.LISTING}?language=ar` },
        { linkName: 'French', linkUrl: `${PRACTITIONER.LISTING}?language=fr` },
        { linkName: 'Hindi', linkUrl: `${PRACTITIONER.LISTING}?language=hi` },
        { linkName: 'Italian', linkUrl: `${PRACTITIONER.LISTING}?language=it` },
        { linkName: 'Mandarin/Cantonese', linkUrl: `${PRACTITIONER.LISTING}?language=zh` },
        { linkName: 'Vietnamese', linkUrl: `${PRACTITIONER.LISTING}?language=vi` }
      ]
    },
    {
      name: 'Available',
      links: [
        { linkName: 'Monday', linkUrl: `${PRACTITIONER.LISTING}?day=monday` },
        { linkName: 'Tuesday', linkUrl: `${PRACTITIONER.LISTING}?day=tuesday` },
        { linkName: 'Wednesday', linkUrl: `${PRACTITIONER.LISTING}?day=wednesday` },
        { linkName: 'Thursday', linkUrl: `${PRACTITIONER.LISTING}?day=thursday` },
        { linkName: 'Friday', linkUrl: `${PRACTITIONER.LISTING}?day=friday` },
        { linkName: 'Weekends', linkUrl: `${PRACTITIONER.LISTING}?day=weekend` }
      ]
    },
    {
      name: 'Style',
      links: [
        {
          linkName: 'Cognitive Behavioral Therapy (CBT)',
          linkUrl: `${PRACTITIONER.LISTING}?style=Cognitive Behavioral Therapy (CBT)`
        },
        {
          linkName: 'Acceptance and Commitment Therapy (ACT)',
          linkUrl: `${PRACTITIONER.LISTING}?style=Acceptance and Commitment Therapy (ACT)`
        },
        { linkName: 'Psychodynamic Therapy', linkUrl: `${PRACTITIONER.LISTING}?style=Psychodynamic Therapy` },
        { linkName: 'Person-Centered Therapy', linkUrl: `${PRACTITIONER.LISTING}?style=Person-Centered Therapy` },
        { linkName: 'Schema Therapy', linkUrl: `${PRACTITIONER.LISTING}?style=Schema Therapy` },
        {
          linkName: 'Dialectical Behavior Therapy',
          linkUrl: `${PRACTITIONER.LISTING}?style=Dialectical Behavior Therapy`
        }
      ]
    },
    {
      name: 'Session Times',
      links: [
        { linkName: 'Morning', linkUrl: `${PRACTITIONER.LISTING}?time=morning` },
        { linkName: 'Afternoon', linkUrl: `${PRACTITIONER.LISTING}?time=afternoon` },
        { linkName: 'Evening', linkUrl: `${PRACTITIONER.LISTING}?time=evening` }
      ]
    },
    {
      name: 'Popular filters',
      links: [
        {
          linkName: "I'm feeling anxious or panicky",
          linkUrl: `${PRACTITIONER.LISTING}/anxiousOrPanicky`
        },
        {
          linkName: "I'm having difficulty in my relationship",
          linkUrl: `${PRACTITIONER.LISTING}/difficultyInMyRelationship`
        },
        {
          linkName: 'A traumatic experience [past or present]',
          linkUrl: `${PRACTITIONER.LISTING}/traumaticExperience`
        },
        {
          linkName: "I've been having trouble sleeping",
          linkUrl: `${PRACTITIONER.LISTING}/troubleSleeping`
        },
        {
          linkName: "I'm navigating addiction or difficulty with substance abuse",
          linkUrl: `${PRACTITIONER.LISTING}/addictionOrDifficultyWithSubstanceAbuse`
        },
        {
          linkName: "I'm feeling down or depressed",
          linkUrl: `${PRACTITIONER.LISTING}/downOrDepressed`
        },
        {
          linkName: "I'm dealing with stress at work or school",
          linkUrl: `${PRACTITIONER.LISTING}/stressAtWorkOrSchool`
        }
      ]
    }
  ];

  const generateContents = () =>
    themeArray
      .filter((item) => (item.links ? item.links.length !== 0 : false))
      .map((filteredItem, index) => {
        const filteredItemLinks = filteredItem.links;
        return (
          <div key={`theme_${index}`} className={styles.linkContainer}>
            <div className={styles.heading}>{filteredItem.name}</div>
            <ul className={styles.linkList}>
              {filteredItemLinks?.map((link, index) => (
                <Link key={`link_${index}`} onClick={() => scrollToView('SomeoneHealthHeader')} to={link.linkUrl}>
                  <li className={styles.link}>{link.linkName}</li>
                </Link>
              ))}
            </ul>
          </div>
        );
      });

  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Find the right fit for you</div>
      </div>
      <div className={styles.contentContainer}>{generateContents()}</div>
    </>
  );
};

export default SomeoneHealthFindRightFit;
