import { useState, useEffect } from 'react';

import { consentFormInterface, SignatureInterface } from 'interfaces/ConsentForm/consentForm';
import { useGetAccessToken } from 'utils/hooks/token';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ConsentContent from 'pages/ConsentForm/components/ConsentContent/ConsentContent';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useFetchConsentSignUpDetail } from './hooks/GetConsentSignUp';
import { postConsentFormSignUp } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { notification } from 'antd';

import styles from './ConsentFormSignUp.module.scss';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

const ConsentFormSignUp = () => {
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();
  const { consentFormData, isConsentFormDataLoading } = useFetchConsentSignUpDetail(token);

  const [consentFormDetail, setConsentFormDetail] = useState(consentFormData as consentFormInterface);
  const [submitBtnStatus, setSubmitBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [errorMessage, setErrorMessage] = useState({
    clientSignature: false,
    otherSignature: false
  });

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/');
      } else if (token && !isConsentFormDataLoading) {
        setConsentFormDetail(consentFormData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentFormData, token, isLoading, isAuthenticated, isConsentFormDataLoading]);

  const handleChangeClientSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentFormDetail,
      signature: {
        ...consentFormDetail.signature,
        client: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      clientSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentFormDetail(newConsentDetail);
  };

  const handleChangeOtherSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentFormDetail,
      signature: {
        ...consentFormDetail.signature,
        other: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      otherSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentFormDetail(newConsentDetail);
  };

  const validation = () => {
    const clientSignatureValidate = consentFormDetail.signature?.client
      ? consentFormDetail.signature.client.type &&
        (consentFormDetail.signature.client.textVal || consentFormDetail.signature.client.drawVal)
      : true;
    const otherSignatureValidate = consentFormDetail.signature?.other
      ? consentFormDetail.signature.other.type &&
        (consentFormDetail.signature.other.textVal || consentFormDetail.signature.other.drawVal)
      : true;

    const errorMessage = {
      clientSignature: !clientSignatureValidate,
      otherSignature: !otherSignatureValidate
    };
    setErrorMessage(errorMessage);
    return clientSignatureValidate && otherSignatureValidate;
  };

  const handleSubmitConsentForm = async () => {
    if (validation()) {
      setSubmitBtnStatus('active');
      try {
        await postConsentFormSignUp(token, consentFormDetail);
        setSubmitBtnStatus('finished');
        setTimeout(() => {
          notification.success({
            message: 'Consent form submitted',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitBtnStatus('');
          navigate(`/`);
          navigate(0);
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setSubmitBtnStatus('');
        notification.error({
          message: 'Submit consent form fail',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return isConsentFormDataLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={styles.container}>
      <SomeoneHealthHeader withPadding withMenu />
      <SomeoneHealthContentLayout className={styles.content}>
        <ConsentContent
          classNames={styles.consentContent}
          consentFormDetail={consentFormDetail}
          handleChangeClientSignature={handleChangeClientSignature}
          handleChangeOtherSignature={handleChangeOtherSignature}
          handleSubmitConsentForm={handleSubmitConsentForm}
          submitBtnStatus={submitBtnStatus}
          errorMessage={errorMessage}
        />
      </SomeoneHealthContentLayout>
    </div>
  );
};

export default ConsentFormSignUp;
