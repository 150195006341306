import { useEffect, useMemo } from 'react';
import styles from './SignUpResult.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import Button from 'components/v2/Button/Button';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import HelmSignUpFooter from 'helm/components/HelmFooter/HelmSignUpFooter/HelmSignUpFooter';
import { useFetchPractitionerDetails } from 'helm/pages/PractitionerDetails/hooks/getPractitionerDetails';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { useNavigate } from 'react-router-dom';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { retrieveUserSession } from '../helper/userSession';
import { ga4EventPush, gaEventPush } from 'utils/GAEvent';
import { isIP6Program, isPowerToChangeProgram } from 'utils/checkEngageProgramId';

const SignUpResult = () => {
  const navigate = useNavigate();
  const { AVATAR } = useHelmRoutesGenerator();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];

  const { clinicianId, sid, appointmentTypeInfo, programId } = useReserveAppointmentData();
  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);

  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;
  const isSMPProgram = useMemo(() => isIP6Program(programId) || isPowerToChangeProgram(programId), [programId]);

  useEffect(() => {
    if (!isPractitionerDetailsLoading) {
      gaEventPush('onLoadWelcomePage', {
        clinicianName: clinicianName,
        sid: sid || '',
        appointmentInfo: {
          name: appointmentTypeInfo?.name || '',
          rate: appointmentTypeInfo?.rate || 0,
          deliveryType: appointmentTypeInfo?.deliveryType || '',
          timeslot: appointmentTypeInfo?.timeSlot
        }
      });
      ga4EventPush('purchase', {
        transaction_id: sid || '',
        value: appointmentTypeInfo?.rate || 0,
        currency: 'AUD',
        items: appointmentTypeInfo?.timeSlot?.map((timeSlotObj, index) => ({
          item_id: appointmentTypeInfo?.name || '',
          item_name: clinicianName,
          index,
          item_category: appointmentTypeInfo?.deliveryType || '',
          item_category2: timeSlotObj.date,
          item_category3: timeSlotObj.startTime,
          item_category4: timeSlotObj.endTime,
          Price: (appointmentTypeInfo?.rate || 0) / (appointmentTypeInfo?.timeSlot.length || 1),
          Quantity: 1,
          Currency: 'AUD'
        }))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPractitionerDetailsLoading]);

  const handleGoToAccount = () => {
    gaEventPush('onClickGoToAccount');
    localStorage.removeItem('reservedAppointmentData');
    navigate(`${AVATAR}${sid ? `?sid=${sid}` : ''}`);
  };

  return (
    <HelmHelmetWrapper title={'HELM - Welcome to Helm'}>
      <div className={styles.container}>
        <HelmHeader />
        <HelmContentLayout>
          {isPractitionerDetailsLoading ? (
            <div className={styles.loading}>
              <LoadingDot />
            </div>
          ) : (
            <div className={styles.signUpResult}>
              <div className={styles.leftContent}>
                <div className={styles.leftContentBody}>
                  <div className={styles.header}>YOU’RE ALL SET</div>
                  <div className={styles.card}>
                    <div className={styles.welcomeTitle}>Welcome {user?.name},</div>
                    {practitionerDetails.helmControl?.welcomeNote && (
                      <div className={styles.welcomeNote}>{practitionerDetails.helmControl.welcomeNote}</div>
                    )}
                    <div className={styles.messageEnding}>
                      Best Regards,
                      <br />
                      <div>{clinicianName}</div>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.welcomeText}>Your appointment has been confirmed.</div>
                    <div className={styles.cardText}>
                      {isSMPProgram
                        ? 'To hit the ground running and maximise our time together, we encourage you to complete a pre-program survey and some brief assessments by logging in to your account using the button below. Please note that these surveys are voluntary, but should you choose to complete them, the results will be used to inform the focus of your sessions.'
                        : 'To hit the ground running and maximise our time together, we ask you to please review and complete the following pre-work:'}
                    </div>
                    {!isSMPProgram && (
                      <div className={styles.tasks}>
                        <ul>
                          {/*<li>*/}
                          {/*  <div className={styles.circle} /> <div className={styles.liText}>Client consent form</div>*/}
                          {/*</li>*/}
                          <li>
                            <div className={styles.circle} />{' '}
                            <div className={styles.liText}>
                              Pre-session survey (this is voluntary, and the results will be used to inform the focus of
                              our sessions).
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className={styles.cardText}>We look forward to seeing you soon.</div>
                    <Button onClick={handleGoToAccount} className={styles.proceedBtn}>
                      Go to your account
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.image}>
                  <div className={styles.shape3} />
                  <div className={styles.shape4}>
                    <img alt={'profile'} src={clinicianAvatar} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </HelmContentLayout>
        <HelmSignUpFooter />
      </div>
    </HelmHelmetWrapper>
  );
};

export default SignUpResult;
