import { AppointmentSlot } from 'utils/hooks/appointment';
import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getReservedAppointmentByReserveId } from 'utils/http/appointment';
import momentTz from 'moment-timezone';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { SelectDefaultTimezone } from '../env/SelectEnv';

const massageTime = (time: string, date: string, clientTimeZone: string, practiceTimeZone: string) => {
  const clinicianTimeTZ = momentTz.tz(`${date} ${time}`, practiceTimeZone);
  const clientTimeTimeZone = momentTz.tz(clinicianTimeTZ, clientTimeZone);
  return clientTimeTimeZone.format('hh:mmA');
};

export const useFetchReservedAppointmentsWithTimeZone = ({
  reserveId,
  accountId
}: {
  reserveId: string;
  accountId: string;
}) => {
  const [fetching, setFetching] = useState(true);
  const [appointments, setAppointments] = useState<AppointmentSlot[]>([]);
  const { data: practiceInfoData } = useGetPracticeInfoQuery({ slugUrlOrAccountId: accountId });
  const practiceTimeZone = practiceInfoData?.accountSettings?.timezone || SelectDefaultTimezone;

  const fetchAppointment = useCallback(async () => {
    if (reserveId && accountId) {
      try {
        const response = await getReservedAppointmentByReserveId(accountId, reserveId);
        const data: AppointmentSlot[] = (await response.json()).appointments;

        // Convert to client timezone
        const massageData: AppointmentSlot[] = data.map((appointment) => ({
          ...appointment,
          startTime: massageTime(
            appointment.startTime,
            appointment.date || '',
            SelectDefaultTimezone,
            practiceTimeZone
          ),
          endTime: massageTime(appointment.endTime, appointment.date || '', SelectDefaultTimezone, practiceTimeZone)
        }));

        setAppointments(massageData);
        setFetching(false);
      } catch (e) {
        notification.error({ message: 'Something went wrong while trying to fetch your appointment' });
      } finally {
        setFetching(false);
      }
    }
  }, [accountId, reserveId, practiceTimeZone]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return { fetching, appointments, fetchAppointment };
};
