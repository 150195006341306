import classnames from 'classnames';

import styles from './TypeMode.module.scss';
import { AppointmentSlot, DeliveryType } from 'utils/hooks/appointment';
import { getAppointmentTypePhoneCallDeliveryMode, getDeliveryTypeLabel } from 'utils/appointment';

interface TypeModeProps {
  deliveryOptions: Required<AppointmentSlot>['deliveryOptions'];
  faceToFaceLocation: AppointmentSlot['faceToFaceLocation'];
  videoCallInstructions: AppointmentSlot['videoCallInstructions'];
  phoneCallInstructions: AppointmentSlot['phoneCallInstructions'];
  phoneCallDialClientInstructions: AppointmentSlot['phoneCallDialClientInstructions'];
  otherInstructions: AppointmentSlot['otherInstructions'];
  value?: Required<AppointmentSlot>['deliveryOptions'][number];
  onChangeMode: any;
}

const TypeMode = ({
  deliveryOptions,
  faceToFaceLocation,
  videoCallInstructions,
  phoneCallInstructions,
  phoneCallDialClientInstructions,
  otherInstructions,
  value,
  onChangeMode
}: TypeModeProps) => {
  const phoneCallDeliveryMode = getAppointmentTypePhoneCallDeliveryMode(deliveryOptions);

  return (
    <div className={styles.container}>
      {deliveryOptions.includes(DeliveryType.FaceToFace) && (
        <div className={styles.typeWrapper}>
          <div className={styles.radioBtn}>
            <input
              type={'radio'}
              id={DeliveryType.FaceToFace}
              name={'modeType'}
              value={DeliveryType.FaceToFace}
              checked={value === DeliveryType.FaceToFace}
              onChange={(e) => onChangeMode(e.target.value)}
            />
            <label htmlFor={DeliveryType.FaceToFace} className={styles.label}>
              Face to face
            </label>
          </div>
          {faceToFaceLocation && (
            <div className={classnames(styles.infoWrapper, value === DeliveryType.FaceToFace && styles.visible)}>
              <div className={styles.locationLabel}>Location</div>
              <div className={styles.locationWrapper}>
                <div className={styles.location}>{faceToFaceLocation}</div>
              </div>
              <div>
                <iframe
                  title="map-frame"
                  className={styles.mapWrapper}
                  width="100%"
                  height={260}
                  frameBorder={0}
                  aria-hidden={false}
                  tabIndex={0}
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB_R-981DOmtR0PO166zBmA61rH43BQPOo&q=${faceToFaceLocation}`}
                  allowFullScreen
                />
              </div>
            </div>
          )}
        </div>
      )}
      {phoneCallDeliveryMode && (
        <div className={styles.typeWrapper}>
          <div className={styles.radioBtn}>
            <input
              type={'radio'}
              id={`phoneCall`}
              name={'modeType'}
              value={phoneCallDeliveryMode}
              checked={value === phoneCallDeliveryMode}
              onChange={(e) => onChangeMode(e.target.value)}
            />
            <label htmlFor={`phoneCall`} className={styles.label}>
              Phone call
            </label>
          </div>
          {((phoneCallDeliveryMode === DeliveryType.PhoneCall && phoneCallInstructions) ||
            (phoneCallDeliveryMode === DeliveryType.PhoneCallDialClient && phoneCallDialClientInstructions)) && (
            <div className={classnames(styles.infoWrapper, value === phoneCallDeliveryMode && styles.visible)}>
              <div className={styles.instructionsLabel}>Instructions</div>
              <div className={styles.instructionsWrapper}>
                <div className={styles.instructions}>
                  {phoneCallDeliveryMode === DeliveryType.PhoneCall
                    ? phoneCallInstructions
                    : phoneCallDialClientInstructions}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {deliveryOptions.includes(DeliveryType.VideoCall) && (
        <div className={styles.typeWrapper}>
          <div className={styles.radioBtn}>
            <input
              type={'radio'}
              id={`videoCall`}
              name={'modeType'}
              value={DeliveryType.VideoCall}
              checked={value === DeliveryType.VideoCall}
              onChange={(e) => onChangeMode(e.target.value)}
            />
            <label htmlFor={`videoCall`} className={styles.label}>
              Video call
            </label>
          </div>
          {videoCallInstructions && (
            <div className={classnames(styles.infoWrapper, value === DeliveryType.VideoCall && styles.visible)}>
              <div className={styles.instructionsLabel}>Instructions</div>
              <div className={styles.instructionsWrapper}>
                <div className={styles.instructions}>{videoCallInstructions}</div>
              </div>
            </div>
          )}
        </div>
      )}
      {deliveryOptions.includes(DeliveryType.Other) && (
        <div className={styles.typeWrapper}>
          <div className={styles.radioBtn}>
            <input
              type={'radio'}
              id={`other`}
              name={'modeType'}
              value={DeliveryType.Other}
              checked={value === DeliveryType.Other}
              onChange={(e) => onChangeMode(e.target.value)}
            />
            <label htmlFor={`other`} className={styles.label}>
              {getDeliveryTypeLabel(DeliveryType.Other, otherInstructions)}
            </label>
          </div>
          {otherInstructions?.instructions && (
            <div className={classnames(styles.infoWrapper, value === DeliveryType.Other && styles.visible)}>
              <div className={styles.instructionsLabel}>Instructions</div>
              <div className={styles.instructionsWrapper}>
                <div className={styles.instructions}>{otherInstructions.instructions}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TypeMode;
