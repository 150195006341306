export enum SignatureType {
  typeVer = 'typeVer',
  drawVer = 'drawVer'
}

export interface Signature {
  type: string;
  textVal: string;
  drawVal: string;
}
