import { useAuth0 } from '@auth0/auth0-react';
import styles from './SignUp.module.scss';
import Loading from 'components/Loading/Loading';
import PortobelloCreateProfileForm from 'Portobello/components/PortobelloCreateProfileForm/PortobelloCreateProfileForm';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';

import portobelloLogo from 'Portobello/assets/images/logo/portobello_logo.png';

const SignUp = () => {
  const { isLoading } = useAuth0();

  return isLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <PortobelloHelmetWrapper title={'Portobello Behavioural Health | Sign Up'}>
      <div className={styles.backgroundContainer}>
        <PortobelloContentLayout className="portobello-theme">
          <div className={styles.headerWrapper}>
            <PortobelloHeader withPadding className={styles.header} />
          </div>
          <div className={styles.container}>
            <div className={styles.leftContent}>
              <PortobelloCreateProfileForm />
            </div>
            <div className={styles.rightContent}>
              <div className={styles.achWrapper}>
                <div>
                  <div className={styles.achLabel}>Supporting clients since</div>
                  <div className={styles.achDesc}>2016</div>
                </div>
                <div>
                  <div className={styles.achLabel}>Clients supported each year</div>
                  <div className={styles.achDesc}>400+</div>
                </div>
                <div>
                  <div className={styles.achLabel}>Years of combined experience</div>
                  <div className={styles.achDesc}>150+</div>
                </div>
                <div>
                  <div className={styles.achLabel}>Hours of coaching per year</div>
                  <div className={styles.achDesc}>26000+</div>
                </div>
              </div>
              <div className={styles.logoWrapper}>
                <img className={styles.logo} alt={'portobello logo'} src={portobelloLogo} />
              </div>
            </div>
          </div>
        </PortobelloContentLayout>
        <TacklitFooter darkFooter />
      </div>
    </PortobelloHelmetWrapper>
  );
};

export default SignUp;
