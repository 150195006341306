import Loading from 'components/Loading/Loading';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './SignUpWithInviteWelcome.module.scss';
import classNames from 'classnames';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

const SignUpWithInviteWelcome = () => {
  const signedUpUser = retrieveUserSession();
  const { SomeoneHealthFindPsychologistURL } = someoneHealthEnvironment();

  const onGoToMyAccount = () => {
    window.location.href = SomeoneHealthFindPsychologistURL;
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - welcome'}>
      <div className={classNames(styles.pageContainer, 's1h-client-portal-theme')}>
        <SomeoneHealthHeader pinkLogo noLogin classNames={styles.header} />
        {!signedUpUser && <Loading />}
        <SomeoneHealthContentLayout className={styles.container}>
          <div className={styles.title}>Thank you for signing up</div>
          <div className={styles.content}>
            Thanks {signedUpUser.firstName || signedUpUser.clientRecord?.clientProfiles[0].firstName}
            <br />
            <br />
            You’ve successfully created your Someone.health profile and can now login to your secure portal to book a
            psychology appointment. Once logged in, your can make, amend or cancel appointments, or update your profile.
            <br />
            <br />
            <div>
              <ButtonSH className={styles.button} onClick={onGoToMyAccount}>
                Login to book a psychologist
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default SignUpWithInviteWelcome;
