import styles from './SQQuestion.module.scss';
import OnboardingQuestionForm from 'pages/OnboardingAssessment/components/OnboardingQuestionForm/OnboardingQuestionForm';

import SQHeaderProgressBar from './components/SQHeaderProgressBar/SQHeaderProgressBar';
import { SurveyAssessment } from 'interfaces/Assessment/SurveyInterfaces';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import { SurveyStep } from '../../SurveyQuestionForm';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

interface SQQuestionProps {
  clinicianName: string;
  clinicianDetail: GetAttachedClinicianDetails;
  token: string;
  surveyData: SurveyAssessment;
  setSurveyQuestion: (val: SurveyAssessment) => void;
  setSurveyStep: (val: SurveyStep['step']) => void;
  type?: string;
}

const SQQuestion = ({
  clinicianDetail,
  clinicianName,
  token,
  surveyData,
  setSurveyQuestion,
  setSurveyStep,
  type
}: SQQuestionProps) => {
  const navigate = useNavigate();
  const { ONBOARDING } = useRoutesGenerator();
  const surveyQuestion = surveyData.questionsResponses;

  const handleUpdateAssessment = (
    assessmentId: string,
    responses: { questionId: string; response: { value: any; otherValue?: any } }[]
  ) => {
    if (surveyQuestion) {
      const newFirstAssessment = { ...surveyQuestion };

      const foundQuestionSet =
        newFirstAssessment.assessment.clinicalAssessments.find((assessment) => assessment.id === assessmentId) ||
        newFirstAssessment.assessment.onboardingQuestions.assessmentField.find(
          (assessment) => assessment.id === assessmentId
        );

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }

      setSurveyQuestion({
        questionsResponses: newFirstAssessment,
        survey: surveyData.survey
      });

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newFirstAssessment };
    }
  };

  const handleUpdateStatusDone = () => {
    if (type === 'onboard') {
      navigate(`${ONBOARDING.BASE}?type=onboarding`);
    } else {
      setSurveyStep('finish');
      window.location.reload();
    }
  };

  return (
    <div className={IS_SOMEONE_HEALTH_APP ? styles.shSurveyQuestionContainer : styles.surveyQuestionContainer}>
      <OnboardingQuestionForm
        assessment={surveyQuestion}
        clinician={clinicianDetail.clinician}
        clinicianName={clinicianName}
        header={SQHeaderProgressBar}
        onUpdateAssessment={handleUpdateAssessment}
        onSaveAndExit={() => {}}
        onUpdateStatusDone={handleUpdateStatusDone}
        noMargin
        token={token}
      />
    </div>
  );
};

export default SQQuestion;
