import styles from './PsychologistAvailabilityCalendar.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AvailabilityPill from 'Recharge/components/AvailabilityPill/AvailabilityPill';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { practitionerListingAppointmentTypeNames } from 'Recharge/interfaces/appointmentType';
import classNames from 'classnames';

interface PractitionerAvailabilityCalendarProps {
  practitionerDetails: PractitionerDetailsInterface;
  nextAvailabilityDetails: PractitionerDetailsInterface['nextAvailabilities'];
  detailPath: string;
  onShowWaitlistForm: () => void;
  isDarkerBackground: boolean;
}

const todayDate = moment();

const PsychologistAvailabilityCalendar = ({
  practitionerDetails,
  nextAvailabilityDetails,
  detailPath,
  onShowWaitlistForm,
  isDarkerBackground
}: PractitionerAvailabilityCalendarProps) => {
  const { isAuthenticated } = useAuth0();

  const massageAvailabilityData =
    nextAvailabilityDetails?.find(
      (obj) =>
        obj.availabilities?.length &&
        practitionerListingAppointmentTypeNames.includes(encodeURIComponent(obj.appointmentTypeName?.toLowerCase()))
    ) || nextAvailabilityDetails?.[0];

  const isNewClientHasNoSlots =
    !isAuthenticated &&
    practitionerDetails.helmControl?.shouldUseCaseLoad &&
    (practitionerDetails.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.calendarContent,
          massageAvailabilityData && !isNewClientHasNoSlots && styles.calendarListContent
        )}
      >
        {isNewClientHasNoSlots ? (
          <div className={isDarkerBackground ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
            <div className={styles.noAvailableCard}>
              <div className={styles.messageWrapper}>
                <div className={styles.redDotWrapper}>
                  <div className={styles.redDot} />
                </div>
                <div className={styles.message}>
                  <div className={styles.desc}>Sorry...</div>
                  <div className={styles.noAvailDesc}>
                    I am not available to work with new clients at this time. <br />
                    If you'd like to join my waitlist should a spot open up please add your details here
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonRecharge icon={'post_add'} iconPostFix variant={'outlined'} onClick={onShowWaitlistForm}>
                  <div className={styles.label}>{`Join ${practitionerDetails.name} Waitlist`}</div>
                </ButtonRecharge>
              </div>
            </div>
          </div>
        ) : massageAvailabilityData ? (
          <div className={styles.calendarCardWrapper}>
            {massageAvailabilityData.availabilities.slice(0, 2).map((availabilitiesObj, index) => {
              const availabilityDate = moment(availabilitiesObj.date);
              const dayDifference = Math.floor(availabilityDate.diff(todayDate, 'days', true)) + 1;

              const massageAvailabilityTimeSlot =
                dayDifference <= 0
                  ? availabilitiesObj.timeSlots.filter((obj) => moment(obj.startTime, 'hh:mma').isAfter(todayDate))
                  : availabilitiesObj.timeSlots;

              return (
                massageAvailabilityTimeSlot.length > 0 && (
                  <div className={isDarkerBackground ? styles.calendarCardOdd : styles.calendarCardEven} key={index}>
                    <div className={styles.cardContent}>
                      <div className={styles.calendarInfo}>
                        <div className={styles.greenDot} />
                        <div className={styles.calendarDateInfo}>
                          <div className={styles.date}>{moment(availabilitiesObj.date).format('DD')}</div>
                          <div className={styles.month}>{moment(availabilitiesObj.date).format('MMM')}</div>
                        </div>
                        <div className={styles.calendarDateInfo}>
                          <div className={styles.day}>{moment(availabilitiesObj.date).format('dddd')}</div>
                          <div className={styles.openAppointment}>
                            {massageAvailabilityTimeSlot.length || 0} open appointments
                          </div>
                        </div>
                      </div>
                      <div className={styles.timeslotWrapper}>
                        {massageAvailabilityTimeSlot.map((timeSlotObj, index) => {
                          const startTime = moment(timeSlotObj.startTime, 'hh:mmA').format('h:mm');
                          const endTime = moment(timeSlotObj.endTime, 'hh:mmA').format('h:mmA');
                          const selectedDateTime = `selectedDateTime=${availabilitiesObj.date},${timeSlotObj.startTime},${timeSlotObj.endTime}`;
                          const newDetailPath = `${detailPath}?${selectedDateTime}`;

                          return index <= 2 ? (
                            <Link to={newDetailPath} key={index}>
                              <AvailabilityPill classNames={styles.timeslot} isDarkerBackground={isDarkerBackground}>
                                {startTime} to {endTime}
                              </AvailabilityPill>
                            </Link>
                          ) : index === 3 ? (
                            <Link to={detailPath} key={index} className={styles.seeMoreSlot}>
                              ...+{availabilitiesObj.timeSlots.length - 3} MORE TIMES
                            </Link>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        ) : (
          <div className={isDarkerBackground ? styles.noAvailableWrapperEven : styles.noAvailableWrapperOdd}>
            <div className={styles.noAvailableCard}>
              <div className={styles.messageWrapper}>
                <div className={styles.redDotWrapper}>
                  <div className={styles.redDot} />
                </div>
                <div className={styles.message}>
                  <div className={styles.desc}>Sorry...</div>
                  <div className={styles.noAvailDesc}>
                    My time for&nbsp;<strong>next 4 weeks</strong>&nbsp;is fully booked.
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <ButtonRecharge icon={'post_add'} iconPostFix variant={'outlined'} onClick={onShowWaitlistForm}>
                  <div className={styles.label}>{`Join ${practitionerDetails.name} Waitlist`}</div>
                </ButtonRecharge>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychologistAvailabilityCalendar;
