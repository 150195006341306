import { useField } from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Select, { SelectProps } from './Select';
import { Props } from 'react-select';
import styles from './FormikSelect.module.scss';

interface FormikSelectProps extends Omit<SelectProps, 'hasError'> {
  name: string;
  styles?: Props['styles'];
  errorClassName?: string;
  ignoreTouched?: boolean;
}

const FormikSelect = ({
  className,
  name,
  styles: propStyles,
  errorClassName,
  ignoreTouched,
  ...props
}: FormikSelectProps) => {
  const [field, { error, touched }, { setValue }] = useField(name);

  const innerStyles: Props['styles'] = {
    control: (base) => ({
      ...base,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom:
        (ignoreTouched || touched) && !!error ? `1px solid ${styles.redColor}` : `1px solid ${styles.greyColor}`,
      borderRadius: 0,
      boxShadow: 'none',
      minHeight: 0,
      '&:hover': {
        borderColor: '#d3d8dd'
      }
    })
  };

  const handleChange = (
    selected: { label: string; value: any } | readonly { label: string; value: any }[] | null | undefined
  ) => {
    if (Array.isArray(selected)) {
      setValue(selected);
    } else {
      setValue((selected as { label: string; value: any })?.value);
    }
  };

  return (
    <div className={className}>
      <Select
        {...props}
        {...field}
        defaultValue={field.value}
        styles={{ ...propStyles, ...innerStyles }}
        hasError={(ignoreTouched || touched) && !!error}
        noErrorStyle
        onChange={handleChange}
      />
      <ErrorMessage className={errorClassName} error={error} visible={ignoreTouched || touched} />
    </div>
  );
};

export default FormikSelect;
