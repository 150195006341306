import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';

const ConsentListWrapper = () => (
  <div className={styles.container}>
    <SomeoneHealthHeader withPadding withMenu />
    <SomeoneHealthContentLayout className={styles.content}>
      <ConsentList />
    </SomeoneHealthContentLayout>
  </div>
);

export default ConsentListWrapper;
