import classnames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { InputProps } from 'components/Input/Input';
import { useField } from 'formik';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import styles from './SelectMobileFormikInput.module.scss';

interface SelectMobileFormikInputProps extends Omit<InputProps, 'hasError'> {
  name: string;
  mobilePattern?: string;
  errorClass?: string;
  phonePrefix?: string;
  isMobileValid?: boolean;
  externalError?: string | ReactNode;
}

const isDevEnv = isDevelopmentENV();

const SelectMobileFormikInput = ({
  className,
  name,
  onChange,
  onKeyUp,
  mobilePattern = '___ ___ ___',
  label,
  inputClass,
  phonePrefix,
  isMobileValid = true,
  errorClass,
  externalError,
  ...props
}: SelectMobileFormikInputProps) => {
  const [{ onChange: onFormChange, ...field }, { error, touched: formikTouched }] = useField(name);
  const [value, setValue] = useState(phonePrefix);
  const [focus, setFocus] = useState<boolean | null>(null);
  const [touched, setTouched] = useState<boolean>(false);
  const hasError = !!error || !!externalError;

  useEffect(() => {
    if (!touched && formikTouched) {
      setTouched(formikTouched);
    }
  }, [formikTouched, touched]);

  const getDisplayValue = (v: string) => {
    if (!v) {
      return mobilePattern;
    }
    let display = mobilePattern;
    v.split('').forEach((element) => {
      display = display.replace('_', element);
    });
    return display;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFormChange(e);
    onChange && onChange(e);
  };

  const errorStyles = touched && hasError ? styles.error : '';

  const containerClasses = classnames(
    styles.container,
    className,
    focus === true && styles.focus,
    errorStyles,
    !touched && styles.notTouched,
    value && styles.haveText
  );

  const labelClasses = classnames(styles.label, props.labelClass);

  return (
    <div className={containerClasses}>
      {label && <div className={labelClasses}>{label}</div>}
      <input hidden value={value} onChange={handleChange} />
      <div>
        <PhoneInput
          containerClass={classnames(styles.frontInput, styles.input, inputClass)}
          inputClass={classnames()}
          onChange={(e, x, event) => {
            let value = e?.toString() || '';
            if (value.startsWith('9')) {
              value = '';
            }
            setValue(value);
            handleChange({ ...event, target: { ...event.target, name: field.name, value: e } });
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setFocus(false);
            setTouched(true);
          }}
          masks={isDevEnv ? {} : { gb: mobilePattern.split('_').join('.') }}
          placeholder={props.placeholder}
          value={value}
          country={isDevEnv ? 'my' : 'gb'}
          disableCountryCode={!isDevEnv}
          showDropdown={false}
        />
        {value && (
          <div className={styles.backInput}>
            {getDisplayValue(value)
              .split('')
              .map((i, index) =>
                /\d/g.test(i) ? (
                  <span key={index} className={styles.noVisibleChar}>
                    {i}
                  </span>
                ) : i === ' ' ? (
                  <span key={index} className={styles.noVisibleChar}>
                    {'.'}
                  </span>
                ) : (
                  <span key={index}>{i}</span>
                )
              )}
          </div>
        )}
      </div>
      {isMobileValid && !externalError ? (
        <ErrorMessage className={errorClass} error={error} visible={touched && !!error} />
      ) : (
        <ErrorMessage className={errorClass} error={externalError || 'Invalid mobile number'} visible={!!true} />
      )}
    </div>
  );
};

export default SelectMobileFormikInput;
