import { PsychologistMinified } from '../../hooks/useFetchPsychologistsMinified';
import PsychologistAlphabetItem from '../PsychologistAlphabetItem/PsychologistAlphabetItem';
import PsychologistEmptyList from '../PsychologistEmptyList/PsychologistEmptyList';
import styles from './PsychologistAlphabetList.module.scss';

interface PsychologistAlphabetListProps {
  searchValue: string;
  selectedAlphabetFilter: string;
  availablePsychologists: Map<string, PsychologistMinified[]>;
}

const PsychologistAlphabetList = ({
  searchValue,
  selectedAlphabetFilter,
  availablePsychologists
}: PsychologistAlphabetListProps) => {
  let filteredData = new Map<string, PsychologistMinified[]>();
  if (selectedAlphabetFilter) {
    filteredData.set(selectedAlphabetFilter, availablePsychologists.get(selectedAlphabetFilter) || []);
  } else if (searchValue) {
    Array.from(availablePsychologists.keys()).forEach((key) => {
      const matchedNameList = availablePsychologists
        .get(key)
        ?.filter((psychologist) => psychologist.name.toLowerCase().includes(searchValue.toLowerCase()));
      if (matchedNameList?.length) {
        filteredData.set(key, matchedNameList);
      }
    });
  } else {
    filteredData = availablePsychologists;
  }

  return (
    <div className={styles.container}>
      {filteredData.size ? (
        Array.from(filteredData.keys()).map((key) => (
          <PsychologistAlphabetItem
            key={key}
            filterCharacter={key}
            searchValue={searchValue}
            psychologist={filteredData.get(key) || []}
          />
        ))
      ) : (
        <PsychologistEmptyList />
      )}
    </div>
  );
};

export default PsychologistAlphabetList;
