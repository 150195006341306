import classNames from 'classnames';
import styles from './GPAppointmentTypeCard.module.scss';
import { AvailabilityAppointmentTypes } from 'utils/hooks/appointment';
import { useAppSelector } from 'redux/hooks';
import { selectIsTimeSlotsLoading } from 'redux/globalStore/gpBookingSlice';

interface GPAppointmentTypeCardProps {
  appointmentType: AvailabilityAppointmentTypes;
  isSelected: boolean;
  onSelect: (appointmentType: AvailabilityAppointmentTypes) => void;
}

const GPAppointmentTypeCard = ({ isSelected, appointmentType, onSelect }: GPAppointmentTypeCardProps) => {
  const isTimeSlotLoading = useAppSelector(selectIsTimeSlotsLoading);

  const finishedAssessment = false; //TODO

  return (
    <div
      className={classNames(styles.container, isSelected && styles.selected, isTimeSlotLoading && styles.disabled)}
      onClick={() => {
        !isTimeSlotLoading && onSelect(appointmentType);
      }}
    >
      <div className={styles.appointmentTypeInfo}>
        <div className={styles.title}>{appointmentType.name}</div>
        <div className={styles.description}>{appointmentType.description}</div>
      </div>
      <div className={styles.duration}>
        <i className={classNames('material-icons-outlined', styles.icon)}>query_builder</i>
        <div>{`${appointmentType.duration} minutes`}</div>
      </div>
      {finishedAssessment && (
        <div className={styles.rate}>
          <i className={classNames('material-icons-outlined', styles.icon)}>monetization_on</i>
          <div>{appointmentType.rate}</div>
        </div>
      )}
    </div>
  );
};

export default GPAppointmentTypeCard;
