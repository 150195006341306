import TaskButton from 'Select/components/TaskButton/TaskButton';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import PaymentFailureTaskBox from '../PaymentFailureTaskBox/PaymentFailureTaskBox';
import styles from './ActionSummaryBox.module.scss';

interface ActionSummaryBoxProps {
  failedPayments?: { amount: number; _id: string }[];
  totalLength: number;
}

const ActionSummaryBox = ({ failedPayments, totalLength }: ActionSummaryBoxProps) => {
  const { PENDING_ACTIONS } = useSelectRoutesGenerator();

  return (
    <div className={styles.actionWrapper}>
      <div className={styles.title}>Your actions</div>
      <div className={styles.menuItemWrapper}>
        {failedPayments &&
          failedPayments.map(({ amount, _id }) => (
            <PaymentFailureTaskBox amount={amount} key={_id} paymentRequestId={_id} />
          ))}
        <TaskButton
          className={styles.taskButton}
          item={{
            name: (
              <div className={styles.taskButtonText}>
                You have{' '}
                <span className={styles.highlight}>
                  {totalLength} outstanding request{totalLength !== 1 && 's'}
                </span>
              </div>
            ),
            url: PENDING_ACTIONS,
            isExternal: false
          }}
        />
      </div>
    </div>
  );
};

export default ActionSummaryBox;
