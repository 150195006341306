import { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './PatientContentLayout.module.scss';

interface PatientContentLayoutProps {
  children?: any;
  className?: string;
  withFullHeight?: boolean;
  style?: CSSProperties;
}

const PatientContentLayout = ({ children, className, withFullHeight, style }: PatientContentLayoutProps) => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div className={withFullHeight ? styles.fullHeightContentLayout : styles.contentLayout}>{children}</div>
    </div>
  );
};

export default PatientContentLayout;
