import styles from './SomeoneHealthFooter.module.scss';
import classnames from 'classnames';
import SomeoneHealthContentLayout from '../SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import someoneHealthLogo from 'SomeoneHealth/assets/images/logo/logo.svg';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFax, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { getSomeoneHealthSupportEmail } from 'SomeoneHealth/PartnerReferral/utils/getSomeoneHealthSupportEmail';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';

const SomeoneHealthFooter = () => {
  const URLs = someoneHealthEnvironment();
  const partnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType | undefined;
  const supportEmail = getSomeoneHealthSupportEmail(partnerCode);

  return (
    <div className={styles.footerContainer}>
      <SomeoneHealthContentLayout className={styles.container}>
        <section className={styles.content}>
          <div id={styles.aboutUsWrapper} className={styles.textContainer}>
            <h4 className={styles.header}>About us</h4>
            <p id={styles.aboutUsContent} className={classnames(styles.text, styles.textPink)}>
              Talk to <span className={styles.textBold}>someone.health</span> about your mental health today.
            </p>
            <p id={styles.aboutUsContent} className={classnames(styles.text, styles.textPink)}>
              We connect you with Medicare Online Bulk Billing video and phone Psychologists counselling, Australia
              wide.
            </p>
            <a href={URLs.SomeoneHealthURL}>
              <img alt={'logo'} src={someoneHealthLogo} className={styles.logo} />
            </a>
          </div>
          <div className={styles.textContainer}>
            <h4 id={styles.quickLinksHeader} className={styles.header}>
              Quick Links
            </h4>
            <ul className={styles.list}>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.ScheduleAppointmentURL}>
                  Schedule Appointment
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.PsychologistsURL}>
                  Find a psychologist
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.PricingURL}>
                  Pricing
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.FAQURL} target="_blank" rel="noreferrer">
                  Frequently Asked Questions
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.ContactURL}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.textContainer}>
            <h4 className={styles.header}>Common Services</h4>
            <ul className={styles.list}>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.AnxietyCounsellingURL}>
                  Anxiety Counselling
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.DepressionCounsellingURL}>
                  Depression Counselling
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.RelationshipCounsellingURL}>
                  Relationship Counselling
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.StressCounsellingURL}>
                  Stress Management Counselling
                </a>
              </li>
              <li className={styles.text}>
                <a className={styles.textBlue} href={URLs.TraumaCounsellingURL}>
                  Trauma & PTSD Counselling
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.textContainer}>
            <h4 className={styles.header}>Get In Touch</h4>
            <ul className={styles.list}>
              <li id={styles.getInTouchContent} className={classnames(styles.text, styles.textPink)}>
                <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
                <p className={styles.text}>GPO Box 2126, Sydney NSW 2001</p>
              </li>
              <li id={styles.getInTouchContent} className={classnames(styles.text, styles.textPink)}>
                <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                <p className={styles.text}>{supportEmail}</p>
              </li>
              <li id={styles.getInTouchContent} className={classnames(styles.text, styles.textPink)}>
                <FontAwesomeIcon icon={faFax} className={styles.icon} />
                <p className={styles.text}>Fax +61 2 8367 5101</p>
              </li>
              <li id={styles.getInTouchContentLast} className={classnames(styles.text, styles.textPink)}>
                <FontAwesomeIcon icon={faWhatsapp} className={styles.icon} />
                <p className={styles.text}>Live Chat (8am-10pm)</p>
              </li>
            </ul>
          </div>
        </section>
        <section className={styles.staySafe}>
          <div className={styles.lineWrapper}>
            <div className={styles.line} />
          </div>
          <p className={styles.text}>
            Stay safe.{' '}
            <span role="img" aria-label="heart-emoji">
              ❤️
            </span>
          </p>
        </section>
      </SomeoneHealthContentLayout>
    </div>
  );
};

export default SomeoneHealthFooter;
