export enum ReportWidgetType {
  HeadingWidget = 'headingWidget',
  BodyTextWidget = 'bodyTextWidget',
  FootNotesTextWidget = 'footNotesTextWidget',
  JournalCommentWidget = 'journalCommentWidget',
  InsightsChartWidget = 'insightsChartWidget',
  BarLineChartWidget = 'barLineChartWidget',
  AssessmentWidget = 'assessmentWidget',
  BodyTextTemplateWidget = 'bodyTextTemplateWidget',
  AssessmentTemplateWidget = 'assessmentTemplateWidget',
  ClientProfileWidget = 'clientProfileWidget',
  NotesWidget = 'notesWidget',
  CaseNoteQuestionWidget = 'caseNoteQuestionWidget'
}

export interface ReportTemplateCaseNoteField {
  _id: string;
  name: string;
  type: 'date' | 'link' | 'multipleChoice' | 'multipleChoiceCount' | 'number' | 'text' | 'time' | 'checkbox';
  linkType?: string;
  options: {
    _id: string;
    label: string;
    subLabel?: string;
    count?: number;
    options?: { label: string; value: string }[];
    value?: string;
  }[];
  multiple?: boolean;
  mandatory: boolean;
  value: any;
}
