import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';

import { consentFormInterface } from 'interfaces/ConsentForm/consentForm';
import { getConsentFormAssigned } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { StatusError } from 'bent';
import { FacilitatedTokenInterface } from 'interfaces/Assessment/OnboardingAssessment';
import jwt_decode from 'jwt-decode';
import { t } from 'i18next';

export const useConsentFormApi = (
  consentId: string,
  setIsConsentFormCompleted: (completed: boolean) => void,
  queryParamToken?: string
) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const [consentForm, setConsentForm] = useState<consentFormInterface>();
  const [isConsentFormLoading, setIsConsentFormLoading] = useState(false);

  const fetchConsentForm = async (token: string) => {
    setIsConsentFormLoading(true);

    try {
      const request = await getConsentFormAssigned(token, consentId);

      if (request.statusCode === 200) {
        const consentData = await request.json();
        const massageData = {
          _id: consentData._id,
          body: consentData.body,
          name: consentData.name,
          clientName: consentData.clientName,
          signature: {
            ...(consentData.signatureRequired.client && {
              client: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            }),
            ...(consentData.signatureRequired.other && {
              other: {
                type: '',
                textVal: '',
                drawVal: ''
              }
            })
          },
          practice: consentData.practice
        };
        setConsentForm(massageData);
      } else if (request.statusCode === 204) {
        setIsConsentFormCompleted(true);
      }
    } catch (ex) {
      console.error(ex);
      const err = ex as StatusError;
      if (err.statusCode && (await err.text()) === 'Token expired') {
        const senderName = queryParamToken
          ? (jwt_decode(queryParamToken) as FacilitatedTokenInterface)['https://tacklit.com/senderName']
          : t('terminology:practitoner');

        const errorMessage = t('form.error.expired_token', { senderName });
        notification.error({
          message: errorMessage
        });
      } else {
        notification.error({
          message: 'Something went wrong while trying to get your consent form. Please try again later.',
          duration: 5
        });
      }
    }

    setIsConsentFormLoading(false);
  };

  const getTokenAndFetchConsentForm = async () => {
    setIsConsentFormLoading(true);

    const token =
      queryParamToken ||
      (await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      }));

    await fetchConsentForm(token);
  };

  useEffect(() => {
    if (!isConsentFormLoading && !consentForm)
      if (queryParamToken) {
        getTokenAndFetchConsentForm();
      } else if (!isLoading && isAuthenticated) {
        if (isAuthenticated) {
          if (user?.['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
            window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
          } else {
            getTokenAndFetchConsentForm();
          }
        }
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParamToken, user, isAuthenticated, isLoading]);

  return { consentForm, isConsentFormLoading, fetchConsentForm, setConsentForm };
};
