import { useEffect, useState } from 'react';
import { ClientTestimonialProps } from './ClientTestimonial';
const DefaultClientTestimonial: ClientTestimonialProps = {
  name: 'Jake, 32',
  message: "“It's difficult to take the first step, but once you do, you won't look back”"
};

export const useFetchClientTestimonial = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<ClientTestimonialProps | {}>({});
  useEffect(() => {
    setLoading(true);
    setContent(DefaultClientTestimonial);
    setLoading(false);
  }, []);
  return { content, loading };
};
