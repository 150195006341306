import { InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './MaterialInput.module.scss';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

export interface MaterialInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  rightComponent?: any;
  containerClass?: string;
  labelClass?: string;
  error?: boolean;
  multiline?: boolean;
}

const MaterialInput = ({
  id,
  label,
  className,
  containerClass,
  value,
  onChange,
  type,
  rightComponent,
  labelClass,
  error,
  ...props
}: MaterialInputProps) => {
  return (
    <div className={classnames(styles.container, containerClass)}>
      <input
        {...props}
        id={id}
        className={classnames(
          rightComponent ? styles.fieldWithComponent : styles.field,
          error && styles.error,
          className,
          IS_SOMEONE_HEALTH_APP && styles.someone_health_theme
        )}
        type={type || 'text'}
        autoComplete={'off'}
        value={value}
        onChange={onChange}
      />
      <label className={classnames(styles.label, labelClass)} htmlFor={id}>
        {label}
      </label>
      {rightComponent && rightComponent}
    </div>
  );
};

export default MaterialInput;
