import styles from './SlotPicker.module.scss';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import CalendarBox from 'helm/components/CalendarBox/CalendarBox';
import moment from 'moment';
import classNames from 'classnames';
import { Skeleton } from 'antd';
import AvailabilityPill from 'helm/components/AvailabilityPill/AvailabilityPill';

interface SlotPickerProps {
  selectedDate: string;
  slots?: TimeSlotsWithDateInterface[];
  selectedSlots: TimeSlotsWithDateInterface[];
  onClickSlot: (slot: TimeSlotsWithDateInterface) => void;
  isLoading: boolean;
  remainSlots: number;
}

const SlotPicker = ({ selectedDate, slots, selectedSlots, onClickSlot, isLoading, remainSlots }: SlotPickerProps) => {
  return isLoading ? (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingHeaderWrapper}>
        <Skeleton.Input active className={styles.loadingAvatar} />
        <div className={styles.loadingDescWrapper}>
          <Skeleton.Input active className={styles.loadingTitle} />
          <Skeleton.Input active className={styles.loadingDesc} />
        </div>
      </div>
      <div className={styles.loadingSlotWrapper}>
        {[...Array(9)].map((obj, i) => (
          <Skeleton.Input key={i} active className={styles.loadingSlot} />
        ))}
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <CalendarBox
        date={moment(selectedDate)}
        label={moment(selectedDate).format('dddd')}
        desc={(slots?.length || 0) + ' Open Appointments'}
      />
      <div className={styles.timeslotWrapper}>
        {slots && slots?.length > 0 ? (
          slots?.map((slot, index) => (
            <div key={index} onClick={() => (selectedSlots.includes(slot) || remainSlots > 0) && onClickSlot(slot)}>
              <AvailabilityPill
                classNames={classNames(
                  styles.slot,
                  selectedSlots.includes(slot) ? styles.active : remainSlots === 0 && styles.disabled
                )}
              >
                {`${moment(slot.startTime, 'hh:mmA').format('hh:mm')} to ${slot.endTime}`}
              </AvailabilityPill>
            </div>
          ))
        ) : (
          <div>No time slot available for the filter you selected</div>
        )}
      </div>
    </div>
  );
};

export default SlotPicker;
