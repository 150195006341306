import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import backgroundImg from 'helm/assets/images/background/HelmClientBg.jpg';
import HelmHeader from '../HelmHeader/HelmHeader/HelmHeader';
import HelmLoginWrapper from '../HelmLoginWrapper/HelmLoginWrapper';
import styles from './HelmLayout.module.scss';

interface HelmLayoutProps {
  children?: React.ReactNode;
}

const HelmLayout = ({ children }: HelmLayoutProps) => {
  return (
    <HelmLoginWrapper>
      <PatientContentLayout
        className={styles.container}
        style={{
          backgroundColor: 'transparent !important',
          backgroundImage: `url('${backgroundImg}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div>
          <HelmHeader logoWithoutText whiteLogo whiteFont withMenu homeLink />
          {children}
        </div>
      </PatientContentLayout>
    </HelmLoginWrapper>
  );
};

export default HelmLayout;
