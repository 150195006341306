import { AppointmentSlot } from 'utils/hooks/appointment';

export enum ProgramId {
  Ip6 = 'ip6',
  P2CGambling = 'p2cgambling'
}

export interface SurveyResponseInterface {
  id: string;
  response: {
    value: string;
  };
}

export interface SessionActivityInterface {
  id: string;
  name: string;
  description: string;
  link?: string;
  completed: boolean;
  startedAt?: Date;
  completedAt?: Date;
  surveyResponse?: SurveyResponseInterface[];
}

export interface ProgramAppointment extends AppointmentSlot {
  duration?: number;
}

export interface ChapterInterface {
  chapterId: number;
  name: string;
  image: string;
  description: string;
  completed: boolean;
  link?: string;
  sessionActivities: {
    pre: SessionActivityInterface[];
    post: SessionActivityInterface[];
  };
  appointment?: ProgramAppointment;
}

export interface ProgramInterface {
  name: string;
  status: 'active' | 'expired';
  isUnlockAll: boolean;
  chapters: ChapterInterface[];
  goals?: {
    goal1?: string;
    goal2?: string;
    goal3?: string;
  };
}

export enum ProgressMenuType {
  Activity = 'activity',
  Progress = 'progress',
  Mood = 'mood',
  SMP = 'smp'
}

export interface ProgramQuoteInterface {
  id: string;
  referenceBy: string;
  reference: string;
}
