import classNames from 'classnames';

import styles from './EaseContentLayout.module.scss';

interface EaseContentLayoutProps {
  children?: any;
  className?: string;
}

const EaseContentLayout = ({ children, className }: EaseContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default EaseContentLayout;
