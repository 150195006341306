import bent, { BentResponse, Options } from 'bent';

export const httpClient = (
  httpMethod: string,
  url: string,
  expectedStatusCode: number | number[],
  headers?: Options
) => {
  if (headers) {
    return bent(url, httpMethod, expectedStatusCode, headers);
  }
  // prettier-ignore
  return bent(url, httpMethod, expectedStatusCode);
};

export const getBentJsonResponse = async (response: BentResponse) => {
  try {
    const json = await response.json();
    return json;
  } catch (ex) {
    return;
  }
};
