import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getPublicAvailabilityByAppointmentTypeId } from 'utils/http/SchedService/AppointmentTypes/availabilities';
import { AppointmentTypeAvailabilities } from '../Interface';
import queryString from 'query-string';
import { NodeResponse } from 'bent';

export const useFetchAppointmentTypeAvailabilities = (
  from: string,
  to: string,
  type: 'booking' | 'reschedule',
  appointmentTypeId: string,
  clinicianId: string,
  accountId: string
) => {
  const [appointmentTypeAvailabilities, setAppointmentTypeAvailabilities] = useState<AppointmentTypeAvailabilities>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchApptTypeAvailabilities = useCallback(
    async ({
      from,
      to,
      appointmentTypeId,
      type,
      clinicianId
    }: {
      from: string;
      to: string;
      appointmentTypeId: string;
      type: 'booking' | 'reschedule';
      clinicianId: string;
    }) => {
      setIsLoading(true);
      try {
        const availabilityNewQueryParam = {
          accountId,
          clinicianId,
          date: from,
          to,
          type
        };
        const generateQueryParam = queryString.stringify(availabilityNewQueryParam, { sort: false });
        const response = (await getPublicAvailabilityByAppointmentTypeId(
          appointmentTypeId,
          generateQueryParam
        )) as NodeResponse;
        if (response.statusCode === 200) {
          const data = (await response.json()) as AppointmentTypeAvailabilities;
          setAppointmentTypeAvailabilities(data);
        }
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to get your appointment type records'
        });
      } finally {
        setIsLoading(false);
      }
    },
    [accountId]
  );

  useEffect(() => {
    if (appointmentTypeId) {
      fetchApptTypeAvailabilities({ from, to, type, appointmentTypeId, clinicianId });
    }
  }, [appointmentTypeId, from, to, clinicianId, type, fetchApptTypeAvailabilities]);

  return { appointmentTypeAvailabilities, isLoading };
};
