import * as yup from 'yup';
import { BackgroundFormErrorInterface } from '../BackgroundFormInterface';

const getSchema = () => {
  const bgFormSchema = yup.object().shape({
    summary: yup.object().shape({
      content: yup.string().when('isShow', {
        is: true,
        then: yup.string().required('Please provide a brief summary'),
        otherwise: yup.string()
      })
    }),
    referral: yup.object().shape({
      code: yup.string().when('isShow', {
        is: true,
        then: yup.string().required('Please select an option'),
        otherwise: yup.string()
      }),
      name: yup.string().when('code', {
        is: 'yes',
        then: yup.string().required("Please enter your GP's name"),
        otherwise: yup.string()
      }),
      date: yup.string().when('code', {
        is: 'yes',
        then: yup.string().required('Please enter the date your GP referred you'),
        otherwise: yup.string()
      }),
      file: yup.mixed()
    }),
    therapist: yup.object().shape({
      code: yup.string().when('isShow', {
        is: true,
        then: yup.string().required('Please select an option'),
        otherwise: yup.string()
      }),
      similarExperience: yup.string().when('code', {
        is: (value: any) => ['currently', 'previously'].includes(value),
        then: yup.string().required('Please select an option'),
        otherwise: yup.string()
      })
    }),
    medication: yup.object().shape({
      code: yup.string().when('isShow', {
        is: true,
        then: yup.string().required('Please select an option'),
        otherwise: yup.string()
      })
    })
  });
  return bgFormSchema;
};
export const validationBgForm = (bgDVal: BackgroundFormErrorInterface) => {
  const validationErrors = {} as BackgroundFormErrorInterface;
  try {
    const bgFormSchema = getSchema();
    bgFormSchema.validateSync(bgDVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.map((error: any) => {
        if (error.path) {
          // @ts-ignore
          validationErrors[error.path] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};
