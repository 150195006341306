import styles from './ActivitiesCard.module.scss';
import { ProgramId, SessionActivityInterface } from 'helm/interfaces/program/program';
import classnames from 'classnames';
import { putProgramActivities } from 'helm/utils/http/ClinicianProfileService/program/program';
import { notification } from 'antd';
import { useGetAccessToken } from 'utils/hooks/token';
import { useState } from 'react';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import moment from 'moment';
import LifeBalanceWheelChart from './LifeBalanceWheelChart/LifeBalanceWheelChart';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

interface ActivitiesCardProps {
  activitiesData: SessionActivityInterface[];
  className?: string;
}

const ActivitiesCard = ({ activitiesData, className }: ActivitiesCardProps) => {
  const { token } = useGetAccessToken();
  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const [activityStartDate, setActivityStartDate] = useState<Date>();

  const onClickLink = (link?: string) => {
    if (link) {
      window.location.href = link;
    }
  };

  const handleExploreActivity = async (id: string, link?: string) => {
    try {
      const response = await (
        await putProgramActivities(token, id, clientRecordId, clientProfileId, ProgramId.P2CGambling)
      ).json();
      setActivityStartDate(response.startedAt);
      onClickLink(link);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong!' });
    }
  };

  const surveyResponseChart: { [key: string]: (arg0: SessionActivityInterface['surveyResponse']) => JSX.Element } = {
    lifeBalanceWheel: (response) => <LifeBalanceWheelChart surveyResponse={response} />
  };

  return (
    <div className={classnames(styles.container, className)}>
      {activitiesData.map((activityObj, index) =>
        activityObj.completed ? (
          <>
            <div className={styles.completeCard}>
              <div className={styles.activityTitle}>
                COMPLETED{' '}
                <span className={styles.highlight}>ON {moment(activityObj.completedAt).format('DD MMM YYYY')}</span>
              </div>
              <div className={styles.name}>{activityObj.name}</div>
              <div className={styles.buttonWrapper}>
                <ButtonHelm onClick={() => onClickLink(activityObj.link)} variant="outlined" className={styles.button}>
                  Revisit this activity
                </ButtonHelm>
              </div>
            </div>
            {activityObj.surveyResponse &&
              activityObj.id in surveyResponseChart &&
              surveyResponseChart[activityObj.id](activityObj.surveyResponse)}
          </>
        ) : activityObj.link && (activityObj.startedAt || activityStartDate) ? (
          <div className={styles.checkBoxCard}>
            <div className={styles.activityTitle}>STARTED ON {moment(activityObj.startedAt).format('DD MMM YYYY')}</div>
            <div className={styles.name}>{activityObj.name}</div>
            <div className={styles.desc}>{activityObj.description}</div>
            <div className={styles.buttonWrapper}>
              <ButtonHelm onClick={() => onClickLink(activityObj.link)} className={styles.button}>
                Continue activity
              </ButtonHelm>
            </div>
          </div>
        ) : (
          <div className={styles.checkBoxCard}>
            <div className={styles.name}>{activityObj.name}</div>
            <div className={styles.desc}>{activityObj.description}</div>
            <div className={styles.buttonWrapper}>
              <ButtonHelm
                onClick={() => handleExploreActivity(activityObj.id, activityObj.link)}
                className={styles.button}
              >
                Explore this activity
              </ButtonHelm>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ActivitiesCard;
