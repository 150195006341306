import GPAppointmentTypeSelect from '../GPAppointmentTypeSelect/GPAppointmentTypeSelect';
import styles from './GPBooking.module.scss';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import GPTimeSlot from '../GPTimeSlot/GPTimeSlot';
import GPProfile from '../GPProfile/GPProfile';
import SelectedSlotDetails from '../SelectedSlotDetails/SelectedSlotDetails';

import { Skeleton } from 'antd';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import classNames from 'classnames';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import queryString from 'query-string';
import { useAppSelector } from 'redux/hooks';
import { selectSelectedGPAppointmentType } from 'redux/globalStore/gpBookingSlice';
import { useSearchParams } from 'react-router-dom';

const GPBooking = () => {
  const [searchParams] = useSearchParams();
  const isSurveyCompleted = searchParams.get('completed') === '1';

  const { MHTPSurveyURL, DASS21SurveyURL, GPReviewAppointmentTypeId } = someoneHealthEnvironment();
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { clientRecordId, clientProfileId } = useGetClientDetails();

  const selectedGPAppointmentType = useAppSelector(selectSelectedGPAppointmentType);

  const isGPReviewAppointmentSelected = selectedGPAppointmentType?._id === GPReviewAppointmentTypeId;

  const handleSurveyRedirection = () => {
    const surveyParameters = {
      uem: clientProfileData?.email || '',
      account_id: attachedClinicianDetail?.accountId || '',
      client_record_id: clientRecordId,
      client_profile_id: clientProfileId,
      appointment_type_id: selectedGPAppointmentType?._id
    };

    window.location.href = `${
      !clientProfileData?.gpSurveyCompleted ? MHTPSurveyURL : DASS21SurveyURL
    }?${queryString.stringify(surveyParameters)}`;
  };

  const gpReviewDass21Required = isGPReviewAppointmentSelected && !isSurveyCompleted;
  const showSurveyRequired = !clientProfileData?.gpSurveyCompleted || gpReviewDass21Required;

  return (
    <div className={classNames(styles.container, showSurveyRequired && styles.paddingBottom)}>
      <div className={styles.header}>
        <div className={styles.title}>Book a GP appointment with us</div>
        <div className={styles.description}>
          We make getting a GP referral easy.
          <br />
          <br />
          We have a team of friendly GPs available for video consultations to assess your suitability for a Mental
          Health Treatment Plan (MHTP) and provide you with the necessary referral.
          <br />
          <br />
          Please select below whether you are after your Initial MHTP or if you already have a MTHP and are needing a GP
          Review. You’ll need to attend a GP Review appointment after 6 sessions with your psychologist.
        </div>
      </div>
      <div className={styles.content}>
        <GPAppointmentTypeSelect />
        {isClientProfileLoading || isAttachedClinicianDetailLoading ? (
          <div className={styles.loadingWrapper}>
            <Skeleton active />
          </div>
        ) : !clientProfileData?.gpSurveyCompleted ? (
          <div className={styles.completeActions}>
            <div className={styles.description}>
              You need to complete a Mental Health Treatment Plan Questionnaire before you can schedule an appointment
              with our GPs.
            </div>
            <div className={styles.description}>
              This is a detailed form that gives your GP the information required to make an assessment on your
              eligibility for a Mental Health Treatment Plan.
            </div>
            <div className={styles.description}>
              Once you have completed the questionnaire, you will be able to come back here to select a time for your GP
              appointment.
            </div>
            <ButtonSH onClick={handleSurveyRedirection}>Begin a New Mental Health Assessment Form</ButtonSH>
          </div>
        ) : gpReviewDass21Required ? (
          <div className={styles.completeActions}>
            <div className={styles.description}>
              You need to complete a DASS21 Questionnaire before you can schedule an appointment with our GPs.
            </div>
            <div className={styles.description}>
              Once you have completed the questionnaire, you will be able to come back here to select a time for your GP
              appointment.
            </div>
            <ButtonSH onClick={handleSurveyRedirection}>Begin a New DASS21 Assessment Form</ButtonSH>
          </div>
        ) : (
          <>
            <GPTimeSlot />
            <div className={styles.gpSlotDetailsWrapper}>
              <GPProfile />
              <SelectedSlotDetails />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GPBooking;
