import styles from './PsychologistAvatar.module.scss';
import classnames from 'classnames';

interface PsychologistAvatarProps {
  profileImg: string;
  className?: string;
  classNameImage?: string;
  classNameFrame?: string;
  detailPath: string;
}

const PsychologistAvatar = ({
  profileImg,
  className,
  classNameImage,
  classNameFrame,
  detailPath
}: PsychologistAvatarProps) => {
  return (
    <div className={classnames(styles.container, className && className)}>
      <a href={detailPath} target="_self">
        <div className={classnames(styles.borderFrame, classNameFrame && classNameFrame)}>
          <img
            className={classnames(styles.image, classNameImage && classNameImage)}
            src={profileImg}
            alt={'profile'}
          />
        </div>
      </a>
    </div>
  );
};

export default PsychologistAvatar;
