import { useMemo, useRef, useState } from 'react';
import { Datepicker } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.scss';
import styles from './Calendar.module.scss';
import './MobiscrollCustom.scss';
import moment from 'moment/moment';
import SlotPicker from './components/SlotPicker/SlotPicker';
import TimeFilterDropdown from './components/TimeFilterDropdown/TimeFilterDropdown';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import { TimeSlotBooking } from '../../PsychologistTimeSlot';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import TimeZoneDropdown from 'components/TimeZoneDropdown/TimeZoneDropdown';
import { SOMEONE_HEALTH_TIME_ZONE_LIST } from 'utils/constants/timeZone';

export const displayMonth = 6;

interface CalendarProps {
  onSlotClick: (e: TimeSlotsWithDateInterface) => void;
  timeSlots: TimeSlotBooking['slots'];
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  isAvailabilityListLoading: boolean;
  remainSlots: number;
  isAdvisorySession?: boolean;
  selectedTimeZone?: string;
  handleChangeTimeZone?: (val: string) => void;
  calendarWrapperClassName?: string;
  calendarCardClassName?: string;
  slotPickerCardClassName?: string;
  filterClassNames?: string;
  supportTimeZone?: boolean;
  showOuterDays?: boolean;
}

const timeRangeSlot = [
  {
    id: 'allAvailableTimes',
    label: 'All Available Times'
  },
  {
    id: 'morningSlot',
    label: 'Morning',
    desc: 'before Midday'
  },
  {
    id: 'afternoonSlot',
    label: 'Afternoon',
    desc: 'between 12 - 6pm'
  },
  {
    id: 'eveningSlot',
    label: 'Evening',
    desc: 'after 6pm'
  }
];

const getFilteredSlots = (slots: TimeSlotsWithDateInterface[], timeRange?: string) => {
  switch (timeRange) {
    case 'morningSlot':
      return slots?.filter((i) => i.endTime.endsWith('AM') || i.endTime === '12:00PM');
    case 'afternoonSlot':
      return slots?.filter((i) => i.startTime.endsWith('PM') && (i.endTime < '06:01PM' || i.endTime.startsWith('12')));
    case 'eveningSlot':
      return slots?.filter(
        (i) => i.startTime.endsWith('PM') && i.startTime > '05:59PM' && !i.startTime.startsWith('12')
      );
    case 'allAvailableTimes':
    default:
      return slots;
  }
};

const maxDate = moment().add(displayMonth, 'month');
const minDate = moment();

const allDates = (() => {
  let x = moment();
  let res: any = [];
  while (x.isBefore(maxDate)) {
    res = [...res, x.format('YYYY-MM-DD')];
    x = x.add(1, 'days');
  }
  return res;
})();

const Calendar = ({
  onSlotClick,
  selectedTimeSlots,
  timeSlots,
  isAvailabilityListLoading,
  remainSlots,
  isAdvisorySession,
  selectedTimeZone,
  handleChangeTimeZone,
  calendarWrapperClassName,
  calendarCardClassName,
  slotPickerCardClassName,
  filterClassNames,
  supportTimeZone = true,
  showOuterDays = true
}: CalendarProps) => {
  const { search } = useLocation();
  const { selectedDateTime: selectedDateTimeParams } = queryString.parse(search);
  const [selectedDate, setSelectedDate] = useState<string>(
    (selectedDateTimeParams as string)?.split(',')[0] || moment().format('YYYY-MM-DD')
  );
  const [filter, setFilter] = useState<{ timeRange?: string }>();

  const invalidDates = useRef<Set<string>>(new Set<string>());

  const filteredData = useMemo(() => {
    const filterTimeSlotByDate = (selectedDate: string) => {
      return timeSlots.filter((timeSlotObj) => timeSlotObj.date === selectedDate);
    };
    invalidDates.current = new Set<string>(allDates);
    const map = new Map();
    if (!timeSlots) {
      return map;
    }
    timeSlots.forEach((item) => {
      if (!map.has(item.date)) {
        const values = getFilteredSlots(filterTimeSlotByDate(item.date), filter?.timeRange);
        map.set(item.date, values);
        if (values.length > 0) {
          invalidDates.current.delete(item.date);
        }
      }
    });
    return map;
  }, [filter, timeSlots]);

  return (
    <div
      className={classNames(styles.container, isAdvisorySession && styles.isAdvisorySession, 'someone-health-theme')}
    >
      <div className={classNames(styles.filterContainer, filterClassNames)}>
        <div className={styles.timeFilterContainer}>
          <div className={styles.filterByLabel}>FILTER BY:</div>
          <div className={styles.filterList}>
            <TimeFilterDropdown
              className={styles.timeRange}
              listData={timeRangeSlot}
              leftListAlign
              onChange={(val) => setFilter({ ...filter, timeRange: val.id })}
            />
          </div>
        </div>
        {supportTimeZone && (
          <TimeZoneDropdown
            listData={SOMEONE_HEALTH_TIME_ZONE_LIST}
            selectedValue={
              SOMEONE_HEALTH_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimeZone) ||
              SOMEONE_HEALTH_TIME_ZONE_LIST[0]
            }
            onChangeValue={(value) => handleChangeTimeZone && handleChangeTimeZone(value.id)}
            whiteFont={false}
          />
        )}
      </div>
      <div className={classNames(styles.calendarWrapper, calendarWrapperClassName)}>
        <div className={classNames(styles.calendarCard, calendarCardClassName)}>
          <Datepicker
            calendarType={'month'}
            firstDay={1}
            pages={1}
            responsive={{
              custom: {
                breakpoint: 820,
                pages: 2
              }
            }}
            controls={['calendar']}
            showInput={false}
            isOpen
            min={minDate.format('YYYY-MM-DD')}
            max={maxDate.format('YYYY-MM-DD')}
            invalid={isAvailabilityListLoading ? Array.from(allDates) : [...Array.from(invalidDates.current)]}
            marked={selectedTimeSlots.map((item) => ({ date: moment(item.date).toDate(), color: styles.helmBlue }))}
            value={selectedDate}
            onChange={(e) => setSelectedDate(moment(e.value).format('YYYY-MM-DD'))}
            theme={'ios'}
            themeVariant={'light'}
            display="inline"
            showOuterDays={showOuterDays}
          />
        </div>
        <div className={classNames(styles.slotPickerCard, slotPickerCardClassName)}>
          <SlotPicker
            remainSlots={remainSlots}
            selectedDate={selectedDate}
            slots={filteredData.get(selectedDate)}
            selectedSlots={selectedTimeSlots}
            isLoading={isAvailabilityListLoading}
            onClickSlot={onSlotClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
