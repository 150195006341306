import { useState } from 'react';

import styles from './SQBackground.module.scss';

import { SurveyAssessment } from 'interfaces/Assessment/SurveyInterfaces';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import BackgroundForm from 'components/BackgroundForm/BackgroundForm';
import { BackgroundResponse } from 'interfaces/Assessment/OnboardingAssessment';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import { putBackgroundResponses } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { notification } from 'antd';
import { SurveyStep } from '../../SurveyQuestionForm';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useNavigate } from 'react-router-dom';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

interface SQBackgroundProps {
  clinicianDetail: GetAttachedClinicianDetails;
  token: string;
  surveyData: SurveyAssessment;
  setSurveyStep: (val: SurveyStep['step']) => void;
  type?: string;
}

const SQBackground = ({ clinicianDetail, token, surveyData, setSurveyStep, type }: SQBackgroundProps) => {
  const navigate = useNavigate();
  const { ONBOARDING } = useRoutesGenerator();
  const surveyQuestion = surveyData.questionsResponses;

  const [bgPageSubmitStatus, setBgPageSubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const handleCompleteBackground = async (val: BackgroundResponse, file: File) => {
    setBgPageSubmitStatus('active');

    try {
      const bgData = val;
      if (val.referral?.code === 'yes') {
        const uploadedFile =
          file.size > 0
            ? ((await uploadDocumentWithEncryption(token, file)) as {
                bucketName: string;
                fileName: string;
                fileUrl: string;
              })
            : undefined;
        bgData.referral = {
          ...val.referral,
          file: uploadedFile
        };
      }

      await putBackgroundResponses(token, surveyQuestion._id, bgData);
      setTimeout(() => {
        setBgPageSubmitStatus('finished');

        const ifHaveSurveyQuestion = surveyQuestion.assessment.onboardingQuestions.assessmentField.length > 0;
        handleSetSurveyStep(ifHaveSurveyQuestion);
      }, 500);
      setTimeout(() => {
        setBgPageSubmitStatus('');
      }, 1000);
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Background details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setBgPageSubmitStatus('');
      }, 1000);
    }
  };

  const handleSetSurveyStep = (ifHaveSurveyQuestion: boolean) => {
    if (ifHaveSurveyQuestion) {
      setSurveyStep('surveyQuestion');
    } else if (type === 'onboard') {
      navigate(`${ONBOARDING.BASE}?type=onboarding`);
    } else {
      setSurveyStep('finish');
    }
  };

  return (
    <div className={IS_SOMEONE_HEALTH_APP ? styles.shBackgroundContainer : styles.backgroundContainer}>
      <BackgroundForm
        clinicianDetail={clinicianDetail}
        backgroundResponse={surveyQuestion?.assessment.onboardingQuestions.backgroundResponse}
        pageSubmitStatus={bgPageSubmitStatus}
        onCompleteBackground={handleCompleteBackground}
        noHeader
        autoHeight
      />
    </div>
  );
};

export default SQBackground;
