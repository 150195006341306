import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CreateProfileForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import Button from 'components/v2/Button/Button';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import Loading from 'components/Loading/Loading';
import { clientRecordsInterface, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { useReserveAppointmentData } from 'helm/utils/hooks/localData';
import { timeZoneLocalStorage } from '../../../PractitionerListing/PractitionerListing';
import moment from 'moment';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import FormikSelect from '../../../../../components/Select/FormikSelect';
import { SpecialistTheme } from 'helm/interfaces/Filter/searchFilter';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import { isPowerToChangeProgram } from 'utils/checkEngageProgramId';
import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import {
  getAuMobile,
  initialCustomer,
  passwordValidationSchema,
  specialistList,
  validationSchema,
  isValidVoucherCode,
  p2cThemeOptions,
  generateP2CGamblingTag
} from './constants';
import FormikRadio from 'components/Radio/FormikRadio';

const VOUCHER_CODE_ERROR_ICON =
  // icon after code expired:
  // 'sports_score'
  'front_hand';

interface CreateProfileFormProps {
  accountId: string;
  onComplete: (input: { clientRecord: clientRecordsInterface; authToken: string }) => void;
}

const CreateProfileForm = ({ accountId, onComplete }: CreateProfileFormProps) => {
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [hasVoucherCode, setHasVoucherCode] = useState(false);
  const URLs = helmEnvironment();
  // const [receiveMail, setReceiveMail] = useState(true);
  // const [receiveSMS, setReceiveSMS] = useState(true);
  const clientTimeZone = localStorage.getItem(timeZoneLocalStorage);
  const [showPwError, setShowPwError] = useState(false);
  const [pwScore, setPwScore] = useState(0);
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const [isUsedEmail, setIsUsedEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { clinicianId, appointmentTypeInfo, programId } = useReserveAppointmentData();
  const [isVoucherCodeValid, setIsVoucherCodeValid] = useState(false);
  const [voucherCodeError, setVoucherCodeError] = useState('');

  const isPowerToChangeAppointment = useMemo(() => isPowerToChangeProgram(programId), [programId]);

  const handleSubmitVoucherCode = useCallback(
    async (voucherCode: string) => {
      if (voucherCode.length !== 6) {
        setVoucherCodeError('The code consists of 6 alphanumeric characters');
        return;
      }

      const valid = isValidVoucherCode(voucherCode);

      setIsVoucherCodeValid(valid);

      if (!valid) {
        setVoucherCodeError(
          // error after code expired:
          // 'Sorry, participation under this code has ended. You can still join the program, but it will no longer be funded.'
          `This code doesn’t seem correct. Please check and re-enter it, or you can join the program without a valid code. You can also contact Helm on ${URLs.HelpSupportTel} or ${URLs.HelmSupportMail} if you have questions or need assistance.`
        );
      }
    },
    [URLs.HelmSupportMail, URLs.HelpSupportTel]
  );

  const handleOnSubmit = async (values: typeof initialCustomer) => {
    const formatMobile = getAuMobile(values.mobileNumber);
    setIsSubmitting(true);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, theme, voucherCode } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');

          const payloadMassage = {
            clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              tag:
                isPowerToChangeAppointment && theme === SpecialistTheme.Gambling
                  ? generateP2CGamblingTag(hasVoucherCode, voucherCode.join(''))
                  : theme,
              timeZone: clientTimeZone || AU_TIME_ZONE_LIST[0].id,
              communicationPreference: CommunicationPreference.NoPreference,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId
            }
          };
          const callPatientSignup = await postPatientSignupWithoutInvitation(accountId, payloadMassage);
          setIsSubmitting(false);
          const { clientRecord, authToken } = await callPatientSignup.json();
          onComplete({
            clientRecord,
            authToken,
            ...(isPowerToChangeAppointment &&
              theme === SpecialistTheme.Gambling &&
              hasVoucherCode && {
                voucherCode: voucherCode.join('')
              })
          });
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to signup account'
          });
        }
      } else {
        setIsSubmitting(false);
        setIsUsedEmail(true);
      }
    } else {
      setIsSubmitting(false);
      setInvalidMobileNumber(true);
    }
  };

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialCustomer>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.header}>
          Welcome to <span className={styles.highlight}>Helm</span>
        </div>
        <div className={styles.subHeader}>
          Create your <b>secure</b> account and take your first step towards a better future.
        </div>
        <div className={styles.loginText}>
          Already have an account?
          <Link to={`/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </Link>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={validationSchema.concat(passwordValidationSchema(pwScore))}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, isValid, validateForm, setFieldValue }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput inputClass={styles.customInput} label="First Name*" name="firstName" required />
                <FormikInput inputClass={styles.customInput} label="Last Name*" name="lastName" required />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                {isPowerToChangeAppointment ? (
                  <>
                    <FormikRadio
                      label={'Which program are you wanting to enrol in?*'}
                      labelClass={styles.customRadioLabel}
                      radioWrapperClass={styles.customRadioWrapper}
                      name="theme"
                      options={p2cThemeOptions}
                    />
                    {values.theme === SpecialistTheme.Gambling && (
                      <>
                        <div className={styles.hasVoucherCode}>
                          <div className={styles.checkBoxText}>
                            <CheckBox
                              id="hasVoucherCode"
                              value={hasVoucherCode}
                              onChange={(e) => setHasVoucherCode(e.target.checked)}
                              className={styles.checkBox}
                              label="Did you hear about us through Crown Casino?"
                            />
                          </div>
                        </div>
                        {hasVoucherCode && (
                          <div
                            className={classnames(
                              styles.voucherCode,
                              isVoucherCodeValid && styles.valid,
                              voucherCodeError && styles.error
                            )}
                          >
                            <div className={styles.label}>Please enter your participant code (optional)</div>
                            <TwoFAInput
                              inputContainerClassName={styles.twoFAInputContainer}
                              inputWrapperClassName={styles.twoFAInputWrapper}
                              inputClassName={styles.twoFAInput}
                              error={''}
                              value={values.voucherCode}
                              isText
                              isTouched
                              isVerified={isVoucherCodeValid}
                              onChangeValue={(value) => {
                                setVoucherCodeError('');
                                setIsVoucherCodeValid(false);
                                setFieldValue('voucherCode', value);
                              }}
                              onSubmitOtp={handleSubmitVoucherCode}
                            />
                            {voucherCodeError && (
                              <div className={styles.voucherCodeError}>
                                <i className={`material-icons-outlined ${styles.icon}`}>{VOUCHER_CODE_ERROR_ICON}</i>
                                <div>{voucherCodeError}</div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <FormikSelect
                    className={styles.customSelect}
                    labelClass={styles.customSelectLabel}
                    errorClassName={styles.customSelectError}
                    label={'I am seeking behavioural health support for*'}
                    placeholder={'Select main focus area'}
                    isSearchable={false}
                    name="theme"
                    options={specialistList}
                    styles={{
                      control: (controlBase: any) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                        boxShadow: 'none'
                      })
                    }}
                  />
                )}
                <HelmMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={IS_HELM_APP ? '0482 666 666' : ''}
                  phonePrefix={IS_HELM_APP ? '04' : ''}
                  onChange={() => invalidMobileNumber && setInvalidMobileNumber(false)}
                  isMobileValid={!invalidMobileNumber}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={isUsedEmail ? 'Email already in use' : ''}
                  onChange={() => isUsedEmail && setIsUsedEmail(false)}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.collectionNotice}>
                <div className={styles.title}>Collection Notice</div>
                <div className={styles.text}>
                  The information you provide to Caraniche, including any personal information as defined in the Privacy
                  Act 1988 (Cth) (Privacy Act) and information about actual or attempted access to our online platform
                  (including our websites and applications) will be used by Caraniche for the purposes of:
                  <ul>
                    <li>providing information and services to you;</li>
                    <li>ensuring that the website and application are working as they should;</li>
                    <li>and marketing.</li>
                  </ul>
                  We may also use your Personal Information for secondary purposes closely related to the primary
                  purpose, in circumstances where you would reasonably expect such use or disclosure (e.g. to
                  investigate complaints).
                  <br />
                  <br />
                  Caraniche may share this information with third parties (healthcare service providers, community
                  service providers, government agencies, and/or funding agencies) where you consent to the use or
                  disclosure for the purpose of facilitating care and to enable the payment for services; and where
                  required or authorised by law.
                  <br />
                  <br />
                  If Caraniche does not collect this information, it may impact the outcome of the services provided.
                  <br />
                  <br />
                  Information about the collection, use, disclosure and storage of personal information by Caraniche,
                  and information about how to contact us, is available in our{' '}
                  <a href={URLs.HelmPrivacyPolicy}>Privacy Statement</a>. This includes information on how you can
                  access and seek corrections to your personal information and about complaints procedures.
                </div>
              </div>
              <div className={styles.ageConfirm}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="ageConfirm"
                    value={ageConfirm}
                    onChange={(e) => setAgeConfirm(e.target.checked)}
                    className={styles.checkBox}
                    label={`I confirm I am over the age of 18 and agree to Helm's <a target="_blank" href=${URLs.HelmPrivacyPolicy}>Privacy Statement</a> and <a target="_blank" href=${URLs.HelmTermAndConditions}>Terms & Conditions</a>`}
                  />
                </div>
              </div>
              <Button
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, initialCustomer) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      handleOnSubmit(values);
                    }
                  });
                }}
              >
                Create Your Profile
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateProfileForm;
