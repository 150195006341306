import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import classNames from 'classnames';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import PatientContentLayout from '../../../components/PatientContentLayoutV2/PatientContentLayout';

const UpdateProfile = () => {
  return (
    <CaWHelmetWrapper title={`Caraniche at Work - Profile`}>
      <PatientContentLayout className={styles.container}>
        <CaWHeader withPadding withMenu homeLink blueMenu />
        <UpdateProfileContent contentClassName={classNames(styles.content, 'caw-theme')} />
      </PatientContentLayout>
    </CaWHelmetWrapper>
  );
};

export default UpdateProfile;
