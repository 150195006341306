import parsePhoneNumber from 'libphonenumber-js';

export const getMobileNumberCountry = (mobileNumber: string): string => {
  const { country, countryCallingCode } = parsePhoneNumber(mobileNumber) || {};

  return country || countryCallingCode?.padStart(countryCallingCode.length + 1, '+') || '';
};

export const getRawMobileNumber = (mobileNumber: string): string => {
  const parsedPhoneNumber = parsePhoneNumber(mobileNumber);

  return (
    parsedPhoneNumber
      ?.format('NATIONAL', {
        // add 0 if it's required
        nationalPrefix: true
      })
      .replaceAll(/\D/g, '') || mobileNumber
  );
};
