import { ReactNode } from 'react';
import { configUK } from './configUK';
import { configAU } from './configAU';
import { configNZ } from './configNZ';
import { configSA } from './configSA';

export interface configInterface {
  countryCode: string;
  moneyIcon: string;
  currencySymbol: string;
  currencyCode: string;
  taxLabel: string;
  taxRateLabel: string;
  codeLabel: string;
  mobileCountryCode: string;
  defaultTimezone: string;
  contactMaskNumber: string;
  contactMaskNumberWithX: string;
  emergencyNumber: string;
  lifeLineNumber: string;
  practiceSettings: {
    registrationNumberLabel: string;
  };
  terms: {
    privacy: ReactNode;
  };
}

interface countryObject {
  [key: string]: configInterface;
}

export const GAE_REGION = process.env.REACT_APP_GAE_REGION || 'au';

const configMapping: countryObject = {
  gb: configUK,
  au: configAU,
  nz: configNZ,
  sa: configSA
};

export const config = {
  ...configMapping[GAE_REGION]
};
