import classNames from 'classnames';
import SummaryItem from 'SomeoneHealth/pages/BookingConfirmation/components/Summary/components/SummaryItem/SummaryItem';
import { reservedHelmPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { toWord } from 'utils/generateCamelCase';
import styles from './PartnerBookingSummaryItem.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';

interface PartnerBookingSummaryItemProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  containerClassName?: string;
  contentClassName?: string;
}

export const sortByCreatedAt = (a: AppointmentSlot, b: AppointmentSlot) => {
  const aCreatedAt = moment(a.createdAt);
  const bCreatedAt = moment(b.createdAt);
  return aCreatedAt.isBefore(bCreatedAt) ? 1 : aCreatedAt.isAfter(bCreatedAt) ? -1 : 0;
};

const PartnerBookingSummaryItem = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  containerClassName,
  contentClassName
}: PartnerBookingSummaryItemProps) => {
  const { psychologistName } = useReserveAppointmentData(reservedHelmPartnerReferral);

  return (
    <div className={classNames(styles.container, containerClassName)}>
      {isReservedAppointmentFetching ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <div className={classNames(styles.content, contentClassName)}>
          {reservedAppointments.sort(sortByCreatedAt).map((appointmentObj, index) => (
            <SummaryItem
              key={index}
              index={index + 1}
              bookingName={appointmentObj.sessionTypeName || ''}
              name={psychologistName || ''}
              startTime={appointmentObj.startTime}
              endTime={appointmentObj.endTime}
              date={appointmentObj?.date || ''}
              deliveryMode={appointmentObj.deliveryType ? toWord(appointmentObj.deliveryType) : ''}
              isLast={reservedAppointments.length === index + 1}
              showBottomSeparation
              showTopSeparation
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PartnerBookingSummaryItem;
