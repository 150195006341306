import { useState } from 'react';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import PracticeTimeSlot from './components/PracticeTimeSlot/PracticeTimeSlot';
import { Modal } from 'antd';
import JoinWaitlistForm from 'Ease/components/JoinWaitlistForm/JoinWaitlistForm';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';

const PracticeBookingPage = () => {
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  return (
    <EaseHelmetWrapper title={`EASE Wellbeing | Therapy & Counselling Experts in London`}>
      <PracticeTimeSlot onShowWaitlistForm={() => setShowWaitlistForm(true)} />
      <TacklitFooter linkToTacklit />

      {/* Join waitlist modal */}
      <Modal
        width={700}
        visible={showWaitlistForm}
        onCancel={() => {
          setShowWaitlistForm(false);
        }}
        bodyStyle={{ padding: '40px 80px', top: 50 }}
        footer={null}
        destroyOnClose
      >
        <JoinWaitlistForm
          onComplete={() => {
            setShowWaitlistForm(false);
          }}
        />
      </Modal>
    </EaseHelmetWrapper>
  );
};

export default PracticeBookingPage;
