import { ReportTemplateCaseNoteField } from 'interfaces/Report/reportTemplate';

import styles from './QuestionWidget.module.scss';

interface QuestionWidgetProps {
  data: { questionDetails: ReportTemplateCaseNoteField };
}

const QuestionWidget = ({ data }: QuestionWidgetProps) => (
  <div className={styles.container}>
    <div className={styles.label}>{data.questionDetails.name}</div>
    {data.questionDetails.type === 'multipleChoice' ? (
      <>
        {data.questionDetails.multiple ? (
          <div className={styles.value}>
            {data.questionDetails.value ? data.questionDetails.value.map((val: any) => val.label).join(', ') : '-'}
          </div>
        ) : (
          <div className={styles.value}>{data.questionDetails.value ? data.questionDetails.value.label : '-'}</div>
        )}
      </>
    ) : (
      <div className={styles.value}>{data.questionDetails.value ? data.questionDetails.value : '-'}</div>
    )}
  </div>
);

export default QuestionWidget;
