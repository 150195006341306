import styles from '../../CoupleForm.module.scss';
import { ChangeEvent, KeyboardEvent } from 'react';
import HelpOutLineWithTooltips from 'components/HelpOutlineTooltip/HelpOutlineTooltip';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import classnames from 'classnames';
import HelmMobileMaterialInput from 'helm/components/Input/HelmMobileMaterialInput';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { convertDateFormat } from 'pages/SignUp/SignUpForm/components/BasicDetails/actions/dateChecker';

interface Partner2FormProps {
  formValues: any;
  setFormValues: any;
  errorMessage: any;
}

const Partner2Form = ({ formValues, setFormValues, errorMessage }: Partner2FormProps) => {
  const handleKeyDownDateChange = (
    event: KeyboardEvent<HTMLInputElement>,
    id: 'referralDoB' | 'dateOfBirth' | 'partnerDoB'
  ) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      const newValue = formValues.referralDoB?.slice(0, formValues.referralDoB.length - 1);
      const newFormValues = {
        ...formValues,
        [id]: newValue
      };
      setFormValues(newFormValues);
    }
  };

  const handleDateChange = (dateValue: string, id: 'referralDoB' | 'dateOfBirth' | 'partnerDoB') => {
    const newDateFormat = convertDateFormat(dateValue);
    const newFormValues = {
      ...formValues,
      [id]: newDateFormat
    };
    setFormValues(newFormValues);
  };

  return (
    <>
      <div className={styles.title}>About {formValues.isForMySelf ? 'your' : 'their'} partner</div>
      <div>
        <div className={classnames(styles.fieldContainer, errorMessage?.partnerFirstName && styles.fieldError)}>
          <MaterialInput
            label={`Partner${!formValues.isForMySelf ? ' 2' : ''} First Name*`}
            id={'partnerFirstName'}
            value={formValues.partnerFirstName}
            onChange={(event) => {
              setFormValues({ ...formValues, partnerFirstName: event.target.value });
            }}
            maxLength={200}
            required
          />
          {errorMessage?.partnerFirstName && <div className={styles.fieldError}>{errorMessage?.partnerFirstName}</div>}
        </div>
        <div className={classnames(styles.fieldContainer, errorMessage?.partnerLastName && styles.fieldError)}>
          <MaterialInput
            label={`Partner${!formValues.isForMySelf ? ' 2' : ''} Last Name*`}
            id={'partnerLastName'}
            value={formValues.partnerLastName}
            onChange={(event) => {
              setFormValues({ ...formValues, partnerLastName: event.target.value });
            }}
            maxLength={200}
            required
          />
          {errorMessage?.partnerLastName && <div className={styles.fieldError}>{errorMessage?.partnerLastName}</div>}
        </div>
        <div className={classnames(styles.fieldContainer, styles.fieldWithTooltip)}>
          <div className={styles.leftContainer}>
            <MaterialInput
              placeholder={'DD/MM/YYYY'}
              id={`partnerDateOfBirth`}
              label={`${formValues.partnerFirstName ? `${formValues.partnerFirstName}'s` : ''} Date of Birth`}
              onKeyDown={(e) => handleKeyDownDateChange(e, 'partnerDoB')}
              onChange={(e) => handleDateChange(e.target.value, 'partnerDoB')}
              value={formValues.partnerDoB}
              type={'tel'}
              pattern={'[0-9\\/]*'}
              maxLength={10}
              autoComplete={'off'}
            />
            {errorMessage?.partnerDoB && <div className={styles.fieldError}>{errorMessage?.partnerDoB}</div>}
          </div>
          <HelpOutLineWithTooltips
            id="partnerDateOfBirth-tooltip"
            text="Why we ask?"
            desc={<p>We ask this to help us match you to services that are age appropriate</p>}
            hideIcon
          />
        </div>
        <div className={classnames(styles.fieldContainer, errorMessage?.partnerEmail && styles.fieldError)}>
          <MaterialInput
            label={'Partner Email Address*'}
            id={'partnerEmail'}
            value={formValues.partnerEmail}
            onChange={(event) => {
              setFormValues({ ...formValues, partnerEmail: event.target.value });
            }}
            maxLength={200}
            required
          />
          {errorMessage?.partnerEmail && <div className={styles.fieldError}>{errorMessage?.partnerEmail}</div>}
        </div>
        <div className={styles.phoneContainer}>
          {IS_HELM_APP ? (
            <HelmMobileMaterialInput
              inputClass={styles.customInput}
              id={'partnerContactNumber'}
              name={'contactNumber'}
              label={'Partner Contact number*'}
              mobilePattern={isDevelopmentENV() ? '+__ __-____-____' : '____ ___ ___'}
              placeholder="0482 666 666"
              phonePrefix={'04'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFormValues({ ...formValues, partnerContactNumber: e.target.value });
              }}
              isMobileValid={!errorMessage?.partnerContactNumber}
              required
            />
          ) : (
            <MaterialPhoneInput
              id={'partnerContactNumber'}
              label={'Partner Contact number*'}
              onChange={(e: string) => {
                setFormValues({ ...formValues, partnerContactNumber: e });
              }}
              value={formValues.partnerContactNumber}
              isError={!!errorMessage?.partnerContactNumber}
            />
          )}
          {errorMessage?.partnerContactNumber && (
            <div className={styles.fieldError}>{errorMessage?.partnerContactNumber}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Partner2Form;
