export const useEaseRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    BOOKING: {
      BASE: `${initPath}/booking`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      CONFIRM_BOOKING: `${initPath}/booking-confirm`,
      WELCOME_BOOKING: `${initPath}/welcome`
    },
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    REPORT: {
      VIEW: `${initPath}/report/:reportId`
    },
    AVATAR: `${initPath}/avatar`,
    UPDATE_PROFILE: `${initPath}/profile`,
    WAITLIST: `${initPath}/waitlist`,
    HOME: '/',
    APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`,
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    REFERRAL: `${initPath}/referral`,
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`
  };
};
