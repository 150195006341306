import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import styles from './HelmTokenAuthWrapper.module.scss';
import { useHelmPartnerRoutesGenerator } from '../../utils/Path/HelmPartnerRoutesGenerator';

interface AccessTokenAuthWrapperProps {
  children?: any;
}

const HelmTokenAuthWrapper = ({ children }: AccessTokenAuthWrapperProps) => {
  const navigate = useNavigate();
  const { TOKEN } = useHelmPartnerRoutesGenerator();

  useEffect(() => {
    const getPartnerCode = localStorage.getItem(PARTNER_CODE_STORAGE);
    if (!getPartnerCode) {
      navigate(TOKEN.BASE);
    }
  }, [navigate, TOKEN]);

  return <div className={styles.container}>{children}</div>;
};

export default HelmTokenAuthWrapper;
