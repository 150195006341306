import { useState, useEffect } from 'react';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import ConsentHeader from '../components/ConsentHeader/ConsentHeader';
import { consentFormInterface, SignatureInterface } from 'interfaces/ConsentForm/consentForm';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import queryString from 'query-string';
import ConsentContent from '../components/ConsentContent/ConsentContent';
import { postConsentFormOnboarding } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { notification } from 'antd';
import { useFetchConsentOnboarding } from './hooks/GetConsentOnboarding';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

import styles from './ConsentFormOnboarding.module.scss';
import classNames from 'classnames';
import {
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from '../../../utils/hooks/AccountInfo/clientDetails';

const ConsentFormOnboarding = ({ className }: { className?: string }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParam = queryString.parse(search);
  const queryParamToken = queryParam.token as string;

  if (!queryParamToken) {
    navigate(`/403`);
  }
  const { FACILITATED } = useRoutesGenerator();
  const { consentFormData, isConsentFormDataLoading } = useFetchConsentOnboarding(queryParamToken);
  const [consentFormDetail, setConsentFormDetail] = useState({} as consentFormInterface);
  const [submitBtnStatus, setSubmitBtnStatus] = useState<'' | 'active' | 'finished'>('');
  const [errorMessage, setErrorMessage] = useState({
    clientSignature: false,
    otherSignature: false
  });

  useEffect(() => {
    setConsentFormDetail(consentFormData);
  }, [consentFormData]);

  const handleChangeClientSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentFormDetail,
      signature: {
        ...consentFormDetail.signature,
        client: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      clientSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentFormDetail(newConsentDetail);
  };

  const handleChangeOtherSignature = (signVal: SignatureInterface) => {
    const newConsentDetail = {
      ...consentFormDetail,
      signature: {
        ...consentFormDetail.signature,
        other: signVal
      }
    };
    const newErrorMessage = {
      ...errorMessage,
      otherSignature: !(signVal.textVal || signVal.drawVal)
    };
    setErrorMessage(newErrorMessage);
    setConsentFormDetail(newConsentDetail);
  };

  const validation = () => {
    const clientSignatureValidate = consentFormDetail.signature?.client
      ? consentFormDetail.signature.client.type &&
        (consentFormDetail.signature.client.textVal || consentFormDetail.signature.client.drawVal)
      : true;
    const otherSignatureValidate = consentFormDetail.signature?.other
      ? consentFormDetail.signature.other.type &&
        (consentFormDetail.signature.other.textVal || consentFormDetail.signature.other.drawVal)
      : true;

    const errorMessage = {
      clientSignature: !clientSignatureValidate,
      otherSignature: !otherSignatureValidate
    };
    setErrorMessage(errorMessage);
    return clientSignatureValidate && otherSignatureValidate;
  };

  const handleSubmitConsentForm = async () => {
    if (validation()) {
      setSubmitBtnStatus('active');
      try {
        await postConsentFormOnboarding(queryParamToken, consentFormDetail);
        setSubmitBtnStatus('finished');
        setTimeout(() => {
          notification.success({
            message: 'Consent form submitted',
            duration: 2,
            closeIcon: <span className="success">OK</span>
          });
          setSubmitBtnStatus('');
          navigate(`${FACILITATED.ONBOARDING}?token=${queryParamToken}`);
        }, 2000);
      } catch (ex) {
        console.error(ex);
        setSubmitBtnStatus('');
        notification.error({
          message: 'Submit consent form fail',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  return (
    <div
      className={classNames(
        IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme',
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      {isConsentFormDataLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <div className={classNames(styles.container, className)}>
          <ContentLayout className={classNames(styles.content, IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme')}>
            <ConsentHeader />
            <ConsentContent
              consentFormDetail={consentFormDetail}
              handleChangeClientSignature={handleChangeClientSignature}
              handleChangeOtherSignature={handleChangeOtherSignature}
              handleSubmitConsentForm={handleSubmitConsentForm}
              submitBtnStatus={submitBtnStatus}
              errorMessage={errorMessage}
            />
          </ContentLayout>
        </div>
      )}
    </div>
  );
};

export default ConsentFormOnboarding;
