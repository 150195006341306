import { useEffect, useState } from 'react';

import styles from './PracticeTimeSlot.module.scss';
import StepTitle from './components/StepTitle/StepTitle';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import Calendar from './components/Calendar/Calendar';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AvailabilityAppointmentTypes,
  DeliveryType,
  TimeSlotsWithDateInterface,
  useAvailabilityWithTimeZone,
  useFetchAvailabilityAppointmentTypes
} from 'utils/hooks/appointment';
import { notification, Skeleton } from 'antd';
import { scrollToView } from 'utils/scrollToView';
import { postReservedAppointment } from 'utils/http/appointment';
import { useSetReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import NoAvailableMessageBox from 'Ease/components/NoAvailableMessageBox/NoAvailableMessageBox';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { isSlotSelected } from './components/Calendar/components/SlotPicker/SlotPicker';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { useEaseToken } from 'Ease/utils/hooks/useEaseToken';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import { EaseContactUrl, EaseDefaultTimezone } from 'Ease/utils/EaseEnv/EaseEnv';
import BookingSession from './components/BookingSession/BookingSession';
import momentTz from 'moment-timezone';
import { MOMENTJS_FORMAT_DATE } from 'components/AvailableTimeSelect/constants';
import { useEaseUserContext } from 'Ease/contexts/EaseUserContextProvider';
import { fetchClaimReservation } from 'Ease/utils/api/fetchClaimReservation';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import { getEngageDefaultAppointmentDeliveryType } from 'utils/appointment';

const massageTimeSlotReverse = (
  timeSlot: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.startTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();
  const clientEndTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.endTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();

  return {
    ...timeSlot,
    date: clientStartTimeZone.format(MOMENTJS_FORMAT_DATE),
    endDate: clientEndTimeZone.format(MOMENTJS_FORMAT_DATE),
    startTime: clientStartTimeZone.format('HH:mm'),
    endTime: clientEndTimeZone.format('HH:mm'),
    startDateTime: clientStartTimeZone.toDate(),
    endDateTime: clientEndTimeZone.toDate()
  };
};

export interface TimeSlotBooking {
  accountId: string;
  sessionTypeId: string;
  slots: TimeSlotsWithDateInterface[];
  returnUrl: string;
  cancelUrl: string;
}

interface PracticeTimeSlotProps {
  onShowWaitlistForm: () => void;
}

const PracticeTimeSlot = ({ onShowWaitlistForm }: PracticeTimeSlotProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { practiceInfo } = useEaseUserContext();
  const { setReserveAppointmentData } = useSetReserveAppointmentData();
  const { token } = useEaseToken();

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();

  const { availabilityAppointmentTypes: fullAvailabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } =
    useFetchAvailabilityAppointmentTypes({
      accountId: practiceInfo.accountId,
      clinicianId: ''
    });
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<AvailabilityAppointmentTypes[]>([]);
  const [isNewClient, setIsNewClient] = useState<boolean>();
  const { HOME, SIGNUP } = useEaseRoutesGenerator();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { selectedDateTime }: { selectedDateTime?: string } = queryString.parse(search);

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AvailabilityAppointmentTypes | undefined>();
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType>(DeliveryType.VideoCall);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);
  const [isInitParamsUsed, setIsInitParamsUsed] = useState(false);
  const [isProcessingReservation, setIsProcessingReservation] = useState(false);

  const handleChangeAppointmentType = (valItem: AvailabilityAppointmentTypes) => {
    if (valItem.name !== selectedAppointmentType?.name) {
      setSelectedTimeSlots([]);
    }
    setSelectedAppointmentType(valItem);
    setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(valItem?.deliveryOptions || []));
  };

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailabilityWithTimeZone({
    appointmentTypeId: selectedAppointmentType?._id!,
    accountId: practiceInfo.accountId,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(12, 'month').format('YYYY-MM-DD'),
    clinicianId: '',
    timeZone: EaseDefaultTimezone,
    allowPracticeClinicianId: true
  });

  useEffect(() => {
    if (!isAvailabilityAppointmentTypesLoading && fullAvailabilityAppointmentTypes) {
      const newFilteredAppointmentType = fullAvailabilityAppointmentTypes.filter(
        ({ bookingRules }: AvailabilityAppointmentTypes) =>
          isNewClient ? bookingRules?.new.available : bookingRules?.existing.available
      );

      const appointmentType = newFilteredAppointmentType?.[0];
      setSelectedAppointmentType(appointmentType);
      const allAppointmentType: string[] = [];
      newFilteredAppointmentType?.map((obj) => obj.deliveryOptions.map((typeObj) => allAppointmentType.push(typeObj)));
      setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(appointmentType?.deliveryOptions || []));
      setAvailabilityAppointmentTypes(newFilteredAppointmentType);
    }
  }, [isAvailabilityAppointmentTypesLoading, isNewClient, fullAvailabilityAppointmentTypes]);

  useEffect(() => {
    if (!isAvailabilityListLoading && appointmentAvailability && !isInitParamsUsed) {
      const [date, startTime, endTime] = selectedDateTime?.split(',') || [];
      if (date && startTime && endTime) {
        const getTimeSlotInfo = appointmentAvailability.timeSlots.find(
          (item) => item.date === date && item.startTime === startTime && item.endTime === endTime
        );
        getTimeSlotInfo && setSelectedTimeSlots([getTimeSlotInfo]);
      }
      setIsInitParamsUsed(true);
    }
  }, [appointmentAvailability, isAvailabilityListLoading, selectedDateTime, isInitParamsUsed]);

  useEffect(() => {
    if (!isClientBookingRuleLoading) {
      const checkNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;
      setIsNewClient(checkNewClient);
    }
  }, [clientBookingRule, isAuthenticated, isClientBookingRuleLoading]);

  useEffect(() => {
    if (selectedDateTime) {
      scrollToView('EaseTimeSlot');
    }
  }, [selectedDateTime]);

  const onContinue = async () => {
    if (selectedAppointmentType) {
      setIsProcessingReservation(true);
      try {
        const timeSlots = selectedTimeSlots.map((item) => massageTimeSlotReverse(item, EaseDefaultTimezone));

        const payload = {
          isNewClient: clientBookingRule === BookingRuleType.New || !isAuthenticated,
          slots: timeSlots,
          deliveryType: selectedDeliveryType,
          appointmentTypeId: selectedAppointmentType._id,
          ...(!selectedAppointmentType.isAdvisory && {
            clinicianId: selectedTimeSlots[0].clinicianId || ''
          })
        };
        const res = await postReservedAppointment(practiceInfo.accountId, payload);
        if (res.statusCode === 200) {
          const reservedAppointmentData = await res.json();
          // claim here got login user
          if (isAuthenticated) {
            await fetchClaimReservation({
              accountId: practiceInfo.accountId,
              reserveId: reservedAppointmentData.reserveId,
              authToken: token!,
              shouldGenerateCheckoutSession: false
            });
          }
          setReserveAppointmentData({
            reserveId: reservedAppointmentData.reserveId,
            clinicianId: reservedAppointmentData.clinicianId,
            accountId: practiceInfo.accountId,
            appointmentTypeInfo: {
              name: selectedAppointmentType.name || '',
              description: selectedAppointmentType.description || '',
              rate: selectedAppointmentType.rate || 0,
              isAdvisory: selectedAppointmentType.isAdvisory || false,
              deliveryType: selectedDeliveryType,
              otherInstructions: selectedAppointmentType.otherInstructions,
              timeSlot: timeSlots,
              sessionTypeId: selectedAppointmentType._id
            },
            programId: selectedAppointmentType.programId,
            psychologistName: practiceInfo.name
          });
          scrollToView('EaseHeader');
          if (isAuthenticated) {
            navigate(SIGNUP.CONFIRM_BOOKING);
          } else {
            navigate(SIGNUP.BASE);
          }
        } else if (res.statusCode === 409) {
          const conflictAppointmentData = await res.json();
          const conflictSlotList: TimeSlotsWithDateInterface[] = conflictAppointmentData.conflictingSlots || [];

          const timeSlotWithStatus = selectedTimeSlots.map((appointmentListObj) => {
            const startTimeSlot = moment(appointmentListObj.startTime, 'hh:mmA').format('HH:mm');
            const endTimeSlot = moment(appointmentListObj.endTime, 'hh:mmA').format('HH:mm');

            const isConflictSlot = conflictSlotList.some(
              (slotObj) =>
                slotObj.date === appointmentListObj.date &&
                slotObj.startTime === startTimeSlot &&
                slotObj.endTime === endTimeSlot
            );

            return {
              ...appointmentListObj,
              isConflict: isConflictSlot
            };
          });
          setSelectedTimeSlots(timeSlotWithStatus);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to reserve the appointment' });
      }
      setIsProcessingReservation(false);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlots, slot: val })) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.findIndex(
      (selectedSlot) =>
        selectedSlot.date === slot.date &&
        selectedSlot.startTime === slot.startTime &&
        selectedSlot.endTime === slot.endTime
    );
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  return (
    <div id={'EaseTimeSlot'}>
      {isLoading ? (
        <div className={styles.noAppointmentContainer}>
          <LoadingDot />
        </div>
      ) : isAuthenticated ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${practiceInfo.name}?`}
            desc={
              'As you are an existing EASE customer please contact our support team to discuss working with a new counsellor.'
            }
            buttonLabel={`Contact ${practiceInfo.name} Team`}
            onClick={() => (window.location.href = EaseContactUrl)}
          />
        </div>
      ) : (
        <>
          <div className={styles.appointmentSlotContainer}>
            <EaseContentLayout>
              {availabilityAppointmentTypes.length <= 0 && !isAvailabilityAppointmentTypesLoading ? (
                <div className={styles.noAppointmentContainer}>
                  <NoAvailableMessageBox
                    title={`Want to book with ${practiceInfo.name}?`}
                    desc={
                      <span>
                        Unfortunately, {practiceInfo.name} is not open to new clients at this time. If you’d like to
                        register your interest, you can join the waitlist here.
                      </span>
                    }
                    buttonLabel={`Register your interest for ${practiceInfo.name}`}
                    onClick={onShowWaitlistForm}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <div className={styles.header}>
                    <div className={styles.leftContent}>
                      <div className={styles.titleWrapper}>
                        <div className={styles.title}>Book time with {practiceInfo.name}</div>
                        {!isAuthenticated && (
                          <ButtonEase
                            className={styles.loginButton}
                            variant="text"
                            icon="arrow_forward"
                            iconPostFix
                            onClick={() => navigate(HOME)}
                          >
                            <span className={styles.text}>EXISTING CLIENT? LOGIN</span>
                          </ButtonEase>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.appointmentContentWrapper}>
                    <div>
                      <StepTitle no={'1'} title={'Select appointment'} />
                      <div className={styles.appointmentType}>
                        {isAvailabilityAppointmentTypesLoading ? (
                          <div className={styles.loadingWrapper}>
                            {[...Array(4)].map((obj, i) => (
                              <Skeleton active key={i} className={styles.loading} />
                            ))}
                          </div>
                        ) : (
                          availabilityAppointmentTypes.map((item, index) => (
                            <AppointmentTypeCard
                              key={index}
                              appointmentTypeData={item}
                              onClick={() => !isAvailabilityListLoading && handleChangeAppointmentType(item)}
                              selected={selectedAppointmentType?.name === item.name}
                              disable={isAvailabilityListLoading}
                            />
                          ))
                        )}
                      </div>
                      <div className={styles.enquiryInfo}>
                        <i className={`material-icons-outlined ${styles.icon}`}>info</i>
                        <div>
                          <div>
                            If you need to have an in-person appointment or have any questions about booking an
                            appointment with us, please call <a href="tel:02036336653">02036336653</a>.
                          </div>
                          <div>
                            All initial appointments are online. Ongoing therapy can be either online or in person
                            depending on your preference.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <StepTitle no={'2'} title={'Select day and time'} />
                      <Calendar
                        onSlotClick={onTimeSlotsChange}
                        timeSlots={appointmentAvailability?.timeSlots || []}
                        remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
                        selectedTimeSlots={selectedTimeSlots}
                        isAvailabilityListLoading={isAvailabilityListLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </EaseContentLayout>
          </div>
          {availabilityAppointmentTypes.length > 0 && (
            <BookingSession
              selectedAppointmentType={selectedAppointmentType}
              selectedTimeSlots={selectedTimeSlots}
              isAvailabilityListLoading={isAvailabilityListLoading}
              isProcessingReservation={isProcessingReservation}
              onContinue={onContinue}
              onRemoveSelectedSlot={onRemoveSelectedSlot}
              selectedDeliveryPreference={selectedDeliveryType}
              onChangeDeliveryPreference={(value) => setSelectedDeliveryType(value)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PracticeTimeSlot;
