import { useAuth0 } from '@auth0/auth0-react';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';

import ClientHeader from 'components/ClientHeader/ClientHeader';
import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import { OnboardingList } from 'pages/OnboardingAssessment/Onboarding/OnboardingList';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import SurveyList from '../SurveyList/SurveyList';
import styles from './SurveyListWrapper.module.scss';

const SurveyListWrapper = () => {
  const { user } = useAuth0();
  const { search } = useLocation();
  const { type } = queryString.parse(search);

  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );

  const getContentType = (type: any) => {
    switch (type) {
      case 'onboarding':
        return <OnboardingList />;
      case 'survey':
        return <SurveyList />;
      case 'consent':
        return <ConsentList />;
      default:
        return <SurveyList />;
    }
  };

  return (
    <PatientContentLayout
      className={styles.container}
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : {}
      }
    >
      <div className={styles.contentWrapper}>
        <ClientHeader />
        {getContentType(type)}
      </div>
    </PatientContentLayout>
  );
};

export default SurveyListWrapper;
