import { ChangeEvent, TextareaHTMLAttributes, useRef } from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea = ({ id, className, label, onChange, ...props }: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(e);

    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 2}px`;
    }
  };

  return (
    <div>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        {...props}
        id={id}
        ref={textAreaRef}
        className={classNames(styles.textarea, className)}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextArea;
