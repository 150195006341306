import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import EaseRoutes from './EaseRoutes';
import appRoute from '../../components/AppRoute/AppRoute';
import EaseLoginWrapper from 'Ease/components/EaseLoginWrapper/EaseLoginWrapper';

const EaseRoutesCollection = () => (
  <Routes>
    {appRoute({ path: '/facilitated/*', component: FacilitatedRoutes, layout: EaseLoginWrapper })}
    <Route path={'/*'} element={<EaseRoutes />} />
  </Routes>
);

export default EaseRoutesCollection;
