import classNames from 'classnames';
import styles from './PreviewItemInfo.module.scss';

export interface PreviewItemInfoProps {
  label?: string;
  value: string;
  className?: string;
}

const PreviewItemInfo = ({ label, value, className }: PreviewItemInfoProps) => {
  return (
    <div className={classNames(styles.infoWrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.infoText}>{value}</div>
    </div>
  );
};

export default PreviewItemInfo;
