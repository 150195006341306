import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';
import { ProgramId } from 'helm/interfaces/program/program';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getProgramDetails = (
  token: string,
  clientRecordId: string,
  clientProfileId: string,
  programId: ProgramId
) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/programs/${programId}`
  ) as Promise<BentResponse>;

export const putProgramActivities = (
  token: string,
  id: string,
  clientRecordId: string,
  clientProfileId: string,
  programId: ProgramId
) =>
  httpClient('PUT', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/client-profiles/${clientProfileId}/programs/${programId}/activities/${id}:start`
  ) as Promise<BentResponse>;
