import { useMemo, useState } from 'react';
import styles from './CreateProfileCheckout.module.scss';
import CheckBox from 'components/CheckBox/CheckBox';
import Button from 'components/v2/Button/Button';
import HelmButton from 'helm/components/Button/Button';
import { CheckoutPayload } from '../../CheckoutPage/CheckoutPage';
import { DeliveryType, OtherInstructions } from 'utils/hooks/appointment';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import { isIP6Program, isPowerToChangeProgram } from 'utils/checkEngageProgramId';
import { getDeliveryTypeLabel } from 'utils/appointment';
import { ButtonStatusType } from 'helm/components/ButtonHelm/ButtonHelm';

interface CreateProfileCheckoutProps {
  practitionerName: string;
  userName: string;
  packName: string;
  packDescription: string;
  deliveryMode?: DeliveryType;
  otherInstructions?: OtherInstructions;
  dateAndTimes: string[];
  totalToPay: number;
  buttonDisabled?: boolean;
  isBookingRuleError: boolean;
  programId?: string;
  isVoucherCodeValid?: boolean;
  checkoutButtonStatus: ButtonStatusType;
  onCheckout: (values: CheckoutPayload) => void;
  onReturnToCounsellorProfile: () => void;
}

const CreateProfileCheckout = ({
  practitionerName,
  userName,
  packName,
  packDescription,
  deliveryMode,
  otherInstructions,
  dateAndTimes,
  totalToPay,
  buttonDisabled,
  isBookingRuleError,
  programId,
  isVoucherCodeValid,
  checkoutButtonStatus,
  onCheckout,
  onReturnToCounsellorProfile
}: CreateProfileCheckoutProps) => {
  const URLs = helmEnvironment();
  const [isPaymentSupportChecked, setPaymentSupportChecked] = useState(false);
  const [isCancellationRuleChecked, setCancellationRuleChecked] = useState(false);
  const isSMPProgram = useMemo(() => isIP6Program(programId) || isPowerToChangeProgram(programId), [programId]);

  const handleCheckout = async () => {
    onCheckout({ rebateClaim: isPaymentSupportChecked });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Thanks <span className={styles.highlight}>{userName}</span>
      </div>
      Here is the summary of the appointments held for you. Please complete the payment in the time to avoid missing
      out.
      <div className={styles.summaryRow}>
        <div className={styles.summaryLeftCol}>With:</div>
        <div className={styles.summaryRightCol}>{practitionerName}</div>
      </div>
      <div className={styles.summaryRow}>
        <div className={styles.summaryLeftCol}>Booking:</div>
        <div className={styles.summaryRightCol}>
          {packName}
          <div className={styles.summaryTextDescription}>{packDescription}</div>
        </div>
      </div>
      <div className={styles.summaryRow}>
        <div className={styles.summaryLeftCol}>Delivery Mode:</div>
        <div className={styles.summaryRightCol}>{getDeliveryTypeLabel(deliveryMode, otherInstructions)}</div>
      </div>
      <div className={styles.summaryRow}>
        <div className={styles.summaryLeftCol}>Date and Time:</div>
        <div className={styles.summaryRightCol}>
          {dateAndTimes.map((i, index) => (
            <div key={index}>{i}</div>
          ))}
        </div>
      </div>
      <div className={styles.divider} />
      {isBookingRuleError ? (
        <>
          <div className={styles.bookingRuleErrorContainer}>
            <i className={`material-icons-outlined ${styles.icon}`}>error_outlined</i>
            <div>
              Your account doesn’t allow you to reserve this specific appointment type at this time. This may be because
              this appointment is reserved for clients on specific treatment plans or who are new to the Helm service.
              <br />
              <br />
              You can select another appointment with this counsellor or contact our customer service team between 9am
              and 5pm, Monday to Friday on 1300 388 576.
            </div>
          </div>
          <HelmButton className={styles.returnButton} onClick={onReturnToCounsellorProfile}>
            Return to counsellor profile
          </HelmButton>
        </>
      ) : (
        <>
          <div className={styles.summaryRow}>
            <div className={styles.totalToPayTitle}>TOTAL TO PAY</div>
            <span className={styles.totalToPay}>
              $ {isVoucherCodeValid ? '0.00' : totalToPay.toFixed(2)}
              {isVoucherCodeValid && <span className={styles.originalTotal}>$ {totalToPay.toFixed(2)}</span>}
            </span>
          </div>
          <div className={styles.supportAndRules}>
            {!isSMPProgram && (
              <>
                <div className={styles.supportAndRulesTitle}>Payment Support</div>
                <div className={styles.supportAndRulesDescription}>
                  We are a verified Medicare provider. We operate a Rebate model at Helm where you cover the initial
                  payment and then receive some or all of the cost back based on your specific circumstance. If you’d
                  like to discuss this before you book please call us on {URLs.HelpSupportTel}
                </div>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="paymentSupport"
                    value={isPaymentSupportChecked}
                    onChange={(e) => setPaymentSupportChecked(e.target.checked)}
                    className={styles.checkBox}
                    label="I have a valid medicare referral from a GP or have Private Health Insurance cover and would like help claiming
            a rebate"
                  />
                </div>
              </>
            )}
            <div className={styles.supportAndRulesTitle}>
              {isSMPProgram ? 'Cancellation and Reschedule Rules' : 'Cancellation Rules'}
            </div>
            <div className={styles.supportAndRulesDescription}>
              {isSMPProgram
                ? "We know plans sometimes need to change so we will allow you to change your appointment time free of charge as long as you provide us more than 24 hours' notice. You can change your booking any time via your profile which makes it quick and easy. We are not able to give refunds for no-shows or cancellations however you will still receive all program content by the planned session date whether you attend the sessions or not."
                : 'We know plans sometimes need to change so we will allow you to change your appointment time free of charge as long as you provide us 24 hours notice. Any requested changes within 24 hours will incur the full session fee.'}
            </div>
            <div className={styles.checkBoxText}>
              <CheckBox
                id="cancellationRule"
                value={isCancellationRuleChecked}
                onChange={(e) => setCancellationRuleChecked(e.target.checked)}
                className={styles.checkBox}
                label="I have read and agree to the cancellation policy"
              />
            </div>
            <Button
              className={styles.submitButton}
              disabled={!isCancellationRuleChecked || buttonDisabled}
              status={checkoutButtonStatus}
              onClick={handleCheckout}
            >
              Check out
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateProfileCheckout;
