import { useNavigate } from 'react-router-dom';
import styles from './PartnerSignUp.module.scss';
import { scrollToView } from 'utils/scrollToView';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode } from 'react';
import { useFetchHelmReservedAppointmentsWithTimeZone } from 'helm/utils/hooks/appointment';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import {
  clientRecordOnlySignUpPayload,
  postClientRecordSignUp
} from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import ErrorMessageWithHelpLink from 'SomeoneHealth/pages/SignUpWithInvite/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';

import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmPartnerCreateProfileForm from './HelmPartnerCreateProfileForm/HelmPartnerCreateProfileForm';
import { getAuMobile, initialClientRecordOnly } from './HelmPartnerCreateProfileForm/constants';
import { useFetchPractitionerDetails } from 'helm/pages/PractitionerDetails/hooks/getPractitionerDetails';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { useHelmPartnerRoutesGenerator } from 'helm/PartnerReferral/utils/Path/HelmPartnerRoutesGenerator';
import {
  PARTNER_HELM_SIGN_UP_USER,
  storeUserSession,
  UserSessionStorage
} from 'helm/pages/CreateProfile/helper/userSession';
import { reservedHelmPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { helmEnvironment } from '../../../utils/HelmEnv/helmEnv';
import HelmPartnerCreateProfileRemainingTime from './components/HelmPartnerCreateProfileRemainingTime/HelmPartnerCreateProfileRemainingTime';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { AU_TIME_ZONE_LIST, helmTimeZoneLocalStorage } from 'utils/constants/timeZone';

const PartnerSignUp = () => {
  const navigate = useNavigate();
  const clientTimeZone = localStorage.getItem(helmTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const { HelmSupportMail } = helmEnvironment();
  const { SIGNUP } = useHelmPartnerRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } =
    useReserveAppointmentData(reservedHelmPartnerReferral);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const { fetching, appointments } = useFetchHelmReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession(value, dob, PARTNER_HELM_SIGN_UP_USER);
    scrollToView('HelmHeader');
    navigate(SIGNUP.FUNDING_STREAM);
  };

  const onSubmitHandle = async (values: typeof initialClientRecordOnly) => {
    setIsSubmitting(true);
    const formatMobile = values.mobileNumber ? getAuMobile(values.mobileNumber) : '';
    const phoneValidate = values.mobileNumber ? await validatePhoneNumber(formatMobile) : undefined;

    const checkEmailExisted = values.email
      ? await checkEmailAlreadyExisted({
          accountId: accountId!,
          email: values.email
        })
      : undefined;

    let isError = false;
    if (phoneValidate && !phoneValidate.valid) {
      isError = true;
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink
          errorMessage="Mobile number not recognized. Please check for typos and try again."
          email={HelmSupportMail}
        />
      );
    }
    if (checkEmailExisted && checkEmailExisted?.response?.used) {
      isError = true;
      setEmailErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Email address is already used." email={HelmSupportMail} />
      );
    }
    if (isError) {
      setIsSubmitting(false);
      return;
    }

    try {
      const { email, firstName, lastName, referralBackground, officeLocation, purchaseOrderNo } = values;
      const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
      const communicationPreferenceLogic = CommunicationPreference.NoPreference;

      const payloadMassage: clientRecordOnlySignUpPayload = {
        clinicianId: clinicianId,
        clientRecord: {
          avatar: '',
          mobile: formatMobile,
          email,
          name: firstName,
          dateOfBirth: dob,
          firstName,
          lastName,
          postcode: '',
          timeZone: clientTimeZone || AU_TIME_ZONE_LIST[0].id,
          referralBackground,
          communicationPreference: communicationPreferenceLogic,
          appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
          officeLocation,
          purchaseOrderNo
        },
        isSendInvite: true
      };
      const callPatientSignUp = await postClientRecordSignUp(accountId || '', payloadMassage);

      const { clientRecord, authToken } = await callPatientSignUp.json();
      await fetchClaimingReservation(reserveId!, accountId!, authToken);
      setIsSubmitting(false);
      proceedToNextStep({ clientRecord, authToken }, dob);
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to sign up account'
      });
    }
  };

  return fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <HelmHelmetWrapper title={`HELM - Sign Up`}>
      <HelmContentLayout>
        <HelmHeader noLogin classNames={styles.header} />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <HelmPartnerCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
            />
          </div>
          <div className={styles.rightContent}>
            <HelmPartnerCreateProfileRemainingTime
              practitionerAvatar={practitionerDetails.avatar}
              practitionerName={practitionerDetails.name}
              appointments={appointments}
            />
          </div>
        </div>
      </HelmContentLayout>
      <TacklitFooter />
    </HelmHelmetWrapper>
  );
};

export default PartnerSignUp;
