import SMPCover from 'components/SMP/SMPCover/SMPCover';
import SMPForm from 'components/SMP/SMPForm/SMPForm';
import SMPPlanOverview from 'components/SMP/SMPPlanOverview/SMPPlanOverview';
import { SMP, SMPFormInputs, SMPType } from 'pages/SMP/interfaces/smp';
import styles from './SMPPlan.module.scss';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import classNames from 'classnames';

interface SMPPlanProps {
  formInputs?: SMPFormInputs;
  smpDetails?: SMP;
  submittedBy?: string;
}

const SMPPlan = ({ formInputs, smpDetails, submittedBy }: SMPPlanProps) => {
  return (
    <div className={classNames(styles.container, IS_HELM_APP && 'helm-theme')}>
      <SMPCover
        type={smpDetails?.type}
        submittedAt={smpDetails?.submittedAt}
        submittedBy={smpDetails?.submittedBy || submittedBy}
      />
      {smpDetails?.type !== SMPType.IP6 && <SMPPlanOverview submittedBy={smpDetails?.submittedBy || submittedBy} />}
      <SMPForm isEditMode={false} smpDetails={smpDetails} formInputs={formInputs} />
    </div>
  );
};

export default SMPPlan;
