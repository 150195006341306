import { ChangeEventHandler } from 'react';
import styles from './ReferralBackgroundForm.module.scss';
import classnames from 'classnames';

interface ReferralBackgroundFormProps {
  errorMessage?: { referralBackground?: string };
  referralBackground: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

const ReferralBackgroundForm = ({ errorMessage, referralBackground, onChange }: ReferralBackgroundFormProps) => {
  return (
    <div className={styles.referralNoteContainer}>
      <div className={styles.title}>Referral background</div>
      <div className={styles.noteWrapper}>
        <div className={styles.noteAreaLabel}>Please add as much detail and context as possible please</div>
        <div className={classnames(styles.textAreaContainer, errorMessage?.referralBackground && styles.fieldError)}>
          <textarea
            placeholder={'This is a space to add context or background to help'}
            value={referralBackground}
            className={styles.textArea}
            rows={8}
            onChange={onChange}
          />
          {errorMessage?.referralBackground && (
            <div className={styles.fieldError}>{errorMessage?.referralBackground}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralBackgroundForm;
