// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
    gtag: any;
  }
}

export const gaEventPush = (id: string, body?: any) => {
  if (id) {
    window.dataLayer.push({
      event: id,
      ...body
    });
  }
};

export const ga4EventPush = (id: string, body?: any) => {
  if (id && (process.env.REACT_APP_CLIENT_GA4_ID || process.env.REACT_APP_CLIENT_GA4_ID2)) {
    window.gtag('event', id, body);
  }
};
