import { ChangeEvent } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';

import { Question } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import TextArea from 'components/TextArea/TextArea';
import CheckboxRadio from '../CheckboxRadio/CheckboxRadio';

import styles from './MultipleChoiceFreeText.module.scss';

interface MultipleChoiceFreeTextProps {
  question: Question;
}

const MultipleChoiceFreeText = ({ question }: MultipleChoiceFreeTextProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField(`${question.id}.value`);
  const [
    { value: otherValue, onBlur: onOtherBlur },
    { error: otherError, touched: otherTouched },
    { setValue: setOtherValue }
  ] = useField(`${question.id}.otherValue`);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (question.maxSelection === 0) {
      if (Array.isArray(value)) {
        if (value.includes(e.target.value)) {
          setValue(value.filter((value: string) => value !== e.target.value));
        } else {
          setValue([...value, e.target.value]);
        }
      } else {
        setValue([e.target.value]);
      }
    } else {
      setValue(e.target.value);
    }
  };

  const handleOtherChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOtherValue(e.target.value);
  };

  return (
    <>
      <CheckboxRadio question={question} hasError={touched && !!error} value={value} onChange={handleChange} />
      <ErrorMessage error={error} visible={touched} />
      <div className={styles.textAreaContainer}>
        <TextArea
          containerClass={classnames(styles.textarea, !value && styles.hidden)}
          label="Please share any additional context or information you feel may be important"
          placeholder="Share as much as you are comfortable with"
          name={`${question.id}.otherValue`}
          hasError={otherTouched && !!otherError}
          value={otherValue}
          onBlur={onOtherBlur}
          onChange={handleOtherChange}
        />
        <ErrorMessage error={otherError} visible={otherTouched} />
      </div>
    </>
  );
};

export default MultipleChoiceFreeText;
