import { ChangeEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useField } from 'formik';

import { Question, Option, ScaleType } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ScaleBox from './components/ScaleBox/ScaleBox';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import hexSliderData from './HexSliderData.json';

import styles from './HexSlider.module.scss';

interface HexSliderProps {
  question: Question;
  defaultPatientDetails?: { name: string; picture: string };
}

const HexSlider = ({ question, defaultPatientDetails }: HexSliderProps) => {
  const { user } = useAuth0();
  const [{ value: formValue }, { error, touched }, { setValue }] = useField(`${question.id}.value`);
  const scales =
    question.scales && question.scales.length > 1
      ? question.scales
      : question.scaleType === ScaleType._1_5
      ? hexSliderData.scales.slice(6)
      : question.scaleType === ScaleType._1_10
      ? hexSliderData.scales.slice(1)
      : hexSliderData.scales;

  const handleRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(e.target.value, 10);

    if (value) {
      setValue(value);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.scaleBarContainer}>
          {scales.map(({ key, colour, value }) => (
            <ScaleBox key={key} colour={colour} formValue={formValue} value={value} onChange={setValue} />
          ))}
        </div>
        <div className={styles.labelContainer}>
          <div className={scales.length > 5 ? styles.labelBox : styles.labelBoxSmaller}>
            {(question.options as Option[])?.map(({ key, description }) => (
              <div key={key}>{description}</div>
            ))}
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            <input
              style={{ width: scales.length * 35 }}
              className={styles.hexSliderRange}
              type="range"
              min={
                scales && scales.length > 1
                  ? scales?.reduce((prev, curr) => (prev.value < curr.value ? prev : curr)).value
                  : 1
              }
              max={
                scales && scales.length > 1
                  ? scales?.reduce((prev, curr) => (prev.value > curr.value ? prev : curr)).value
                  : 11
              }
              value={formValue}
              onChange={handleRangeChange}
            />
          </div>
          <div className={styles.pointerContainer} data-value={formValue}>
            <div className={styles.label}>
              <div className={styles.circleContainer}>
                <div className={styles.triangle} />
                <div className={styles.circle}>
                  <div className={styles.profileWrapper}>
                    <div className={styles.profile}>
                      <ClientProfileAvatar avatarUrl={user?.picture || defaultPatientDetails?.picture} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default HexSlider;
