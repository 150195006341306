import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Radio from 'components/Radio/Radio';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BazaarToken } from '../../../../utils/hooks/useBazaarTokens';
import { putQuestionsResponses } from '../../../../utils/http/checkIn';
import { getSurveyQuestions } from '../../../../utils/http/CheckInService/Surveys/surveys';
import NumberSelection from '../NumberSelection/NumberSelection';
import styles from './Form.module.scss';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';

const Form = ({ token, resizeParentFrame }: { token: BazaarToken; resizeParentFrame: () => void }) => {
  const [questionsResponseId, setQuestionsResponseId] = useState('');
  const [assessmentId, setAssessmentId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const getQuestions = async ({ surveyAssignmentId, authToken }: BazaarToken) => {
    const survey = await (await getSurveyQuestions(authToken, surveyAssignmentId)).json();
    setQuestionsResponseId(survey.questionsResponses._id);
    setAssessmentId(survey.questionsResponses.assessment.onboardingQuestions.assessmentField[0].id);
  };

  useEffect(() => {
    if (token) {
      getQuestions(token);
    }
  }, [token]);

  useEffect(() => {
    resizeParentFrame();
  });

  const [submitted, setSubmitted] = useState(false);

  const formik = useFormik({
    initialValues: {
      howAnxious: null,
      howDepressed: null,
      feelingSuicide: ''
    },
    validationSchema: Yup.object().shape({
      howAnxious: Yup.number().required('This field is required').typeError('This field is required'),
      howDepressed: Yup.number().required('This field is required').typeError('This field is required'),
      feelingSuicide: Yup.string().required('This field is required')
    }),
    onSubmit: async (values) => {
      const responses = [
        {
          assessmentId,
          questionId: 'bazaar-q1',
          response: { value: values.howAnxious }
        },
        {
          assessmentId,
          questionId: 'bazaar-q2',
          response: { value: values.howDepressed }
        },
        {
          assessmentId,
          questionId: 'bazaar-q3',
          response: { value: values.feelingSuicide }
        }
      ];

      try {
        setSubmitting(true);
        await putQuestionsResponses(token.authToken, token.clinicianId, questionsResponseId, {
          responses,
          status: 'done'
        });
        setSubmitting(false);
        setSubmitted(true);
      } catch (e) {
        setSubmitting(false);
      }
    }
  });

  return submitted ? (
    <div className={styles.thankyou}>Thank you. You've submitted the form.</div>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <div className={styles.questionContainer}>
          <div className={styles.question}>How anxious have you been feeling? *</div>
          <div className={styles.questionDescription}>1 being Extremely Relaxed, 10 being Extremely Anxious</div>
          <ErrorMessage
            visible={formik.submitCount > 0 && !!formik.errors.howAnxious}
            error={formik.errors.howAnxious}
            className={styles.errorText}
          />
          <NumberSelection
            value={formik.values.howAnxious}
            onChange={(value) => {
              formik.setFieldValue('howAnxious', value);
            }}
          />
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.question}>How depressed have you been feeling? *</div>
          <div className={styles.questionDescription}>1 being Not at All, 10 being Extremely Depressed</div>
          <ErrorMessage
            visible={formik.submitCount > 0 && !!formik.errors.howDepressed}
            error={formik.errors.howDepressed}
            className={styles.errorText}
          />
          <NumberSelection
            value={formik.values.howDepressed}
            onChange={(value) => {
              formik.setFieldValue('howDepressed', value);
            }}
          />
        </div>
        <div className={styles.questionContainer}>
          <div className={styles.question}>Do you currently have any thoughts, feelings or plans of suicide? *</div>
          <ErrorMessage
            visible={formik.submitCount > 0 && !!formik.errors.feelingSuicide}
            error={formik.errors.feelingSuicide}
            className={styles.errorText}
          />
          <Radio
            vertical
            options={[
              {
                label: 'No',
                value: 'no'
              },
              {
                label: 'Thoughts and feelings of suicide, but no current intention to end my life',
                value: 'yesWithNoIntentionToAct'
              },
              {
                label: 'Thoughts and feelings of suicide, and I have a current plan to end my life',
                value: 'yesWithPlan'
              }
            ]}
            value={formik.values.feelingSuicide}
            onChange={(e) => {
              formik.setFieldValue('feelingSuicide', e.target.value);
            }}
          />
        </div>
      </div>
      <button className={styles.button} type="submit" disabled={submitting}>
        {submitting ? <LoadingCircle /> : 'SUBMIT'}
      </button>
    </form>
  );
};

export default Form;
