import { useMemo } from 'react';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  completedQuestions: number;
  totalQuestions?: number;
}

const ProgressBar = ({ completedQuestions, totalQuestions = 0 }: ProgressBarProps) => {
  const progressValue = useMemo(() => {
    if (totalQuestions) {
      return `${Math.ceil((completedQuestions * 100) / totalQuestions).toFixed(2)}%`;
    } else {
      return '0%';
    }
  }, [completedQuestions, totalQuestions]);

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <div className={styles.bar} style={{ width: progressValue }} />
      </div>
    </div>
  );
};

export default ProgressBar;
