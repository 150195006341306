import { Modal } from 'antd';
import Icon from 'components/Icon/Icon';
import Loading from 'components/Loading/Loading';
import SectionLoader from 'components/SectionLoader/SectionLoader';
import SMPGoalsSetting from 'components/SMP/SMPGoalsSetting/SMPGoalsSetting';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { SignatureType } from 'interfaces/signature';
import { SMP, SMPForm as SMPFormInterface, SMPFormInputs, SmpStatus, SMPUpdateAction } from 'pages/SMP/interfaces/smp';
import { MutableRefObject, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateSmp } from 'utils/hooks/smp';
import { UserContext } from 'utils/UserContextProvider';
import SMPGoalsMethod from '../SMPGoalsMethod/SMPGoalsMethod';
import SMPPlan from '../SMPPlan/SMPPlan';
import SMPRelapsePrevention from '../SMPRelapsePrevention/SMPRelapsePrevention';
import styles from './SMPForm.module.scss';
import classNames from 'classnames';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

type SmpFormProps = {
  formInputs?: SMPFormInputs;
  generatePrintPayload?: () => Document | undefined;
  isEditMode?: boolean;
  refetchSmpDetails?: () => Promise<void>;
  smpDetails?: SMP;
  smpPrintPayloadRef?: MutableRefObject<null>;
};

const defaultGoal = {
  description: '',
  steps: ['', '', '']
};

const defaultValues: SMPFormInputs = {
  form: {
    goals: {
      goal1: defaultGoal,
      goal2: defaultGoal,
      goal3: defaultGoal
    },
    methods: {
      callSupport: '',
      drawStrength: '',
      helpfulThought: '',
      difficultEmotion: '',
      unhelpfulThought: '',
      emotionManagement: ''
    },
    relapsePreventions: {
      warningSign: '',
      cravingManagement: '',
      reductionStrategy: ''
    }
  },
  signature: {
    type: '',
    textVal: '',
    drawVal: ''
  }
};

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const reconstructSmp = (smpNode: Node): Document => {
  const newDocument = new Document();
  const newHTML = newDocument.createElement('html');
  const newHead = document.head.cloneNode(true);
  const newBody = newDocument.createElement('body');
  newBody.appendChild(smpNode.cloneNode(true));

  newHTML.appendChild(newHead);
  newHTML.appendChild(newBody);
  newDocument.appendChild(newHTML);

  const styleSheets = newDocument.querySelectorAll("link[rel='stylesheet' i]");
  styleSheets.forEach((styleSheet) => {
    const extractedHref = styleSheet.getAttribute('href');
    if (extractedHref && !isValidUrl(extractedHref)) {
      styleSheet.setAttribute('href', `${document.location.origin}${extractedHref}`);
    }
  });

  const images = newDocument.querySelectorAll('img');
  images.forEach((image) => {
    const extractedSrc = image.getAttribute('src');
    if (extractedSrc && !isValidUrl(extractedSrc)) {
      image.setAttribute('src', `${document.location.origin}${extractedSrc}`);
    }
  });

  return newDocument;
};

const SMPForm = ({
  formInputs,
  isEditMode = true,
  refetchSmpDetails,
  smpDetails,
  smpPrintPayloadRef
}: SmpFormProps): JSX.Element => {
  const [forms, setForms] = useState<SMPFormInputs>(defaultValues);
  const { smpId } = useParams();
  const { clientProfile } = useContext(UserContext);

  const { updateSmp } = useUpdateSmp(smpId);
  const [isLoading, setIsLoading] = useState(true);
  const [saveState, setSaveState] = useState<HelmBtnStatus>('');
  const [submitState, setSubmitState] = useState<HelmBtnStatus>('');
  const [isSignatureBoxOpen, setIsSignatureBoxOpen] = useState(false);
  const [isUpdatingModalOpen, setIsUpdatingModalOpen] = useState(false);

  const isEditable = useMemo(
    () => isEditMode && smpDetails?.status === SmpStatus.draft && !saveState && !submitState,
    [isEditMode, saveState, smpDetails?.status, submitState]
  );

  const isActionButtonsVisible = useMemo(
    () => isEditMode && smpDetails?.status === SmpStatus.draft,
    [isEditMode, smpDetails?.status]
  );

  useEffect(() => {
    if (smpDetails) {
      setIsLoading(false);
      setForms(smpDetails);
    }
  }, [smpDetails]);

  useEffect(() => {
    if (formInputs) {
      setIsLoading(false);
      setForms(formInputs);
    }
  }, [formInputs]);

  const generatePrintPayload = useCallback(() => {
    if (smpPrintPayloadRef?.current) {
      return reconstructSmp(smpPrintPayloadRef?.current);
    }
  }, [smpPrintPayloadRef]);

  const handleUpdate = async (action: SMPUpdateAction) => {
    setIsUpdatingModalOpen(true);
    try {
      const printPayload =
        (action === SMPUpdateAction.submit && generatePrintPayload && generatePrintPayload()) || undefined;

      await updateSmp({ ...forms, action, printPayload });

      if (action === SMPUpdateAction.save) {
        setSaveState('finished');
      } else {
        refetchSmpDetails?.();
        setSubmitState('finished');
      }
    } finally {
      setTimeout(() => {
        setSaveState('');
        setSubmitState('');
      }, 1000);
      setIsUpdatingModalOpen(false);
    }
  };

  const handleSaveDraft = () => {
    setSaveState('active');
    handleUpdate(SMPUpdateAction.save);
  };

  const checkSignatureValidity = () =>
    (forms.signature?.type === SignatureType.drawVer && forms.signature?.drawVal) ||
    (forms.signature?.type === SignatureType.typeVer && forms.signature?.textVal);

  const handleSubmit = () => {
    if (checkSignatureValidity()) {
      setSubmitState('active');
      handleUpdate(SMPUpdateAction.submit);
    }
  };

  const submittedBy = smpDetails?.submittedBy || `${clientProfile?.firstName} ${clientProfile?.lastName}`;

  return isLoading || !clientProfile ? (
    <SectionLoader variant="dot" />
  ) : (
    <>
      {smpPrintPayloadRef && (
        <div className={styles.previewContainer}>
          <div ref={smpPrintPayloadRef}>
            <SMPPlan formInputs={forms} smpDetails={smpDetails} submittedBy={submittedBy} />
          </div>
        </div>
      )}
      <Modal
        visible={isUpdatingModalOpen}
        closable={false}
        footer={null}
        className={classNames(styles.updatingModal, IS_HELM_APP && 'helm-theme')}
      >
        <div className={styles.updatingModalBodyContainer}>
          <Icon name="error_outline" className={styles.warningIcon} />
          <div className={styles.updatingModelBody}>Updating your Self Management Plan</div>
          <Loading />
        </div>
      </Modal>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <SMPGoalsSetting
            goals={forms.form.goals}
            onChange={(goals: SMPFormInterface['goals']) => setForms({ ...forms, form: { ...forms.form, goals } })}
            isEditMode={isEditable}
          />
          <SMPGoalsMethod
            type={smpDetails?.type}
            methods={forms.form.methods}
            onChange={(methods: SMPFormInterface['methods']) =>
              setForms({ ...forms, form: { ...forms.form, methods } })
            }
            isEditMode={isEditable}
          />
          <SMPRelapsePrevention
            signature={forms.signature}
            isEditMode={isEditable}
            submittedAt={smpDetails?.submittedAt}
            type={smpDetails?.type}
            isSignatureBoxOpen={isSignatureBoxOpen}
            relapsePreventions={forms.form.relapsePreventions}
            setIsSignatureBoxOpen={setIsSignatureBoxOpen}
            onChange={(relapsePreventions: SMPFormInterface['relapsePreventions']) =>
              setForms({ ...forms, form: { ...forms.form, relapsePreventions } })
            }
            onSignatureChange={(signature: SMPFormInputs['signature']) =>
              setForms({ ...forms, form: { ...forms.form }, signature })
            }
            submittedBy={submittedBy}
          />
          {isActionButtonsVisible && (
            <div className={styles.actionContainer}>
              {IS_HELM_APP ? (
                <>
                  <ButtonHelm
                    status={saveState}
                    variant="contained"
                    onClick={handleSaveDraft}
                    disabled={!!saveState || !!submitState}
                    className={styles.saveButton}
                  >
                    Save draft
                  </ButtonHelm>
                  <ButtonHelm
                    status={submitState}
                    variant="outlined"
                    onClick={handleSubmit}
                    disabled={!!submitState || !!saveState || !checkSignatureValidity()}
                    className={styles.submitButton}
                  >
                    Submit
                  </ButtonHelm>
                </>
              ) : (
                <>
                  <Button
                    status={saveState}
                    variant="primary"
                    onClick={handleSaveDraft}
                    disabled={!!saveState || !!submitState}
                  >
                    Save draft
                  </Button>
                  <Button
                    status={submitState}
                    variant="secondary"
                    onClick={handleSubmit}
                    disabled={!!submitState || !!saveState || !checkSignatureValidity()}
                  >
                    Submit
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SMPForm;
