import Button from 'components/v2/Button/Button';
import { ButtonHTMLAttributes } from 'react';

type SignatureButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const SignatureButton = (props: SignatureButtonProps) => {
  return (
    <Button variant="secondary" type="button" {...props}>
      Sign here
    </Button>
  );
};

export default SignatureButton;
