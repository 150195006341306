import CalendarBox from 'Ease/components/CalendarBox/CalendarBox';
import styles from './SelectSlot.module.scss';
import { Moment } from 'moment';

interface SelectSlotProps {
  date?: Moment;
  time?: string;
  onDelete?: () => void;
  blockSlot?: boolean;
}

const SelectSlot = ({ date, time, onDelete, blockSlot }: SelectSlotProps) => {
  const handleRemoveSlot = () => {
    onDelete?.();
  };

  return date ? (
    <div className={blockSlot ? styles.blockContainer : styles.bookContainer}>
      <div className={styles.closeBtn}>
        <div onClick={handleRemoveSlot} className={`material-icons ${styles.closeIcon}`}>
          close
        </div>
      </div>
      <CalendarBox date={date} center />
      <div className={styles.timeSlot}>{time}</div>
    </div>
  ) : (
    <div className={styles.container} />
  );
};

export default SelectSlot;
