import classNames from 'classnames';
import styles from './SHAlphabetFilter.module.scss';

interface SHAlphabetFilterProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  subLabel?: string;
  labelClassName?: string;
  subLabelClassName?: string;
  labelContainerClassName?: string;
  containerClassName?: string;
  buttonClassName?: string;
  showClearButton?: boolean;
  clearButtonClassName?: string;
  availableOptions?: string[];
}

const Alphabets = new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i));

const SHAlphabetFilter = ({
  value,
  onChange,
  label,
  subLabel,
  labelClassName,
  subLabelClassName,
  labelContainerClassName,
  containerClassName,
  buttonClassName,
  showClearButton,
  clearButtonClassName,
  availableOptions
}: SHAlphabetFilterProps) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      {(label || subLabel) && (
        <div className={classNames(styles.labelContainer, labelContainerClassName)}>
          {label && <div className={classNames(styles.label, labelClassName)}>{label}</div>}
          {subLabel && <div className={classNames(styles.subLabel, subLabelClassName)}>{subLabel}</div>}
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.filterContainer}>
          <div className={styles.optionList}>
            {Alphabets.map((item, index) => (
              <button
                key={index}
                className={classNames(
                  styles.button,
                  item === value && styles.selected,
                  !availableOptions?.includes(item) && styles.disabled,
                  value && item !== value && styles.unSelected,
                  buttonClassName
                )}
                disabled={!availableOptions?.includes(item)}
                onClick={() => {
                  onChange(item);
                }}
              >
                {item}
              </button>
            ))}
          </div>
          {showClearButton && value && (
            <button
              className={classNames(styles.clearButton, clearButtonClassName)}
              onClick={() => {
                onChange('');
              }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SHAlphabetFilter;
