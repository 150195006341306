import CheckBox from 'components/CheckBox/CheckBox';

import styles from './PaymentPolicy.module.scss';
import CancellationPolicy from '../CancellationPolicy/CancellationPolicy';

interface PaymentPolicyProps {
  policyAccepted: boolean;
  onSetPolicyAccepted: (value: boolean) => void;
}

const PaymentPolicy = ({ policyAccepted, onSetPolicyAccepted }: PaymentPolicyProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Cancellation Policy for Initial Appointment</div>
      <CancellationPolicy />
      <CheckBox
        id="policyAccepted"
        value={policyAccepted}
        onChange={(event) => onSetPolicyAccepted(event.target.checked)}
        label="I have read and agree to the cancellation policy"
        labelClassName={styles.checkBoxLabel}
      />
    </div>
  );
};

export default PaymentPolicy;
