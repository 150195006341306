import classnames from 'classnames';
import styles from './FlexBox.module.scss';

interface FlexBoxProps {
  children?: React.ReactNode;
  direction: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  flex?: 1 | 'auto' | 'initial';
  spacing?: 4 | 8 | 12 | 16 | 20 | 40;
  className?: string;
}

const FlexBox = ({ direction, alignItems, justifyContent, flex, spacing, className, children }: FlexBoxProps) => {
  const flexBoxClasses = classnames(
    styles.container,
    direction && styles[direction],
    alignItems && styles[`alignItems-${alignItems}`],
    justifyContent && styles[`justifyContent-${justifyContent}`],
    flex && styles[`flex-${flex}`],
    spacing && styles[`spacing-${spacing}`],
    className
  );
  return <div className={flexBoxClasses}>{children}</div>;
};

export default FlexBox;
