import tacklitLogo from 'assets/images/tacklitLogoWhite.png';

import styles from './HelmFooter.module.scss';
import classNames from 'classnames';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';

interface HelmFooterProps {
  hideProgram?: boolean;
}

const HelmFooter = ({ hideProgram }: HelmFooterProps) => {
  const URLs = helmEnvironment();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <div className={styles.contact}>
            <div className={styles.telAndEmail}>
              <strong>
                <a href={'tel:' + URLs.HelpSupportTel} className={styles.tel}>
                  {URLs.HelpSupportTel}
                </a>
                <br />
                <a href={'mailto:' + URLs.HelmSupportMail} className={styles.email}>
                  <span>{URLs.HelmSupportMail}</span>
                </a>
              </strong>
            </div>

            <div className={styles.support}>
              <a href={URLs.HelmPrivacyPolicy}>
                Helm is not a crisis service.
                <br />
                Please call Lifeline on 13 11 14 for 24-hour crisis support. You can also text or chat online with
                Lifeline – see
              </a>
              <br />
              <a className={styles.link} href={`https://www.lifeline.org.au`}>
                https://www.lifeline.org.au/.
              </a>
              <br />
              <a href={URLs.HelmPrivacyPolicy}>If life is in danger, please call 000.</a>
            </div>
          </div>
          <div className={styles.takeTheHelm}>
            <a href={URLs.HelmFooterLogoURL}>Take the Helm</a>
          </div>
        </div>
        <div className={hideProgram ? styles.rightContentWithoutLink : styles.rightContent}>
          {!hideProgram && (
            <div className={styles.programs}>
              <ul>
                <li>
                  <a href={URLs.HelmFooterAlcoholURL}>
                    <span>Alcohol</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmFooterDrugURL}>
                    <span>Drugs</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmFooterGamblingURL}>
                    <span>Gambling</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmFooterEmotionsURL}>
                    <span>Emotions</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmFooterOtherDifficultiesURL}>
                    <span>Other Difficulties in Life</span>
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className={styles.policy}>
            <a href={URLs.HelmPrivacyPolicy}>
              <span>Privacy Policy</span>
            </a>
            <br />
            <span>
              <a href={URLs.HelmTermAndConditions}>Terms and Conditions</a>
            </span>
          </div>

          <div className={styles.quotes}>
            <p>
              We acknowledge and pay respect to the Traditional Owners of the lands upon which our services are
              delivered.
            </p>
          </div>
        </div>

        {!hideProgram && (
          <div className={styles.rightContent}>
            <div className={styles.programs}>
              <ul>
                <li>
                  <a href={URLs.HelmAboutURL}>
                    <span>About</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmContactUsURL}>
                    <span>Contact Us</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmOurCounsellorURL}>
                    <span>Our Counsellors</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmOurPricingURL}>
                    <span>Our Pricing</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmFAQURL}>
                    <span>FAQs</span>
                  </a>
                </li>
                <li>
                  <a href={URLs.HelmBookNowURL}>
                    <span>Book Now</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className={classNames(styles.row, styles.tacklitRow)}>
        <a href={'https://www.tacklit.com/'}>
          <img alt="Tacklit logo" className={styles.tacklitLogo} src={tacklitLogo} />
        </a>
        <i className={classNames(styles.copyrightSymbol, 'material-icons')}>copyright</i> Powered by{' '}
        <a className={styles.tacklitName} href={'https://www.tacklit.com/'}>
          Tacklit
        </a>
      </div>
    </div>
  );
};

export default HelmFooter;
