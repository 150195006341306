import { useCallback, useEffect, useState } from 'react';
import { getSharedLetter } from 'utils/http/letter';
import { Letter } from 'interfaces/letter';

export const useFetchSharedLetter = (accountId: string, letterId: string) => {
  const [letterData, setLetterData] = useState<Letter>();
  const [isLetterLoading, setIsLetterLoading] = useState(true);

  const fetchSharedLetter = useCallback(async () => {
    if (accountId && letterId) {
      try {
        setIsLetterLoading(true);
        const getLetterRes = await (await getSharedLetter(accountId, letterId)).json();
        setLetterData(getLetterRes);
      } catch (ex) {
        console.error(ex);
        setLetterData({} as Letter);
      } finally {
        setIsLetterLoading(false);
      }
    }
  }, [accountId, letterId]);

  useEffect(() => {
    fetchSharedLetter();
  }, [fetchSharedLetter]);

  return { letterData, isLetterLoading, fetchSharedLetter };
};
