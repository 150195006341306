import IconButton from 'components/IconButton/IconButton';
import { ReactNode } from 'react';
import styles from './ListItem.module.scss';

interface ListItemProps {
  icon?: ReactNode;
  onClick?: () => void;
  primaryText?: string;
  secondaryText?: string;
  primaryActionIcon?: ReactNode;
  onPrimaryActionClick?: () => void;
  primaryActionIconName?: string;
}

const ListItem = ({
  icon,
  onClick,
  primaryText,
  secondaryText,
  onPrimaryActionClick,
  primaryActionIconName
}: ListItemProps): JSX.Element => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.icon}>{icon}</div>

      <div className={styles.contentContainer}>
        <div>
          <div className={styles.primaryText}>{primaryText}</div>
          <div className={styles.secondaryText}>{secondaryText}</div>
        </div>

        {primaryActionIconName && <IconButton iconName={primaryActionIconName} onClick={onPrimaryActionClick} />}
      </div>
    </div>
  );
};

export default ListItem;
