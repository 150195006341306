import styles from './PsychologistFAQ.module.scss';
import FAQBubble from './components/FAQBubble/FAQBubble';
import classnames from 'classnames';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

interface FAQProps {
  data: {
    title: string;
    desc: string;
    faqData: {
      title: string;
      desc: string;
    }[];
  };
  className?: string;
  id: string;
}

const PsychologistFAQ = ({ data, className, id }: FAQProps) => {
  return (
    <div className={classnames(styles.container, className)} id={id}>
      <SomeoneHealthContentLayout>
        <div className={styles.content}>
          <div className={styles.title}>{data.title}</div>
          <div className={styles.desc} dangerouslySetInnerHTML={{ __html: data.desc }} />
          <div className={styles.faqWrapper}>
            {data.faqData.map((obj, index) => (
              <FAQBubble key={index} details={obj} />
            ))}
          </div>
        </div>
      </SomeoneHealthContentLayout>
    </div>
  );
};

export default PsychologistFAQ;
