import styles from './RechargeTimer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimExpireAlert from 'components/TimeExpireAlert/TimeExpireAlert';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

interface RechargeTimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
  reservedAppointmentId?: string;
}

const RechargeTimer = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  hideTimer,
  onlyText,
  reservedAppointmentId
}: RechargeTimerProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();
  const { clinicianId, reserveId } = useReserveAppointmentData(reservedAppointmentId);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  const detailsPagePath = clinicianId
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId
      })
    : PRACTITIONER.LISTING;

  if (!reserveId) {
    navigate(detailsPagePath);
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 1) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#806ABE'}
      />
      <TimExpireAlert
        modalClassname="recharge-theme"
        showExpiredAlert={showExpiredAlert}
        returnRoute={detailsPagePath}
      />
    </div>
  );
};

export default RechargeTimer;
