import styles from './UpdateProfile.module.scss';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import ClientHeader from '../../components/ClientHeader/ClientHeader';

const UpdateProfile = () => {
  return (
    <PatientContentLayout
      className={styles.container}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div>
        <ClientHeader withMenu />
        <UpdateProfileContent />
      </div>
    </PatientContentLayout>
  );
};

export default UpdateProfile;
