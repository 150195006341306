import { components, SingleValueProps } from 'react-select';
import { CountryCodeOptionInterface } from '../../CountryCodeSelect';
import CountryCodeDetails from '../CountryCodeDetails/CountryCodeDetails';

const CountryCodeSingleValue = ({ children, ...props }: SingleValueProps<CountryCodeOptionInterface>) => {
  return (
    <components.SingleValue {...props}>
      <CountryCodeDetails data={props.data} alignRight />
    </components.SingleValue>
  );
};

export default CountryCodeSingleValue;
