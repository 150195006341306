import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import classNames from 'classnames';
import styles from './SomeoneHealthLoginWrapper.module.scss';

interface SomeoneHealthLoginWrapperProps {
  children?: any;
}

const SomeoneHealthLoginWrapper = ({ children }: SomeoneHealthLoginWrapperProps) => (
  <>
    <div className={classNames(styles.container, 's1h-client-portal-theme')}>{children}</div>
    <TacklitFooter />
  </>
);

export default SomeoneHealthLoginWrapper;
