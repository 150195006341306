import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import PortobelloRoutes from './PortobelloRoutes';
import appRoute from '../../components/AppRoute/AppRoute';
import PortobelloLoginWrapper from 'Portobello/components/Layout/PortobelloLoginWrapper/PortobelloLoginWrapper';

const PortobelloRoutesCollection = () => (
  <Routes>
    {appRoute({ path: '/facilitated/*', component: FacilitatedRoutes, layout: PortobelloLoginWrapper })}
    <Route path={'/*'} element={<PortobelloRoutes />} />
  </Routes>
);

export default PortobelloRoutesCollection;
