import { useAuth0 } from '@auth0/auth0-react';

import styles from './RechargeSurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';

const RechargeSurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <div className={`${styles.container} recharge-theme`}>
      <RechargeContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <RechargeHeader classNames={styles.header} withPadding withMenu homeLink whiteMenu />
        </div>
      </RechargeContentLayout>
      <RechargeContentLayout className={styles.contentWrapper}>
        <SurveyQuestion />
      </RechargeContentLayout>
    </div>
  );
};

export default RechargeSurveyQuestionWrapper;
