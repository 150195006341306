import Select from './CommonSelect/Select';
import { Styles } from 'react-select';

import styles from './MaterialSelect.module.scss';

export interface MaterialSelectProps {
  id?: string;
  label: string;
  isSearchable: boolean;
  optionList: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange: any;
  loading?: boolean;
  boldValue?: boolean;
  isError?: boolean;
  className?: string;
  menuPosition?: 'top';
  styles?: Partial<
    Styles<
      {
        label: string;
        value: string;
      },
      false
    >
  >;
}

const MENU_POSITIONS = {
  top: { top: -300 }
};

const MaterialSelect = ({
  id,
  label,
  isSearchable,
  optionList,
  value,
  onChange,
  loading,
  boldValue,
  isError,
  menuPosition,
  styles: propStyles
}: MaterialSelectProps) => {
  return (
    <Select
      id={id}
      label={label}
      labelClass={styles.selectLabel}
      options={optionList}
      isSearchable={isSearchable}
      smallCaretDown
      styles={{
        container: (containerBase: any) => ({ ...containerBase, width: '100%' }),
        valueContainer: (base: any) => ({
          ...base,
          minHeight: '30px',
          padding: '0 8px 0 0',
          cursor: 'pointer',
          fontWeight: boldValue ? 'bold' : 'normal'
        }),
        option: (menuBase: any) => ({
          ...menuBase,
          cursor: 'pointer'
        }),
        control: (controlBase: any) => ({
          ...controlBase,
          minHeight: '30px',
          backgroundColor: 'transparent',
          border: 'none',
          borderBottom: isError ? '1px solid #ff4d4f' : '1px solid #d3d8dd',
          borderRadius: 0,
          boxShadow: 'none'
        }),
        menu: (base) => ({
          ...base,
          marginTop: 0,
          width: '100% !important',
          zIndex: 5,
          ...(menuPosition && MENU_POSITIONS[menuPosition])
        }),
        ...propStyles
      }}
      value={value}
      onChange={(e) => onChange(e?.value)}
      loading={loading}
    />
  );
};

export default MaterialSelect;
