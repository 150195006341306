import classNames from 'classnames';
import SummaryItem from 'CaW/pages/CaWBookingConfirmation/components/Summary/components/SummaryItem/SummaryItem';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { toWord } from 'utils/generateCamelCase';
import styles from './Summary.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';

interface SummaryProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  containerClassName?: string;
}

const Summary = ({ isReservedAppointmentFetching, reservedAppointments, containerClassName }: SummaryProps) => {
  const { psychologistName, gpDetails } = useReserveAppointmentData();

  const sortByCreatedAt = (a: AppointmentSlot, b: AppointmentSlot) => {
    const aCreatedAt = moment(a.createdAt);
    const bCreatedAt = moment(b.createdAt);
    return aCreatedAt.isBefore(bCreatedAt) ? 1 : aCreatedAt.isAfter(bCreatedAt) ? -1 : 0;
  };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={styles.header}>
        Here is the summary of the appointments held for you. Please confirm the booking.
      </div>
      {isReservedAppointmentFetching ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <div className={styles.content}>
          {reservedAppointments.sort(sortByCreatedAt).map((appointmentObj, index) => (
            <SummaryItem
              key={index}
              index={index + 1}
              bookingName={appointmentObj.sessionTypeName || ''}
              name={appointmentObj.sessionTypeName === 'GP' ? gpDetails?.gpName || '' : psychologistName || ''}
              startTime={appointmentObj.startTime}
              endTime={appointmentObj.endTime}
              date={appointmentObj?.date || ''}
              deliveryMode={appointmentObj.deliveryType ? toWord(appointmentObj.deliveryType) : ''}
              isLast={reservedAppointments.length === index + 1}
            />
          ))}
        </div>
      )}
      <div className={styles.cancelPolicyWrapper}>
        <div className={styles.cancelTitle}>Cancellation Policy:</div>
        <div>
          We know plans sometimes need to change so our service allows you to reschedule or cancel your appointment
          without penalty when you provide more than 24 hours notice. To do this, you can change your booking via your
          client portal login. <br />
          Please note: if you do not provide 24 hours notice, your session will be forfeited.
        </div>
      </div>
    </div>
  );
};

export default Summary;
