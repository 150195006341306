import { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './PatientContentLayout.module.scss';

interface PatientContentLayoutProps {
  children?: any;
  className?: string;
  withFullHeight?: boolean;
  style?: CSSProperties;
  contentClassName?: string;
}

const PatientContentLayout = ({
  children,
  className,
  withFullHeight,
  style,
  contentClassName
}: PatientContentLayoutProps) => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div
        className={classNames(withFullHeight ? styles.fullHeightContentLayout : styles.contentLayout, contentClassName)}
      >
        {children}
      </div>
    </div>
  );
};

export default PatientContentLayout;
