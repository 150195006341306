import styles from './AvailabilityPill.module.scss';
import classnames from 'classnames';
import { ReactNode } from 'react';

export interface AvailabilityPillProps {
  children: ReactNode;
  classNames?: string;
  darkYellowPill?: boolean;
}

const AvailabilityPill = ({ children, classNames, darkYellowPill }: AvailabilityPillProps) => {
  return (
    <div className={classnames(darkYellowPill ? styles.darkYellowContainer : styles.container, classNames)}>
      {children}
    </div>
  );
};

export default AvailabilityPill;
