export enum FitPsychologistGenderList {
  woman = 'woman',
  man = 'man',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  agender = 'agender'
}

export const FIT_PSYCHOLOGIST_GENDER_LIST_LABELS: Record<FitPsychologistGenderList, string> = {
  woman: 'Female',
  man: 'Male',
  transgenderWoman: 'Transgender Female',
  transgenderMan: 'Transgender Male',
  nonBinary: 'Non-Binary',
  agender: 'Agender'
};

export enum FitDaysList {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  weekend = 'weekend'
}

export const FIT_DAYS_LIST_LABELS: Record<FitDaysList, string> = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  weekend: 'Weekend'
};

export enum FitTimesList {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening'
}

export const FIT_TIMES_LIST_LABELS: Record<FitTimesList, string> = {
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening'
};

export enum FitDeliveryList {
  faceToFace = 'faceToFace',
  phone = 'phone',
  video = 'video'
}

export const FIT_DELIVERY_LIST_LABELS: Record<FitDeliveryList, string> = {
  [FitDeliveryList.faceToFace]: 'Face to Face',
  [FitDeliveryList.phone]: 'Phone Call',
  [FitDeliveryList.video]: 'Video Call'
};
