import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export interface UpdateAppointmentPayload {
  date: string;
  endDate: string;
  startTime: string;
  endTime: string;
  startDateTime: string;
  endDateTime: string;
}

export const rescheduleAppointment = (token: string, appointmentId: string, payload: UpdateAppointmentPayload) =>
  httpClient('PUT', url!, [204, 403, 409], { Authorization: `Bearer ${token}` })(
    `/client-records/me/appointments/${appointmentId}/reschedule`,
    payload
  ) as Promise<BentResponse>;

export const rescheduleAppointmentRequest = (token: string, appointmentId: string, payload: UpdateAppointmentPayload) =>
  httpClient('PUT', url!, [204, 403, 409], { Authorization: `Bearer ${token}` })(
    `/client-records/me/appointments/${appointmentId}/reschedule/request`,
    payload
  ) as Promise<BentResponse>;
