import { AppointmentSlot, DeliveryType, OtherInstructions } from 'utils/hooks/appointment';

import styles from './CallInstructions.module.scss';

interface CallInstructionsProps {
  deliveryType: AppointmentSlot['deliveryType'];
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
}

const CallInstructions = ({
  deliveryType,
  videoCallInstructions,
  phoneCallInstructions,
  phoneCallDialClientInstructions,
  otherInstructions
}: CallInstructionsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.phone}>
        <div className={styles.title}>
          {(!deliveryType ||
            ![
              DeliveryType.PhoneCall,
              DeliveryType.PhoneCallDialClient,
              DeliveryType.VideoCall,
              DeliveryType.Other
            ].includes(deliveryType)) &&
            'No delivery mode selected'}
          {deliveryType === DeliveryType.VideoCall && 'Video:'}
          {deliveryType &&
            [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].includes(deliveryType) &&
            'Phone:'}
          {deliveryType === DeliveryType.Other && 'Instructions:'}
        </div>{' '}
        {deliveryType === DeliveryType.VideoCall && videoCallInstructions}
        {deliveryType === DeliveryType.PhoneCall && phoneCallInstructions}
        {deliveryType === DeliveryType.PhoneCallDialClient && phoneCallDialClientInstructions}
        {deliveryType === DeliveryType.Other && otherInstructions?.instructions}
      </div>
    </div>
  );
};

export default CallInstructions;
