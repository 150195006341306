import { FitTimesList } from 'SomeoneHealth/interfaces/fitFilter';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { defaultClinicianTimeZone } from '../hooks/getPsychologistList';

export const getTimeFilterInMelbourneTimezone = ({ time, timezone }: { time?: FitTimesList; timezone: string }) => {
  if (time === FitTimesList.morning) {
    return {
      startTime: '00:00',
      endTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.afternoon) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.evening) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: '24:00'
    };
  }

  return {};
};
