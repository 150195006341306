import { notification } from 'antd';
import Section from 'components/Section/Section';
import SectionItem from 'components/SectionItem/SectionItem';
import Button from 'components/v2/Button/Button';
import { SMPType } from 'pages/SMP/interfaces/smp';
import { useNavigate } from 'react-router-dom';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useNewSmp } from 'utils/hooks/smp';
import styles from './SMPNewPage.module.scss';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

const SMPNewPage = () => {
  const navigate = useNavigate();
  const { createSmp, isLoading } = useNewSmp();
  const defaultType = IS_HELM_APP ? SMPType.IP6 : SMPType.G24;

  const navigateToSmpListPage = () => {
    navigate('/smp');
  };

  const createThenNavigate = async (options?: { blankPlan?: boolean }) => {
    const newSmp = await createSmp({
      blankPlan: options?.blankPlan,
      type: defaultType
    });

    if (newSmp?._id) {
      navigate(`/smp/${newSmp._id}`, { replace: true });

      notification.success({
        message: 'Created successfully, this is your new plan'
      });
    } else {
      notification.error({
        message: 'Something went wrong while trying to create a new plan'
      });
    }
  };

  return (
    <Section className={IS_HELM_APP ? 'helm-theme' : undefined} maxWidth="sm">
      <SectionItem>
        <div className={styles.question}>Do you want to create a new Self Management Plan?</div>

        <div className={styles.subtitle}>
          The new plan will be pre-filled with the latest answers you entered in go.tacklit.com
        </div>

        <div className={styles.actionContainer}>
          {IS_HELM_APP ? (
            <ButtonHelm className={styles.button} onClick={navigateToSmpListPage} variant="text" disabled={isLoading}>
              {'< Go back'}
            </ButtonHelm>
          ) : (
            <Button onClick={navigateToSmpListPage} variant="link" disabled={isLoading}>
              {'< Go back'}
            </Button>
          )}

          <div className={styles.createButtons}>
            {IS_HELM_APP ? (
              <ButtonHelm
                className={styles.button}
                onClick={() => createThenNavigate({ blankPlan: true })}
                variant="outlined"
                disabled={isLoading}
              >
                Start new plan
              </ButtonHelm>
            ) : (
              <Button onClick={() => createThenNavigate({ blankPlan: true })} variant="secondary" disabled={isLoading}>
                Start new plan
              </Button>
            )}

            {IS_HELM_APP ? (
              <ButtonHelm
                className={styles.button}
                onClick={() => createThenNavigate()}
                variant="contained"
                disabled={isLoading}
              >
                {isLoading ? 'Creating...' : 'Edit plan from my program activities'}
              </ButtonHelm>
            ) : (
              <Button onClick={() => createThenNavigate()} variant="primary" disabled={isLoading}>
                {isLoading ? 'Creating...' : 'Edit plan from my program activities'}
              </Button>
            )}
          </div>
        </div>
      </SectionItem>
    </Section>
  );
};

export default SMPNewPage;
