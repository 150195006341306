import { Helmet, HelmetProvider } from 'react-helmet-async';

interface SelectHelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favSelect.png`;

const SelectHelmetWrapper = ({ children, title }: SelectHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'Expert psychologists in Newcastle. Child, educational, behavioural & clinical psychology, counselling, therapy & treatments in a private setting.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default SelectHelmetWrapper;
