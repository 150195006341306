import styles from './ScaleBox.module.scss';

interface ScaleBoxProps {
  colour: string;
  formValue: number;
  value: number;
  onChange: (value: number) => void;
}

const ScaleBox = ({ colour, formValue, value, onChange }: ScaleBoxProps) => {
  return (
    <div className={styles.container} onClick={() => onChange(value)}>
      <div
        style={{ background: colour, zIndex: value }}
        className={formValue === value ? styles.pointFocus : styles.point}
      />
    </div>
  );
};

export default ScaleBox;
