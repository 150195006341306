import { RechargeSupportEmail } from 'Recharge/utils/RechargeEnv/RechargeEnv';

interface ErrorMessageWithHelpLinkProps {
  errorMessage: string;
  email?: string;
  showEmailOnly?: boolean;
}

const ErrorMessageWithHelpLink = ({
  errorMessage,
  showEmailOnly,
  email = RechargeSupportEmail
}: ErrorMessageWithHelpLinkProps) => {
  const supportEmail = email || RechargeSupportEmail;

  return (
    <>
      {errorMessage}{' '}
      {showEmailOnly ? (
        <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      ) : (
        <>
          Otherwise{' '}
          <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
            contact our support team
          </a>{' '}
          for assistance.
        </>
      )}{' '}
    </>
  );
};

export default ErrorMessageWithHelpLink;
