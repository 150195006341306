import { notification } from 'antd';
import { formatGraphData } from 'helm/utils/formatGraphData';
import { ChartData } from 'interfaces/MicroJournal/Chart';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getCheckInInsights } from 'utils/http/checkIn';

export const useFetchAppetiteCheckInGraph = ({ token, dateRange = 30 }: { token: string; dateRange?: number }) => {
  const [appetiteCheckInGraph, setAppetiteCheckInGraph] = useState<ChartData['insights']>([]);
  const [isAppetiteCheckInGraphLoading, setIsAppetiteCheckInGraphLoading] = useState(true);

  const fetchAppetiteCheckInGraph = useCallback(async ({ token, dateRange }: { token: string; dateRange: number }) => {
    setIsAppetiteCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights({
        token,
        queryParam: { startDate, endDate, type: 'appetite' }
      });
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setAppetiteCheckInGraph(formatGraphData(insights));
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your appetite tracking'
      });
    }

    setIsAppetiteCheckInGraphLoading(false);
  }, []);

  const refetch = (dateRange: number) => {
    if (token) {
      fetchAppetiteCheckInGraph({ token, dateRange });
    }
  };

  useEffect(() => {
    if (token) {
      fetchAppetiteCheckInGraph({ token, dateRange });
    }
  }, [fetchAppetiteCheckInGraph, dateRange, token]);

  return {
    appetiteTitle: 'How are your appetite and eating habits?',
    appetiteCheckInGraph,
    isAppetiteCheckInGraphLoading,
    fetchAppetiteCheckInGraph: refetch
  };
};
