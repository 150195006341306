import { Helmet, HelmetProvider } from 'react-helmet-async';

interface TacklitHelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = '/favicon.ico';

const TacklitHelmetWrapper = ({ children, title }: TacklitHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'The platform psychologists and mental health therapists have been waiting for. Coordinate all aspects of your practice or mental health and wellbeing program with our end to end platform. Measure and report clinical outcomes. Stay secure and compliant.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default TacklitHelmetWrapper;
