import styles from './SignUpCompleteBooking.module.scss';
import classNames from 'classnames';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import { useGetClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { useEaseUserContext } from 'Ease/contexts/EaseUserContextProvider';

const SignUpCompleteBooking = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const { practiceInfo } = useEaseUserContext();

  const { AVATAR } = useEaseRoutesGenerator();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];
  const { clinicianId, psychologistName } = useReserveAppointmentData();
  const { data: clinician } = useGetClinicianDetailsByIdQuery({
    clinicianId: clinicianId || ''
  });
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();

  const handleGoToAccount = () => {
    removeReserveAppointmentData();

    // this redirects from easewellbeing.co.uk to myease.co.uk (example)
    // uncomment if engage flow should be hosted on easewellbeing.co.uk, but
    // client-ui is on our own domain
    // if (!isDevelopmentENV()) {
    //   window.location.href = process.env.REACT_APP_CLIENT_DOMAIN_EASE || 'https://myease.co.uk';
    // } else {
    //   navigate(AVATAR);
    // }

    navigate(AVATAR);
  };

  return (
    <EaseHelmetWrapper title={'EASE Wellbeing | Therapy & Counselling Experts in London - Welcome'}>
      <div className={classNames(styles.container, 'ease-theme')}>
        <EaseHeader withPadding withMenu greenMenu />
        <EaseContentLayout>
          <div className={styles.content}>
            <div className={styles.title}>YOU'RE ALL SET</div>
            <div className={styles.welcome}>
              <div>
                <div className={styles.message}>Welcome {user?.name}</div>
                <div className={styles.message}>
                  Hi welcome onboard, if you have any concern or you need my help please contact me or meet me in
                  person. I'll always be there for you.
                </div>
                <div className={styles.messageEnding}>
                  Best Regards,
                  <br />
                  <div className={styles.clinicianName}>{clinician?.name || psychologistName || practiceInfo.name}</div>
                </div>
              </div>
            </div>

            <div className={styles.whatNext}>
              {!isAuthenticated && (
                <>
                  <div className={styles.label}>Your appointment has been confirmed. What next?</div>
                  <div className={styles.radioLabel}>
                    To hit the ground running and maximise on our time together, we ask you to please review and
                    complete the following pre-work:
                  </div>
                  <div className={styles.tasks}>
                    <ul>
                      <li>
                        <div className={styles.circle} /> <div className={styles.liText}>Client consent form</div>
                      </li>
                      <li>
                        <div className={styles.circle} />{' '}
                        <div className={styles.liText}>
                          Pre-session survey (this is voluntary, and the results will be used to inform the focus of our
                          sessions)
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.radioLabel}>We look forward to seeing you then.</div>
                </>
              )}
              <ButtonEase onClick={handleGoToAccount} className={styles.goToMyAccountBtn}>
                GO TO YOUR ACCOUNT
              </ButtonEase>
            </div>
          </div>
        </EaseContentLayout>
        <TacklitFooter linkToTacklit />
      </div>
    </EaseHelmetWrapper>
  );
};

export default SignUpCompleteBooking;
