import { useEffect, useState, KeyboardEvent } from 'react';
import styles from './BasicDetails.module.scss';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import classnames from 'classnames';
import { BasicDetailsErrorInterface, ClientSignUpInterface } from '../../SignUpFormInterface';
import { convertDateFormat } from './actions/dateChecker';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { validationBasicDetailsForm } from './validation/BasicDetailsValidation';

interface BasicDetailsProps {
  basicDetailsValue: ClientSignUpInterface;
  checkValidation: boolean;
  onChangeClientField: (val: ClientSignUpInterface) => void;
}

const BasicDetails = ({ basicDetailsValue, checkValidation, onChangeClientField }: BasicDetailsProps) => {
  const [basicDetailsField, setBasicDetailsField] = useState(basicDetailsValue);
  const [errorMessage, setErrorMessage] = useState({
    firstName: '',
    lastName: '',
    postcode: '',
    dateOfBirth: '',
    mobileNumber: ''
  } as BasicDetailsErrorInterface);

  useEffect(() => {
    if (checkValidation) {
      validateBasicField(basicDetailsField);
    }
  }, [checkValidation, basicDetailsField]);

  const validateBasicField = (BasicDetailsVal: ClientSignUpInterface) => {
    const validate = validationBasicDetailsForm(BasicDetailsVal);
    setErrorMessage(validate as BasicDetailsErrorInterface);
    return validate;
  };

  const handleDateChange = (dateValue: string) => {
    const newDateFormat = convertDateFormat(dateValue);
    const newClientField = {
      ...basicDetailsField,
      dateOfBirth: newDateFormat
    };
    setBasicDetailsField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validateBasicField(newClientField);
    }
  };

  const handleKeyDownDateChange = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      const newValue = basicDetailsField.dateOfBirth.slice(0, basicDetailsField.dateOfBirth.length - 1);
      const newClientField = {
        ...basicDetailsField,
        dateOfBirth: newValue
      };
      setBasicDetailsField(newClientField);
      onChangeClientField(newClientField);
      if (checkValidation) {
        validateBasicField(newClientField);
      }
    }
  };

  const handleChangeFieldValue = async (key: string, value: string) => {
    const newClientField = {
      ...basicDetailsField,
      [key]: value
    };
    setBasicDetailsField(newClientField);
    onChangeClientField(newClientField);
    if (checkValidation) {
      validateBasicField(newClientField);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Welcome to Tacklit</div>
        <div className={styles.desc}>
          Create your <strong>free</strong> and <strong>secure</strong> account
        </div>
      </div>
      <form className={styles.formContainer}>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.firstName && styles.fieldError)}
        >
          <MaterialInput
            label={'First Name*'}
            id={'firstName'}
            onChange={(e) => handleChangeFieldValue('firstName', e.target.value)}
            value={basicDetailsField.firstName}
            maxLength={80}
            required
          />
          {checkValidation && errorMessage.firstName && (
            <div className={styles.fieldError}>{errorMessage.firstName}</div>
          )}
        </div>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.lastName && styles.fieldError)}
        >
          <MaterialInput
            label={'Last Name*'}
            id={'lastName'}
            onChange={(e) => handleChangeFieldValue('lastName', e.target.value)}
            value={basicDetailsField.lastName}
            maxLength={80}
            required
          />
          {checkValidation && errorMessage.lastName && <div className={styles.fieldError}>{errorMessage.lastName}</div>}
        </div>
        <div
          className={classnames(styles.fieldContainer, checkValidation && errorMessage.postcode && styles.fieldError)}
        >
          <MaterialInput
            label={'Postcode*'}
            id={'postcode'}
            maxLength={7}
            onChange={(e) => handleChangeFieldValue('postcode', e.target.value)}
            value={basicDetailsField.postcode}
            autoComplete={'off'}
            required
          />
          {checkValidation && errorMessage.postcode && <div className={styles.fieldError}>{errorMessage.postcode}</div>}
        </div>
        <div
          className={classnames(
            styles.fieldContainer,
            checkValidation && errorMessage.dateOfBirth && styles.fieldError
          )}
        >
          <MaterialInput
            placeholder={'DD/MM/YYYY'}
            label="Date of Birth*"
            name="dateOfBirth"
            onKeyUp={(e) => handleKeyDownDateChange(e)}
            onChange={(e) => handleDateChange(e.target.value)}
            value={basicDetailsField.dateOfBirth}
            type={'tel'}
            pattern={'[0-9\\/]*'}
            maxLength={10}
            autoComplete="off"
          />
          {checkValidation && errorMessage.dateOfBirth && (
            <div className={styles.fieldError}>{errorMessage.dateOfBirth}</div>
          )}
        </div>
        <div className={styles.phoneContainer}>
          <MaterialPhoneInput
            id={`mobileNumber`}
            label={'Mobile Number*'}
            onChange={(e: string) => handleChangeFieldValue('mobileNumber', e)}
            value={basicDetailsField.mobileNumber}
            isError={checkValidation && !!errorMessage.mobileNumber}
          />
          {checkValidation && errorMessage.mobileNumber && (
            <div className={styles.fieldError}>{errorMessage.mobileNumber}</div>
          )}
        </div>
      </form>
    </div>
  );
};

export default BasicDetails;
