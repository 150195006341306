import styles from './RechargeFooter.module.scss';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import {
  RechargeAboutUsUrl,
  RechargeSupportEmail,
  RechargeSupportMobile,
  RechargeUrl
} from 'Recharge/utils/RechargeEnv/RechargeEnv';
import { Link } from 'react-router-dom';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import Flags from './flags.png';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

const RechargeFooter = () => {
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();

  return (
    <>
      <div className={styles.footerContainer}>
        <RechargeContentLayout className={styles.container}>
          <div className={styles.disclaimer}>
            Recharge Wellness is not an emergency service. If you need emergency support, please call{' '}
            <a href="tel:000">000</a> or contact these{' '}
            <a href="https://www.healthdirect.gov.au/mental-health-helplines">resources</a>.
          </div>
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <div className={styles.heading}>Contact Us</div>
              <a href={`mailto:${RechargeSupportEmail}`}>
                <i className={`material-icons-outlined ${styles.icon}`}>mail</i>
                {RechargeSupportEmail}
              </a>
              <a href={`tel:${RechargeSupportMobile}`}>
                <i className={`material-icons-outlined ${styles.icon}`}>call</i>
                {RechargeSupportMobile}
              </a>
              <div className={styles.location}>
                <i className={`material-icons-outlined ${styles.icon}`}>location_on</i>
                sydney, new south wales, aus
              </div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.links}>
                <div>
                  <Link to={`${PRACTITIONER.LISTING}`}>Find a Psychologist</Link>
                </div>
                <div>
                  <a href={`${RechargeUrl}pricing`}>Pricing</a>
                </div>
                <div>
                  <a href={RechargeAboutUsUrl}>About</a>
                </div>
                <div>
                  <a href={RechargeAboutUsUrl}>FAQs</a>
                </div>
                <div>
                  <a href={`${RechargeUrl}businesses`}>For Businesses</a>
                </div>
              </div>
              <a
                href={`${process.env.REACT_APP_CLIENT_DOMAIN_RECHARGE || 'http://localhost:4070'}/get-matched`}
                className={styles.getStartedButton}
              >
                Get Started
              </a>
            </div>
          </div>
          <div className={styles.acknowledgement}>
            <div className={styles.text}>
              Recharge Wellness acknowledges the traditional owners and custodians of country throughout Australia and
              acknowledge their continuing connection to land, water and community. We pay our respects to the people,
              the cultures and the elders past, present and emerging.
            </div>
            <img src={Flags} className={styles.flags} alt="aboriginal, torres strait islander, and pride flags" />
          </div>
        </RechargeContentLayout>
      </div>
      <TacklitFooter darkFooter />
    </>
  );
};

export default RechargeFooter;
