import TwoFAInput from 'components/v2/TwoFAInput/TwoFAInput';
import styles from './TokenAccess.module.scss';
import { useState } from 'react';
import { CODE_DEFAULT_VALUE, PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { PARTNER_CODE } from './partnerCode';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import { helmEnvironment } from '../../../utils/HelmEnv/helmEnv';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useHelmPartnerRoutesGenerator } from '../../utils/Path/HelmPartnerRoutesGenerator';
import ButtonHelm, { ButtonStatusType } from 'helm/components/ButtonHelm/ButtonHelm';
import wiseLogo from 'helm/assets/images/wise/WISE.png';

const TokenAccess = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<typeof CODE_DEFAULT_VALUE>(CODE_DEFAULT_VALUE);
  const [error, setError] = useState<string>('');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [signInBtnStatus, setSignInBtnStatus] = useState<ButtonStatusType>('');
  const { HelmSupportMail, HelpSupportTel, HelmFooterLogoURL } = helmEnvironment();
  const { PRACTITIONER } = useHelmPartnerRoutesGenerator();

  const onSignIn = () => {
    const accessToken = code.join('');
    if (PARTNER_CODE.some((obj) => obj.accessToken === accessToken)) {
      setSignInBtnStatus('active');
      const getPartnerCode = PARTNER_CODE.find((obj) => obj.accessToken === accessToken);
      if (getPartnerCode?.partnerCode) {
        setSignInBtnStatus('finished');
        localStorage.setItem(PARTNER_CODE_STORAGE, getPartnerCode?.partnerCode);
        setError('');
        setIsTouched(false);
        setTimeout(() => {
          setSignInBtnStatus('');
          navigate(PRACTITIONER.LISTING);
        }, 3000);
      }
    } else {
      setSignInBtnStatus('');
      setError('Invalid Code.');
      setIsTouched(true);
    }
  };

  const handleChangeCode = (code: typeof CODE_DEFAULT_VALUE) => {
    setError('');
    setIsTouched(false);
    setCode(code);
  };

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.background} />
      <HelmHeader withPadding noLogin classNames={styles.header} />
      <HelmHelmetWrapper title={'HELM - Sign Up'}>
        <HelmContentLayout>
          <div className={styles.content}>
            <div className={styles.leftContent}>
              <div className={styles.formContent}>
                <div className={styles.formWrapper}>
                  <div className={styles.welcomeText}>Welcome</div>
                  <div className={styles.text}>
                    This is the Helm partner referral channel. <br />
                    Please enter your organisation's access code.
                  </div>
                  <TwoFAInput
                    isText
                    error={error}
                    value={code}
                    inputContainerClassName={styles.inputContainerClassName}
                    inputWrapperClassName={styles.inputClassNameBox}
                    inputClassName={classNames(styles.inputClassName, error && styles.inputError)}
                    isTouched={isTouched}
                    onChangeValue={handleChangeCode}
                  />
                  <a className={styles.link} href={`mailto:${HelmSupportMail}`}>
                    Forgot code?
                  </a>
                  <ButtonHelm status={signInBtnStatus} fullWidth className={styles.signinButton} onClick={onSignIn}>
                    Sign In
                  </ButtonHelm>
                </div>
                <div className={styles.smallText}>
                  Organisation not registered yet? Contact us by{' '}
                  <a className={styles.link} href={`mailto:${HelmSupportMail}`}>
                    email
                  </a>{' '}
                  or{' '}
                  <a className={styles.link} href={`tel:${HelpSupportTel}`}>
                    phone.
                  </a>
                </div>
                <div className={styles.smallText}>
                  Looking for our direct access site?{' '}
                  <a className={styles.link} href={HelmFooterLogoURL}>
                    Please visit takethehelm.com.au
                  </a>
                </div>
                <div className={styles.wiseLogoContainer}>
                  <img className={styles.wiseLogo} src={wiseLogo} alt={'wise logo'} />
                </div>
              </div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.infoBox}>
                <div className={styles.welcomeText}>Take the Helm</div>
                <label>
                  Helm offers counselling and other support services that can be accessed quickly, confidentially and
                  without judgement.
                </label>
              </div>
            </div>
          </div>
        </HelmContentLayout>
      </HelmHelmetWrapper>
    </div>
  );
};

export default TokenAccess;
