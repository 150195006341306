import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import EaseCreateProfileForm from 'Ease/components/EaseCreateProfileForm/EaseCreateProfileForm';
import styles from './SignUp.module.scss';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { storeUserSession, UserSessionStorage } from 'Ease/pages/SignUp/helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode, useEffect } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'Ease/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import { getUkMobile, initialCustomer } from 'Ease/components/EaseCreateProfileForm/constants';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { fetchClaimReservation } from 'Ease/utils/api/fetchClaimReservation';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHelmetWrapper from 'Ease/components/EaseHelmetWrapper/EaseHelmetWrapper';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';
import EaseTimer from 'Ease/components/EaseTimer/EaseTimer';
import ClientTestimonial from 'Ease/components/ClientTestimonial/ClientTestimonial';
import ErrorMessageWithHelpLink from './components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { useEaseUserContext } from 'Ease/contexts/EaseUserContextProvider';
import { EaseDefaultTimezone } from 'Ease/utils/EaseEnv/EaseEnv';

const SignUp = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  const { practiceInfo } = useEaseUserContext();
  const { BOOKING, SIGNUP } = useEaseRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  useEffect(() => {
    if (!reserveId || !accountId) {
      navigate(BOOKING.BASE);
    }
  }, [BOOKING.BASE, accountId, navigate, reserveId]);

  if (isAuthenticated) {
    navigate(SIGNUP.CONFIRM_BOOKING);
  }

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession(value, dob);
    scrollToView('EaseHeader');
    navigate(SIGNUP.CONFIRM_BOOKING);
  };

  const onSubmitHandle = async (values: typeof initialCustomer) => {
    setIsSubmitting(true);
    const formatMobile = getUkMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, communicationPreference } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');

          const payloadMassage = {
            clinicianId: clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              timeZone: EaseDefaultTimezone,
              communicationPreference,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(accountId || '', payloadMassage);

          const { clientRecord, authToken } = await callPatientSignUp.json();
          await fetchClaimReservation({
            accountId: accountId!,
            reserveId: reserveId!,
            authToken,
            shouldGenerateCheckoutSession: false
          });
          setIsSubmitting(false);
          proceedToNextStep({ clientRecord, authToken }, dob);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." />);
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
      );
    }
  };

  return isLoading || fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <EaseHelmetWrapper title={'EASE Wellbeing | Therapy & Counselling Experts in London - Sign Up'}>
      <EaseContentLayout>
        <EaseHeader withPadding classNames={styles.header} greenMenu />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <EaseCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
              practiceName={practiceInfo.name}
            />
          </div>
          <div className={styles.rightContent}>
            <ClientTestimonial
              avatar="https://storage.googleapis.com/clinician-avatars/e160a4d42e82e0c300359427b45eb0c9-auth0|61137cdc838de9006a4ca594-profile-avatar.png"
              name="EASE Client"
              message='" Incredibly supportive in a non-judgmental space. My therapist helped me recognise the value of who I am as a person and to acknowledge my value. Also, helped me understand why I have acted and responded the way I have. It has provided me with an opportunity to learn and grow from my experiences. "'
            />
          </div>
        </div>
        <EaseTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} hideTimer />
      </EaseContentLayout>
      <TacklitFooter linkToTacklit />
    </EaseHelmetWrapper>
  );
};

export default SignUp;
