import classnames from 'classnames';
import { useState } from 'react';
import { Props, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import styles from './Select.module.scss';

export interface SelectProps extends Props {
  label?: string;
  labelClass?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  noErrorStyle?: boolean;
}

const innerStyles: StylesConfig<any, any> = {
  container: (base) => ({ ...base, width: 'calc(100% - 12px)' }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #d3d8dd',
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: 48,
    '&:hover': {
      borderColor: '#d3d8dd'
    }
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  menu: (base) => ({ ...base, marginTop: 0, width: '100% !important' }),
  placeholder: (base) => ({ ...base, fontSize: 13, lineHeight: 18 }),
  singleValue: (base) => ({ ...base, color: '#343434' }),
  valueContainer: (base) => ({ ...base, paddingLeft: 15 }),
  multiValue: (base) => ({ ...base, backgroundColor: '#2a4670', borderRadius: 25, color: 'white', padding: 4 }),
  multiValueLabel: (base) => ({ ...base, color: 'white' })
};

const StyledSelect = ({
  className,
  label,
  labelClass,
  hasError,
  noSpacing,
  options,
  styles: propStyles,
  value,
  multipleSelect,
  noErrorStyle,
  ...props
}: Props) => {
  const selectClasses = classnames(
    styles.select,
    hasError && !noErrorStyle && styles.error,
    noSpacing && styles.noSpacing
  );

  const [isFocusing, setIsFocusing] = useState(false);

  return (
    <div className={className}>
      <div
        className={classnames(
          labelClass,
          styles.label,
          isFocusing && styles.focusLabelClass,
          hasError && styles.errorLabel
        )}
      >
        {label}
      </div>
      <CreatableSelect
        className={selectClasses}
        styles={{ ...innerStyles, ...propStyles }}
        isMulti={multipleSelect}
        value={Array.isArray(options) && options.find((option) => option.value === value)}
        options={options}
        {...props}
        onFocus={() => setIsFocusing(true)}
        onBlur={() => setIsFocusing(false)}
      />
    </div>
  );
};

export default StyledSelect;
