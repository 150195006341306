import classNames from 'classnames';
import styles from './SectionItem.module.scss';

type SectionProps = {
  header?: string;
  children: React.ReactNode;
  totalNumber?: number;
  headerRight?: React.ReactNode;
  disableContentPadding?: boolean;
  disableContentPaddingTop?: boolean;
};

const SectionItem = ({
  header,
  children,
  headerRight,
  totalNumber,
  disableContentPadding,
  disableContentPaddingTop
}: SectionProps): JSX.Element => {
  return (
    <div>
      {header && (
        <div className={styles.headerContainer}>
          <h2 className={styles.header}>
            {header}
            {!Number.isNaN(totalNumber) && <div className={styles.totalNumber}>{totalNumber}</div>}
          </h2>
          {headerRight}
        </div>
      )}

      <div
        className={classNames(
          styles.content,
          disableContentPadding && styles.disableContentPadding,
          disableContentPaddingTop && styles.disableContentPaddingTop
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionItem;
