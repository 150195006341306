import { clientProfilesInterface, clientRecordsInterface } from 'interfaces/Clients/clientsRecord';

export interface UserSessionStorage {
  clientRecord: clientRecordsInterface;
  authToken: string;
  firstName?: string;
}

export const PORTOBELLO_SIGN_UP_CREATED_USER = 'PORTOBELLO_SIGN_UP_CREATED_USER';

export const storeUserSession = (session: UserSessionStorage, dateOfBirthValue: string, signUpSessionId?: string) => {
  const massageClientProfiles = (data: clientProfilesInterface[]) => {
    const massageClientList: clientProfilesInterface[] = [];
    for (let obj of data) {
      massageClientList.push({
        ...obj,
        dateOfBirth: dateOfBirthValue
      });
    }
    return massageClientList;
  };

  const massageSessionData: UserSessionStorage = {
    ...session,
    clientRecord: {
      ...session.clientRecord,
      clientProfiles: massageClientProfiles(session.clientRecord.clientProfiles)
    }
  };
  sessionStorage.setItem(signUpSessionId || PORTOBELLO_SIGN_UP_CREATED_USER, JSON.stringify(massageSessionData));
};

export const retrieveUserSession = (signUpSessionId?: string): Partial<UserSessionStorage> =>
  JSON.parse(
    sessionStorage.getItem(signUpSessionId || PORTOBELLO_SIGN_UP_CREATED_USER) || '{}'
  ) as Partial<UserSessionStorage>;
