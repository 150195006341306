import kickstartLogo from 'assets/images/smp/kickstartLogo.png';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import styles from './SMPHeader.module.scss';

const SMPHeader = () => {
  return (
    <div className={styles.container}>
      <div>{!IS_HELM_APP && <img src={kickstartLogo} alt="KickStart Logo" className={styles.img} />}</div>

      <div className={styles.text}>{`${IS_HELM_APP ? '' : 'KickStart'} Self Management Plan`}</div>
    </div>
  );
};

export default SMPHeader;
