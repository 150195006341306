import { ChangeEvent, useRef, useState } from 'react';
import styles from './ReferralPage.module.scss';
import {
  DETAIL_OPTIONS,
  validationForm,
  DEFAULT_FORM_VALUES,
  DEFAULT_ERROR,
  RECAPTCHA_KEY
} from '../../components/ReferralForm/constants';
import Button from 'components/v2/Button/Button';
import classnames from 'classnames';
import { notification } from 'antd';
import { useParams } from 'react-router-dom';
import { postReferral } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useFetchPublicPracticeProfile } from 'utils/hooks/accounts';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmpty from 'lodash/isEmpty';
import ContactForm from 'components/ContactForm/ContactForm';
import ReferralForm from '../../components/ReferralForm/ReferralForm';
import CheckBox from '../../components/CheckBox/CheckBox';
import ReferralBackgroundForm from '../../components/ReferralBackgroundForm/ReferralBackgroundForm';
import { buildPayload } from '../../components/ReferralForm/referralFormHelpers';
import { CoupleForm } from 'components/CoupleForm/CoupleForm';
import TacklitHelmetWrapper from 'Tacklit/components/TacklitHelmetWrapper/TacklitHelmetWrapper';

const ReferralPage = () => {
  const { slugUrl = '' } = useParams<{ slugUrl: string }>();
  const { practiceProfile, isPracticeProfileLoading } = useFetchPublicPracticeProfile(slugUrl);

  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR);
  const [captcha, setCaptcha] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onCaptchaChange = (value: string | null) => {
    if (value) {
      setCaptcha(value);
    }
  };

  const onCaptchaExpired = () => {
    setCaptcha('');
  };

  const handleSubmit = async () => {
    const validate = validationForm(formValues);
    setErrorMessage(validate);

    if (Object.values(validate).every((value) => value === '')) {
      setLoadingButton(true);

      try {
        const massagePayload = buildPayload(formValues, captcha).payload();
        const result = await postReferral(practiceProfile?.accountId || '', massagePayload);
        if (result.statusCode === 201) {
          setFormValues(DEFAULT_FORM_VALUES);
          notification.success({
            message: 'Successfully submitted referral',
            duration: 3,
            closeIcon: <span className="success">OK</span>
          });
        }
      } catch (ex) {
        notification.error({
          message: 'Failed to submit referral',
          duration: 2,
          closeIcon: <span className="fail">OK</span>
        });
      } finally {
        recaptchaRef.current?.reset();
        onCaptchaExpired();
        setLoadingButton(false);
      }
    }
  };

  return isPracticeProfileLoading ? (
    <div className={styles.loadingContainer}>
      <LoadingCircle />
    </div>
  ) : (
    <TacklitHelmetWrapper title={`${practiceProfile?.name} Referral Form`}>
      <div className={styles.container}>
        <div className={styles.practiceInfoWrapper}>
          <img className={styles.practiceLogo} src={practiceProfile?.logo} alt={'practice logo'} />
          <div className={styles.practiceInfo}>
            <div className={styles.practiceName}>{practiceProfile?.name}</div>
            <div className={styles.practiceDesc}>{practiceProfile?.description}</div>
          </div>
        </div>

        <div className={styles.formWrapper}>
          <div className={styles.informationFormWrapper}>
            <ReferralForm
              formValues={formValues}
              setFormValues={setFormValues}
              referralDetailOption={DETAIL_OPTIONS}
              errorMessage={errorMessage}
            />
          </div>
          <ContactForm
            errorMessage={errorMessage}
            formValues={formValues}
            setFormValues={setFormValues}
            referralDetailOption={DETAIL_OPTIONS}
          />
          {formValues.isForMySelf && formValues.referralRecordType === 'couple' && (
            <CoupleForm
              formValues={formValues}
              setFormValues={setFormValues}
              errorMessage={errorMessage}
              referralDetailOption={DETAIL_OPTIONS}
            />
          )}
          <ReferralBackgroundForm
            errorMessage={errorMessage}
            referralBackground={formValues.referralBackground}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setFormValues({ ...formValues, referralBackground: event.target.value });
            }}
          />

          <div className={styles.tncCheckerWrapper}>
            <div className={styles.referralConfirm}>
              <CheckBox
                id={'referralConfirm'}
                label={'Please confirm you have consent to make this referral'}
                value={formValues.referralConfirm}
                onChange={(event) => {
                  setFormValues({ ...formValues, referralConfirm: event.target.checked });
                }}
              />
            </div>
            <div className={styles.captchaContainer}>
              <ReCAPTCHA
                ref={recaptchaRef}
                type={'image'}
                sitekey={RECAPTCHA_KEY}
                onChange={onCaptchaChange}
                onExpired={onCaptchaExpired}
              />
            </div>
          </div>
          <div className={styles.submitBtnWrapper}>
            <Button
              disabled={(!formValues.isForMySelf && !formValues.referralConfirm) || isEmpty(captcha) || loadingButton}
              className={classnames(
                styles.submitBtn,
                ((!formValues.isForMySelf && !formValues.referralConfirm) || isEmpty(captcha) || loadingButton) &&
                  styles.disabled
              )}
              onClick={handleSubmit}
            >
              Make Referral Request
            </Button>
          </div>
          <div className={styles.policyWrapper}>
            <div className={styles.privacy}>
              {`You agree to this information being actioned in line with our `}
              <a
                target="_blank"
                rel={'noopener noreferrer'}
                className={styles.policyURL}
                href={'https://www.tacklit.com/policies/privacy-policy'}
              >
                privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </TacklitHelmetWrapper>
  );
};

export default ReferralPage;
