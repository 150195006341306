import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { useNavigate, generatePath } from 'react-router-dom';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import WelcomeMessage from 'pages/PatientPage/HomePage/components/WelcomeMessage/WelcomeMessage';
import { useFetchCompletedCheckInStatus } from 'pages/PatientPage/HomePage/hooks/getCheckInStatus';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { useGetAccessToken } from 'utils/hooks/token';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';

import QuestionBox from './components/QuestionBox/QuestionBox';
import SuggestionBox from './components/SuggestionBox/SuggestionBox';
import ActionSummaryBox from './components/ActionSummaryBox/ActionSummaryBox';
import styles from './RechargeHomePage.module.scss';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const RechargeHomePage = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );
  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';
  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const hasTask =
    (clientTasks &&
      (clientTasks.consentList.length > 0 ||
        clientTasks.assessmentList.length > 0 ||
        clientTasks.failedPayments.length > 0)) ||
    (surveyList && surveyList.length > 0) ||
    psychometricList.length > 0;

  const clientTasksLength = clientTasks
    ? clientTasks.failedPayments.length + clientTasks.consentList.length + clientTasks.assessmentList.length
    : 0;
  const surveyListLength = surveyList ? surveyList.length : 0;
  const psychometricListLength = psychometricList ? psychometricList.length : 0;
  const totalLength = clientTasksLength + surveyListLength + psychometricListLength;

  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();
  const bookNewAppointmentUrl = attachedClinicianDetail?.clinician
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId: attachedClinicianDetail.clinician._id
      })
    : PRACTITIONER.LISTING;

  return (
    <RechargeHelmetWrapper title={`Recharge | Welcome ${user ? user.name : ''}`}>
      <RechargeContentLayout className={`${styles.container} recharge-theme`}>
        {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
          <Button
            className={styles.fixedLeftBtn}
            type={'primary'}
            disabled={isAttachedClinicianDetailLoading}
            onClick={() => {
              navigate(`/${attachedClinicianDetail.clinician?.slugUrl}/checkIn`);
            }}
          >
            <div className={styles.newJournalBtn}>
              <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
              NEW JOURNAL ENTRY
            </div>
          </Button>
        )}
        <div className={styles.contentWrapper}>
          <RechargeHeader withPadding withMenu homeLink whiteMenu />
          <div className={styles.content}>
            {isContentLoading || isCompletedCheckInStatusLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                <div className={styles.main}>
                  {attachedClinicianDetail && (
                    <WelcomeMessage
                      clinicianName={clinicianName}
                      clinicianDetail={attachedClinicianDetail}
                      completedCheckInStatus={completedCheckInStatus}
                      className={styles.welcomeMessage}
                    />
                  )}
                  <div className={styles.mainContent}>
                    <div className={styles.leftContent}>
                      <SuggestionBox bookNewAppointmentUrl={bookNewAppointmentUrl} />
                    </div>
                    <div className={styles.rightContent}>
                      {hasTask ? (
                        <ActionSummaryBox
                          failedPayments={
                            clientTasks && clientTasks.failedPayments && clientTasks.failedPayments.length > 0
                              ? clientTasks.failedPayments
                              : undefined
                          }
                          totalLength={totalLength}
                        />
                      ) : (
                        <div className={styles.desc}>You have no outstanding tasks at this time.</div>
                      )}
                      <QuestionBox />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </RechargeContentLayout>
    </RechargeHelmetWrapper>
  );
};

export default RechargeHomePage;
