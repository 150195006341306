import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import ButtonSH from '../ButtonSH/ButtonSH';
import FloatingHelpBtnHubSpot from '../FloatingHelpBtnHubSpot/FloatingHelpBtnHubSpot';
import styles from './FloatingHelpBtn.module.scss';

interface FloatingHelpBtnProps {
  hubspot?: boolean;
}

const FloatingHelpBtn = ({ hubspot }: FloatingHelpBtnProps) => {
  const URLs = someoneHealthEnvironment();

  return hubspot ? (
    <FloatingHelpBtnHubSpot />
  ) : (
    <ButtonSH variant={'contained'} onClick={() => (window.location.href = URLs.FAQURL)} className={styles.container}>
      <i className={`material-icons-outlined ${styles.icon}`}>question_mark</i>
    </ButtonSH>
  );
};

export default FloatingHelpBtn;
