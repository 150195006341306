import styles from './PractitionerDetails.module.scss';
import classnames from 'classnames';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { Link } from 'react-router-dom';

interface PractitionerDetailsProps {
  className?: string;
  detailPath: string;
  practitionerDetails: PractitionerDetailsInterface;
}

const PractitionerDetails = ({ className, detailPath, practitionerDetails }: PractitionerDetailsProps) => (
  <div className={classnames(styles.container, className && className)}>
    <div className={styles.topContent}>
      <Link to={detailPath} className={styles.name}>
        {practitionerDetails.name}
      </Link>
    </div>
    <div className={styles.role}>{practitionerDetails.helmControl.bonaFides?.style?.value}</div>
  </div>
);

export default PractitionerDetails;
