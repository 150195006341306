import { notification } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import ContactForm from 'components/ContactForm/ContactForm';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import {
  DEFAULT_ERROR,
  DEFAULT_FORM_VALUES,
  DETAIL_OPTIONS,
  RECAPTCHA_KEY,
  validationForm
} from 'components/ReferralForm/constants';
import { buildPayload } from 'components/ReferralForm/referralFormHelpers';
import ReferralForm from 'components/ReferralForm/ReferralForm';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { postReferral } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import styles from './JoinWaitlistForm.module.scss';
import classNames from 'classnames';
import ButtonEase from '../ButtonEase/ButtonEase';
import PsychologistAvatar from '../PsychologistAvatar/PsychologistAvatar';
import { isEmpty } from 'lodash';
import { useEaseUserContext } from 'Ease/contexts/EaseUserContextProvider';
import easeLogo from 'Ease/assets/images/logo/logo.png';
import { EasePrivacyPolicyUrl } from 'Ease/utils/EaseEnv/EaseEnv';

interface JoinWaitlistFormProps {
  onComplete: (input: { createdClientRecord: clientRecordsInterface }) => void;
}

const JoinWaitlistForm = ({ onComplete }: JoinWaitlistFormProps) => {
  const { practiceInfo } = useEaseUserContext();

  const [ageConfirm, setAgeConfirm] = useState(false);
  const [receiveCommunications, setReceiveCommunications] = useState(false);
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(DEFAULT_FORM_VALUES);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR);
  const [captcha, setCaptcha] = useState('');

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const onCaptchaChange = (value: string | null) => {
    if (value) {
      setCaptcha(value);
    }
  };

  const onCaptchaExpired = () => {
    setCaptcha('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const validate = validationForm(formValues);
    setErrorMessage(validate);
    const checkFieldHaveError = validate ? Object.values(validate).some((value) => value !== '') : false;
    if (checkFieldHaveError) {
      setIsSubmitting(false);
      document.getElementById('join-waitlist-form-header')?.scrollIntoView();
      return;
    }
    const phoneValidate = await validatePhoneNumber(
      formValues.isUseGuardianInformation === 'guardianInformation'
        ? formValues.guardianContactNumber
        : formValues.contactNumber
    );
    if (phoneValidate.valid) {
      try {
        const massagePayload = buildPayload(formValues, captcha).payload();

        const res = await postReferral(practiceInfo.accountId, massagePayload);
        setIsSubmitting(false);
        notification.success({ message: `Joined ${practiceInfo.name}'s waitlist successfully.` });
        const { createdClientRecord } = await res.json();
        setFormValues(DEFAULT_FORM_VALUES);
        setReceiveCommunications(false);
        setAgeConfirm(false);
        onComplete({ createdClientRecord });
      } catch (ex) {
        setIsSubmitting(false);
        notification.error({
          message: `Something went wrong while trying to join ${practiceInfo.name}'s waitlist.`
        });
      }
    } else {
      setIsSubmitting(false);
      setInvalidMobileNumber(true);
      document.getElementById('join-waitlist-form-header')?.scrollIntoView();
    }
  };

  const isBtnDisabled = !ageConfirm || !receiveCommunications || isEmpty(captcha);

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      )}
      <div className={classNames(styles.container, 'ease-theme')}>
        <div className={styles.header} id="join-waitlist-form-header">
          <PsychologistAvatar className={styles.profile} classNameFrame={styles.profileImg} profileImg={easeLogo} />

          <div className={styles.headerTitle}>
            {`Register your interest for ${practiceInfo.name}.`}
            <div className={styles.subHeader}>Fill in the relevant information below.</div>
          </div>
        </div>

        <div className={styles.loginText}>
          Already have an account?
          <Link to={`/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </Link>
        </div>

        <ContactForm
          errorMessage={errorMessage}
          formValues={formValues}
          setFormValues={setFormValues}
          setInvalidMobileNumber={setInvalidMobileNumber}
          referralDetailOption={DETAIL_OPTIONS}
          invalidMobileNumber={invalidMobileNumber}
          resetErrors={(key: string) => setErrorMessage({ ...errorMessage, [key]: '' })}
        />

        <div className={styles.referral}>
          <ReferralForm
            formValues={formValues}
            setFormValues={setFormValues}
            referralDetailOption={DETAIL_OPTIONS}
            errorMessage={errorMessage}
          />
        </div>

        <div className={styles.ageConfirm}>
          <div className={styles.checkBoxText}>
            <CheckBox
              id="ageConfirm"
              value={ageConfirm}
              onChange={(e) => setAgeConfirm(e.target.checked)}
              className={styles.checkBox}
              label={`I confirm I am over the age of 18 and agree to ${practiceInfo.name}'s <a target="_blank" href="${EasePrivacyPolicyUrl}">Privacy Policy and Terms & Conditions</a>`}
            />
          </div>
        </div>

        <div className={styles.communicationPreferences}>
          <div className={styles.title}>Communication Preferences</div>
          <div className={styles.text}>
            We will contact you in regards to helping set you up with an available counsellor or service. Please confirm
            you give consent for us to contact you to discuss.
          </div>
          <div className={styles.checkBoxes}>
            <div className={styles.checkBoxText}>
              <CheckBox
                id="receiveCommunications"
                value={receiveCommunications}
                onChange={(e) => setReceiveCommunications(e.target.checked)}
                className={styles.checkBox}
                label={`I am happy to be contacted by the ${practiceInfo.name} team.`}
              />
            </div>
          </div>
        </div>

        <ReCAPTCHA
          ref={reCaptchaRef}
          type={'image'}
          sitekey={RECAPTCHA_KEY}
          onChange={onCaptchaChange}
          onExpired={onCaptchaExpired}
        />

        <ButtonEase className={styles.submitButton} disabled={isBtnDisabled} onClick={handleSubmit}>
          JOIN WAITLIST NOW
        </ButtonEase>
      </div>
    </>
  );
};

export default JoinWaitlistForm;
