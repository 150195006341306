import styles from './ClientProfileWidget.module.scss';

interface ClientProfileWidgetProps {
  data: any;
}

const ClientProfileWidget = ({ data }: ClientProfileWidgetProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{data.title}</div>
  </div>
);

export default ClientProfileWidget;
