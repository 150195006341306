import styles from './CancellationPolicy.module.scss';

const CancellationPolicy = () => (
  <div className={styles.container}>
    An initial appointment is to discuss your concerns and for us to assess how we can help you and for you to assess if
    you wish to proceed into therapy with EASE Wellbeing. If you attend an initial appointment and decide not to
    continue into therapy you can request a refund.
    <br />
    <br />
    If you do not turn up to your appointment you will not receive a refund. If you need to reschedule or cancel your
    appointment you can do so up to 24 hours before your appointment. If however you are unable to attend the
    alternative appointment times offered you will not receive a refund.
    <br />
    <br />
    If we need to reschedule your appointment and you cannot attend the alternative day/time offered you are entitled to
    a refund.
    <br />
    <br />
    Please note, all cancellations or rebooking requests need to be made by emailing{' '}
    <a href="mailto:engage@easewellbeing.co.uk">engage@easewellbeing.co.uk</a> or by calling{' '}
    <a href="tel:02036336653">02036336653</a>.
  </div>
);
export default CancellationPolicy;
