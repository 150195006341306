import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import styles from './TimExpireAlert.module.scss';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';

interface TimExpireAlertProps {
  showExpiredAlert: boolean;
}
const TimExpireAlert = ({ showExpiredAlert }: TimExpireAlertProps) => {
  const { CaWHomeURL } = cawEnvironment();
  const { clinicianId } = useReserveAppointmentData();

  return (
    <Modal
      className={styles.modalContainer}
      bodyStyle={{ padding: 0 }}
      visible={showExpiredAlert}
      footer={null}
      closable={false}
    >
      <div className={styles.container}>
        <i className={`material-icons-outlined ${styles.infoIcon}`}>info</i>
        <div className={styles.alertContent}>
          <div className={styles.title}>Your appointment hold has timed out.</div>
          <p>
            Because of high demand for our psychologists we can only reserve your appointment time for 15 minutes for
            you to complete your booking. This is to ensure fairness for everyone trying to find an available slot.{' '}
          </p>
          <p>
            You may find the same appointment slot is still available, so please go back and select an open appointment.
          </p>
          <Link
            to={
              clinicianId
                ? `/psychologists-profile/${clinicianId}`
                : isDevelopmentENV()
                ? '/psychologists'
                : `${CaWHomeURL}counsellor`
            }
            className={styles.link}
          >
            Return to the profile page
            <i className={`material-icons-outlined ${styles.backIcon}`}>arrow_back</i>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default TimExpireAlert;
