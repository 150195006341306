import classNames from 'classnames';

import styles from './PatientFooterCard.module.scss';

interface PatientContentLayoutProps {
  children?: any;
  className?: string;
  containerClassName?: string;
}

const PatientFooterCard = ({ children, className, containerClassName }: PatientContentLayoutProps) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={classNames(styles.card, className)}>{children}</div>
    </div>
  );
};

export default PatientFooterCard;
