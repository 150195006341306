import moment from 'moment';
import SelectSlot from '../SelectSlot/SelectSlot';
import styles from './BookingSession.module.scss';
import { AvailabilityAppointmentTypes, DeliveryType, TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import classNames from 'classnames';
import ToggleSwitchV2 from 'components/ToggleSwitchV2/ToggleSwitchV2';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import { useMemo } from 'react';
import { generateEngageConsultPreferences, getDeliveryTypeLabel } from 'utils/appointment';

interface BookingSessionProps {
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  selectedTimeSlots: TimeSlotsWithDateInterface[];
  onRemoveSelectedSlot: (slot: TimeSlotsWithDateInterface) => void;
  isAvailabilityListLoading: boolean;
  isProcessingReservation: boolean;
  onContinue: () => void;
  isAdvisorySession?: boolean;
  selectedDeliveryPreference: DeliveryType;
  onChangeDeliveryPreference: (value: DeliveryType) => void;
}

const BookingSession = ({
  selectedAppointmentType,
  selectedTimeSlots,
  onRemoveSelectedSlot,
  isAvailabilityListLoading,
  isProcessingReservation,
  onContinue,
  isAdvisorySession,
  selectedDeliveryPreference,
  onChangeDeliveryPreference
}: BookingSessionProps) => {
  const consultPreferences = useMemo(
    () =>
      generateEngageConsultPreferences({
        selectedAppointmentType,
        selectedDeliveryPreference
      }),
    [selectedAppointmentType, selectedDeliveryPreference]
  );

  return (
    <div className={classNames(styles.bookingContainer, isAdvisorySession && styles.isAdvisorySession)}>
      <CaWContentLayout className={styles.contentLayout}>
        <div className={styles.bookingContent}>
          {/* Description */}
          <div className={classNames(styles.bookingDesc)}>
            <div className={styles.bookingTitle}>You are booking</div>
            <div className={styles.bookingDescSummary}>
              {`${selectedAppointmentType?.name} | ${selectedAppointmentType?.duration} minutes on`}
            </div>
          </div>

          <div className={styles.middleContent}>
            {/* Slot */}
            <div className={styles.topContainer}>
              <div className={styles.slotContainer}>
                {selectedTimeSlots.filter((obj) => obj.isConflict).length > 0 && (
                  <div className={styles.blocked}>
                    Our counsellors are in high demand. Some times are no longer available. Please select another time.
                  </div>
                )}
                <div className={styles.slotWrapper}>
                  {selectedTimeSlots.map((timeSlotObj, index) => (
                    <SelectSlot
                      key={index}
                      date={moment(timeSlotObj.date)}
                      time={`${moment(timeSlotObj.startTime, 'hh:mmA').format('hh:mm')} - ${timeSlotObj.endTime}`}
                      onDelete={() => onRemoveSelectedSlot(timeSlotObj)}
                      blockSlot={timeSlotObj.isConflict}
                    />
                  ))}
                  {Array.from(Array((selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length)).map(
                    (_, i) => (
                      <SelectSlot key={i} />
                    )
                  )}
                </div>
              </div>
              {/* Consult Preferences */}
              {consultPreferences && consultPreferences.length > 0 && (
                <div className={styles.consultPreferenceWrapper}>
                  <div className={styles.consultPreference}>
                    <div className={styles.consultPreferenceLabel}>CONSULT PREFERENCE</div>
                    <ToggleSwitchV2
                      id="consultPreference"
                      toggleList={consultPreferences}
                      onChangeStatus={(value) => {
                        onChangeDeliveryPreference(value.id as DeliveryType);
                      }}
                      className={styles.consultPreferenceButton}
                      wrapperClassName={styles[`optionButton${consultPreferences?.length}`]}
                      indicatorClassName={styles.indicator}
                      labelClassName={styles.label}
                      labelActiveClassName={styles.labelActive}
                    />
                    <div className={styles.selectedPreference}>
                      {`${getDeliveryTypeLabel(
                        selectedDeliveryPreference,
                        selectedAppointmentType?.otherInstructions
                      ).toUpperCase()} preferred`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Next button */}
          <div className={styles.continueBtnWrapper}>
            <ButtonCaW
              className={styles.continueBtn}
              onClick={onContinue}
              disabled={
                isAvailabilityListLoading ||
                isProcessingReservation ||
                selectedTimeSlots.length < (selectedAppointmentType?.slotCount || 1)
              }
              icon={'navigate_next'}
              iconPostFix
            >
              Next to complete booking
            </ButtonCaW>
            <div className={styles.continueLabel}>Appointment not confirmed until complete</div>
          </div>
        </div>
      </CaWContentLayout>
    </div>
  );
};

export default BookingSession;
