import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './ListingFilter.module.scss';
import FilterDropdownWrapper from '../components/FilterDropdownWrapper/FilterDropdownWrapper';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Recharge/interfaces/mentalHealth';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import TimeZoneDropdown from 'components/TimeZoneDropdown/TimeZoneDropdown';
import { RECHARGE_TIME_ZONE_LIST } from 'utils/constants/timeZone';

interface ListingFilterProps {
  selectedMentalHealthConcern: MentalHealthList[];
  onChangeMentalHealthConcern: (value: listInterface) => void;
  selectedTimezone: string;
  onChangeTimeZone: (value: string) => void;
}

const ListingFilter = ({
  selectedMentalHealthConcern,
  onChangeMentalHealthConcern,
  selectedTimezone,
  onChangeTimeZone
}: ListingFilterProps) => {
  const massageMentalHealthConcern = selectedMentalHealthConcern.map((item) => ({
    id: item,
    label: MENTAL_HEALTH_LIST_LABELS[item]
  }));

  return (
    <RechargeContentLayout>
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div className={styles.label}>SHOWING MATCHES FOR</div>
          <FilterDropdownWrapper
            classNames={styles.filter}
            mentalHealthConcernValue={massageMentalHealthConcern}
            onChangeMentalHealthConcernValue={onChangeMentalHealthConcern}
          />
        </div>
        <div className={styles.timezone}>
          <TimeZoneDropdown
            listData={RECHARGE_TIME_ZONE_LIST}
            selectedValue={
              RECHARGE_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimezone) || RECHARGE_TIME_ZONE_LIST[0]
            }
            selectClassName={styles.selectDropDown}
            onChangeValue={(value) => onChangeTimeZone(value.id)}
          />
        </div>
      </div>
    </RechargeContentLayout>
  );
};

export default ListingFilter;
