import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { retrieveUserSession } from 'Portobello/pages/SignUp/helper/userSession';

export const usePortobelloToken = (signUpSessionId?: string) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();
  const [isInitialised, setIsInitialised] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const loginToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          setToken(loginToken);
          setIsInitialised(true);
        })();
      } else {
        const storedTempAuthToken = retrieveUserSession(signUpSessionId).authToken;
        if (storedTempAuthToken) {
          setToken(storedTempAuthToken);
        }
        setIsInitialised(true);
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, signUpSessionId]);

  return { token, isInitialised };
};
