import styles from './EaseTimer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimExpireAlert from 'components/TimeExpireAlert/TimeExpireAlert';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';

interface EaseTimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
  reservedAppointmentId?: string;
}

const EaseTimer = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  hideTimer,
  onlyText,
  reservedAppointmentId
}: EaseTimerProps) => {
  const navigate = useNavigate();
  const { BOOKING } = useEaseRoutesGenerator();
  const { reserveId } = useReserveAppointmentData(reservedAppointmentId);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  if (!reserveId) {
    navigate(BOOKING.BASE);
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 1) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#ADEA5C'}
      />
      <TimExpireAlert
        showExpiredAlert={showExpiredAlert}
        returnLinkText={'Return to booking page'}
        returnRoute={BOOKING.BASE}
      />
    </div>
  );
};

export default EaseTimer;
