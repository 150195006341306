import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCardV2/PatientFooterCard';
import Radio from 'components/Radio/Radio';
import moment from 'moment';
import { ChangeEvent, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  AppointmentSlot,
  AvailabilityAppointmentTypes,
  DeliveryType,
  OtherInstructions,
  useAvailability
} from 'utils/hooks/appointment';
import { Clinician } from 'utils/hooks/clinician';

import AvailabilityListing from '../AppointmentBookingCalendar/components/AvailabilityListing/AvailabilityListing';
import AppointmentHeader from '../components/AppointmentHeader/AppointmentHeader';
import styles from './AppointmentBooking.module.scss';
import { AU_TIME_ZONE_LIST, defaultClinicianTimezone } from '../../../utils/constants/timeZone';
import { checkAppointmentTypeDeliveryOptionAvailability, getDeliveryTypeLabel } from 'utils/appointment';

const getDeliveryTypeFilterOptions = (otherInstructions?: OtherInstructions) => [
  { value: DeliveryType.FaceToFace, label: getDeliveryTypeLabel(DeliveryType.FaceToFace) },
  { value: DeliveryType.VideoCall, label: getDeliveryTypeLabel(DeliveryType.VideoCall) },
  // Only using phone call, but should include both phone call types when filtering
  { value: DeliveryType.PhoneCall, label: getDeliveryTypeLabel(DeliveryType.PhoneCall) },
  ...(otherInstructions
    ? [{ value: DeliveryType.Other, label: getDeliveryTypeLabel(DeliveryType.Other, otherInstructions) }]
    : [])
];

const AppointmentBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<
    Required<AppointmentSlot>['deliveryOptions'][number] | ''
  >('');
  const handleDeliveryOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDeliveryOption = e.target.value as Required<AppointmentSlot>['deliveryOptions'][number];

    if (selectedDeliveryOption === newDeliveryOption) {
      setSelectedDeliveryOption('');
    } else {
      setSelectedDeliveryOption(newDeliveryOption);
    }
  };

  const { clinician, appointmentType, isNewClient } = useMemo(() => {
    const { clinician, appointmentType, isNewClient } =
      (location.state as
        | { clinician: Clinician; appointmentType: AvailabilityAppointmentTypes; isNewClient?: boolean }
        | undefined) || {};

    if (!clinician || !appointmentType) {
      navigate(`/${clinicianId}/appointment`);
    }
    return { clinician, appointmentType, isNewClient };
  }, [navigate, location.state, clinicianId]);

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailability({
    appointmentTypeId: appointmentType?._id!,
    accountId: clinician?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(5, 'week').format('YYYY-MM-DD'),
    clinicianId
  });

  const isAvailabilityListEmpty = useMemo(() => {
    if (
      !appointmentAvailability ||
      (selectedDeliveryOption &&
        !checkAppointmentTypeDeliveryOptionAvailability(
          appointmentAvailability.appointmentType.deliveryOptions || [],
          selectedDeliveryOption
        )) ||
      // @ts-ignore
      !Object.values(appointmentAvailability.timeSlots).some((timeSlot) => timeSlot.isAvailable)
    ) {
      return true;
    }

    return false;
  }, [appointmentAvailability, selectedDeliveryOption]);

  const timezone = clinician?.workTimeZone || clinician?.accountSettings?.timezone || defaultClinicianTimezone;
  const timeZoneLocation = AU_TIME_ZONE_LIST.find(({ timezones }) => timezones.includes(timezone))?.label;

  return (
    <PatientContentLayout>
      {clinician && appointmentType && (
        <>
          <div className={styles.contentWrapper}>
            <AppointmentHeader profileName={clinician.name} profileImg={clinician.avatar} label="Confirm Details" />
          </div>
          <PatientFooterCard containerClassName={styles.fullHeightFooter} className={styles.cardContainer}>
            <div className={styles.title}>Available times for</div>
            <div className={styles.appointmentTypeWrapper}>
              <div className={styles.appointmentName}>{appointmentType.name}</div>
              <div className={styles.timezone}>Timezone: {timeZoneLocation}</div>
              <div className={styles.filterTitle}>filter</div>
              <div className={styles.filterList}>
                {/* TODO: Change Radio to checkbox, so user can deselect option */}
                <Radio
                  variant={'button'}
                  options={getDeliveryTypeFilterOptions(appointmentType.otherInstructions)}
                  value={selectedDeliveryOption}
                  onChange={handleDeliveryOptionChange}
                />
              </div>
            </div>
            <AvailabilityListing
              appointmentType={appointmentAvailability?.appointmentType}
              availabilities={appointmentAvailability?.timeSlots}
              clinician={clinician}
              timezone={timezone}
              isAvailabilityListEmpty={isAvailabilityListEmpty}
              isAvailabilityListLoading={isAvailabilityListLoading}
              isNewClient={isNewClient}
              paymentRequired={appointmentType.paymentRequired}
            />
          </PatientFooterCard>
        </>
      )}
    </PatientContentLayout>
  );
};

export default AppointmentBooking;
