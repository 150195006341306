import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './Map.module.scss';

interface MapProps {
  address?: string;
}

const Map = ({ address }: MapProps) => (
  <div className={styles.container}>
    <div className={styles.location}>
      <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
      <div className={styles.text}>{address}</div>
    </div>
    <iframe
      title="map-frame"
      className={styles.mapWrapper}
      width="100%"
      height="260"
      frameBorder="0"
      aria-hidden="false"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB_R-981DOmtR0PO166zBmA61rH43BQPOo&q=${address}`}
      allowFullScreen
    />
  </div>
);

export default Map;
