import { useState, ChangeEvent, TextareaHTMLAttributes, useEffect, useRef } from 'react';
import classnames from 'classnames';

import styles from './TextArea.module.scss';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  containerClass?: string;
  hasError?: boolean;
  label?: string;
}

const TextArea = ({ className, containerClass, hasError, rows, onChange, label, ...props }: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const textAreaLineHeight = 24;
  const textAreaClasses = classnames(styles.textArea, hasError && styles.error, className);
  const [textArea, setTextArea] = useState({
    rows: 1,
    min: 1,
    max: 8
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e);

    if (!(typeof rows === 'number')) {
      const minRows = textArea.min;
      const maxRows = textArea.max;

      const previousRows = e.target.rows;
      e.target.rows = minRows;

      const currentRows = Math.floor(e.target.scrollHeight / textAreaLineHeight);

      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        e.target.rows = maxRows;
        e.target.scrollTop = e.target.scrollHeight;
      }

      setTextArea({
        ...textArea,
        rows: currentRows < maxRows ? currentRows : maxRows
      });
    }
  };

  useEffect(() => {
    if (props.value && textAreaRef?.current) {
      const currentRows = Math.floor(textAreaRef.current.scrollHeight / textAreaLineHeight);

      if (currentRows >= textArea.max) {
        textAreaRef.current.rows = textArea.max;
      } else if (currentRows > 1) {
        textAreaRef.current.rows = currentRows;
      }
    }
  }, [props.value, textArea.max]);

  return (
    <div className={classnames(styles.container, !label && styles.noLabel, containerClass)}>
      <textarea ref={textAreaRef} className={textAreaClasses} onChange={handleChange} rows={textArea.rows} {...props} />
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default TextArea;
