import styles from './WelcomeContent.module.scss';
import Handlebars from 'handlebars';
import DefaultWelcomeContent from './DefaultWelcomeContent/DefaultWelcomeContent';

interface WelcomeContentProps {
  clientName: string;
  clinicianName: string;
  clinicianAvatar?: string;
  withBox?: boolean;
  welcomeMessage: string;
}

const WelcomeContent = ({
  clientName,
  clinicianAvatar,
  clinicianName,
  withBox,
  welcomeMessage
}: WelcomeContentProps) => {
  const prefillClientName = Handlebars.compile(welcomeMessage);
  const variableList = {
    CLIENT_NAME: clientName,
    CLINICIAN_NAME: clinicianName
  };

  return (
    <div className={withBox ? styles.container : styles.noBoxContainer}>
      <div className={styles.expandHeader}>
        {clinicianAvatar && <img className={styles.logoWrapper} src={clinicianAvatar} alt={'practiceLogo'} />}
        <div className={styles.practiceInfoWrapper}>
          <div className={styles.welcomeLabel}>Welcome from</div>
          <div className={styles.practiceName}>{clinicianName}</div>
        </div>
      </div>
      <div className={styles.expandContent}>
        {welcomeMessage ? (
          <div dangerouslySetInnerHTML={{ __html: prefillClientName(variableList) }} />
        ) : (
          <DefaultWelcomeContent clientName={clientName} clinicianName={clinicianName} />
        )}
      </div>
    </div>
  );
};

export default WelcomeContent;
