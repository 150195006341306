import ClientHeader from 'components/ClientHeader/ClientHeader';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import styles from './TacklitLayout.module.scss';

interface TacklitLayoutProps {
  children?: React.ReactNode;
}

const TacklitLayout = ({ children }: TacklitLayoutProps) => {
  return (
    <PatientContentLayout className={styles.container}>
      <div className={styles.contentWrapper}>
        <ClientHeader withMenu />
        {children}
      </div>
    </PatientContentLayout>
  );
};

export default TacklitLayout;
