import FlexBox from 'components/FlexBox/FlexBox';
import styles from '../../InvoiceViewWithTemplate.module.scss';

interface InvoiceFieldProps {
  label?: string;
  value?: string;
  isTableField?: boolean;
}

const InvoiceField = ({ label, value, isTableField }: InvoiceFieldProps) => {
  if (!value) {
    return null;
  }

  return (
    <FlexBox direction="row" spacing={4} alignItems="center">
      {label && <span className={styles.label}>{label}:</span>}
      <span className={isTableField ? styles.tableFieldValue : styles.value}>{value}</span>
    </FlexBox>
  );
};

export default InvoiceField;
