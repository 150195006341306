import { useState } from 'react';
import { notification } from 'antd';

import { postConsentFormVerifyOtp } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';

export const useVerifyOtp = (consentId: string, setIsConsentFormCompleted: (completed: boolean) => void) => {
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpVerifiedLoading, setIsOtpVerifiedLoading] = useState(false);
  const [otpToken, setOtpToken] = useState('');

  const verifyOtp = async (otp: number) => {
    setIsOtpVerified(false);
    setIsOtpVerifiedLoading(true);

    try {
      const request = await postConsentFormVerifyOtp(consentId, otp);

      if (request.statusCode === 200) {
        const response = await request.json();

        setOtpToken(response.token);
        setIsOtpVerified(true);
        setIsOtpVerifiedLoading(false);
      } else if (request.statusCode === 204) {
        setIsConsentFormCompleted(true);
        notification.info({ message: 'You have already signed this consent form.' });
      }

      return true;
    } catch (ex) {
      if (isErrorBentStatusError(ex) && ex.statusCode === 400) {
        notification.error({ message: 'OTP must consist of 6 numbers' });
      } else if (isErrorBentStatusError(ex) && ex.statusCode === 403) {
        notification.error({ message: 'Invalid OTP' });
      } else {
        console.error(ex);
        notification.error({
          message: 'Something went wrong while trying to verify your otp. Please try again later.'
        });
      }

      setIsOtpVerifiedLoading(false);
      return false;
    }
  };

  return { otpToken, isOtpVerified, isOtpVerifiedLoading, verifyOtp };
};
