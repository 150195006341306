import { useState } from 'react';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

import FilterDropdown, { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './FitFilter.module.scss';
import {
  FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
  FIT_DAYS_LIST_LABELS,
  FIT_STYLE_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FitPsychologistGenderList,
  FitDaysList,
  FitLanguageList,
  FitStyleList,
  FitTimesList,
  SOMEONE_HEALTH_FIT_LANGUAGE_LIST_LABELS
} from 'SomeoneHealth/interfaces/fitFilter';
import TimeZoneDropdown from 'components/TimeZoneDropdown/TimeZoneDropdown';
import { PsychologistFitFilter } from 'SomeoneHealth/pages/PsychologistListing/PsychologistListing';
import FitFilterMobilePopup, { FitFilterInterface } from './components/FitFilterMobilePopup/FitFilterMobilePopup';
import { SOMEONE_HEALTH_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import classNames from 'classnames';
import FilterDropdownMultiSelect from 'components/FilterDropdown/components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';
import {
  generateDaysList,
  generateGenderList,
  generateLanguageList,
  generateStylesList,
  generateTimesList
} from '../utils/generateFitFilters';

interface FitFilterProps {
  selectedFitValue?: PsychologistFitFilter;
  onChangeFitValue: (value: PsychologistFitFilter) => void;
  selectedTimezone: string;
  handleChangeTimeZone: (value: string) => void;
  handleFitResetValue: () => void;
  isLoading?: boolean;
}

const FitFilter = ({
  selectedFitValue,
  onChangeFitValue,
  selectedTimezone,
  handleChangeTimeZone,
  handleFitResetValue,
  isLoading
}: FitFilterProps) => {
  const [isFilterModalShow, setIsFilterModalShow] = useState(false);
  const [filterIsSelected, setFilterIsSelected] = useState<string>('');

  const generateList = (id?: any, listLabel?: any, listEnum?: any) => {
    return id ? { id, label: listLabel[id as keyof typeof listEnum] } : undefined;
  };

  const massageFitGender = generateList(
    selectedFitValue?.gender,
    FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
    FitPsychologistGenderList
  );
  const massageFitDays = selectedFitValue?.days?.map((item) => ({ id: item, label: FIT_DAYS_LIST_LABELS[item] }));
  const massageFitTimes = generateList(selectedFitValue?.time, FIT_TIMES_LIST_LABELS, FitTimesList);
  const massageFitLanguage = generateList(
    selectedFitValue?.language,
    SOMEONE_HEALTH_FIT_LANGUAGE_LIST_LABELS,
    FitLanguageList
  );
  const massageFitStyles = generateList(selectedFitValue?.style, FIT_STYLE_LIST_LABELS, FitStyleList);
  const [scrollCount, setScrollCount] = useState<number>(0);

  const handleChangeGender = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      gender: value?.id as FitPsychologistGenderList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDays = (value: listInterface) => {
    const isRemoveAction = selectedFitValue?.days?.some((item) => item === value.id);
    const days = isRemoveAction
      ? selectedFitValue?.days?.filter((item) => item !== value.id)
      : [...(selectedFitValue?.days || []), value.id as FitDaysList];

    onChangeFitValue({ ...selectedFitValue, days });
  };

  const handleChangeTimes = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      time: value?.id as FitTimesList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeLanguage = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      language: value?.id as FitLanguageList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeStyles = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      style: value?.id as FitStyleList
    };
    onChangeFitValue(massageFitCollection);
  };

  const onFilterClickHandle = (filterName: string) => {
    setFilterIsSelected(filterName);
    setIsFilterModalShow(true);
  };

  const selectedFilters: FitFilterInterface = {
    gender: massageFitGender,
    days: massageFitDays,
    time: massageFitTimes,
    language: massageFitLanguage,
    style: massageFitStyles
  };

  return (
    <div className={styles.container}>
      <SomeoneHealthContentLayout>
        <div className={styles.content}>
          <div className={styles.filterWrapper} onScroll={() => setScrollCount(scrollCount + 1)}>
            <div className={styles.labelWrapper}>
              <div className={styles.title}>REFINE YOUR FIT</div>
              <div className={styles.desc}>Filter psychologists by...</div>
            </div>
            <div className={styles.fitFilterWrapper}>
              <FilterDropdown
                label={'Gender'}
                noFloatLabel
                badgeStyles
                listData={generateGenderList()}
                onChangeValue={handleChangeGender}
                selectedValue={massageFitGender}
                className={styles.gender}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('gender')}
                disabled={isLoading}
              />
              <FilterDropdownMultiSelect
                id="dayValues"
                label="Days"
                listData={generateDaysList()}
                onChangeValue={handleChangeDays}
                selectedMultipleValue={massageFitDays || []}
                className={styles.days}
                onClick={() => onFilterClickHandle('days')}
                disabled={isLoading}
                menuWrapperClass={styles.daysMenuWrapperClass}
                moreLabel="days"
                hideTitle
              />
              <FilterDropdown
                label="Timing"
                noFloatLabel
                badgeStyles
                listData={generateTimesList()}
                onChangeValue={handleChangeTimes}
                selectedValue={massageFitTimes}
                className={styles.times}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('time')}
                disabled={isLoading}
              />
              <FilterDropdown
                label={'Language'}
                noFloatLabel
                badgeStyles
                listData={generateLanguageList()}
                onChangeValue={handleChangeLanguage}
                selectedValue={massageFitLanguage}
                className={styles.language}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('language')}
                disabled={isLoading}
              />
              <FilterDropdown
                label={'Style'}
                noFloatLabel
                badgeStyles
                listData={generateStylesList()}
                onChangeValue={handleChangeStyles}
                selectedValue={massageFitStyles}
                className={styles.style}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('style')}
                disabled={isLoading}
              />
              <div
                className={classNames(styles.fitReset, isLoading && styles.disabled)}
                onClick={() => {
                  !isLoading && handleFitResetValue();
                }}
              >
                Clear All
              </div>
            </div>
          </div>
          <div className={styles.timezone}>
            <TimeZoneDropdown
              listData={SOMEONE_HEALTH_TIME_ZONE_LIST}
              selectedValue={
                SOMEONE_HEALTH_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimezone) ||
                SOMEONE_HEALTH_TIME_ZONE_LIST[0]
              }
              onChangeValue={(value) => handleChangeTimeZone(value.id)}
              disabled={isLoading}
            />
          </div>
        </div>
        <FitFilterMobilePopup
          visible={isFilterModalShow}
          filterIsSelected={filterIsSelected}
          selectedFiltersProp={selectedFilters}
          genderOptions={generateGenderList()}
          dayOptions={generateDaysList()}
          timeOptions={generateTimesList()}
          languageOptions={generateLanguageList()}
          styleOptions={generateStylesList()}
          onView={onChangeFitValue}
          onClearAll={handleFitResetValue}
          onCancel={() => setIsFilterModalShow(false)}
        />
      </SomeoneHealthContentLayout>
    </div>
  );
};

export default FitFilter;
