import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import HelmHeader from '../../components/HelmHeader/HelmHeader/HelmHeader';
import ContentLayout from '../../../components/ContentLayout/ContentLayout';
import HelmHelmetWrapper from '../../components/HelmHelmetWrapper/HelmHelmetWrapper';

const ConsentListWrapper = () => (
  <HelmHelmetWrapper title={'HELM - Consent List'}>
    <div className={styles.container}>
      <ContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <HelmHeader whiteFont whiteLogo noWrapper withPadding withMenu />
        </div>
      </ContentLayout>
      <ContentLayout className={styles.content}>
        <ConsentList />
      </ContentLayout>
    </div>
  </HelmHelmetWrapper>
);

export default ConsentListWrapper;
