import styles from './AppointmentPatientHeader.module.scss';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import { useLogout } from 'utils/hooks/logout';

interface AppointmentPatientHeaderProps {
  profileImg?: string;
  initialsName?: string;
}

const AppointmentPatientHeader = ({ profileImg, initialsName }: AppointmentPatientHeaderProps) => {
  const { logout } = useLogout();

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <div className={styles.profile}>
          <ClientProfileAvatar avatarUrl={profileImg} initialsName={initialsName} />
        </div>
      </div>
      <div className={styles.logOutBtn} onClick={logout}>
        Log out <i className={`material-icons ${styles.icon}`}>exit_to_app</i>
      </div>
    </div>
  );
};

export default AppointmentPatientHeader;
