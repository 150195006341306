import styles from './Icon.module.scss';

interface IconProps {
  name: string;
  className?: string;
}

const Icon = ({ name, className }: IconProps) => {
  return <i className={`material-icons ${styles.icon} ${className}`}>{name}</i>;
};

export default Icon;
