import TaskButton from 'SomeoneHealth/components/TaskButton/TaskButton';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import PaymentFailureTaskBox from '../PaymentFailureTaskBox/PaymentFailureTaskBox';
import styles from './ActionSummaryBox.module.scss';

interface ActionSummaryBoxProps {
  failedPayments?: { amount: number; _id: string }[];
  totalLength: number;
}

const ActionSummaryBox = ({ failedPayments, totalLength }: ActionSummaryBoxProps) => {
  const { PENDING_ACTIONS } = useSomeoneHealthRoutesGenerator();

  return (
    <div className={styles.actionWrapper}>
      <div className={styles.title}>Your actions</div>
      <div className={styles.menuItemWrapper}>
        {failedPayments &&
          failedPayments.map(({ amount, _id }) => (
            <PaymentFailureTaskBox amount={amount} key={_id} paymentRequestId={_id} />
          ))}
        <TaskButton
          item={{
            name: (
              <div className={styles.taskButtonText}>
                You have <span className={styles.highlight}>{totalLength} outstanding requests</span>
              </div>
            ),
            url: PENDING_ACTIONS,
            isExternal: false
          }}
        />
      </div>
    </div>
  );
};

export default ActionSummaryBox;
