/* eslint-disable react/no-multi-comp */
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { flatMapDeep } from 'lodash';
import Select, { Props } from 'react-select';

import styles from './Select.module.scss';
import { Skeleton } from 'antd';

export interface SelectProps extends Omit<Props, 'value'> {
  labelClass?: string;
  label?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  styles?: Props['styles'];
  value?: string;
  smallCaretDown?: boolean;
  loading?: boolean;
  onChange?: Props['onChange'];
  onInputChange?: Props['onInputChange'];
}

const innerStyles: Props['styles'] = {
  container: (base) => ({
    ...base,
    width: 'calc(100% - 12px)'
  }),
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #d3d8dd',
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: '48px'
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  menu: (base) => ({ ...base, marginTop: 0, width: '100% !important', zIndex: 3 }),
  placeholder: (base) => ({ ...base, fontSize: 13, lineHeight: '18px' }),
  singleValue: (base) => ({ ...base, color: '#343434' }),
  valueContainer: (base) => ({ ...base, paddingLeft: 15 }),
  multiValue: (base) => ({ ...base, backgroundColor: '#2a4670', borderRadius: 25, color: 'white', padding: 4 }),
  multiValueLabel: (base) => ({ ...base, color: 'white' })
};

const StyledSelect = ({
  className,
  labelClass,
  label,
  hasError,
  noSpacing,
  options,
  styles: propStyles,
  value,
  smallCaretDown,
  loading,
  ...props
}: SelectProps) => {
  const selectClasses = classnames(styles.select, hasError && styles.error, noSpacing && styles.noSpacing);
  const [isFocusing, setIsFocusing] = useState(false);

  const flattenedOptions = useMemo(() => {
    if (options) {
      return flatMapDeep([...options], (option: any) => option.options ?? [option]);
    } else {
      return [];
    }
  }, [options]);

  const DropdownIndicator = () => {
    return <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>;
  };

  return (
    <div className={className}>
      {label && (
        <div className={classnames(styles.label, labelClass, isFocusing && styles.focusLabelClass)}>{label}</div>
      )}
      {loading ? (
        <div className={styles.loadingWrapper}>
          <Skeleton.Input style={{ width: '300px', height: '30px' }} active />
        </div>
      ) : (
        <Select
          className={selectClasses}
          styles={{ ...innerStyles, ...propStyles }}
          value={flattenedOptions.find((option) => option.value === value) || value}
          options={options}
          components={smallCaretDown ? { DropdownIndicator } : {}}
          {...props}
          onFocus={() => setIsFocusing(true)}
          onBlur={() => {
            setIsFocusing(false);
          }}
        />
      )}
    </div>
  );
};

export default StyledSelect;
