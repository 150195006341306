import { useAuth0 } from '@auth0/auth0-react';

import styles from './CaWSurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import CaWHeader from 'CaW/components/CaWHeader/CaWHeader';

const CaWSurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <div className={styles.container}>
      <CaWContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <CaWHeader classNames={styles.shHeader} withPadding withMenu homeLink blueMenu />
        </div>
      </CaWContentLayout>
      <CaWContentLayout className={styles.contentWrapper}>
        <SurveyQuestion />
      </CaWContentLayout>
    </div>
  );
};

export default CaWSurveyQuestionWrapper;
