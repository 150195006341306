import classnames from 'classnames';
import ContentLayout from 'components/ContentLayout/ContentLayout';

import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import LogoSkeleton from 'components/LogoSkeleton/LogoSkeleton';
import styles from './Header.module.scss';

interface HeaderProps {
  whiteLogo?: boolean;
  logoClassName?: string;
}

const Header = ({ whiteLogo, logoClassName }: HeaderProps) => {
  const { darkLogo, lightLogo, isLogoLoading } = useCustomLogo();

  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.header}>
          {isLogoLoading ? (
            <LogoSkeleton />
          ) : (
            <img alt="logo" className={classnames(styles.logo, logoClassName)} src={whiteLogo ? lightLogo : darkLogo} />
          )}
        </div>
      </ContentLayout>
    </div>
  );
};

export default Header;
