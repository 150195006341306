import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ProgramId, ProgramInterface } from 'helm/interfaces/program/program';
import { getProgramDetails } from '../../../utils/http/ClinicianProfileService/program/program';
import { useGetAccessToken } from 'utils/hooks/token';
import chapter1 from 'helm/assets/images/p2cGambling/chapter1.svg';
import chapter2 from 'helm/assets/images/p2cGambling/chapter2.svg';
import chapter3 from 'helm/assets/images/p2cGambling/chapter3.svg';
import chapter4 from 'helm/assets/images/p2cGambling/chapter4.svg';
import chapter5 from 'helm/assets/images/p2cGambling/chapter5.svg';
import chapter6 from 'helm/assets/images/p2cGambling/chapter6.svg';
import { getSmpCdfGoals } from 'helm/utils/http/CheckInService/smp';

const getChapterImage = (chapterId: number) => {
  switch (chapterId) {
    case 1:
      return chapter1;
    case 2:
      return chapter2;
    case 3:
      return chapter3;
    case 4:
      return chapter4;
    case 5:
      return chapter5;
    case 6:
      return chapter6;
    default:
      return chapter1;
  }
};

export const useFetchP2CGamblingDetails = (clientRecordId: string, clientProfileId: string) => {
  const [p2cGamblingDetails, setP2CGamblingDetails] = useState<ProgramInterface>();
  const [isP2CGamblingDetailsLoading, setP2CGamblingDetailsLoading] = useState(true);
  const { token } = useGetAccessToken();

  const massageChapters = (chapterList: ProgramInterface['chapters']) =>
    chapterList.map((chapterObj, index) => ({
      ...chapterObj,
      chapterId: index + 1,
      image: getChapterImage(index + 1)
    }));

  const fetchP2CGamblingDetails = useCallback(async () => {
    try {
      setP2CGamblingDetailsLoading(true);
      const [program, goals] = await Promise.all([
        (await getProgramDetails(token, clientRecordId, clientProfileId, ProgramId.P2CGambling)).json(),
        (await getSmpCdfGoals(token)).json()
      ]);
      const massageData = {
        ...program,
        chapters: massageChapters(program.chapters),
        goals
      };

      setP2CGamblingDetails(massageData);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get P2CGambling Details' });
    }
    setP2CGamblingDetailsLoading(false);
  }, [token, clientRecordId, clientProfileId]);

  useEffect(() => {
    if (token) {
      fetchP2CGamblingDetails();
    }
  }, [token, fetchP2CGamblingDetails]);

  return { p2cGamblingDetails, isP2CGamblingDetailsLoading, fetchP2CGamblingDetails };
};
