import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getSurveyList = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/me/surveys`
  ) as Promise<BentResponse>;

export const getSurveyQuestions = (token: string, surveyAssignmentId: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/me/surveys/${surveyAssignmentId}`
  ) as Promise<BentResponse>;
