import { useMemo, useState } from 'react';
import styles from './PractitionerListing.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import PractitionerAvatar from 'helm/components/PractitionerAvatar/PractitionerAvatar';
import PractitionerBonaFides from '../../components/PractitionerBonaFides/PractitionerBonaFides';
import PractitionerDetails from './components/PractitionerDetails/PractitionerDetails';
import PractitionerSpecialisation from './components/PractitionerSpecialisation/PractitionerSpecialisation';
import HelmFilterHeader, { sessionList } from 'helm/components/HelmHeader/HelmFilterHeader/HelmFilterHeader';
import PractitionerAvailabilityCalendar from './components/PractitionerAvailabilityCalendar/PractitionerAvailabilityCalendar';
import { useFetchPractitionerList } from './hooks/getPractitionerList';
import HelmFooter from 'helm/components/HelmFooter/HelmFooter';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { InitialSessionType, SpecialistTheme } from '../../interfaces/Filter/searchFilter';
import { Skeleton } from 'antd';
import NoAvailableMessageBox from 'helm/components/NoAvailableMessageBox/NoAvailableMessageBox';
import moment from 'moment';
import { nextAvailability, PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { scrollToView } from '../../../utils/scrollToView';
import { useAuth0 } from '@auth0/auth0-react';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { getHelmPractitionerDetailsPath } from 'helm/utils/Path/getHelmPractitionerDetailsPath';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

export interface PractitionerQueryParam {
  theme?: SpecialistTheme;
  selectedSessionType?: InitialSessionType;
  sid?: string;
  timeSlotSection?: boolean;
  showAllAppt?: string;
}

export const timeZoneLocalStorage = 'CLIENT-TIMEZONE';

const PractitionerListing = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const clientTimeZone = localStorage.getItem(timeZoneLocalStorage);
  const {
    theme = SpecialistTheme.All,
    selectedSessionType,
    sid,
    showAllAppt
  }: PractitionerQueryParam = queryString.parse(queryParam);

  const { PRACTITIONER, COUNSELLORS_CALENDAR, WAITLIST } = useHelmRoutesGenerator();

  const queryTheme = Object.values(SpecialistTheme).includes(theme);
  const [selectedTheme, setSelectedTheme] = useState<SpecialistTheme>(queryTheme ? theme : SpecialistTheme.All);
  const [sessionType, setSessionType] = useState<InitialSessionType>(
    selectedSessionType || InitialSessionType.InitialConsult
  );

  const [timeZone, setTimeZone] = useState(clientTimeZone || AU_TIME_ZONE_LIST[0].id);

  const newQueryParam: PractitionerQueryParam = {
    theme: selectedTheme,
    selectedSessionType: selectedSessionType,
    sid: sid
  };

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const { practitionerList, advisorList, isPractitionerListLoading } = useFetchPractitionerList(
    newQueryParam,
    timeZone
  );

  const generateQueryParam = queryString.stringify(newQueryParam, { sort: false });
  const generateQueryParamWithSection = queryString.stringify(
    { ...newQueryParam, timeSlotSection: true },
    { sort: false }
  );

  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  const practitionerListByTheme = useMemo(() => {
    const selectedAppointmentTypeName =
      (sessionType &&
        sessionList.find((obj) => encodeURIComponent(obj.id) === encodeURIComponent(sessionType))?.label) ||
      sessionList[0].label;

    const getMassageAvailabilityData = (nextAvailabilities: nextAvailability[]) =>
      nextAvailabilities?.find(
        (obj) =>
          encodeURIComponent(obj.appointmentTypeName?.toLowerCase()) ===
            encodeURIComponent(selectedAppointmentTypeName.toLowerCase()) &&
          (isNewClient
            ? obj.appointmentTypeBookingRules.new.available
            : obj.appointmentTypeBookingRules.existing.available)
      );

    const sortByNearestAvailabilities = (a: PractitionerDetailsInterface, b: PractitionerDetailsInterface) => {
      const aFirstTimeSlot = a.nextAvailabilities
        ? getMassageAvailabilityData(a.nextAvailabilities)?.availabilities[0]
        : null;
      const bFirstTimeSlot = b.nextAvailabilities
        ? getMassageAvailabilityData(b.nextAvailabilities)?.availabilities[0]
        : null;

      const aEarliestTime = aFirstTimeSlot
        ? moment(`${aFirstTimeSlot.date} ${aFirstTimeSlot.timeSlots[0].startTime}`, 'YYYY-MM-DD hh:mmA')
        : null;
      const bEarliestTime = bFirstTimeSlot
        ? moment(`${bFirstTimeSlot.date} ${bFirstTimeSlot.timeSlots[0].startTime}`, 'YYYY-MM-DD hh:mmA')
        : null;

      if (aEarliestTime === bEarliestTime) {
        return 0;
      }

      if (aEarliestTime === null) {
        return 1;
      }
      if (bEarliestTime === null) {
        return -1;
      }

      return aEarliestTime.isAfter(bEarliestTime) ? 1 : -1;
    };

    return newQueryParam.theme !== SpecialistTheme.All
      ? practitionerList
          .filter((obj) => obj.specialisations.some((speObj) => speObj.toLowerCase() === newQueryParam.theme))
          .sort(sortByNearestAvailabilities)
      : practitionerList.sort(sortByNearestAvailabilities);
  }, [practitionerList, newQueryParam.theme, sessionType, isNewClient]);

  const handleChangeTheme = (val: SpecialistTheme) => {
    setSelectedTheme(val);
    const massageQueryParam = {
      ...newQueryParam,
      theme: val
    };
    const generateQueryParam = queryString.stringify(massageQueryParam, { sort: false });
    navigate(`${PRACTITIONER.LISTING}?${generateQueryParam}`);
  };

  const handleChangeSessionType = (val: InitialSessionType) => {
    setSessionType(val);
    const massageQueryParam = {
      ...newQueryParam,
      selectedSessionType: val
    };
    const generateQueryParam = queryString.stringify(massageQueryParam, { sort: false });
    navigate(`${PRACTITIONER.LISTING}?${generateQueryParam}`);
  };

  const handleChangeTimeZone = (val: string) => {
    setTimeZone(val);
    localStorage.setItem(timeZoneLocalStorage, val);
  };

  return (
    <HelmHelmetWrapper title={'HELM - Behavioural Health Specialists'}>
      <HelmFilterHeader
        selectedTheme={selectedTheme}
        selectedSessionType={sessionType}
        selectedTimezone={timeZone}
        onChangeTheme={handleChangeTheme}
        onChangeSession={handleChangeSessionType}
        onChangeTimeZone={handleChangeTimeZone}
      />
      {isPractitionerListLoading || isClientBookingRuleLoading ? (
        <div className={styles.container}>
          {[...Array(4)].map((obj, i) => (
            <div key={i} className={styles.contentWrapper}>
              <HelmContentLayout>
                <div className={styles.content}>
                  <div className={styles.loadingWrapper}>
                    <div className={styles.profileWrapper}>
                      <Skeleton.Avatar active size={70} className={styles.headerLoading} />
                      <div className={styles.bonaFidesLoadingWrapper}>
                        <Skeleton.Button active className={styles.bonaFidesLoading} />
                        <Skeleton.Button active className={styles.bonaFidesLoading} />
                      </div>
                    </div>
                    <div className={styles.detailsContainer}>
                      <Skeleton active />
                    </div>
                  </div>
                </div>
              </HelmContentLayout>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.container}>
          {practitionerListByTheme.map((obj, i) => {
            const detailsPagePath = getHelmPractitionerDetailsPath({
              clinicianId: obj._id,
              counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
              practitionerDetailsPath: PRACTITIONER.DETAILS
            });
            const detailPath = `${detailsPagePath}${generateQueryParam ? `?${generateQueryParam}` : ''}`;
            const detailPathWithTimeSlot = `${detailsPagePath}${
              generateQueryParamWithSection ? `?${generateQueryParamWithSection}` : ''
            }`;
            return (
              <div className={styles.contentWrapper} key={i}>
                <HelmContentLayout>
                  <div className={styles.content}>
                    <PractitionerDetails
                      practitionerDetails={obj}
                      detailPath={detailPath}
                      className={styles.mobileDetails}
                    />
                    <div className={styles.profileWrapper}>
                      <PractitionerAvatar
                        className={styles.profile}
                        classNameImage={styles.profileImg}
                        shapeType={`${(i + 1) % 4}`}
                        profileImg={obj.avatar}
                        detailPath={detailPath}
                      />
                      <PractitionerBonaFides limitDisplayQualifications bonaFidesDetails={obj.helmControl.bonaFides} />
                    </div>
                    <div className={styles.detailsContainer}>
                      <PractitionerDetails
                        practitionerDetails={obj}
                        detailPath={detailPath}
                        className={styles.details}
                      />
                      {obj.specialisations.length > 0 && (
                        <PractitionerSpecialisation specialisationsDetails={obj.specialisations} />
                      )}
                      <PractitionerAvailabilityCalendar
                        detailPath={detailPathWithTimeSlot}
                        nextAvailabilityDetails={obj.nextAvailabilities?.filter(
                          (item) =>
                            (isNewClient
                              ? item.appointmentTypeBookingRules?.new.available
                              : item.appointmentTypeBookingRules?.existing.available) &&
                            (showAllAppt || !item.shouldHideFromListing)
                        )}
                        isDarkYellowPill={i % 2 === 1}
                        selectedSessionType={sessionType}
                      />
                    </div>
                  </div>
                </HelmContentLayout>
              </div>
            );
          })}
          {advisorList.map((obj, i) => {
            const detailsPagePath = getHelmPractitionerDetailsPath({
              clinicianId: obj._id,
              counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
              practitionerDetailsPath: PRACTITIONER.DETAILS
            });
            const newQueryParam: PractitionerQueryParam = {
              theme: selectedTheme,
              sid: sid
            };
            const generateAdvisorQueryParamWithSection = queryString.stringify(
              { ...newQueryParam, timeSlotSection: true },
              { sort: false }
            );
            const detailPath = `${detailsPagePath}${generateQueryParam ? `?${generateQueryParam}` : ''}`;
            const detailPathWithTimeSlot = `${detailsPagePath}${
              generateAdvisorQueryParamWithSection ? `?${generateAdvisorQueryParamWithSection}` : ''
            }`;
            return (
              <div className={styles.contentWrapper} key={i}>
                <HelmContentLayout>
                  <div className={styles.content}>
                    <PractitionerDetails
                      practitionerDetails={obj}
                      detailPath={detailPath}
                      className={styles.mobileDetails}
                    />
                    <div className={styles.profileWrapper}>
                      <PractitionerAvatar
                        className={styles.profile}
                        classNameImage={styles.profileImg}
                        shapeType={`${(i + 1) % 4}`}
                        profileImg={obj.avatar}
                        detailPath={detailPath}
                      />
                      <PractitionerBonaFides limitDisplayQualifications bonaFidesDetails={obj.helmControl.bonaFides} />
                    </div>
                    <div className={styles.detailsContainer}>
                      <PractitionerDetails
                        practitionerDetails={obj}
                        detailPath={detailPath}
                        className={styles.details}
                      />
                      {obj.specialisations.length > 0 && (
                        <PractitionerSpecialisation specialisationsDetails={obj.specialisations} />
                      )}
                      <PractitionerAvailabilityCalendar
                        detailPath={detailPathWithTimeSlot}
                        nextAvailabilityDetails={obj.nextAvailabilities}
                        isDarkYellowPill={i % 2 === 1}
                        selectedSessionType={sessionType}
                        noFilter
                      />
                    </div>
                  </div>
                </HelmContentLayout>
              </div>
            );
          })}
          <div className={styles.joinWaitListMessage}>
            <NoAvailableMessageBox
              title={'Can’t find what you are looking for?'}
              desc={'Join our waitlist with details on your preferences and we will be in touch.'}
              buttonLabel={'Join Waitlist Now'}
              onClick={() => {
                scrollToView('HelmHeader');
                navigate(WAITLIST);
              }}
            />
          </div>
        </div>
      )}
      <HelmFooter />
    </HelmHelmetWrapper>
  );
};

export default PractitionerListing;
