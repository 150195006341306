import classnames from 'classnames';
import { get } from 'lodash';
import styles from './ButtonEase.module.scss';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';
import Finished from './Finished/Finished';
import { ButtonHTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

export type ButtonStatusType = '' | 'active' | 'finished';
export type ButtonAltSize = 'small' | 'medium' | 'large';
export type ButtonAltVariant = 'contained' | 'outlined' | 'text' | 'contained-fab';

export enum IconVariant {
  Filled = 'material-icons',
  Outlined = 'material-icons-outlined'
}

export interface ButtonAltProps {
  status?: ButtonStatusType;
  contentClassName?: string;
  activeClassName?: string;
  finishedClassName?: string;
  iconClassName?: string;
  size?: ButtonAltSize;
  variant?: ButtonAltVariant;
  fab?: boolean;
  icon?: string;
  iconVariant?: IconVariant;
  iconPostFix?: boolean;
  id?: string;
  noTransition?: boolean;
  fullWidth?: boolean;
  to?: string;
  loadingWord?: string;
  completedWord?: string;
  error?: boolean;
}

const ButtonEase = ({
  status = '',
  contentClassName,
  activeClassName,
  finishedClassName,
  iconClassName,
  size = 'large',
  variant = 'contained',
  fab,
  icon,
  iconVariant,
  iconPostFix,
  id,
  noTransition,
  children,
  fullWidth,
  to,
  loadingWord,
  completedWord,
  error,
  disabled,
  ...props
}: ButtonAltProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const navigate = useNavigate();
  const statusClass = get(
    {
      active: classnames(styles.active, activeClassName),
      finished: classnames(styles.finished, finishedClassName)
    },
    status,
    ''
  );
  const { className } = props;

  const handleNavigate = (value: string) => {
    navigate(value);
  };

  const round = !children && fab;

  return (
    <div className={classnames(error ? styles.errorTheme : styles.userTheme)}>
      <div className={classnames(fab && styles.fab, variant && styles[variant], round && styles.round)}>
        <button
          {...props}
          onClick={status === '' ? (to ? () => handleNavigate(to) : props.onClick) : undefined}
          className={classnames(
            styles.container,
            className,
            statusClass,
            styles[size],
            fullWidth && styles.fullWidth,
            noTransition && styles.noTransition
          )}
          disabled={status !== '' || disabled}
        >
          <span className={classnames(styles.submit, contentClassName)}>
            {!iconPostFix && icon && (
              <i
                className={classnames(
                  iconVariant ? iconVariant : IconVariant.Outlined,
                  styles.icon,
                  children && styles.iconLeft,
                  iconClassName
                )}
              >
                {icon}
              </i>
            )}
            {children && <div>{children}</div>}
            {iconPostFix && icon && (
              <i
                className={classnames(
                  iconVariant ? iconVariant : IconVariant.Outlined,
                  styles.icon,
                  children && styles.iconRight,
                  iconClassName
                )}
              >
                {icon}
              </i>
            )}
          </span>
          <span className={styles.loading}>
            <LoadingSpinner className={styles.loadingSpinner} />
            <div>{loadingWord ? loadingWord : 'Loading'}</div>
          </span>
          <span className={styles.check}>
            <Finished />
            <div>{completedWord ? completedWord : 'Loading'}</div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default ButtonEase;
