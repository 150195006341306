import classnames from 'classnames';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import FormikInput from 'components/Input/FormikInput';
import { Field, FieldProps, useFormikContext } from 'formik';
import SubmitButton from 'pages/PatientSignUp/components/SubmitButton/SubmitButton';
import { MouseEvent } from 'react';
import PhoneInput from 'react-phone-input-2';

import styles from './NewCustomerForm.module.scss';
import { config } from 'config/config';

const PREFERRED_COUNTRY = config.countryCode;

interface NewCustomerFormProps {
  isBookAppointmentButtonLoading: boolean;
  paymentRequired?: boolean;
}

const NewCustomerForm = ({ isBookAppointmentButtonLoading, paymentRequired }: NewCustomerFormProps) => {
  const { setTouched, validateForm, submitForm } = useFormikContext<Record<string, any>>();
  const handleSubmit = (e: MouseEvent) => {
    setTouched({ firstName: true, lastName: true, email: true, referralSource: true });
    validateForm();
    submitForm();
  };

  return (
    <>
      <div className={styles.title}>Add your contact details</div>
      <FormikInput label="First Name" name="firstName" className={styles.input} required />
      <FormikInput label="Last Name" name="lastName" className={styles.input} required />
      <FormikInput label="Email address" name="email" className={styles.email} required />
      <div className={styles.phoneNumberContainer}>
        <div className={styles.titlePhone}>Mobile Number</div>
        <Field name="mobileNumber">
          {({ field, form, meta }: FieldProps) => (
            <div className={styles.phoneNumber}>
              <PhoneInput
                inputClass={styles.phoneNumberInput}
                buttonClass={styles.countrySelect}
                dropdownClass={styles.countryDropdown}
                inputProps={{ onBlur: field.onBlur, name: field.name }}
                onChange={(value, formattedValue) => {
                  form.setFieldValue('mobileNumber', value);
                  form.setFieldValue('formattedMobileNumber', formattedValue);
                  return;
                }}
                {...(PREFERRED_COUNTRY && {
                  country: PREFERRED_COUNTRY,
                  preferredCountries: [PREFERRED_COUNTRY]
                })}
              />
              <ErrorMessage error={meta.error} visible={meta.touched} />
            </div>
          )}
        </Field>
      </div>
      <FormikInput label="Have you been referred?" name="referralSource" className={styles.email} required />
      <div className={styles.policy}>
        By making this booking you agree to our <a href="/">privacy policy</a> and adhere to the{' '}
        <a href="/">cancellation policy</a>
      </div>
      <SubmitButton
        className={classnames(styles.button, isBookAppointmentButtonLoading && styles.loading)}
        variant="primary"
        type="submit"
        onClick={handleSubmit}
        disabled={isBookAppointmentButtonLoading}
      >
        {paymentRequired ? 'Pay To Confirm' : 'Book Appointment'}
      </SubmitButton>
    </>
  );
};

export default NewCustomerForm;
