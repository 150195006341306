import {
  CreateReferralProspectPayload,
  GuardianDetails,
  OtherContactDetails,
  PartnerDetails,
  ReferralDetails,
  ReferrerDetails
} from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { DEFAULT_FORM_VALUES, RELATIONSHIP_OPTIONS } from './constants';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';

export const buildPayload = (formValues: typeof DEFAULT_FORM_VALUES, captcha: string, clinicianAuth0Id?: string) => {
  const payload = () => {
    const note = buildNote();
    const payload: CreateReferralProspectPayload = massagePayload(note);
    return payload;
  };

  const massagePayload = (note: any): CreateReferralProspectPayload => {
    const {
      firstName,
      lastName,
      emailAddress,
      contactNumber,
      dateOfBirth,
      postcode,
      referralSource,
      referralFirstName,
      referralLastName,
      referralDoB,
      referralEmailAddress,
      referralContactNumber,
      referralDetail,
      otherContactRelationship,
      isForMySelf,
      referralRecordType,
      contactMethod,

      guardianFirstName,
      guardianLastName,
      guardianEmail,
      guardianContactNumber,
      guardianRelationshipToReferral,

      partnerFirstName,
      partnerLastName,
      partnerDoB,
      partnerEmail,
      partnerContactNumber,

      otherContactFirstName,
      otherContactLastName,

      isUseGuardianInformation,

      relationship,
      otherContactEmail,
      otherContactNumber,

      whoseDetails
    } = formValues;

    const constantData = {
      referralSourceTags: [referralSource],
      referralDetail,
      note,
      captchaChallenge: captcha,
      referralRecordType,
      contactMethod,
      isUseGuardianInformation,
      isForMySelf: isForMySelf,
      entrySource: 'form'
    };

    let referralData: ReferralDetails = {
        firstName: '',
        lastName: ''
      },
      referrerData: ReferrerDetails = {
        firstName: '',
        lastName: ''
      },
      otherContactData: OtherContactDetails = {
        firstName: '',
        lastName: ''
      },
      guardianData: GuardianDetails = {
        firstName: '',
        lastName: ''
      },
      partnerData: PartnerDetails = {
        firstName: '',
        lastName: ''
      };

    if (isForMySelf) {
      referralData = {
        firstName,
        lastName,
        email: emailAddress,
        mobileNumber: contactNumber,
        dateOfBirth: dateOfBirth,
        postcode
      };

      if (referralRecordType === 'couple') {
        partnerData = {
          firstName: partnerFirstName,
          lastName: partnerLastName,
          email: partnerEmail,
          mobileNumber: partnerContactNumber,
          dateOfBirth: partnerDoB,
          isForMySelf: false
        };
      }
    } else {
      if (referralRecordType === 'adult') {
        referralData = {
          firstName: referralFirstName,
          lastName: referralLastName,
          email: referralEmailAddress,
          mobileNumber: referralContactNumber,
          dateOfBirth: referralDoB,
          postcode: postcode
        };

        referrerData = {
          firstName,
          lastName,
          email: emailAddress,
          mobileNumber: contactNumber,
          relationship: relationship,
          isForMySelf: false
        };

        if (contactMethod === 'otherContact') {
          otherContactData = {
            firstName: otherContactFirstName,
            lastName: otherContactLastName,
            email: otherContactEmail,
            mobileNumber: otherContactNumber,
            relationship: otherContactRelationship
          };
        }
      }

      if (['child', 'youngPerson'].includes(referralRecordType)) {
        referralData = {
          firstName: referralFirstName,
          lastName: referralLastName,
          email: undefined,
          mobileNumber: undefined,
          dateOfBirth: referralDoB,
          postcode: postcode,
          isForMySelf: false
        };

        guardianData = {
          firstName: guardianFirstName,
          lastName: guardianLastName,
          email: guardianEmail,
          mobileNumber: guardianContactNumber,
          relationship: guardianRelationshipToReferral
        };

        if (isUseGuardianInformation === 'guardianInformation') {
          referrerData = guardianData;
        }

        if (isUseGuardianInformation === 'otherInformation') {
          referrerData = {
            firstName,
            lastName,
            email: emailAddress,
            mobileNumber: contactNumber,
            relationship: whoseDetails, // referrer's relationship to referral
            isForMySelf: false
          };
        }

        if (contactMethod === 'otherContact') {
          otherContactData = {
            firstName: otherContactFirstName,
            lastName: otherContactLastName,
            email: otherContactEmail,
            mobileNumber: otherContactNumber,
            relationship: otherContactRelationship // your relationship to referral
          };
        }
      }

      if (referralRecordType === 'couple') {
        referralData = {
          firstName: referralFirstName,
          lastName: referralLastName,
          email: referralEmailAddress,
          mobileNumber: referralContactNumber,
          dateOfBirth: referralDoB,
          postcode: postcode,
          isForMySelf: false
        };

        partnerData = {
          firstName: partnerFirstName,
          lastName: partnerLastName,
          email: partnerEmail,
          mobileNumber: partnerContactNumber,
          dateOfBirth: partnerDoB,
          isForMySelf: false
        };

        referrerData = {
          firstName: firstName,
          lastName,
          email: emailAddress,
          mobileNumber: contactNumber,
          relationship: whoseDetails,
          isForMySelf: false
        };

        if (contactMethod === 'otherContact') {
          otherContactData = {
            firstName: otherContactFirstName,
            lastName: otherContactLastName,
            email: otherContactEmail,
            mobileNumber: otherContactNumber,
            relationship: otherContactRelationship
          };
        }
      }
    }

    return {
      ...referralData,
      ...constantData,
      ...(IS_HELM_APP
        ? {
            customFields: {
              helmInterestedWithPowerToChange: formValues?.customFields?.helmInterestedWithPowerToChange
            }
          }
        : {}),
      clinicianAuth0Id,
      referrerData,
      otherContactData,
      guardianData,
      partnerData
    };
  };

  const buildNote = () => {
    const referralInformation = generateNoteBody(formValues);
    return referralInformation && referralInformation.length > 0
      ? {
          title: 'Referral information',
          body: referralInformation
        }
      : undefined;
  };

  return {
    payload
  };
};

export const generateNoteBody = (formValues: typeof DEFAULT_FORM_VALUES) => {
  let bodyStr = `<div>Record Type: ${formValues.referralRecordType}</div><br/>`;

  if (formValues.isForMySelf) {
    bodyStr += `<div>Referral First Name: ${formValues.referralFirstName}</div>
    <div>Referral Last Name: ${formValues.referralLastName}</div>
    <div>Referral Email: ${formValues.emailAddress}</div>
    <div>Referral Mobile Number: ${formValues.contactNumber}</div>
    <div>Referral Date of Birth: ${formValues.dateOfBirth}</div>
    <div>Referral Postcode: ${formValues.postcode}</div><br/>`;
  } else {
    if (formValues.referralRecordType === 'adult') {
      bodyStr += `<div>Referral First Name: ${formValues.referralFirstName}</div>
      <div>Referral Last Name: ${formValues.referralLastName}</div>
      <div>Referral Date of Birth: ${formValues.referralDoB}</div>
      <div>Referral Postcode: ${formValues.postcode}</div><br/>
      <div>Referrer First Name: ${formValues.firstName}</div>
      <div>Referrer Last Name: ${formValues.lastName}</div>
      <div>Referrer Email: ${formValues.emailAddress}</div>
      <div>Referrer Mobile Number: ${formValues.contactNumber}</div>

      <div>Relationship to Referred: ${
        RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.relationship)?.label
      }</div><br/>`;

      if (formValues.contactMethod === 'otherContact') {
        bodyStr += `<div>Other Contact:</div>
        <div>Preferred First Name: ${formValues.otherContactFirstName}</div>
        <div>Preferred Last Name: ${formValues.otherContactLastName}</div>
        <div>Preferred Email: ${formValues.otherContactEmail}</div>
        <div>Preferred Mobile Number: ${formValues.otherContactNumber}</div>

        <div>Preferred Contact's Relationship to Referred: ${
          RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.otherContactRelationship)?.label
        }</div><br/>`;
      }
    }

    if (formValues.referralRecordType === 'child') {
      bodyStr += `<div>Referral First Name: ${formValues.referralFirstName}</div>
      <div>Referral Last Name: ${formValues.referralLastName}</div>
      <div>Referral Date of Birth: ${formValues.referralDoB}</div>
      <div>Referral Postcode: ${formValues.postcode}</div><br/>

      <div>Parent/Guardian First Name: ${formValues.guardianFirstName}</div>
      <div>Parent/Guardian Last Name: ${formValues.guardianLastName}</div>
      <div>Parent/Guardian Email: ${formValues.guardianEmail}</div>
      <div>Parent/Guardian Mobile Number: ${formValues.guardianContactNumber}</div>

      <div>Relationship to Referred: ${
        RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.guardianRelationshipToReferral)?.label
      }</div><br/>`;

      if (formValues.isUseGuardianInformation === 'otherInformation') {
        bodyStr += `<div>Referrer First Name: ${formValues.firstName}</div>
        <div>Referrer Last Name: ${formValues.lastName}</div>
        <div>Referrer Email: ${formValues.emailAddress}</div>
        <div>Referrer Mobile Number: ${formValues.contactNumber}</div>
          <div>Relationship to Referred: ${
            RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.whoseDetails)?.label
          }</div><br/>`;
      }

      if (formValues.isUseGuardianInformation === 'guardianInformation') {
        bodyStr += '<div>NOTE: Use parent or guardian information</div><br/>';
      }

      if (formValues.contactMethod === 'otherContact') {
        bodyStr += `<div>Other Contact:</div>
        <div>Preferred First Name: ${formValues.otherContactFirstName}</div>
        <div>Preferred Last Name: ${formValues.otherContactLastName}</div>
        <div>Preferred Email: ${formValues.otherContactEmail}</div>
        <div>Preferred Mobile Number: ${formValues.otherContactNumber}</div>
        
        <div>Preferred Contact's Relationship to Referred: ${
          RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.otherContactRelationship)?.label
        }</div><br/>`;
      }
    }

    if (formValues.referralRecordType === 'couple') {
      bodyStr += `<div>Referral First Name: ${formValues.referralFirstName}</div>
      <div>Referral Last Name: ${formValues.referralLastName}</div>
      <div>Referral Email: ${formValues.referralEmailAddress}</div>
      <div>Referral Mobile Number: ${formValues.referralContactNumber}</div>
      <div>Referral Date of Birth: ${formValues.referralDoB}</div>
      <div>Referral Postcode: ${formValues.postcode}</div><br/>

      <div>Partner First Name: ${formValues.partnerFirstName}</div>
      <div>Partner Last Name: ${formValues.partnerLastName}</div>
      <div>Partner Email: ${formValues.partnerEmail}</div>
      <div>Partner Mobile Number: ${formValues.partnerContactNumber}</div>
      <div>Partner Date of Birth: ${formValues.partnerDoB}</div>

      <div>Referrer First Name: ${formValues.firstName}</div>
      <div>Referrer Last Name: ${formValues.lastName}</div>
      <div>Referrer Email: ${formValues.emailAddress}</div>
      <div>Referrer Mobile Number: ${formValues.contactNumber}</div>
      
      <div>Relationship to Referred: ${
        RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.whoseDetails)?.label
      }</div><br/>`;

      if (formValues.contactMethod === 'otherContact') {
        bodyStr += `<div>Other Contact:</div>
        <div>Preferred First Name: ${formValues.otherContactFirstName}</div>
        <div>Preferred Last Name: ${formValues.otherContactLastName}</div>
        <div>Preferred Email: ${formValues.otherContactEmail}</div>
        <div>Preferred Mobile Number: ${formValues.otherContactNumber}</div>
        
        <div>Preferred Contact's Relationship to Referred: ${
          RELATIONSHIP_OPTIONS.find((relation) => relation.value === formValues.otherContactRelationship)?.label
        }</div><br/>`;
      }
    }
  }

  if (formValues.referralBackground) {
    bodyStr += `<div>Referral Background:<br/>${formValues.referralBackground}</div>`;
  }
  return bodyStr;
};
