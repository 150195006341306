import { ReportInterface } from 'interfaces/report';
import moment from 'moment';

import styles from './ReportContentHeader.module.scss';

export interface ReportContentHeaderProps {
  reportName: string;
  clinicianDetails: ReportInterface;
  clinicianContactData?: ReportInterface['contactDetails'];
  issueDate: ReportInterface['issueDate'];
}

const ReportContentHeader = ({
  reportName,
  clinicianDetails,
  clinicianContactData,
  issueDate
}: ReportContentHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.practiceInfoWrapper}>
        <div className={styles.practiceCard}>
          {clinicianDetails.practice?.logo && (
            <div className={styles.practiceImgWrapper}>
              <img src={clinicianDetails.practice?.logo} alt={'practice logo'} className={styles.practiceImg} />
            </div>
          )}
          <div className={styles.practiceInfo}>
            <span className={styles.name}>{clinicianDetails.practice?.name}</span>
            {clinicianContactData?.practice.address.isVisible && (
              <div className={styles.detailInfo}>{clinicianContactData.practice.address.value}</div>
            )}
            {clinicianContactData?.practice.mobileNumber.isVisible && (
              <div className={styles.detailInfo}>{clinicianContactData.practice.mobileNumber.value}</div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.patientInfoWrapper}>
        {/* <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>Client Name:</span> {clientRecord.clientProfiles[0].name || '-'}
        </div> */}
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>Report Name:</span> {reportName || '-'}
        </div>
        <div className={styles.infoWrap}>
          <span className={styles.infoTitle}>Date of report:</span>{' '}
          {moment(issueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
        </div>
      </div>
    </div>
  );
};

export default ReportContentHeader;
