import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'utils/UserContextProvider';
import { useRechargeRoutesGenerator } from '../Path/RechargeRoutesGenerator';
import { useRechargeToken } from './useRechargeToken';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';

export const useRedirectLoginClientNextEngageStep = () => {
  const navigate = useNavigate();

  const { clientProfile } = useContext(UserContext);
  const { isClientProfileLoading } = useGetClientProfileDetails();

  const { SIGNUP } = useRechargeRoutesGenerator();
  const { token, isInitialised } = useRechargeToken();

  const redirectLoginClientNextEngageStep = useCallback(
    async ({
      accountId,
      reserveId,

      noLoginRedirectPath,
      skipEmergencyContactCheck
    }: {
      accountId: string;
      reserveId: string;

      noLoginRedirectPath?: string;
      skipEmergencyContactCheck?: boolean;
    }) => {
      // if client is logged in, check if should redirect to medicare or emergency contact paths
      if (clientProfile && !skipEmergencyContactCheck && !clientProfile.isEmergencyContactSetup) {
        navigate(SIGNUP.EMERGENCY_CONTACT);
        return;
      }

      // only called if:
      // 1. client is logged in, and has the requisite details
      // 2. client is not logged in, and there is no noLoginRedirectPath (last page before saved card only)
      if (token && (clientProfile || !noLoginRedirectPath)) {
        const res = await putProcessWithPaymentMethod(
          accountId,
          reserveId,
          {
            cancelUrl: window.location.href,
            returnUrl: `${window.location.origin}${SIGNUP.WELCOME_BOOKING}`
          },
          token
        );

        if (res.statusCode === 200) {
          const responseData = await res.json();

          if (responseData.checkoutUrl) {
            window.location.href = responseData.checkoutUrl;
          }
        } else if (res.statusCode === 204) {
          navigate(SIGNUP.WELCOME_BOOKING);
        }

        return;
      }

      // else if there's noLoginRedirectPath, redirect to that instead
      if (noLoginRedirectPath) {
        navigate(noLoginRedirectPath);
        return;
      }
    },
    [SIGNUP.EMERGENCY_CONTACT, SIGNUP.WELCOME_BOOKING, clientProfile, navigate, token]
  );

  return { isPreparingRedirect: isClientProfileLoading || !isInitialised, redirectLoginClientNextEngageStep };
};
