import { useAuth0 } from '@auth0/auth0-react';

import styles from './SurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';

const SurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <div className={`${styles.container} ease-theme`}>
      <EaseContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <EaseHeader classNames={styles.header} withPadding withMenu homeLink greenMenu />
        </div>
      </EaseContentLayout>
      <EaseContentLayout className={styles.contentWrapper}>
        <SurveyQuestion />
      </EaseContentLayout>
    </div>
  );
};

export default SurveyQuestionWrapper;
