import styles from './SignUpCompleteBooking.module.scss';
import classNames from 'classnames';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import { useGetClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import { useRechargeRoutesGenerator } from 'Recharge/utils/Path/RechargeRoutesGenerator';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useContext } from 'react';
import { UserContext } from 'utils/UserContextProvider';

const SignUpCompleteBooking = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { clientProfile } = useContext(UserContext);

  const { AVATAR, HOME } = useRechargeRoutesGenerator();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];
  const { clinicianId, psychologistName } = useReserveAppointmentData();
  const { data: clinician } = useGetClinicianDetailsByIdQuery({
    clinicianId: clinicianId || ''
  });
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();

  const handleGoToAccount = () => {
    removeReserveAppointmentData();

    const clientPortalPath = clientProfile?.avatar ? HOME : AVATAR;

    // this redirects from rechargewellness.com.au to my.rechargewellness.com.au
    // uncomment if engage flow should be hosted on rechargewellness.com.au, but
    // client-ui is on our own domain
    if (!isDevelopmentENV()) {
      window.location.href = `${
        process.env.REACT_APP_CLIENT_DOMAIN_RECHARGE || 'https://my.rechargewellness.com.au'
      }${clientPortalPath}`;
    } else {
      navigate(clientPortalPath);
    }
  };

  return (
    <RechargeHelmetWrapper title={'Recharge Wellness | Welcome'}>
      <div className={classNames(styles.container, 'recharge-theme')}>
        <RechargeHeader withPadding withMenu lightLogo />
        <RechargeContentLayout>
          <div className={styles.content}>
            {clinician?.avatar && (
              <div className={styles.avatar}>
                <img src={clinician?.avatar} alt={'Avatar'} />
              </div>
            )}
            <div className={styles.title}>You're all set</div>
            <div className={styles.welcome}>
              <div>
                <div className={styles.messageHeading}>
                  {isAuthenticated ? 'Hi' : 'Welcome'} {user?.name}
                </div>
                <div className={styles.message}>
                  Thanks so much for booking an appointment through Recharge Wellness.
                  {!isAuthenticated && (
                    <>
                      <br />
                      <br />
                      To make the best use of your session, we have included some online intake forms for you to fill
                      out prior to your appointment with {clinician?.name || psychologistName}.
                      <br />
                      <br />
                      The forms will include:
                      <ul>
                        <li>Consent form - Please read so you understand our terms and conditions</li>
                        <li>
                          DASS-21 - This is a 3-minute questionnaire to measure your current emotional state. This is
                          important for our psychologists to receive before your session, so we can get a really good
                          understanding of where you’re at to make the most of your first appointment
                        </li>
                        <li>Confirmation of your details</li>
                      </ul>
                    </>
                  )}
                </div>
                <div className={styles.messageEnding}>
                  Kind Regards,
                  <br />
                  {clinician?.name || psychologistName}
                </div>
              </div>
            </div>

            <div className={styles.whatNext}>
              {!isAuthenticated && (
                <>
                  <div className={styles.heading}>Your appointment has been confirmed. What next?</div>
                  <div className={styles.tasks}>
                    <ul>
                      <li>
                        <div className={styles.circle} /> <div className={styles.liText}>Consent form</div>
                      </li>
                      <li>
                        <div className={styles.circle} /> <div className={styles.liText}>DASS-21</div>
                      </li>
                      <li>
                        <div className={styles.circle} />{' '}
                        <div className={styles.liText}>Confirmation of your details</div>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.message}>
                    If you have any questions at all, please do not hesitate to reach out to us.
                  </div>
                </>
              )}
              <ButtonRecharge onClick={handleGoToAccount} className={styles.goToMyAccountBtn}>
                Go to your account
              </ButtonRecharge>
            </div>
          </div>
        </RechargeContentLayout>
        <TacklitFooter linkToTacklit darkFooter />
      </div>
    </RechargeHelmetWrapper>
  );
};

export default SignUpCompleteBooking;
