import { useFormikContext } from 'formik';
import CheckBox from 'components/CheckBox/CheckBox';
import styles from './SHPartnerCommunicationPreferencesForm.module.scss';

const SHPartnerCommunicationPreferencesForm = () => {
  const { values, setFieldValue } = useFormikContext<{
    receiveEmail: boolean;
    receiveSMS: boolean;
  }>();

  return (
    <div className={styles.communicationPreferences}>
      <div className={styles.header}>Communication Preferences</div>
      <div className={styles.text}>
        Please confirm that client consents to being contacted about this service with relevant information regarding
        your appointment via email and SMS. This includes items like appointment reminders, and requests to complete
        documents relevant to your program. This is not marketing.
      </div>
      <div className={styles.checkBoxes}>
        <div className={styles.checkBoxText}>
          <CheckBox
            id="receiveEmail"
            className={styles.checkBox}
            label="Client is happy to receive emails"
            onChange={(e) => {
              setFieldValue('receiveEmail', e.target.checked);
            }}
            value={values.receiveEmail}
          />
        </div>
        <div className={styles.checkBoxText}>
          <CheckBox
            id="receiveSMS"
            className={styles.checkBox}
            label="Client is happy to receive SMS"
            onChange={(e) => {
              setFieldValue('receiveSMS', e.target.checked);
            }}
            value={values.receiveSMS}
          />
        </div>
      </div>
    </div>
  );
};

export default SHPartnerCommunicationPreferencesForm;
