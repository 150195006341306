import classNames from 'classnames';

import styles from './PortobelloContentLayout.module.scss';

interface PortobelloContentLayoutProps {
  children?: any;
  className?: string;
}

const PortobelloContentLayout = ({ children, className }: PortobelloContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default PortobelloContentLayout;
