import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import classNames from 'classnames';

const ConsentListWrapper = () => (
  <div className={classNames(styles.container, 'select-theme')}>
    <SelectContentLayout className={styles.headerContent}>
      <div className={styles.headerWrapper}>
        <SelectHeader withPadding withMenu whiteFont />
      </div>
    </SelectContentLayout>
    <SelectContentLayout className={styles.content}>
      <ConsentList />
    </SelectContentLayout>
  </div>
);

export default ConsentListWrapper;
