/* eslint-disable complexity */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirstAssessment } from '../../../../components/PatientQuestionForm/AssessmentInterfaces';
import { getConsentFormFacilitated } from 'utils/http/DocumentService/ConsentForm/consentForm';
import { getOnboardingAssessment } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { notification } from 'antd';
import { isErrorBentStatusError } from 'utils/isErrorWithStatusCode';
import { getPsychometricList } from 'utils/http/CheckInService/Psychometrics/psychometrics';

export const useFetchFacilitatedOA = (
  token: string,
  setViewState: Dispatch<
    SetStateAction<'done' | 'saveAndExit' | 'welcomeBack' | 'questions' | 'background' | 'welcomeSurvey'>
  >,
  clientRecordId: string
) => {
  const navigate = useNavigate();
  const { HOME, FACILITATED } = useRoutesGenerator();
  const [facilitatedOADetails, setFacilitatedOADetails] = useState<FirstAssessment | undefined>(undefined);
  const [isFacilitatedOALoading, setIsFacilitatedOALoading] = useState(true);

  const checkConsentFormExist = async (token: string) => {
    try {
      const res = await getConsentFormFacilitated(token);

      if (res.statusCode === 200) {
        navigate(`${FACILITATED.CONSENT}?token=${token}`);
        return true;
      } else {
        return false;
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const fetchFirstAssessment = async () => {
    setIsFacilitatedOALoading(true);

    try {
      const hasConsent = await checkConsentFormExist(token);

      if (!hasConsent) {
        const response = await getOnboardingAssessment(token, clientRecordId);

        if (response.statusCode === 204) {
          const psychometricList = await (await getPsychometricList(token)).json();
          psychometricList.length > 0 ? navigate(`${HOME}/psychometrics?token=${token}`) : setViewState('done');
        } else if (response.statusCode === 200) {
          navigate(`${FACILITATED.ONBOARDING}/next?token=${token}`);
        } else {
          setViewState('done');
        }
      }
    } catch (ex) {
      console.error(ex);

      notification.error({
        message: 'Fail to get facilitated onboarding assessments',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      if (isErrorBentStatusError(ex) && ex.statusCode === 401) {
        navigate('/404');
        return;
      }
      setViewState('done');
    }

    setIsFacilitatedOALoading(false);
  };

  useEffect(() => {
    if (clientRecordId) {
      fetchFirstAssessment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRecordId]);

  return { facilitatedOADetails, isFacilitatedOALoading, setFacilitatedOADetails };
};
