import { Helmet, HelmetProvider } from 'react-helmet-async';

interface SelectHelmetWrapperProps {
  title?: string;
  children?: any;
}

const faviconUrl = `${process.env.PUBLIC_URL}/favPortobello.png`;

const PortobelloHelmetWrapper = ({ children, title }: SelectHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name={'description'}
          content={
            'Practice established in 2016. Now the leading provider of Case Management and Behavioural Health Coaching in Europe.'
          }
        />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default PortobelloHelmetWrapper;
