import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './PsychologistDetails.module.scss';
import { useFetchPractitionerDetails } from './hooks/getPsychologistDetails';
import PsychologistDetailsHeader from './components/PsychologistDetailsHeader/PsychologistDetailsHeader';
import PsychologistBonaFides from 'Select/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistQuote from './components/PsychologistQuote/PsychologistQuote';
import PsychologistTimeSlot from './components/PsychologistTimeSlot/PsychologistTimeSlot';
import PsychologistWelcomeNote from './components/PsychologistWelcomeNote/PsychologistWelcomeNote';
import PsychologistFAQ from './components/PsychologistFAQ/PsychologistFAQ';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PractitionerImageSlideshow from 'components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { FitDeliveryList } from 'Select/interfaces/fitFilter';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectFooter from 'Select/components/SelectFooter/SelectFooter';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { Modal } from 'antd';
import JoinWaitlistForm from 'Select/components/JoinWaitlistForm/JoinWaitlistForm';

export interface PsychologistQueryParam {
  timeSlotSection?: boolean;
}

const PsychologistDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useSelectRoutesGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('SelectTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return (
    <SelectHelmetWrapper title={`Select Psychology | ${practitionerName || 'Psychologist'} Profile`}>
      <div className="select-theme">
        {isPractitionerDetailsLoading ? (
          <div className={styles.loading}>
            <LoadingDot />
          </div>
        ) : (
          <>
            <PsychologistDetailsHeader practitionerDetails={practitionerDetails} listingLink={backToListingLink} />
            <div className={styles.container}>
              <SelectContentLayout>
                <div className={styles.detailsContentWrapper}>
                  {/* Left content */}
                  <div className={styles.leftContent}>
                    <PsychologistWelcomeNote practitionerDetails={practitionerDetails} />

                    {/* Just show on mobile view */}
                    <div className={styles.appointmentBtnWrapper}>
                      <ButtonSelect onClick={() => scrollToView('SelectTimeSlot')} className={styles.appointmentBtn}>
                        BOOK AN APPOINTMENT
                      </ButtonSelect>
                    </div>
                  </div>

                  {/* Right content */}
                  <div className={styles.rightContent}>
                    {practitionerDetails.helmControl?.additionalPhotos?.length > 0 && (
                      <div className={styles.additionalPhoto}>
                        <div className={styles.additionalPhotoCard}>
                          <PractitionerImageSlideshow
                            classNames={styles.slideShow}
                            slidePhoto={practitionerDetails.helmControl.additionalPhotos}
                          />
                        </div>
                      </div>
                    )}
                    <div className={styles.detailsWrapper}>
                      {/* Just show on desktop view */}
                      <div className={styles.appointmentBtnWrapper}>
                        <ButtonSelect onClick={() => scrollToView('SelectTimeSlot')} className={styles.appointmentBtn}>
                          BOOK AN APPOINTMENT
                        </ButtonSelect>
                      </div>
                      {practitionerDetails.helmControl?.bonaFides && (
                        <PsychologistBonaFides bonaFidesDetails={practitionerDetails.helmControl.bonaFides} />
                      )}
                      {practitionerDetails.helmControl?.deliveryModes?.length && (
                        <div className={styles.deliveryModeContainer}>
                          <div className={styles.deliveryModeLabel}>SERVICES DELIVERED VIA</div>
                          {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.faceToFace) && (
                            <div className={styles.deliveryModeItem}>
                              <i className={`material-icons ${styles.callIcon}`}>face</i>
                              <div className={styles.label}>Face to Face</div>
                            </div>
                          )}
                          {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.video) && (
                            <div className={styles.deliveryModeItem}>
                              <i className={`material-icons ${styles.callIcon}`}>videocam</i>
                              <div className={styles.label}>Video Call</div>
                            </div>
                          )}
                          {practitionerDetails.helmControl?.deliveryModes?.includes(FitDeliveryList.phone) && (
                            <div className={styles.deliveryModeItem}>
                              <i className={`material-icons ${styles.callIcon}`}>call</i>
                              <div className={styles.label}>Phone Call</div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </SelectContentLayout>
            </div>
            {practitionerDetails.helmControl?.quote && (
              <PsychologistQuote quote={practitionerDetails.helmControl.quote} />
            )}
            <PsychologistTimeSlot
              psychologistDetails={practitionerDetails}
              onShowWaitlistForm={() => setShowWaitlistForm(true)}
            />
            <PsychologistFAQ />
            <SelectFooter />
          </>
        )}

        <Modal
          width={700}
          visible={showWaitlistForm}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          <JoinWaitlistForm
            onComplete={() => {
              setShowWaitlistForm(false);
            }}
            accountId={practitionerDetails.accountId}
          />
        </Modal>
      </div>
    </SelectHelmetWrapper>
  );
};

export default PsychologistDetails;
