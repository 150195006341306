import styles from './PartnerConfirmPage.module.scss';
import classNames from 'classnames';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import { PARTNER_SH_SIGN_UP_USER, retrieveUserSession } from 'SomeoneHealth/pages/SignUp/helper/userSession';
import { useFetchPractitionerDetails } from 'SomeoneHealth/pages/PsychologistDetails/hooks/getPsychologistDetails';
import {
  reservedPartnerReferral,
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import { useSomeoneHealthPartnerRoutesGenerator } from '../../utils/Path/SomeoneHealthPartnerRoutesGenerator';
import { useNavigate } from 'react-router-dom';
import { useFetchReservedAppointmentsWithTimeZone } from '../../../utils/hooks/appointment';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from '../../../../utils/constants/timeZone';
import Loading from '../../../../components/Loading/Loading';

const PartnerConfirmPage = () => {
  const navigate = useNavigate();
  const user = retrieveUserSession(PARTNER_SH_SIGN_UP_USER)?.clientRecord?.clientProfiles?.[0];
  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;
  const { PRACTITIONER } = useSomeoneHealthPartnerRoutesGenerator();
  const { reserveId, accountId, clinicianId } = useReserveAppointmentData(reservedPartnerReferral);
  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData(reservedPartnerReferral);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  const handleGoToAccount = () => {
    removeReserveAppointmentData();
    navigate(PRACTITIONER.LISTING);
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Confirmed'}>
      <div className={classNames(styles.container, 'someone-health-theme')}>
        <SomeoneHealthHeader withPadding pinkLogo withMenu pinkMenu noLogin />
        <SomeoneHealthContentLayout>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={styles.box}>
              <div className={styles.avatar}>
                <img src={clinicianAvatar} alt={'Avatar'} />
              </div>
              <div className={styles.content}>
                <div className={styles.badge}>APPOINTMENT CONFIRMED</div>
                <div className={styles.title}>
                  Nice work! The appointment for {user?.name} with {clinicianName} has been confirmed.
                </div>
                <div className={styles.desc}>
                  <div>
                    Appointment ID is: <strong>{appointments.map((obj) => obj._id).join(', ')}</strong>
                  </div>
                  <br />
                  The client will receive confirmation of their appointment booking by Email and/or SMS shortly
                  <br />
                  <br />
                  They will also receive information on what to expect.
                  <br />
                  <br />
                  You can provide this link should they want to read more immediately:{' '}
                  <a
                    href={'https://someone.health/I-have-been-referred'}
                    className={styles.link}
                    rel="noreferrer"
                    target={'_blank'}
                  >
                    someone.health/I-have-been-referred
                  </a>
                </div>
              </div>
              <div className={styles.closeButtonWrapper}>
                <ButtonSH onClick={handleGoToAccount} className={styles.closeButton}>
                  Close this session
                </ButtonSH>
              </div>
            </div>
          )}
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default PartnerConfirmPage;
