import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getHelmClinicianListing = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/helm/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getHelmClinicianDetails = (clinicianId: string) =>
  httpClient('GET', url!, 200)(`/accounts/helm/clinicians/${clinicianId}`) as Promise<BentResponse>;
