import { useAuth0 } from '@auth0/auth0-react';
import { message, notification } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Clinician, useFetchClinician } from 'utils/hooks/clinician';
import { useGetAccessToken } from 'utils/hooks/token';
import { getAdHocAssessment } from 'utils/http/checkIn';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientQuestionForm from 'components/PatientQuestionForm/PatientQuestionForm';
import Gateway from './components/Gateway/Gateway';
import Header from './components/Header/Header';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';

import { StatusError } from 'bent';
import classNames from 'classnames';
import { FacilitatedTokenInterface } from 'interfaces/Assessment/OnboardingAssessment';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import {
  IS_CAW_APP,
  IS_CORDS_APP,
  IS_EASE_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import CORDSGateway from './components/Gateway/CORDSGateway';
import styles from './PatientAdHocAssessment.module.scss';
import { CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrand';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';

const useFetchAdHocAssessment = (
  setViewState: Dispatch<SetStateAction<'done' | 'saveAndExit' | 'welcomeBack' | 'questions'>>,
  assessmentId?: string,
  clinician?: Clinician,
  token?: string
) => {
  const [adHocAssessment, setAdHocAssessment] = useState<FirstAssessment | undefined>(undefined);
  const [isAdHocAssessment404, setIsAdHocAssessment404] = useState(false);
  const [isAdHocAssessmentLoading, setIsAdHocAssessmentLoading] = useState(true);
  const [t] = useTranslation();

  const fetchAdHocAssessment = async () => {
    setIsAdHocAssessmentLoading(true);

    let adHocAssessment;
    try {
      if (token) {
        adHocAssessment = await (await getAdHocAssessment(token, clinician!._id, assessmentId!)).json();
      }

      if (adHocAssessment && (adHocAssessment.status === 'done' || adHocAssessment.status === 'none')) {
        setViewState('done');
      }

      setAdHocAssessment(adHocAssessment);
    } catch (ex) {
      const err = ex as StatusError;
      if (err.statusCode && (await err.text()) === 'Token expired') {
        const senderName = token
          ? (jwt_decode(token) as FacilitatedTokenInterface)['https://tacklit.com/senderName']
          : t('terminology:practitioner');
        notification.error({
          message: t('form.error.expired_token', { senderName })
        });
      } else if (IS_CORDS_APP && err.statusCode === 404) {
        setIsAdHocAssessment404(true);
      } else {
        console.error(ex);
        message.error(t('form.error.failed_to_retrieve_onboarding assessment'));
      }
    }

    setIsAdHocAssessmentLoading(false);
  };

  useEffect(() => {
    if (token && assessmentId && clinician?._id) {
      fetchAdHocAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, clinician?._id]);

  return { adHocAssessment, isAdHocAssessment404, isAdHocAssessmentLoading, setAdHocAssessment };
};

const PatientAdHocAssessment = () => {
  const { user } = useAuth0();
  const { token, isQueryParamToken, userInfo } = useGetAccessToken(true);
  const { assessmentId, clinicianSlugUrlOrId = '' } = useParams<{
    assessmentId: string;
    clinicianSlugUrlOrId: string;
  }>();

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { clinician } = useFetchClinician(clinicianSlugUrlOrId);
  const [viewState, setViewState] = useState<'done' | 'saveAndExit' | 'welcomeBack' | 'questions'>('welcomeBack');
  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user && user['https://tacklit.com/use-check-in-bg'])
  );
  const { adHocAssessment, isAdHocAssessment404, isAdHocAssessmentLoading, setAdHocAssessment } =
    useFetchAdHocAssessment(setViewState, assessmentId, clinician, token);

  const handleSaveAndExit = () => {
    setViewState('saveAndExit');
  };

  const handleReturnToAssessment = () => {
    setViewState('questions');
  };

  const handleUpdateStatusDone = () => {
    setViewState('done');
  };

  const handleUpdateAssessment = (
    assessmentId: string,
    responses: { questionId: string; response: { value: any; otherValue?: any } }[]
  ) => {
    if (adHocAssessment) {
      const newAdHocAssessment = { ...adHocAssessment };

      const foundQuestionSet =
        newAdHocAssessment.assessment.clinicalAssessments.find((assessment) => assessment.id === assessmentId) ||
        newAdHocAssessment.assessment.onboardingQuestions.assessmentField.find(
          (assessment) => assessment.id === assessmentId
        );

      if (foundQuestionSet) {
        const questions = foundQuestionSet.sections.map((section) => section.questions).flat();

        for (let i = 0; i < responses.length; i++) {
          const { questionId, response } = responses[i];

          const foundQuestion = questions.find((question) => question.id === questionId);

          foundQuestion && (foundQuestion.response = response);
        }
      }

      setAdHocAssessment(newAdHocAssessment);

      return { updatedQuestionSet: foundQuestionSet, updatedAssessment: newAdHocAssessment };
    }
  };

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  const clinicianAvatar = attachedClinicianDetail?.clinician?.avatar || attachedClinicianDetail?.practice?.logo;

  const isLoading = isAdHocAssessmentLoading || isAttachedClinicianDetailLoading;

  return isLoading ? (
    <div className={classNames(styles.loading, styles[CLIENT_BRAND_LOWER_CASE])}>
      <LoadingCircle />
    </div>
  ) : viewState === 'questions' ? (
    <div
      className={classNames(
        styles.container,
        styles[CLIENT_BRAND_LOWER_CASE],
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme',
        IS_PORTOBELLO_APP && 'portobello-theme',
        IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme'
      )}
    >
      <ContentLayout className={styles.content}>
        <PatientQuestionForm
          assessment={adHocAssessment}
          clinician={clinician}
          clinicianName={clinicianName}
          header={Header}
          onUpdateAssessment={handleUpdateAssessment}
          onSaveAndExit={handleSaveAndExit}
          onUpdateStatusDone={handleUpdateStatusDone}
          token={token}
        />
      </ContentLayout>
    </div>
  ) : IS_CORDS_APP ? (
    <CORDSGateway
      state={viewState}
      practiceName={clinician?.practices?.[0]?.name}
      clinicianAvatar={clinician?.avatar}
      clinicianName={`${clinician?.title} ${clinician?.name}`}
      onReturnToAssessment={handleReturnToAssessment}
      assessment={adHocAssessment}
      isAdHocAssessment404={isAdHocAssessment404}
      isQueryParamToken={isQueryParamToken}
      userInfo={userInfo}
    />
  ) : (
    <div
      className={classNames(
        IS_CAW_APP
          ? styles.cawGatewayContainer
          : IS_SOMEONE_HEALTH_APP
          ? styles.shGatewayContainer
          : IS_EASE_APP
          ? styles.easeGatewayContainer
          : IS_RECHARGE_APP
          ? styles.rechargeContainer
          : IS_SELECT_APP
          ? styles.selectContainer
          : IS_PORTOBELLO_APP
          ? styles.portobelloContainer
          : styles.gatewayContainer,
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme',
        IS_PORTOBELLO_APP && 'portobello-theme',
        IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme'
      )}
      style={
        !isBackgroundImageUrlLoading && backgroundImageUrl
          ? {
              backgroundImage: `url('${backgroundImageUrl}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }
          : undefined
      }
    >
      <Gateway
        state={viewState}
        clinicianAvatar={clinicianAvatar}
        clinicianName={clinicianName}
        onReturnToAssessment={handleReturnToAssessment}
        assessment={adHocAssessment}
        isQueryParamToken={isQueryParamToken}
        userInfo={userInfo}
      />
    </div>
  );
};

export default PatientAdHocAssessment;
