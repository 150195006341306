import { someoneHealthEnvironment } from '../SomeoneHealthEnv/SomeoneHealthEnv';

export const useSomeoneHealthPractitionersUrl = () => {
  const { SomeoneHealthFindPsychologistURL } = someoneHealthEnvironment();
  return SomeoneHealthFindPsychologistURL;
};

export const useSomeoneHealthPractitionerUrl = ({
  noParams,
  clinicianIdOrSlugUrl
}: {
  noParams?: boolean;
  clinicianIdOrSlugUrl?: string;
}) => {
  const { SomeoneHealthFindPsychologistURL } = someoneHealthEnvironment();

  const practitionerDetails = `${SomeoneHealthFindPsychologistURL}/details`;

  if (noParams) {
    return practitionerDetails;
  } else if (clinicianIdOrSlugUrl) {
    return `${practitionerDetails}?id=${clinicianIdOrSlugUrl}`;
  }
  return SomeoneHealthFindPsychologistURL;
};
