import ThankYouImage from 'assets/images/thankyou.png';
import SHThankYouImage from 'assets/images/shtq.png';
import styles from './DoneCard.module.scss';
import { IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

const DoneCard = () =>
  IS_SOMEONE_HEALTH_APP ? (
    <div className={styles.container}>
      <img className={styles.shThankYouImage} src={SHThankYouImage} alt="Thank you" />
      <div className={styles.shThankYouText}>Thank you for signing the consent form</div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.thankYouText}>Thank you for signing the consent form</div>
      <img className={styles.thankYouImage} src={ThankYouImage} alt="Thank you" />
    </div>
  );

export default DoneCard;
