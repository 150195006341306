import styles from './WidgetPreviewWrapper.module.scss';

interface WidgetPreviewWrapperProps {
  children: any;
  position: any;
  dimensions: any;
}

export const WidgetPreviewWrapper = ({ children, position, dimensions }: WidgetPreviewWrapperProps) => {
  return (
    <div
      className={styles.container}
      style={{
        left: position.left,
        top: position.top,
        width: dimensions.width,
        height: dimensions.height
      }}
    >
      <div>
        <div className={styles.box}>{children}</div>
      </div>
    </div>
  );
};
