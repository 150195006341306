import EditSavedCardButton from 'components/EditSavedCardButton/EditSavedCardButton';
import styles from './PaymentDetails.module.scss';

const PaymentDetails = () => {
  return (
    <div className={styles.contact}>
      <div className={styles.title}>Payment Details</div>
      <div className={styles.description}>Update your stored card details</div>
      <div className={styles.addKeyBtnWrapper}>
        <EditSavedCardButton />
      </div>
    </div>
  );
};

export default PaymentDetails;
