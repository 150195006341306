import { Skeleton } from 'antd';
import styles from './LogoSkeleton.module.scss';

const LogoSkeleton = () => (
  <div className={styles.logoLoading}>
    <Skeleton.Input active className={styles.logoLoadingImg} />
  </div>
);

export default LogoSkeleton;
