import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SHGPReferral from 'SomeoneHealth/components/SHGPReferral/SHGPReferral';
import SHCreateProfileForm from 'SomeoneHealth/components/SHCreateProfileForm/SHCreateProfileForm';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import OCD from 'SomeoneHealth/assets/images/OCD.png';
import styles from './SignUp.module.scss';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { storeUserSession, UserSessionStorage } from 'SomeoneHealth/pages/SignUp/helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'SomeoneHealth/utils/hooks/appointment';
import { getAuMobile, initialCustomer } from 'SomeoneHealth/components/SHCreateProfileForm/constants';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import SomeoneHealthTimer from '../SomeoneHealthTimer/SomeoneHealthTimer';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import ErrorMessageWithHelpLink from '../SignUpWithInvite/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { ga4EventPush } from 'utils/GAEvent';

const SignUp = () => {
  const navigate = useNavigate();
  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;
  const { isAuthenticated, isLoading } = useAuth0();
  const { SIGNUP } = useSomeoneHealthRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } = useReserveAppointmentData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  if (isAuthenticated) {
    navigate(SIGNUP.MEDICARE);
  }

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession(value, dob);
    scrollToView('SomeoneHealthHeader');
    navigate(SIGNUP.MEDICARE);
  };

  const onSubmitHandle = async (values: typeof initialCustomer) => {
    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, receiveSMS } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
          const communicationPreferenceLogic = receiveSMS
            ? CommunicationPreference.NoPreference
            : CommunicationPreference.Email;

          const payloadMassage = {
            clinicianId: clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              timeZone: clientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
              communicationPreference: communicationPreferenceLogic,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(accountId || '', payloadMassage);

          const { clientRecord, authToken } = await callPatientSignUp.json();

          ga4EventPush('onSuccessSignUpEngageClient', {
            clientId: clientRecord._id,
            clinicianId: clinicianId
          });

          await fetchClaimingReservation(reserveId!, accountId!, authToken);
          setIsSubmitting(false);
          proceedToNextStep({ clientRecord, authToken }, dob);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(
          <ErrorMessageWithHelpLink
            isFullProfileType={checkEmailExisted?.response?.isFullProfileType}
            errorMessage="Email address is already used."
          />
        );
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
      );
    }
  };

  return isLoading || fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <SomeoneHealthHelmetWrapper title={'someone.health - Sign Up'}>
      <SomeoneHealthContentLayout>
        <SomeoneHealthHeader pinkLogo withPadding classNames={styles.header} loginTextClass={styles.loginTextClass} />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <SHCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
            />
          </div>
          <div className={styles.rightContent}>
            <SHGPReferral />
            <div className={styles.topNotch}>
              <img src={OCD} alt={'Someone Health top notch'} />
              <div className={styles.topNotchTitle}>Top-notch privacy and security</div>
              <div className={styles.topNotchDesc}>
                <b>someone.health</b> was built to exceed all regulatory requirements for privacy and security. All data
                is stored within Australia and encrypted both at-rest and in-transit, with strict access controls.
              </div>
            </div>
          </div>
        </div>
        <SomeoneHealthTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} hideTimer />
      </SomeoneHealthContentLayout>
      <TacklitFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default SignUp;
