import { Link } from 'react-router-dom';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import Button from 'helm/components/Button/Button';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import { scrollToView } from 'utils/scrollToView';

import styles from './PractitionerDetailsHeader.module.scss';

interface PractitionerDetailsHeaderProps {
  practitionerDetails: PractitionerDetailsInterface;
  listingLink: string;
}

const PractitionerDetailsHeader = ({ practitionerDetails, listingLink }: PractitionerDetailsHeaderProps) => {
  return (
    <div className={styles.container}>
      <HelmContentLayout className={styles.headerWrapper}>
        <HelmHeader noWrapper />
      </HelmContentLayout>
      <HelmContentLayout className={styles.wrapper}>
        <div className={styles.overlay} />
        <div className={styles.content}>
          <div className={styles.middleWrapper}>
            <div className={styles.headerImageContainer}>
              {practitionerDetails.helmControl.headerPhoto && (
                <div className={styles.headerImgWrapper}>
                  <div className={styles.headerImageBgWrapper}>
                    <div className={styles.headerImgBackground} />
                  </div>
                  <div className={styles.headerImgMask}>
                    <img
                      alt={'heading_photo'}
                      className={styles.headerImage}
                      src={practitionerDetails.helmControl.headerPhoto}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.detailsWrapper}>
              <div className={styles.name}>{practitionerDetails.name}</div>
              <div className={styles.actionWrapper}>
                <Link to={listingLink} className={styles.otherPractitioner}>
                  <i className={`material-icons ${styles.icon}`}>arrow_back</i>
                  <div className={styles.label}>SEE OTHER COUNSELLORS</div>
                </Link>
                <div className={styles.bookAppointmentBtn}>
                  <Button onClick={() => scrollToView('HelmTimeSlot')}>BOOK AN APPOINTMENT</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmContentLayout>
    </div>
  );
};

export default PractitionerDetailsHeader;
