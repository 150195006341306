import { createContext, ReactNode, useContext, useEffect } from 'react';
import Loading from 'components/Loading/Loading';
import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import { PatientProfile } from 'components/UpdateProfileContent/interface';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useNavigate } from 'react-router-dom';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { EasePracticeSlugUrl } from 'Ease/utils/EaseEnv/EaseEnv';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';

export const EaseUserContext = createContext<{
  clientProfile?: PatientProfile;
  practiceInfo: PublicPracticeProfile;
}>({
  practiceInfo: {
    accountId: '',
    name: '',
    description: '',
    logo: ''
  }
});

export const EaseUserContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { clientProfileData, isClientProfileLoading, isError } = useGetClientProfileDetails();

  const { data: practiceInfoData, isLoading: practiceInfoDataLoading } = useGetPracticeInfoQuery({
    slugUrlOrAccountId: EasePracticeSlugUrl
  });

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError, navigate]);

  return isClientProfileLoading || practiceInfoDataLoading || !practiceInfoData ? (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  ) : (
    <EaseUserContext.Provider
      value={{
        clientProfile: clientProfileData,
        practiceInfo: practiceInfoData
      }}
    >
      {children}
    </EaseUserContext.Provider>
  );
};

export const useEaseUserContext = () => useContext(EaseUserContext);
