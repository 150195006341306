import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FirstAssessment } from '../../../../components/PatientQuestionForm/AssessmentInterfaces';
import { getOnboardingAssessment } from 'utils/http/CheckInService/ClientRecords/clientRecords';
import { notification } from 'antd';

export const useFetchOnboardingDetails = (
  setViewState: Dispatch<
    SetStateAction<'done' | 'saveAndExit' | 'welcomeBack' | 'questions' | 'background' | 'welcomeSurvey'>
  >,
  clientRecordId: string
) => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [surveyNote, setSurveyNote] = useState<string>('');
  const [onboardingAssessmentDetails, setOnboardingAssessmentDetails] = useState<FirstAssessment | undefined>(
    undefined
  );
  const [isOnboardingAssessmentLoading, setIsOnboardingAssessmentLoading] = useState(true);

  const fetchOnboardingAssessment = async () => {
    setIsOnboardingAssessmentLoading(true);

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const response = await getOnboardingAssessment(token, clientRecordId);

      if (response.statusCode === 204) {
        setViewState('done');
      } else {
        const onboardingAssessment = await response.json();

        const bgRes = onboardingAssessment.questionsResponses.assessment.onboardingQuestions.backgroundResponse;

        let isHaveBgQuestion = false;

        for (const key in bgRes) {
          if (Object.prototype.hasOwnProperty.call(bgRes, key)) {
            if (bgRes[key].isShow) {
              isHaveBgQuestion = true;
            }

            bgRes[key] =
              key !== 'status'
                ? key === 'therapist'
                  ? {
                      ...bgRes[key],
                      ...(!bgRes[key].code && { code: 'no', similarExperience: 'no' })
                    }
                  : key === 'summary'
                  ? { ...bgRes[key] }
                  : { ...bgRes[key], ...(!bgRes[key].code && { code: 'no' }) }
                : bgRes[key];
          }
        }

        onboardingAssessment.questionsResponses.assessment.onboardingQuestions.backgroundResponse = bgRes;

        const callOAData = onboardingAssessment.questionsResponses;

        setSurveyNote(onboardingAssessment?.survey?.note);

        if (bgRes.status === 'done' || !isHaveBgQuestion) {
          const isHaveQuestion = callOAData.assessment.onboardingQuestions.assessmentField.find((obj: any) => {
            return obj.sections && obj.sections.find((sec: any) => sec.questions.length > 0);
          });
          if (isHaveQuestion) {
            setViewState('welcomeBack');
          } else {
            setViewState('done');
          }
        }

        if (callOAData && (callOAData.status === 'done' || callOAData.status === 'none')) {
          setViewState('done');
        }

        setOnboardingAssessmentDetails(callOAData);
      }
    } catch (ex) {
      console.error(ex);

      notification.error({
        message: 'Fail to get onboarding assessments',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });

      setViewState('done');
    }

    setIsOnboardingAssessmentLoading(false);
  };

  useEffect(() => {
    if (clientRecordId && !isLoading) {
      fetchOnboardingAssessment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRecordId, isLoading]);

  return { onboardingAssessmentDetails, surveyNote, isOnboardingAssessmentLoading, setOnboardingAssessmentDetails };
};
