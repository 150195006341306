import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const initialCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  password: '',
  theme: '',
  communicationPreference: '',
  hearAboutSource: ''
};

export const howDidYouHearAboutUsOptions = [
  { label: 'Google', value: 'Google' },
  { label: 'Social Media', value: 'Social Media' },
  { label: 'Bark', value: 'Bark' },
  { label: 'GP', value: 'GP' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Health Directory', value: 'Health Directory' }
];

export const getAuMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+61' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const passwordValidationSchema = (pwScore: number) =>
  Yup.object().shape({
    password: Yup.string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Weak password!', () => pwScore === 4)
      .nullable()
  });

export const formDataValidationSchema = (requireHearAboutSource: boolean) => {
  const dobAge = 14;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    dateOfBirth: Yup.string()
      .required('Please choose your date of birth')
      .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
        return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
      }),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    communicationPreference: Yup.string()
      .required('Please select a communication preference')
      .oneOf([CommunicationPreference.Email, CommunicationPreference.SMS, CommunicationPreference.NoPreference]),
    hearAboutSource: requireHearAboutSource ? Yup.string().required('Please select one') : Yup.string().nullable()
  });
};
