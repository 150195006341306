import styles from './AppointmentTags.module.scss';

const AppointmentTags = ({ tags }: { tags: string[] }) => {
  return (
    <div className={styles.tags}>
      {tags.map((status, index) => (
        <div className={styles.tag} key={index}>
          {status}
        </div>
      ))}
    </div>
  );
};

export default AppointmentTags;
