import { Component, FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { ClientBrand } from '../../interfaces/ClientBrand';
import HelmHelmetWrapper from '../../helm/components/HelmHelmetWrapper/HelmHelmetWrapper';

interface AppRouteProps {
  component: typeof Component | FunctionComponent;
  layout: typeof Component | FunctionComponent;
  title?: string;
  brand?: ClientBrand;
}

const routeWithLayout = ({
  component: Component,
  layout: Layout,
  title,
  brand,
  ...props
}: AppRouteProps & RouteProps) => {
  return brand === ClientBrand.HELM ? (
    <Route
      {...props}
      element={
        <Layout>
          <HelmHelmetWrapper title={title}>
            <Component {...props} />
          </HelmHelmetWrapper>
        </Layout>
      }
    />
  ) : (
    <Route
      {...props}
      element={
        <Layout>
          <Component {...props} />
        </Layout>
      }
    />
  );
};

export default routeWithLayout;
