import styles from './FAQBubble.module.scss';

interface FAQBubbleProps {
  details: {
    id?: string;
    title: string;
    desc: string;
  };
}

const FAQBubble = ({ details }: FAQBubbleProps) => {
  return (
    <div className={styles.container} id={details.id}>
      <div className={styles.pointWrapper}>
        <div className={styles.line} />
        <div className={styles.bubbleWrapper}>
          <div className={`material-icons-outlined ${styles.bubbleDot}`}>question_answer</div>
        </div>
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.title}>{details.title}</div>
        <div className={styles.desc} dangerouslySetInnerHTML={{ __html: details.desc }} />
      </div>
    </div>
  );
};

export default FAQBubble;
