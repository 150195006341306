import { notification } from 'antd';
import CheckBox from 'components/CheckBox/CheckBox';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import { clinicianDetailsInterface } from 'interfaces/Clinician/clinicianDetails';
import { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import PsychologistAvatar from 'Recharge/components/PsychologistAvatar/PsychologistAvatar';
import { useGetAccessToken } from 'utils/hooks/token';
import { patchDisconnectPractitioner } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import styles from './ConfirmationDisconnectModal.module.scss';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';

interface ConfirmationDisconnectModalProps {
  clinician: clinicianDetailsInterface;
  onSubmitted: () => void;
}

const DISCONNECTED_REASON = [
  { label: "Didn't feel a connection", value: "Didn't feel a connection" },
  { label: "Wasn't making enough progress", value: "Wasn't making enough progress" },
  { label: "Didn't have any availability", value: "Didn't have any availability" },
  { label: "Weren't available at times suitable for me", value: "Weren't available at times suitable for me" },
  { label: 'Just want to try someone new', value: 'Just want to try someone new' },
  { label: 'Not experienced in my needs', value: 'Not experienced in my needs' },
  { label: "Wasn't my style of therapy", value: "Wasn't my style of therapy" },
  { label: "I'd rather not say", value: "I'd rather not say" }
];

const ConfirmationDisconnectModal = ({ clinician, onSubmitted }: ConfirmationDisconnectModalProps) => {
  const { PRACTITIONER } = useRechargeDoDOrNonDoDPathGenerator();
  const { token } = useGetAccessToken();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [status, setStatus] = useState<'' | 'active' | 'finished'>('');
  const [reason, setReason] = useState<string>('');
  const detailsPagePath =
    clinician &&
    generatePath(PRACTITIONER.DETAILS, {
      clinicianId: clinician.slugUrl
    });

  const handleConfirm = async () => {
    try {
      setStatus('active');
      const body = { reason };
      await patchDisconnectPractitioner(token, body);
      setStatus('finished');
      onSubmitted();
      setTimeout(() => {
        setStatus('');
      }, 1000);
      return;
    } catch (ex) {
      setStatus('');
      console.error(ex);
      notification.error({ message: 'Something went wrong!', duration: 3000 });
    }
  };

  return (
    <div className={`${styles.container} recharge-theme`}>
      <div className={styles.infoSection}>
        <div className={styles.title}>You can decide to change to another psychologist</div>
        <div className={styles.description}>
          If you’d like to work with someone else we make it easy for you to make that change.
          <br /> <br /> You can browse through our available psychologists and if you find someone you think will be a
          good fit, come back to your secure account and make that update.
        </div>
        <Link to={generatePath(PRACTITIONER.LISTING)}>
          <ButtonRecharge variant={'outlined'}>Browse available psychologists</ButtonRecharge>
        </Link>
      </div>
      <div className={styles.confirmationSection}>
        <PsychologistAvatar
          className={styles.profile}
          profileImg={clinician.avatar}
          detailPath={detailsPagePath || ''}
          classNameFrame={styles.profileFrame}
        />
        <div className={styles.confirmInfo}>
          <div className={styles.title}>Stop working with {clinician.name}?</div>
          <div className={styles.description}>
            You can end your relationship with {clinician.name} at this time (you can always reconnect again in the
            future).
            <br />
            <br />
            Note that any future appointments you have scheduled will be cancelled, and may incur a charge if outside
            our accepted cancellation policy.
          </div>
          <div>
            <CheckBox
              id="comfirmation"
              value={isConfirm}
              onChange={(e) => setIsConfirm(e.target.checked)}
              label="Confirm you want to work with a different psychologist"
            />
          </div>
          <MaterialSelect
            value={reason}
            label="Please let us know why"
            optionList={DISCONNECTED_REASON}
            onChange={setReason}
            isSearchable={false}
            menuPosition={'top'}
          />
          <ButtonRecharge status={status} disabled={!isConfirm || !reason} onClick={handleConfirm}>
            Confirm Change
          </ButtonRecharge>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDisconnectModal;
