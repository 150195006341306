import styles from './CaWCompletePage.module.scss';
import classNames from 'classnames';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useCawUserSession } from 'CaW/pages/SignUp/helper/userSession';
import { useFetchClinicianDetails } from 'CaW/pages/ClinicianDetails/hooks/getClinicianDetails';
import { useReserveAppointmentData, useSetReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { useNavigate } from 'react-router-dom';
import { useCaWRoutesGenerator } from 'CaW/utils/Path/CaWRoutesGenerator';
import { useAuth0 } from '@auth0/auth0-react';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import CaWContentLayout from '../../components/CaWContentLayout/CaWContentLayout';

const CaWCompletePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { HOME } = useCaWRoutesGenerator();
  const { clearUserSession, retrieveUserSession } = useCawUserSession();
  const user = retrieveUserSession()?.clientRecord?.clientProfiles?.[0];
  const { clinicianId } = useReserveAppointmentData();
  const { practitionerDetails } = useFetchClinicianDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  const handleGoToAccount = () => {
    removeReserveAppointmentData();
    clearUserSession();
    navigate(HOME);
  };

  return (
    <CaWHelmetWrapper title={'Caraniche at Work - welcome'}>
      <div className={classNames(styles.container, 'caw-theme')}>
        <CaWHeader withPadding withMenu blueMenu />
        <CaWContentLayout>
          <div className={styles.content}>
            <div className={styles.title}>YOU’RE ALL SET</div>
            <div className={styles.welcome}>
              <div>
                <div className={styles.welcomeTitle}>
                  {isAuthenticated ? 'Hi' : 'Welcome'} {user?.name}
                </div>
                {practitionerDetails.helmControl?.welcomeNote && (
                  <div className={styles.message}>{practitionerDetails.helmControl.welcomeNote}</div>
                )}
                <div className={styles.messageEnding}>
                  Best Regards,
                  <br />
                  <div className={styles.clinicianName}>{clinicianName}</div>
                </div>
              </div>
              <div className={styles.avatar}>
                <img src={clinicianAvatar} alt={'Avatar'} />
              </div>
            </div>

            <div className={styles.whatNext}>
              {!isAuthenticated && (
                <>
                  <div className={styles.label}>You can now access your secure account.</div>
                  <div className={styles.radioLabel}>
                    Here we will post notifications and requests for you to complete forms or assessments from time to
                    time. If something new is requested we will also send you an email to let you know.
                  </div>
                  <div className={styles.radioLabel}>
                    You can also manage all your appointments here directly should you need to make a change.
                  </div>
                </>
              )}
              <ButtonCaW onClick={handleGoToAccount} className={styles.goToMyAccountBtn}>
                Go to my account
              </ButtonCaW>
            </div>
          </div>
        </CaWContentLayout>
        <TacklitFooter />
      </div>
    </CaWHelmetWrapper>
  );
};

export default CaWCompletePage;
