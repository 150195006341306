import { notification } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getMicroJournalEntries } from 'utils/http/checkIn';

export interface MicroJournalEntry {
  _id: string;
  date: string;
  title: string;
  response: string;
  tags?: Record<string, string>[];
}

const DATE_FORMAT = 'YYYY-MM-DD';

export const useFetchMicroJournalEntries = ({ token, dateRange }: { token?: string; dateRange?: number }) => {
  const [microJournalEntries, setMicroJournalEntries] = useState<MicroJournalEntry[]>([]);
  const [isMicroJournalEntriesLoading, setIsMicroJournalEntriesLoading] = useState(true);

  const fetchMicroJournalEntries = useCallback(async ({ token, dateRange }: { token: string; dateRange?: number }) => {
    setIsMicroJournalEntriesLoading(true);

    try {
      const startDate = dateRange
        ? moment().subtract(dateRange, 'days').format(DATE_FORMAT)
        : moment('2020-10-01').format(DATE_FORMAT);
      const endDate = moment().format(DATE_FORMAT);

      const callGetMicroJournalEntriesByPatientId = await getMicroJournalEntries({
        token,
        queryParam: { startDate, endDate }
      });
      const response = await callGetMicroJournalEntriesByPatientId.json();

      if (Array.isArray(response.entries)) {
        setMicroJournalEntries(response.entries);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get this your micro journal entries'
      });
    }

    setIsMicroJournalEntriesLoading(false);
  }, []);

  const refetch = (dateRange?: number) => {
    if (token) {
      fetchMicroJournalEntries({ token, dateRange });
    }
  };

  useEffect(() => {
    if (token) {
      fetchMicroJournalEntries({ token, dateRange });
    }
  }, [fetchMicroJournalEntries, dateRange, token]);

  return { microJournalEntries, isMicroJournalEntriesLoading, fetchMicroJournalEntries: refetch };
};
