import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Loading/Loading';
import TacklitFooter from '../../../../components/TacklitFooter/TacklitFooter';
import { PARTNER_HELM_SIGN_UP_USER, retrieveUserSession } from 'helm/pages/CreateProfile/helper/userSession';
import { useFetchHelmReservedAppointmentsWithTimeZone } from 'helm/utils/hooks/appointment';
import {
  reservedHelmPartnerReferral,
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import HelmContentLayout from '../../../components/HelmContentLayout/HelmContentLayout';
import HelmHeader from '../../../components/HelmHeader/HelmHeader/HelmHeader';
import HelmHelmetWrapper from '../../../components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useFetchPractitionerDetails } from '../../../pages/PractitionerDetails/hooks/getPractitionerDetails';
import { useHelmPartnerRoutesGenerator } from '../../utils/Path/HelmPartnerRoutesGenerator';
import styles from './PartnerConfirmPage.module.scss';
import ButtonHelm from '../../../components/ButtonHelm/ButtonHelm';
import { AU_TIME_ZONE_LIST, helmTimeZoneLocalStorage } from 'utils/constants/timeZone';

const HelmPartnerConfirmPage: FC = () => {
  const navigate = useNavigate();
  const user = retrieveUserSession(PARTNER_HELM_SIGN_UP_USER)?.clientRecord?.clientProfiles?.[0];
  const clientTimeZone = localStorage.getItem(helmTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const { PRACTITIONER } = useHelmPartnerRoutesGenerator();
  const { reserveId, accountId, clinicianId } = useReserveAppointmentData(reservedHelmPartnerReferral);
  const { practitionerDetails } = useFetchPractitionerDetails(clinicianId!);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData(reservedHelmPartnerReferral);
  const { fetching } = useFetchHelmReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const clinicianName = practitionerDetails.name;
  const clinicianAvatar = practitionerDetails.avatar;

  const handleGoToAccount = () => {
    removeReserveAppointmentData();
    navigate(PRACTITIONER.LISTING);
  };
  return (
    <HelmHelmetWrapper title={'Helm - Confirmed'}>
      <div className={classNames(styles.container, 'helm-theme')}>
        <HelmHeader withPadding withMenu noLogin />
        <HelmContentLayout>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={styles.box}>
              <div className={styles.content}>
                <div className={styles.avatar}>
                  <img src={clinicianAvatar} alt={'Avatar'} />
                </div>
                <div className={styles.badge}>APPOINTMENT CONFIRMED</div>
                <div className={styles.title}>
                  The appointment for {user?.name} with {clinicianName} has been confirmed.
                </div>
                <div className={styles.desc}>
                  The client will receive confirmation of their appointment booking by Email and/or SMS shortly
                  <br />
                  <br />
                  They will also receive information on what to expect.
                </div>
              </div>
              <div className={styles.closeButtonWrapper}>
                <ButtonHelm onClick={handleGoToAccount} className={styles.closeButton}>
                  Close this session
                </ButtonHelm>
              </div>
            </div>
          )}
        </HelmContentLayout>
        <TacklitFooter />
      </div>
    </HelmHelmetWrapper>
  );
};

export default HelmPartnerConfirmPage;
