import { useMemo } from 'react';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import { scrollToView } from 'utils/scrollToView';
import { useFetchP2CGamblingDetails } from './hooks/useFetchP2CGamblingDetails';
import styles from './P2CGambling.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import YourProgram from './components/YourProgram/YourProgram';
import HeaderContent from './components/HeaderContent/HeaderContent';
import WhatToExpect from './components/WhatToExpect/WhatToExpect';
import YourGoals from './components/YourGoals/YourGoals';
import { getActiveChapterData } from './constants';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';

const P2CGambling = () => {
  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const { p2cGamblingDetails, isP2CGamblingDetailsLoading } = useFetchP2CGamblingDetails(
    clientRecordId,
    clientProfileId
  );

  const currentChapterData = useMemo(() => {
    if (!isP2CGamblingDetailsLoading && p2cGamblingDetails) {
      return getActiveChapterData(p2cGamblingDetails);
    }
  }, [p2cGamblingDetails, isP2CGamblingDetailsLoading]);

  const handleToNextActivity = () => {
    scrollToView('yourProgram', true, false, true);
  };

  return (
    <HelmHelmetWrapper title={`HELM - Power to Change Program`}>
      <div className={styles.container}>
        <HelmContentLayout className={styles.headerContainer}>
          <HelmHeader classNames={styles.desktopHeader} whiteFont whiteLogo withPadding withMenu homeLink />
          <HelmHeader
            classNames={styles.mobileHeader}
            logoWithoutText
            whiteFont
            whiteLogo
            withPadding
            withMenu
            homeLink
          />
        </HelmContentLayout>
        <HeaderContent
          currentChapter={currentChapterData?.activeChapter}
          isBeforeFirstSession={currentChapterData?.isBeforeFirstSession}
          isLoading={isP2CGamblingDetailsLoading}
          onClickToNextActivity={handleToNextActivity}
        />
        {p2cGamblingDetails && (
          <>
            {currentChapterData?.isBeforeFirstSession ? <WhatToExpect /> : <YourGoals data={p2cGamblingDetails} />}
            <YourProgram
              data={p2cGamblingDetails}
              activeChapter={currentChapterData?.activeChapter}
              isPreActivityExistOrCompleted={currentChapterData?.isPreActivityExistOrCompleted}
            />
          </>
        )}
      </div>
    </HelmHelmetWrapper>
  );
};

export default P2CGambling;
