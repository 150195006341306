import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import styles from './CompletedTaskBox.module.scss';

const CompletedTaskBox = () => {
  const { SomeoneHealthFindGPURL } = someoneHealthEnvironment();

  const redirectToGP = () => {
    window.location.href = SomeoneHealthFindGPURL;
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>GP Pre-session Form</div>
          <div className={styles.status}>COMPLETED</div>
        </div>
        <div className={styles.iconContainer}>
          <i className={`material-icons ${styles.successIcon}`}>done</i>
        </div>
      </div>
      <div className={styles.description}>
        <div>Thank you. You can now book with one of our friendly GPs for a 20 minute online appointment.</div>
        <div>They will help you with a referral and mental health plan.</div>
      </div>
      <ButtonSH className={styles.button} onClick={redirectToGP} icon="navigate_next" iconPostFix>
        Book a GP appointment
      </ButtonSH>
    </div>
  );
};

export default CompletedTaskBox;
