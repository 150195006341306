export const DOD_PATH = '/dod';

export const useRechargeRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;
  const initPathWithDoDPath = `${initPath}${DOD_PATH}`;

  return {
    // DOD engage paths
    DOD: {
      PRACTITIONER: {
        LISTING: `${initPathWithDoDPath}/psychologists`,
        LISTING_WITH_FILTER: `${initPathWithDoDPath}/psychologists/:filter`,
        DETAILS: `${initPathWithDoDPath}/psychologists-profile/:clinicianId`
      },
      SIGNUP: {
        BASE: `${initPathWithDoDPath}/signup`,
        EMERGENCY_CONTACT: `${initPathWithDoDPath}/emergency-contact`,
        WELCOME_BOOKING: `${initPathWithDoDPath}/welcome`
      }
    },

    // Regular engage paths
    PRACTITIONER: {
      LISTING: `${initPath}/psychologists`,
      LISTING_WITH_FILTER: `${initPath}/psychologists/:filter`,
      DETAILS: `${initPath}/psychologists-profile/:clinicianId`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      EMERGENCY_CONTACT: `${initPath}/emergency-contact`,
      WELCOME_BOOKING: `${initPath}/welcome`
    },

    // others
    LOGIN: '/login',
    INVOICE: {
      VIEW: `${initPath}/invoice/:invoiceId`
    },
    REPORT: {
      VIEW: `${initPath}/report/:reportId`
    },
    LETTER: {
      VIEW: `${initPath}/accounts/:accountId/letters/:letterId`
    },
    AVATAR: `${initPath}/avatar`,
    UPDATE_PROFILE: `${initPath}/profile`,
    WAITLIST: `${initPath}/waitlist`,
    HOME: '/',
    PENDING_ACTIONS: `${initPath}/pending-actions`,
    APPOINTMENT_OVERVIEW: `${initPath}/appointment/overview`,
    MY_PSYCHOLOGIST: `${initPath}/my-psychologist`,
    CONSENT: {
      BASE: `${initPath}/consent`,
      DETAILS: `${initPath}/consent/:consentFormId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    ONBOARDING: {
      BASE: `${initPath}/onboarding`,
      DETAILS: `${initPath}/onboarding/:surveyId/:type`
    },
    PSYCHOMETRIC: {
      BASE: `${initPath}/psychometrics`,
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    REFERRAL: `${initPath}/referral`,
    CHECKIN: `${initPath}/:clinicianSlugUrlOrId/checkin`
  };
};
