import { Link, useParams } from 'react-router-dom';

import { useFetchClinician } from 'utils/hooks/clinician';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCard/PatientFooterCard';

import styles from './AppointmentEntryPage.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

const AppointmentEntryPage = () => {
  const { clinicianSlugUrlOrId = '' } = useParams<{ clinicianSlugUrlOrId: string }>();
  const { clinician, isClinicianLoading } = useFetchClinician(clinicianSlugUrlOrId);
  const [t] = useTranslation();

  const { user } = useAuth0();
  const isLoggedInAsClinician = user?.['https://tacklit.com/roles'].includes('clinician');

  return (
    <PatientContentLayout>
      {isClinicianLoading || !clinician ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div className={styles.imageWrapper}>
                <img className={styles.image} src={clinician.avatar} alt="Clinician Profile" />
              </div>
              <div className={styles.name}>
                {clinician.title} {clinician.name}
              </div>
              <div className={styles.desc}>{clinician.bio}</div>
              <div className={styles.specialistWrapper}>
                <div className={styles.specialistTitle}>SPECIALISATION:</div>
                <div className={styles.specialist}>
                  {clinician.specialisations?.map((specialisation, index) => (
                    <div className={styles.specialistItem} key={index}>
                      {specialisation}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <PatientFooterCard>
            <div className={styles.text}>
              View availability and schedule a session with{' '}
              <strong>
                {clinician.title} {clinician.name}
              </strong>
            </div>
            <div className={styles.wrapper}>
              <Link
                className={isLoggedInAsClinician ? styles.noClickButton : styles.button}
                to={`/${clinicianSlugUrlOrId}/appointment`}
              >
                {t('label.iam_existing_client')}
              </Link>
            </div>
            {clinician.edge ? (
              <>
                <div className={styles.middleText}>or</div>
                <div>
                  <Link className={styles.button} to={`/${clinicianSlugUrlOrId}/appointment/new`}>
                    {t('label.iam_new_client')}
                  </Link>
                </div>
              </>
            ) : (
              <></>
            )}
          </PatientFooterCard>
        </>
      )}
    </PatientContentLayout>
  );
};

export default AppointmentEntryPage;
