import { useState, UIEvent, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import tacklitLogo from 'assets/images/tacklit-logo-blue.svg';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import Button from 'components/v2/Button/Button';
import Loading from 'components/Loading/Loading';
import { useFetchInvitedClinicianDetails } from 'pages/SignUp/hooks/getInvitedClinicianDetails';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';

import styles from './PolicyPage.module.scss';
import CaWPrivacy from './components/CaWPrivacy';
import { config } from 'config/config';

const PolicyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [enableBtn, setEnableBtn] = useState(false);
  const { clinicianSlugUrlOrId = '' } = useParams() as { clinicianSlugUrlOrId: string };
  const { SIGNUP } = useRoutesGenerator(clinicianSlugUrlOrId);
  const { invitedClinicianDetail, isInvitedCLoading } = useFetchInvitedClinicianDetails(clinicianSlugUrlOrId);

  const handleNextPath = () => {
    if (enableBtn) {
      Cookies.set('signUpPolicy', 'true', { expires: 1 });
      navigate(`${SIGNUP.NEW}${location.search}`);
    }
  };

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    const scrollTillBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <= e.currentTarget.clientHeight + 1;
    setEnableBtn(scrollTillBottom);
  };

  useEffect(() => {
    // CaW collection notice is too short in desktop
    if (invitedClinicianDetail.isKickStart) {
      setEnableBtn(true);
    }
  }, [invitedClinicianDetail.isKickStart]);

  return isInvitedCLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <ContentLayout className={styles.body}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {(invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar) && (
              <img
                className={styles.logoWrapper}
                src={invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar}
                alt={'practiceLogo'}
              />
            )}
            <div className={styles.practiceName}>
              {invitedClinicianDetail.practice?.name || invitedClinicianDetail.name}
            </div>
          </div>
          <div className={styles.workingWithWrapper}>
            <div className={styles.label}>working securely with</div>
            <img className={styles.tacklitLogo} src={tacklitLogo} alt={'tacklitLogo'} />
          </div>
        </div>
        <div className={styles.policyContainer} onScroll={(e) => handleScroll(e)}>
          {invitedClinicianDetail.isKickStart ? <CaWPrivacy /> : config.terms.privacy}
        </div>
        <div className={styles.buttonContainer}>
          <Button disabled={!enableBtn} className={styles.agreeBtn} onClick={() => handleNextPath()}>
            Agree and continue
          </Button>
        </div>
      </ContentLayout>
    </div>
  );
};

export default PolicyPage;
