export const useHelmPartnerRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    TOKEN: {
      BASE: `${initPath}/login`
    },
    PRACTITIONER: {
      LISTING: `${initPath}/counsellors`,
      LISTING_WITH_FILTER: `${initPath}/counsellors/:filter`,
      DETAILS: `${initPath}/counsellors-profile/:clinicianId`
    },
    SIGNUP: {
      BASE: `${initPath}/signup`,
      FUNDING_STREAM: `${initPath}/funding`,
      SUMMARY_BOOKING: `${initPath}/summary`,
      CONFIRM_BOOKING: `${initPath}/confirmed`
    },
    WAITLIST: `${initPath}/waitlist`
  };
};
