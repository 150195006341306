import styles from './CalendarBox.module.scss';
import moment, { Moment } from 'moment';

interface CalendarBoxProps {
  date: string | Date | Moment;
  label?: string;
  desc?: string;
  center?: boolean;
}

const CalendarBox = ({ date, label, desc, center }: CalendarBoxProps) => {
  const dateFormat = moment(date).format('DD');
  const month = moment(date).format('MMM');

  return (
    <div className={center ? styles.containerCenter : styles.container}>
      <div className={styles.calendarBadge}>
        <div className={styles.calendarContent}>
          <div className={styles.date}>{dateFormat}</div>
          <div className={styles.month}>{month}</div>
        </div>
      </div>
      {(label || desc) && (
        <div className={styles.dayWrapper}>
          {label && <div className={styles.day}>{label}</div>}
          {desc && <div className={styles.remainingDay}>{desc}</div>}
        </div>
      )}
    </div>
  );
};

export default CalendarBox;
