import { ReactNode } from 'react';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import FormikInput from 'components/Input/FormikInput';
import FormikSelect from 'components/Select/FormikSelect';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import { PHN_PROVIDER } from '../../constants';
import styles from './SHPartnerCreateProfileForm.module.scss';

interface SHCreateProfileFormProps {
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  caseIdErrorMsg?: ReactNode | string;
  setCaseIdErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
}

const SHPartnerCreateProfileForm = ({
  emailErrorMsg,
  mobileNumberErrorMsg,
  caseIdErrorMsg,
  setCaseIdErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg
}: SHCreateProfileFormProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Client Information</div>
      <div>
        <FormikInput
          inputClass={styles.customInput}
          label="First Name*"
          name="firstName"
          required
          labelClass={styles.inputLabel}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Last Name*"
          name="lastName"
          required
          labelClass={styles.inputLabel}
        />
        <FormikDatePicker
          inputClass={styles.customInput}
          label="Date of birth*"
          name="dateOfBirth"
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
        />
        <HelmMobileFormikInput
          inputClass={styles.customInput}
          id={`mobileNumber`}
          name={'mobileNumber'}
          label={'Mobile number'}
          mobilePattern="____ ___ ___"
          placeholder={'0482 555 444'}
          onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
          phonePrefix=""
          labelClass={styles.inputLabel}
          externalError={mobileNumberErrorMsg}
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Email address"
          name="email"
          externalError={emailErrorMsg}
          onChange={() => emailErrorMsg && setEmailErrorMsg()}
          labelClass={styles.inputLabel}
          required
        />
        <FormikInput
          inputClass={styles.customInput}
          label="Unique ID"
          name="caseId"
          externalError={caseIdErrorMsg}
          onChange={() => caseIdErrorMsg && setCaseIdErrorMsg()}
          labelClass={styles.inputLabel}
          required
        />
        <FormikSelect
          className={styles.customSelect}
          labelClass={styles.selectInputLabel}
          label={'Provider Details'}
          placeholder={'Select Provider Details'}
          isSearchable={false}
          name="providerDetails"
          options={PHN_PROVIDER}
          styles={{
            container: (base) => ({
              ...base,
              width: '100%'
            }),
            control: (controlBase) => ({
              ...controlBase,
              minHeight: '30px',
              backgroundColor: 'transparent',
              border: 'none',
              borderRadius: 0,
              boxShadow: 'none'
            }),
            singleValue: (singleValueBase) => ({
              ...singleValueBase,
              margin: 0
            }),
            placeholder: (placeholderBase) => ({
              ...placeholderBase,
              margin: 0
            }),
            valueContainer: (base) => ({ ...base, paddingLeft: 0 })
          }}
        />
      </div>
    </div>
  );
};

export default SHPartnerCreateProfileForm;
