import styles from './WelcomeMessage.module.scss';
import parse from 'html-react-parser';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import { useAuth0 } from '@auth0/auth0-react';
import DefaultWelcomeMessage from './DefaultWelcomeMessage';

interface WelcomeMessageProps {
  clinicianDetail: GetAttachedClinicianDetails;
  completedCheckInStatus: boolean;
  clinicianName: string;
  className?: string;
}

const WelcomeMessage = ({ clinicianDetail, completedCheckInStatus, clinicianName, className }: WelcomeMessageProps) => {
  const { user } = useAuth0();
  const clientName = user?.name || '';
  const customiseWelcomeMessage = clinicianDetail?.clinician?.customisations;

  const getDescription = () => {
    if (!completedCheckInStatus && customiseWelcomeMessage?.checkIn?.firstCheckInDescription) {
      return parse(
        customiseWelcomeMessage.checkIn.firstCheckInDescription
          .replace(/\$CLIENT_NAME/g, clientName)
          .replace(/\$CLINICIAN_NAME/g, `${clinicianName}`)
      );
    } else if (customiseWelcomeMessage?.checkIn?.checkInDescription) {
      return parse(customiseWelcomeMessage?.checkIn.checkInDescription.replace(/\$CLIENT_NAME/g, clientName));
    }
  };

  return (
    <div className={styles.container}>
      {customiseWelcomeMessage ? (
        getDescription()
      ) : (
        <DefaultWelcomeMessage clientName={clientName} className={className} />
      )}
    </div>
  );
};

export default WelcomeMessage;
