import classnames from 'classnames';
import FlexBox from 'components/FlexBox/FlexBox';
import styles from './InvoiceItemSection.module.scss';
import { Invoice, ItemColumns } from 'interfaces/Invoice/Invoice';
import AppointmentTags from '../AppointmentTags/AppointmentTags';
import { config } from 'config/config';

const InvoiceItemSection = ({ invoice }: { invoice: Invoice }) => {
  const MONEY_SYMBOL = config.currencySymbol;
  const TAX_LABEL = config.taxLabel;

  const showQuantity = !invoice.amountChanged && invoice.template?.itemColumns.includes(ItemColumns.Quantity);
  const showUnitPrice = !invoice.amountChanged && invoice.template?.itemColumns.includes(ItemColumns.UnitPrice);
  const showTaxRate = !invoice.amountChanged && invoice.template?.itemColumns.includes(ItemColumns.TaxRate);

  const totalQuantity = invoice.items.reduce((finalTotal, item) => finalTotal + Number(item.quantity), 0);
  const totalCost = invoice.items.reduce((finalTotal, item) => finalTotal + Number(item.cost), 0).toFixed(2);
  const totalTax = invoice.items.reduce((finalTotal, item) => finalTotal + Number(item.tax), 0).toFixed(2);
  const totalPrice = invoice.items.reduce((finalTotal, item) => finalTotal + Number(item.total), 0);
  const showItemTotal = !invoice.amountChanged;

  const getHeaders = () => {
    const headers = [
      { label: '#', key: 'index' },
      { label: 'OVERVIEW', key: 'overview' }
    ];

    if (showQuantity) {
      headers.push({
        label: ItemColumns.Quantity.toUpperCase(),
        key: ItemColumns.Quantity
      });
    }

    if (showUnitPrice) {
      headers.push({
        label: ItemColumns.UnitPrice.split(/(?=[A-Z])/)
          .join(' ')
          .toUpperCase(),
        key: ItemColumns.UnitPrice
      });
    }

    if (showTaxRate) {
      headers.push({
        label: `${TAX_LABEL} RATE`,
        key: ItemColumns.TaxRate
      });
    }

    if (showItemTotal) {
      headers.push({
        label: `${ItemColumns.Total.toUpperCase()} (${MONEY_SYMBOL})`,
        key: ItemColumns.Total
      });
    }

    return headers;
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th />
            {getHeaders().map(({ label, key }) => (
              <th
                key={key}
                className={
                  key === 'index'
                    ? styles.noPadding
                    : key === ItemColumns.Quantity ||
                      key === ItemColumns.UnitPrice ||
                      key === ItemColumns.TaxRate ||
                      key === ItemColumns.Total
                    ? styles.width110
                    : undefined
                }
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invoice.items.map((item, index) => (
            <tr key={`invoice-item-${index}`}>
              <td className={styles.noPadding} />
              <td className={styles.noPadding}>{index + 1}</td>
              <td>
                <FlexBox direction="column">
                  {item.overview && <span className={styles.title}>{item.overview}</span>}
                  {item.description}
                  {item.tags && item.tags.length > 0 && <AppointmentTags tags={item.tags} />}
                </FlexBox>
              </td>
              {showQuantity && <td>{item.quantity}</td>}
              {showUnitPrice && (
                <td>
                  {MONEY_SYMBOL}
                  {Number(item.cost).toFixed(2)}
                </td>
              )}
              {showTaxRate && (
                <td>
                  {MONEY_SYMBOL}
                  {Number(item.tax).toFixed(2)}
                </td>
              )}
              {showItemTotal && (
                <td>
                  {MONEY_SYMBOL}
                  {Number(item.total).toFixed(2)}
                </td>
              )}
            </tr>
          ))}
          {!invoice.amountChanged && invoice.discount && (
            <tr>
              <td colSpan={2} />
              <td className={styles.title}>Discount</td>
              <td colSpan={3} />
              <td>
                -{MONEY_SYMBOL}
                {(Number(totalPrice) - Number(invoice.invoiceAmount)).toFixed(2)}
              </td>
            </tr>
          )}
          <tr className={styles.noBorder}>
            <td colSpan={2} />
            <td align="right" className={styles.totalLabel}>
              TOTAL
            </td>
            {showQuantity && <td className={styles.dashedBorderTop}>{totalQuantity}</td>}
            {showUnitPrice && (
              <td className={styles.dashedBorderTop}>
                {MONEY_SYMBOL}
                {totalCost}
              </td>
            )}
            {showTaxRate && (
              <td className={styles.dashedBorderTop}>
                {MONEY_SYMBOL}
                {totalTax}
              </td>
            )}
            <td className={classnames(styles.dashedBorderTop, styles.totalPrice)}>
              {MONEY_SYMBOL}
              {Number(invoice.invoiceAmount).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItemSection;
