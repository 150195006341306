export const useCORDSRoutesGenerator = () => {
  const firstPath = window.location.pathname.split('/')[1];
  const initPath = firstPath !== 'p' ? '' : `/${firstPath}`;

  return {
    PSYCHOMETRIC: {
      DETAILS: `${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`
    },
    SURVEY: {
      BASE: `${initPath}/surveys`,
      DETAILS: `${initPath}/surveys/:surveyId`
    },
    CONSENT: {
      BASE: `${initPath}/facilitated/consent`,
      DETAILS: `${initPath}/facilitated/consent/:consentId`
    }
  };
};
