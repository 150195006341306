import { useState } from 'react';
import { Modal } from 'antd';
import { decode } from 'js-base64';
import * as queryString from 'query-string';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import Button from 'components/v2/Button/Button';
import WelcomeContent from './components/WelcomeContent/WelcomeContent';
import Loading from 'components/Loading/Loading';
import Header from 'layouts/NoAuthLayout/components/Header/Header';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { ClientSignUpQueryParamInterface } from '../SignUpForm/SignUpFormInterface';
import { useFetchInvitedClinicianDetails } from 'pages/SignUp/hooks/getInvitedClinicianDetails';

import styles from './WelcomePage.module.scss';

const filterNonNullParam = (obj: ClientSignUpQueryParamInterface) => {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
};

const WelcomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParam: ClientSignUpQueryParamInterface = queryString.parse(location.search);
  const { clinicianSlugUrlOrId = '' } = useParams() as { clinicianSlugUrlOrId: string };
  const [isExpandWelcomeModal, setExpandWelcomeModal] = useState(false);
  const clientName = queryParam?.firstname ? decode(queryParam?.firstname) : '';
  const { SIGNUP } = useRoutesGenerator(clinicianSlugUrlOrId);
  const { invitedClinicianDetail, isInvitedCLoading } = useFetchInvitedClinicianDetails(clinicianSlugUrlOrId);

  const massageQueryParam = {
    firstname: clientName,
    lastname: queryParam.lastname || '',
    dob: queryParam.dob || '',
    postcode: queryParam.postcode || '',
    mobile: queryParam.mobile || '',
    email: queryParam.email || ''
  };
  const qParam = queryString.stringify(filterNonNullParam(massageQueryParam));
  const handleNextPage = () => {
    navigate(`${SIGNUP.TASK}${qParam ? `?${qParam}` : ''}`);
  };

  return isInvitedCLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.container}>
      <ContentLayout className={styles.body}>
        <div className={styles.headerContainer}>
          <Header whiteLogo logoClassName={styles.logo} />
        </div>
        <div className={styles.content}>
          <div>
            <div className={styles.hello}>Hello</div>
            <div className={styles.desc}>
              As part of your work with <strong>{invitedClinicianDetail.practice?.name}</strong> you will have access to
              digital tools and documents via your free Tacklit account.
            </div>
          </div>
          <WelcomeContent
            clientName={clientName}
            clinicianAvatar={invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar || ''}
            clinicianName={invitedClinicianDetail.practice?.name || invitedClinicianDetail?.name || ''}
            welcomeMessage={invitedClinicianDetail.practice?.welcomeMessage || ''}
            withBox
          />
          <div className={styles.buttonWrapper}>
            <div className={styles.readMoreBtnWrapper}>
              <Button className={styles.readMoreBtn} onClick={() => setExpandWelcomeModal(true)}>
                READ MORE
              </Button>
            </div>
          </div>
        </div>
      </ContentLayout>
      <Modal
        bodyStyle={{ padding: 16 }}
        className={styles.expandModalContainer}
        visible={isExpandWelcomeModal}
        onCancel={() => setExpandWelcomeModal(false)}
        footer={null}
        destroyOnClose
      >
        <WelcomeContent
          clientName={clientName}
          clinicianAvatar={invitedClinicianDetail.practice?.logo || invitedClinicianDetail?.avatar || ''}
          clinicianName={invitedClinicianDetail.practice?.name || invitedClinicianDetail?.name || ''}
          welcomeMessage={invitedClinicianDetail.practice?.welcomeMessage || ''}
        />
        <div className={styles.modalButtonWrapper}>
          <Button className={styles.getStartedBtn} onClick={() => handleNextPage()}>
            GET STARTED
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WelcomePage;
