import { useAuth0 } from '@auth0/auth0-react';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';

import styles from './SurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';

const SurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <EaseContentLayout className={`${styles.container} ease-theme`}>
      <div className={styles.headerWrapper}>
        <EaseHeader withPadding withMenu homeLink greenMenu />
      </div>
      <div className={styles.contentWrapper}>
        <SurveyList />
      </div>
    </EaseContentLayout>
  );
};

export default SurveyListWrapper;
