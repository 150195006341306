import { notification } from 'antd';
import { formatGraphData } from 'helm/utils/formatGraphData';
import { ChartData } from 'interfaces/MicroJournal/Chart';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getCheckInInsights } from 'utils/http/checkIn';

export const useFetchSleepCheckInGraph = ({ token, dateRange = 30 }: { token: string; dateRange?: number }) => {
  const [sleepCheckInGraph, setSleepCheckInGraph] = useState<ChartData['insights']>([]);
  const [isSleepCheckInGraphLoading, setIsSleepCheckInGraphLoading] = useState(true);

  const fetchSleepCheckInGraph = useCallback(async ({ token, dateRange }: { token: string; dateRange: number }) => {
    setIsSleepCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights({
        token,
        queryParam: { startDate, endDate, type: 'sleep' }
      });
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setSleepCheckInGraph(formatGraphData(insights));
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your sleeps tracking'
      });
    }

    setIsSleepCheckInGraphLoading(false);
  }, []);

  const refetch = (dateRange: number) => {
    if (token) {
      fetchSleepCheckInGraph({ token, dateRange });
    }
  };

  useEffect(() => {
    if (token) {
      fetchSleepCheckInGraph({ token, dateRange });
    }
  }, [fetchSleepCheckInGraph, dateRange, token]);

  return {
    sleepTitle: 'How have you slept the last few nights?',
    sleepCheckInGraph,
    isSleepCheckInGraphLoading,
    fetchSleepCheckInGraph: refetch
  };
};
