import * as yup from 'yup';
import { PersonalisationErrorInterface } from '../../../SignUpFormInterface';

export interface PersonalisationInterface {
  nickname: string;
  avatar: string;
}

const personaliseFormSchema = yup.object().shape({
  nickname: yup.string().required('Please enter your preferred name'),
  avatar: yup.string().required('Please choose an avatar')
});

export const validationPersonaliseForm = (personaliseFormVal?: PersonalisationInterface) => {
  const validationErrors = {
    nickname: '',
    avatar: ''
  } as PersonalisationErrorInterface;
  try {
    personaliseFormSchema.validateSync(personaliseFormVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.map((error: any) => {
        if (error.path) {
          // @ts-ignore
          validationErrors[error.path] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};
