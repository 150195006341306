import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './DateInput.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import classNames from 'classnames';
import moment from 'moment';

export enum DateFormat {
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  DAY_MONTH_YEAR = 'DD/MM/YYYY'
}

interface DateInputProps {
  id?: string;
  label: string;
  value: string;
  error?: string;
  format?: DateFormat;
  onChange: (value: string) => void;
  onError?: (hasError: boolean) => void;
  emptyErrorMessage?: string;
  checkValidation?: boolean;
}

const getSeparatedDate = (date: string, format: DateFormat) => {
  const dateDayjs = moment(date, format);

  return (
    dateDayjs.isValid() &&
    dateDayjs.format(format) === date && {
      day: dateDayjs.format('DD'),
      month: dateDayjs.format('MM'),
      year: dateDayjs.format('YYYY')
    }
  );
};

const getCombinedDate = (day: string, month: string, year: string, format: DateFormat) => {
  if (format === DateFormat.DAY_MONTH_YEAR) {
    const dateParts = [day, month, year].filter(Boolean);
    return dateParts.join('/'); // Join with '/' 'dd/mm/yyyy'
  }

  if (format === DateFormat.YEAR_MONTH_DAY) {
    const dateParts = [year, month, day].filter(Boolean);
    return dateParts.join('-'); // Join with '-' 'yyyy-mm-dd'
  }

  // this is here because of typescript
  // how can it even reach here?
  return '';
};

const DateInput = ({
  id,
  label,
  value,
  error: propsError,
  format = DateFormat.YEAR_MONTH_DAY,
  onChange,
  emptyErrorMessage,
  checkValidation = true
}: DateInputProps) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [dayError, setDayError] = useState('');
  const [monthError, setMonthError] = useState('');
  const [yearError, setYearError] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const separatedDate = getSeparatedDate(value, format);

    if (separatedDate) {
      setDay(separatedDate.day);
      setMonth(separatedDate.month);
      setYear(separatedDate.year);
    }
  }, [format, value]);

  const validateDate = useCallback(() => {
    const combinedDate = getCombinedDate(day, month, year, format);

    const parsedDayjs = moment(combinedDate, format);

    if (combinedDate === parsedDayjs.format(format)) {
      setDayError('');
      setMonthError('');
      setYearError('');
      setError('');
    } else {
      const dayNumber = Number(day);
      const monthNumber = Number(month);

      const isMonthError = month.length === 2 && (monthNumber < 1 || monthNumber > 12);
      const isYearError = year.length > 0 && year.length < 4;

      const correctDaysInMonth = moment()
        .year(Number(year))
        // month starts at 0
        .month(monthNumber - 1)
        .daysInMonth();

      if (
        day.length === 2 &&
        !isYearError &&
        !isMonthError &&
        ((parsedDayjs.isValid() && dayNumber > correctDaysInMonth) || dayNumber < 1 || dayNumber > 31)
      ) {
        setDayError(`Date must be 01-${correctDaysInMonth || 31}`);
      } else {
        setDayError('');
      }

      if (isMonthError) {
        setMonthError('Month must be 01-12');
      } else {
        setMonthError('');
      }

      if (isYearError) {
        setYearError('Year must be in YYYY format');
      } else {
        setYearError('');
      }

      if (!day && !month && !year) {
        setError(emptyErrorMessage || 'Please enter date');
      } else {
        setError('Invalid date');
      }
    }

    return combinedDate;
  }, [day, format, month, year, emptyErrorMessage]);

  useEffect(() => {
    const combinedDate = validateDate();
    combinedDate !== value && onChange(combinedDate);
  }, [onChange, validateDate, value]);

  const handleDayBlur = () => {
    if (day && day.length < 2 && !isNaN(Number(day))) {
      setDay(day.padStart(2, '0'));
    }
  };

  const handleMonthBlur = () => {
    if (month && month.length < 2 && !isNaN(Number(month))) {
      setMonth(month.padStart(2, '0'));
    }
  };

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const dayValue = e.target.value.replace(/\D/g, '');
    setDay(dayValue);
    setTimeout(() => {
      if (dayValue.length === 2) {
        const monthField = document.getElementById(`${id}-month-field`) as HTMLInputElement;
        monthField?.focus();
      }
    }, 100);
  };
  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const monthValue = e.target.value.replace(/\D/g, '');
    setMonth(monthValue);

    // Auto jump to year field when month is filled
    setTimeout(() => {
      if (monthValue.length === 2) {
        const yearField = document.getElementById(`${id}-year-field`) as HTMLInputElement;
        yearField?.focus();
      }
    }, 100);

    // Jump back to day field if month is emptied
    setTimeout(() => {
      if (monthValue.length === 0) {
        const dayField = document.getElementById(`${id}-day-field`) as HTMLInputElement;
        dayField?.focus();
      }
    }, 100);
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    const yearValue = e.target.value.replace(/\D/g, '');
    setYear(yearValue);

    // Jump back to month field if year is emptied
    setTimeout(() => {
      if (yearValue.length === 0) {
        const monthField = document.getElementById(`${id}-month-field`) as HTMLInputElement;
        monthField?.focus();
      }
    }, 100);
  };

  const showBaseError = !(dayError || monthError || yearError) && !!(error || propsError);

  return (
    <div className={classNames(styles.container, showBaseError && checkValidation && styles.error)} id={id}>
      <label className={styles.label} htmlFor={`${id}-day-field`}>
        {label}
      </label>
      <div className={styles.inputRow}>
        <input
          className={classNames(
            styles.input,
            styles.day,
            (!!dayError || (!day && error)) && checkValidation && styles.error
          )}
          id={`${id}-day-field`}
          placeholder="DD"
          value={day}
          maxLength={2}
          onBlur={handleDayBlur}
          onChange={handleDayChange}
          autoComplete={'off'}
          type={'tel'}
        />
        <div className={styles.separator}>/</div>
        <input
          className={classNames(
            styles.input,
            styles.month,
            (!!monthError || (!month && error)) && checkValidation && styles.error
          )}
          id={`${id}-month-field`}
          placeholder="MM"
          value={month}
          maxLength={2}
          onBlur={handleMonthBlur}
          onChange={handleMonthChange}
          autoComplete={'off'}
          type={'tel'}
        />
        <div className={styles.separator}>/</div>
        <input
          className={classNames(
            styles.input,
            styles.year,
            (!!yearError || (!year && error)) && checkValidation && styles.error
          )}
          id={`${id}-year-field`}
          placeholder="YYYY"
          value={year}
          maxLength={4}
          onChange={handleYearChange}
          autoComplete={'off'}
          type={'tel'}
        />
      </div>
      <ErrorMessage error={dayError} visible={!!dayError && checkValidation} />
      <ErrorMessage error={monthError} visible={!!monthError && checkValidation} />
      <ErrorMessage error={yearError} visible={!!yearError && checkValidation} />
      <ErrorMessage error={error || propsError} visible={showBaseError && checkValidation} />
    </div>
  );
};

export default DateInput;
