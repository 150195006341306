import styles from './SelectTimer.module.scss';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimeExpireAlert from 'components/TimeExpireAlert/TimeExpireAlert';
import {
  useReserveAppointmentData,
  useSetReserveAppointmentData
} from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';

interface SelectTimerProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
  reservedAppointmentId?: string;
}

const SelectTimer = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  hideTimer,
  onlyText,
  reservedAppointmentId
}: SelectTimerProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useSelectRoutesGenerator();
  const { clinicianId, reserveId } = useReserveAppointmentData(reservedAppointmentId);
  const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);

  const detailsPagePath = clinicianId
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId
      })
    : PRACTITIONER.LISTING;

  if (!reserveId) {
    navigate(detailsPagePath);
  }

  useEffect(() => {
    if (!isReservedAppointmentFetching && reservedAppointments.length < 1) {
      setShowExpiredAlert(true);
    } else {
      setShowExpiredAlert(false);
    }
  }, [isReservedAppointmentFetching, reservedAppointments]);

  return (
    <div className={classnames(hideTimer && styles.hidden)}>
      <CircularTimer
        startTime={reservedAppointments?.[0]?.updatedAt}
        onlyText={onlyText}
        timeLabelClass={classnames(onlyText && styles.timeLabel)}
        minutes={15}
        onExpire={() => {
          setShowExpiredAlert(true);
          removeReserveAppointmentData();
        }}
        className={styles.circularTimer}
        strokeColor={'#EE9C6C'}
      />
      <TimeExpireAlert
        modalClassname="select-theme"
        showExpiredAlert={showExpiredAlert}
        returnRoute={detailsPagePath}
      />
    </div>
  );
};

export default SelectTimer;
