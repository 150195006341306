import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

export const PARTNER_CODE = [
  ...(isDevelopmentENV()
    ? [
        {
          accessToken: '686868',
          partnerCode: 'WISE'
        }
      ]
    : []),
  {
    accessToken: 'WISE92',
    partnerCode: 'WISE'
  }
];
