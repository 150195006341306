import { ChangeEvent, useMemo, useState } from 'react';
import ContentLayout from 'components/ContentLayout/ContentLayout';
import AuthWhiteLayout from 'layouts/AuthWhiteLayout/AuthWhiteLayout';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { message, notification } from 'antd';
import CreatableSelect from '../Select/Select';
import Radio from '../Radio/Radio';
import medications from '../../pages/PatientSignUp/components/BasicBackground/medications.json';
import { debounce } from 'lodash';
import { StylesConfig } from 'react-select';
import { BackgroundFormErrorInterface } from './BackgroundFormInterface';
import { validationBgForm } from './validation/BackgroundDetailsValidation';

import styles from './BackgroundForm.module.scss';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import DateSelector from 'components/DatePicker/DateSelector';
import Button from 'components/v2/Button/Button';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import { useTranslation } from 'react-i18next';
import { IS_EASE_APP, IS_RECHARGE_APP, IS_SELECT_APP } from 'utils/hooks/AccountInfo/clientDetails';

export interface BackgroundFormInterface {
  summary: { isShow: boolean; content?: string };
  medication: {
    isShow: boolean;
    code: string;
    medications: {
      label: string;
      value: string;
    }[];
  };
  referral: {
    isShow: boolean;
    code: string;
    date?: string;
    name?: string;
    file?: {
      bucketName: string;
      fileName: string;
      fileUrl: string;
    };
  };
  therapist: {
    isShow: boolean;
    code: string;
    similarExperience?: string;
  };
}

interface BackgroundProp {
  clinicianDetail: GetAttachedClinicianDetails;
  backgroundResponse?: BackgroundFormInterface;
  pageSubmitStatus: '' | 'active' | 'finished';
  onCompleteBackground: (val: BackgroundFormInterface, file: File) => void;
  noHeader?: boolean;
  autoHeight?: boolean;
  title?: string;
  buttonText?: string;
}

// eslint-disable-next-line complexity
const BackgroundForm = ({
  clinicianDetail,
  backgroundResponse,
  pageSubmitStatus,
  onCompleteBackground,
  noHeader,
  autoHeight,
  title,
  buttonText
}: BackgroundProp) => {
  const [backgroundDetails, setBackgroundDetails] = useState(
    backgroundResponse || {
      summary: {
        isShow: true,
        content: ''
      },
      referral: {
        isShow: true,
        code: ''
      },
      medication: {
        isShow: true,
        code: '',
        medications: []
      },
      therapist: {
        isShow: true,
        code: ''
      }
    }
  );
  const [checkValidation, setCheckValidation] = useState(false);
  const [selectedFile, setSelectedFile] = useState({} as File);
  const [errorMessage, setErrorMessage] = useState({} as BackgroundFormErrorInterface);
  const [medicationSearch, setMedicationSearch] = useState('');
  const [isMedicationSearchLoading, setIsMedicationSearchLoading] = useState(false);
  const medicationOptions = useMemo(() => {
    let medicationOptions: any[] = [];
    if (medicationSearch.length >= 3) {
      medicationOptions = medications.data.filter((medication) =>
        medication.value.toLowerCase().includes(medicationSearch)
      );
    }
    setIsMedicationSearchLoading(false);
    return medicationOptions;
  }, [medicationSearch]);
  const [t] = useTranslation();

  const handleMedicationSearch = (value: string, { action }: { action: string }) => {
    const toLowerCaseValue = value.toLowerCase();
    if (action === 'input-change') {
      setIsMedicationSearchLoading(true);
      debounce(() => setMedicationSearch(toLowerCaseValue), 300)();
    }
  };

  const getMedicationsNoOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing to search for your medication';
    } else if (inputValue.length < 2) {
      return 'Search must be at least 3 letters long';
    } else if (isMedicationSearchLoading) {
      return 'Loading...';
    } else {
      return 'No options found';
    }
  };

  const handleChangeSummary = (val: string) => {
    const newMassageBgData = {
      ...backgroundDetails,
      summary: {
        ...backgroundDetails.summary,
        content: val
      }
    };
    setBackgroundDetails(newMassageBgData);
    if (checkValidation) {
      validateField(newMassageBgData);
    }
  };

  const handleChangeReferralCode = (val: string) => {
    const newMassageBgData = {
      ...backgroundDetails,
      referral: {
        ...backgroundDetails.referral,
        code: val
      }
    };
    setBackgroundDetails(newMassageBgData);
    if (checkValidation) {
      validateField(newMassageBgData);
    }
  };

  const handleChangeReferralValue = async (key: string, value: string) => {
    const newBgData = {
      ...backgroundDetails,
      referral: {
        ...backgroundDetails.referral,
        [key]: value
      }
    };
    setBackgroundDetails(newBgData);
    if (checkValidation) {
      validateField(newBgData);
    }
  };

  const handleChangeTherapist = async (key: string, value: string) => {
    const newBgData = {
      ...backgroundDetails,
      therapist: {
        ...backgroundDetails.therapist,
        [key]: value
      }
    };
    setBackgroundDetails(newBgData);
  };

  const handleChangeMedication = async (key: string, value: string) => {
    const newBgData = {
      ...backgroundDetails,
      medication: {
        ...backgroundDetails.medication,
        [key]: value
      }
    };
    setBackgroundDetails(newBgData);
  };

  const handleSelectMedication = async (value: any) => {
    const newBgData = {
      ...backgroundDetails,
      medication: {
        ...backgroundDetails.medication,
        medications: value
      }
    };
    setBackgroundDetails(newBgData);
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0) as File;

    if (file && file.size > 10 * 1024 * 1024) {
      message.error('File too big! Please select a file that is smaller than 10MB');
    } else {
      const newBgData = {
        ...backgroundDetails,
        referral: {
          ...backgroundDetails.referral,
          file: file as any
        }
      };
      setBackgroundDetails(newBgData);
      setSelectedFile(file);
      if (checkValidation) {
        validateField(newBgData);
      }
    }
  };

  const handleFileDelete = () => {
    setSelectedFile({} as File);
  };

  const validateField = (backgroundFormVal: BackgroundFormInterface) => {
    const validate = validationBgForm(backgroundFormVal as any);
    setErrorMessage(validate as BackgroundFormErrorInterface);
    return validate;
  };

  const validateSubmitField = async (backgroundFormVal: BackgroundFormInterface) => {
    const bgForm = validationBgForm(backgroundFormVal as any);
    setErrorMessage(bgForm as BackgroundFormErrorInterface);
    const checkFieldHaveError = Object.values(bgForm as BackgroundFormErrorInterface).some((value) => value !== '');

    return !checkFieldHaveError;
  };

  const handleSubmitBackground = async () => {
    setCheckValidation(true);
    if (await validateSubmitField(backgroundDetails)) {
      try {
        let result = {
          summary: backgroundDetails.summary,
          referral: {
            ...backgroundDetails.referral,
            ...(backgroundDetails.referral.code === 'yes' && {
              date: backgroundDetails.referral.date,
              name: backgroundDetails.referral.name,
              file: {}
            })
          },
          therapist: {
            ...backgroundDetails.therapist,
            ...(['currently', 'previously'].includes(backgroundDetails.therapist.code || '') && {
              similarExperience: backgroundDetails.therapist.similarExperience
            })
          },
          medication: {
            ...backgroundDetails.medication,
            ...(['currently', 'previously'].includes(backgroundDetails.medication.code || '') && {
              medications: backgroundDetails.medication.medications
            })
          }
        } as BackgroundFormInterface;
        onCompleteBackground(result, selectedFile);
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Background details fail to update',
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    }
  };

  const innerStyles: StylesConfig<any, any> = {
    valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
    ...(IS_EASE_APP && {
      multiValue: (base) => ({
        ...base,
        backgroundColor: '#adea5c',
        borderRadius: 25,
        color: '#333333',
        padding: 4
      })
    }),
    ...(IS_RECHARGE_APP && {
      multiValue: (base) => ({
        ...base,
        backgroundColor: '#806abe',
        borderRadius: 25,
        color: '#fff',
        padding: 4
      })
    }),
    ...(IS_SELECT_APP && {
      multiValue: (base) => ({
        ...base,
        backgroundColor: '#ee9c6c',
        borderRadius: 25,
        color: '#fff',
        padding: 4
      })
    })
  };

  const clinicianName = clinicianDetail.practice?.name || clinicianDetail.clinician?.name || '';

  return (
    <AuthWhiteLayout autoHeight={autoHeight} noHeader={noHeader} noFooter>
      <div className={styles.container}>
        <div id={'backgroundFormHeader'} />
        <ContentLayout>
          <div className={styles.formContainer}>
            <div className={styles.title}>{title || t('label.new_client_background')}</div>
            <div className={styles.fieldWrapper}>
              {backgroundDetails?.summary.isShow && (
                <div className={styles.fieldBoxContainer}>
                  <div className={styles.fieldTitle}>
                    Please provide a brief summary of what you'd like to work on with {clinicianName}
                  </div>
                  <div className={styles.input}>
                    <textarea
                      id={'backgroundSummary'}
                      className={errorMessage['summary.content'] ? styles.fieldErrorTextArea : styles.fieldTextArea}
                      value={backgroundDetails.summary.content}
                      onChange={(e) => handleChangeSummary(e.target.value)}
                    />
                    {checkValidation && errorMessage['summary.content'] && (
                      <div className={styles.fieldError}>{errorMessage['summary.content']}</div>
                    )}
                  </div>
                </div>
              )}
              {backgroundDetails?.referral.isShow && (
                <div className={styles.fieldBoxContainer}>
                  <div className={styles.isReferredByGp}>
                    <Radio
                      label={'Have you been referred by a GP?'}
                      variant={'button'}
                      id={'referralByGP'}
                      name={'referralByGP'}
                      options={[
                        { label: 'Yes', value: 'yes' },
                        { label: 'No', value: 'no' }
                      ]}
                      value={backgroundDetails.referral.code}
                      onChange={(e) => handleChangeReferralCode(e.target.value)}
                    />
                  </div>
                  {backgroundDetails.referral.code === 'yes' && (
                    <div className={styles.referredDetailContainer}>
                      <div
                        className={classnames(
                          styles.fieldContainer,
                          checkValidation && errorMessage['referral.name'] && styles.fieldError
                        )}
                      >
                        <MaterialInput
                          label={'Doctors Name*'}
                          id={'doctorName'}
                          onChange={(e) => handleChangeReferralValue('name', e.target.value)}
                          value={backgroundDetails.referral.name}
                          maxLength={80}
                          required
                        />
                        {checkValidation && errorMessage['referral.name'] && (
                          <div className={styles.fieldError}>{errorMessage['referral.name']}</div>
                        )}
                      </div>
                      <div className={styles.fieldContainer}>
                        <DateSelector
                          id={'dateOfReferral'}
                          name={'dateOfReferral'}
                          label={'Date of referral'}
                          onChange={(e: string) => handleChangeReferralValue('date', e)}
                          value={backgroundDetails.referral.date}
                          error={checkValidation && errorMessage['referral.date'] ? styles.fieldError : undefined}
                        />
                        {checkValidation && errorMessage['referral.date'] && (
                          <div className={styles.fieldError}>{errorMessage['referral.date']}</div>
                        )}
                      </div>
                      <div className={styles.fieldContainer}>
                        {selectedFile.name ? (
                          <>
                            <div className={styles.referralTitle}>Referral File</div>
                            <div className={styles.selectedFile}>
                              {selectedFile.name}
                              <FontAwesomeIcon className={styles.icon} icon={faTrashAlt} onClick={handleFileDelete} />
                            </div>
                          </>
                        ) : (
                          <div className={styles.uploadReferralContainer}>
                            <input
                              className={styles.uploadContainerInput}
                              type={'file'}
                              id={'referralFile'}
                              onChange={handleFileSelect}
                              accept={'.pdf,.docx,image/png,image/jpeg'}
                            />
                            <label
                              className={classnames(
                                styles.uploadContainerBtn,
                                errorMessage['referral.file'] && styles.error
                              )}
                              htmlFor={'referralFile'}
                            >
                              <i className={`material-icons-outlined ${styles.publishIcon}`}>publish</i>
                              <div className={styles.publishLabel}>Upload picture of referral</div>
                            </label>
                            {checkValidation && errorMessage['referral.file'] && (
                              <div className={styles.fieldError}>{errorMessage['referral.file']}</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {backgroundDetails?.therapist.isShow && (
              <>
                <div className={styles.hr} />
                <div className={styles.fieldWrapper}>
                  <div className={styles.questionContainer}>
                    <Radio
                      label={'Have you worked with a therapist before?'}
                      id={'therapistCode'}
                      name={'therapistCode'}
                      vertical
                      options={[
                        { label: 'Yes and still am', value: 'currently' },
                        { label: 'Yes previously', value: 'previously' },
                        { label: 'No', value: 'no' }
                      ]}
                      value={backgroundDetails.therapist.code}
                      onChange={(e) => handleChangeTherapist('code', e.target.value)}
                    />
                    {['currently', 'previously'].includes(backgroundDetails.therapist.code || '') && (
                      <div className={styles.radioContainer}>
                        <Radio
                          label={'Was that experience working on a similar theme as you outlined above?'}
                          id={'therapistSimilarExperience'}
                          name={'therapistSimilarExperience'}
                          vertical
                          options={[
                            { label: 'Yes similar', value: 'yes' },
                            { label: 'No different', value: 'no' }
                          ]}
                          value={backgroundDetails.therapist.similarExperience}
                          onChange={(e) => handleChangeTherapist('similarExperience', e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {backgroundDetails?.medication.isShow && (
              <>
                <div className={styles.hr} />
                <div className={styles.fieldWrapper}>
                  <div className={styles.questionContainer}>
                    <Radio
                      label={'Are you taking any prescription medication?'}
                      id={'medicationCode'}
                      name={'medicationCode'}
                      vertical
                      options={[
                        { label: 'Yes and am currently', value: 'currently' },
                        { label: 'No, but have in last 6 months', value: 'previously' },
                        { label: 'No', value: 'no' }
                      ]}
                      value={backgroundDetails.medication.code}
                      onChange={(e) => handleChangeMedication('code', e.target.value)}
                    />
                    {['currently', 'previously'].includes(backgroundDetails.medication.code || '') && (
                      <div>
                        <CreatableSelect
                          styles={innerStyles}
                          label={'Medication'}
                          placeholder={'Search here'}
                          blurInputOnSelect={false}
                          closeMenuOnSelect={false}
                          multipleSelect
                          name={'medications'}
                          options={medicationOptions}
                          noOptionsMessage={getMedicationsNoOptionsMessage}
                          onInputChange={handleMedicationSearch}
                          onChange={handleSelectMedication}
                          formatCreateLabel={(inputText: string) => inputText}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={styles.submitButtonContainer}>
              <Button
                disabled={pageSubmitStatus !== ''}
                className={styles.submitBtn}
                status={pageSubmitStatus}
                onClick={handleSubmitBackground}
              >
                {buttonText || 'Next'}
              </Button>
            </div>
          </div>
        </ContentLayout>
      </div>
    </AuthWhiteLayout>
  );
};
export default BackgroundForm;
