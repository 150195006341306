import classNames from 'classnames';
import styles from './StepTitle.module.scss';

interface StepTitleProps {
  no: string;
  title: string;
  numberBubbleClassName?: string;
  titleClassName?: string;
}

const StepTitle = ({ no, title, numberBubbleClassName, titleClassName }: StepTitleProps) => (
  <div className={styles.container}>
    <div className={classNames(styles.numberBubble, numberBubbleClassName)}>{no}</div>
    <div className={classNames(styles.stepTitle, titleClassName)}>{title}</div>
  </div>
);

export default StepTitle;
