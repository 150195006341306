export const scrollToView = (id: string, blockCenter?: boolean, behaviorAuto?: boolean, inlineCenter?: boolean) => {
  document.getElementById(id)?.scrollIntoView({
    behavior: behaviorAuto ? 'auto' : 'smooth',
    block: blockCenter ? 'center' : 'nearest',
    inline: inlineCenter ? 'center' : 'nearest'
  });
};

export const scrollErrorsIntoView = (errors: Record<string, unknown> = {}) => {
  for (const errorKey in errors) {
    const element = document.getElementById(errorKey);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      return;
    }
  }
};
