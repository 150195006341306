export const SelectAboutUsUrl = 'https://selectpsychology.co.uk/about/';
export const SelectContactUrl = 'https://selectpsychology.co.uk/get-started/';
export const SelectDefaultTimezone = 'Europe/London';
export const SelectMatchingWizardUrl = `${
  process.env.REACT_APP_CLIENT_DOMAIN_SELECT || 'http://localhost:4080'
}/get-matched`;
export const SelectPracticeSlugUrl = 'Select_Psychology';
export const SelectPrivacyPolicyUrl = 'https://selectpsychology.co.uk/privacy-policy/';
export const SelectSocialMediaUrls = {
  facebook: 'https://www.facebook.com/SelectPsychologyUK',
  instagram: 'https://www.instagram.com/selectpsychology/',
  linkedIn: 'https://www.linkedin.com/company/select-psychology/'
};
export const SelectSupportEmail = 'office@selectpsychology.co.uk';
export const SelectSupportMobile = '01912580008';
export const SelectTermsAndConditions =
  'https://selectpsychology.co.uk/wp-content/uploads/2023/01/Terms-and-Conditions-Dec-2022.pdf';
export const SelectUrl = 'https://selectpsychology.co.uk/';
