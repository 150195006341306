import { Field, FieldProps } from 'formik';
import styles from './SHPartnerBackgroundInformationForm.module.scss';

const SHPartnerBackgroundInformationForm = () => {
  return (
    <div className={styles.backgroundSection}>
      <div className={styles.header}>Background information for our psychologist</div>
      <div className={styles.noteAreaLabel}>Please add some detail and context relating to this referral please</div>
      <Field name="referralBackground">
        {({ field }: FieldProps) => (
          <textarea
            id={'referralBackground'}
            className={styles.textAreaField}
            placeholder={'Please add detail here....'}
            {...field}
          />
        )}
      </Field>
    </div>
  );
};

export default SHPartnerBackgroundInformationForm;
