import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, RedirectLoginOptions } from '@auth0/auth0-react';

const checkHasAccessToWindows = () => {
  try {
    // This is to bypass issues with Auth0
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const testRef = window.sessionStorage;
    return true;
  } catch (_) {
    return false;
  }
};

const Auth0ProviderWithHistory: FC<{ children: ReactNode }> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: RedirectLoginOptions) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (checkHasAccessToWindows()) {
    return (
      <Auth0Provider
        domain={domain!}
        clientId={clientId!}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        audience={process.env.REACT_APP_API_AUDIENCE}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return <>{children}</>;
  }
};

export default Auth0ProviderWithHistory;
