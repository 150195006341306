import Form from 'antd/lib/form/Form';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox/CheckBox';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import FormikInput from 'components/Input/FormikInput';
import Loading from 'components/Loading/Loading';
import { Field, FieldProps, Formik } from 'formik';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import { isEmpty } from 'lodash';
import { ReactNode, useState } from 'react';
import ButtonHelm from '../../../../components/ButtonHelm/ButtonHelm';
import { WISE_OFFICE_LOCATIONS, formDataValidationSchema, initialClientRecordOnly } from './constants';
import styles from './HelmPartnerCreateProfileForm.module.scss';
import FormikSelect from 'components/Select/FormikSelect';

interface HelmCreateProfileFormProps {
  isSubmitting: boolean;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialClientRecordOnly) => void;
  customAgeCheck?: number;
}

const HelmPartnerCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  customAgeCheck
}: HelmCreateProfileFormProps) => {
  const [consentedReferral, setConsentedReferral] = useState(true);
  const [consentedAppointment, setConsentedAppointment] = useState(true);

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'helm-theme')}>
        <div className={styles.header}>
          <div>
            Welcome to <span className={styles.highlight}>Helm</span>
          </div>
          <div className={styles.subHeader}>
            Please provide the required client details to submit this private referral.
          </div>
        </div>
        <Formik
          initialValues={initialClientRecordOnly}
          validationSchema={formDataValidationSchema(customAgeCheck)}
          onSubmit={onSubmit}
        >
          {({ values, isValid, validateForm, dirty }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of Birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                <HelmMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={'0482 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  labelClass={styles.inputLabel}
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email Address*"
                  name="email"
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                  required
                />
                <FormikSelect
                  id="officeLocation"
                  className={styles.customSelect}
                  label={'WISE Office Location*'}
                  placeholder={'Select'}
                  isValidNewOption={() => false}
                  name="officeLocation"
                  options={WISE_OFFICE_LOCATIONS}
                  styles={{
                    valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                  }}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Purchase Order No.*"
                  name="purchaseOrderNo"
                  required
                  labelClass={styles.inputLabel}
                />
                <div className={styles.backgroundSection}>
                  <div className={styles.title}>Reason for Referral</div>
                  <div className={styles.label}>
                    Please include any detail that you feel is important and relevant for our team in regard to this
                    specific referral.
                  </div>
                  <Field name="referralBackground">
                    {({ field }: FieldProps) => (
                      <textarea
                        id={'referralBackground'}
                        className={styles.textAreaField}
                        placeholder={'Please add detail here....'}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className={styles.communicationPreferences}>
                <div className={styles.title}>Client Consent</div>
                <div className={styles.label}>
                  We will send relevant information regarding this referral and/or consultation to the client via email
                  and SMS. This may include information about specific appointments and/or requests to provide
                  additional information. This is not marketing.
                </div>
                <div className={styles.checkBoxes}>
                  <div className={styles.checkBoxText}>
                    <CheckBox
                      id="consentedReferral"
                      value={consentedReferral}
                      onChange={(e) => setConsentedReferral(e.target.checked)}
                      className={styles.checkBox}
                      label="Client has consented to providing their information to make this referral"
                    />
                  </div>
                  <div className={styles.checkBoxText}>
                    <CheckBox
                      id="consentedAppointment"
                      value={consentedAppointment}
                      onChange={(e) => setConsentedAppointment(e.target.checked)}
                      className={styles.checkBox}
                      label="Client has consented to receiving relevant email and SMS in relation to their appointment"
                    />
                  </div>
                </div>
              </div>
              <ButtonHelm
                className={styles.submitButton}
                disabled={!(isValid && dirty) || !consentedReferral || !consentedAppointment}
                onClick={() => {
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit(values);
                    }
                  });
                }}
              >
                Continue
              </ButtonHelm>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default HelmPartnerCreateProfileForm;
