import { scheduleServicesApiSlice } from 'redux/services/scheduleServicesApiSlice';
import { AppointmentSlot } from 'utils/hooks/appointment';

interface GetReservedAppointmentByIdRequest {
  reserveId: string;
  accountId: string;
}

export const reservedAppointment = scheduleServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReservedAppointmentById: builder.query<AppointmentSlot[], GetReservedAppointmentByIdRequest>({
      query: ({ reserveId, accountId }) => {
        return {
          url: `/accounts/${accountId}/reserved-appointments/${reserveId}`
        };
      },
      providesTags: ['ReservedAppointmentByIdRequest'],
      transformResponse: (response: { appointments: AppointmentSlot[] }) => {
        return response.appointments;
      }
    })
  })
});

export const { useGetReservedAppointmentByIdQuery } = reservedAppointment;
