import { useRef, useState } from 'react';
import { Modal, notification } from 'antd';
import styles from './InvoiceModal.module.scss';
import { useFetchInvoice } from 'pages/InvoiceViewPage/InvoiceViewPage';
import InvoiceView from 'components/InvoiceView/InvoiceView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { downloadInvoicePDF } from 'utils/http/BillingService/Invoice/invoice';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import { reconstructNodeIntoDocument } from 'utils/generatePDF';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';
import InvoiceViewWithTemplate from 'components/InvoiceViewWithTemplate/InvoiceViewWithTemplate';

interface InvoiceModalProps {
  visible: boolean;
  invoiceId?: string;
  modalWrapperClassName?: string;
  closeDialog: () => void;
}

const InvoiceModal = ({ visible, invoiceId, modalWrapperClassName, closeDialog }: InvoiceModalProps) => {
  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const { invoice, isInvoiceLoading, fetchInvoice } = useFetchInvoice({ invoiceIdArg: invoiceId });
  const invoicePDFRef = useRef<HTMLDivElement>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  const { token } = useGetAccessToken();
  const downloadInvoice = async () => {
    if (invoicePDFRef?.current && invoiceId) {
      setIsDownloadLoading(true);
      try {
        if (token) {
          const htmlNode = reconstructNodeIntoDocument(invoicePDFRef.current);
          const resFile: Blob = await downloadInvoicePDF({
            token,
            invoiceId,
            clientRecordId,
            clientProfileId,
            payload: htmlNode
          });
          const generateFile = document.createElement('a');
          generateFile.href = window.URL.createObjectURL(resFile);
          generateFile.download = `invoice_${invoiceId}.pdf`;
          generateFile.click();
          generateFile.remove();
        }
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: 'Failed to download Invoice.'
        });
      }
      setIsDownloadLoading(false);
    }
  };

  const isStripeActive = !!invoice?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentType === 'stripe' && paymentMethod.stripeStatus === 'completed'
  );

  return (
    <Modal
      title={
        <div className={styles.title}>
          <div>Invoice Overview</div>
          {!isInvoiceLoading && (
            <div
              className={isDownloadLoading ? styles.downloadingInvoice : styles.downloadInvoiceBtn}
              onClick={!isDownloadLoading ? downloadInvoice : undefined}
            >
              {isDownloadLoading ? (
                <>
                  <LoadingCircle className={styles.loading} />
                  <span>Downloading...</span>
                </>
              ) : (
                <>
                  <i className={`material-icons-outlined ${styles.downloadIcon}`}>download</i>
                  <span>Download invoice</span>
                </>
              )}
            </div>
          )}
        </div>
      }
      footer={null}
      visible={visible}
      onCancel={() => closeDialog()}
      className={styles.container}
      wrapClassName={modalWrapperClassName}
      width={'fit-content'}
    >
      <div className={styles.body}>
        {isInvoiceLoading || !invoice ? (
          <LoadingDot />
        ) : (
          invoice._id &&
          (invoice.template?._id ? (
            <InvoiceViewWithTemplate
              invoice={invoice}
              isStripeActive={isStripeActive}
              pdfRef={invoicePDFRef}
              refetchInvoice={fetchInvoice}
            />
          ) : (
            <InvoiceView
              invoice={invoice}
              isStripeActive={isStripeActive}
              pdfRef={invoicePDFRef}
              refetchInvoice={fetchInvoice}
            />
          ))
        )}
      </div>
    </Modal>
  );
};

export default InvoiceModal;
