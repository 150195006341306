import styles from './HomeWorkCard.module.scss';
import { SessionActivityInterface } from 'helm/interfaces/program/program';
import ActivitiesCard from '../../../ActivitiesCard/ActivitiesCard';

interface HomeWorkCardProps {
  title: string;
  activitiesData: SessionActivityInterface[];
}

const HomeWorkCard = ({ title, activitiesData }: HomeWorkCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <ActivitiesCard className={styles.content} activitiesData={activitiesData} />
    </div>
  );
};

export default HomeWorkCard;
