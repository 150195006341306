import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import { MentalHealthList } from 'SomeoneHealth/interfaces/mentalHealth';
import classnames from 'classnames';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import NoPsychologistBanner from '../../NoPsychologistBanner/NoPsychologistBanner';
import PsychologistGridCard from '../../PsychologistCard/PsychologistGridCard/PsychologistGridCard';
import PsychologistGridSkeleton from '../../PsychologistSkeleton/PsychologistGridSkeleton';
import styles from './PsychologistGridView.module.scss';

interface PsychologistGridViewProps {
  isPsychologistFound: boolean;
  psychologistList: PractitionerDetailsInterface[];
  matchedSpecialisations: string[];
  hasMorePsychologists: boolean;
  selectedMentalHealthConcern: MentalHealthList[];
  isNewClient: boolean;
  clientAppointmentType: AppointmentBookingClaimType;
  isLoading: boolean;
  loadMorePsychologists: () => void;
  handleOpenWaitListModal: (practitioner: PractitionerDetailsInterface) => void;
  clearAllFilter: () => void;
}

const PsychologistGridView: FC<PsychologistGridViewProps> = ({
  isPsychologistFound,
  psychologistList,
  matchedSpecialisations,
  hasMorePsychologists,
  selectedMentalHealthConcern,
  isNewClient,
  clientAppointmentType,
  isLoading,
  handleOpenWaitListModal,
  loadMorePsychologists,
  clearAllFilter
}) => {
  return isLoading ? (
    <SomeoneHealthContentLayout>
      <div className={styles.gridSkeletonWrapper}>
        <div className={styles.gridSkeletonContent}>
          {[...Array(6)].map((_, index) => (
            <PsychologistGridSkeleton key={index} />
          ))}
        </div>
      </div>
    </SomeoneHealthContentLayout>
  ) : (
    <div className={styles.container}>
      <InfiniteScroll
        loadMore={loadMorePsychologists}
        initialLoad={false}
        pageStart={0}
        hasMore={isPsychologistFound ? hasMorePsychologists : false}
        loader={
          <SomeoneHealthContentLayout key={0}>
            <div className={styles.psychologistSkeletonContainerLoader}>
              {[...Array(3)].map((_, index) => (
                <PsychologistGridSkeleton key={index} />
              ))}
            </div>
          </SomeoneHealthContentLayout>
        }
      >
        {!isPsychologistFound && <NoPsychologistBanner clearAllFilter={clearAllFilter} />}
        <SomeoneHealthContentLayout className={classnames(!isPsychologistFound && styles.noPsychologistWrapper)}>
          <div className={styles.cardContainer}>
            <div className={styles.psychologistCardContainer}>
              {psychologistList.map((practitioner, index) => (
                <PsychologistGridCard
                  key={index}
                  handleOpenWaitListModal={() => handleOpenWaitListModal(practitioner)}
                  practitioner={practitioner}
                  matchedSpecialisations={matchedSpecialisations}
                  selectedMentalHealthConcern={selectedMentalHealthConcern}
                  nextAvailabilityDetails={
                    practitioner.nextAvailabilities
                      ? practitioner.nextAvailabilities?.filter(
                          (item) =>
                            (isNewClient
                              ? item.appointmentTypeBookingRules?.new.available
                              : item.appointmentTypeBookingRules?.existing.available) &&
                            item.appointmentTypeClaimType === clientAppointmentType
                        )
                      : []
                  }
                />
              ))}
            </div>
          </div>
        </SomeoneHealthContentLayout>
      </InfiniteScroll>
    </div>
  );
};

export default PsychologistGridView;
