import { useField } from 'formik';
import { Option, Question, SubQuestion } from 'interfaces/Assessment/OnboardingAssessment';
import { Slider } from 'antd';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

import styles from './SimpleSlider.module.scss';

interface SimpleSliderProps {
  question: Question;
}

const extractSliderParameters = (options: Option[]) => {
  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  let tooltips: Record<number, string> = {};
  let marks: Record<number, string> = {};

  for (const { value, key, description } of options) {
    if (!Number.isInteger(value) || typeof value !== 'number' || value === undefined) {
      throw new Error('Expect value to be number');
    }

    if (min === undefined || min > value) {
      min = value;
    }

    if (max === undefined || max < value) {
      max = value;
    }

    marks = {
      ...marks,
      [value]: key
    };

    tooltips = {
      ...tooltips,
      [value]: description
    };
  }

  if (max === undefined || min === undefined) {
    throw new Error('Unable too determine min max value from scale');
  }

  return {
    min,
    max,
    marks,
    tooltips
  };
};

const isOptionsOptionType = (options: Option[] | SubQuestion[]): options is Option[] =>
  'key' in options[0] && 'description' in options[0] && 'value' in options[0];

const SimpleSlider = ({ question }: SimpleSliderProps) => {
  if (!question || !question.options || question.options.length < 2 || !isOptionsOptionType(question.options)) {
    throw new Error('SimpleSlider component does not support question');
  }
  const [{ value }, { error, touched }, { setValue }] = useField(`${question.id}.value`);

  const { min, max, marks, tooltips } = extractSliderParameters(question?.options);
  const tipFormatter = (value: number | undefined) => {
    if (value !== undefined) {
      return tooltips[value] ?? value;
    }
    return null;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.slider}>
          <Slider
            min={min}
            max={max}
            marks={marks}
            value={value}
            onChange={setValue}
            onAfterChange={setValue}
            tooltipPlacement={'top'}
            tipFormatter={tipFormatter}
          />
        </div>
      </div>
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default SimpleSlider;
