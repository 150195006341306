import { createContext, ReactNode, useEffect } from 'react';
import Loading from '../components/Loading/Loading';
import NoAuthLayout from '../layouts/NoAuthLayout/NoAuthLayout';
import { PatientProfile } from '../components/UpdateProfileContent/interface';
import { useGetClientProfileDetails } from '../redux/endpoints/clinicianProfileServices/getClientDetails';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext<{
  clientProfile?: PatientProfile;
}>({});

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const { clientProfileData, isClientProfileLoading, isError } = useGetClientProfileDetails();

  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError, navigate]);

  return isClientProfileLoading ? (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  ) : (
    <UserContext.Provider
      value={{
        clientProfile: clientProfileData
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
