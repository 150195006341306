import { ReactNode } from 'react';
import styles from './HelmHeader.module.scss';
import LoginAvatar from '../HelmFilterHeader/components/LoginAvatar/LoginAvatar';
import HelmContentLayout from '../../HelmContentLayout/HelmContentLayout';
import classnames from 'classnames';
import { helmEnvironment } from 'helm/utils/HelmEnv/helmEnv';
import HelmLogo from './HelmLogo/HelmLogo';
import { useNavigate } from 'react-router-dom';

interface HelmHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  whiteLogo?: boolean;
  withPadding?: boolean;
  logoWithoutText?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  classNames?: string;
  noFlex?: boolean;
  middleChildren?: ReactNode;
}

const HelmHeader = ({
  noWrapper,
  whiteFont,
  whiteLogo,
  withPadding,
  logoWithoutText,
  withMenu,
  homeLink,
  noLogin,
  classNames,
  middleChildren
}: HelmHeaderProps) => {
  const navigate = useNavigate();
  const URLs = helmEnvironment();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = URLs.HelmFooterLogoURL;
    }
  };

  return (
    <HelmContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, classNames)}
    >
      <div id={'HelmHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <HelmLogo whiteLogo={whiteLogo} logoWithoutText={logoWithoutText} />
        </div>
        {middleChildren}
        {!noLogin && <LoginAvatar whiteFont={whiteFont} withMenu={withMenu} />}
      </div>
    </HelmContentLayout>
  );
};

export default HelmHeader;
