import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import parse from 'html-react-parser';

import {
  CheckInQuestion,
  ClinicalAssessment,
  MicroJournalQuestion,
  OnboardingQuestion
} from 'interfaces/Assessment/OnboardingAssessment';

import { Clinician } from 'utils/hooks/clinician';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import IndividualQuestions from './components/IndividualQuestions/IndividualQuestions';
import QuestionSet from './components/QuestionSet/QuestionSet';
import Stimulus from './components/Stimulus/Stimulus';

import { PatientQuestionFormHeaderProps } from '../../pages/OnboardingAssessment/components/OnboardingQuestionForm/OnboardingQuestionForm';

import styles from './AssessmentQuestion.module.scss';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician/clinicianDetails';
import { IS_CORDS_APP } from 'utils/hooks/AccountInfo/clientDetails';

interface AssessmentQuestionProps {
  assessment: CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion;
  completedQuestions: number;
  totalQuestions?: number;
  clinician?: Clinician | GetAttachedClinicianDetails['clinician'];
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  reverseStemAndHideTitle: boolean;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (
    assessmentId: string,
    values: any,
    setStageToNextQuestion: boolean
  ) => Promise<CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion | undefined>;
  onSaveAndExit: () => void;
}

const AssessmentQuestion = ({
  assessment,
  completedQuestions,
  totalQuestions,
  clinician,
  defaultPatientDetails,
  header,
  reverseStemAndHideTitle,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  onSaveAndExit
}: AssessmentQuestionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { questionType, stimulus, metadata } = useMemo(() => {
    if ('questionType' in assessment) {
      const { questionType, stimulus, metadata } = assessment;

      return { questionType, stimulus, metadata };
    }

    return {};
  }, [assessment]);

  useEffect(() => {
    if (metadata?.popup) {
      Modal.info({
        content: (
          <div>{parse(metadata.popup.replace(/\$CLINICIAN_NAME/g, `${clinician?.title} ${clinician?.name}`))}</div>
        ),
        icon: null
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  const { id, title, sections } = assessment;

  const handleSubmit = (values: { [key: string]: Record<string, any> }, setStageToNextQuestion: boolean) =>
    onSubmit(id, values, setStageToNextQuestion);

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      )}
      {questionType === 'questionSet' ? (
        <>
          <div className={styles.title}>{(IS_CORDS_APP && metadata?.cords?.shortTitle) || title}</div>
          <Stimulus stimulus={stimulus} />
          <QuestionSet
            questionId={id}
            sections={sections}
            completedQuestions={completedQuestions}
            totalQuestions={totalQuestions}
            defaultPatientDetails={defaultPatientDetails}
            header={!IS_CORDS_APP ? header : () => <></>}
            isSkippable={isSkippable}
            isFirstQuestionSet={isFirstQuestionSet}
            onBack={onBack}
            onSkip={onSkip}
            onSubmit={handleSubmit}
            setIsSubmitting={setIsSubmitting}
            onSaveAndExit={onSaveAndExit}
          />
        </>
      ) : (
        <IndividualQuestions
          sections={sections}
          completedQuestions={completedQuestions}
          totalQuestions={totalQuestions}
          defaultPatientDetails={defaultPatientDetails}
          header={!IS_CORDS_APP ? header : () => <></>}
          title={title}
          reverseStemAndHideTitle={reverseStemAndHideTitle}
          isSkippable={isSkippable}
          isFirstQuestionSet={isFirstQuestionSet}
          onBack={onBack}
          onSkip={onSkip}
          onSubmit={handleSubmit}
          setIsSubmitting={setIsSubmitting}
          onSaveAndExit={onSaveAndExit}
        />
      )}
    </>
  );
};

export default AssessmentQuestion;
