import { Link } from 'react-router-dom';
import styles from './PsychologistEmptyList.module.scss';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';

const PsychologistEmptyList = () => {
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  return (
    <div className={styles.container}>
      <div className={styles.message}>Sorry, we can’t find an active psychologist by that name</div>
      <div className={styles.suggestion}>
        {`You can `}
        <Link to={PRACTITIONER.DIRECTORY} className={styles.link}>
          browse our directory
        </Link>
        {` or `}
        <Link to={PRACTITIONER.LISTING} className={styles.link}>
          explore being matched based on your needs
        </Link>
      </div>
    </div>
  );
};

export default PsychologistEmptyList;
