import { ChangeEvent, useMemo } from 'react';
import { useField } from 'formik';

import { Question, SubQuestion } from 'interfaces/Assessment/OnboardingAssessment';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Radio from 'components/Radio/Radio';

import styles from './MultipleAnswers.module.scss';

interface MultipleAnswersProps {
  question: Question;
}

const MultipleAnswers = ({ question }: MultipleAnswersProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField<{ [key: string]: any }>(`${question.id}.value`);

  const subQuestions = useMemo(() => {
    if (question.options) {
      return (question.options as SubQuestion[]).map((subQuestion) => ({
        ...subQuestion,
        options: subQuestion.options.map(({ description, key }) => ({ label: description, value: key }))
      }));
    } else {
      return [];
    }
  }, [question]);

  const handleChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (value) {
      setValue({ ...value, [id]: e.target.value });
    } else {
      setValue({ [id]: e.target.value });
    }
  };

  return (
    <>
      {subQuestions.map(({ id, stem, options }, index) => (
        <div className={styles.container} key={index}>
          <span className={styles.stem}>{stem}</span>
          <Radio
            variant="button"
            name={`${question.id}-${id}`}
            options={options}
            value={value?.[id]}
            onChange={handleChange(id)}
            hasError={touched && !!(error as any)?.[id]}
            noSpacing
          />
          <ErrorMessage error={(error as any)?.[id]} visible={touched && !!(error as any)?.[id]} />
        </div>
      ))}
    </>
  );
};

export default MultipleAnswers;
