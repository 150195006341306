import { CLIENT_BRAND_LABEL } from 'interfaces/ClientBrand';
import styles from './AdvisoryBookingHeader.module.scss';
import someoneHealthLogo from 'SomeoneHealth/assets/images/logo/favSomeoneHealth.png';

const AdvisoryBookingHeader = () => (
  <div className={styles.container}>
    <div className={styles.logoWrapper}>
      <img src={someoneHealthLogo} className={styles.logo} alt={`${CLIENT_BRAND_LABEL.SOMEONE_HEALTH} logo`} />
    </div>
    <div className={styles.descWrapper}>
      <div className={styles.title}>Book a time with our trained support team</div>
      <div className={styles.desc}>Book a time with our trained support team.</div>
    </div>
  </div>
);

export default AdvisoryBookingHeader;
