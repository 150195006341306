import { Fragment, useMemo } from 'react';

import styles from './Stimulus.module.scss';

interface StimulusProps {
  stimulus?: string;
}

const Stimulus = ({ stimulus }: StimulusProps) => {
  const stimulusTexts = useMemo(() => {
    if (stimulus) {
      return stimulus.split('\n');
    } else {
      return [];
    }
  }, [stimulus]);

  return stimulus ? (
    <div className={styles.stimulus}>
      {stimulusTexts.map((text, index) => (
        <Fragment key={index}>
          {text}
          {index !== stimulusTexts.length - 1 && <br />}
        </Fragment>
      ))}
    </div>
  ) : null;
};

export default Stimulus;
