import { useAuth0 } from '@auth0/auth0-react';
import styles from './ReferralDetail.module.scss';
import ReferralDetailForm from 'helm/pages/ReferralDetail/components/ReferralDetailForm/ReferralDetailForm';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';

const ReferralDetail = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <RechargeContentLayout className={`${styles.container} recharge-theme`}>
      <div className={styles.headerWrapper}>
        <RechargeHeader withPadding withMenu homeLink whiteFont whiteMenu />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Add Referral Details</div>
        <div className={styles.content}>
          <div className={styles.description}>
            Complete and share details of your referral with Recharge. This is required for any Medicare claims.
          </div>
          <ReferralDetailForm />
        </div>
      </div>
    </RechargeContentLayout>
  );
};

export default ReferralDetail;
