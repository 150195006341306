/* eslint-disable no-constant-condition */
import classnames from 'classnames';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { generatePath, Link } from 'react-router-dom';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import PsychologistAvatar from 'Select/components/PsychologistAvatar/PsychologistAvatar';
import PsychologistBonaFides from 'Select/components/PsychologistBonaFides/PsychologistBonaFides';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Select/interfaces/mentalHealth';
import PsychologistSpecialisation from 'Select/pages/PsychologistListing/components/PsychologistCard/components/PsychologistSpecialisation/PsychologistSpecialisation';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import styles from './MyPsychologistDetail.module.scss';

interface MyPsychologistDetailProps {
  classNames?: string;
}
const MyPsychologistDetail = ({ classNames }: MyPsychologistDetailProps) => {
  const { PRACTITIONER } = useSelectRoutesGenerator();

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const clinician = attachedClinicianDetail?.clinician;

  const detailsPagePath =
    clinician &&
    generatePath(PRACTITIONER.DETAILS, {
      clinicianId: clinician.slugUrl
    });

  return (
    <div className={classnames(styles.container, classNames)}>
      {isAttachedClinicianDetailLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <div className={classnames(styles.content, classNames)}>
            <div className={styles.header}>
              <div className={styles.title}>My Psychologist</div>
            </div>

            {clinician ? (
              <div className={styles.psychologistInfo}>
                <div className={styles.overview}>
                  <div className={styles.detailsWrapper}>
                    <PsychologistAvatar
                      classNameImage={styles.profileImg}
                      profileImg={clinician.avatar}
                      detailPath={detailsPagePath || ''}
                    />
                    <Link to={detailsPagePath || ''}>
                      <ButtonSelect className={styles.scheduleButton}>Schedule Appointment</ButtonSelect>
                    </Link>
                  </div>
                  <div className={styles.bonaFidesWrapper}>
                    <PsychologistBonaFides
                      limitDisplayQualifications
                      bonaFidesDetails={clinician.helmControl.bonaFides}
                    />
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.greet}>Hi, I'm {clinician.name}</div>
                  <div className={styles.descWrapper}>
                    {clinician.helmControl.shortDescription && (
                      <div className={styles.desc}>
                        <div className={styles.label}>INTRODUCTION</div>
                        <div
                          className={styles.contentDesc}
                          dangerouslySetInnerHTML={{
                            __html: clinician.helmControl.shortDescription
                          }}
                        />
                      </div>
                    )}
                    {clinician.helmControl.primarySpecialisations &&
                      clinician.helmControl.primarySpecialisations.length > 0 && (
                        <div className={styles.desc}>
                          <PsychologistSpecialisation
                            label={'MAIN AREAS OF PRACTICE'}
                            specialisationsDetails={clinician.helmControl.primarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                          />
                        </div>
                      )}
                    {clinician.helmControl.secondarySpecialisations &&
                      clinician.helmControl.secondarySpecialisations.length > 0 && (
                        <div className={styles.desc}>
                          <PsychologistSpecialisation
                            label={'I CAN ALSO HELP WITH'}
                            specialisationsDetails={clinician.helmControl.secondarySpecialisations.map(
                              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                            )}
                            badgeClassName={styles.secondaryAreaBadge}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.psychologistInfo}>
                  <div className={styles.avatar}>
                    <i className={`material-icons ${styles.icon}`}>person</i>
                  </div>
                  <div className={styles.detail}>
                    <div className={styles.headerDesc}>Please select a psychologist</div>
                    <div className={styles.subDesc}>
                      Find the right psychologist for you from panel of experience, verified providers
                    </div>
                    <Link to={generatePath(PRACTITIONER.LISTING)}>
                      <ButtonSelect className={styles.findButton}>Find the right psychologist for me</ButtonSelect>
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyPsychologistDetail;
