import styles from './HowToConnect.module.scss';
import { ProgramAppointment } from 'helm/interfaces/program/program';
import { generateAppointmentLabelAndInstructions } from 'helm/pages/P2CGambling/constants';
import { useMemo } from 'react';

interface YourProgressContentProps {
  appointmentData: ProgramAppointment;
}

const HowToConnect = ({ appointmentData }: YourProgressContentProps) => {
  const { label, instructions } = useMemo(
    () => generateAppointmentLabelAndInstructions(appointmentData),
    [appointmentData]
  );

  return (
    <div className={styles.container}>
      <div className={styles.appointmentTitle}>HOW TO CONNECT</div>
      <div className={styles.connectWrapper}>
        <span>{label}</span>
        {instructions && <span className={styles.text} dangerouslySetInnerHTML={{ __html: instructions }} />}
      </div>
    </div>
  );
};

export default HowToConnect;
