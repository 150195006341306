import { useEffect, useMemo, useState } from 'react';
import styles from './PartnerPractitionerListing.module.scss';
import { defaultClinicianTimeZone, useFetchPractitionerList } from './hooks/getPsychologistList';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Modal, Skeleton } from 'antd';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import FitFilter from 'helm/PartnerReferral/components/FitFilter/FitFilter';
import {
  AppointmentBookingClaimType,
  PractitionerDetailsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import moment from 'moment/moment';
import { useAuth0 } from '@auth0/auth0-react';
import {
  FitPsychologistGenderList,
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitTimesList,
  FitFundingList
} from 'helm/interfaces/Filter/fitFilter';
import queryString from 'query-string';
import momentTz from 'moment-timezone';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { useHelmPartnerRoutesGenerator } from 'helm/PartnerReferral/utils/Path/HelmPartnerRoutesGenerator';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import HelmFooter from 'helm/components/HelmFooter/HelmFooter';
import PractitionerDetails from '../../../pages/PractitionerListing/components/PractitionerDetails/PractitionerDetails';
import PractitionerAvatar from '../../../components/PractitionerAvatar/PractitionerAvatar';
import PractitionerBonaFides from '../../../components/PractitionerBonaFides/PractitionerBonaFides';
import PractitionerAvailabilityCalendar from '../../../pages/PractitionerListing/components/PractitionerAvailabilityCalendar/PractitionerAvailabilityCalendar';
import JoinWaitlistForm from 'helm/components/JoinWaitlistForm/JoinWaitlistForm';
import { AU_TIME_ZONE_LIST, helmTimeZoneLocalStorage } from 'utils/constants/timeZone';

export interface PsychologistFitFilter {
  gender?: FitPsychologistGenderList;
  day?: FitDaysList;
  time?: FitTimesList;
  deliveryMode?: FitDeliveryList;
  language?: FitLanguageList;
  funding?: FitFundingList;
}

const getTimeFilterInMelbourneTimezone = ({ time, timezone }: { time?: FitTimesList; timezone: string }) => {
  if (time === FitTimesList.morning) {
    return {
      startTime: '00:00',
      endTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.afternoon) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('12:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm')
    };
  }

  if (time === FitTimesList.evening) {
    return {
      startTime: momentTz
        .tz(momentTz.tz(moment('18:00', 'HH:mm').format('YYYY-MM-DD HH:mm'), timezone), defaultClinicianTimeZone)
        .format('HH:mm'),
      endTime: '24:00'
    };
  }

  return {};
};

const PartnerPractitionerListing = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const helmPartnerClientTimeZone = localStorage.getItem(helmTimeZoneLocalStorage);

  const { gender, day, time, deliveryMode, language, funding }: PsychologistFitFilter = queryString.parse(queryParam);

  const [selectedFitFilter, setSelectedFitFilter] = useState<PsychologistFitFilter>({
    gender: gender || undefined,
    day: day || undefined,
    time: time || undefined,
    deliveryMode: deliveryMode || undefined,
    language: language || undefined,
    funding: funding || undefined
  });

  useEffect(() => {
    setSelectedFitFilter({
      gender: gender || undefined,
      day: day || undefined,
      time: time || undefined,
      deliveryMode: deliveryMode || undefined,
      language: language || undefined,
      funding: funding || undefined
    });
    // will hit infinity loop if we listen to filter value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { PRACTITIONER } = useHelmPartnerRoutesGenerator();

  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerDetailsInterface | undefined>(undefined);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  const [selectedTimezone, setSelectedTimezone] = useState(
    helmPartnerClientTimeZone ||
      // use browser timezone if browser timezone is supported
      AU_TIME_ZONE_LIST.find((tzObj) =>
        // eslint-disable-next-line new-cap
        tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
      )?.id ||
      // use default client timezone
      AU_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
      AU_TIME_ZONE_LIST[0].id
  );

  const getPartnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType;
  const clientAppointmentType: AppointmentBookingClaimType = getPartnerCode;

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  const filterQuery = useMemo(() => {
    const { day, ...selectedFitFilterRest } = selectedFitFilter;

    return {
      ...selectedFitFilterRest,
      days: day,
      deliveryMode: selectedFitFilter.deliveryMode,
      claimType: clientAppointmentType,
      ...getTimeFilterInMelbourneTimezone({ time: selectedFitFilter.time, timezone: selectedTimezone }),
      isNewClient
    };
  }, [clientAppointmentType, selectedFitFilter, selectedTimezone, isNewClient]);

  const { psychologistList, isPractitionerListLoading, loadMorePsychologists, hasMorePsychologists } =
    useFetchPractitionerList({
      clientTimeZone: selectedTimezone,
      filterQuery,
      shouldFetch: !isClientBookingRuleLoading
    });

  const noResultFilterQuery = useMemo(
    () => ({
      claimType: clientAppointmentType,
      isNewClient,
      perPage: 10
    }),
    [clientAppointmentType, isNewClient]
  );

  const { psychologistList: psychologistListNoResult } = useFetchPractitionerList({
    clientTimeZone: selectedTimezone,
    filterQuery: noResultFilterQuery
  });

  const handleChangeTimeZone = (val: string) => {
    setSelectedTimezone(val);
    localStorage.setItem(helmTimeZoneLocalStorage, val);
  };

  const handleNavigatePath = (queryParamValue?: PsychologistFitFilter) => {
    const generateQueryParam = queryString.stringify(queryParamValue || selectedFitFilter, { sort: false });
    navigate(`${generateQueryParam ? `?${generateQueryParam}` : ''}`);
  };

  const handleFitResetValue = () => {
    const defaultValue = {
      gender: undefined,
      day: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath(defaultValue);
  };

  const clearAllFilter = () => {
    const defaultValue = {
      gender: undefined,
      day: undefined,
      time: undefined,
      deliveryMode: undefined,
      language: undefined,
      style: undefined
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath(defaultValue);
  };

  const handleChangeFit = (fitValue: PsychologistFitFilter) => {
    setSelectedFitFilter(fitValue);
    handleNavigatePath(fitValue);
  };

  const psychologistListCard = (psychologistList: PractitionerDetailsInterface[], reverseBg?: boolean) => (
    <div className={styles.container}>
      <InfiniteScroll
        loadMore={loadMorePsychologists}
        initialLoad={false}
        pageStart={0}
        hasMore={hasMorePsychologists}
        loader={
          <div className={styles.loadMoreWrapper} key={'loading'}>
            <LoadingDot />
          </div>
        }
      >
        {psychologistList.map((obj, i) => {
          const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
            clinicianId: obj.slugUrl
          });
          const detailPath = `${detailsPagePath}`;
          const detailPathWithTimeSlot = `${detailsPagePath}`;

          return (
            <div className={reverseBg ? styles.contentWrapperRs : styles.contentWrapper} key={i}>
              <HelmContentLayout>
                <div className={styles.content}>
                  <PractitionerDetails
                    practitionerDetails={obj}
                    detailPath={detailPath}
                    className={styles.mobileDetails}
                  />
                  <div className={styles.profileWrapper}>
                    <PractitionerAvatar
                      className={styles.profile}
                      classNameImage={styles.profileImg}
                      shapeType={`${(i + 1) % 4}`}
                      profileImg={obj.avatar}
                      detailPath={detailPath}
                    />
                    <PractitionerBonaFides
                      limitDisplayQualifications
                      bonaFidesDetails={obj.helmControl.bonaFides}
                      hideMedicare
                    />
                  </div>
                  <div className={styles.detailsContainer}>
                    <PractitionerDetails practitionerDetails={obj} detailPath={detailPath} className={styles.details} />
                    <PractitionerAvailabilityCalendar
                      practitionerDetails={obj}
                      detailPath={detailPathWithTimeSlot}
                      nextAvailabilityDetails={obj.nextAvailabilities?.filter(
                        (item) =>
                          (isNewClient
                            ? item.appointmentTypeBookingRules?.new.available
                            : item.appointmentTypeBookingRules?.existing.available) &&
                          item.appointmentTypeClaimType === clientAppointmentType
                      )}
                      isDarkYellowPill={i % 2 === 1}
                      onShowWaitlistForm={() => {
                        setSelectedPractitioner(obj);
                        setShowWaitlistForm(true);
                      }}
                      noFilter
                    />
                  </div>
                </div>
              </HelmContentLayout>
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );

  return (
    <HelmHelmetWrapper title={'HELM - Behavioural Health Specialists'}>
      <div className={styles.headerContainer}>
        <HelmHeader
          withPadding
          whiteFont
          withMenu
          noLogin
          middleChildren={
            <div className={styles.headerTitleWrapper}>
              <div className={styles.headerTitle}>Find Your Counsellor</div>
            </div>
          }
        />
      </div>
      <FitFilter
        selectedFitValue={selectedFitFilter}
        onChangeFitValue={handleChangeFit}
        handleChangeTimeZone={handleChangeTimeZone}
        selectedTimezone={selectedTimezone}
        handleFitResetValue={handleFitResetValue}
      />
      {isPractitionerListLoading || isClientBookingRuleLoading ? (
        <div className={styles.container}>
          {[...Array(4)].map((obj, i) => (
            <div key={i} className={styles.contentWrapper}>
              <HelmContentLayout>
                <div className={styles.content}>
                  <div className={styles.loadingWrapper}>
                    <div className={styles.profileWrapper}>
                      <Skeleton.Avatar active size={70} className={styles.headerLoading} />
                      <div className={styles.bonaFidesLoadingWrapper}>
                        <Skeleton.Button active className={styles.bonaFidesLoading} />
                        <Skeleton.Button active className={styles.bonaFidesLoading} />
                      </div>
                    </div>
                    <div className={styles.detailsContainer}>
                      <Skeleton active />
                    </div>
                  </div>
                </div>
              </HelmContentLayout>
            </div>
          ))}
        </div>
      ) : psychologistList.length > 0 ? (
        psychologistListCard(psychologistList)
      ) : (
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <HelmContentLayout>
              <div className={styles.noResultWrapper}>
                <div className={styles.noResultTitle}>Can’t find what you’re looking for?</div>
                <div className={styles.noResultDesc}>
                  Sorry, we’re unable to provide a match. Please adjust your criteria or{' '}
                  <span className={styles.clearAllFiltersButton} onClick={clearAllFilter}>
                    clear all filters
                  </span>
                </div>
              </div>
            </HelmContentLayout>
          </div>
          <div className={styles.contentWrapper}>
            <HelmContentLayout>
              <div className={styles.recommendWrapper}>
                <div className={styles.trustLabel}>
                  <span className={styles.label}>Please consider one of our other clinicians</span>
                </div>
              </div>
            </HelmContentLayout>
            {psychologistListCard(psychologistListNoResult, true)}
          </div>
        </div>
      )}
      <HelmFooter hideProgram />
      {/* Join waitlist modal */}
      <Modal
        width={700}
        visible={showWaitlistForm && !!selectedPractitioner}
        onCancel={() => {
          setShowWaitlistForm(false);
        }}
        bodyStyle={{ padding: '40px 80px', top: 50 }}
        footer={null}
        destroyOnClose
      >
        {selectedPractitioner && (
          <JoinWaitlistForm
            onComplete={() => {
              setShowWaitlistForm(false);
            }}
            practitionerDetails={selectedPractitioner}
          />
        )}
      </Modal>
    </HelmHelmetWrapper>
  );
};

export default PartnerPractitionerListing;
