export const cawEnvironment = () => ({
  CaWHomeURL: 'https://work.caraniche.com.au/',
  CaWAboutURL: 'https://work.caraniche.com.au/about/',
  CaWOurApproachURL: 'https://work.caraniche.com.au/about/our-approach/',
  CaWOurPeopleURL: 'https://work.caraniche.com.au/about/our-people/',
  CaWOurServiceURL: 'https://work.caraniche.com.au/services/',
  CaWOurEnhanceURL: 'https://work.caraniche.com.au/services/enhance/',
  CaWOurSupportURL: 'https://work.caraniche.com.au/services/support/',
  CaWOurConnectURL: 'https://work.caraniche.com.au/services/connect/',
  CaWOurResourcesURL: 'https://work.caraniche.com.au/resources/',
  CaWTrainingURL: 'https://work.caraniche.com.au/training/',
  CaWCareersURL: 'https://work.caraniche.com.au/career/',
  CaWContactUsURL: 'https://work.caraniche.com.au/contact/',
  CaWFeedbackURL: 'https://work.caraniche.com.au/feedback/',
  CaWYourPrivacyNConfidentialityURL: 'https://work.caraniche.com.au/your-privacy-confidentiality/',
  CaWChildSafetyURL: 'https://work.caraniche.com.au/child-safety-and-our-statement-of-commitment/',
  CaWTermAndConditions: 'https://work.caraniche.com.au/website-terms-conditions/',
  CaWPrivacyPolicy: 'https://work.caraniche.com.au/website-privacy-policy/',
  CaWSupportMail: 'admin@caraniche.com.au',
  HelpSupportTel: '(03) 8417 0500'
});

export const DefaultClientTimeZone = 'Australia/Melbourne';
