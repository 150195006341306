import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import PortobelloHeader from '../../components/Layout/PortobelloHeader/PortobelloHeader';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloContentLayout from '../../components/Layout/PortobelloContentLayout/PortobelloContentLayout';

const ConsentListWrapper = () => (
  <PortobelloHelmetWrapper title={`Portobello Behavioural Health | Consent List`}>
    <PortobelloHeader withPadding withMenu homeLink />
    <div className={styles.container}>
      <PortobelloContentLayout className="portobello-theme">
        <ConsentList />
      </PortobelloContentLayout>
    </div>
  </PortobelloHelmetWrapper>
);

export default ConsentListWrapper;
