import styles from './IP6YourProgress.module.scss';
import IP6ProgressBox from './components/IP6ProgressBox/IP6ProgressBox';
import { ProgramInterface } from 'helm/interfaces/program/program';

interface IP6YourProgressProps {
  data: ProgramInterface;
  handleNextActivityClick?: number;
}

const IP6YourProgress = ({ data, handleNextActivityClick }: IP6YourProgressProps) => {
  return (
    <div id={'yourProgress'} className={styles.container}>
      <div className={styles.title}>Your Progress</div>
      <div className={styles.graphContent}>
        <IP6ProgressBox data={data} handleNextActivityClick={handleNextActivityClick} />
      </div>
    </div>
  );
};

export default IP6YourProgress;
