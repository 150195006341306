import { useState } from 'react';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import queryString from 'query-string';
import NoAuthLayout from '../../../layouts/NoAuthLayout/NoAuthLayout';
import SignUpProgressBar from './components/SignUpProgressBar/SignUpProgressBar';
import BasicDetails from './components/BasicDetails/BasicDetails';
import ContentLayout from '../../../components/ContentLayout/ContentLayout';
import {
  BasicDetailsErrorInterface,
  ClientSignUpInterface,
  ClientSignUpQueryParamInterface,
  PersonalisationErrorInterface,
  SecurityErrorInterface
} from './SignUpFormInterface';
import Button from 'components/v2/Button/Button';
import Security from './components/Security/Security';
import Personalisation from './components/Personalisation/Personalisation';
import {
  validatePhoneNumber,
  validationBasicDetailsForm
} from './components/BasicDetails/validation/BasicDetailsValidation';
import { validateEmailInvite, validationSecurityForm } from './components/Security/validation/SecurityValidation';
import {
  PersonalisationInterface,
  validationPersonaliseForm
} from './components/Personalisation/validation/PersonaliseValidation';
import { scrollToView } from 'utils/scrollToView';
import { postPatientAvatar, postPatientSignup } from 'utils/http/PatientProfileService/Patient/patient';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useFetchInvitedClinicianDetails } from 'pages/SignUp/hooks/getInvitedClinicianDetails';
import Loading from 'components/Loading/Loading';

import styles from './SignUpForm.module.scss';
import { useTranslation } from 'react-i18next';

const stages = [
  {
    id: 1,
    label: 'Create account',
    isCompleted: false
  },
  {
    id: 2,
    label: 'Security',
    isCompleted: false
  },
  {
    id: 3,
    label: 'Personalise',
    isCompleted: false
  }
];

const SignUpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clinicianSlugUrlOrId = '' } = useParams<{ clinicianSlugUrlOrId: string }>();
  const { SIGNUP } = useRoutesGenerator(clinicianSlugUrlOrId);
  const queryParam: ClientSignUpQueryParamInterface = queryString.parse(location.search);

  const isPolicyChecked = Cookies.get('signUpPolicy');
  if (!isPolicyChecked) {
    navigate(`${SIGNUP.POLICY}${location.search}`);
  }

  const initialValues = {
    firstName: queryParam.firstname || '',
    lastName: queryParam.lastname || '',
    postcode: queryParam.postcode || '',
    dateOfBirth: queryParam.dob || '',
    mobileNumber: queryParam.mobile || '',
    password: '',
    passwordScore: 0,
    email: queryParam.email || '',
    nickname: '',
    avatar: ''
  } as ClientSignUpInterface;

  const [currentPageStep, setCurrentPageStep] = useState(1);
  const [clientDetails, setClientDetails] = useState(initialValues);
  const [isSubmitPage1, setIsSubmitPage1] = useState(false);
  const [isSubmitPage2, setIsSubmitPage2] = useState(false);
  const [isSubmitPage3, setIsSubmitPage3] = useState(false);

  const [page1SubmitStatus, setPage1SubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [page2SubmitStatus, setPage2SubmitStatus] = useState<'' | 'active' | 'finished'>('');
  const [page3SubmitStatus, setPage3SubmitStatus] = useState<'' | 'active' | 'finished'>('');

  const { invitedClinicianDetail, isInvitedCLoading } = useFetchInvitedClinicianDetails(clinicianSlugUrlOrId);
  const [t] = useTranslation();

  const validatePart1Field = async (clientVal: BasicDetailsErrorInterface) => {
    const BasicDetailsForm = validationBasicDetailsForm(clientVal);
    const checkFieldHaveError = Object.values(BasicDetailsForm as BasicDetailsErrorInterface).some(
      (value) => value !== ''
    );

    let checkPhoneValid = false;
    if (checkFieldHaveError) {
      setCurrentPageStep(1);
      setIsSubmitPage1(true);
    } else {
      const phoneValidate = await validatePhoneNumber(clientVal.mobileNumber);
      if (phoneValidate.valid) {
        checkPhoneValid = true;
      }
    }
    return !checkFieldHaveError && checkPhoneValid;
  };

  const validatePart2Field = async (clientVal: SecurityErrorInterface) => {
    const SecurityForm = validationSecurityForm(clientVal);
    const checkFieldHaveError = Object.values(SecurityForm as SecurityErrorInterface).some((value) => value !== '');

    let checkEmailValid = false;
    if (checkFieldHaveError) {
      setCurrentPageStep(2);
      setIsSubmitPage2(true);
    } else {
      const invitedOrUsed = await validateEmailInvite(invitedClinicianDetail.accountId, clientVal.email);
      checkEmailValid = !!invitedOrUsed?.invited && !invitedOrUsed?.used;
    }

    return !checkFieldHaveError && checkEmailValid;
  };

  const validatePart3Field = async (clientVal: PersonalisationErrorInterface) => {
    const personaliseForm = validationPersonaliseForm(clientVal);
    const checkFieldHaveError = Object.values(personaliseForm as PersonalisationErrorInterface).some(
      (value) => value !== ''
    );
    return !checkFieldHaveError;
  };

  const handleSubmitBasicDetails = async () => {
    setPage1SubmitStatus('active');
    setIsSubmitPage1(true);
    if (await validatePart1Field(clientDetails)) {
      setTimeout(() => {
        setPage1SubmitStatus('finished');
      }, 500);
      setTimeout(() => {
        setCurrentPageStep(2);
        scrollToView('signUpFormHeader', true);
        setPage1SubmitStatus('');
      }, 1000);
    } else {
      setPage1SubmitStatus('');
    }
  };

  const handleSubmitSecurityDetails = async () => {
    setPage2SubmitStatus('active');
    setIsSubmitPage2(true);
    if (await validatePart2Field(clientDetails)) {
      setTimeout(() => {
        setPage2SubmitStatus('finished');
      }, 500);
      setTimeout(() => {
        setCurrentPageStep(3);
        scrollToView('signUpFormHeader', true);
        setPage2SubmitStatus('');
      }, 1000);
    } else {
      setPage2SubmitStatus('');
    }
  };

  const handleSubmitPersonaliseDetails = async () => {
    setPage3SubmitStatus('active');
    setIsSubmitPage3(true);
    if (
      (await validatePart3Field(clientDetails)) &&
      (await validatePart1Field(clientDetails)) &&
      (await validatePart2Field(clientDetails))
    ) {
      try {
        const avatarPayload = {
          avatar: clientDetails.avatar
        };
        const avatarUrl = await (await postPatientAvatar(avatarPayload)).text();

        const massagePayload = {
          accountId: invitedClinicianDetail.accountId,
          avatar: avatarUrl,
          mobile: clientDetails.mobileNumber,
          email: clientDetails.email,
          name: clientDetails.nickname,
          password: clientDetails.password,
          dateOfBirth: clientDetails.dateOfBirth,
          firstName: clientDetails.firstName,
          lastName: clientDetails.lastName,
          postcode: clientDetails.postcode
        };

        const callPostSignUp = await (await postPatientSignup(massagePayload)).json();
        const successToken = callPostSignUp.token;
        setTimeout(() => {
          setPage3SubmitStatus('finished');
        }, 500);
        setTimeout(() => {
          navigate(`${SIGNUP.SUCCESS}?successToken=${successToken}`);
          setPage3SubmitStatus('');
        }, 1000);
      } catch (ex) {
        console.error(ex);
        notification.error({
          message: t('form.error.create_client'),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
    } else {
      setPage3SubmitStatus('');
    }
  };

  const onHandleClickOnStep = (step: number) => {
    setCurrentPageStep(step);
  };

  const handleChangeBasicDetails = (val: ClientSignUpInterface) => {
    setClientDetails(val);
  };

  const handleChangeNameAndAvatar = (val: PersonalisationInterface) => {
    setClientDetails({ ...clientDetails, ...val });
  };

  const renderForm = (stage: number) => {
    switch (stage) {
      case 1:
        return (
          <>
            <BasicDetails
              checkValidation={isSubmitPage1}
              basicDetailsValue={clientDetails}
              onChangeClientField={handleChangeBasicDetails}
            />
            <div className={styles.submitButtonContainer}>
              <Button
                disabled={page1SubmitStatus !== ''}
                className={styles.submitBtn}
                status={page1SubmitStatus}
                onClick={handleSubmitBasicDetails}
              >
                Next, secure your account
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <Security
              checkValidation={isSubmitPage2}
              securityDetailsValue={clientDetails}
              onChangeSecurityField={handleChangeBasicDetails}
              accountId={invitedClinicianDetail.accountId}
              invitationFromName={invitedClinicianDetail.practice?.name || invitedClinicianDetail?.name || ''}
            />
            <div className={styles.submitButtonContainer}>
              <Button
                disabled={page2SubmitStatus !== ''}
                className={styles.submitBtn}
                status={page2SubmitStatus}
                onClick={handleSubmitSecurityDetails}
              >
                Next, personalise your account
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <Personalisation
              checkValidation={isSubmitPage3}
              personalisationValue={clientDetails}
              onChangePersonalisationField={handleChangeNameAndAvatar}
            />
            <div className={styles.submitButtonContainer}>
              <Button
                disabled={page3SubmitStatus !== ''}
                className={styles.submitBtn}
                status={page3SubmitStatus}
                onClick={handleSubmitPersonaliseDetails}
              >
                Set up account details
              </Button>
            </div>
          </>
        );

      default:
        return;
    }
  };

  return isInvitedCLoading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <NoAuthLayout>
      <div className={styles.container}>
        <div id={'signUpFormHeader'} />
        <SignUpProgressBar stages={stages} activeStage={currentPageStep} onHandleClickOnStep={onHandleClickOnStep} />
        <ContentLayout>
          <div className={styles.formContainer}>{renderForm(currentPageStep)}</div>
        </ContentLayout>
      </div>
    </NoAuthLayout>
  );
};

export default SignUpForm;
