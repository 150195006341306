import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import PortobelloHelmetWrapper from '../../components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloHeader from '../../components/Layout/PortobelloHeader/PortobelloHeader';
import PortobelloContentLayout from '../../components/Layout/PortobelloContentLayout/PortobelloContentLayout';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const { PRACTITIONER } = useSelectRoutesGenerator();

  return (
    <PortobelloHelmetWrapper title={`Portobello Behavioural Health | Appointments`}>
      <PortobelloHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <PortobelloContentLayout className="portobello-theme">
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientProfileTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </PortobelloContentLayout>
      </div>
    </PortobelloHelmetWrapper>
  );
};

export default AppointmentsOverview;
