import { useAuth0 } from '@auth0/auth0-react';
import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';

import styles from './SomeoneHealthSurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';

const SomeoneHealthSurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );

  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Surveys`}>
      <div
        className={styles.container}
        style={
          !isBackgroundImageUrlLoading && backgroundImageUrl
            ? {
                backgroundImage: `url('${backgroundImageUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }
            : {}
        }
      >
        <SomeoneHealthHeader withPadding withMenu homeLink />
        <SomeoneHealthContentLayout className={styles.contentWrapper}>
          <SurveyList />
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default SomeoneHealthSurveyListWrapper;
