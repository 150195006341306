import { notification } from 'antd';
import { formatGraphData } from 'helm/utils/formatGraphData';
import { ChartData } from 'interfaces/MicroJournal/Chart';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getCheckInInsights } from 'utils/http/checkIn';

export const useFetchEnergyCheckInGraph = ({ token, dateRange = 30 }: { token: string; dateRange?: number }) => {
  const [energyCheckInGraph, setEnergyCheckInGraph] = useState<ChartData['insights']>([]);
  const [isEnergyCheckInGraphLoading, setIsEnergyCheckInGraphLoading] = useState(true);

  const fetchEnergyCheckInGraph = useCallback(async ({ token, dateRange }: { token: string; dateRange: number }) => {
    setIsEnergyCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights({
        token,
        queryParam: { startDate, endDate, type: 'energy' }
      });
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setEnergyCheckInGraph(formatGraphData(insights));
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your energies tracking'
      });
    }

    setIsEnergyCheckInGraphLoading(false);
  }, []);

  const refetch = (dateRange: number) => {
    if (token) {
      fetchEnergyCheckInGraph({ token, dateRange });
    }
  };

  useEffect(() => {
    if (token) {
      fetchEnergyCheckInGraph({ token, dateRange });
    }
  }, [fetchEnergyCheckInGraph, dateRange, token]);

  return {
    energyTitle: 'How are your energy levels?',
    energyCheckInGraph,
    isEnergyCheckInGraphLoading,
    fetchEnergyCheckInGraph: refetch
  };
};
