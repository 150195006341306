import Button from 'components/v2/Button/Button';
import styles from './ClientKeyContactDetails.module.scss';
import { ClientKeyContact } from 'components/UpdateProfileContent/interface';

interface ClientKeyContactDetailsProps {
  clientKeyContact: ClientKeyContact;
  onRemove: () => void;
}

const ClientKeyContactDetails = ({ clientKeyContact, onRemove }: ClientKeyContactDetailsProps) => {
  return (
    <div className={styles.container}>
      <Button icon="delete_forever" variant="secondary" className={styles.removeBtn} onClick={onRemove} />
      <div className={styles.detail}>
        <div className={styles.nameWrapper}>
          <div className={styles.name}>{`${clientKeyContact.firstName ? `${clientKeyContact.firstName} ` : ''}${
            clientKeyContact.lastName || ''
          }`}</div>
          <div className={styles.relationships}>
            {clientKeyContact.tags?.map((tag, index) => (
              <div className={styles.relationship} key={index}>
                {tag}
              </div>
            ))}
          </div>
        </div>
        {clientKeyContact.mobileNumber && <div>{clientKeyContact.mobileNumber}</div>}
        {clientKeyContact.email && <div>{clientKeyContact.email}</div>}
      </div>
    </div>
  );
};

export default ClientKeyContactDetails;
