import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const getInitialCustomer = (presetEmployerCode?: string) => ({
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  postcode: '',
  password: '',
  theme: '',
  receiveSMS: true,
  employerCode: presetEmployerCode || '',
  issueSource: '',
  directorate: '',
  department: '',
  serviceFor: '',
  serviceForOther: '',
  initialPresentingIssue: ''
});

export const workplaceOrPersonal = [
  {
    value: 'workplace',
    label: 'Workplace'
  },
  {
    value: 'personal',
    label: 'Personal'
  }
];

export const serviceForOptions = [
  {
    value: 'Employee',
    label: 'Employee'
  },
  {
    value: 'Immediate Family Member',
    label: 'Immediate Family Member'
  },
  {
    value: 'Volunteer',
    label: 'Volunteer'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const initialPresentingIssueOptions = [
  {
    value: 'Emotional Difficulties',
    label: 'Emotional Difficulties'
  },
  {
    value: 'COVID',
    label: 'COVID'
  },
  {
    value: 'Do not wish to disclose',
    label: 'Do not wish to disclose'
  },
  {
    value: 'Substance Use',
    label: 'Substance Use'
  },
  {
    value: 'Family concerns (including family violence)',
    label: 'Family concerns (including family violence)'
  },
  {
    value: 'Gambling/Gaming',
    label: 'Gambling/Gaming'
  },
  {
    value: 'Grief & Loss',
    label: 'Grief & Loss'
  },
  {
    value: 'Physical Health',
    label: 'Physical Health'
  },
  {
    value: 'Depression',
    label: 'Depression'
  },
  {
    value: 'Interpersonal Relationship concerns',
    label: 'Interpersonal Relationship concerns'
  },
  {
    value: 'Trauma (inc. Single event and Vicarious)',
    label: 'Trauma (inc. Single event and Vicarious)'
  },
  {
    value: 'Anxiety',
    label: 'Anxiety'
  },
  {
    value: 'Alleged Bullying/Harassment',
    label: 'Alleged Bullying/Harassment'
  },
  {
    value: 'Career Challenges (inc. professional development)',
    label: 'Career Challenges (inc. professional development)'
  },
  {
    value: 'Workplace injury - physical/mental',
    label: 'Workplace injury - physical/mental'
  },
  {
    value: 'Interpersonal Conflict',
    label: 'Interpersonal Conflict'
  },
  {
    value: 'Organisation change/ restructure',
    label: 'Organisation change/ restructure'
  },
  {
    value: 'Performance Management',
    label: 'Performance Management'
  },
  {
    value: 'Return/Remain at work plan',
    label: 'Return/Remain at work plan'
  },
  {
    value: 'Stress/Burnout',
    label: 'Stress/Burnout'
  },
  {
    value: 'Occupational Violence',
    label: 'Occupational Violence'
  },
  {
    value: 'Other',
    label: 'Other'
  },
  {
    value: 'Supervision - Client/case work skills development',
    label: 'Supervision - Client/case work skills development'
  },
  {
    value: 'Supervision - Challenging client behaviour',
    label: 'Supervision - Challenging client behaviour'
  },
  {
    value: 'Supervision - Reflective practice',
    label: 'Supervision - Reflective practice'
  },
  {
    value: 'Supervision - Wellbeing support/self-care',
    label: 'Supervision - Wellbeing support/self-care'
  },
  {
    value: 'Supervision - Team dynamics/workplace relationships',
    label: 'Supervision - Team dynamics/workplace relationships'
  },
  {
    value: 'Supervision - Workload pressure',
    label: 'Supervision - Workload pressure'
  },
  {
    value: 'Supervision - Systemic challenges',
    label: 'Supervision - Systemic challenges'
  },
  {
    value: 'Supervision - Change management',
    label: 'Supervision - Change management'
  },
  {
    value: 'Supervision - Other',
    label: 'Supervision - Other'
  }
];

export const getAuMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+61' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const passwordValidationSchema = (pwScore: number) =>
  Yup.object().shape({
    password: Yup.string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Weak password!', () => pwScore === 4)
      .nullable()
  });

export const formDataValidationSchema = (customAgeCheck?: number) => {
  const dobAge = customAgeCheck || 18;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    dateOfBirth: Yup.string()
      .required('Please choose your date of birth')
      .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
        return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
      }),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    postcode: Yup.string().required('Please enter your residential postcode'),
    employerCode: Yup.string().required('Please select your employer code'),
    issueSource: Yup.string().required('Please select your issue source'),
    directorate: Yup.string().required('Please enter your directorate'),
    department: Yup.string().required('Please enter your role'),
    serviceFor: Yup.string().required('Please indicate who this service is for'),
    serviceForOther: Yup.string().when('serviceFor', {
      is: 'Other',
      then: Yup.string().required('Please indicate who this service is for'),
      otherwise: Yup.string()
    }),
    initialPresentingIssue: Yup.string().required('Please select your initial presenting issue')
  });
};
