import { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { get } from 'lodash';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';

import styles from './Button.module.scss';

export interface ButtonProps {
  status?: '' | 'active' | 'finished';
  variant?: 'secondary' | 'primary' | 'pink' | 'link';
  icon?: string;
  iconPostFix?: boolean;
}

const Button = ({ status = '', variant, ...props }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const statusClass = get({ active: styles.active, finished: styles.finished }, status, '');
  const { className } = props;

  return (
    <button {...props} className={classnames(styles.container, className, statusClass, variant && styles[variant])}>
      <span className={styles.submit}>{props.children}</span>
      <span className={styles.loading}>
        <SyncOutlined />
      </span>
      <span className={styles.check}>
        <CheckOutlined />
      </span>
    </button>
  );
};

export default Button;
