import { Route, Routes } from 'react-router-dom';
import RedirectTo404 from 'components/RedirectTo404/RedirectTo404';
import WelcomePage from '../pages/SignUp/WelcomePage/WelcomePage';
import WelcomeTaskList from '../pages/SignUp/WelcomeTaskList/WelcomeTaskList';
import PolicyPage from '../pages/SignUp/PolicyPage/PolicyPage';
import AppointmentEntryPage from '../pages/PatientAppointment/AppointmentEntryPage/AppointmentEntryPage';
import ConsentFormSignUp from '../pages/ConsentForm/ConsentFormSignUp/ConsentFormSignUp';
import PatientRoute from '../components/PatientRoute/PatientRoute';
import HomePage from '../pages/PatientPage/HomePage/HomePage';
import RescheduleApproval from '../pages/Appointments/RescheduleApproval';
import PatientAdHocAssessment from '../pages/PatientAdHocAssessment/PatientAdHocAssessment';
import PatientCheckIn from '../pages/PatientCheckIn/PatientCheckIn';
import InvoiceViewPage from '../pages/InvoiceViewPage/InvoiceViewPage';
import ReportViewPage from '../pages/ReportViewPage/ReportViewPage';
import AvailableAppointmentTypes from '../pages/PatientAppointment/AvailableAppointmentTypes/AvailableAppointmentTypes';
import AppointmentBooking from '../pages/PatientAppointment/AppointmentBooking/AppointmentBooking';
import AppointmentBookingType from '../pages/PatientAppointment/AppointmentBookingType/AppointmentBookingType';
import AppointmentBookingNewCustomer from '../pages/PatientAppointment/AppointmentBookingNewCustomer/AppointmentBookingNewCustomer';
import AppointmentConfirmed from '../pages/PatientAppointment/AppointmentConfirmed/AppointmentConfirmed';
import AppointmentRequest from '../pages/PatientAppointment/AppointmentRequest/AppointmentRequest';
import AppointmentRequestSent from '../pages/PatientAppointment/AppointmentRequestSent/AppointmentRequestSent';
import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import SignUpForm from '../pages/SignUp/SignUpForm/SignUpForm';
import SuccessSignUpPage from '../pages/SignUp/SuccessSignUpPage/SuccessSignUpPage';
import appRoute from '../components/AppRoute/AppRoute';
import SignUpWrapper from '../pages/SignUp/components/SignUpWrapper/SignUpWrapper';
import PsychometricList from 'pages/Psychometric/PsychometricList/PsychometricList';
import AppointmentsOverview from 'pages/AppointmentsOverview/AppointmentsOverview';
import { useRoutesGenerator } from '../utils/Path/RoutesGenerator';
import UpdateProfile from 'pages/UpdateProfile/UpdateProfile';
import SurveyListWrapper from '../pages/Survey/SurveyListWrapper/SurveyListWrapper';
import SurveyQuestionWrapper from '../pages/Survey/SurveyQuestionWrapper/SurveyQuestionWrapper';
import RedirectClinician from 'components/RedirectClinician/RedirectClinician';
import LetterViewPage from 'pages/LetterViewPage/LetterViewPage';

const ClientRoutes = ({ match }: any) => {
  const initPath = match && match.path && match.path !== '/' ? match.path : '';
  const { APPOINTMENT_OVERVIEW, UPDATE_PROFILE } = useRoutesGenerator();
  return (
    <Routes>
      <Route path={`${initPath}/profile/:clinicianSlugUrlOrId`} element={<AppointmentEntryPage />} />
      <Route path={`${initPath}/:clinicianSlugUrlOrId/checkin`} element={<PatientCheckIn />} />
      <Route path={`${initPath}/invoice/:invoiceId`} element={<InvoiceViewPage />} />
      <Route path={`${initPath}/report/:reportId`} element={<ReportViewPage />} />
      <Route path={`${initPath}/accounts/:accountId/letters/:letterId`} element={<LetterViewPage />} />

      <Route
        path={`${initPath}/:clinicianId/appointment`}
        element={<PatientRoute component={AvailableAppointmentTypes} />}
      />
      <Route path={`${initPath}/:clinicianId/appointment/new`} element={<AvailableAppointmentTypes />} />
      <Route path={`${initPath}/:clinicianId/appointment/booking`} element={<AppointmentBooking />} />
      <Route path={`${initPath}/:clinicianId/appointment/mode`} element={<AppointmentBookingType />} />
      <Route path={`${initPath}/:clinicianId/appointment/contact`} element={<AppointmentBookingNewCustomer />} />
      <Route path={`${initPath}/:clinicianId/appointment/confirmed`} element={<AppointmentConfirmed />} />
      <Route path={`${initPath}/:clinicianId/appointment/request`} element={<AppointmentRequest />} />
      <Route path={`${initPath}/:clinicianId/appointment/request/sent`} element={<AppointmentRequestSent />} />

      <Route
        path={`${initPath}/surveys`}
        element={
          <RedirectClinician>
            <SurveyListWrapper />
          </RedirectClinician>
        }
      />
      <Route
        path={`${initPath}/psychometrics`}
        element={
          <RedirectClinician>
            <PsychometricList />
          </RedirectClinician>
        }
      />
      <Route
        path={`${initPath}/surveys/:surveyId`}
        element={
          <RedirectClinician>
            <SurveyQuestionWrapper />
          </RedirectClinician>
        }
      />
      <Route
        path={`${initPath}/:clinicianSlugUrlOrId/assessment/:assessmentId`}
        element={
          <RedirectClinician>
            <PatientAdHocAssessment />
          </RedirectClinician>
        }
      />

      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/welcome`,
        component: WelcomePage,
        layout: SignUpWrapper
      })}
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/task`,
        component: WelcomeTaskList,
        layout: SignUpWrapper
      })}
      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/policy`,
        component: PolicyPage,
        layout: SignUpWrapper
      })}
      {appRoute({ path: `${initPath}/:clinicianSlugUrlOrId/signup/new`, component: SignUpForm, layout: SignUpWrapper })}

      {appRoute({
        path: `${initPath}/:clinicianSlugUrlOrId/signup/success`,
        component: SuccessSignUpPage,
        layout: SignUpWrapper
      })}

      <Route element={<AuthLayout />}>
        <Route
          path={`${initPath}/:clinicianId/appt/:id/reschedule`}
          element={<PatientRoute component={RescheduleApproval} />}
        />

        <Route path={`${initPath}/`} element={<HomePage />} />
        <Route path={UPDATE_PROFILE} element={<UpdateProfile />} />
        <Route path={APPOINTMENT_OVERVIEW} element={<AppointmentsOverview />} />
        <Route path={`${initPath}/consent/:consentFormId`} element={<ConsentFormSignUp />} />
        <Route path={`${initPath}/consent`} element={<SurveyListWrapper />} />
        <Route path={`${initPath}/onboarding`} element={<SurveyListWrapper />} />
        <Route path={`${initPath}/onboarding/:surveyId/:type`} element={<SurveyQuestionWrapper />} />

        <Route path={'*'} element={<RedirectTo404 />} />
      </Route>
    </Routes>
  );
};

export default ClientRoutes;
