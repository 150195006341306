import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { getRechargeClinicianDetails } from 'Recharge/utils/http/ClinicianProfileService/Recharge/recharge';

export const useFetchPractitionerDetails = (clinicianId: string) => {
  const [practitionerDetails, setPractitionerDetails] = useState<PractitionerDetailsInterface>(
    {} as PractitionerDetailsInterface
  );
  const [isPractitionerDetailsLoading, setIsPractitionerDetailsLoading] = useState(true);

  const fetchPractitionerDetails = useCallback(async () => {
    if (clinicianId) {
      try {
        setIsPractitionerDetailsLoading(true);
        const response: PractitionerDetailsInterface = await (await getRechargeClinicianDetails(clinicianId)).json();

        setPractitionerDetails(response);
      } catch (ex) {
        console.log(ex);
        notification.error({ message: "Something went wrong while trying to get the psychologist's details" });
      }
      setIsPractitionerDetailsLoading(false);
    }
  }, [clinicianId]);

  useEffect(() => {
    fetchPractitionerDetails();
  }, [fetchPractitionerDetails]);

  return { practitionerDetails, isPractitionerDetailsLoading, fetchPractitionerDetails };
};
