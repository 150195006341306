import { useAuth0 } from '@auth0/auth0-react';

import { removePatientToken } from '../patientToken';

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    removePatientToken();
    auth0Logout({ returnTo: window.location.origin });
  };

  return { logout };
};
