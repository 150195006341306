import { useAuth0 } from '@auth0/auth0-react';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';

import styles from './CaWSurveyListWrapper.module.scss';
import SurveyList from 'pages/Survey/SurveyList/SurveyList';
import CaWHeader from '../../../components/CaWHeader/CaWHeader';

const CaWSurveyListWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <PatientContentLayout className={styles.container}>
      <div className={styles.headerWrapper}>
        <CaWHeader withPadding withMenu homeLink blueMenu />
      </div>
      <div className={styles.contentWrapper}>
        <SurveyList />
      </div>
    </PatientContentLayout>
  );
};

export default CaWSurveyListWrapper;
