import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRechargeRoutesGenerator } from './RechargeRoutesGenerator';

export const useRechargeDoDOrNonDoDPathGenerator = () => {
  const { pathname } = useLocation();
  const { DOD: dodRoutes, PRACTITIONER: practitionerRoutes, SIGNUP: signupRoutes } = useRechargeRoutesGenerator();

  const { PRACTITIONER, SIGNUP, isDoDPath } = useMemo(() => {
    if (pathname.includes('/dod')) {
      return { ...dodRoutes, isDoDPath: true };
    } else {
      return { PRACTITIONER: practitionerRoutes, SIGNUP: signupRoutes, isDoDPath: false };
    }
  }, [dodRoutes, pathname, practitionerRoutes, signupRoutes]);

  return { PRACTITIONER, SIGNUP, isDoDPath };
};
