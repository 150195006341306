import { useMemo } from 'react';
import moment from 'moment';
import BarLineChart from '../ChartComponents/BarLineChart/BarLineChart';
import CardHeader from '../ChartComponents/CardHeader/CardHeader';

import styles from './BarLineChartWidget.module.scss';

interface BarLineChartWidgetProps {
  data: any;
}

const BarLineChartWidget = ({ data }: BarLineChartWidgetProps) => {
  const isOverallCheckInGraphLoading = false;
  const appetiteTickFormatter = (value: number) => {
    if (value === -18) {
      return 'Under';
    } else if (value === 18) {
      return 'Over';
    } else {
      return 'OK';
    }
  };

  const sleepTickFormatter = (value: number) => {
    const sleepValue = value + 8;

    if (sleepValue === 4) {
      return `<${sleepValue}`;
    } else if (sleepValue === 12) {
      return `${sleepValue}+`;
    } else {
      return `${sleepValue}`;
    }
  };

  const graphData = useMemo(() => {
    const { graphFilter, graphData } = data;
    const startDate = moment().subtract(graphFilter, 'days').format('YYYY-MM-DD');
    return graphData.filter((datum: any) => datum.date >= startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
      <CardHeader title={data.title} />
      <BarLineChart
        data={graphData}
        yDomain={[-18, 18]}
        yLabel={data.label === 'sleepHours' ? 'Hours per night' : 'Eating patterns'}
        yTicks={[-18, 0, 18]}
        yTickFormatter={data.label === 'sleepHours' ? sleepTickFormatter : appetiteTickFormatter}
        isLoading={isOverallCheckInGraphLoading}
        height={200}
      />
    </div>
  );
};

export default BarLineChartWidget;
