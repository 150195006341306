import { useAuth0 } from '@auth0/auth0-react';
import { useGetAttachedClinicianDetailsQuery, useGetClientDetailsQuery } from './client';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useGetClientProfileDetails = () => {
  const { isAuthenticated, user, isLoading: auth0Loading } = useAuth0();

  const { data, isLoading, isFetching, isError } = useGetClientDetailsQuery(null, {
    skip:
      !isAuthenticated || auth0Loading || (isAuthenticated && user?.['https://tacklit.com/roles'].includes('clinician'))
  });

  return {
    clientProfileData: data,
    isClientProfileLoading: isAuthenticated ? isLoading || isFetching : false,
    isError
  };
};

export const useGetAttachedClinicianDetails = () => {
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const { token } = queryString.parse(location.search) as { token?: string };

  const { data, isLoading, isFetching } = useGetAttachedClinicianDetailsQuery(null, {
    skip: (!isAuthenticated && !token) || (isAuthenticated && user?.['https://tacklit.com/roles'].includes('clinician'))
  });

  return {
    attachedClinicianDetail: data,
    customFeatures: data?.clinician?.customFeatures,
    isAttachedClinicianDetailLoading: isAuthenticated ? isLoading || isFetching : false
  };
};
