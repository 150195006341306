import { useRechargeRoutesGenerator } from 'Recharge/utils/Path/RechargeRoutesGenerator';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { HOME } = useRechargeRoutesGenerator();

  useEffect(() => {
    navigate(HOME, { replace: true });
  }, [HOME, navigate]);

  return <></>;
};

export default LoginPage;
