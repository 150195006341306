import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import { SelectContactUrl } from 'Select/utils/env/SelectEnv';
import styles from './QuestionBox.module.scss';

const QuestionBox = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Have a question?</div>
      <div className={styles.contact}>
        Our team are available and happy to answer your questions Monday - Friday 9am - 5pm (4.30pm on a Friday).
      </div>
      <ButtonSelect
        className={styles.contactBtn}
        variant="outlined"
        onClick={() => (window.location.href = SelectContactUrl)}
      >
        Get in touch with our support team
      </ButtonSelect>
    </div>
  );
};

export default QuestionBox;
