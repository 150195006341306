import CaWContentLayout from 'CaW/components/CaWContentLayout/CaWContentLayout';
import CaWHeader from 'CaW/components/CaWHeader/CaWHeader';
import CaWHelmetWrapper from 'CaW/components/CaWHelmetWrapper/CaWHelmetWrapper';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useCallback, useEffect, useMemo } from 'react';
import { Path, useLocation } from 'react-router-dom';
import { useCawUserSession } from '../SignUp/helper/userSession';

interface ExpectedState {
  formId?: string;
}

const CaWAssessmentsPage = () => {
  const { state } = useLocation() as Path & { state?: ExpectedState };

  const { retrieveUserSession } = useCawUserSession();

  const userSession = useMemo(retrieveUserSession, [retrieveUserSession]);

  const postFormSubmit = useCallback(async (e?: any) => {
    // paperform event
    // if (
    //   Array.isArray(e?.detail?.data) &&
    //   e.detail.data.some(
    //     (data: any) =>
    //       data?.key && data.value === "Yes" && accountSettings?.formSubmissionAlertFieldKeys?.includes(data.key)
    //   ) &&
    //   URLRoomName
    // ) {
    //   await postClientRequestHelp(URLRoomName, { senderId: user?._id, name: user?.name });
    // }
    //
    // actions after paperform submitted
    // if (!postSubmitRef.current && state) {
    //   setIsSubmitting(true);
    //   if (state.joinRoom && state.name && state.roomName) {
    //     await joinRoom(state.name, state.roomName);
    //   }
    //   if (state.redirectPath) {
    //     navigate(state.redirectPath);
    //   }
    // }
  }, []);

  useEffect(() => {
    if (!state?.formId || !userSession?.clientRecord) {
      postFormSubmit();
    }
  }, [postFormSubmit, state?.formId, userSession?.clientRecord]);

  useEffect(() => {
    if (
      !Array.from(document.body.getElementsByTagName('script')).some(
        (elem) => elem.src === 'https://paperform.co/__embed.min.js'
      )
    ) {
      const script = document.createElement('script');
      script.src = 'https://paperform.co/__embed.min.js';
      document.body.appendChild(script);
    }

    window.addEventListener('PaperformSubmission', postFormSubmit);

    return () => {
      window.removeEventListener('PaperformSubmission', postFormSubmit);
    };
  }, [postFormSubmit]);

  return (
    <CaWHelmetWrapper title={'Caraniche at Work - Sign Up'}>
      <CaWContentLayout>
        <CaWHeader withPadding blueMenu />
        {state?.formId && (
          <div
            data-paperform-id={state.formId}
            data-spinner
            data-prefill={
              userSession?.clientRecord &&
              `account_id=${userSession.clientRecord.accountId}&client_record_id=${userSession.clientRecord._id}&client_profile_id=${userSession.clientRecord.clientProfiles[0]?._id}`
            }
          />
        )}
      </CaWContentLayout>
      <TacklitFooter />
    </CaWHelmetWrapper>
  );
};

export default CaWAssessmentsPage;
