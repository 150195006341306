import { AppointmentSlot } from 'utils/hooks/appointment';

export interface PublicHoliday {
  country: string;
  selection: string;
  stateCode: string;
  date: string;
  holiday: string;
}

export interface AppointmentsOrSlots {
  date: string;
  appointmentSlots: AppointmentSlot[];
  blocked: boolean;
  occupancy: number;
  publicHoliday?: PublicHoliday;
}

export interface TimeSlot {
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
}

export enum NoAvailabilityReason {
  TimeHasPassed = 'timeHasPassed',
  IsPublicHoliday = 'isPublicHoliday',
  MaxForwardAvailability = 'maxForwardAvailability',
  MaxSessionReached = 'maxSessionReached',
  MinTimeBufferBeforeBooking = 'minTimeBufferBeforeBooking',
  NotInRoomSchedule = 'notInRoomSchedule',
  NotInClinicianSchedule = 'notInClinicianSchedule',
  NotInAppointmentSchedule = 'notInAppointmentTypeSchedule',
  RoomBusy = 'roomBusy',
  ClinicianBusy = 'clinicianBusy',
  CannotFitGap = 'cannotFitGap'
}

export type AvailabilityTimeSlot =
  | ({ isAvailable: true } & TimeSlot)
  | ({ isAvailable: false; noAvailabilityReason: NoAvailabilityReason } & TimeSlot);

export type AvailabilityTimeSlotsByDate = Record<
  string,
  | { isAvailable: true; timeSlots: AvailabilityTimeSlot[] }
  | { isAvailable: false; noAvailabilityReason: NoAvailabilityReason }
>;

export interface AppointmentTypeAvailabilities {
  appointmentType: AppointmentSlot;
  timeSlots: AvailabilityTimeSlotsByDate;
}
