import { useMemo } from 'react';
import moment from 'moment';

import styles from './InsightsChartWidget.module.scss';
import InsightsLineChart from '../ChartComponents/InsightsLineChart/InsightsLineChart';
import CardHeader from '../ChartComponents/CardHeader/CardHeader';

interface InsightsChartWidgetProps {
  data: any;
}

const InsightsChartWidget = ({ data }: InsightsChartWidgetProps) => {
  const isOverallCheckInGraphLoading = false;

  const graphData = useMemo(() => {
    const { graphFilter, graphData } = data;
    const startDate = moment().subtract(graphFilter, 'days').format('YYYY-MM-DD');
    return graphData.filter((datum: any) => datum.date >= startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
      <CardHeader title={data.title} />
      <InsightsLineChart
        data={graphData}
        yDomain={[1, 11]}
        yTicks={[1, 6, 11]}
        isLoading={isOverallCheckInGraphLoading}
        height={200}
      />
    </div>
  );
};

export default InsightsChartWidget;
