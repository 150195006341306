import { ChangeEvent } from 'react';

import styles from './CheckBox.module.scss';
import classnames from 'classnames';

interface CheckBoxProps {
  id: string;
  value: boolean;
  className?: string;
  label?: any;
  labelClassName?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputClassName?: string
}

const CheckBox = ({ id, value, className, onChange, label, labelClassName, disabled, inputClassName }: CheckBoxProps) => {
  return (
    <label className={classnames(disabled ? styles.disabledContainer : styles.container, className)} htmlFor={id}>
      <input
        disabled={disabled}
        className={classnames(styles.checkbox, inputClassName)}
        id={id}
        type={'checkbox'}
        checked={value}
        onChange={onChange}
      />
      {label && (
        <div className={classnames(styles.checkBoxLabel, labelClassName)} dangerouslySetInnerHTML={{ __html: label }} />
      )}
    </label>
  );
};

export default CheckBox;
