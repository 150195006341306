import { useNavigate } from 'react-router-dom';
import { retrieveUserSession } from '../../SignUp/helper/userSession';
import styles from './DecoupleGPWelcomePage.module.scss';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useSetReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const DecoupleGPWelcomePage = () => {
  const signedUpUser = retrieveUserSession();
  const navigate = useNavigate();
  const { GP } = useSomeoneHealthRoutesGenerator();
  const { removeReserveGPAppointmentData } = useSetReserveGPAppointmentData();

  const onGoToMyAccount = () => {
    removeReserveGPAppointmentData();
    if (!isDevelopmentENV()) {
      window.location.href =
        `${process.env.REACT_APP_CLIENT_DOMAIN_SOMEONE_HEALTH}/gp-booking` || 'https://my.someone.health/gp-booking';
    } else {
      navigate(GP.BASE);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Welcome'}>
      <div className={styles.pageContainer}>
        <SomeoneHealthHeader pinkLogo withPadding classNames={styles.header} />
        <SomeoneHealthContentLayout className={styles.container}>
          <div className={styles.title}>Your account has been created.</div>
          <div className={styles.content}>
            <b>Thanks {signedUpUser.firstName || signedUpUser.clientRecord?.clientProfiles[0].firstName}</b>
            <br />
            <br />
            You've successfully created your new someone.health profile!
            <br />
            <br />
            You can now log into my.someone.health to schedule and manage your appointments, complete intake forms and
            update your personal information.
            <br />
            <br />
            To schedule a GP appointment you will need to login to your account using your email address and password
            you just created.
            <br />
            <br />
            <div>
              <ButtonSH className={styles.button} onClick={onGoToMyAccount}>
                Go to my account
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default DecoupleGPWelcomePage;
