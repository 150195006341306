import { useField } from 'formik';
import { Question } from 'interfaces/Assessment/OnboardingAssessment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';

interface DateProps {
  question: Question;
}

const DATE_FORMAT = 'DD/MM/YYYY';

const Date = ({ question }: DateProps) => {
  const [{ value }, { error, touched }, { setValue }] = useField(`${question.id}.value`);

  const handleOnChange = (e: Moment | null) => {
    setValue(e ? moment(e).format(DATE_FORMAT) : '');
  };
  return (
    <>
      <DatePicker
        id={`date`}
        inputReadOnly
        format={DATE_FORMAT}
        onChange={handleOnChange}
        value={value && moment(value, DATE_FORMAT)}
      />
      <ErrorMessage error={error} visible={touched && !!error} />
    </>
  );
};

export default Date;
