import classNames from 'classnames';

import styles from './SelectContentLayout.module.scss';

interface SelectContentLayoutProps {
  children?: any;
  className?: string;
}

const SelectContentLayout = ({ children, className }: SelectContentLayoutProps) => {
  return <div className={classNames(styles.contentLayout, className)}>{children}</div>;
};

export default SelectContentLayout;
