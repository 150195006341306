import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import styles from './UpdateProfile.module.scss';
import backgroundImg from 'helm/assets/images/background/HelmClientBg.jpg';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';

const UpdateProfile = () => {
  return (
    <HelmHelmetWrapper title={`HELM - Profile`}>
      <PatientContentLayout
        className={styles.container}
        style={{
          backgroundImage: `url('${backgroundImg}')`,
          backgroundColor: 'transparent !important',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div>
          <HelmHeader noWrapper whiteLogo logoWithoutText withMenu withPadding whiteFont homeLink />
          <UpdateProfileContent />
        </div>
      </PatientContentLayout>
    </HelmHelmetWrapper>
  );
};

export default UpdateProfile;
