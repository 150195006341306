import { useMemo, MouseEvent as ReactMouseEvent } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import moment from 'moment';

import Button from 'components/Button/Button';
import ProgressBar from './components/ProgressBar/ProgressBar';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';

import { PatientQuestionFormHeaderProps } from 'components/PatientQuestionForm/PatientQuestionForm';

import styles from './Header.module.scss';

const Header = ({
  completedQuestions,
  totalQuestions,
  patientDetails,
  isFirstQuestionSet,
  isSkippable,
  onBack,
  onSkip,
  onSaveAndExit
}: PatientQuestionFormHeaderProps) => {
  const { user } = useAuth0();
  const { values } = useFormikContext();

  const date = useMemo(() => {
    const today = moment();

    const date = today.format('D');
    const month = today.format('MMMM').substring(0, 3).toUpperCase();

    return `${date} ${month}`;
  }, []);

  const handleBack = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onBack();
  };

  const handleSkip = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSkip(values);
  };

  const handleSaveAndExit = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onSaveAndExit();
  };

  return (
    <div className={styles.container} id="header">
      <div className={styles.top}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div>MICRO JOURNAL</div>
            <div>{date}</div>
          </div>
          <div className={styles.profilePic}>
            <ClientProfileAvatar avatarUrl={user?.picture || patientDetails?.picture} />
          </div>
        </div>
        {isFirstQuestionSet ? (
          <Button className={styles.button} onClick={handleSaveAndExit}>
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
            EXIT JOURNAL
          </Button>
        ) : (
          <Button className={styles.button} onClick={handleBack}>
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
            STEP BACK
          </Button>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.progress}>
          <span>
            QUESTION {completedQuestions + 1} / {totalQuestions}
          </span>
          <ProgressBar completedQuestions={completedQuestions} totalQuestions={totalQuestions} />
        </div>
        {isSkippable && (
          <Button className={styles.button} onClick={handleSkip}>
            SKIP
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
