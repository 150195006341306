import styles from './SuggestionCard.module.scss';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { IS_SOMEONE_HEALTH_APP, IS_CAW_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';

interface SuggestionCardProps {
  title: string;
  desc?: string;
  image?: string;
  button?: {
    label: string;
    link: string;
  };
}

const SuggestionCard = ({ title, desc, image, button }: SuggestionCardProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.desc}>{desc}</div>
    <div className={styles.imageContainer}>
      <img className={styles.image} src={image} alt={'appointment'} />
    </div>
    {button && (
      <div className={styles.buttonContainer}>
        {IS_SOMEONE_HEALTH_APP ? (
          <ButtonSH to={button.link}>{button?.label}</ButtonSH>
        ) : IS_CAW_APP ? (
          <ButtonCaW to={button.link}>{button?.label}</ButtonCaW>
        ) : (
          <Link to={button.link}>
            <Button className={styles.button} variant="primary">
              <span>{button?.label}</span>
            </Button>
          </Link>
        )}
      </div>
    )}
  </div>
);

export default SuggestionCard;
