import styles from './TimerProgress.module.scss';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';
import { Progress } from 'antd';
import classnames from 'classnames';

interface TimerProgressProps {
  className?: string;
  timeLabelClass?: string;
  timeStrokeClass?: string;
  onlyText?: boolean;
  minutes?: number;
  startTime?: string;
  width?: number;
  strokeWidth?: number;
  strokeColor?: string;
  trailColor?: string;
  onExpire?: () => void;
}

const TimerProgress = ({
  className,
  timeLabelClass,
  timeStrokeClass,
  onlyText = false,
  minutes = 10,
  width = 180,
  strokeWidth = 8,
  startTime,
  strokeColor,
  trailColor = '#ecedef',
  onExpire
}: TimerProgressProps) => {
  const {
    seconds: secs,
    minutes: mins,
    hours
  } = useTimer({
    expiryTimestamp: (moment(startTime) || moment()).add(minutes, 'minutes').toDate(),
    onExpire: onExpire
  });
  const exactMins = hours * 60 + mins;
  const totalSeconds = exactMins * 60 + secs;

  return (
    <div className={className}>
      {onlyText ? (
        <div className={timeLabelClass}>
          <div>
            {exactMins < 10 ? '0' + exactMins : exactMins}
            <div>Mins</div>
          </div>
          :
          <div>
            {secs < 10 ? '0' + secs : secs}
            <div>Secs</div>
          </div>
        </div>
      ) : (
        <Progress
          trailColor={trailColor}
          strokeColor={strokeColor}
          className={timeStrokeClass}
          strokeLinecap="square"
          type="circle"
          percent={(totalSeconds * 100) / (minutes * 60)}
          strokeWidth={strokeWidth}
          width={width}
          format={() => (
            <div className={classnames(styles.timeLabel, timeLabelClass)}>
              <div>
                {exactMins < 10 ? '0' + exactMins : exactMins}
                <div className={styles.label}>Mins</div>
              </div>
              :
              <div>
                {secs < 10 ? '0' + secs : secs}
                <div className={styles.label}>Secs</div>
              </div>
            </div>
          )}
        />
      )}
    </div>
  );
};

export default TimerProgress;
