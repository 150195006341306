import styles from './AuthErrorMessage.module.scss';

interface AuthErrorMessageProps {
  requestStorageAccess: (() => Promise<void>) | undefined;
  hasFailedToRequestStorage: boolean;
}

const AuthErrorMessage = ({ requestStorageAccess, hasFailedToRequestStorage }: AuthErrorMessageProps) => (
  <span className={styles.container}>
    {requestStorageAccess && !hasFailedToRequestStorage ? (
      <>
        <p>
          The next prompt will ask you about using cookies.
          <br />
          Please <b>ALLOW</b> cookies to ensure we can deliver you the best experience.
        </p>
        <button className={styles.button} onClick={requestStorageAccess}>
          Got it, Allow Cookies.
        </button>
      </>
    ) : (
      <>
        <p>
          Sorry it looks like there is an issue with your login.
          <br />
          Please return to Tacklit and re-enter Bazaar.
        </p>
        <button
          className={styles.button}
          onClick={() => {
            if (window && window.top && window.top.location && process.env.REACT_APP_CLINICIAN_DOMAIN) {
              window.top.location = process.env.REACT_APP_CLINICIAN_DOMAIN;
            } else {
              console.error('Unable to redirect user back to Mentor view.');
            }
          }}
        >
          Back to Tacklit.com
        </button>
      </>
    )}
  </span>
);

export default AuthErrorMessage;
