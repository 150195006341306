import * as Yup from 'yup';

export const initialContact = {
  firstName: '',
  lastName: '',
  mobileNumber: '',
  relationship: ''
};

export const contactValidation = Yup.object().shape({
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  mobileNumber: Yup.string().required('Please enter contact number'),
  relationship: Yup.string().required('Please select relationship')
});

export const EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS = [
  'Partner',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Parent',
  'Friend',
  'Other Family Member',
  'Other'
];
