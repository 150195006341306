import styles from './PsychologistWelcomeNote.module.scss';

import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'Select/interfaces/mentalHealth';
import PsychologistSpecialisation from 'Select/pages/PsychologistListing/components/PsychologistCard/components/PsychologistSpecialisation/PsychologistSpecialisation';

const PRONOUN_OPTIONS = {
  heHim: 'He / Him',
  sheHer: 'She / Her',
  theyThem: 'They / Them'
};

interface PractitionerWelcomeNoteProps {
  practitionerDetails: PractitionerDetailsInterface;
}

const PsychologistWelcomeNote = ({ practitionerDetails }: PractitionerWelcomeNoteProps) => (
  <div className={styles.container}>
    <div className={styles.greeting}>
      <div className={styles.greet}>Hi, I'm {practitionerDetails.name}</div>
      <div className={styles.subGreet}>
        {practitionerDetails.pronouns
          ? `${PRONOUN_OPTIONS[practitionerDetails.pronouns as keyof typeof PRONOUN_OPTIONS]}`
          : ''}
      </div>
    </div>
    <div className={styles.descWrapper}>
      {practitionerDetails.helmControl?.shortDescription && (
        <div className={styles.desc}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.shortDescription
            }}
          />
        </div>
      )}
      {/* not enabled on clinician-ui, when enabled can show this */}
      {/* {practitionerDetails.helmControl?.credentials && (
        <div className={styles.desc}>
          <div className={styles.label}>EXPERIENCE & CREDENTIALS</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: practitionerDetails.helmControl.credentials
            }}
          />
        </div>
      )} */}
    </div>
    {practitionerDetails.helmControl?.primarySpecialisations &&
      practitionerDetails.helmControl.primarySpecialisations.length > 0 && (
        <div>
          <PsychologistSpecialisation
            label={'MAIN AREAS OF PRACTICE'}
            specialisationsDetails={practitionerDetails.helmControl.primarySpecialisations.map(
              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
            )}
            badgeClassName={styles.mainAreaBadge}
          />
        </div>
      )}
    {practitionerDetails.helmControl?.secondarySpecialisations &&
      practitionerDetails.helmControl.secondarySpecialisations.length > 0 && (
        <div>
          <PsychologistSpecialisation
            label={'I CAN ALSO HELP WITH'}
            specialisationsDetails={practitionerDetails.helmControl.secondarySpecialisations.map(
              (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
            )}
            badgeClassName={styles.subAreaBadge}
          />
        </div>
      )}
    {practitionerDetails.helmControl.bioPhoto && (
      <img
        className={styles.bioPhoto}
        src={practitionerDetails.helmControl.bioPhoto}
        alt={`${practitionerDetails.name} mid-profile`}
      />
    )}
  </div>
);

export default PsychologistWelcomeNote;
