import styles from './PartnerFundingStream.module.scss';
import classNames from 'classnames';

import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import Loading from 'components/Loading/Loading';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { FundingStreamType } from '../PartnerSignUp/HelmPartnerCreateProfileForm/constants';
import { useFetchHelmReservedAppointmentsWithTimeZone } from '../../../utils/hooks/appointment';
import { reservedHelmPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import NDISPlan from './components/NDISPlan/NDISPlan';
import Radio from 'components/Radio/Radio';
import { useState } from 'react';
import ButtonHelm, { ButtonStatusType } from '../../../components/ButtonHelm/ButtonHelm';
import MedicareWithCoPay, { DEFAULT_GP_OPTION } from './components/MedicareWithCoPay/MedicareWithCoPay';
import { MedicareInformation, ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';
import {
  putClientFundingStream,
  putClientMedicare,
  putClientReferralDetail
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import moment from 'moment';
import { notification } from 'antd';
import { MEDICARE_CARD_DEFAULT_VALUE } from 'SomeoneHealth/pages/Booking/Booking';
import { PARTNER_HELM_SIGN_UP_USER, retrieveUserSession } from 'helm/pages/CreateProfile/helper/userSession';
import { useHelmToken } from 'helm/utils/hooks/useHelmToken';
import {
  GPReferral,
  PROVIDE_REFERRAL_DOCUMENTS
} from 'SomeoneHealth/pages/Booking/components/AddGPReferralForm/AddGPReferralForm';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import { useNavigate } from 'react-router-dom';
import { useHelmPartnerRoutesGenerator } from 'helm/PartnerReferral/utils/Path/HelmPartnerRoutesGenerator';
import HelmTimer from 'helm/components/HelmTimer/HelmTimer';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { NDISInterface, NDIS_DEFAULT_VALUE } from './components/NDISPlan/contants';
import { AU_TIME_ZONE_LIST, helmTimeZoneLocalStorage } from 'utils/constants/timeZone';

export const GP_REFERRAL_DEFAULT_VALUE: GPReferral = {
  name: '',
  practiceName: '',
  medicareProviderNumber: '',
  date: '',
  files: [],
  treatmentPlanFile: undefined
};

const PartnerFundingStream = () => {
  const navigate = useNavigate();
  const { SIGNUP } = useHelmPartnerRoutesGenerator();
  const { token } = useHelmToken(PARTNER_HELM_SIGN_UP_USER);
  const clientTimeZone = localStorage.getItem(helmTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const retrieveUserProfile = retrieveUserSession(PARTNER_HELM_SIGN_UP_USER).clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData(reservedHelmPartnerReferral);
  const { fetching, appointments } = useFetchHelmReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const [submitButtonStatus, setSubmitButtonStatus] = useState<ButtonStatusType>('');

  // NDIS Section
  const [fundingStream, setFundingStream] = useState(FundingStreamType.WISE);
  const [ndisDetails, setNDISDetails] = useState<NDISInterface>(NDIS_DEFAULT_VALUE);
  const [isValidNDIS, setIsValidNDIS] = useState(true);
  const handleOnChangeNDIS = (fieldName: string, value: string) => {
    const massageData = {
      ...ndisDetails,
      [fieldName]: value
    };
    setNDISDetails(massageData);
  };

  // Medicare Section
  const massageMedicareDefaultValue: MedicareInformation = {
    ...MEDICARE_CARD_DEFAULT_VALUE,
    firstName: retrieveUserProfile[0]?.firstName || '',
    lastName: retrieveUserProfile[0]?.lastName || '',
    dateOfBirth: retrieveUserProfile[0]?.dateOfBirth || ''
  };

  const [medicareCard, setMedicareCard] = useState<MedicareInformation>(massageMedicareDefaultValue);
  const [isMedicareCardValidating, setIsMedicareValidating] = useState(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>(
    medicareCard.status || {}
  );

  const saveMedicareInfo = async (token: string) => {
    setSubmitButtonStatus('active');
    if (fundingStream === FundingStreamType.MEDICARE) {
      setIsMedicareValidating(true);
      const value: MedicareInformation = medicareCard;
      try {
        const medicareResponse = await putClientMedicare(token, {
          ...value,
          expiryDate: value.expiryDate || null,
          dateOfBirth: moment(value.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
        });

        if (medicareResponse.statusCode === 200) {
          const medicareRes = await medicareResponse.json();
          const valueMe = {
            ...medicareRes,
            dateOfBirth: moment(medicareRes.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
          };
          setMedicareErrorMessage(medicareRes.status);
          setMedicareCard(valueMe);

          if (medicareRes.status?.medicare?.code !== 0 && medicareRes.status?.medicare?.code !== 9626) {
            setSubmitButtonStatus('');
            setIsMedicareValidating(false);
            return;
          }

          let referralUpdateSuccess = 0;
          if (haveGPReferralPlan === 'yes') {
            const payload: ReferralDetailUpdateInterface = {
              files: [],
              isReferredByGP: true,
              isHaveTreatmentPlan: true,
              isGPSentReferral: provideReferralDocuments === 'gp'
            };

            if (gpReferralValue.files.length) {
              payload.files = await Promise.all(
                gpReferralValue.files.map(
                  (file) =>
                    uploadDocumentWithEncryption(token, file) as Promise<{
                      bucketName: string;
                      fileName: string;
                      fileUrl: string;
                    }>
                )
              );
            }

            const referralRes = await putClientReferralDetail(token, payload);

            referralUpdateSuccess = referralRes.statusCode;

            if (referralRes.statusCode === 400) {
              const resMessage = await referralRes.json();
              const message = resMessage.message || 'Something went wrong on your details';
              setSubmitButtonStatus('');
              notification.error({
                message: message,
                duration: 2,
                closeIcon: <span className="success">OK</span>
              });
            }
          }

          if (medicareResponse.statusCode === 200 && (haveGPReferralPlan === 'no' || referralUpdateSuccess === 204)) {
            await handleSaveFundingStreamType(token);
          }
        } else if (medicareResponse.statusCode === 400) {
          setSubmitButtonStatus('');
          setMedicareErrorMessage({
            medicare: {
              code: 9633
            }
          });
        }
      } catch (e) {
        console.error(e);
        notification.error({ message: 'Something went wrong while trying to update your information' });
      } finally {
        setIsMedicareValidating(false);
      }
    } else {
      await handleSaveFundingStreamType(token);
    }
  };

  // GP Section
  const [gpReferralValue, setGPReferralValue] = useState(GP_REFERRAL_DEFAULT_VALUE);
  const [haveGPReferralPlan, setHaveGPReferralPlan] = useState(DEFAULT_GP_OPTION[0].value);
  const [provideReferralDocuments, setProvideReferralDocuments] = useState(PROVIDE_REFERRAL_DOCUMENTS[0].value);

  const checkMedicareValid = () => {
    const isCheckMedicareValid = medicareCard.dva
      ? medicareCard.dva && medicareCard.dvaCardDetails
      : medicareCard.firstName &&
        medicareCard.firstName &&
        medicareCard.dateOfBirth &&
        medicareCard.number &&
        medicareCard.irn &&
        medicareCard.expiryDate;

    const checkReferralFilesValid =
      haveGPReferralPlan === 'yes' && provideReferralDocuments === 'client' ? !!gpReferralValue.files?.length : true;

    return !checkReferralFilesValid || !isCheckMedicareValid;
  };

  const handleSaveFundingStreamType = async (token: string) => {
    try {
      const response = await putClientFundingStream(token, {
        fundingStreamType: fundingStream,
        ...(fundingStream === FundingStreamType.NDIS && {
          ndis: {
            plan: ndisDetails.plan,
            number: ndisDetails.number,
            ...(ndisDetails.name && {
              name: ndisDetails.name
            }),
            ...(ndisDetails.email && {
              email: ndisDetails.email
            }),
            ...(ndisDetails.abn && {
              abn: ndisDetails.abn
            })
          }
        })
      });

      if (response.statusCode === 200) {
        await fetchClaimingReservation(reserveId!, accountId!, token);
        setSubmitButtonStatus('finished');
        navigate(SIGNUP.SUMMARY_BOOKING);
      }

      setTimeout(() => {
        setSubmitButtonStatus('');
      }, 1000);
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong while trying to update funding stream type' });
    } finally {
      setTimeout(() => {
        setSubmitButtonStatus('');
      }, 1000);
      setIsMedicareValidating(false);
    }
  };

  const isDisabledWithOwnReferral =
    fundingStream === FundingStreamType.MEDICARE
      ? checkMedicareValid()
      : fundingStream === FundingStreamType.NDIS
      ? !isValidNDIS
      : false;

  const handleSubmit = async () => {
    await saveMedicareInfo(token || '');
  };

  return (
    <HelmHelmetWrapper title={'Helm - Funding Stream'}>
      <HelmHeader withPadding whiteFont withMenu classNames={styles.header} noLogin />
      <HelmContentLayout>
        <div className={styles.container}>
          <div className={styles.leftContent}>
            {fetching ? (
              <div className={styles.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <div className={classNames(styles.formContainer, 'helm-theme')}>
                <div className={styles.fundingSection}>
                  <div className={styles.title}>Funding Stream</div>
                  <div className={styles.label}>What is the preferred funding stream for this client?</div>
                  <Radio
                    vertical
                    name="fundingStream"
                    value={fundingStream}
                    onChange={(e) => setFundingStream(e.target.value as FundingStreamType)}
                    options={[
                      { label: 'WISE funded', value: FundingStreamType.WISE },
                      { label: 'Medicare With Bulk Billing', value: FundingStreamType.MEDICARE },
                      { label: 'NDIS', value: FundingStreamType.NDIS }
                    ]}
                  />
                </div>
                <div className={styles.subFieldForm}>
                  {fundingStream === FundingStreamType.MEDICARE && token && (
                    <MedicareWithCoPay
                      medicareCardValue={medicareCard}
                      setMedicareCardValue={(value) => {
                        setMedicareErrorMessage({});
                        setMedicareCard(value);
                      }}
                      isMedicareCardValidating={isMedicareCardValidating}
                      medicareErrorMessage={medicareErrorMessage}
                      token={token}
                      gpReferralValue={gpReferralValue}
                      setGPReferralValue={setGPReferralValue}
                      haveGPReferralPlan={haveGPReferralPlan}
                      setHaveGPReferralPlan={setHaveGPReferralPlan}
                      provideReferralDocuments={provideReferralDocuments}
                      setProvideReferralDocuments={setProvideReferralDocuments}
                    />
                  )}
                  {fundingStream === FundingStreamType.NDIS && (
                    <NDISPlan
                      onChangeNDISDetails={handleOnChangeNDIS}
                      ndisDetails={ndisDetails}
                      setIsValidNDIS={setIsValidNDIS}
                    />
                  )}
                </div>
                <ButtonHelm
                  className={styles.submitButton}
                  onClick={() => {
                    handleSubmit();
                  }}
                  status={submitButtonStatus}
                  disabled={isDisabledWithOwnReferral}
                >
                  Update Funding Stream
                </ButtonHelm>
              </div>
            )}
          </div>
          <HelmTimer
            isReservedAppointmentFetching={fetching}
            reservedAppointments={appointments}
            reversedAppointmentId={reservedHelmPartnerReferral}
            hideTimer
          />
          {/*<div className={styles.rightContent}>*/}
          {/*  <HelmPartnerCreateProfileRemainingTime*/}
          {/*    practitionerAvatar={practitionerDetails.avatar}*/}
          {/*    practitionerName={practitionerDetails.name}*/}
          {/*    appointments={appointments}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </HelmContentLayout>
      <TacklitFooter />
    </HelmHelmetWrapper>
  );
};

export default PartnerFundingStream;
