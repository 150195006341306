import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './ListingFilter.module.scss';
import FilterDropdownWrapper from '../components/FilterDropdownWrapper/FilterDropdownWrapper';
import {
  FIT_LOCATION_LIST_LABELS,
  LocationList,
  MENTAL_HEALTH_LIST_LABELS,
  MentalHealthList
} from 'Select/interfaces/mentalHealth';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

interface ListingFilterProps {
  selectedLocation: LocationList[];
  selectedMentalHealthConcern: MentalHealthList[];
  onChangeSelectedLocation: (value: listInterface) => void;
  onChangeMentalHealthConcern: (value: listInterface) => void;
}

const ListingFilter = ({
  selectedLocation,
  selectedMentalHealthConcern,
  onChangeSelectedLocation,
  onChangeMentalHealthConcern
}: ListingFilterProps) => {
  const massageLocation = selectedLocation.map((item) => ({
    id: item,
    label: FIT_LOCATION_LIST_LABELS[item]
  }));
  const massageMentalHealthConcern = selectedMentalHealthConcern.map((item) => ({
    id: item,
    label: MENTAL_HEALTH_LIST_LABELS[item]
  }));

  return (
    <div className={styles.container}>
      <SelectContentLayout className={styles.content}>
        <div className={styles.filterWrapper}>
          <FilterDropdownWrapper
            classNames={styles.filter}
            locationValue={massageLocation}
            mentalHealthConcernValue={massageMentalHealthConcern}
            onChangeLocationValue={onChangeSelectedLocation}
            onChangeMentalHealthConcernValue={onChangeMentalHealthConcern}
          />
        </div>
      </SelectContentLayout>
    </div>
  );
};

export default ListingFilter;
