import { useField } from 'formik';

import styles from './EmojiButton.module.scss';

interface EmojiButtonProps {
  label: string;
  name: string;
  questionId: string;
  value: string | number;
}

const EMOJI_MAP = {
  frowning: '☹️',
  slightly_frowning: '🙁',
  neutral: '😐',
  slightly_smile: '🙂',
  smiley: '😃'
} as Record<string, string>;

const EmojiButton = ({ label, name, questionId, value: val }: EmojiButtonProps) => {
  const [{ value }, , { setValue }] = useField<string | number>(`${questionId}.value`);
  const handleClick = (val: string | number) => {
    setValue(val);
  };
  return (
    <div className={styles.container}>
      <div className={value === val ? styles.active : styles.emojiButton} onClick={() => handleClick(val)}>
        <div className={styles.emoji} title={label}>
          {EMOJI_MAP[name]}
        </div>
      </div>
    </div>
  );
};

export default EmojiButton;
