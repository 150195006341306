import smpHandStop from 'assets/images/smp/smpHandStop.png';
import classNames from 'classnames';
import SignatureBox from 'components/SignatureBox/SignatureBox';
import SignatureButton from 'components/SignatureButton/SignatureButton';
import SignatureDisplay from 'components/SignatureDisplay/SignatureDisplay';
import SquareCard from 'components/SquareCard/SquareCard';
import TextArea from 'components/TextArea/TextArea';
import { Signature } from 'interfaces/signature';
import moment from 'moment';
import { SMPForm, SMPType } from 'pages/SMP/interfaces/smp';
import { ChangeEventHandler } from 'react';
import styles from './SMPRelapsePrevention.module.scss';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';

type SMPRelapsePreventionProps = {
  onChange: (methods: SMPForm['relapsePreventions']) => void;
  signature?: Signature;
  isEditMode?: boolean;
  submittedAt?: string;
  submittedBy: string;
  type?: SMPType;
  onSignatureChange: (signature: Signature) => void;
  relapsePreventions: SMPForm['relapsePreventions'];
  isSignatureBoxOpen: boolean;
  setIsSignatureBoxOpen: (arg0: boolean) => void;
};

const SMPRelapsePrevention = ({
  onChange,
  signature,
  isEditMode,
  submittedAt,
  submittedBy,
  type,
  onSignatureChange,
  relapsePreventions,
  isSignatureBoxOpen,
  setIsSignatureBoxOpen
}: SMPRelapsePreventionProps): JSX.Element => {
  const onChangeGenerator =
    (key: keyof SMPForm['relapsePreventions']): ChangeEventHandler<HTMLTextAreaElement> =>
    (e) =>
      onChange({ ...relapsePreventions, [key]: e.target.value });

  const isIP6 = type === SMPType.IP6;
  const labels = isIP6
    ? {
        title: 'In high-risk social situations I can call on my supports',
        subtitle: 'And stay on track',
        rowTitle1: 'I can get support from',
        rowTitle2: 'My high-risk situations are',
        strategyTitle: 'Skills and strategies to build my supports'
      }
    : {
        title: 'My Relapse Prevention Plan',
        subtitle: 'At A Glance',
        rowTitle1: 'Some things I can do to manage urges and cravings are:',
        rowTitle2: 'Warning signs I might be at risk',
        strategyTitle: 'My strategies to reduce my risk'
      };

  return (
    <div className={styles.container}>
      <div className={styles.atAGlanceContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{labels.title}</div>
          <div className={styles.subtitle}>{labels.subtitle}</div>
        </div>
        <div className={styles.cardContainer}>
          <SquareCard
            label={labels.rowTitle1}
            value={relapsePreventions?.cravingManagement}
            onChange={onChangeGenerator('cravingManagement')}
            className={styles.cravingManagement}
            isEditMode={isEditMode}
            inputClassName={styles.textArea}
            labelClassName={styles.label}
          />
          <SquareCard
            label={labels.rowTitle2}
            value={relapsePreventions?.warningSign}
            onChange={onChangeGenerator('warningSign')}
            className={classNames(styles.warningSign, isIP6 && styles.top)}
            isEditMode={isEditMode}
            inputClassName={styles.textArea}
            labelClassName={styles.label}
          >
            {!isIP6 && (
              <div className={styles.handStopContainer}>
                <img src={smpHandStop} alt="stopping hand sign" className={styles.handStop} />
              </div>
            )}
          </SquareCard>
        </div>
      </div>
      <div className={styles.strategiesContainer}>
        <div className={styles.label}>{labels.strategyTitle}</div>
        <div className={styles.strategiesValue}>
          {isEditMode ? (
            <TextArea
              className={styles.textArea}
              value={relapsePreventions?.reductionStrategy}
              onChange={onChangeGenerator('reductionStrategy')}
            />
          ) : (
            relapsePreventions?.reductionStrategy
          )}
        </div>
      </div>
      <div className={styles.signatureContainer}>
        <div className={styles.description}>
          This is my plan and I am going to do my best to follow it and achieve my goals
        </div>
        <div className={styles.signature}>
          {signature?.type ? (
            <SignatureDisplay
              signVal={signature}
              editable={!!isEditMode}
              onEditClick={() => setIsSignatureBoxOpen(true)}
            />
          ) : (
            isEditMode && (
              <div className={styles.buttonContainer}>
                {IS_HELM_APP ? (
                  <ButtonHelm
                    className={styles.signButton}
                    variant="outlined"
                    type="button"
                    onClick={() => setIsSignatureBoxOpen(true)}
                  >
                    Sign here
                  </ButtonHelm>
                ) : (
                  <SignatureButton onClick={() => setIsSignatureBoxOpen(true)} />
                )}
              </div>
            )
          )}
          <SignatureBox
            wrapperClassName={IS_HELM_APP ? 'helm-theme' : undefined}
            id="smpSignature"
            title="Sign Self Management Plan"
            visible={isSignatureBoxOpen}
            buttonLabel="Fill in"
            onCloseSignature={() => {
              setIsSignatureBoxOpen(false);
            }}
            onChangeSignatureValue={onSignatureChange}
          />
          {(submittedAt || signature?.type) && (
            <div className={styles.signDetails}>
              {submittedBy}
              <span className={styles.separator}>|</span> <span>{moment(submittedAt).format('MMMM')}</span>
              <span className={styles.dayYear}>{moment(submittedAt).format('DD, YYYY')}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SMPRelapsePrevention;
