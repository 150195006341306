import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useCaWRoutesGenerator } from '../../utils/Path/CaWRoutesGenerator';
import CaWHelmetWrapper from 'CaW/components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWHeader from 'CaW/components/CaWHeader/CaWHeader';
import { config } from 'config/config';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || config.defaultTimezone;
  const timeZoneLocation = AU_TIME_ZONE_LIST.find(({ id }) => id === timeZone)?.label;
  const { PRACTITIONER } = useCaWRoutesGenerator();

  return (
    <CaWHelmetWrapper title={`Caraniche at Work - Appointments`}>
      <PatientContentLayout className={styles.container}>
        <div className={styles.contentWrapper}>
          <CaWHeader withPadding withMenu homeLink blueMenu />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
              {timeZoneLocation && <div className={styles.timeZone}>Time in {timeZoneLocation}</div>}
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientProfileTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={PRACTITIONER.DETAILS}
            />
          </div>
        </div>
      </PatientContentLayout>
    </CaWHelmetWrapper>
  );
};

export default AppointmentsOverview;
