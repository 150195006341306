import HelmContentLayout from '../../HelmContentLayout/HelmContentLayout';

import styles from './HelmFilterHeader.module.scss';
import TimeZoneDropdown from './components/TimeZoneDropdown/TimeZoneDropdown';
import { InitialSessionType, SpecialistTheme } from 'helm/interfaces/Filter/searchFilter';
import HelmHeader from '../HelmHeader/HelmHeader';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

export const sessionList = [
  {
    id: InitialSessionType.InitialConsult,
    label: 'Initial Consult',
    desc: 'Your first session will support you to start taking the Helm and identify goals and desired treatment outcomes.'
  },
  {
    id: InitialSessionType.InitialConsultFriendsFamily,
    label: 'Initial Consult: Friends & Family',
    desc: 'For those looking to help those around them. Discuss strategies and tactics for how best support friends or loved ones.'
  }
];

interface HelmFilterHeaderProps {
  selectedTheme: SpecialistTheme;
  onChangeTheme: (val: SpecialistTheme) => void;
  selectedSessionType: InitialSessionType;
  onChangeSession: (val: InitialSessionType) => void;
  selectedTimezone?: string;
  onChangeTimeZone: (val: string) => void;
}

const HelmFilterHeader = ({
  selectedTheme,
  onChangeTheme,
  selectedSessionType,
  onChangeSession,
  selectedTimezone,
  onChangeTimeZone
}: HelmFilterHeaderProps) => {
  return (
    <div className={styles.container}>
      <HelmContentLayout>
        <div className={styles.content}>
          <HelmHeader
            noWrapper
            middleChildren={
              <div className={styles.headerTitleWrapper}>
                <div className={styles.headerTitle}>Find Your Counsellor</div>
              </div>
            }
          />
          <div className={styles.mobileHeaderTitleWrapper}>
            <div className={styles.headerTitle}>Find Your Counsellor</div>
          </div>
          <div className={styles.timezoneContainer}>
            <TimeZoneDropdown
              selectedValue={AU_TIME_ZONE_LIST.find((obj) => obj.id === selectedTimezone) || AU_TIME_ZONE_LIST[0]}
              listData={AU_TIME_ZONE_LIST}
              onChangeValue={(val) => onChangeTimeZone(val.id)}
            />
          </div>
        </div>
      </HelmContentLayout>
    </div>
  );
};

export default HelmFilterHeader;
