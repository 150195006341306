import styles from './CardHeader.module.scss';

interface CardHeaderProps {
  title: string;
}

const CardHeader = ({ title }: CardHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default CardHeader;
