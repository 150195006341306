import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { Link } from 'react-router-dom';

export default ({
  errorMessage,
  isEmail,
  email,
  isFullProfileType
}: {
  errorMessage: string;
  isEmail?: boolean;
  email?: string;
  isFullProfileType?: boolean;
}) => {
  const supportEmail = email || 'team@someone.health';

  const { SIGNUP_INVITE } = useSomeoneHealthRoutesGenerator();
  if (isFullProfileType) {
    return (
      <>
        {errorMessage} Please proceed to <Link to={SIGNUP_INVITE.INVITE}>{SIGNUP_INVITE.INVITE}</Link> to continue.
      </>
    );
  }

  return (
    <>
      {errorMessage}{' '}
      {isEmail ? (
        <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      ) : (
        <>
          Otherwise{' '}
          <a href={`mailto:${supportEmail}`} target="_blank" rel="noreferrer">
            contact our support team
          </a>{' '}
          for assistance.
        </>
      )}{' '}
    </>
  );
};
