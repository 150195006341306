import moment from 'moment';
import * as yup from 'yup';

export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY ?? '';

export const DEFAULT_FORM_VALUES = {
  firstName: '',
  lastName: '',
  organisation: '',
  emailAddress: '',
  contactNumber: '',
  dateOfBirth: '',
  postcode: '',

  /** Returns true if the referral for `myself` instead of someone else. */
  isForMySelf: true,

  referralFirstName: '',
  referralLastName: '',
  relationship: '',
  referralDoB: '',
  contactMethod: 'myContact',
  referralEmailAddress: '',
  referralContactNumber: '',

  /**
   * @deprecated replaced by `referralSourceTags`
   * to standardize the data format.
   */
  referralSource: '',

  /** @deprecated replaced with `referralDetail` */
  referralSourceName: '',

  /**
   * New added variable to standardize the data format
   * from `Tacklit` website and referral form.
   *
   * To replace `referralSource`.
   */
  referralSourceTags: [],

  /** Used for referral source details. */
  referralDetail: '',

  /** Replace old `referralDetail`. */
  otherContactRelationship: '',

  /** Stored as a quick note. */
  referralBackground: '',

  referralConfirm: false,

  /**
   * Options: adult | child | couple
   *
   * Apply to both `Self referral` and `3rd party referral`.
   */
  referralRecordType: 'adult',

  // =======================
  //     Other contact
  // =======================
  otherContactFirstName: '',
  otherContactLastName: '',
  otherContactEmail: '',
  otherContactNumber: '',

  // =======================
  //   parent or guardian
  // =======================
  guardianFirstName: '',
  guardianLastName: '',
  guardianEmail: '',
  guardianContactNumber: '',
  guardianRelationshipToReferral: '',

  // =======================
  //   parent or guardian
  // =======================
  partnerFirstName: '',
  partnerLastName: '',
  partnerEmail: '',
  partnerContactNumber: '',
  partnerDoB: '',

  // For referral "child", they can choose to use parent/guardian
  // information as referrer.
  isUseGuardianInformation: 'otherInformation',

  // For "whose details are they?" under your information.
  // Only when referral is couple.
  whoseDetails: '',

  // For helm use only
  customFields: {
    helmInterestedWithPowerToChange: true
  }
};

export const DEFAULT_ERROR = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  contactNumber: '',
  dateOfBirth: '',
  postcode: '',
  referralFirstName: '',
  referralLastName: '',
  relationship: '',
  referralDoB: '',
  referralEmailAddress: '',
  referralContactNumber: '',
  referralSourceTags: '',
  referralDetail: '',
  referralSourceName: '',
  referralBackground: '',
  referralRecordType: '',
  otherContactFirstName: '',
  otherContactLastName: '',
  otherContactRelationship: '',
  otherContactEmail: '',
  otherContactNumber: '',

  // =======================
  //   parent or guardian
  // =======================
  guardianFirstName: '',
  guardianLastName: '',
  guardianEmail: '',
  guardianContactNumber: '',
  guardianRelationshipToReferral: '',

  // =======================
  //   partner
  // =======================
  partnerFirstName: '',
  partnerLastName: '',
  partnerEmail: '',
  partnerContactNumber: '',
  partnerDoB: '',

  whoseDetails: ''
};

export const REFERRAL_SOURCE_OPTIONS = [
  { label: 'GP', value: 'GP', needDetails: true },
  { label: 'Employer', value: 'employer', needDetails: true },
  { label: 'School', value: 'school', needDetails: true },
  { label: 'Health Insurer', value: 'health insurer', needDetails: true },
  { label: 'A web search', value: 'web search', needDetails: false },
  { label: 'Friend / Family', value: 'friend / family', needDetails: false },
  { label: 'Social Media', value: 'social media', needDetails: false },
  { label: 'Community group or charity', value: 'community group or charity', needDetails: true },
  { label: 'Other Psychologist / Psychiatrist', value: 'other psychologist / psychiatrist', needDetails: true },
  { label: 'Other healthcare service', value: 'other healthcare service', needDetails: true },
  { label: 'Other', value: 'other', needDetails: false }
];

export const HELM_REFERRAL_SOURCE_OPTIONS = [
  { label: 'GP', value: 'GP', needDetails: true },
  { label: 'A web search', value: 'web search', needDetails: false },
  { label: 'Social Media', value: 'social media', needDetails: false },
  { label: 'Other digital media', value: 'other digital media', needDetails: true },
  { label: 'Radio', value: 'radio', needDetails: false },
  { label: 'Friend / Family', value: 'friend / family', needDetails: false },
  { label: 'Other, Please Specify', value: 'other', needDetails: true }
];

export const RELATIONSHIP_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'wife' },
  { label: 'Partner', value: 'partner' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Friend', value: 'friend' },
  { label: 'Employer', value: 'employer' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];

export const DETAIL_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Employer', value: 'employer' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'GP or Health Professional', value: 'gpOrHealthProfessional' },
  { label: 'Other', value: 'other' }
];

export const CONTACT_OPTIONS = [
  { value: 'myContact', label: 'Use my contact details' },
  { value: 'otherContact', label: 'Provide other contact details' }
];

export const INFORMATION_OPTIONS = [
  { value: 'otherInformation', label: 'Provide my information' },
  { value: 'guardianInformation', label: 'Use parent or guardian information' }
];

export const REFERRAL_RECORD_TYPE = [
  { label: 'Adult', value: 'adult' },
  { label: 'Young Person', value: 'youngPerson' },
  { label: 'Child', value: 'child' },
  { label: 'Couple', value: 'couple' }
];

export const validationSchema = yup.object().shape({
  firstName: yup.string().when('isUseGuardianInformation', {
    is: 'guardianInformation',
    then: yup.string().nullable(),
    otherwise: yup.string().required('Please enter your first name')
  }),
  lastName: yup.string().when('isUseGuardianInformation', {
    is: 'guardianInformation',
    then: yup.string().nullable(),
    otherwise: yup.string().required('Please enter your last name')
  }),
  emailAddress: yup.string().when('isUseGuardianInformation', {
    is: 'guardianInformation',
    then: yup.string().email('Please enter a valid email address').nullable(),
    otherwise: yup.string().email('Please enter a valid email address').required('Please enter your email address')
  }),
  contactNumber: yup.string().when('isUseGuardianInformation', {
    is: 'guardianInformation',
    then: yup.string().nullable(),
    otherwise: yup
      .string()
      .min(10, 'Your mobile number must be 10 digits or longer')
      .required('Please enter your mobile number')
  }),
  dateOfBirth: yup.string().when('isForMySelf', {
    is: true,
    then: yup.string().test('Date of Birth', 'Date of Birth must be a valid date in the DD/MM/YYYY format', (value) => {
      if (!value || value.length === 0) {
        return true; // this field is optional
      }
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
    }),
    otherwise: yup.string()
  }),
  referralRecordType: yup.string().required('Please enter the referral record type'),
  referralFirstName: yup.string().when('isForMySelf', {
    is: false,
    then: yup.string().required("Please enter the referred's first name"),
    otherwise: yup.string()
  }),
  referralLastName: yup.string().when('isForMySelf', {
    is: false,
    then: yup.string().required("Please enter the referred's last name"),
    otherwise: yup.string()
  }),
  relationship: yup.string().when('isForMySelf', {
    is: false,
    then: yup.string(), // optional
    otherwise: yup.string()
  }),
  referralDoB: yup.string().when('isForMySelf', {
    is: false,
    then: yup.string().test('Date of Birth', 'Date of Birth must be a valid date in the DD/MM/YYYY format', (value) => {
      if (!value || value.length === 0) {
        return true; // this field is optional
      }
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
    }),
    otherwise: yup.string()
  }),
  referralEmailAddress: yup.string().when(['isForMySelf', 'referralRecordType'], {
    is: (isForMySelf: boolean, referralRecordType: string) =>
      !isForMySelf && ['adult', 'couple'].includes(referralRecordType),
    then: yup
      .string()
      .email('Please enter a valid email address')
      .required("Please enter the referred's contact email address"),
    otherwise: yup.string().email('Please enter a valid email address')
  }),
  referralContactNumber: yup.string().when(['isForMySelf', 'referralRecordType'], {
    is: (isForMySelf: boolean, referralRecordType: string) =>
      !isForMySelf && ['adult', 'couple'].includes(referralRecordType),
    then: yup.string().required("Please enter the referred's contact number"),
    otherwise: yup.string()
  }),
  referralBackground: yup.string(), // this field is now optional
  postcode: yup.string().max(7, 'Please enter the right format'),

  // guardian
  guardianFirstName: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) =>
      ['child', 'youngPerson'].includes(referralRecordType) && !isForMySelf,
    then: yup.string().required('Please enter the parent/guardian first name'),
    otherwise: yup.string()
  }),
  guardianLastName: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) =>
      ['child', 'youngPerson'].includes(referralRecordType) && !isForMySelf,
    then: yup.string().required('Please enter the parent/guardian last name'),
    otherwise: yup.string()
  }),
  guardianEmail: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) =>
      ['child', 'youngPerson'].includes(referralRecordType) && !isForMySelf,
    then: yup
      .string()
      .email('Please enter a valid email address')
      .required("Please enter the parent/guardian's contact email address"),
    otherwise: yup.string()
  }),
  guardianContactNumber: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) =>
      ['child', 'youngPerson'].includes(referralRecordType) && !isForMySelf,
    then: yup.string().required('Please enter the parent/guardian contact number'),
    otherwise: yup.string()
  }),
  guardianRelationshipToReferral: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) =>
      ['child', 'youngPerson'].includes(referralRecordType) && !isForMySelf,
    then: yup.string().required("Please enter the parent/guardian's relationship"),
    otherwise: yup.string()
  }),

  // partner/couple
  partnerFirstName: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) => referralRecordType === 'couple' && !isForMySelf,
    then: yup.string().required('Please enter partner first name'),
    otherwise: yup.string()
  }),
  partnerLastName: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) => referralRecordType === 'couple' && !isForMySelf,
    then: yup.string().required('Please enter partner last name'),
    otherwise: yup.string()
  }),
  partnerEmail: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) => referralRecordType === 'couple' && !isForMySelf,
    then: yup.string().email('Please enter a valid email address').required('Please enter partner email address'),
    otherwise: yup.string()
  }),
  partnerContactNumber: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) => referralRecordType === 'couple' && !isForMySelf,
    then: yup.string().required('Please enter partner contact number'),
    otherwise: yup.string()
  }),
  partnerDoB: yup.string().when(['referralRecordType', 'isForMySelf'], {
    is: (referralRecordType: string, isForMySelf: boolean) => referralRecordType === 'couple' && !isForMySelf,
    then: yup.string().test('Date of Birth', 'Date of Birth must be a valid date in the DD/MM/YYYY format', (value) => {
      if (!value || value.length === 0) {
        return true; // this field is optional
      }
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
    })
  }),
  otherContactFirstName: yup.string().when(['contactMethod', 'isForMySelf'], {
    is: (contactMethod: string, isForMySelf: boolean) => contactMethod === 'otherContact' && !isForMySelf,
    then: yup.string().required('Please enter other contact first name'),
    otherwise: yup.string()
  }),
  otherContactLastName: yup.string().when(['contactMethod', 'isForMySelf'], {
    is: (contactMethod: string, isForMySelf: boolean) => contactMethod === 'otherContact' && !isForMySelf,
    then: yup.string().required('Please enter other contact last name'),
    otherwise: yup.string()
  }),
  otherContactEmail: yup.string().when(['contactMethod', 'isForMySelf'], {
    is: (contactMethod: string, isForMySelf: boolean) => contactMethod === 'otherContact' && !isForMySelf,
    then: yup.string().required('Please enter other contact email address'),
    otherwise: yup.string()
  }),
  otherContactNumber: yup.string().when(['contactMethod', 'isForMySelf'], {
    is: (contactMethod: string, isForMySelf: boolean) => contactMethod === 'otherContact' && !isForMySelf,
    then: yup.string().required('Please enter other contact contact number'),
    otherwise: yup.string()
  })
});

export const validationForm = (formValues: typeof DEFAULT_FORM_VALUES) => {
  const validationErrors = {
    firstName: '',
    lastName: ''
  } as any;
  try {
    validationSchema.validateSync(formValues, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.map((error: any) => {
        if (error.path) {
          // @ts-ignore
          validationErrors[error.path] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};
