import { useEffect, useRef, useState } from 'react';

import styles from './FilterDropdown.module.scss';
import classnames from 'classnames';
import { IS_HELM_APP, IS_SOMEONE_HEALTH_APP } from '../../utils/hooks/AccountInfo/clientDetails';

export interface listInterface {
  id: string;
  label: string;
  desc?: string;
  inOtherList?: boolean;
}

interface FilterDropdownProps {
  label: string;
  noFloatLabel?: boolean;
  listData: listInterface[];
  selectedValue: listInterface | undefined;
  onChangeValue: (val: listInterface | undefined) => void;
  className?: string;
  withOtherSection?: boolean;
  withSearch?: boolean;
  badgeStyles?: boolean;
  refreshPosition?: number;
  onClick?: () => void;
  disabled?: boolean;
}

const FilterDropdown = ({
  label,
  noFloatLabel,
  listData,
  selectedValue,
  onChangeValue,
  className,
  withOtherSection,
  withSearch,
  badgeStyles,
  refreshPosition,
  onClick,
  disabled
}: FilterDropdownProps) => {
  const listMenuRef = useRef<HTMLDivElement>(null);
  const ButtonRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const [dropdownList, setDropdownList] = useState(listData);

  const [searchValue, setSearchValue] = useState('');
  const [dropdownPos, setDropdownPos] = useState<{
    left: string | number | undefined;
    right: string | number | undefined;
  }>({
    left: undefined,
    right: undefined
  });
  const [menuWidth, setMenuWidth] = useState<string | number>('auto');
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const { left, right, width } = ButtonRef.current?.getBoundingClientRect() || { width: 0, left: 0, right: 0 };
    setDropdownPos({
      left: right >= screenWidth ? 'auto' : left || 0,
      right:
        left + width >= right - 2
          ? right < screenWidth
            ? screenWidth - right - (IS_SOMEONE_HEALTH_APP ? 10 : 0)
            : 5
          : 'auto'
    });
  }, [refreshPosition, screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      const { left, right, width } = ButtonRef.current?.getBoundingClientRect() || { width: 0, left: 0, right: 0 };
      setDropdownPos({
        left: right >= screenWidth ? 'auto' : left || 0,
        right:
          left + width >= right - 2
            ? right < screenWidth
              ? screenWidth - right - (IS_SOMEONE_HEALTH_APP ? 10 : 0)
              : 5
            : 'auto'
      });
      setMenuWidth(width);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenWidth]);

  const handleCloseMenu = () => {
    setShowList(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = () => {
    if (disabled) {
      return;
    }
    if (screenWidth <= 1020 && onClick) {
      onClick();
      return;
    }
    setShowList(!showList);
    const { left, right, width } = ButtonRef.current?.getBoundingClientRect() || { width: 0, left: 0, right: 0 };
    setMenuWidth(width);
    setDropdownPos({
      left: right >= screenWidth ? 'auto' : left || 0,
      right:
        left + width >= right - 2
          ? right < screenWidth
            ? screenWidth - right - (IS_SOMEONE_HEALTH_APP ? 10 : 0)
            : 5
          : 'auto'
    });
  };

  const handleClick = (e: any) => {
    if (listMenuRef.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleChangeFilter = (val: listInterface) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    const filterData = listData.filter((obj) => obj.label.toLowerCase().includes(value.toLowerCase()));
    setDropdownList(filterData);
  };

  const matchedLabel = (value: string) => {
    const matchedIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
    return (
      <div className={styles.label}>
        {value.substring(0, matchedIndex)}
        <span className={styles.matchedValue}>{value.substring(matchedIndex, matchedIndex + searchValue.length)}</span>
        {value.substring(matchedIndex + searchValue.length)}
      </div>
    );
  };

  return (
    <div ref={listMenuRef} className={classnames(styles.container, className, IS_HELM_APP && 'helm-theme')}>
      {!noFloatLabel && (
        <div className={styles.filterTitleWrapper}>
          <div className={classnames(styles.filterTitle, selectedValue && styles.showTitle)}>{label}</div>
        </div>
      )}
      <div
        ref={ButtonRef}
        className={classnames(
          styles.selectButton,
          showList && styles.openMenu,
          badgeStyles && selectedValue && styles.badgeStyles,
          disabled && styles.disabled
        )}
        onClick={handleSelectButtonClick}
      >
        <div className={styles.label}>{selectedValue ? selectedValue.label : label}</div>
        {selectedValue ? (
          <button
            className={styles.clearButton}
            disabled={disabled}
            onClick={(event) => {
              event.stopPropagation();
              onChangeValue(undefined);
              setShowList(false);
            }}
          >
            <i className={`material-icons-outlined ${styles.clearIcon}`}>close</i>
          </button>
        ) : (
          <i className={`material-icons-outlined ${styles.arrow}`}>keyboard_arrow_down</i>
        )}
      </div>
      <div className={styles.menuWrapper}>
        <div
          style={{
            left: 'auto',
            right: dropdownPos.right,
            minWidth: menuWidth
          }}
          className={showList ? styles.menuBoxShow : styles.menuBoxHide}
        >
          {withSearch && (
            <div className={styles.searchWrapper}>
              <i className={`material-icons-outlined ${styles.searchIcon}`}>search</i>
              <input
                className={styles.searchInput}
                type={'text'}
                autoComplete={'off'}
                onChange={(e) => handleChangeSearch(e.target.value)}
              />
            </div>
          )}
          {withOtherSection ? (
            <>
              {searchValue.length > 0 ? (
                <div className={showOtherMenu ? styles.hideMenu : styles.displayMenu}>
                  {dropdownList.length > 0 ? (
                    dropdownList.map((listObj, index) => (
                      <div
                        key={index}
                        className={selectedValue?.id === listObj.id ? styles.selectedListBox : styles.listBox}
                        onClick={() => handleChangeFilter(listObj)}
                      >
                        {matchedLabel(listObj.label)}
                      </div>
                    ))
                  ) : (
                    <div className={styles.emptyList}>No matching items found</div>
                  )}
                </div>
              ) : (
                <>
                  <div className={showOtherMenu ? styles.hideMenu : styles.displayMenu}>
                    {dropdownList
                      .filter((obj) => !obj.inOtherList)
                      .map((listObj, index) => (
                        <div
                          key={index}
                          className={selectedValue?.id === listObj.id ? styles.selectedListBox : styles.listBox}
                          onClick={() => handleChangeFilter(listObj)}
                        >
                          {matchedLabel(listObj.label)}
                        </div>
                      ))}
                    <div className={styles.listBox} onClick={() => setShowOtherMenu(true)}>
                      Something else
                      <i className={`material-icons-outlined ${styles.rightArrow}`}>arrow_forward_ios</i>
                    </div>
                  </div>
                  <div className={showOtherMenu ? styles.displayMenu : styles.hideMenu}>
                    <div className={styles.backButton} onClick={() => setShowOtherMenu(false)}>
                      <i className={`material-icons-outlined ${styles.leftArrow}`}>arrow_back_ios</i>
                      Back
                    </div>
                    {dropdownList
                      .filter((obj) => obj.inOtherList)
                      .map((listObj, index) => (
                        <div
                          key={index}
                          className={selectedValue?.id === listObj.id ? styles.selectedListBox : styles.listBox}
                          onClick={() => handleChangeFilter(listObj)}
                        >
                          {matchedLabel(listObj.label)}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dropdownList.map((listObj, index) => (
                <div
                  key={index}
                  className={selectedValue?.id === listObj.id ? styles.selectedListBox : styles.listBox}
                  onClick={() => handleChangeFilter(listObj)}
                >
                  {matchedLabel(listObj.label)}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
