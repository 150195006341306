import { ReactNode, useState } from 'react';
import styles from './SHCreateProfileForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonSH from '../ButtonSH/ButtonSH';
import { formDataValidationSchema, initialCustomer, passwordValidationSchema } from './constants';

interface SHCreateProfileFormProps {
  isSubmitting: boolean;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialCustomer) => void;
  customAgeCheck?: number;
  subHeader?: ReactNode;
}

const SHCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  customAgeCheck,
  subHeader
}: SHCreateProfileFormProps) => {
  const [pwScore, setPwScore] = useState(0);
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [receiveSMS, setReceiveSMS] = useState(true);
  const [showPwError, setShowPwError] = useState(false);

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialCustomer>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'someone-health-theme')}>
        <div className={styles.header}>
          Welcome to <span className={styles.highlight}>someone.health</span>
          <div className={styles.subHeader}>
            {subHeader ? (
              subHeader
            ) : (
              <>
                Create your <b>secure account</b> and access specialised psychologists for personalised care.
              </>
            )}
          </div>
        </div>
        <div className={styles.loginText}>
          Already have an account?
          <a href={`${process.env.REACT_APP_CLIENT_DOMAIN_SOMEONE_HEALTH}/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </a>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={formDataValidationSchema(customAgeCheck).concat(passwordValidationSchema(pwScore))}
          onSubmit={onSubmit}
        >
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                <HelmMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={'0482 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                  labelClass={styles.inputLabel}
                  allowShowPassword
                  showPasswordButtonClass={styles.showPasswordButtonClass}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.ageConfirm}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="ageConfirm"
                    value={ageConfirm}
                    onChange={(e) => setAgeConfirm(e.target.checked)}
                    className={styles.checkBox}
                    label={`I confirm I agree to the <a target="_blank" href='https://someone.health/terms-and-conditions'>Privacy Policy and Terms & Conditions</a>`}
                  />
                </div>
              </div>
              <div className={styles.communicationPreferences}>
                <div className={styles.title}>Communication Preferences</div>
                <div className={styles.text}>
                  We need to communicate with you via <b>email</b> to send you information about your appointments, for
                  example links to join the Telehealth room. By completing this registration you agree to us sending
                  this detail to your registered email address.
                  <br />
                  <br />
                  We can also send you important information such as appointment reminders via SMS. Please let us know
                  if you’d prefer not to.
                </div>
                <div className={styles.checkBoxes}>
                  <div className={styles.checkBoxText}>
                    <CheckBox
                      id="receiveSMS"
                      value={receiveSMS}
                      onChange={(e) => setReceiveSMS(e.target.checked)}
                      className={styles.checkBox}
                      label="I am happy to receive SMS"
                    />
                  </div>
                </div>
              </div>

              <ButtonSH
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, initialCustomer) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit({ ...values, receiveSMS });
                    }
                  });
                }}
              >
                Create Your Profile
              </ButtonSH>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SHCreateProfileForm;
