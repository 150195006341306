import Select from 'react-select';
import CountryCodeOption from './components/CountryCodeOption/CountryCodeOption';
import countryCodes from './countryCodes.json';
import CountryCodeSingleValue from './components/CountryCodeSingleValue/CountryCodeSingleValue';

export interface CountryCodeOptionInterface {
  label: string;
  value: string;
}

const countryCodeOptions: CountryCodeOptionInterface[] = countryCodes.map(({ name, iso2, countryCode }) => ({
  label: `${name} (+${countryCode})`,
  value: iso2
}));

const styles = {
  container: (base: any) => ({
    ...base,
    width: 140
  }),
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 'unset',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none'
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0
  }),
  singleValue: (base: any) => ({
    ...base,
    margin: 0,
    color: '#333333'
  }),
  input: (base: any) => ({
    ...base,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    color: '#333333',
    fontSize: 10,
    fontWeight: 600,

    input: {
      textAlign: 'right'
    },
    '::after': {
      display: 'none'
    }
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#666666',
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'right'
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    '> div': {
      padding: 0
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: 2,
    marginBottom: 0,
    overflow: 'hidden'
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isSelected ? '#707dc5' : state.isFocused ? '#eaedff' : base.backgroundColor
  })
};

interface CountryCodeSelectProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
}

// const DropdownIndicator = () => {
//   return <i className={`material-icons `}>arrow_drop_down</i>;
// };

const CountryCodeSelect = ({ id, value, onChange }: CountryCodeSelectProps) => {
  return (
    <Select<CountryCodeOptionInterface, false>
      inputId={`${id ? `${id}-` : ''}country-code-select`}
      components={{
        // DropdownIndicator: DropdownIndicator,
        Option: CountryCodeOption,
        SingleValue: CountryCodeSingleValue
      }}
      styles={styles}
      options={countryCodeOptions}
      // isSearchable={false}
      value={countryCodeOptions.find((option) => option.value === value) || { label: value, value }}
      onChange={(value) => onChange(value?.value || '')}
    />
  );
};

export default CountryCodeSelect;
