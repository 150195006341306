import styles from './RechargeLoginWrapper.module.scss';

interface RechargeLoginWrapperProps {
  children?: any;
}

const RechargeLoginWrapper = ({ children }: RechargeLoginWrapperProps) => (
  <div className={styles.container}>
    <div className={styles.background1} />
    <div className={styles.background2} />
    <div className={styles.content}>{children}</div>
  </div>
);

export default RechargeLoginWrapper;
