import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useFetchBackgroundImageUrl } from 'utils/hooks/checkIn';
import { useGetAccessToken } from 'utils/hooks/token';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import ClientHeader from 'components/ClientHeader/ClientHeader';

import styles from './HomePage.module.scss';
import TaskBox from '../../../components/TaskBox/TaskBox';
import { useRoutesGenerator } from 'utils/Path/RoutesGenerator';
import { useFetchCompletedCheckInStatus } from './hooks/getCheckInStatus';
import WelcomeMessage from './components/WelcomeMessage/WelcomeMessage';
import { useGetAllSurveysQuery } from 'redux/endpoints/checkInServices/survey';
import { useTranslation } from 'react-i18next';
import { useFetchPsychometricList } from 'pages/Psychometric/PsychometricList/hooks/getPsychometricList';
import HomeNoTaskBox, { noTaskBoxContentInterface } from '../../../components/HomeNoTaskBox/HomeNoTaskBox';
import appointmentImg from 'SomeoneHealth/assets/images/tasks/appointment.png';
import profileImg from 'SomeoneHealth/assets/images/tasks/profile.png';
import TacklitHelmetWrapper from 'Tacklit/components/TacklitHelmetWrapper/TacklitHelmetWrapper';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetTasksQuery } from 'redux/endpoints/clinicianProfileServices/client';
import { formatActionCount } from 'utils/general';

const REFETCH_DATA_INTERVAL_TIME = 15 * 60 * 1000;

const HomePage = () => {
  const { user } = useAuth0();
  const { token } = useGetAccessToken();
  const navigate = useNavigate();
  const [t] = useTranslation();

  const { backgroundImageUrl, isBackgroundImageUrlLoading } = useFetchBackgroundImageUrl(
    user?.sub || '',
    Boolean(user?.['https://tacklit.com/use-check-in-bg'])
  );
  const clientRecordId = user?.['https://tacklit.com/clientRecordId'] || '';

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: clientTasks, isLoading: isClientTasksLoading } = useGetTasksQuery(
    { clientRecordId },
    { skip: !clientRecordId, pollingInterval: REFETCH_DATA_INTERVAL_TIME }
  );
  const { psychometricList, isPsychometricListLoading } = useFetchPsychometricList(token);
  const { data: surveyList, isLoading: isSurveyListLoading } = useGetAllSurveysQuery();
  const clinicianId = attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';
  const { completedCheckInStatus, isCompletedCheckInStatusLoading } = useFetchCompletedCheckInStatus(
    token,
    clinicianId
  );

  const { ONBOARDING, SURVEY, PSYCHOMETRIC, APPOINTMENT_OVERVIEW, UPDATE_PROFILE } = useRoutesGenerator();

  const handleCheckInPath = () => {
    navigate(`/${attachedClinicianDetail?.clinician?.slugUrl}/checkIn`);
  };

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail?.clinician?.title} ${attachedClinicianDetail?.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';
  const isContentLoading =
    isAttachedClinicianDetailLoading || isClientTasksLoading || isPsychometricListLoading || isSurveyListLoading;

  const noTaskBoxContent: noTaskBoxContentInterface[] = [
    {
      title: 'View appointments & invoices',
      desc: 'Find all detail on past and upcoming appointments, including your telehealth link.',
      image: appointmentImg,
      button: {
        label: 'View my appointment',
        link: APPOINTMENT_OVERVIEW
      }
    },
    {
      title: 'Manage your profile',
      desc: 'Complete and update your information to save you time, all stored securely with AES256 encryption',
      image: profileImg,
      button: {
        label: 'Edit my profile',
        link: UPDATE_PROFILE
      }
    }
  ];

  const hasTask =
    (clientTasks && (clientTasks.consentList.length > 0 || clientTasks.assessmentList.length > 0)) ||
    (surveyList && surveyList.length) ||
    psychometricList.length > 0;

  return (
    <TacklitHelmetWrapper title={`Tacklit - Welcome ${user ? user.name : ''}`}>
      <PatientContentLayout
        className={styles.container}
        style={
          !isBackgroundImageUrlLoading && backgroundImageUrl
            ? {
                backgroundImage: `url('${backgroundImageUrl}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }
            : {}
        }
      >
        {!isAttachedClinicianDetailLoading && attachedClinicianDetail?.clinician && completedCheckInStatus && (
          <Button
            className={styles.createBtn}
            type={'primary'}
            disabled={isAttachedClinicianDetailLoading}
            onClick={handleCheckInPath}
          >
            <div className={styles.newJournalBtn}>
              <i className={`material-icons ${styles.icon}`}>add_circle_outline</i>
              NEW JOURNAL ENTRY
            </div>
          </Button>
        )}
        <div className={styles.contentWrapper}>
          <ClientHeader withMenu />
          <div className={styles.content}>
            {isContentLoading || isCompletedCheckInStatusLoading ? (
              <div className={styles.loading}>
                <LoadingCircle />
              </div>
            ) : (
              <div className={styles.contentBox}>
                {attachedClinicianDetail && (
                  <WelcomeMessage
                    clinicianName={clinicianName}
                    clinicianDetail={attachedClinicianDetail}
                    completedCheckInStatus={completedCheckInStatus}
                  />
                )}
                {hasTask ? (
                  <div className={styles.taskListContainer}>
                    <div className={styles.taskListTitle}>
                      <strong>{clinicianName}</strong> has asked you to complete
                    </div>
                    <div className={styles.taskListBox}>
                      {clientTasks && (
                        <>
                          {clientTasks.consentList.length > 0 && (
                            <TaskBox
                              title={t('label.esign_client_consent_form')}
                              desc={formatActionCount({ count: clientTasks.consentList.length })}
                              onClick={() => navigate(`${ONBOARDING.BASE}?type=consent`)}
                            />
                          )}
                          {clientTasks.assessmentList.length > 0 && (
                            <TaskBox
                              title={'Onboarding assessment'}
                              desc={formatActionCount({ count: clientTasks.assessmentList.length })}
                              onClick={() => navigate(`${ONBOARDING.BASE}?type=onboarding`)}
                            />
                          )}
                        </>
                      )}
                      {surveyList && !!surveyList.length && (
                        <TaskBox
                          title={'Surveys'}
                          desc={formatActionCount({ count: surveyList.length })}
                          onClick={() => navigate(SURVEY.BASE)}
                        />
                      )}
                      {psychometricList.length > 0 && (
                        <TaskBox
                          title={'Psychometrics'}
                          desc={formatActionCount({ count: psychometricList.length })}
                          onClick={() => navigate(PSYCHOMETRIC.BASE)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <HomeNoTaskBox taskContent={noTaskBoxContent} />
                )}
              </div>
            )}
          </div>
        </div>
      </PatientContentLayout>
    </TacklitHelmetWrapper>
  );
};

export default HomePage;
