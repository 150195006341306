import { ReportInterface } from 'interfaces/report';
import styles from './SignatureSection.module.scss';

export interface SignatureSectionProps {
  signVal: ReportInterface['clinicianSignature'];
  extraDetailsVal: ReportInterface['clinicianSignatureExtraDetails'];
}

const SignatureSection = ({ extraDetailsVal, signVal }: SignatureSectionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.signLabel}>{extraDetailsVal.valediction}</div>
      <div className={styles.signature}>
        {signVal.type === 'typeVer' ? (
          signVal.textVal
        ) : (
          <img className={styles.signatureImg} alt={'signature'} src={signVal.drawVal} />
        )}
      </div>
      <div className={styles.extraDetailsWrapper}>
        {extraDetailsVal.name && <div className={styles.extraDetails}>{extraDetailsVal.name}</div>}
        {extraDetailsVal.mobileNumber && <div className={styles.extraDetails}>{extraDetailsVal.mobileNumber}</div>}
        {extraDetailsVal.email && <div className={styles.extraDetails}>{extraDetailsVal.email}</div>}
        {extraDetailsVal.other && <div className={styles.extraDetails}>{extraDetailsVal.other}</div>}
      </div>
    </div>
  );
};

export default SignatureSection;
