import styles from './ClientTestimonial.module.scss';
import { useFetchClientTestimonial } from './content';

export interface ClientTestimonialProps {
  message?: string;
  avatar?: string;
  name?: string;
}

const ClientTestimonial = (props: ClientTestimonialProps) => {
  const { content } = useFetchClientTestimonial();
  const { message, name, avatar } = { ...content, ...props };
  return (
    <div className={styles.testimonialCard}>
      <div className={styles.shape2} />
      <div className={styles.shape4} />
      <div className={styles.testimonialCardBody}>
        <div className={styles.testimonialCardTitle}>Client Testimonials</div>
        <div className={styles.testimonialCardContent}>{message}</div>
        <div className={styles.testimonialClientAvatar}>
          <div className={styles.avatarText}>{name}</div>
          {avatar && <img src={avatar} alt="avatar" />}
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonial;
