import classNames from 'classnames';
import SummaryItem from 'SomeoneHealth/pages/BookingConfirmation/components/Summary/components/SummaryItem/SummaryItem';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { toWord } from 'utils/generateCamelCase';
import styles from './Summary.module.scss';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

interface SummaryProps {
  isReservedAppointmentFetching: boolean;
  reservedAppointments: AppointmentSlot[];
  showPayment?: boolean;
  containerClassName?: string;
  contentClassName?: string;
}

const Summary = ({
  isReservedAppointmentFetching,
  reservedAppointments,
  showPayment,
  containerClassName,
  contentClassName
}: SummaryProps) => {
  const { CancellationFee } = someoneHealthEnvironment();
  const { psychologistName, gpDetails } = useReserveAppointmentData();

  const sortByCreatedAt = (a: AppointmentSlot, b: AppointmentSlot) => {
    const aCreatedAt = moment(a.createdAt);
    const bCreatedAt = moment(b.createdAt);
    return aCreatedAt.isBefore(bCreatedAt) ? 1 : aCreatedAt.isAfter(bCreatedAt) ? -1 : 0;
  };

  return (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={styles.header}>Here is the summary of the appointments being held for you:</div>
      {isReservedAppointmentFetching ? (
        <div className={styles.loadingContainer}>
          <LoadingDot />
        </div>
      ) : (
        <div className={classNames(styles.content, contentClassName)}>
          {reservedAppointments.sort(sortByCreatedAt).map((appointmentObj, index) => (
            <SummaryItem
              key={index}
              index={index + 1}
              bookingName={appointmentObj.sessionTypeName || ''}
              name={appointmentObj.sessionTypeName === 'GP' ? gpDetails?.gpName || '' : psychologistName || ''}
              startTime={appointmentObj.startTime}
              endTime={appointmentObj.endTime}
              date={appointmentObj?.date || ''}
              deliveryMode={appointmentObj.deliveryType ? toWord(appointmentObj.deliveryType) : ''}
              showTopSeparation
              isLast={showPayment && reservedAppointments.length === index + 1}
              showBottomSeparation
            />
          ))}
          {showPayment && (
            <div className={styles.paymentContainer}>
              <div className={styles.title}>If you don’t attend your appointment you will be charged:</div>
              <div className={styles.paymentDetails}>
                <div className={styles.label}>TOTAL</div>
                <div className={styles.value}>$ {CancellationFee}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Summary;
