import smpDownArrow from 'assets/images/smp/smpDownArrow.png';
import classNames from 'classnames';
import TextArea from 'components/TextArea/TextArea';
import { ChangeEventHandler } from 'react';
import { GoalNumber, SMPGoal as SMPGoalInterface } from '../../../pages/SMP/interfaces/smp';
import styles from './SMPGoal.module.scss';

interface SMPGoalProps {
  num: GoalNumber;
  goal: SMPGoalInterface;
  onChange: (goal: SMPGoalInterface) => void;
  isEditMode?: boolean;
  goalContainerClassName?: string;
}

const SMPGoal = ({ num, goal, goalContainerClassName, isEditMode, onChange }: SMPGoalProps) => {
  const descriptionValue = goal?.description;
  const onDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    onChange({ ...goal, description: e.target.value });

  const stepValues = goal?.steps;
  const onStepChangeGenerator =
    (stepIndex: number): React.ChangeEventHandler<HTMLTextAreaElement> =>
    (e) => {
      const steps = [...goal.steps];
      steps[stepIndex] = e.target.value;
      onChange({ ...goal, steps });
    };

  return (
    <div className={classNames(styles.goalContainer, goalContainerClassName)}>
      <div className={styles.descriptionBox}>
        <div className={styles.descriptionLabel}>Goal {num}</div>
        {isEditMode ? (
          <TextArea className={styles.descriptionInput} value={descriptionValue} onChange={onDescriptionChange} />
        ) : (
          <div>{descriptionValue}</div>
        )}
      </div>

      {(isEditMode || stepValues.find((step) => step)) && (
        <div className={styles.arrowContainer}>
          <img src={smpDownArrow} alt={'arrow'} className={styles.downArrow} />
        </div>
      )}

      <div className={styles.stepContainer}>
        {stepValues?.map((step, stepIndex) =>
          isEditMode ? (
            <div key={stepIndex} className={styles.stepBox}>
              <TextArea
                value={stepValues[stepIndex]}
                onChange={onStepChangeGenerator(stepIndex)}
                className={styles.stepInput}
              />
            </div>
          ) : (
            step && (
              <div key={stepIndex} className={styles.stepBox}>
                <div className={styles.stepValue}>{step}</div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default SMPGoal;
