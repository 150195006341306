import { useAuth0 } from '@auth0/auth0-react';

import styles from './SomeoneHealthSurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthContentLayout from '../../../components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

const SomeoneHealthSurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <div className={styles.container}>
      <SomeoneHealthContentLayout className={styles.headerContent}>
        <div className={styles.headerWrapper}>
          <SomeoneHealthHeader classNames={styles.shHeader} withPadding withMenu homeLink />
        </div>
      </SomeoneHealthContentLayout>
      <SomeoneHealthContentLayout className={styles.contentWrapper}>
        <SurveyQuestion />
      </SomeoneHealthContentLayout>
    </div>
  );
};

export default SomeoneHealthSurveyQuestionWrapper;
