import classNames from 'classnames';
import TextArea from 'components/TextArea/TextArea';
import { ChangeEventHandler, ReactNode } from 'react';
import styles from './SquareCard.module.scss';

type SquareCardProps = {
  label: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  children?: ReactNode;
  className?: string;
  isEditMode?: boolean;
  inputClassName?: string;
  labelClassName?: string;
};

const SquareCard = ({
  label,
  value,
  onChange,
  children,
  className,
  isEditMode,
  inputClassName,
  labelClassName
}: SquareCardProps): JSX.Element => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.label, labelClassName)}>{label}</div>

      <div className={styles.content}>
        {isEditMode ? <TextArea value={value} onChange={onChange} className={inputClassName} /> : value}
      </div>

      {children}
    </div>
  );
};

export default SquareCard;
