import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import FormikInput from 'components/Input/FormikInput';
import FormikSelect from 'components/Select/FormikSelect';
import { Form, Formik } from 'formik';
import styles from './CaWEmergencyContactForm.module.scss';
import { isEmpty, isEqual } from 'lodash';
import { HelmBtnStatus } from 'components/v2/Button/Button';
import { contactValidation, EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS, initialContact } from '../constant';
import HelmMobileFormikInput from 'helm/components/Input/HelmMobileFormikInput';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface CaWEmergencyContactFormProps {
  invalidMobileNumber: ReactNode;
  setInvalidMobileNumber: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialContact) => void;
  submitStatus: HelmBtnStatus;
}

const CaWEmergencyContactForm = ({
  submitStatus,
  invalidMobileNumber,
  setInvalidMobileNumber,
  onSubmit
}: CaWEmergencyContactFormProps) => {
  return (
    <div className={classNames(styles.container, 'caw-theme')}>
      <Formik initialValues={initialContact} validationSchema={contactValidation} onSubmit={onSubmit}>
        {({ values, submitForm, isValid, validateForm }) => (
          <Form noValidate>
            <div>
              <FormikInput
                inputClass={styles.customInput}
                label="Contact First Name*"
                name="firstName"
                required
                labelClass={styles.inputLabel}
              />
              <FormikInput
                inputClass={styles.customInput}
                label="Contact Last Name*"
                name="lastName"
                required
                labelClass={styles.inputLabel}
              />
              <FormikSelect
                className={styles.customSelect}
                labelClass={styles.selectInputLabel}
                errorClassName={styles.customSelectError}
                label={'Your relationship?*'}
                isSearchable={false}
                name="relationship"
                options={EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS.map((tag) => ({ label: tag, value: tag }))}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '100%'
                  }),
                  control: (controlBase: any) => ({
                    ...controlBase,
                    minHeight: '30px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: 0,
                    boxShadow: 'none'
                  }),
                  valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                }}
              />
              <HelmMobileFormikInput
                inputClass={styles.customInput}
                labelClass={styles.selectInputLabel}
                id={`mobileNumber`}
                name={'mobileNumber'}
                label={'Contact Number*'}
                mobilePattern={'____ ___ ___'}
                placeholder={'(eg: 0482 666 666)'}
                phonePrefix=""
                externalError={invalidMobileNumber}
                onChange={(e) => {
                  invalidMobileNumber && setInvalidMobileNumber();
                }}
                required
              />
            </div>
            <ButtonCaW
              status={submitStatus}
              className={styles.submitButton}
              fullWidth
              disabled={!isValid || isEqual(values, initialContact)}
              type="button"
              onClick={() => {
                validateForm().then((errors) => {
                  if (isEmpty(errors)) {
                    submitForm();
                  }
                });
              }}
            >
              Save Emergency Contact
            </ButtonCaW>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CaWEmergencyContactForm;
