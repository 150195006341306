import { notification } from 'antd';
import classNames from 'classnames';
import DateSelector from 'components/DatePicker/DateSelector';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import Radio from 'components/Radio/Radio';
import { ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { useState, ChangeEvent } from 'react';
import { useGetAccessToken } from 'utils/hooks/token';
import { putClientReferralDetail } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import * as Yup from 'yup';
import { checkReferralFileSize } from '../../utils/fileSize';
import { IS_CAW_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';
import styles from './ReferralDetailForm.module.scss';
import ButtonCaW from '../../../../../CaW/components/ButtonCaW/ButtonCaW';

interface HelmReferralDetail {
  referral: {
    code: string;
    name?: string;
    practiceName?: string;
    medicareProviderNumber?: string;
    files?: File[];
    currentFiles?: {
      bucketName: string;
      fileName: string;
      fileUrl: string;
    }[];
  };
  mentalHealthTreatment: {
    code: string;
    date?: string;
    file?: File;
    currentFile?: {
      bucketName: string;
      fileName: string;
      fileUrl: string;
    };
  };
}

const validatorSchema = Yup.object().shape({
  referral: Yup.object().shape({
    name: Yup.string().required('Name of GP is required'),
    practiceName: Yup.string().required('Practice name is required'),
    medicareProviderNumber: Yup.string().required('Doctors medicare provider number is required')
  }),
  mentalHealthTreatment: Yup.object().shape({
    date: Yup.string().required('Date of treatment is required')
  })
});

const initialFormState: HelmReferralDetail = {
  referral: { code: 'no', name: '', practiceName: '', medicareProviderNumber: '' },
  mentalHealthTreatment: { code: 'no', date: undefined }
};

const radioOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
];

const MEDICARE_REBATE_NOTE =
  'If you want to receive the Medicare rebate you will need a mental health plan to do so. You can get a mental health plan from your GP. Alternatively, you can get one via doctorai.com.au/ noting there are associated costs through that website.';
const MEDICARE_REBATE_NOTE_SH =
  'If you want to receive the Medicare rebate you will need a mental health plan and referral to do so. You can get a mental health plan and referral from your GP.';

const ReferralDetailForm = () => {
  const { token } = useGetAccessToken();

  const [values, setValues] = useState<HelmReferralDetail>(initialFormState);
  const [buttonStatus, setButtonStatus] = useState<HelmBtnStatus>('');
  const [touchedInputs, setTouchedInputs] = useState<string[]>([]);
  const [errors, setErrors] = useState<{
    GPName?: string;
    practiceName?: string;
    doctorMedicareNumber?: string;
    treatmentDate?: string;
  }>({});

  const handleChangeValue = (val: HelmReferralDetail) => {
    setValues(val);
    validateData(val);
  };

  const resetForm = () => {
    setValues(initialFormState);
  };

  const validateData = (val: HelmReferralDetail) => {
    const validate = (values: HelmReferralDetail) => {
      try {
        validatorSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (ex) {
        const validationErrors = {} as any;
        if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
          ex.inner.map((error: any) => {
            if (error.path) {
              validationErrors[error.path] = error.message;
            }
            return validationErrors;
          });
          return validationErrors;
        }
      }
    };

    const error = validate(val);
    setErrors({
      practiceName: error['referral.practiceName'],
      GPName: error['referral.name'],
      doctorMedicareNumber: error['referral.medicareProviderNumber'],
      treatmentDate: error['mentalHealthTreatment.date']
    });
  };

  const isButtonDisabled = () => {
    if (buttonStatus !== '') {
      return true;
    }

    if (
      values.referral.code === 'yes' &&
      (!values.referral.name || !values.referral.medicareProviderNumber || !values.referral.practiceName)
    ) {
      return true;
    }

    if (values.referral.code === 'no' && !values.referral.files) {
      return true;
    }

    if (values.mentalHealthTreatment.code === 'yes' && !values.mentalHealthTreatment.date) {
      return true;
    }

    return false;
  };

  const handleUploadTreatmentPlan = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0) as File;
    const fileSizeValidate = checkReferralFileSize(file);
    if (!fileSizeValidate.valid) {
      notification.error({ message: fileSizeValidate.message });
    } else {
      setValues({
        ...values,
        mentalHealthTreatment: {
          ...values.mentalHealthTreatment,
          file: file
        }
      });
    }
    e.target.value = '';
  };

  const handleUploadRefferal = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let validatedFiles: File[] = [];
      files.forEach((file) => {
        const fileSizeValidate = checkReferralFileSize(file);
        if (!fileSizeValidate.valid) {
          notification.error({ message: fileSizeValidate.message });
        } else {
          validatedFiles = [...validatedFiles, file];
        }
      });
      setValues((values) => ({
        ...values,
        referral: {
          ...values.referral,
          files: [...validatedFiles]
        }
      }));
    }
    e.target.value = '';
  };

  const handleSubmit = async () => {
    setButtonStatus('active');
    try {
      const payload: ReferralDetailUpdateInterface = {
        files: [],
        treatmentPlanFile: undefined,
        name: values.referral.name,
        practiceName: values.referral.practiceName,
        medicareProviderNumber: values.referral.medicareProviderNumber,
        isReferredByGP: values.referral.code === 'yes',
        isHaveTreatmentPlan: values.mentalHealthTreatment.code === 'yes',
        treatmentPlanDate: values.mentalHealthTreatment.date
      };

      const referralFiles = values.referral.files;
      if (referralFiles?.length) {
        payload.files = await Promise.all(
          referralFiles.map(
            (file) =>
              uploadDocumentWithEncryption(token, file) as Promise<{
                bucketName: string;
                fileName: string;
                fileUrl: string;
              }>
          )
        );
      }

      if (values.mentalHealthTreatment.code === 'yes') {
        const file = values.mentalHealthTreatment.file;
        const uploadedFile = file
          ? ((await uploadDocumentWithEncryption(token, file)) as {
              bucketName: string;
              fileName: string;
              fileUrl: string;
            })
          : undefined;
        payload.treatmentPlanFile = uploadedFile || values.mentalHealthTreatment.currentFile;
      }

      const res = await putClientReferralDetail(token, payload);

      if (res.statusCode === 204) {
        notification.success({ message: 'Your referral detail has been saved!' });
        setButtonStatus('finished');
        resetForm();
      } else if (res.statusCode === 400) {
        notification.error({
          message: await res.text(),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    } catch (e) {
      notification.error({
        message: 'Referral details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.radio1}>
        <Radio
          label={'Have you been able to speak with a GP about your mental health?'}
          variant={'button'}
          id={'referralByGP'}
          name={'referralByGP'}
          options={radioOptions}
          radioLabelClass={styles.radioLabel}
          value={values.referral.code}
          onChange={(e) =>
            e.target.value === 'yes'
              ? setValues({ ...values, referral: { ...values.referral, code: e.target.value } })
              : resetForm()
          }
        />
      </div>
      {values.referral.code === 'no' && (
        <div className={styles.note}>
          <i className="material-icons-outlined">info</i>
          {IS_SOMEONE_HEALTH_APP ? MEDICARE_REBATE_NOTE_SH : MEDICARE_REBATE_NOTE}
        </div>
      )}
      <div className={classNames(styles.radio2, values.referral.code === 'no' && styles.disabled)}>
        <Radio
          label={'Do you have a current Mental Healthcare Treatment Plan?'}
          variant={'button'}
          id={'mentalTreatment'}
          name={'mentalTreatment'}
          width={380}
          options={radioOptions}
          radioLabelClass={styles.radioLabel}
          value={values.referral.code === 'no' ? '' : values.mentalHealthTreatment.code}
          onChange={(e) => setValues({ ...values, mentalHealthTreatment: { code: e.target.value } })}
        />
        {values.referral.code === 'yes' && values.mentalHealthTreatment.code === 'no' && (
          <div className={styles.note}>
            <i className="material-icons-outlined">info</i>
            {IS_SOMEONE_HEALTH_APP ? MEDICARE_REBATE_NOTE_SH : MEDICARE_REBATE_NOTE}
          </div>
        )}
      </div>

      <div className={styles.inputs}>
        <div className={classNames(values.referral.code === 'no' && styles.disabled)}>
          <div className={styles.row}>
            <MaterialInput
              className={styles.input}
              label={'Name of GP'}
              id={'GPName'}
              onChange={(e) => handleChangeValue({ ...values, referral: { ...values.referral, name: e.target.value } })}
              onBlur={() => !touchedInputs.includes('GPName') && setTouchedInputs([...touchedInputs, 'GPName'])}
              value={values.referral.name}
              maxLength={80}
              required
            />
          </div>
          <ErrorMessage
            className={styles.error}
            error={errors.GPName}
            visible={touchedInputs.includes('GPName') && !!errors.GPName}
          />

          <div className={styles.row}>
            <MaterialInput
              className={styles.input}
              label={'Name of practice'}
              id={'practiceName'}
              onChange={(e) =>
                handleChangeValue({ ...values, referral: { ...values.referral, practiceName: e.target.value } })
              }
              onBlur={() =>
                !touchedInputs.includes('practiceName') && setTouchedInputs([...touchedInputs, 'practiceName'])
              }
              value={values.referral.practiceName}
              maxLength={80}
              required
            />
          </div>
          <ErrorMessage
            className={styles.error}
            error={errors.practiceName}
            visible={touchedInputs.includes('practiceName') && !!errors.practiceName}
          />

          <div className={styles.row}>
            <MaterialInput
              className={styles.input}
              label={'Doctors Medicare Provider Number'}
              id={'doctorMedicareNumber'}
              onChange={(e) =>
                handleChangeValue({
                  ...values,
                  referral: { ...values.referral, medicareProviderNumber: e.target.value }
                })
              }
              onBlur={() =>
                !touchedInputs.includes('doctorMedicareNumber') &&
                setTouchedInputs([...touchedInputs, 'doctorMedicareNumber'])
              }
              value={values.referral.medicareProviderNumber}
              maxLength={80}
              required
            />
          </div>
          <ErrorMessage
            className={styles.error}
            error={errors.doctorMedicareNumber}
            visible={touchedInputs.includes('doctorMedicareNumber') && !!errors.doctorMedicareNumber}
          />
        </div>

        <div className={classNames(styles.row, values.mentalHealthTreatment.code === 'no' && styles.disabled)}>
          <DateSelector
            id={'treatmentDate'}
            name={'treatmentDate'}
            className={styles.datePicker}
            inputClass={styles.input}
            label={'Date of Mental Health Treatment Plan'}
            onChange={(e: string) => {
              if (!touchedInputs.includes('treatmentDate')) {
                setTouchedInputs([...touchedInputs, 'treatmentDate']);
              }
              handleChangeValue({ ...values, mentalHealthTreatment: { ...values.mentalHealthTreatment, date: e } });
            }}
            value={values.mentalHealthTreatment.date}
          />
        </div>
        <ErrorMessage
          className={styles.error}
          error={errors.treatmentDate}
          visible={
            values.mentalHealthTreatment.code === 'yes' &&
            touchedInputs.includes('treatmentDate') &&
            !!errors.treatmentDate
          }
        />
      </div>

      <div
        className={classNames(
          styles.uploadReferralContainer,
          values.mentalHealthTreatment.code === 'no' && styles.disabled
        )}
      >
        <input
          className={styles.uploadContainerInput}
          type={'file'}
          id={'treatmentFile'}
          onChange={handleUploadTreatmentPlan}
          accept={'.pdf,.docx,image/png,image/jpeg'}
        />
        <label
          className={classNames(
            styles.uploadContainerBtn,
            IS_CAW_APP && styles.cawUploadContainerBtn,
            IS_SOMEONE_HEALTH_APP && styles.shUploadContainerBtn
          )}
          htmlFor={'treatmentFile'}
        >
          <i className={`material-icons ${styles.publishIcon}`}>upload</i>
          <div className={styles.publishLabel}>Upload a copy of your Mental Health Care Treatment Plan</div>
        </label>
        {values.mentalHealthTreatment.file ? (
          <div className={styles.uploadedFiles}>
            <button
              className={styles.removeFile}
              onClick={() => {
                setValues({ ...values, mentalHealthTreatment: { ...values.mentalHealthTreatment, file: undefined } });
              }}
            >
              <i className="material-icons-outlined">delete_forever</i>
            </button>
            <div className={styles.uploadedFile}>
              <span>{values.mentalHealthTreatment.file.name}</span>
              <span className={styles.fileSize}>
                {(values.mentalHealthTreatment.file.size / (1024 * 1024)).toFixed(2)} MB{' '}
              </span>
            </div>
          </div>
        ) : (
          values.mentalHealthTreatment.currentFile && (
            <div className={styles.uploadedFiles}>
              <button
                className={styles.removeFile}
                onClick={() => {
                  setValues({
                    ...values,
                    mentalHealthTreatment: { ...values.mentalHealthTreatment, currentFile: undefined }
                  });
                }}
              >
                <i className="material-icons-outlined">delete_forever</i>
              </button>
              <div className={styles.uploadedFile}>
                <span>{values.mentalHealthTreatment.currentFile.fileName}</span>
              </div>
            </div>
          )
        )}
      </div>

      <div className={styles.uploadReferralContainer}>
        <input
          className={styles.uploadContainerInput}
          type={'file'}
          id={'referralFile'}
          onChange={handleUploadRefferal}
          accept={'.pdf,.docx,image/png,image/jpeg'}
          multiple
        />
        <label
          className={classNames(
            styles.uploadContainerBtn,
            IS_CAW_APP && styles.cawUploadContainerBtn,
            IS_SOMEONE_HEALTH_APP && styles.shUploadContainerBtn
          )}
          htmlFor={'referralFile'}
        >
          <i className={`material-icons ${styles.publishIcon}`}>upload</i>
          <div className={styles.publishLabel}>Upload any other referral documents</div>
        </label>
        {values.referral.files && values.referral.files.length > 0 ? (
          <div className={styles.uploadedFiles}>
            <button
              className={styles.removeFile}
              onClick={() => {
                setValues({ ...values, referral: { ...values.referral, files: undefined } });
              }}
            >
              <i className="material-icons-outlined">delete_forever</i>
            </button>
            <div className={styles.uploadedFile}>
              {values.referral.files.map((item, index) => (
                <div className={styles.uploadedFile} key={index}>
                  <span>{item.name}</span>
                  <span className={styles.fileSize}>{(item.size / (1024 * 1024)).toFixed(2)} MB</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          values.referral.currentFiles && (
            <div className={styles.uploadedFiles}>
              <button
                className={styles.removeFile}
                onClick={() => {
                  setValues({ ...values, referral: { ...values.referral, currentFiles: undefined } });
                }}
              >
                <i className="material-icons-outlined">delete_forever</i>
              </button>
              <div className={styles.uploadedFile}>
                {values.referral.currentFiles.map((item, index) => (
                  <div className={styles.uploadedFile} key={index}>
                    <span>{item.fileName}</span>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      <div className={styles.submitButtonContainer}>
        {IS_CAW_APP ? (
          <ButtonCaW
            disabled={isButtonDisabled()}
            className={styles.submitBtn}
            status={buttonStatus}
            onClick={handleSubmit}
          >
            Save Referral Details
          </ButtonCaW>
        ) : (
          <Button
            disabled={isButtonDisabled()}
            className={styles.submitBtn}
            status={buttonStatus}
            onClick={handleSubmit}
          >
            Save Referral Details
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReferralDetailForm;
