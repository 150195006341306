import styles from './JoinWaitlist.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmSignUpFooter from 'helm/components/HelmFooter/HelmSignUpFooter/HelmSignUpFooter';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import JoinWaitlistForm from './components/JoinWaitlistForm';
import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { useNavigate } from 'react-router-dom';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';

const JoinWaitlist = () => {
  const navigate = useNavigate();
  const { WAITLIST_SUCCESS } = useHelmRoutesGenerator();
  const onComplete = ({ createdClientRecord }: { createdClientRecord: clientRecordsInterface }) => {
    navigate(WAITLIST_SUCCESS, { state: { clientName: createdClientRecord?.clientProfiles[0]?.firstName } });
  };

  return (
    <HelmHelmetWrapper title={'HELM - Waitlist'}>
      <HelmHeader />
      <HelmContentLayout>
        <div className={styles.container}>
          <div className={styles.content}>
            <JoinWaitlistForm onComplete={onComplete} />
          </div>
        </div>
      </HelmContentLayout>
      <HelmSignUpFooter />
    </HelmHelmetWrapper>
  );
};

export default JoinWaitlist;
