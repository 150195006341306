import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import classNames from 'classnames';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';

const UpdateProfile = () => {
  return (
    <PortobelloHelmetWrapper title={`Portobello Behavioural Health | Profile`}>
      <PortobelloHeader withPadding withMenu homeLink />
      <div className={styles.container}>
        <PortobelloContentLayout className="portobello-theme">
          <UpdateProfileContent contentClassName={classNames(styles.content, 'portobello-theme')} />
        </PortobelloContentLayout>
      </div>
    </PortobelloHelmetWrapper>
  );
};

export default UpdateProfile;
