import styles from './UpdateProfile.module.scss';
import UpdateProfileContent from 'components/UpdateProfileContent/UpdateProfileContent';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import classNames from 'classnames';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

const UpdateProfile = () => {
  return (
    <SelectHelmetWrapper title={`Select Psychology | Profile`}>
      <SelectContentLayout className={styles.container}>
        <SelectHeader withPadding withMenu homeLink whiteFont />
        <UpdateProfileContent contentClassName={classNames(styles.content, 'select-theme')} />
      </SelectContentLayout>
    </SelectHelmetWrapper>
  );
};

export default UpdateProfile;
