import { ReactNode } from 'react';
import styles from './RechargeEmergencyContactForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import { isEmpty, isEqual } from 'lodash';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS, formDataValidationSchema, initialContact } from './constants';
import RechargeMobileFormikInput from 'Recharge/components/RechargeMobileFormikInput/RechargeMobileFormikInput';
import FormikSelect from 'components/Select/FormikSelect';

interface RechargeEmergencyContactFormProps {
  isSubmitting: boolean;
  mobileNumberErrorMsg?: ReactNode | string;
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialContact) => void;
  subHeader?: ReactNode;
}

const RechargeEmergencyContactForm = ({
  isSubmitting,
  mobileNumberErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  subHeader
}: RechargeEmergencyContactFormProps) => {
  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialContact>) => {
    if (!isValid) {
      return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'recharge-theme')}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            Provide your Emergency Contact
            <div className={styles.subHeader}>
              {subHeader ? (
                subHeader
              ) : (
                <div className={styles.description}>
                  <div>To provide you with support we need you to provide the detail of one emergency contact.</div>

                  <div>
                    We would only contact this person should we be concerned about your safety. You can add additional
                    contacts, and update information in your client profile
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Formik initialValues={initialContact} onSubmit={onSubmit} validationSchema={formDataValidationSchema}>
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="Contact First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Contact Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <div className={styles.selectContainer}>
                  <FormikSelect
                    className={styles.customInput}
                    labelClass={styles.selectInputLabel}
                    label={'Your relationship*'}
                    isSearchable={false}
                    name="relationship"
                    options={EMERGENCY_CONTACT_RELATIONSHIP_OPTIONS.map((tag) => ({ label: tag, value: tag }))}
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: '100%'
                      }),
                      control: (controlBase: any) => ({
                        ...controlBase,
                        minHeight: '30px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: 0,
                        boxShadow: 'none'
                      }),
                      valueContainer: (base) => ({ ...base, paddingLeft: 0 })
                    }}
                  />
                </div>

                <RechargeMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="____ ___ ___"
                  placeholder={'0482 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
              </div>
              <ButtonRecharge
                className={styles.submitButton}
                disabled={isEqual(values, initialContact) || !isButtonEnabled(isValid, errors)}
                onClick={() => {
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit(values);
                    }
                  });
                }}
              >
                Save emergency contact
              </ButtonRecharge>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RechargeEmergencyContactForm;
