import styles from './FootNotesTextWidget.module.scss';

interface FootNotesTextWidgetProps {
  data: any;
}

const FootNotesTextWidget = ({ data }: FootNotesTextWidgetProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.title}</div>
    </div>
  );
};

export default FootNotesTextWidget;
