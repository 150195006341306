import classNames from 'classnames';
import moment from 'moment';
import styles from './SummaryItem.module.scss';

interface SummaryItemProps {
  index: number;
  bookingName: string;
  name: string;
  startTime: string;
  endTime: string;
  date: string;
  deliveryMode: string;
  isLast?: boolean;
}

const SummaryItem = ({
  index,
  bookingName,
  name,
  startTime,
  endTime,
  date,
  deliveryMode,
  isLast
}: SummaryItemProps) => {
  return (
    <div className={styles.summary}>
      <div className={styles.index}>{index}</div>
      <div className={classNames(styles.details, isLast && styles.lastOne)}>
        <div className={styles.item}>
          <div className={styles.label}>Booking:</div>
          <div className={styles.value}>{bookingName}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>With:</div>
          <div className={styles.value}>{name}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Duration:</div>
          <div className={styles.value}>{`${moment(endTime, 'hh:mmA').diff(
            moment(startTime, 'hh:mmA'),
            'minute'
          )} minutes`}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Date and Time:</div>
          <div className={styles.value}>{`${moment(date).format('DD MMMM YYYY')}, ${startTime} to ${endTime}`}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Delivery Mode:</div>
          <div className={styles.value}>{deliveryMode}</div>
        </div>
      </div>
    </div>
  );
};

export default SummaryItem;
