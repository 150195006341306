import './AdvisoryBookingModal.scss';
import styles from './AdvisoryBookingModal.module.scss';
import { Modal } from 'antd';
import AdvisoryBookingHeader from './AdvisoryBookingHeader/AdvisoryBookingHeader';

interface AdvisoryBookingModalProps {
  onCancel?: () => void;
  visible: boolean;
  calendar: JSX.Element;
  bookingSession: JSX.Element;
}

const AdvisoryBookingModal = ({ visible, calendar, bookingSession, ...props }: AdvisoryBookingModalProps) => (
  <Modal
    style={{ top: 10 }}
    className={styles.modalContainer}
    title={<AdvisoryBookingHeader />}
    visible={visible}
    {...props}
    footer={null}
  >
    <div className={styles.content}>
      <div className={styles.calendar}>{calendar}</div>
      <div className={styles.bookingSession}>{bookingSession}</div>
    </div>
  </Modal>
);

export default AdvisoryBookingModal;
