import styles from './LoginAvatar.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import classNames from 'classnames';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import { useFetchAvatar } from 'utils/hooks/getAvatarFromToken';
import { useEaseRoutesGenerator } from 'Ease/utils/Path/EaseRoutesGenerator';
import { EaseContactUrl } from 'Ease/utils/EaseEnv/EaseEnv';

interface LoginAvatarProps {
  whiteFont?: boolean;
  withMenu?: boolean;
  loginTextClass?: string;
  greenMenu?: boolean;
}

const LoginAvatar = ({ whiteFont, withMenu, loginTextClass, greenMenu }: LoginAvatarProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const { initialsName } = useFetchAvatar();

  const { APPOINTMENT_OVERVIEW, REFERRAL, UPDATE_PROFILE } = useEaseRoutesGenerator();

  const menuItems = [
    { name: 'Manage my appointments', url: APPOINTMENT_OVERVIEW, isExternal: false },
    { name: 'Add referral details', url: REFERRAL, isExternal: false },
    { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false },
    { name: 'Contact Us', url: EaseContactUrl, isExternal: true }
  ];

  return (
    <div className={styles.container}>
      {initialsName || isAuthenticated ? (
        <div className={styles.profileWrapper}>
          <div className={styles.profileCard}>
            <div className={styles.profile}>
              <ClientProfileAvatar
                fullAvatarBg
                avatarUrl={user?.picture}
                initialsName={initialsName}
                tokenUser={!!initialsName}
              />
            </div>
          </div>
          {withMenu && isAuthenticated && (
            <div className={greenMenu ? styles.nameGreen : styles.name}>
              <HeaderMenu menuList={menuItems}>
                <i className={`material-icons ${styles.menuIcon}`}>menu</i>
              </HeaderMenu>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.loginWrapper}>
          <div
            onClick={() =>
              loginWithRedirect({ loginType: 'patient', appState: { returnTo: window.location.pathname } })
            }
            className={classNames(loginTextClass, whiteFont ? styles.loginWhite : styles.login)}
          >
            LOGIN
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginAvatar;
