import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';
import classnames from 'classnames';
import Button from 'components/Button/Button';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCard/PatientFooterCard';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import PracticeLayout from 'layouts/PracticeLayout/PracticeLayout';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { AppointmentSlot, DeliveryType } from 'utils/hooks/appointment';
import { Clinician } from 'utils/hooks/clinician';
import { postAppointmentByClinician, postPaymentRequiredAppointment } from 'utils/http/appointment';

import AppointmentHeader from '../components/AppointmentHeader/AppointmentHeader';
import TypeMode from '../components/TypeMode/TypeMode';
import styles from './AppointmentBookingType.module.scss';

const AppointmentBookingType = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  // one of these should exist, based on the route that this component is placed in
  const { clinicianId = '', slugUrl = '' } = useParams<{ clinicianId: string; slugUrl: string }>();

  const [deliveryOption, setDeliveryOption] = useState<
    Required<AppointmentSlot>['deliveryOptions'][number] | undefined
  >();
  const [isBookAppointmentButtonLoading, setIsBookAppointmentButtonLoading] = useState(false);
  const [deliveryOptionError, setDeliveryOptionError] = useState(false);

  const { clinician, practiceProfile, slot, isNewClient, paymentRequired } = useMemo(() => {
    const { clinician, practiceProfile, slot, isNewClient, paymentRequired } =
      (location.state as {
        clinician?: Clinician;
        practiceProfile?: PublicPracticeProfile;
        slot: AppointmentSlot;
        isNewClient?: boolean;
        paymentRequired?: boolean;
      }) || {};

    if (!slot) {
      slugUrl ? navigate(`/p/${slugUrl}`) : navigate(`/${clinicianId}/appointment`);
    }

    return { clinician, practiceProfile, slot, isNewClient, paymentRequired };
  }, [clinicianId, navigate, location.state, slugUrl]);

  const { bookingRule, date, day, month, startTime, endTime } = useMemo(() => {
    if (slot) {
      const dateMoment = moment(slot.date, 'YYYY-MM-DD');

      const date = dateMoment.format('DD');
      const day = dateMoment.format('ddd');
      const month = dateMoment.format('MMM');

      const startTime = slot.startTime;
      const endTime = moment(slot.endTime, 'HH:mm').format('HH:mm a');

      return {
        bookingRule: isNewClient ? slot?.bookingRules?.new.rule : slot?.bookingRules?.existing.rule,
        date,
        day,
        month,
        startTime,
        endTime
      };
    } else {
      return {};
    }
  }, [slot, isNewClient]);

  const onSubmit = async () => {
    if (!isNewClient && !practiceProfile) {
      if (!deliveryOption) {
        setDeliveryOptionError(true);
        return;
      }

      setIsBookAppointmentButtonLoading(true);

      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      try {
        if (paymentRequired) {
          const callPostPaymentRequiredAppointment = await postPaymentRequiredAppointment(
            {
              clinicianId,
              deliveryOption,
              patient: user,
              slot,
              accountId: clinician?.accountId,
              bookingRule,
              returnUrl: `${window.location.origin}/${clinicianId}/appointment/confirmed`,
              cancelUrl: `${window.location.origin}/${clinicianId}/appointment/new`
            },
            token
          );
          const { url, appointment } = await callPostPaymentRequiredAppointment.json();
          localStorage.setItem(appointment._id, JSON.stringify({ clinician, appointment }));
          window.location.href = url;
          return;
        }

        if (bookingRule === 'instant') {
          const callPostAppointmentByClinician = await postAppointmentByClinician(
            clinicianId,
            { deliveryOption, patient: user, slot, accountId: clinician?.accountId, bookingRule },
            token
          );

          const createdAppointment = await callPostAppointmentByClinician.json();

          navigate(
            {
              pathname: `/${clinicianId}/appointment/confirmed`
            },
            {
              state: { appointment: createdAppointment, clinician, paymentRequired }
            }
          );
        } else {
          const callPostAppointmentByClinician = await postAppointmentByClinician(
            clinicianId,
            { deliveryOption, patient: user, slot, accountId: clinician?.accountId, bookingRule },
            token
          );

          await callPostAppointmentByClinician.json();

          navigate(
            {
              pathname: `/${clinicianId}/appointment/request/sent`
            },
            {
              state: { clinician }
            }
          );
        }
      } catch {
        message.error('Something went wrong while trying to create your appointment. Please try again.');
        setIsBookAppointmentButtonLoading(false);
      }
    } else {
      if (!deliveryOption) {
        setDeliveryOptionError(true);
        return;
      }

      if (practiceProfile) {
        navigate(
          {
            pathname: `/p/${slugUrl}/appointment/contact`
          },
          {
            state: {
              bookingRule: slot?.bookingRules?.new.rule,
              deliveryOption,
              slot,
              accountId: practiceProfile.accountId,
              practiceProfile,
              paymentRequired
            }
          }
        );
      } else {
        navigate(
          {
            pathname: `/${clinicianId}/appointment/contact`
          },
          {
            state: { bookingRule, deliveryOption, slot, accountId: clinician?.accountId, paymentRequired }
          }
        );
      }
    }
  };

  const handleSetDeliveryOption = (e: DeliveryType) => {
    setDeliveryOption(e);
    setDeliveryOptionError(false);
  };

  const getNextButtonLabel = ({
    slugUrl,
    isNewClient,
    bookingRule,
    paymentRequired
  }: {
    slugUrl: string;
    isNewClient?: boolean;
    bookingRule?: string;
    paymentRequired?: boolean;
  }) => {
    if (isNewClient || slugUrl) {
      return 'Next';
    }

    if (bookingRule === 'instant') {
      return paymentRequired ? 'Pay To Book' : 'Book Appointment';
    }

    return 'Request Appointment';
  };

  // eslint-disable-next-line react/no-multi-comp
  const ModeSelection = ({ slot }: { slot: AppointmentSlot }) => (
    <div className={styles.cardWrapper}>
      <div className={styles.title}>Confirm details</div>
      <div className={styles.aPointCard}>
        <div className={styles.dateInfo}>
          <div className={styles.date}>{date}</div>
          <div className={styles.monthDay}>{month}</div>
          <div className={styles.monthDay}>{day}</div>
          <div className={styles.bookingTime}>
            <div>{startTime}</div>
            <div className={styles.to}>to</div>
            <div>{endTime}</div>
          </div>
        </div>
        <div className={styles.sessionType}>{slot.name}</div>
      </div>
      <div className={styles.modeWrapper}>
        <div className={styles.label}>Select the session format:</div>
        <TypeMode
          deliveryOptions={slot.deliveryOptions || []}
          faceToFaceLocation={slot.faceToFaceLocation}
          videoCallInstructions={slot.videoCallInstructions}
          phoneCallInstructions={slot.phoneCallInstructions}
          phoneCallDialClientInstructions={slot.phoneCallDialClientInstructions}
          otherInstructions={slot.otherInstructions}
          value={deliveryOption}
          onChangeMode={handleSetDeliveryOption}
        />
        {deliveryOptionError ? <div className={styles.error}>Please select a session format.</div> : null}
      </div>
      <div className={styles.divider} />
      <div className={styles.footer}>
        <div className={styles.policy}>By making this booking you agree to adhere to the cancellation policy</div>
        <Button
          className={classnames(styles.button, isBookAppointmentButtonLoading && styles.loading)}
          variant="primary"
          disabled={isBookAppointmentButtonLoading}
          onClick={onSubmit}
        >
          {isBookAppointmentButtonLoading && (
            <div className={styles.loadingContainer}>
              <LoadingCircle />
            </div>
          )}
          {getNextButtonLabel({ isNewClient, slugUrl, bookingRule, paymentRequired })}
        </Button>
      </div>
    </div>
  );

  return clinician && slot ? (
    <PatientContentLayout>
      <>
        <div className={styles.contentWrapper}>
          <AppointmentHeader profileName={clinician.name} profileImg={clinician.avatar} label="Confirm Details" />
        </div>
        <PatientFooterCard containerClassName={styles.fullHeightFooter} className={styles.cardContainer}>
          <ModeSelection slot={slot} />
        </PatientFooterCard>
      </>
    </PatientContentLayout>
  ) : slot ? (
    <PracticeLayout>
      <div className={styles.cardContainer}>
        <ModeSelection slot={slot} />
      </div>
    </PracticeLayout>
  ) : null;
};

export default AppointmentBookingType;
