import { useState } from 'react';
import styles from './AdvisorySession.module.scss';
import NoAvailableMessageBox from 'helm/components/NoAvailableMessageBox/NoAvailableMessageBox';
import AdvisoryBookingModal from './components/AdvisoryBookingModal/AdvisoryBookingModal';

interface AdvisorySessionProps {
  calendar: JSX.Element;
  bookingSession: JSX.Element;
}

const AdvisorySession = ({ calendar, bookingSession }: AdvisorySessionProps) => {
  const [visibleAdvisorBox, setVisibleAdvisorBox] = useState(false);

  return (
    <div className={styles.container}>
      <NoAvailableMessageBox
        title={'Not ready for a counselling session just yet?  '}
        desc={
          'This counsellor doesn’t provide Advisory Sessions but you can find and book at time with our trained support team.'
        }
        buttonLabel={'Find Advisory Session'}
        onClick={() => {
          setVisibleAdvisorBox(true);
        }}
      />
      <AdvisoryBookingModal
        visible={visibleAdvisorBox}
        onCancel={() => {
          setVisibleAdvisorBox(false);
        }}
        calendar={calendar}
        bookingSession={bookingSession}
      />
    </div>
  );
};

export default AdvisorySession;
