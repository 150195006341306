import classNames from 'classnames';
import styles from './DefaultWelcomeMessage.module.scss';
import { useAuth0 } from '@auth0/auth0-react';

interface DefaultWelcomeMessageProps {
  clientName?: string;
  className?: string;
}

const DefaultWelcomeMessage = ({ clientName, className }: DefaultWelcomeMessageProps) => {
  const { user } = useAuth0();
  const loginCount = user?.['https://tacklit.com/loginsCount'];
  return (
    <div className={classNames(styles.WelcomeMessage, className)}>
      Welcome{loginCount > 1 && ` back`}
      {clientName && `, ${clientName}!`}
    </div>
  );
};

export default DefaultWelcomeMessage;
