import styles from './IP6ProgressBox.module.scss';
import ProgressMenu from './components/ProgressMenu/ProgressMenu';
import ProgressContentBody from './components/ProgressContentBody/ProgressContentBody';
import { useEffect, useState } from 'react';
import { ProgramInterface, ProgressMenuType } from 'helm/interfaces/program/program';

const ProgressBoxMenu = [
  {
    id: ProgressMenuType.Activity,
    imageIcon: {
      icon: 'local_activity',
      color: '#6F1D38'
    },
    title: 'NEXT ACTIVITY',
    desc: 'This is what is coming next in your program schedule'
  },
  {
    id: ProgressMenuType.Progress,
    imageIcon: {
      icon: 'stairs',
      color: '#22348C'
    },
    title: 'PROGRESS',
    desc: 'Track your progress through the program'
  },
  {
    id: ProgressMenuType.Mood,
    imageIcon: {
      icon: 'sentiment_satisfied',
      color: '#CBB600'
    },
    title: 'MOOD TRACKING',
    desc: 'Every day mood track'
  },
  {
    id: ProgressMenuType.SMP,
    imageIcon: {
      icon: 'photo_filter',
      color: '#36A163'
    },
    title: 'POWER TO CHANGE PLAN',
    desc: 'See how your plan is coming together'
  }
];

interface IP6ProgressBoxProps {
  data: ProgramInterface;
  handleNextActivityClick?: number;
}

const IP6ProgressBox = ({ data, handleNextActivityClick }: IP6ProgressBoxProps) => {
  const [activeId, setActiveId] = useState<ProgressMenuType>(ProgressBoxMenu[0].id);

  useEffect(() => {
    setActiveId(ProgressMenuType.Activity);
  }, [handleNextActivityClick]);

  const onHandleSelectItem = (id: ProgressMenuType) => {
    setActiveId(id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.menuWrapper}>
        {ProgressBoxMenu.map((menuObj, index) => {
          return (
            <ProgressMenu
              isSelected={activeId === menuObj.id}
              menuImage={menuObj.imageIcon}
              title={menuObj.title}
              desc={menuObj.desc}
              onselect={() => onHandleSelectItem(menuObj.id)}
              key={index}
            />
          );
        })}
      </div>
      <div className={styles.scrollWrapper}>
        <div className={styles.mobileMenuWrapper}>
          {ProgressBoxMenu.map((menuObj, index) => {
            return (
              <ProgressMenu
                isSelected={activeId === menuObj.id}
                menuImage={menuObj.imageIcon}
                title={menuObj.title}
                desc={menuObj.desc}
                onselect={() => onHandleSelectItem(menuObj.id)}
                mobileCard
                key={index}
              />
            );
          })}
        </div>
      </div>
      <ProgressContentBody data={data} selectedMenu={activeId} />
    </div>
  );
};

export default IP6ProgressBox;
