import { useEffect, useState } from 'react';
import styles from './FitFilterMobileItem.module.scss';
import classNames from 'classnames';
import { scrollToView } from 'utils/scrollToView';

export interface OptionItem {
  id: string;
  label: string;
}

interface FitFilterMobileItemProps {
  id: string;
  filterLabel: string;
  selectedValue: OptionItem | undefined;
  optionList: OptionItem[];
  isExpandProp: boolean;
  onChangeValue: (value: OptionItem) => void;
  classname?: string;
}

const FitFilterMobileItem = ({
  id,
  filterLabel,
  selectedValue,
  optionList,
  isExpandProp,
  onChangeValue,
  classname
}: FitFilterMobileItemProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(isExpandProp);

  useEffect(() => {
    if (isExpand) {
      scrollToView(id);
    }
  }, [isExpand, id]);

  return (
    <div className={classNames(styles.container, classname)}>
      <div
        className={styles.filterHeader}
        onClick={() => {
          setIsExpand(!isExpand);
        }}
      >
        <div className={styles.labelWrapper}>
          <div className={isExpand ? styles.expandLabel : styles.label}>{filterLabel}</div>
          {!isExpand && selectedValue && <div className={styles.selectedValueLabel}>{selectedValue.label}</div>}
        </div>
        <i className={`material-icons-outlined ${styles.icon}`}>
          {isExpand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        </i>
      </div>
      {isExpand && (
        <div className={styles.filterBody}>
          {optionList.map((item, index) => (
            <div
              key={index}
              className={classNames(styles.optionItem, selectedValue?.id === item.id && styles.selected)}
              onClick={() => onChangeValue(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FitFilterMobileItem;
