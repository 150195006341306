// import styles from './CaWContentLibrary.module.scss';

const CaWContentLibrary = () => {
  return (
    <iframe
      src="https://caraniche.online/"
      style={{ flex: 1 }}
      title="CaW Content Library"
      width="100%"
      height="100%"
    />
  );
};

export default CaWContentLibrary;
