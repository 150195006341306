import styles from './PsychologistFAQ.module.scss';
import FAQBubble from './components/FAQBubble/FAQBubble';
import classnames from 'classnames';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

const faqData = [
  {
    title: 'How do I know my therapist is qualified and registered with a professional body?',
    desc: 'We only provide clinical, evidenced based therapies, and all of our therapists are registered with at least one of the following UK professional bodies: HCPC, BABCP, UCKP, ACP, CORST. They are also fully insured and DBS checked.'
  },
  {
    title: 'Can I Choose a Specific Therapist?',
    desc: "Our online booking guide aims to match you with the therapist that best suits your needs. If you are not matching with the person you would like to see or need more specific guidance we recommend booking in for a telephone consultation with one of our team 'booking telephone consultation'."
  },
  {
    title: 'What will happen in my first session?',
    desc: 'Your first session is an opportunity to share your concerns and for your therapist to gather relevant information. It’s a collaborative process where you will work together to establish goals, and begin the therapeutic journey.'
  },
  {
    title: 'How often will I need to see my therapist?',
    desc: 'In order for therapy to be effective we recommend that you attend regular sessions. The frequency of these can be discussed at your initial assessment, but we would usually recommend weekly or fortnightly appointments to begin with.'
  },
  {
    title: 'Will everything we discuss be kept confidential?',
    desc: 'All information discussed in sessions will remain confidential with the following exceptions:<br /><ul><li>If we identify a serious risk to yourself or others</li><li>If required to do do under a court order or by law</li><li>If required to do so for the prevention, detection or prosecution of crime</li></ul>A copy of our Confidentiality and Consent to share policy is available upon request.'
  },
  {
    title: 'How many sessions will I need?',
    desc: 'At your initial assessment you will have an opportunity to discuss the type of therapy that best fits your goals and your therapist will be able to give you an idea about how many sessions they will be recommending. You and your therapist will review your therapy at regular intervals to ensure that you are on track and identify any changes that may be recommended to your treatment plan if needed.'
  },
  {
    title: 'Will my therapist be available in a crisis?',
    desc: 'Your therapist will not be available for support outside of your sessions. If you do have a crisis then we would recommend you contact your GP, or for urgent matters you should contact your local NHS Crisis Team. Your therapist may also give you the details of other agencies they feel may be helpful for you in case of need outside of sessions.'
  },
  {
    title: 'What if I am not finding therapy helpful?',
    desc: 'In the first instance we would recommend that you discuss this with your therapist as it may be that you are able to work together to identify a different formulation or approach. There are occasions when the therapist is not able to provide the best type of therapy for you - if this happens a new therapist will be allocated.'
  }
];

interface FAQProps {
  className?: string;
}

const PsychologistFAQ = ({ className }: FAQProps) => {
  return (
    <div className={classnames(styles.container, className)} id="SelectFAQ">
      <SelectContentLayout>
        <div className={styles.content}>
          <div className={styles.title}>Frequently Asked Questions</div>
          <div className={styles.faqWrapper}>
            {faqData.map((obj, index) => (
              <FAQBubble key={index} details={obj} />
            ))}
          </div>
          <br />
          <div className={styles.desc}>
            We hope this guide is helpful, but please do not hesitate to contact us if we can be of further assistance.
          </div>
        </div>
      </SelectContentLayout>
    </div>
  );
};

export default PsychologistFAQ;
