import { configInterface } from './config';
import UKPrivacy from 'pages/SignUp/PolicyPage/components/UKPrivacy';

export const configUK: configInterface = {
  countryCode: 'gb',
  moneyIcon: 'currency_pound',
  currencySymbol: '£',
  currencyCode: 'gdp',
  taxLabel: 'VAT',
  taxRateLabel: 'VAT',
  codeLabel: 'Sort Code',
  mobileCountryCode: '44',
  defaultTimezone: 'Europe/London',
  contactMaskNumber: '07712 555 444',
  contactMaskNumberWithX: '07xxx xxx xxx',
  emergencyNumber: '999',
  lifeLineNumber: '0808 808 8000',
  practiceSettings: {
    registrationNumberLabel: 'Company Number'
  },
  terms: {
    privacy: <UKPrivacy />
  }
};
