import styles from './NextActivityContent.module.scss';
import { ChapterInterface, ProgramInterface } from 'helm/interfaces/program/program';
import IP6ActivitiesCard from 'helm/pages/IP6Page/components/IP6ActivitiesCard/IP6ActivitiesCard';
import moment from 'moment';
import Button from 'components/v2/Button/Button';
import HowToConnect from './HowToConnect/HowToConnect';
import { scrollToView } from 'utils/scrollToView';
import { getDeliveryTypeLabel } from 'utils/appointment';

export const checkIsTodayOrYesterday = (time: string) => {
  const updateTime = moment(time);
  const TodayDate = moment();
  const TODAY = TodayDate.clone().startOf('day');
  const YESTERDAY = TodayDate.clone().subtract(1, 'days').startOf('day');

  if (moment(updateTime).isSame(TODAY, 'd')) {
    return 'Today';
  } else if (moment(updateTime).isSame(YESTERDAY, 'd')) {
    return 'Yesterday';
  } else {
    return updateTime.format('DD MMMM YYYY');
  }
};

interface NextActivityContentProps {
  data: ProgramInterface;
}

export const getActiveChapter = (data: ProgramInterface) => {
  const chapterWithLink = data.chapters.filter((chapterObj) => chapterObj.link);
  const getLinkLastItem = chapterWithLink.at(-1);
  const checkLinkItem =
    getLinkLastItem &&
    (!getLinkLastItem.completed || getLinkLastItem.sessionActivities.post.some((sessionObj) => !sessionObj.completed));

  const getNextChapter = data.chapters
    .filter((chapterObj) => !chapterObj.link)
    .find(
      (chapterObj) =>
        !chapterObj.completed || chapterObj.sessionActivities.post.some((sessionObj) => !sessionObj.completed)
    );

  const activeChapter: ChapterInterface | undefined = checkLinkItem ? getLinkLastItem : getNextChapter;

  const preSessionActivity = (activeChapter && !activeChapter.link && activeChapter.sessionActivities.pre) || [];
  const postSessionActivity = (activeChapter && activeChapter.sessionActivities.post) || [];

  const checkPreActivityExistOrCompleted = preSessionActivity
    ? preSessionActivity.some((sessionObj) => !sessionObj.completed)
    : false;

  const checkPostActivityExistOrCompleted = postSessionActivity
    ? postSessionActivity.some((sessionObj) => !sessionObj.completed)
    : false;

  return {
    activeChapter,
    preSessionActivity,
    postSessionActivity,
    checkPreActivityExistOrCompleted,
    checkPostActivityExistOrCompleted
  };
};

const NextActivityContent = ({ data }: NextActivityContentProps) => {
  const {
    activeChapter,
    preSessionActivity,
    postSessionActivity,
    checkPreActivityExistOrCompleted,
    checkPostActivityExistOrCompleted
  } = getActiveChapter(data);

  const isSessionReady = activeChapter && !activeChapter.completed;

  const handleVisitProgram = () => {
    scrollToView('yourProgram', true, false, true);
  };

  return data.isUnlockAll ? (
    <div className={styles.container}>
      <div className={styles.unlockContent}>
        <div className={styles.doneTitle}>You have no pending activities.</div>
        <div className={styles.doneDesc}>
          All chapters of the Power to Change Program are unlocked.
          <br />
          <br />
          You can revisit the exercises any time you like.
          <br />
          <br />
          Your power to change plan is available to download or view from your profile menu.
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={handleVisitProgram} className={styles.button}>
            Explore program content <i className={`material-icons-outlined`}>navigate_next</i>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      {checkPreActivityExistOrCompleted ? (
        <div className={styles.taskContentWrapper}>
          <IP6ActivitiesCard
            className={styles.activityContent}
            cardClassName={styles.card}
            activitiesData={preSessionActivity}
          />
        </div>
      ) : isSessionReady ? (
        <div className={styles.sessionContent}>
          <div className={styles.sessionBox}>
            <div className={styles.session}>SESSION {activeChapter.chapterId}</div>
            {activeChapter.appointment && (
              <div className={styles.sessionTime}>
                {activeChapter.appointment.duration} minutes,{' '}
                {activeChapter.appointment.deliveryType &&
                  getDeliveryTypeLabel(
                    activeChapter.appointment.deliveryType,
                    activeChapter.appointment.otherInstructions
                  )}{' '}
                booked for {activeChapter.appointment?.date && checkIsTodayOrYesterday(activeChapter.appointment.date)}{' '}
                {moment(activeChapter.appointment?.startTime, 'hh:mm').format('hh:mmA')}.
              </div>
            )}
            <div className={styles.chapterName}>{activeChapter.name}</div>
            <div className={styles.chapterDesc}>{activeChapter.description}</div>
            {activeChapter.appointment && <HowToConnect appointmentData={activeChapter.appointment} />}
            {activeChapter.link && (
              <div className={styles.moduleButtonWrapper}>
                <Button
                  onClick={() => (window.location.href = activeChapter.link || '')}
                  className={styles.moduleButton}
                >
                  Start this module
                </Button>
              </div>
            )}
          </div>
          <div
            className={styles.bgImage}
            style={{
              background: `url(${activeChapter.image}) center center no-repeat`
            }}
          />
        </div>
      ) : checkPostActivityExistOrCompleted ? (
        <div className={styles.taskContentWrapper}>
          <IP6ActivitiesCard
            className={styles.activityContent}
            cardClassName={styles.card}
            activitiesData={postSessionActivity}
          />
        </div>
      ) : (
        <div className={styles.doneActivityContent}>
          <div className={styles.doneTitle}>Congratulations!</div>
          <div className={styles.doneDesc}>
            You have completed the Power to Change Program.
            <br />
            <br />
            You can revisit all the content from your program via the links below.
            <br />
            <br />
            And your Power to Change Plan is available to access any time as well.
          </div>
        </div>
      )}
    </div>
  );
};

export default NextActivityContent;
