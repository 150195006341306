import { Link } from 'react-router-dom';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

import styles from './PsychologistDetailsHeader.module.scss';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

interface PractitionerDetailsHeaderProps {
  practitionerDetails: PractitionerDetailsInterface;
  listingLink: string;
  noLogin?: boolean;
}

const PsychologistDetailsHeader = ({ practitionerDetails, listingLink, noLogin }: PractitionerDetailsHeaderProps) => {
  return (
    <div className={styles.container}>
      <SelectHeader withPadding noWrapper withMenu noLogin={noLogin} />
      <SelectContentLayout className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.middleWrapper}>
            <div className={styles.headerImageContainer}>
              {practitionerDetails.helmControl?.headerPhoto && (
                <img
                  alt={'profile header'}
                  className={styles.headerImage}
                  src={practitionerDetails.helmControl.headerPhoto}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.overlay}>
          <div className={styles.detailsWrapper}>
            <div className={styles.actionWrapper}>
              <Link to={listingLink} className={styles.otherPractitioner}>
                <i className={`material-icons ${styles.icon}`}>arrow_back</i>
                <div className={styles.label}>SEE OTHER THERAPISTS</div>
              </Link>
            </div>
            <div className={styles.name}>{practitionerDetails.name}</div>
          </div>
        </div>
      </SelectContentLayout>
    </div>
  );
};

export default PsychologistDetailsHeader;
