import { ReactNode } from 'react';
import styles from './CORDSContentWrapper.module.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CORDSHeader from '../CORDSHeader/CORDSHeader';
import classNames from 'classnames';

interface CORDSContentWrapperProps {
  title?: string;
  desc?: string;
  children?: ReactNode;
  contentClassName?: string;
  fullwidth?: boolean;
}

const CORDSContentWrapper = ({ children, title, desc, contentClassName, fullwidth }: CORDSContentWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={desc || 'CORDS - Child Outcomes Record Data System'} />
      </Helmet>
      <CORDSHeader contentClassNames={styles.container} />
      <div className={classNames(styles.container, styles.content, contentClassName, fullwidth && styles.fullwidth)}>
        {children}
      </div>
    </HelmetProvider>
  );
};

export default CORDSContentWrapper;
