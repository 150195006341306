import styles from './NoAvailableMessageBox.module.scss';
import classnames from 'classnames';
import ButtonSH from '../ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from '../SomeoneHealthContentLayout/SomeoneHealthContentLayout';

interface NoAvailableMessageBoxProps {
  title: string;
  desc: string | JSX.Element;
  buttonLabel: string;
  onClick: () => void;
  titleClassName?: string;
  buttonClassName?: string;
}

const NoAvailableMessageBox = ({
  title,
  desc,
  buttonLabel,
  onClick,
  titleClassName,
  buttonClassName
}: NoAvailableMessageBoxProps) => (
  <SomeoneHealthContentLayout>
    <div className={styles.container}>
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      <div className={styles.buttonWrapper}>
        <ButtonSH onClick={onClick}>{buttonLabel}</ButtonSH>
      </div>
    </div>
  </SomeoneHealthContentLayout>
);

export default NoAvailableMessageBox;
