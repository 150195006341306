import { useEffect, useRef } from 'react';
import useBazaarTokens from '../../../utils/hooks/useBazaarTokens';
import AuthErrorMessage from '../AuthErrorMessage/AuthErrorMessage';
import styles from './BeforeWeBegin.module.scss';
import Form from './Form/Form';

const BeforeWeBegin = () => {
  const { token, hasFailedToRequestStorage, requestStorageAccess } = useBazaarTokens();

  const ref = useRef(null);

  const resizeParentFrame = () => {
    for (const resizeComponentID of ['tacklitBeforeWeBegin', 'BeforeWeBegin']) {
      // @ts-ignore
      // eslint-disable-next-line
      parent.postMessage(
        {
          resizeComponentID,
          // @ts-ignore
          height: ref?.current?.offsetHeight || 0
        },
        '*'
      );
    }
  };

  useEffect(() => {
    resizeParentFrame();
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.title}>BEFORE&nbsp;&nbsp; WE&nbsp;&nbsp; BEGIN</div>
      {!token ? (
        <AuthErrorMessage
          requestStorageAccess={requestStorageAccess}
          hasFailedToRequestStorage={hasFailedToRequestStorage}
        />
      ) : (
        <Form token={token} resizeParentFrame={resizeParentFrame} />
      )}
    </div>
  );
};

export default BeforeWeBegin;
