import styles from './LoadingDot.module.scss';

const LoadingDot = () => (
  <div className={styles.loading}>
    <div className={styles.circle} />
    <div className={styles.circle} />
    <div className={styles.circle} />
  </div>
);

export default LoadingDot;
