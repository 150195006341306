import { AppointmentSlot } from 'utils/hooks/appointment';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { RECHARGE_TIME_ZONE_LIST, rechargeTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { RechargeDefaultTimezone } from './RechargeEnv/RechargeEnv';

export const getReservedAppointmentTimeString = (value: AppointmentSlot) => {
  const clientTimeZone =
    localStorage.getItem(rechargeTimeZoneLocalStorage) ||
    // use browser timezone if browser timezone is supported
    RECHARGE_TIME_ZONE_LIST.find((tzObj) =>
      // eslint-disable-next-line new-cap
      tzObj.timezones.find((tzString) => tzString === Intl.DateTimeFormat().resolvedOptions().timeZone)
    )?.id ||
    // use default client timezone
    RECHARGE_TIME_ZONE_LIST.find((tzObj) => tzObj.id === process.env.REACT_APP_DEFAULT_CLIENT_TIMEZONE)?.id ||
    RECHARGE_TIME_ZONE_LIST[0].id;

  const massageTimeZone = convertTimeSlot(
    value.startTime,
    value.endTime,
    moment(value.date).format('YYYY-MM-DD'),
    clientTimeZone
  );

  return `${moment(value.date).format('DD MMMM YYYY')}, ${moment(massageTimeZone.startTime, 'hh:mmA').format(
    'hh:mm'
  )} to ${massageTimeZone.endTime}`;
};

export const convertTimeSlot = (startTime: string, endTime: string, date: string | Date, clientTimeZone: string) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, 'YYYY-MM-DD hh:mmA', RechargeDefaultTimezone);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, clientTimeZone).format('hh:mmA');
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, 'YYYY-MM-DD hh:mmA', RechargeDefaultTimezone);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, clientTimeZone).format('hh:mmA');
  return {
    startTime: clientStartTimeZone,
    endTime: clientEndTimeZone
  };
};
