import Form from 'antd/lib/form/Form';
import classNames from 'classnames';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import OCD from 'SomeoneHealth/assets/images/OCD.png';
import styles from './PartnerSignUp.module.scss';
import { reservedPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { scrollToView } from 'utils/scrollToView';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'SomeoneHealth/utils/hooks/appointment';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { postCheckCaseId } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import moment from 'moment';
import {
  EmergencyContactDetail,
  KeyClientContact,
  clientRecordOnlySignUpPayload,
  postClientRecordSignUp
} from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { ClientRecordType, CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import SomeoneHealthTimer from 'SomeoneHealth/pages/SomeoneHealthTimer/SomeoneHealthTimer';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import ErrorMessageWithHelpLink from 'SomeoneHealth/pages/SignUpWithInvite/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { useSomeoneHealthPartnerRoutesGenerator } from 'SomeoneHealth/PartnerReferral/utils/Path/SomeoneHealthPartnerRoutesGenerator';
import SHPartnerCreateProfileForm from './components/SHPartnerCreateProfileForm/SHPartnerCreateProfileForm';
import {
  PARTNER_SH_SIGN_UP_USER,
  storeUserSession,
  UserSessionStorage
} from 'SomeoneHealth/pages/SignUp/helper/userSession';
import { getSomeoneHealthSupportEmail } from 'SomeoneHealth/PartnerReferral/utils/getSomeoneHealthSupportEmail';
import { PARTNER_CODE_STORAGE } from 'interfaces/PartnerReferral/constants';
import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import {
  getAuMobile,
  formDataValidationSchema,
  initialClientRecordOnly,
  checkIsYoungPerson,
  checkIsChild,
  PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS,
  SH_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP
} from './constants';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import AgeRestrictionAlert from 'SomeoneHealth/components/AgeRestrictionAlert/AgeRestrictionAlert';
import SHPartnerCommunicationPreferencesForm from './components/SHPartnerCommunicationPreferencesForm/SHPartnerCommunicationPreferencesForm';
import SHPartnerParentOrGuardianForm from './components/SHPartnerParentOrGuardianForm/SHPartnerParentOrGuardianForm';
import SHPartnerBackgroundInformationForm from './components/SHPartnerBackgroundInformationForm/SHPartnerBackgroundInformationForm';
import SHPartnerEmergencyContactForm from './components/SHPartnerEmergencyContactForm/SHPartnerEmergencyContactForm';

const PartnerSignUp = () => {
  const navigate = useNavigate();
  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;
  const { SIGNUP } = useSomeoneHealthPartnerRoutesGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } = useReserveAppointmentData(reservedPartnerReferral);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const [caseIdErrorMsg, setCaseIdErrorMsg] = useState<ReactNode>();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });
  const partnerCode = localStorage.getItem(PARTNER_CODE_STORAGE) as AppointmentBookingClaimType | undefined;
  const supportEmail = getSomeoneHealthSupportEmail(partnerCode);

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession(value, dob, PARTNER_SH_SIGN_UP_USER);
    scrollToView('SomeoneHealthHeader');
    navigate(SIGNUP.SUMMARY_BOOKING);
  };

  const onSubmitHandle = async (values: typeof initialClientRecordOnly) => {
    setIsSubmitting(true);
    const formatMobile = values.mobileNumber ? getAuMobile(values.mobileNumber) : '';
    const phoneValidate = values.mobileNumber ? await validatePhoneNumber(formatMobile) : undefined;

    const checkEmailExisted = values.email
      ? await checkEmailAlreadyExisted({
          accountId: accountId!,
          email: values.email
        })
      : undefined;

    const checkCaseIdExisted =
      values.caseId && accountId ? await postCheckCaseId('someone-health', { caseId: values.caseId }) : undefined;

    let isError = false;
    if (checkCaseIdExisted && checkCaseIdExisted.statusCode !== 204) {
      isError = true;
      setCaseIdErrorMsg(<ErrorMessageWithHelpLink errorMessage="Unique Id is duplicated." email={supportEmail} />);
    }
    if (phoneValidate && !phoneValidate.valid) {
      isError = true;
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink
          errorMessage="Mobile number not recognized. Please check for typos and try again."
          email={supportEmail}
        />
      );
    }
    if (checkEmailExisted && checkEmailExisted?.response?.used) {
      isError = true;
      setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." email={supportEmail} />);
    }
    if (isError) {
      setIsSubmitting(false);
      return;
    }

    try {
      const {
        email,
        firstName,
        lastName,
        caseId,
        providerDetails,
        referralBackground,
        receiveSMS,
        receiveEmail,
        guardianFirstName,
        guardianLastName,
        guardianMobileNumber,
        guardianEmail,
        guardianRelationship,
        emergencyContacts,
        saveAsEmergencyContact,
        consentToContact
      } = values;
      const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
      const communicationPreferenceLogic =
        receiveEmail && receiveSMS
          ? CommunicationPreference.NoPreference
          : receiveEmail
          ? CommunicationPreference.Email
          : receiveSMS
          ? CommunicationPreference.SMS
          : CommunicationPreference.NONE;

      const isYoungPerson = checkIsYoungPerson(values.dateOfBirth);

      const guardianRoleAsTag = PARENT_OR_GUARDIAN_RELATIONSHIP_OPTIONS.find(
        (item) => item.value === guardianRelationship
      )?.label;

      const keyClientContacts: KeyClientContact[] = emergencyContacts.map((contact: EmergencyContactDetail) => ({
        ...contact,
        tags: [contact.relationship, SH_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
      }));

      const payloadMassage: clientRecordOnlySignUpPayload = {
        clinicianId: clinicianId,
        clientRecord: {
          avatar: '',
          mobile: formatMobile,
          email,
          name: firstName,
          dateOfBirth: dob,
          firstName,
          lastName,
          postcode: '',
          timeZone: clientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
          caseId,
          providerDetails,
          referralBackground,
          communicationPreference: communicationPreferenceLogic,
          appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
          recordType: isYoungPerson ? ClientRecordType.YoungPerson : ClientRecordType.Adult,
          ...(isYoungPerson && {
            additionalProfiles: [
              {
                firstName: guardianFirstName,
                lastName: guardianLastName,
                mobileNumber: guardianMobileNumber,
                email: guardianEmail,
                role: guardianRelationship,
                isPrimaryContact: consentToContact
              }
            ]
          }),
          ...(isYoungPerson && {
            keyClientContacts: saveAsEmergencyContact
              ? [
                  ...keyClientContacts,
                  {
                    firstName: guardianFirstName,
                    lastName: guardianLastName,
                    mobileNumber: guardianMobileNumber,
                    email: guardianEmail,
                    tags: guardianRoleAsTag
                      ? [guardianRoleAsTag, SH_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
                      : [SH_DEFAULT_EMERGENCY_CONTACT_RELATIONSHIP]
                  }
                ]
              : keyClientContacts
          })
        }
      };
      const callPatientSignUp = await postClientRecordSignUp(accountId || '', payloadMassage);

      const { clientRecord, authToken } = await callPatientSignUp.json();
      await fetchClaimingReservation(reserveId!, accountId!, authToken);
      setIsSubmitting(false);
      proceedToNextStep({ clientRecord, authToken }, dob);
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to sign up account'
      });
    }
  };

  return fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <SomeoneHealthHelmetWrapper title={'someone.health - Sign Up'}>
      <SomeoneHealthContentLayout>
        <SomeoneHealthHeader
          pinkLogo
          withPadding
          noLogin
          classNames={styles.header}
          loginTextClass={styles.loginTextClass}
        />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            {isSubmitting && (
              <div className={styles.loading}>
                <Loading />
              </div>
            )}
            <div className={classNames(styles.leftContentWrapper, 'someone-health-theme')}>
              <div className={styles.header}>
                <div>
                  Welcome to <span className={styles.highlight}>someone.health</span>
                </div>
                <div className={styles.subHeader}>
                  You are creating a referral to a qualified psychologist via our private access channel. Please
                  complete the following information to book an appointment
                </div>
              </div>
              <Formik
                initialValues={initialClientRecordOnly}
                validationSchema={formDataValidationSchema}
                onSubmit={onSubmitHandle}
              >
                {({ values, errors, isValid, validateForm, setFieldValue }) => (
                  <Form noValidate className={styles.formWrapper}>
                    {checkIsChild(values.dateOfBirth) && (
                      <AgeRestrictionAlert
                        open={checkIsChild(values.dateOfBirth)}
                        onClose={() => setFieldValue('dateOfBirth', '')}
                      />
                    )}
                    <SHPartnerCreateProfileForm
                      emailErrorMsg={emailErrorMsg}
                      mobileNumberErrorMsg={mobileNumberErrorMsg}
                      caseIdErrorMsg={caseIdErrorMsg}
                      setEmailErrorMsg={setEmailErrorMsg}
                      setCaseIdErrorMsg={setCaseIdErrorMsg}
                      setMobileNumberErrorMsg={setMobileNumberErrorMsg}
                    />
                    {checkIsYoungPerson(values.dateOfBirth) && (
                      <>
                        <SHPartnerParentOrGuardianForm />
                        <SHPartnerEmergencyContactForm />
                      </>
                    )}
                    <SHPartnerBackgroundInformationForm />
                    <SHPartnerCommunicationPreferencesForm />
                    <ButtonSH
                      className={styles.submitButton}
                      disabled={isEqual(values, initialClientRecordOnly) || !isValid}
                      onClick={() => {
                        validateForm().then((errors) => {
                          if (isEmpty(errors)) {
                            onSubmitHandle({
                              ...values
                            });
                          }
                        });
                      }}
                    >
                      Create Your Profile
                    </ButtonSH>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.topNotch}>
              <img src={OCD} alt={'Someone Health top notch'} />
              <div className={styles.topNotchTitle}>Top-notch privacy and security</div>
              <div className={styles.topNotchDesc}>
                <b>someone.health</b> was built to exceed all regulatory requirements for privacy and security. All data
                is stored within Australia and encrypted both at-rest and in-transit, with strict access controls.
              </div>
            </div>
          </div>
        </div>
        <SomeoneHealthTimer
          isReservedAppointmentFetching={fetching}
          reservedAppointments={appointments}
          hideTimer
          reversedAppointmentId={reservedPartnerReferral}
        />
      </SomeoneHealthContentLayout>
      <TacklitFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default PartnerSignUp;
