import { ReactNode, useState } from 'react';
import styles from './EaseCreateProfileForm.module.scss';
import { Formik, FormikErrors } from 'formik';
import Form from 'antd/lib/form/Form';
import FormikInput from 'components/Input/FormikInput';
import FormikDatePicker from 'components/DatePicker/FormikDatePicker';
import CheckBox from 'components/CheckBox/CheckBox';
import { isEmpty, isEqual } from 'lodash';
import classnames from 'classnames';
import PasswordRules from 'pages/SignUp/SignUpForm/components/Security/components/PasswordRules/PasswordRules';
import Loading from 'components/Loading/Loading';
import classNames from 'classnames';
import ButtonEase from '../ButtonEase/ButtonEase';
import { formDataValidationSchema, initialCustomer, passwordValidationSchema } from './constants';
import easeLogo from 'Ease/assets/images/logo/logo.png';
import EaseMobileFormikInput from '../EaseMobileFormikInput/EaseMobileFormikInput';
import { EasePrivacyPolicyUrl } from 'Ease/utils/EaseEnv/EaseEnv';
import FormikRadio from 'components/Radio/FormikRadio';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';

interface EaseCreateProfileFormProps {
  isSubmitting: boolean;
  emailErrorMsg?: ReactNode | string;
  mobileNumberErrorMsg?: ReactNode | string;
  setEmailErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  setMobileNumberErrorMsg: ((value?: ReactNode) => void) | ((value?: string) => void);
  onSubmit: (value: typeof initialCustomer) => void;
  customAgeCheck?: number;
  subHeader?: ReactNode;
  practiceName: string;
}

const EaseCreateProfileForm = ({
  isSubmitting,
  emailErrorMsg,
  mobileNumberErrorMsg,
  setEmailErrorMsg,
  setMobileNumberErrorMsg,
  onSubmit,
  customAgeCheck,
  subHeader,
  practiceName
}: EaseCreateProfileFormProps) => {
  const [pwScore, setPwScore] = useState(0);
  const [ageConfirm, setAgeConfirm] = useState(false);
  const [showPwError, setShowPwError] = useState(false);

  const isButtonEnabled = (isValid: boolean, errors: FormikErrors<typeof initialCustomer>) => {
    if (!ageConfirm) {
      return false;
    }
    const hasOtherErrorThanPassword = Object.values({ ...errors, password: '' }).reduce(
      (res, item) => res || !!item,
      false
    );
    if (!isValid) {
      if (!showPwError) {
        return !hasOtherErrorThanPassword;
      } else return false;
    }
    return true;
  };

  return (
    <>
      {isSubmitting && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <div className={classNames(styles.container, 'ease-theme')}>
        <div className={styles.header}>
          <img className={styles.logo} alt={'logo'} src={easeLogo} />
          <div className={styles.headerText}>
            Welcome to {practiceName}
            <div className={styles.subHeader}>
              {subHeader ? subHeader : 'Create your secure account and take your first step to a better future.'}
            </div>
          </div>
        </div>
        <div className={styles.loginText}>
          Already have an account?
          <a href={`${process.env.REACT_APP_CLIENT_DOMAIN_EASE}/?from=signup`} className={styles.loginLink}>
            Sign in instead
          </a>
        </div>
        <Formik
          initialValues={initialCustomer}
          validationSchema={formDataValidationSchema(customAgeCheck).concat(passwordValidationSchema(pwScore))}
          onSubmit={onSubmit}
        >
          {({ values, errors, isValid, validateForm }) => (
            <Form noValidate>
              <div className={styles.fixCustomInput}>
                <FormikInput
                  inputClass={styles.customInput}
                  label="First Name*"
                  name="firstName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Last Name*"
                  name="lastName"
                  required
                  labelClass={styles.inputLabel}
                />
                <FormikDatePicker
                  inputClass={styles.customInput}
                  label="Date of birth*"
                  name="dateOfBirth"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                />
                <EaseMobileFormikInput
                  inputClass={styles.customInput}
                  id={`mobileNumber`}
                  name={'mobileNumber'}
                  label={'Mobile number*'}
                  mobilePattern="_____ ___ ___"
                  placeholder={'07712 555 444'}
                  onChange={() => mobileNumberErrorMsg && setMobileNumberErrorMsg()}
                  phonePrefix=""
                  externalError={mobileNumberErrorMsg}
                  required
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Email address*"
                  name="email"
                  required
                  externalError={emailErrorMsg}
                  onChange={() => emailErrorMsg && setEmailErrorMsg()}
                  labelClass={styles.inputLabel}
                />
                <FormikInput
                  inputClass={styles.customInput}
                  label="Set Secure Password*"
                  name="password"
                  type="password"
                  required
                  showError={showPwError}
                  labelClass={styles.inputLabel}
                  allowShowPassword
                  showPasswordButtonClass={styles.showPasswordButtonClass}
                />
              </div>
              <div className={classnames(styles.passwordHint, showPwError && styles.passwordHintWithError)}>
                It must meet the following password rules:
                <div className={styles.hints}>
                  <PasswordRules password={values.password} onChangePasswordScore={setPwScore} />
                </div>
              </div>

              <div className={styles.ageConfirm}>
                <div className={styles.checkBoxText}>
                  <CheckBox
                    id="ageConfirm"
                    value={ageConfirm}
                    onChange={(e) => setAgeConfirm(e.target.checked)}
                    className={styles.checkBox}
                    label={`I confirm I am over the age of 18 and agree to ${practiceName}'s <a target="_blank" href="${EasePrivacyPolicyUrl}">Privacy Policy and Terms & Conditions</a>`}
                  />
                </div>
              </div>
              <div className={styles.communicationPreferences}>
                <div className={styles.title}>Communication Preferences</div>
                <div className={styles.text}>
                  In order to deliver our service, EASE Wellbeing needs to contact you via email and SMS. This includes
                  items like appointment reminders, and requests to complete documents relevant to your therapy. EASE
                  Wellbeing will not email or text you for marketing purposes. Please choose at least one communication
                  preference below in order to book the appointment.
                </div>
                <FormikRadio
                  id={'communicationPreference'}
                  name={'communicationPreference'}
                  options={[
                    { label: 'I would like Email correspondence only', value: CommunicationPreference.Email },
                    { label: 'I would like SMS correspondence only', value: CommunicationPreference.SMS },
                    { label: 'SMS and / or Email', value: CommunicationPreference.NoPreference }
                  ]}
                  radioLabelClass={styles.radioLabel}
                  vertical
                />
              </div>

              <ButtonEase
                className={styles.submitButton}
                disabled={
                  !values.password ||
                  values.password.length < 2 ||
                  isEqual(values, initialCustomer) ||
                  !isButtonEnabled(isValid, errors)
                }
                onClick={() => {
                  setShowPwError(true);
                  validateForm().then((errors) => {
                    if (isEmpty(errors)) {
                      onSubmit(values);
                    }
                  });
                }}
              >
                Create Your Profile
              </ButtonEase>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EaseCreateProfileForm;
