import styles from './SomeoneHealthHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import SomeoneHealthContentLayout from '../SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import LoginAvatar from 'SomeoneHealth/components/LoginAvatar/LoginAvatar';
import someoneHealthLogo from 'SomeoneHealth/assets/images/logo/logo.svg';
import someoneHealthMaroonLogo from 'SomeoneHealth/assets/images/logo/logo.png';
import someoneHealthPinkLogo from 'SomeoneHealth/assets/images/brandLogo.svg';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { Link } from 'react-router-dom';

interface SomeoneHealthHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  classNames?: string;
  loginTextClass?: string;
  pinkLogo?: boolean;
  pinkMenu?: boolean;
  showDirectorySwitch?: boolean;
  isDirectory?: boolean;
}

const SomeoneHealthHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  classNames,
  loginTextClass,
  pinkLogo,
  pinkMenu,
  showDirectorySwitch,
  isDirectory
}: SomeoneHealthHeaderProps) => {
  const navigate = useNavigate();
  const URLs = someoneHealthEnvironment();

  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = URLs.SomeoneHealthURL;
    }
  };

  return (
    <SomeoneHealthContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, classNames)}
    >
      <div id={'SomeoneHealthHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img alt="logo" className={styles.logoImage} src={pinkLogo ? someoneHealthPinkLogo : someoneHealthLogo} />
          <img alt="logo" className={styles.maroonLogoImage} src={someoneHealthMaroonLogo} />
        </div>
        <div className={styles.rightContent}>
          {showDirectorySwitch && (
            <Link className={styles.switchViewLink} to={isDirectory ? PRACTITIONER.LISTING : PRACTITIONER.DIRECTORY}>
              {`Psychologist ${isDirectory ? 'Matching' : 'Directory'}`}
            </Link>
          )}
          {!noLogin && (
            <LoginAvatar
              whiteFont={whiteFont}
              pinkMenu={pinkMenu}
              withMenu={withMenu}
              loginTextClass={loginTextClass}
            />
          )}
        </div>
      </div>
    </SomeoneHealthContentLayout>
  );
};

export default SomeoneHealthHeader;
