import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { scheduleServicesApiSlice } from './services/scheduleServicesApiSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      clinicianProfileServicesApiSlice.middleware,
      scheduleServicesApiSlice.middleware,
      checkInServicesApiSlice.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
