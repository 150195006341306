import CheckBox from 'components/CheckBox/CheckBox';
import Loading from 'components/Loading/Loading';
import { useFetchReservedAppointmentsWithTimeZone } from 'SomeoneHealth/utils/hooks/appointment';
import { useContext, useEffect, useRef, useState } from 'react';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import PartnerBookingSummaryItem from './components/PartnerBookingSummaryItem/PartnerBookingSummaryItem';
import { reservedPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import styles from './PartnerBookingSummary.module.scss';
import SomeoneHealthTimer from 'SomeoneHealth/pages/SomeoneHealthTimer/SomeoneHealthTimer';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { UserContext } from 'utils/UserContextProvider';
import { message } from 'antd';
import { useSomeoneHealthToken } from 'SomeoneHealth/utils/hooks/useSomeoneHealthToken';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { useSomeoneHealthPartnerRoutesGenerator } from '../../utils/Path/SomeoneHealthPartnerRoutesGenerator';
import { PARTNER_SH_SIGN_UP_USER, retrieveUserSession } from '../../../pages/SignUp/helper/userSession';
import { putProcessWithoutPayment } from 'utils/http/appointment';

const PartnerBookingSummary = () => {
  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;
  const { token } = useSomeoneHealthToken(PARTNER_SH_SIGN_UP_USER);
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { SIGNUP } = useSomeoneHealthPartnerRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession(PARTNER_SH_SIGN_UP_USER).clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData(reservedPartnerReferral);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        await fetchClaimingReservation(reserveId, accountId, token);

        const res = await putProcessWithoutPayment(accountId, reserveId, token);

        if (res.statusCode === 204) {
          setConfirmBookingStatus('finished');
          navigate(SIGNUP.CONFIRM_BOOKING);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <SomeoneHealthHelmetWrapper title={'someone.health - Booking Summary'}>
        <SomeoneHealthHeader withPadding pinkLogo withMenu pinkMenu noLogin />
        <SomeoneHealthContentLayout className={styles.layoutClassName}>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'someone-health-theme')}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <div className={styles.greet}>You are about to confirm the following</div>
                  <div className={styles.subHeader}>
                    <div className={styles.name}>
                      This is the appointment being held for{' '}
                      <strong>{clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}</strong>
                    </div>
                    <br />
                    <div className={styles.desc}>
                      It will not be confirmed unless you complete within the required time limit
                    </div>
                  </div>
                  <PartnerBookingSummaryItem
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <div className={styles.cancellationPolicy}>
                    <div className={styles.title}>IMPORTANT INFORMATION</div>
                    <p className={styles.content}>
                      We are providing your information securely to someone.health to facilitate the delivery of this
                      appointment. They will provide you with more details before your session commences
                    </p>
                  </div>

                  <CheckBox
                    id="policyAccepted"
                    value={policyAccepted}
                    onChange={(event) => setPolicyAccepted(event.target.checked)}
                    className={styles.checkBox}
                    label="I have read the important information to the client and they have acknowledged and accepted"
                  />

                  <ButtonSH
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!policyAccepted || !token}
                    className={styles.submitButton}
                  >
                    Confirm Appointment Booking
                  </ButtonSH>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <SomeoneHealthTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                      reversedAppointmentId={reservedPartnerReferral}
                    />
                    <div className={styles.noticeMsg}>
                      <div>
                        To confirm these appointments you need to complete registration and payment within 15 minutes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </SomeoneHealthHelmetWrapper>
    </div>
  );
};

export default PartnerBookingSummary;
