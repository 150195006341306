import { claimReservedAppointments } from 'utils/http/SchedService/ReservedAppointments/claimReservation';
import { notification } from 'antd';

export const fetchClaimingReservation = async (reserveId: string, accountId: string, authToken: string) => {
  if (reserveId && accountId && authToken) {
    try {
      const { statusCode } = await claimReservedAppointments({
        accountId,
        reservationId: reserveId,
        authToken,
        shouldGenerateCheckoutSession: false
      });

      if (statusCode !== 200 && statusCode !== 403) {
        const errorMessage = `Claim reservation (ID: ${reserveId}) failed with status. ${statusCode}`;
        console.error(errorMessage);
        notification.error({ message: errorMessage });
      }
    } catch (ex) {
      console.error(ex);
    }
  } else {
    console.error(`Missing either reserveId: ${reserveId}, accountId: ${accountId}, or authToken: ${authToken}`);
  }
};
