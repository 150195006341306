import { useEffect } from 'react';
import styles from './PractitionerDetails.module.scss';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmContentLayout from '../../components/HelmContentLayout/HelmContentLayout';
import PractitionerImageSlideshow from '../../../components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { useFetchPractitionerDetails } from './hooks/getPractitionerDetails';
import HelmFooter from 'helm/components/HelmFooter/HelmFooter';
import PractitionerDetailsHeader from './components/PractitionerDetailsHeader/PractitionerDetailsHeader';
import Button from 'helm/components/Button/Button';
import PractitionerBonaFides from '../../components/PractitionerBonaFides/PractitionerBonaFides';
import PractitionerSpecialisation from '../PractitionerListing/components/PractitionerSpecialisation/PractitionerSpecialisation';
import PractitionerServiceDetails from '../../components/PractitionerServiceDetails/PractitionerServiceDetails';
import PractitionerQuote from './components/PractitionerQuote/PractitionerQuote';
import PractitionerTimeSlot from './components/PractitionerTimeSlot/PractitionerTimeSlot';
import PractitionerWelcomeNote from './components/PractitionerWelcomeNote/PractitionerWelcomeNote';
import PractitionerFAQ from './components/PractitionerFAQ/PractitionerFAQ';
import { useLocation, useParams } from 'react-router-dom';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { scrollToView } from 'utils/scrollToView';
import queryString from 'query-string';
import { PractitionerQueryParam } from '../PractitionerListing/PractitionerListing';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { helmEnvironment } from '../../utils/HelmEnv/helmEnv';

const { HelmFAQURL } = helmEnvironment();
export const helmFAQ = {
  title: 'Frequently Asked Questions',
  desc: `Have a query about our service, our counsellors, pricing or payment? Check out our FAQs <a href=${HelmFAQURL} target="_blank">here</a>.`,
  faqData: []
};

const PractitionerDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useHelmRoutesGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PractitionerQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('HelmTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return isPractitionerDetailsLoading ? (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  ) : (
    <HelmHelmetWrapper title={`HELM - ${practitionerName} Profile`}>
      <PractitionerDetailsHeader practitionerDetails={practitionerDetails} listingLink={backToListingLink} />
      <div className={styles.container}>
        <HelmContentLayout>
          <div className={styles.detailsContentWrapper}>
            <div className={styles.leftContent}>
              <PractitionerWelcomeNote practitionerDetails={practitionerDetails} />
            </div>
            <div className={styles.rightContent}>
              {practitionerDetails.helmControl.additionalPhotos?.length > 0 && (
                <div className={styles.slideShowWrapper}>
                  <div className={styles.slideShowCard}>
                    <PractitionerImageSlideshow slidePhoto={practitionerDetails.helmControl.additionalPhotos} />
                  </div>
                </div>
              )}
              <div className={styles.detailsWrapper}>
                <div className={styles.appointmentBtnWrapper}>
                  <Button onClick={() => scrollToView('HelmTimeSlot')} className={styles.appointmentBtn}>
                    BOOK AN APPOINTMENT
                  </Button>
                </div>
                <PractitionerBonaFides bonaFidesDetails={practitionerDetails.helmControl.bonaFides} />
                <PractitionerSpecialisation vertical specialisationsDetails={practitionerDetails.specialisations} />
                <PractitionerServiceDetails helmDetails={practitionerDetails} />
              </div>
            </div>
          </div>
        </HelmContentLayout>
      </div>
      {practitionerDetails.helmControl.quote && <PractitionerQuote quote={practitionerDetails.helmControl.quote} />}
      <PractitionerTimeSlot helmDetails={practitionerDetails} overlayImage />
      <PractitionerFAQ data={helmFAQ} id={'HelmFAQ'} />
      <HelmFooter />
    </HelmHelmetWrapper>
  );
};

export default PractitionerDetails;
