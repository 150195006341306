import { Route, Routes } from 'react-router-dom';
import appRoute from '../../components/AppRoute/AppRoute';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { UserContextProvider } from '../../utils/UserContextProvider';
import PsychologistListing from 'Recharge/pages/PsychologistListing/PsychologistListing';
import PsychologistDetails from '../../Recharge/pages/PsychologistDetails/PsychologistDetails';
import SignUp from 'Recharge/pages/SignUp/SignUp';
import SignUpCompleteBooking from 'Recharge/pages/SignUpCompleteBooking/SignUpCompleteBooking';
import RechargeHomePage from '../../Recharge/pages/RechargeHomePage/RechargeHomePage';
import RechargeWrapper from '../../Recharge/components/RechargeWrapper/RechargeWrapper';
import RechargeLoginWrapper from 'Recharge/components/RechargeLoginWrapper/RechargeLoginWrapper';
import UpdateProfile from 'Recharge/pages/UpdateProfile/UpdateProfile';
import ReferralDetail from 'Recharge/pages/ReferralDetail/ReferralDetail';
import AppointmentsOverview from 'Recharge/pages/AppointmentsOverview/AppointmentsOverview';
import SelectAvatar from 'Recharge/pages/SelectAvatar/SelectAvatar';
import PsychometricList from '../../pages/Psychometric/PsychometricList/PsychometricList';
import Onboarding from '../../pages/OnboardingAssessment/Onboarding/Onboarding';
import PatientCheckIn from '../../pages/PatientCheckIn/PatientCheckIn';
import RechargeSurveyQuestionWrapper from 'Recharge/pages/Survey/RechargeSurveyQuestionWrapper/RechargeSurveyQuestionWrapper';
import RechargeSurveyListWrapper from 'Recharge/pages/Survey/RechargeSurveyListWrapper/RechargeSurveyListWrapper';
import ConsentListWrapper from 'Recharge/pages/ConsentListWrapper/ConsentListWrapper';
import ConsentFormSignUp from 'Recharge/pages/ConsentFormSignUp/ConsentFormSignUp';
import PatientAdHocAssessment from '../../pages/PatientAdHocAssessment/PatientAdHocAssessment';
// import InvoiceViewPage from '../../pages/InvoiceViewPage/InvoiceViewPage';
// import ReportViewPage from '../../pages/ReportViewPage/ReportViewPage';
import RechargeActionPage from 'Recharge/pages/RechargeActionPage/RechargeActionPage';
// import LetterViewPage from 'pages/LetterViewPage/LetterViewPage';
import { useRechargeRoutesGenerator } from 'Recharge/utils/Path/RechargeRoutesGenerator';
import SignUpEmergencyContact from 'Recharge/pages/SignUpEmergencyContact/SignUpEmergencyContact';
import MyPsychologist from 'Recharge/pages/MyPsychologist/MyPsychologist';
import LoginPage from 'Recharge/pages/LoginPage/LoginPage';

const RechargeRoutes = () => {
  const {
    DOD,
    PRACTITIONER,
    SIGNUP,
    LOGIN,
    HOME,
    PENDING_ACTIONS,
    ONBOARDING,
    PSYCHOMETRIC,
    // INVOICE,
    // REPORT,
    // LETTER,
    AVATAR,
    UPDATE_PROFILE,
    REFERRAL,
    CHECKIN,
    SURVEY,
    CONSENT,
    APPOINTMENT_OVERVIEW,
    MY_PSYCHOLOGIST
  } = useRechargeRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {/* DOD Partnership */}
        {appRoute({ path: DOD.PRACTITIONER.LISTING, component: PsychologistListing, layout: RechargeWrapper })}
        {appRoute({
          path: DOD.PRACTITIONER.LISTING_WITH_FILTER,
          component: PsychologistListing,
          layout: RechargeWrapper
        })}
        {appRoute({
          path: DOD.PRACTITIONER.DETAILS,
          component: PsychologistDetails,
          layout: RechargeWrapper
        })}
        {appRoute({ path: DOD.SIGNUP.BASE, component: SignUp, layout: RechargeWrapper })}
        {appRoute({ path: DOD.SIGNUP.EMERGENCY_CONTACT, component: SignUpEmergencyContact, layout: RechargeWrapper })}
        {appRoute({ path: DOD.SIGNUP.WELCOME_BOOKING, component: SignUpCompleteBooking, layout: RechargeWrapper })}

        {/* Regular Engage */}
        {appRoute({ path: PRACTITIONER.LISTING, component: PsychologistListing, layout: RechargeWrapper })}
        {appRoute({
          path: PRACTITIONER.LISTING_WITH_FILTER,
          component: PsychologistListing,
          layout: RechargeWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PsychologistDetails,
          layout: RechargeWrapper
        })}
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: RechargeWrapper })}
        {appRoute({ path: SIGNUP.EMERGENCY_CONTACT, component: SignUpEmergencyContact, layout: RechargeWrapper })}
        {appRoute({ path: SIGNUP.WELCOME_BOOKING, component: SignUpCompleteBooking, layout: RechargeWrapper })}

        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: RechargeLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: RechargeLoginWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: RechargeSurveyListWrapper,
          layout: RechargeLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: RechargeSurveyQuestionWrapper,
          layout: RechargeLoginWrapper
        })}
        {/* {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: REPORT.VIEW,
          component: ReportViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: LETTER.VIEW,
          component: LetterViewPage,
          layout: SomeoneHealthWrapper
        })} */}

        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: LOGIN, component: LoginPage, layout: RechargeLoginWrapper })}
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: RechargeLoginWrapper })}
          {appRoute({ path: HOME, component: RechargeHomePage, layout: RechargeLoginWrapper })}
          {appRoute({ path: PENDING_ACTIONS, component: RechargeActionPage, layout: RechargeLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: RechargeLoginWrapper })}
          {appRoute({
            path: APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: RechargeLoginWrapper
          })}
          {appRoute({ path: REFERRAL, component: ReferralDetail, layout: RechargeLoginWrapper })}
          {appRoute({ path: MY_PSYCHOLOGIST, component: MyPsychologist, layout: RechargeLoginWrapper })}

          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: RechargeLoginWrapper })}
          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: RechargeLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: RechargeLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: RechargeLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default RechargeRoutes;
