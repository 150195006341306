import { useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import {
  CheckInQuestion,
  ClinicalAssessment,
  MicroJournalQuestion,
  OnboardingQuestion,
  Option,
  ScaleType,
  Section,
  SubQuestion
} from 'interfaces/Assessment/OnboardingAssessment';

import Question from '../Question/Question';
import SubmitButton from '../SubmitButton/SubmitButton';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import { PatientQuestionFormHeaderProps } from '../../../../pages/OnboardingAssessment/components/OnboardingQuestionForm/OnboardingQuestionForm';
import { putCheckEmailAlreadyUsed } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import { useGetAccessToken } from 'utils/hooks/token';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import styles from './IndividualQuestions.module.scss';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import ButtonCaW from 'CaW/components/ButtonCaW/ButtonCaW';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import ButtonPortobello from 'Portobello/components/ButtonPortobello/ButtonPortobello';

const CLIENT_EMAIL_QUESTION_VARIABLE_ID = 'PI005';

interface IndividualQuestionProps {
  sections: Section[];
  completedQuestions: number;
  totalQuestions?: number;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  title: string;
  reverseStemAndHideTitle: boolean;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (
    values: any,
    setStageToNextQuestion: boolean
  ) => Promise<CheckInQuestion | ClinicalAssessment | MicroJournalQuestion | OnboardingQuestion | undefined>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onSaveAndExit: () => void;
}

const useQuestionStage = () => {
  const [stage, setStage] = useState(-1);

  const nextStage = () => {
    setStage(stage + 1);
  };

  const prevStage = () => {
    if (stage > 0) {
      setStage(stage - 1);
    }
  };

  const resetStage = () => {
    setStage(0);
  };

  return { stage, nextStage, prevStage, resetStage, setStage };
};

export const getScaleQuestionInitialValue = (options: Option[] = [], scaleType?: ScaleType) => {
  switch (scaleType) {
    case ScaleType._1_5:
      return 3;
    case ScaleType._1_10:
      return 5;
    case ScaleType.AgreeDisagree:
    case ScaleType.GoodBadRating:
    case ScaleType.Frequency:
    case ScaleType.ImpactOfChange:
    case ScaleType.Likelihood:
    case ScaleType.Priority:
    case ScaleType.Recency:
    case ScaleType.Satisfaction:
    case ScaleType.EmojiRating:
      return '';
    case ScaleType.EmojiReaction:
      return options.reduce((res, key, index) => ({ ...res, [key.key]: 0 }), {});
    default:
      return 1;
  }
};

export const getScaleQuestionValidation = (scaleType?: ScaleType) => {
  switch (scaleType) {
    case ScaleType._1_5:
    case ScaleType._1_10:
      return Yup.number();
    case ScaleType.AgreeDisagree:
    case ScaleType.GoodBadRating:
    case ScaleType.Frequency:
    case ScaleType.ImpactOfChange:
    case ScaleType.Likelihood:
    case ScaleType.Priority:
    case ScaleType.Recency:
    case ScaleType.Satisfaction:
    case ScaleType.EmojiRating:
      return Yup.string();
    case ScaleType.EmojiReaction:
      return Yup.object().test(
        'Not Empty',
        'Please select an option',
        (v) => Object.keys(v).filter((key) => v[key] > 0).length > 0
      );
    default:
      return Yup.number().nullable();
  }
};

const IndividualQuestion = ({
  sections,
  completedQuestions,
  totalQuestions,
  defaultPatientDetails,
  header: Header,
  title,
  reverseStemAndHideTitle,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  setIsSubmitting,
  onSaveAndExit
}: IndividualQuestionProps) => {
  const [width] = useWindowSize();

  useEffect(() => {
    if (sections) {
      checkAndSetStage(sections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const [floatingBtn, setFloatingBtn] = useState(false);
  const { token } = useGetAccessToken(true);
  const questions = useMemo(() => {
    return sections.map((section) => section.questions).flat();
  }, [sections]);

  const { stage, nextStage, prevStage, resetStage, setStage } = useQuestionStage();

  // eslint-disable-next-line complexity
  const { initialValues, validationSchema } = useMemo(() => {
    if (questions[stage]) {
      const { id, maxSelection, questionType, options, response, scales, scaleType } = questions[stage];

      const initialValues = {} as Record<string, any>;
      const validationSchema = {} as Record<string, any>;

      switch (questionType) {
        case 'scale':
          initialValues[id] = response || { value: getScaleQuestionInitialValue(options as Option[], scaleType) };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? getScaleQuestionValidation(scaleType)?.required('Please answer the question')
              : getScaleQuestionValidation(scaleType)
          });
          break;
        case 'barSlider':
          initialValues[id] = response || { value: 20 };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.number().typeError('Please select an option').required('Please answer the question')
              : Yup.number().typeError('Please select an option')
          });
          setFloatingBtn(false);
          break;
        case 'freeText':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
          });
          setFloatingBtn(false);
          break;
        case 'hexSlider':
          initialValues[id] = response || { value: scales && scales.length > 0 ? Math.ceil(scales.length / 2) : 6 };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.number().typeError('Please select an option').required('Please answer the question')
              : Yup.number().typeError('Please select an option')
          });
          setFloatingBtn(false);
          break;
        case 'multipleAnswers':
          initialValues[id] = response || {
            value: (options as SubQuestion[]).reduce((initialValues, { id }) => {
              initialValues[id] = '';

              return initialValues;
            }, {} as Record<string, any>)
          };
          validationSchema[id] = Yup.object().shape({
            value: Yup.object().shape(
              (options as SubQuestion[]).reduce((validationSchema, { id }) => {
                validationSchema[id] = !isSkippable ? Yup.string().required('Please select an option') : Yup.string();

                return validationSchema;
              }, {} as Record<string, any>)
            )
          });
          setFloatingBtn(false);
          break;
        case 'multipleChoice':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string()
          });
          setFloatingBtn(false);
          break;
        case 'multipleChoiceFreeText':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string(),
            otherValue: Yup.string()
          });
          setFloatingBtn(false);
          break;
        case 'numeric':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.string()
                  .test({
                    name: 'number check',
                    message: 'Please enter a valid number',
                    test: (value: string | unknown) => !isNaN(Number(value))
                  })
                  .required('Please answer the question')
              : Yup.string().test({
                  name: 'number check',
                  message: 'Please enter a valid number',
                  test: (value: string | unknown) => !isNaN(Number(value))
                })
          });
          setFloatingBtn(false);
          break;
        case 'selectOther':
          initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
          validationSchema[id] = Yup.object().shape({
            value:
              maxSelection === 0
                ? !isSkippable
                  ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                  : Yup.array().of(Yup.string())
                : !isSkippable
                ? Yup.string().required('Please select an option')
                : Yup.string(),
            otherValue: Yup.string().when('value', {
              is: 'other',
              then: !isSkippable ? Yup.string().required('Please elaborate') : Yup.string(),
              otherwise: Yup.string()
            })
          });
          setFloatingBtn(false);
          break;
        case 'vote':
          initialValues[id] = response || {
            value: (options as Option[]).reduce((initialValues, { key }) => {
              initialValues[key] = 0;

              return initialValues;
            }, {} as Record<string, any>)
          };
          validationSchema[id] = Yup.object().shape({
            value: Yup.object().shape(
              (options as Option[]).reduce((validationSchema, { key }) => {
                validationSchema[key] = !isSkippable
                  ? Yup.number().required('Please vote on this question')
                  : Yup.number();

                return validationSchema;
              }, {} as Record<string, any>)
            )
          });
          setFloatingBtn(true);
          break;
        case 'phone':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.string()
                  .required('Please answer the question')
                  .test('validatePhoneNumber', 'Invalid Phone Number', async (value) => {
                    setIsSubmitting(true);
                    const phoneValidate = await validatePhoneNumber(value || '', true);
                    setIsSubmitting(false);
                    return phoneValidate.valid;
                  })
              : Yup.string()
                  .test('validatePhoneNumber', 'Invalid Phone Number', async (value) => {
                    if (!value) {
                      return true;
                    }

                    setIsSubmitting(true);
                    const phoneValidate = await validatePhoneNumber(value || '', true);
                    setIsSubmitting(false);
                    return phoneValidate.valid;
                  })
                  .nullable()
          });
          setFloatingBtn(false);
          break;
        case 'email':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable
              ? Yup.string().required('Please answer the question').email('Please enter a valid email address')
              : Yup.string().email('Please enter a valid email address').nullable()
          });
          setFloatingBtn(false);
          break;
        case 'date':
          initialValues[id] = response || { value: '' };
          validationSchema[id] = Yup.object().shape({
            value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
          });
          setFloatingBtn(false);
          break;

        default:
          setFloatingBtn(false);
          break;
      }

      return { initialValues, validationSchema: Yup.object().shape(validationSchema) };
    } else {
      return { initialValues: {}, validationSchema: Yup.object() };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, stage]);

  const checkAndSetStage = (sections: Section[]) => {
    const questions = sections.map((section) => section.questions).flat();

    let currentStage = 0;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].response) {
        currentStage++;
      } else {
        break;
      }
    }

    if (currentStage < questions.length) {
      setStage(currentStage);
    } else {
      setStage(currentStage - 1);
    }
  };

  const handleBack = () => {
    if (stage > 0) {
      prevStage();
    } else {
      onBack();
    }
  };

  const handleSkip = async (values: any) => {
    const setStageToNextQuestion = stage === questions.length - 1;

    const isTrue = await handleSubmit(values, setStageToNextQuestion);
    if (!isTrue) {
      if (setStageToNextQuestion) {
        resetStage();
        onSkip();
      } else {
        nextStage();
      }
    }
  };

  const handleSubmit = async (values: any, setStageToNextQuestion = stage === questions.length - 1) => {
    setIsSubmitting(true);
    const updatedQuestionSet = await onSubmit(values, setStageToNextQuestion);

    if (updatedQuestionSet) {
      checkAndSetStage(updatedQuestionSet.sections);
      setIsSubmitting(false);
      return true;
    } else if (!setStageToNextQuestion) {
      nextStage();
    }

    setIsSubmitting(false);
  };

  const validateAndSubmit = async (values: any, setErrors?: (errors: FormikErrors<Record<string, any>>) => void) => {
    setIsSubmitting(true);

    // Special case Validate Profile Information - Email question
    const currentQuestion = questions[stage];
    if (currentQuestion && currentQuestion.structuredQuestionId === CLIENT_EMAIL_QUESTION_VARIABLE_ID) {
      const value = values[currentQuestion.id].value;
      if (value) {
        const emailValidate = await putCheckEmailAlreadyUsed(token, { email: value });
        if (emailValidate.statusCode !== 204) {
          setIsSubmitting(false);
          const errorId = `${currentQuestion.id}`;
          setErrors && setErrors({ [errorId]: { value: 'Email already in use' } });
          setIsSubmitting(false);
          return;
        }
      }
    }

    // Format phone number
    if (currentQuestion && currentQuestion.questionType === 'phone') {
      const responseValue: string = values[currentQuestion.id].value;
      values[currentQuestion.id].value = responseValue ? responseValue.replaceAll(/\s|-/g, '') : responseValue;
    }

    handleSubmit(values);
  };

  const floatingSubmitBtn = width < 768 && floatingBtn;

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values, { setErrors }) => validateAndSubmit(values, setErrors)}
        enableReinitialize
      >
        {({ submitForm, validateForm }) => (
          <Form
            className="question-form-container"
            noValidate
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (document.activeElement?.tagName !== 'TEXTAREA') {
                  event.preventDefault();
                  event.stopPropagation();
                }
                validateForm();
              }
            }}
          >
            <Header
              completedQuestions={completedQuestions}
              totalQuestions={totalQuestions}
              patientDetails={defaultPatientDetails}
              isFirstQuestionSet={isFirstQuestionSet && stage === 0}
              isSkippable={isSkippable}
              onBack={handleBack}
              onSkip={handleSkip}
              onSaveAndExit={onSaveAndExit}
            />
            {questions[stage] && (
              <Question
                question={questions[stage]}
                title={title}
                reverseStemAndHideTitle={reverseStemAndHideTitle}
                defaultPatientDetails={defaultPatientDetails}
              />
            )}
            {IS_SOMEONE_HEALTH_APP ? (
              <ButtonSH className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonSH>
            ) : IS_CAW_APP ? (
              <ButtonCaW className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonCaW>
            ) : IS_EASE_APP ? (
              <ButtonEase className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonEase>
            ) : IS_RECHARGE_APP ? (
              <ButtonRecharge className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonRecharge>
            ) : IS_SELECT_APP ? (
              <ButtonSelect className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonSelect>
            ) : IS_PORTOBELLO_APP ? (
              <ButtonPortobello className={styles.floatingBtn} type="button" onClick={submitForm}>
                Next
              </ButtonPortobello>
            ) : (
              <SubmitButton floatingBtn={floatingSubmitBtn} type="button" onClick={submitForm}>
                Next
              </SubmitButton>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IndividualQuestion;
