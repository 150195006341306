import { BentResponse } from 'bent';
import queryString from 'query-string';

import { httpClient } from '../httpClient';
import { AppointmentBookingClaimType } from 'interfaces/PublicProfile/Practitioner/practitioner';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAvailabilityAppointmentTypes = ({
  accountId,
  clinicianId,
  isGP,
  claimType
}: {
  accountId?: string;
  clinicianId?: string;
  isGP?: boolean;
  claimType?: AppointmentBookingClaimType;
}) =>
  httpClient(
    'GET',
    url!,
    200
  )(
    `/availability/appointment-types/public?${queryString.stringify({
      accountId,
      clinicianId,
      isGP: isGP ? '1' : undefined,
      claimType
    })}`
  ) as Promise<BentResponse>;
