import styles from './HeadingWidget.module.scss';

interface HeadingWidgetProps {
  data: any;
}

const HeadingWidget = ({ data }: HeadingWidgetProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{data.title}</div>
    </div>
  );
};

export default HeadingWidget;
