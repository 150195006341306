import { ChangeEvent, ReactNode } from 'react';
import { useField } from 'formik';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Input, { InputProps } from './Input';

interface FormikInputProps extends Omit<InputProps, 'hasError'> {
  name: string;
  showError?: boolean;
  externalError?: ReactNode | string;
}

const FormikInput = ({
  className,
  name,
  externalError,
  onChange,
  onKeyUp,
  showError = true,
  ...props
}: FormikInputProps) => {
  const [{ onChange: onFormChange, ...field }, { error, touched }] = useField(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFormChange(e);
    onChange && onChange(e);
  };

  const handleKeyUp = (e: any) => {
    onKeyUp && onKeyUp(e);
  };

  return (
    <div className={className}>
      <Input
        {...props}
        {...field}
        hasError={!!(touched && (externalError || !!error) && showError)}
        onChange={(e) => handleChange(e)}
        onKeyUp={(e) => handleKeyUp(e)}
      />
      <ErrorMessage error={externalError || error} visible={(!!externalError || touched) && !!showError} />
    </div>
  );
};

export default FormikInput;
