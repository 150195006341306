import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import Button from '../Button/Button';
import styles from './AvailableTimeSelect.module.scss';
import DayCard, { SelectedAppointmentSlot } from './components/DayCard/DayCard';
import { MOMENTJS_FORMAT_DATE } from './constants';
import { getCurrentWeekAppointmentSlots } from './helpers/getCurrentWeekAppointmentSlots';
import { getNextAvailableDate } from './helpers/getNextAvailableDate';
import { useFetchAppointmentTypeAvailabilities } from './hooks/GetAvailabilityByAppointmentType';

const MAX_DAYS = 180;

interface AvailableTimeSelectProps {
  appointmentTypeId: string;
  showAppointmentTypeName?: boolean;
  onSelectSlot?: (slot?: SelectedAppointmentSlot) => void;
  selectedClinicianId: string;
  isEditing: boolean;
  containerClassName?: string;
  accountId: string;
  clientTimeZone?: string;
  clinicianTimeZone: string;
}

const AvailableTimeSelect = ({
  appointmentTypeId,
  selectedClinicianId,
  onSelectSlot,
  isEditing,
  containerClassName,
  accountId,
  clientTimeZone,
  clinicianTimeZone
}: AvailableTimeSelectProps) => {
  const [, , dateHelper] = useField('date');
  const [, , startTimeHelper] = useField('startTime');
  const [, , endTimeHelper] = useField('endTime');
  const [, , startDateTimeHelper] = useField('startDateTime');
  const [, , endDateTimeHelper] = useField('endDateTime');

  const [firstDay, setFirstDay] = useState<Moment>(moment());
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState<SelectedAppointmentSlot>();

  const isPrevButtonEnabled = firstDay.diff(moment(), 'd') > 0;
  const isNextButtonEnabled = firstDay.clone().diff(moment(), 'd') < MAX_DAYS;

  const { appointmentTypeAvailabilities, isLoading } = useFetchAppointmentTypeAvailabilities(
    moment().format(MOMENTJS_FORMAT_DATE),
    moment().add(MAX_DAYS, 'd').format(MOMENTJS_FORMAT_DATE),
    'booking',
    appointmentTypeId || '',
    selectedClinicianId ? selectedClinicianId : '',
    accountId
  );

  const goPrev = () => {
    if (isPrevButtonEnabled) {
      setFirstDay(firstDay.clone().subtract(1, 'w'));
    }
  };

  const goNext = () => {
    if (isNextButtonEnabled) {
      setFirstDay(firstDay.clone().add(1, 'w'));
    }
  };

  const handleSelectSlot = (slot: SelectedAppointmentSlot) => {
    setSelectedAppointmentSlot(slot);
    if (onSelectSlot) {
      dateHelper.setValue(slot.date);
      startTimeHelper.setValue(slot.startTime);
      endTimeHelper.setValue(slot.endTime);
      startDateTimeHelper.setValue(slot.startDateTime);
      endDateTimeHelper.setValue(slot.endDateTime);
      onSelectSlot(slot);
    }
  };

  const jumpToNextAvailableSlot = () => {
    const nextAvailableDate = getNextAvailableDate({
      currentDate: firstDay,
      appointmentTypeAvailabilities
    });

    if (nextAvailableDate) {
      setFirstDay(nextAvailableDate.startOf('isoWeek'));
    }
  };

  const { currentWeekAppointmentSlots, hasNextAvailableSlot, currentWeekHasNoAvailability } =
    getCurrentWeekAppointmentSlots({
      currentDate: firstDay,
      appointmentTypeAvailabilities
    });

  const shouldShowJumpToNextAvailableSlot = currentWeekHasNoAvailability && hasNextAvailableSlot && isNextButtonEnabled;
  const shouldShowLoading = isLoading || !appointmentTypeAvailabilities;

  return shouldShowLoading ? (
    <div className={styles.loading}>
      <LoadingCircle />
    </div>
  ) : (
    <div className={classNames(styles.container, containerClassName)}>
      <div className={classNames(styles.scrollContainer)}>
        <div className={classNames(styles.scrollableContainer)}>
          {isEditing && (
            <div className={styles.navContainerEdit}>
              <div className={classNames(styles.leftControl, !isPrevButtonEnabled && styles.disabled)} onClick={goPrev}>
                <span className="material-icons-outlined">keyboard_arrow_left</span>
              </div>
              <div
                className={classNames(styles.rightControl, !isNextButtonEnabled && styles.disabled)}
                onClick={goNext}
              >
                <span className="material-icons-outlined">keyboard_arrow_right</span>
              </div>
            </div>
          )}
          <div className={styles.weeksContainer}>
            <div className={styles.daysContainer}>
              {currentWeekAppointmentSlots.map((appointmentSlots, index) => (
                <DayCard
                  isAvailabilitiesViewTypeSimple
                  key={index}
                  data={appointmentSlots}
                  selectedAppointmentSlot={selectedAppointmentSlot}
                  onSelectSlot={handleSelectSlot}
                  clientTimeZone={clientTimeZone}
                  clinicianTimeZone={clinicianTimeZone}
                />
              ))}
            </div>
            {!isEditing && (
              <div className={styles.navContainer}>
                <Button variant="link" onClick={goPrev} disabled={!isPrevButtonEnabled}>
                  <div className={styles.navCenter}>
                    <i className="material-icons">arrow_back</i>
                    Previous week
                  </div>
                </Button>
                {shouldShowJumpToNextAvailableSlot && (
                  <Button className={styles.button} variant="link" onClick={jumpToNextAvailableSlot}>
                    <div className={styles.navCenter}>
                      JUMP TO NEXT AVAILABLE
                      <i className="material-icons">skip_next</i>
                    </div>
                  </Button>
                )}
                <Button variant="link" disabled={!isNextButtonEnabled} onClick={goNext}>
                  <div className={styles.navCenter}>
                    Next week
                    <i className="material-icons">arrow_forward</i>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableTimeSelect;
