import styles from './YourProgram.module.scss';
import ProgramCard from './components/ProgramCard/ProgramCard';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { useEffect, useRef, useState } from 'react';
import { ChapterInterface, ProgramInterface } from 'helm/interfaces/program/program';
import classnames from 'classnames';
import HomeWorkCard from './components/HomeWorkCard/HomeWorkCard';
import { scrollToView } from 'utils/scrollToView';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';

interface YourProgramProps {
  data: ProgramInterface;
  activeChapter?: ChapterInterface;
  isPreActivityExistOrCompleted?: boolean;
}

const YourProgram = ({ data, activeChapter, isPreActivityExistOrCompleted }: YourProgramProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { P2C_GAMBLING } = useHelmRoutesGenerator();
  const { session }: { session?: string } = queryString.parse(location.search);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        setScrollPosition(el.scrollLeft + e.deltaY);
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      setMaxScrollWidth(el.scrollWidth);
    }
  }, [scrollRef, screenWidth]);

  useEffect(() => {
    const el = scrollRef.current;
    if (session && el) {
      scrollToView(session, true, false, true);
      setTimeout(() => {
        setScrollPosition(el.scrollLeft);
      }, 1000);
      navigate(P2C_GAMBLING);
    }
  }, [session, P2C_GAMBLING, navigate]);

  const scroll = (step: number) => {
    let scrollAmount = 0;
    const el = scrollRef.current;
    const slideTimer = setInterval(() => {
      if (el) {
        setScrollPosition(el.scrollLeft + step);
        el.scrollTo({
          left: el.scrollLeft + step,
          behavior: 'smooth'
        });
        scrollAmount += Math.abs(step);
        if (scrollAmount >= 100) {
          clearInterval(slideTimer);
        }
      }
    }, 100);
  };

  const numberOfDots = Math.ceil(maxScrollWidth / (scrollRef.current?.offsetWidth || maxScrollWidth)) || 1;
  const singleDotWidth = maxScrollWidth / numberOfDots;

  const handleDotNavigate = (dotId: number) => {
    const el = scrollRef.current;

    if (el) {
      const calculatedDotWidth = dotId === numberOfDots - 1 ? maxScrollWidth : Math.ceil(singleDotWidth * dotId);

      setScrollPosition(calculatedDotWidth);
      el.scrollTo({
        left: calculatedDotWidth,
        behavior: 'smooth'
      });
    }
  };

  const getScrollActive = (dotId: number) => {
    const minDot = Math.ceil(singleDotWidth * (dotId - 1));
    const maxDot = Math.ceil(singleDotWidth * dotId);
    return scrollPosition >= minDot && scrollPosition < maxDot;
  };

  return (
    <HelmContentLayout id={'yourProgram'} className={styles.container}>
      <HelmContentLayout className={styles.title}>Your Program</HelmContentLayout>
      <div className={styles.programContainer}>
        <div ref={scrollRef} className={styles.programWrapper}>
          {data.chapters.map((obj, i) => (
            <div id={obj.chapterId.toString()} key={i} className={styles.programCard}>
              {obj.sessionActivities.pre.length > 0 && (
                <HomeWorkCard title={'PRE-SESSION ACTIVITIES'} activitiesData={obj.sessionActivities.pre} />
              )}
              <ProgramCard
                activeCard={
                  !data.isUnlockAll &&
                  !isPreActivityExistOrCompleted &&
                  !activeChapter?.completed &&
                  activeChapter?.chapterId === obj.chapterId
                }
                id={(i + 1).toString()}
                chapterData={obj}
              />
              {obj.sessionActivities.post.length > 0 && (
                <HomeWorkCard title={'POST-SESSION ACTIVITIES'} activitiesData={obj.sessionActivities.post} />
              )}
            </div>
          ))}
        </div>
        <div
          className={classnames(styles.leftScrollButton, scrollPosition <= 0 && styles.hideButton)}
          onClick={() => scroll(-(scrollRef.current?.offsetWidth || 0))}
        >
          <div className={`material-icons ${styles.arrowButton}`}>keyboard_arrow_left</div>
        </div>
        <div
          className={classnames(
            styles.rightScrollButton,
            scrollPosition >= maxScrollWidth - (scrollRef.current?.offsetWidth || 0) - 50 && styles.hideButton
          )}
          onClick={() => scroll(scrollRef.current?.offsetWidth || 0)}
        >
          <div className={`material-icons ${styles.arrowButton}`}>keyboard_arrow_right</div>
        </div>
      </div>
      <div className={styles.dotWrapper}>
        {[...Array(numberOfDots)].map((_, index) => (
          <div
            onClick={() => handleDotNavigate(index)}
            className={
              getScrollActive(index + 1) ||
              (index === 0 && scrollPosition <= 0) ||
              (index === numberOfDots - 1 && scrollPosition >= maxScrollWidth)
                ? styles.dotActive
                : styles.dot
            }
          />
        ))}
      </div>
    </HelmContentLayout>
  );
};

export default YourProgram;
