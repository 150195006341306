import { useCallback, useEffect, useState } from 'react';
import { getPublicAvailabilityByAppointmentTypeId } from 'utils/http/SchedService/AppointmentTypes/availabilities';
import { getAvailabilityAppointmentTypes } from 'utils/http/sessionType';
import { NodeResponse } from 'bent';
import { massageTimeSlotObj } from 'helm/pages/PractitionerListing/hooks/getPractitionerList';
import queryString from 'query-string';
import { getRescheduleRules } from 'utils/http/SchedService/AccountLevelRules/getRescheduleRules';
import { notification } from 'antd';
import { Appointment as AppointmentInteface } from 'pages/PatientAppointment/AppointmentConfirmed/AppointmentConfirmed';
import { getAppointments } from 'utils/http/SchedService/ClientRecords/appointments';
import {
  AppointmentBookingClaimType,
  timeSlotsInterface
} from '../../interfaces/PublicProfile/Practitioner/practitioner';

export enum DeliveryType {
  FaceToFace = 'faceToFace',
  VideoCall = 'videoCall',

  // telehealth and regular phone call use this
  PhoneCall = 'phoneCall',
  // only used for telehealth accounts to not generate telehealth voice link
  PhoneCallDialClient = 'phoneCallDialClient',

  // requires otherInstructions as well
  Other = 'other'
}

export interface OtherInstructions {
  title: string;
  instructions?: string;
}

export interface ActiveDays {
  Sunday: boolean;
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  [key: string]: any;
}

export interface BookingRulesChildProps {
  available: boolean;
}

export interface AvailabilityAppointmentTypes {
  _id: string;
  clinicianId?: string;
  name?: string;
  description?: string;
  duration?: number;
  rate?: number;
  assignedClinicians?: string[];
  deliveryOptions: DeliveryType[];
  otherInstructions?: OtherInstructions;
  activeDays: ActiveDays;
  paymentRequired?: boolean;
  slotCount?: number;
  bookingRules?: {
    new: BookingRulesChildProps;
    existing: BookingRulesChildProps;
  };
  isAdvisory?: boolean;
  shouldHideFromListing?: boolean;
  programId?: string;
  claimType?: AppointmentBookingClaimType;
}

export const useFetchAvailabilityAppointmentTypes = ({
  accountId,
  clinicianId,
  claimType
}: {
  accountId?: string;
  clinicianId?: string;
  claimType?: AppointmentBookingClaimType;
}) => {
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<
    AvailabilityAppointmentTypes[] | undefined
  >([]);
  const [isAvailabilityAppointmentTypesLoading, setIsAvailabilityAppointmentTypesLoading] = useState(true);

  const fetchAvailabilityAppointmentTypes = async () => {
    try {
      if (accountId) {
        const callGetAvailabilityAppointmentTypes = await getAvailabilityAppointmentTypes({
          accountId,
          clinicianId,
          claimType
        });

        const data = await callGetAvailabilityAppointmentTypes.json();

        setAvailabilityAppointmentTypes(data);
        setIsAvailabilityAppointmentTypesLoading(false);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    fetchAvailabilityAppointmentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, clinicianId]);

  return { availabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading, fetchAvailabilityAppointmentTypes };
};

export enum AttendeeParticipationStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Unknown = 'unknown'
}

export interface Attendee {
  clinicianId?: string;
  clientRecordId?: string;
  clientProfileId?: string;
  email?: string;
  participationStatus: AttendeeParticipationStatus;
  name: string;
  initials?: string;
  initialsBackgroundColor?: string;
  avatar?: string;
}

export interface updateReservedAppointment {
  clinicianId?: string;
  appointmentTypeId: string;
  deliveryType: DeliveryType;
  slots: {
    date: string;
    startTime: string;
    endTime: string;
  }[];
}

export interface AppointmentSlot {
  _id: string;
  accountId: string;
  clinicianId?: string;
  sessionTypeId?: string;
  sessionTypeName?: string;
  name: string;
  date: string;
  endDate: string;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  availability?: 'open' | 'blocked' | 'busy' | 'booked' | 'request';
  deliveryType?: DeliveryType;
  clientRecord?: {
    _id: string;
    clientProfiles: {
      _id: string;
      clientAuth0Id?: string;
      avatar?: string;
      initials?: string;
      initialsBackgroundColor?: string;
      name: string;
    }[];
  };
  confirmation?: {
    clinician: boolean;
    patient: boolean;
  };
  deliveryOptions?: DeliveryType[];
  faceToFaceLocation?: string;
  videoCallInstructions?: string;
  phoneCallInstructions?: string;
  phoneCallDialClientInstructions?: string;
  otherInstructions?: OtherInstructions;
  rate?: number;
  gap?: number;
  bookingRules?: {
    new: {
      available: boolean;
      rule: 'instant' | 'request';
    };
    existing: {
      available: boolean;
      rule: 'instant' | 'request';
    };
  };
  bookingRule?: 'instant' | 'request';
  recurringAppointmentId?: string;
  room?: {
    roomId: string;
    roomName: string;
  };
  markedStatus?: string[];
  title: string;
  recurrings: any[];
  notes?: string;
  type: string;
  integration?: {
    profileName?: string;
  };
  attendees?: Attendee[];
  reschedulingStatus?: string;
  createdAt?: string;
  updatedAt?: string;
  isGP?: boolean;
}

export interface Availability {
  date: string;
  appointmentSlots: AppointmentSlot[];
  blocked: boolean;
  occupancy: number;
}

export interface AppointmentAvailability {
  appointmentType: Pick<
    AppointmentSlot,
    | 'bookingRules'
    | 'deliveryOptions'
    | 'faceToFaceLocation'
    | 'gap'
    | 'name'
    | 'rate'
    | 'sessionTypeId'
    | 'videoCallInstructions'
    | 'phoneCallInstructions'
  >;
  timeSlots: Record<string, { isAvailable?: boolean; timeSlots?: timeSlotsInterface[] }>;
}

export const useAvailability = ({
  appointmentTypeId,
  accountId,
  from,
  to,
  clinicianId
}: {
  appointmentTypeId: string;
  accountId: string;
  from: string;
  to: string;
  clinicianId?: string;
}) => {
  const [appointmentAvailability, setAppointmentAvailability] = useState<AppointmentAvailability>();
  const [isAvailabilityListLoading, setIsAvailabilityLoading] = useState(true);

  useEffect(() => {
    const fetchAvailability = async () => {
      setIsAvailabilityLoading(true);
      const availabilityNewQueryParam = {
        accountId: accountId,
        clinicianId: clinicianId || '',
        date: from,
        to: to,
        type: 'booking'
      };
      const generateQueryParam = queryString.stringify(availabilityNewQueryParam, { sort: false });
      const callGetPublicAvailabilityByAppointmentTypeId = (await getPublicAvailabilityByAppointmentTypeId(
        appointmentTypeId,
        generateQueryParam
      )) as NodeResponse;
      const data = (await callGetPublicAvailabilityByAppointmentTypeId.json()) as AppointmentAvailability;

      setAppointmentAvailability(data);
      setIsAvailabilityLoading(false);
    };

    fetchAvailability();
  }, [from, to, appointmentTypeId, accountId, clinicianId]);

  return {
    appointmentAvailability,
    isAvailabilityListLoading
  };
};

export interface TimeSlotsWithDateInterface {
  date: string;
  endDate: string;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  clinicianId?: string;
  isAvailable?: boolean;
  isConflict?: boolean;
}

export interface AppointmentAvailabilityWithTimeZone {
  appointmentType: Pick<
    AppointmentSlot,
    | 'bookingRules'
    | 'deliveryOptions'
    | 'faceToFaceLocation'
    | 'gap'
    | 'name'
    | 'rate'
    | 'sessionTypeId'
    | 'videoCallInstructions'
    | 'phoneCallInstructions'
  >;
  timeSlots: TimeSlotsWithDateInterface[];
}

interface timeSlotWithoutDate extends Omit<TimeSlotsWithDateInterface, 'date'> {}

export const useAvailabilityWithTimeZone = ({
  appointmentTypeId,
  accountId,
  from,
  to,
  clinicianId,
  timeZone,
  clinicianTimeZone,
  allowPracticeClinicianId
}: {
  appointmentTypeId: string;
  accountId: string;
  from: string;
  to: string;
  clinicianId?: string;
  timeZone: string;
  clinicianTimeZone?: string;
  allowPracticeClinicianId?: boolean; // clinicianId:''
}) => {
  const [appointmentAvailability, setAppointmentAvailability] = useState<AppointmentAvailabilityWithTimeZone>();
  const [isAvailabilityListLoading, setIsAvailabilityLoading] = useState(true);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        setIsAvailabilityLoading(true);

        const availabilityNewQueryParam = {
          accountId: accountId,
          clinicianId: clinicianId,
          date: from,
          to: to,
          type: 'booking'
        };
        const generateQueryParam = queryString.stringify(availabilityNewQueryParam, { sort: false });
        const callGetPublicAvailabilityByAppointmentTypeId = (await getPublicAvailabilityByAppointmentTypeId(
          appointmentTypeId,
          generateQueryParam
        )) as NodeResponse;
        const data = (await callGetPublicAvailabilityByAppointmentTypeId.json()) as AppointmentAvailability;

        const massageNewTimeSlot = (date: string, timeSlotList?: timeSlotWithoutDate[]) => {
          return timeSlotList
            ?.filter((obj) => obj.isAvailable)
            .map((obj) => ({
              date: date,
              isAvailable: obj.isAvailable,
              startTime: obj.startTime,
              endTime: obj.endTime,
              startDateTime: obj.startDateTime,
              endDateTime: obj.endDateTime,
              clinicianId: obj.clinicianId
            }));
        };

        const massageTimeSlot = (timeSlotList: any) => {
          const newTimeSlot = Object.entries(timeSlotList);
          const newMassageSlot = newTimeSlot.map(
            (dateObj: any) =>
              dateObj[1].isAvailable && massageNewTimeSlot(dateObj[0], dateObj[1].timeSlots as timeSlotWithoutDate[])
          );
          return newMassageSlot.filter((el) => Object.keys(el).length !== 0).flat();
        };

        const massageData: AppointmentAvailabilityWithTimeZone = {
          appointmentType: data.appointmentType,
          timeSlots: massageTimeSlot(data.timeSlots).map((item) => massageTimeSlotObj(item, timeZone))
        };

        setAppointmentAvailability(massageData);
        setIsAvailabilityLoading(false);
      } catch (ex) {
        console.error(ex);
        return;
      }
    };
    if (appointmentTypeId && (clinicianId || (allowPracticeClinicianId && clinicianId !== undefined)) && accountId) {
      fetchAvailability();
    }
  }, [from, to, appointmentTypeId, accountId, clinicianId, timeZone, clinicianTimeZone, allowPracticeClinicianId]);

  return {
    appointmentAvailability,
    isAvailabilityListLoading
  };
};

export interface RescheduleRules {
  active: boolean;
  limit: number;
  selfManage: {
    active: boolean;
    value: { from: number; to: number };
  };
  requestChanges: {
    active: boolean;
    value: { from: number; to: number };
  };
}

export const useFetchRescheduleRules = (token: string) => {
  const [rescheduleRules, setRescheduleRules] = useState<RescheduleRules | undefined>();
  const [isRescheduleRulesLoading, setIsRescheduleRulesLoading] = useState(true);

  const fetchRescheduleRules = useCallback(async (token: string) => {
    setIsRescheduleRulesLoading(true);
    try {
      const getRescheduleRulesResponses = await getRescheduleRules(token);
      if (getRescheduleRulesResponses.statusCode === 200) {
        const result = (await getRescheduleRulesResponses.json()) as RescheduleRules;
        setRescheduleRules(result);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get reschedule rules' });
    }
    setIsRescheduleRulesLoading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchRescheduleRules(token);
    }
  }, [token, fetchRescheduleRules]);

  return { rescheduleRules, isRescheduleRulesLoading };
};

export const useFetchAppointments = (token: string) => {
  const [appointments, setAppointments] = useState<AppointmentInteface[]>([]);
  const [isAppointmentsLoading, setIsAppointmentloading] = useState(true);

  const fetchAppointments = useCallback(async (token: string) => {
    setIsAppointmentloading(true);
    try {
      if (token) {
        const response = await getAppointments(token);
        const results = (await response.json()) as AppointmentInteface[];
        const sortedAppointments = results.sort((a, b) => (a.date > b.date ? 1 : -1));
        setAppointments(sortedAppointments);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get appointments' });
    }
    setIsAppointmentloading(false);
  }, []);

  useEffect(() => {
    if (token) {
      fetchAppointments(token);
    }
  }, [token, fetchAppointments]);

  const refetchAppointments = () => {
    fetchAppointments(token);
  };

  return { appointments, isAppointmentsLoading, refetchAppointments };
};
