import styles from './ProgressMenu.module.scss';
import classnames from 'classnames';

interface ProgressMenuProps {
  mobileCard?: boolean;
  isSelected: boolean;
  menuImage: {
    icon: string;
    color: string;
  };
  title: string;
  desc: string;
  onselect: () => void;
}

const ProgressMenu = ({ mobileCard, isSelected, menuImage, title, desc, onselect }: ProgressMenuProps) => {
  return (
    <div className={isSelected ? styles.containerSelected : styles.container} onClick={() => onselect()}>
      <div
        style={{ backgroundColor: menuImage.color }}
        className={classnames(isSelected ? styles.menuImageSelected : styles.menuImage, 'material-icons-outlined')}
      >
        {menuImage.icon}
      </div>
      <div className={styles.detailWrapper}>
        <div className={isSelected ? styles.titleSelected : styles.title}>{title}</div>
        <div className={isSelected ? styles.descSelected : styles.desc}>{desc}</div>
      </div>
      {mobileCard ? (
        <div className={isSelected ? styles.mobileArrowWrapper : styles.hideMobileArrowIcon}>
          <i className={`material-icons-outlined ${styles.mobileArrow}`}>keyboard_arrow_down</i>
        </div>
      ) : (
        <div className={isSelected ? styles.arrowIcon : styles.hideArrowIcon}>
          <i className="material-icons-outlined">arrow_forward_ios</i>
        </div>
      )}
    </div>
  );
};

export default ProgressMenu;
