import { Routes } from 'react-router-dom';
import PsychometricAssessment from 'CORDS/pages/PsychometricAssessment/PsychometricAssessment';
import CORDSWrapper from 'CORDS/components/CORDSWrapper/CORDSWrapper';
import { useCORDSRoutesGenerator } from 'CORDS/utils/Path/CORDSRoutesGenerator';
import appRoute from 'components/AppRoute/AppRoute';
import CordsSurveyList from 'CORDS/pages/Survey/CordsSurveyList/CordsSurveyList';
import CordsSurveyQuestion from 'CORDS/pages/Survey/CordsSurveyQuestion/CordsSurveyQuestion';
import CordsConsentList from 'CORDS/pages/Consent/CordsConsentList/CordsConsentList';
import CordsConsentDetails from 'CORDS/pages/Consent/CordsConsentDetails/CordsConsentDetails';
import CordsHome from 'CORDS/pages/CordsHome/CordsHome';

const CORDSRoutes = () => {
  const { PSYCHOMETRIC, SURVEY, CONSENT } = useCORDSRoutesGenerator();

  return (
    <Routes>
      {appRoute({ path: PSYCHOMETRIC.DETAILS, component: PsychometricAssessment, layout: CORDSWrapper })}
      {appRoute({ path: SURVEY.BASE, component: CordsSurveyList, layout: CORDSWrapper })}
      {appRoute({ path: SURVEY.DETAILS, component: CordsSurveyQuestion, layout: CORDSWrapper })}
      {appRoute({ path: CONSENT.BASE, component: CordsConsentList, layout: CORDSWrapper })}
      {appRoute({ path: CONSENT.DETAILS, component: CordsConsentDetails, layout: CORDSWrapper })}
      {appRoute({ path: '/', component: CordsHome, layout: CORDSWrapper })}
    </Routes>
  );
};

export default CORDSRoutes;
