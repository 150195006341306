import { ChapterInterface, ProgramAppointment, ProgramInterface } from 'helm/interfaces/program/program';
import moment from 'moment';
import { linkify } from 'utils/general';
import { DeliveryType } from 'utils/hooks/appointment';

export const checkIsTodayOrYesterday = (time: string) => {
  const timeMoment = moment(time);
  const nowMoment = moment();
  const todayStartMoment = nowMoment.clone().startOf('day');
  const yesterdayStartMoment = nowMoment.clone().subtract(1, 'days').startOf('day');

  if (moment(timeMoment).isSame(todayStartMoment, 'd')) {
    return 'today';
  } else if (moment(timeMoment).isSame(yesterdayStartMoment, 'd')) {
    return 'yesterday';
  } else {
    return timeMoment.format('DD MMMM YYYY');
  }
};

export const generateAppointmentLabelAndInstructions = (appointmentData: ProgramAppointment) => {
  switch (appointmentData.deliveryType) {
    case DeliveryType.VideoCall:
      return {
        label: 'Video call - ',
        instructions: linkify(appointmentData.videoCallInstructions)
      };
    case DeliveryType.PhoneCall:
      return {
        label: 'Phone call - ',
        instructions: linkify(appointmentData.phoneCallInstructions)
      };
    case DeliveryType.PhoneCallDialClient:
      return {
        label: 'Phone call - ',
        instructions: appointmentData.phoneCallDialClientInstructions
      };
    case DeliveryType.FaceToFace:
      return {
        label: appointmentData.faceToFaceLocation ? 'Face to face at ' : 'Face to face ',
        instructions: linkify(appointmentData.faceToFaceLocation)
      };
    case DeliveryType.Other:
      if (appointmentData.otherInstructions) {
        return {
          label: `${appointmentData.otherInstructions.title}${
            appointmentData.otherInstructions.instructions ? ' at' : ''
          }`,
          instructions: linkify(appointmentData.otherInstructions.instructions)
        };
      }
      return {
        label: '',
        instructions: ''
      };
    default:
      return {
        label: '',
        instructions: ''
      };
  }
};

export const getActiveChapterData = (data: ProgramInterface) => {
  // logic is that if chapter has link, it's unlocked either by
  //   - group stage assignment
  //   - appointment within next 1 hour
  // if no link, it's locked

  // getting last incomplete chapter with link
  const lastChapterWithLink = data.chapters.filter((chapterObj) => chapterObj.link).at(-1);
  const isLastChapterWithLinkIncomplete =
    lastChapterWithLink &&
    (!lastChapterWithLink.completed ||
      lastChapterWithLink.sessionActivities.post.some((sessionObj) => !sessionObj.completed));

  // getting first incomplete chapter without link
  const nextChapter = data.chapters
    .filter(
      (chapterObj) =>
        !chapterObj.link &&
        (!chapterObj.completed || chapterObj.sessionActivities.post.some((sessionObj) => !sessionObj.completed))
    )
    .at(0);

  const activeChapter: ChapterInterface | undefined = isLastChapterWithLinkIncomplete
    ? lastChapterWithLink
    : nextChapter;

  const preSessionActivity = (activeChapter && !activeChapter.link && activeChapter.sessionActivities.pre) || [];
  const postSessionActivity = (activeChapter && activeChapter.sessionActivities.post) || [];

  const isPreActivityExistOrCompleted = preSessionActivity.some((sessionObj) => !sessionObj.completed);
  const isPostActivityExistOrCompleted = postSessionActivity.some((sessionObj) => !sessionObj.completed);

  return {
    activeChapter,
    preSessionActivity,
    postSessionActivity,
    isPreActivityExistOrCompleted,
    isPostActivityExistOrCompleted,
    isBeforeFirstSession:
      activeChapter?.chapterId === 1 &&
      activeChapter.sessionActivities.pre.length > 0 &&
      activeChapter.sessionActivities.pre.some((activity) => !activity.completed)
  };
};
