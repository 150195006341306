import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import ContentLayout from 'components/ContentLayout/ContentLayout';

import styles from './SignUpProgressBar.module.scss';
import { ClientSignUpStageInterface } from '../../SignUpFormInterface';

interface SignUpProgressBarProps {
  stages: ClientSignUpStageInterface[];
  activeStage: number;
  onHandleClickOnStep: (val: number) => void;
}

const SignUpProgressBar = ({ activeStage, onHandleClickOnStep, stages }: SignUpProgressBarProps) => {
  const handleClickOnStage = (val: number) => {
    if (activeStage > val) {
      onHandleClickOnStep(val);
    }
  };

  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.progressBar}>
          {stages.map((stageObj, index) => {
            const count = stageObj.id;

            const isActive = count === activeStage;
            const isCompleted = count < activeStage;

            const iconClasses = `${styles.icon} ${isActive ? styles.isActive : ''}`;
            const labelClasses = `${styles.label} ${isActive ? styles.isActive : ''}`;

            return (
              <div className={styles.pillItem} key={index} onClick={() => handleClickOnStage(count)}>
                <div className={isCompleted ? styles.iconComplete : iconClasses}>
                  {isCompleted ? <FontAwesomeIcon icon={faCheck} className={styles.checkmark} /> : count}
                </div>
                <div className={labelClasses}>{stageObj.label}</div>
              </div>
            );
          })}
        </div>
      </ContentLayout>
    </div>
  );
};

export default SignUpProgressBar;
