import Button from 'components/v2/Button/Button';
import helmWhiteLogo from 'helm/assets/images/logo/WithoutText/White.svg';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import HelmSignUpFooter from 'helm/components/HelmFooter/HelmSignUpFooter/HelmSignUpFooter';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './JoinWaitlistResult.module.scss';

const JoinWaitlistResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientName = (location.state as { clientName?: string })?.clientName || '';

  const handleGoToPractitionerListing = () => {
    navigate('https://takethehelm.com.au/counsellors');
  };

  return (
    <HelmHelmetWrapper title={'HELM - Welcome to Helm'}>
      <div className={styles.container}>
        <HelmHeader />
        <HelmContentLayout>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.logo}>
                <img src={helmWhiteLogo} alt="helm white logo" />
              </div>
              You have joined our waitlist
            </div>
            <div className={styles.card}>
              <div className={styles.cardTitle}>Thank you {clientName},</div>
              <div className={styles.cardText}>We have added your information to our waitlist.</div>
              <div className={styles.cardText}>The Helm team will review and be in touch to discuss your needs.</div>
              <div className={styles.cardButton}>
                <Button onClick={handleGoToPractitionerListing} className={styles.proceedBtn}>
                  Back to Practitioner listing
                </Button>
              </div>
            </div>
          </div>
        </HelmContentLayout>
        <HelmSignUpFooter />
      </div>
    </HelmHelmetWrapper>
  );
};

export default JoinWaitlistResult;
