import styles from './NoAvailableMessageBox.module.scss';
import classnames from 'classnames';
import ButtonCaW from '../ButtonCaW/ButtonCaW';
import CaWContentLayout from '../CaWContentLayout/CaWContentLayout';

interface NoAvailableMessageBoxProps {
  title: string;
  desc: string | JSX.Element;
  titleClassName?: string;
  buttonLabel?: string;
  onClick?: () => void;
  showButton?: boolean;
}

const NoAvailableMessageBox = ({
  title,
  desc,
  buttonLabel,
  onClick,
  titleClassName,
  showButton = true
}: NoAvailableMessageBoxProps) => (
  <CaWContentLayout>
    <div className={styles.container}>
      <div className={classnames(styles.title, titleClassName)}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      {showButton && (
        <div className={styles.buttonWrapper}>
          <ButtonCaW onClick={onClick}>{buttonLabel}</ButtonCaW>
        </div>
      )}
    </div>
  </CaWContentLayout>
);

export default NoAvailableMessageBox;
