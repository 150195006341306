import { BentResponse } from 'bent';

import { httpClient } from '../httpClient';
import { DeliveryType, updateReservedAppointment } from '../hooks/appointment';
import { CheckoutSessionMode } from './SchedService/ReservedAppointments/claimReservation';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAppointmentById = (id: string, token: string) =>
  httpClient('GET', url!, 200, {
    Authorization: `Bearer ${token}`
  })(`/appointments/${id}`);

export const getReservedAppointmentByReserveId = (accountId: string, reserveId: string) =>
  httpClient('GET', url!, 200)(`/accounts/${accountId}/reserved-appointments/${reserveId}`) as Promise<BentResponse>;

export const postReservedAppointment = (
  accountId: string,
  payload: {
    isNewClient: boolean;
    clinicianId?: string;
    appointmentTypeId: string;
    deliveryType: DeliveryType;
    slots: {
      date: string;
      endDate: string;
      startTime: string;
      endTime: string;
      startDateTime: Date;
      endDateTime: Date;
    }[];
  }
) =>
  httpClient(
    'POST',
    url!,
    [200, 409]
  )(`/accounts/${accountId}/reserved-appointments`, payload) as Promise<BentResponse>;

export const putReservedAppointment = (
  token: string,
  accountId: string,
  reserveId: string,
  body: updateReservedAppointment
) =>
  httpClient('PUT', url!, [200, 400], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/reserved-appointments/${reserveId}`,
    body
  ) as Promise<BentResponse>;

export const patchReservedAppointment = (
  token: string,
  accountId: string,
  reserveId: string,
  body: updateReservedAppointment
) =>
  httpClient('PATCH', url!, [204, 400], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/reserved-appointments/${reserveId}`,
    body
  ) as Promise<BentResponse>;

export const submitRescheduleApproval = (payload: { appointmentId: string; approve: boolean }, token: string) => {
  return httpClient('POST', url!, 204, { Authorization: `Bearer ${token}` })(
    `/appointments/reschedule/approve`,
    payload
  );
};

export const postAppointmentByClinician = (clinicianId: string, payload: any, token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/clinician/${clinicianId}/appointment`,
    payload
  ) as Promise<BentResponse>;

export const postPaymentRequiredAppointment = (payload: any, token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })(
    `/payment-required-appointments`,
    payload
  ) as Promise<BentResponse>;

export const postProspectPaymentRequiredAppointment = (payload: any) =>
  httpClient('POST', url!, 201)(`/payment-required-appointments/prospect`, payload) as Promise<BentResponse>;

export const postProspectAppointments = (payload: any) =>
  httpClient('POST', url!, 201)(`/appointments/prospect`, payload) as Promise<BentResponse>;

export const postProspectRequests = (payload: any) =>
  httpClient('POST', url!, 201)(`/appointments/request/prospect`, payload) as Promise<BentResponse>;

export const postAppointmentRequest = (payload: { clinicianId: string; message: string }, token: string) =>
  httpClient('POST', url!, 201, { Authorization: `Bearer ${token}` })('/appointments/requests', payload);

export const deleteAppointment = (id: string, payload: { notes?: string }, token: string) =>
  httpClient('DELETE', url!, 204, {
    Authorization: `Bearer ${token}`
  })(`/appointments/cancel/${id}`, payload);

export const putProcessWithPaymentMethod = (
  accountId: string,
  reserveId: string,
  payload: { mode?: CheckoutSessionMode; cancelUrl: string; returnUrl: string; saveCardForFutureUse?: boolean },
  token: string
) =>
  httpClient('PUT', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/reserved-appointments/${reserveId}:processWithPaymentMethod`,
    payload
  ) as Promise<BentResponse>;

export const putProcessWithoutPayment = (accountId: string, reserveId: string, token: string) =>
  httpClient('PUT', url!, [204], { Authorization: `Bearer ${token}` })(
    `/accounts/${accountId}/reserved-appointments/${reserveId}:processWithoutPayment`
  ) as Promise<BentResponse>;
