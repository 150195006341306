import { notification } from 'antd';
import { formatGraphData } from 'helm/utils/formatGraphData';
import { ChartData } from 'interfaces/MicroJournal/Chart';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { getCheckInInsights } from 'utils/http/checkIn';

export const useFetchOverallCheckInGraph = ({ token, dateRange = 30 }: { token: string; dateRange?: number }) => {
  const [overallCheckInGraph, setOverallCheckInGraph] = useState<ChartData['insights']>([]);
  const [isOverallCheckInGraphLoading, setIsOverallCheckInGraphLoading] = useState(true);

  const fetchOverallCheckInGraph = useCallback(async ({ token, dateRange }: { token: string; dateRange: number }) => {
    setIsOverallCheckInGraphLoading(true);

    try {
      const startDate = moment().subtract(dateRange, 'days').format('YYYY-MM-DD');
      const endDate = moment().format('YYYY-MM-DD');

      const callGetCheckInInsightsByRecordIdAndType = await getCheckInInsights({
        token,
        queryParam: {
          startDate,
          endDate,
          type: 'overall'
        }
      });
      const { insights } = await callGetCheckInInsightsByRecordIdAndType.json();

      if (insights) {
        setOverallCheckInGraph(formatGraphData(insights));
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to get your feelings tracking'
      });
    }

    setIsOverallCheckInGraphLoading(false);
  }, []);

  const refetch = (dateRange: number) => {
    if (token) {
      fetchOverallCheckInGraph({ token, dateRange });
    }
  };

  useEffect(() => {
    if (token) {
      fetchOverallCheckInGraph({ token, dateRange });
    }
  }, [fetchOverallCheckInGraph, dateRange, token]);

  return {
    overallTitle: 'Overall, how are you feeling?',
    overallCheckInGraph,
    isOverallCheckInGraphLoading,
    fetchOverallCheckInGraph: refetch
  };
};
