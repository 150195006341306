import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import styles from './PsychologistList.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import PsychologistCard from '../PsychologistCard/PsychologistCard';
import { PsychologistFitFilter } from '../../PsychologistListing';
import { LocationList, MentalHealthList } from 'Select/interfaces/mentalHealth';

interface PsychologistListProps {
  psychologistList: PractitionerDetailsInterface[];
  matchedSpecialisations: string[];
  selectedFitFilter: PsychologistFitFilter;
  selectedLocation: LocationList[];
  selectedMentalHealthConcern: MentalHealthList[];
  hasMorePsychologists: boolean;
  isNewClient: boolean;
  isReversedBackground: boolean;
  loadMorePsychologists: () => Promise<void>;
  onClickJoinWaitlist: () => void;
}

const PsychologistList = ({
  psychologistList,
  matchedSpecialisations,
  selectedFitFilter,
  selectedLocation,
  selectedMentalHealthConcern,
  hasMorePsychologists,
  isNewClient,
  isReversedBackground,
  loadMorePsychologists,
  onClickJoinWaitlist
}: PsychologistListProps) => {
  return (
    <div className={styles.container}>
      <InfiniteScroll
        loadMore={loadMorePsychologists}
        initialLoad={false}
        pageStart={0}
        hasMore={hasMorePsychologists}
        loader={
          <div className={styles.loadMoreWrapper} key={'loading'}>
            <LoadingDot />
          </div>
        }
      >
        {psychologistList.map((psychologist, i) => (
          <PsychologistCard
            key={i}
            psychologist={psychologist}
            matchedSpecialisations={matchedSpecialisations}
            selectedFitFilter={selectedFitFilter}
            selectedLocation={selectedLocation}
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            isNewClient={isNewClient}
            isOddItem={i % 2 === 1}
            isReversedBackground={isReversedBackground}
            onClickJoinWaitlist={onClickJoinWaitlist}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default PsychologistList;
