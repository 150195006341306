import { AppointmentSlot } from 'utils/hooks/appointment';
import { useCallback, useEffect, useState } from 'react';
import { notification } from 'antd';
import { getReservedAppointmentByReserveId } from 'utils/http/appointment';
import moment from 'moment';

export const useFetchReservedAppointmentsWithTimeZone = ({
  reserveId,
  accountId
}: {
  reserveId: string;
  accountId: string;
}) => {
  const [fetching, setFetching] = useState(true);
  const [appointments, setAppointments] = useState<AppointmentSlot[]>([]);

  const fetchAppointment = useCallback(async () => {
    if (reserveId && accountId) {
      try {
        const response = await getReservedAppointmentByReserveId(accountId, reserveId);
        const data: AppointmentSlot[] = (await response.json()).appointments;

        // Convert to client timezone
        const massageData: AppointmentSlot[] = data.map((appointment) => ({
          ...appointment,
          startTime: moment(`${appointment.date} ${appointment.startTime}`).format('hh:mmA'),
          endTime: moment(`${appointment.date} ${appointment.endTime}`).format('hh:mmA')
        }));

        setAppointments(massageData);
        setFetching(false);
      } catch (e) {
        notification.error({ message: 'Something went wrong while trying to fetch your appointment' });
      } finally {
        setFetching(false);
      }
    }
  }, [accountId, reserveId]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return { fetching, appointments, fetchAppointment };
};
