import { MicroJournalEntry } from 'utils/hooks/microJournal/getMicroJournalEntries';
import styles from './JournalEntryCard.module.scss';

const JournalEntryCard = ({ date, title, response, tags }: Omit<MicroJournalEntry, '_id'>) => (
  <div className={styles.container}>
    <div className={styles.date}>{date}</div>
    <div className={styles.title}>{title}</div>
    <div className={styles.subtitle}>{response}</div>
    <div className={styles.tags}>
      {tags?.map(({ title, polarity }, idx) => (
        <div className={polarity === 'positive' ? styles.positivePolarity : styles.negativePolarity} key={idx}>
          {title}
        </div>
      ))}
    </div>
  </div>
);

export default JournalEntryCard;
