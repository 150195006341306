import { useField } from 'formik';

import styles from './EmojiButton.module.scss';

interface EmojiButtonProps {
  label: string;
  name: string;
  questionId: string;
}

const EMOJI_MAP = {
  happy: '😄',
  grateful: '🤗',
  loved: '🥰',
  respected: '🙇',
  thoughtful: '🤔',
  hopeful: '🤞',
  nervous: '😧',
  anxious: '😰',
  worried: '😟',
  angry: '😠',
  tired: '😴',
  frustrated: '😤',
  bored: '🥱',
  lonely: '😶',
  mischievous: '😜',
  strong: '💪',
  flat: '😒',
  upset: '😢',
  unwell: '🤒'
} as Record<string, string>;

const EmojiButton = ({ label, name, questionId }: EmojiButtonProps) => {
  const [{ value }, , { setValue }] = useField<number>(`${questionId}.value.${name}`);

  const handleClick = () => {
    if (value === 2) {
      setValue(0);
    } else {
      setValue(value + 1);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles[`buttonValue${value}`]} onClick={handleClick}>
        <div className={styles.emoji}>{EMOJI_MAP[name]}</div>
        <div className={styles.text}>{label}</div>
      </div>
    </div>
  );
};

export default EmojiButton;
