import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import styles from './DecoupleGPMedicarePage.module.scss';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import MedicareCard from '../../Booking/components/MedicareCard/MedicareCard';
import { retrieveUserSession } from '../../SignUp/helper/userSession';
import { MedicareInformation } from 'components/UpdateProfileContent/interface';
import { MEDICARE_CARD_DEFAULT_VALUE } from '../../Booking/Booking';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { putClientMedicare } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import moment from 'moment';
import { notification } from 'antd';
import { debounce } from 'lodash';
import { useSomeoneHealthToken } from 'SomeoneHealth/utils/hooks/useSomeoneHealthToken';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';

const DecoupleGPMedicarePage = () => {
  const { token } = useSomeoneHealthToken();
  const navigate = useNavigate();
  const { clientProfileData: clientProfile } = useGetClientProfileDetails();
  const { SIGNUP_DECOUPLE_GP } = useSomeoneHealthRoutesGenerator();

  const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
  const massageMedicareDefaultValue: MedicareInformation = {
    ...MEDICARE_CARD_DEFAULT_VALUE,
    firstName: retrieveUserProfile[0]?.firstName || '',
    lastName: retrieveUserProfile[0]?.lastName || '',
    dateOfBirth: retrieveUserProfile[0]?.dateOfBirth || ''
  };

  const clientProfileData: MedicareInformation = {
    ...MEDICARE_CARD_DEFAULT_VALUE,
    ...clientProfile?.medicare
  };

  const [medicareCard, setMedicareCard] = useState<MedicareInformation>(
    clientProfile ? clientProfileData : massageMedicareDefaultValue
  );
  const [useSignUpInfoToggle, setUseSignUpInfoToggle] = useState<boolean>(!clientProfile?.medicare);
  const [isMedicareCardValidating, setIsMedicareValidating] = useState(false);
  const [isMedicareValueChanged, setIsMedicareValueChanged] = useState(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>(
    medicareCard.status || {}
  );

  const saveMedicareInfo = async (value: MedicareInformation, token: string) => {
    try {
      const response = await putClientMedicare(token, {
        ...value,
        expiryDate: value.expiryDate || null,
        dateOfBirth: moment(value.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
      });
      setIsMedicareValueChanged(false);

      if (response.statusCode === 200) {
        const medicareResponse = await response.json();
        const valueMe = {
          ...medicareResponse,
          dateOfBirth: moment(medicareResponse.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
        };
        setMedicareErrorMessage(medicareResponse.status);
        setMedicareCard(valueMe);
      } else if (response.statusCode === 400) {
        setMedicareErrorMessage({
          medicare: {
            code: 9633
          }
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong while trying to update your Medicare information' });
    } finally {
      setIsMedicareValidating(false);
    }
  };

  const callMedicareUpdating = useMemo(
    () =>
      debounce((formValues: MedicareInformation) => {
        if (token) {
          saveMedicareInfo(formValues, token);
        }
      }, 2000),
    [token]
  );

  useEffect(() => {
    if (isMedicareValueChanged && medicareCard) {
      const { number, irn, expiryDate, dva } = medicareCard;
      if ((number && irn && expiryDate !== '') || dva) {
        setIsMedicareValidating(true);
        callMedicareUpdating(medicareCard);
      }
    }
  }, [isMedicareValueChanged, medicareCard, callMedicareUpdating]);

  const onNextStep = () => {
    navigate(SIGNUP_DECOUPLE_GP.ADD_PAYMENT);
  };

  const isCheckMedicareValid =
    medicareCard.status?.medicare?.code !== 0 && medicareCard.status?.medicare?.code !== 9626;

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Medicare'}>
      <SomeoneHealthContentLayout>
        <SomeoneHealthHeader pinkLogo withPadding classNames={styles.header} />
        <div className={classNames(styles.container, 'someone-health-theme')}>
          <div className={styles.contentHeader}>
            <div className={styles.title}>Please add your Medicare details</div>
            <div className={styles.subHeader}>
              Current Medicare information is required to schedule a GP appointment.
            </div>
          </div>
          <MedicareCard
            medicareCardValue={medicareCard}
            setMedicareCardValue={setMedicareCard}
            useSignUpInfoToggle={useSignUpInfoToggle}
            setUseSignUpInfoToggle={() => setUseSignUpInfoToggle(!useSignUpInfoToggle)}
            showCheckToggle={!clientProfile?.medicare}
            isMedicareCardValidating={isMedicareCardValidating}
            medicareErrorMessage={medicareErrorMessage}
            setIsMedicareValueChanged={setIsMedicareValueChanged}
            className={styles.medicareCardContent}
            introClassName={styles.introClassName}
            verificationStatusClassName={styles.verificationStatusClassName}
          />
          <div className={styles.buttonWrapper}>
            <ButtonSH
              icon={'navigate_next'}
              iconPostFix
              disabled={isMedicareCardValidating || isCheckMedicareValid}
              className={styles.submitButton}
              onClick={onNextStep}
            >
              Continue
            </ButtonSH>
          </div>
        </div>
      </SomeoneHealthContentLayout>
      <TacklitFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default DecoupleGPMedicarePage;
