import DocViewer from '@cyntler/react-doc-viewer';
import { Modal } from 'antd';
import Loading from 'components/Loading/Loading';
import styles from './FilePreview.module.scss';
import queryString from 'query-string';
import { UploadFile } from 'SomeoneHealth/pages/Booking/components/AddGPReferralForm/AddGPReferralForm';

const DOWNLOAD_ENDPOINT = `${process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL}/client-records/me/gp-referral-documents:download?`;

const DOC_VIEWER_CONFIG = {
  header: {
    disableHeader: true,
    disableFileName: true
  }
};

interface FilePreviewProps {
  title: string;
  file: UploadFile;
  visible: boolean;
  token: string;
  onCancel: () => void;
}

const FilePreview = ({ title, file, visible, token, onCancel }: FilePreviewProps) => {
  const { fileName, bucketName } = file;
  return (
    <Modal
      className={styles.modalContainer}
      visible={visible}
      onCancel={onCancel}
      title={
        <div className={styles.modalHeader}>
          <p className={styles.title}>{title}</p>
        </div>
      }
      footer={null}
    >
      {!token ? (
        <Loading />
      ) : (
        <DocViewer
          config={DOC_VIEWER_CONFIG}
          requestHeaders={{ Authorization: `Bearer ${token}` }}
          documents={[
            {
              uri: `${DOWNLOAD_ENDPOINT}${queryString.stringify({ fileName, bucketName })}`
            }
          ]}
        />
      )}
    </Modal>
  );
};

export default FilePreview;
