/* eslint-disable react/no-multi-comp */
import { useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { Clinician } from 'utils/hooks/clinician';
import { postAppointmentRequest } from 'utils/http/appointment';

import LoadingButton from 'components/v2/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import PatientFooterCard from 'components/PatientFooterCard/PatientFooterCard';
import TextArea from 'components/TextArea/TextArea';
import AppointmentHeader from '../components/AppointmentHeader/AppointmentHeader';

import styles from './AppointmentRequest.module.scss';
import PracticeLayout from 'layouts/PracticeLayout/PracticeLayout';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import { config } from 'config/config';

const AppointmentRequest = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { clinicianId = '', slugUrl = '' } = useParams<{ clinicianId: string; slugUrl: string }>();
  const location = useLocation();

  const { practiceProfile, clinician, isNewClient } = useMemo(() => {
    const { practiceProfile, clinician, isNewClient } =
      (location.state as {
        practiceProfile: PublicPracticeProfile;
        isNewClient: boolean;
        clinician: Clinician;
      }) || {};

    return { practiceProfile, clinician, isNewClient };
  }, [location.state]);
  const [request, setRequest] = useState('');
  const [showError, setShowError] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState<'' | 'active' | 'finished'>('');

  const handleSubmitAppointmentRequest = async () => {
    if (!request) {
      setShowError(true);
      return;
    }

    setSubmitButtonState('active');

    if (practiceProfile) {
      navigate(
        {
          pathname: `/p/${slugUrl}/appointment/contact`
        },
        {
          state: { practiceProfile, accountId: practiceProfile.accountId, message: request }
        }
      );
    } else if (isNewClient) {
      navigate(
        {
          pathname: `/${clinicianId}/appointment/contact`
        },
        {
          state: { accountId: clinician?.accountId, message: request }
        }
      );
    } else {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        await postAppointmentRequest({ clinicianId, message: request }, token);

        setSubmitButtonState('finished');

        navigate(
          {
            pathname: `/${clinicianId}/appointment/request/sent`
          },
          {
            state: { clinician }
          }
        );
      } catch (ex) {
        setSubmitButtonState('');
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
      }
    }
  };

  const appointmentRequestFormRender = () => {
    return (
      <>
        <div className={styles.title}>Create and send request</div>
        {!practiceProfile && !isNewClient ? (
          <div className={styles.patientProfileImgWrapper}>
            {user?.picture && <img src={user?.picture} alt={'patientImg'} className={styles.image} />}
            {user?.name}
          </div>
        ) : null}
        <div className={styles.otherOptionLabel}>
          This is not the best way to seek urgent support. If you or someone you know needs immediate help please call:
        </div>
        <div className={styles.emergencyLabel}>
          <div className={styles.contact}>
            <div>Emergency</div>
            <div className={styles.number}>{config.emergencyNumber}</div>
          </div>
          <div className={styles.contact}>
            <div>Lifeline</div>
            <div className={styles.number}>{config.lifeLineNumber}</div>
          </div>
        </div>
        <div className={styles.noteWrapper}>
          <TextArea
            label={`Add request to ${
              practiceProfile ? practiceProfile.name : `${clinician?.title} ${clinician?.name}`
            }`}
            value={request}
            onChange={(e) => setRequest(e.target.value)}
          />
          <ErrorMessage error="Please type your request here" visible={showError} />
        </div>
        <div className={styles.footer}>
          <LoadingButton className={styles.button} status={submitButtonState} onClick={handleSubmitAppointmentRequest}>
            Make Appointment Request
          </LoadingButton>
        </div>
      </>
    );
  };

  return !practiceProfile ? (
    <PatientContentLayout withFullHeight>
      {!clinician ? (
        <div className={styles.loading} />
      ) : (
        <>
          <div className={styles.contentWrapper}>
            <AppointmentHeader
              profileName={`${clinician.title} ${clinician.name}`}
              profileImg={clinician.avatar}
              label="Availability"
            />
          </div>
          <PatientFooterCard containerClassName={styles.fullHeightFooter} className={styles.cardContainer}>
            {appointmentRequestFormRender()}
          </PatientFooterCard>
        </>
      )}
    </PatientContentLayout>
  ) : (
    <PracticeLayout>
      <div className={styles.cardContainer}>{appointmentRequestFormRender()}</div>
    </PracticeLayout>
  );
};

export default AppointmentRequest;
