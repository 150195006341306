export enum MentalHealthList {
  AnxiousOrPanicky = 'anxiousOrPanicky',
  DifficultyInMyRelationship = 'difficultyInMyRelationship',
  TraumaticExperience = 'traumaticExperience',
  TroubleSleeping = 'troubleSleeping',
  AddictionOrDifficultyWithSubstanceAbuse = 'addictionOrDifficultyWithSubstanceAbuse',
  DownOrDepressed = 'downOrDepressed',
  StressAtWorkOrSchool = 'stressAtWorkOrSchool',
  NeurodevelopmentalDisorders = 'neurodevelopmentalDisorders',
  EatingAndBodyStruggles = 'eatingAndBodyStruggles',
  HealthAndLifestyleDisorders = 'healthAndLifestyleDisorders',

  // Specialisations
  Addiction = 'addiction',
  AdjustmentDisorder = 'adjustmentDisorder',
  AlcoholAndOtherDrugs = 'alcoholAndOtherDrugs',
  AngerManagement = 'angerManagement',
  Anxiety = 'anxiety',
  AttentionDeficitHyperactivityDisorder = 'attentionDeficitHyperactivityDisorder',
  Autism = 'autism',
  BipolarAffectiveDisorder = 'bipolarAffectiveDisorder',
  BodyDysmorphia = 'bodyDysmorphia',
  BorderlinePersonalityDisorder = 'borderlinePersonalityDisorder',
  Burnout = 'burnout',
  Career = 'career',
  ChronicPain = 'chronicPain',
  Communication = 'communication',
  Confidence = 'confidence',
  DepressionAndMood = 'depressionAndMood',
  DissociativeDisorders = 'dissociativeDisorders',
  DomesticViolence = 'domesticViolence',
  Dyslexia = 'dyslexia',
  EatingDisorders = 'eatingDisorders',
  Family = 'family',
  FertilityAPerinatal = 'fertilityAPerinatal',
  FinancialStress = 'financialStress',
  GamblingAddiction = 'gamblingAddiction',
  GenderAndSexualIdentity = 'genderAndSexualIdentity',
  GriefAndBereavement = 'griefAndBereavement',
  HealthAndLifestyle = 'healthAndLifestyle',
  InfantSleep = 'infantSleep',
  LifeTransition = 'lifeTransition',
  Loneliness = 'loneliness',
  LowMood = 'lowMood',
  MeaningValue = 'meaningValue',
  Narcissism = 'narcissism',
  NewParent = 'newParent',
  Ocd = 'ocd',
  PainManagement = 'painManagement',
  PanicAttacks = 'panicAttacks',
  ParentTherapy = 'parentTherapy',
  Perfectionism = 'perfectionism',
  Performance = 'performance',
  PerinatalAndPostnatalDepressionAndAnxiety = 'perinatalAndPostnatalDepressionAndAnxiety',
  PersonalityDisorder = 'personalityDisorder',
  Phobias = 'phobias',
  PornographySexualDifficulties = 'pornographySexualDifficulties',
  Ptsd = 'ptsd',
  RelationshipCounselling = 'relationshipCounselling',
  RiskyBehaviours = 'riskyBehaviours',
  SelfDevelopment = 'selfDevelopment',
  SelfEsteem = 'selfEsteem',
  SexualAbuse = 'sexualAbuse',
  SexualHealth = 'sexualHealth',
  SleepInsomnia = 'sleepInsomnia',
  SmokingCessation = 'smokingCessation',
  SocialAnxiety = 'socialAnxiety',
  StressManagement = 'stressManagement',
  Tinnitus = 'tinnitus',
  TraumaAndPtsd = 'traumaAndPtsd',
  Violence = 'violence',
  WeightManagement = 'weightManagement',
  WorkplaceIncidentTherapy = 'workplaceIncidentTherapy',
  WorkplaceRelations = 'workplaceRelations',
  WorkStress = 'workStress'
}

export const MENTAL_HEALTH_LIST_LABELS: Record<MentalHealthList, string> = {
  [MentalHealthList.AnxiousOrPanicky]: 'Anxious or panicky',
  [MentalHealthList.DifficultyInMyRelationship]: 'Relationship difficulties',
  [MentalHealthList.TraumaticExperience]: 'Traumatic experience',
  [MentalHealthList.TroubleSleeping]: 'Sleeping issues',
  [MentalHealthList.AddictionOrDifficultyWithSubstanceAbuse]: 'Addiction or substance abuse',
  [MentalHealthList.DownOrDepressed]: 'Down or depressed',
  [MentalHealthList.StressAtWorkOrSchool]: 'Stress',
  [MentalHealthList.NeurodevelopmentalDisorders]: 'Neurodevelopmental disorders',
  [MentalHealthList.EatingAndBodyStruggles]: 'Eating and body struggles',
  [MentalHealthList.HealthAndLifestyleDisorders]: 'Health and Lifestyle disorders',

  // Specialisations
  [MentalHealthList.Addiction]: 'Addiction',
  [MentalHealthList.AdjustmentDisorder]: 'Adjustment disorder',
  [MentalHealthList.AlcoholAndOtherDrugs]: 'Alcohol and Other Drugs (AOD)',
  [MentalHealthList.AngerManagement]: 'Anger Management',
  [MentalHealthList.Anxiety]: 'Anxiety',
  [MentalHealthList.AttentionDeficitHyperactivityDisorder]: 'Attention Deficit Hyperactivity Disorder (ADHD)',
  [MentalHealthList.Autism]: 'Autism',
  [MentalHealthList.BipolarAffectiveDisorder]: 'Bipolar affective disorder',
  [MentalHealthList.BodyDysmorphia]: 'Body Dysmorphia',
  [MentalHealthList.BorderlinePersonalityDisorder]: 'Borderline Personality Disorder',
  [MentalHealthList.Burnout]: 'Burnout',
  [MentalHealthList.Career]: 'Career',
  [MentalHealthList.ChronicPain]: 'Chronic Pain',
  [MentalHealthList.Communication]: 'Communication',
  [MentalHealthList.Confidence]: 'Confidence',
  [MentalHealthList.DepressionAndMood]: 'Depression & Mood',
  [MentalHealthList.DissociativeDisorders]: 'Dissociative disorders',
  [MentalHealthList.DomesticViolence]: 'Domestic Violence',
  [MentalHealthList.Dyslexia]: 'Dyslexia',
  [MentalHealthList.EatingDisorders]: 'Eating Disorders',
  [MentalHealthList.Family]: 'Family',
  [MentalHealthList.FertilityAPerinatal]: 'Fertility & Perinatal',
  [MentalHealthList.FinancialStress]: 'Financial stress',
  [MentalHealthList.GamblingAddiction]: 'Gambling Addiction',
  [MentalHealthList.GenderAndSexualIdentity]: 'Gender & Sexual Identity',
  [MentalHealthList.GriefAndBereavement]: 'Grief & Bereavement',
  [MentalHealthList.HealthAndLifestyle]: 'Health & Lifestyle',
  [MentalHealthList.InfantSleep]: 'Infant Sleep',
  [MentalHealthList.LifeTransition]: 'Life Transition',
  [MentalHealthList.Loneliness]: 'Loneliness',
  [MentalHealthList.LowMood]: 'Low mood',
  [MentalHealthList.MeaningValue]: 'Meaning & value',
  [MentalHealthList.Narcissism]: 'Narcissism',
  [MentalHealthList.NewParent]: 'New Parent',
  [MentalHealthList.Ocd]: 'OCD',
  [MentalHealthList.PainManagement]: 'Pain Management',
  [MentalHealthList.PanicAttacks]: 'Panic attacks',
  [MentalHealthList.ParentTherapy]: 'Parent therapy',
  [MentalHealthList.Perfectionism]: 'Perfectionism',
  [MentalHealthList.Performance]: 'Performance',
  [MentalHealthList.PerinatalAndPostnatalDepressionAndAnxiety]: 'Perinatal and postnatal depression and anxiety',
  [MentalHealthList.PersonalityDisorder]: 'Personality disorder',
  [MentalHealthList.Phobias]: 'Phobias',
  [MentalHealthList.PornographySexualDifficulties]: 'Pornography/ sexual difficulties',
  [MentalHealthList.Ptsd]: 'PTSD',
  [MentalHealthList.RelationshipCounselling]: 'Relationship Counselling',
  [MentalHealthList.RiskyBehaviours]: 'Risky behaviours',
  [MentalHealthList.SelfDevelopment]: 'Self development',
  [MentalHealthList.SelfEsteem]: 'Self esteem',
  [MentalHealthList.SexualAbuse]: 'Sexual Abuse',
  [MentalHealthList.SexualHealth]: 'Sexual health',
  [MentalHealthList.SleepInsomnia]: 'Sleep/ Insomnia',
  [MentalHealthList.SmokingCessation]: 'Smoking cessation',
  [MentalHealthList.SocialAnxiety]: 'Social anxiety',
  [MentalHealthList.StressManagement]: 'Stress Management',
  [MentalHealthList.Tinnitus]: 'Tinitus',
  [MentalHealthList.TraumaAndPtsd]: 'Trauma & PTSD',
  [MentalHealthList.Violence]: 'Violence',
  [MentalHealthList.WeightManagement]: 'Weight Management',
  [MentalHealthList.WorkplaceIncidentTherapy]: 'Workplace incident therapy',
  [MentalHealthList.WorkplaceRelations]: 'Workplace relations',
  [MentalHealthList.WorkStress]: 'Work stress'
};

export const SpecialisationOptionList = [
  MentalHealthList.Addiction,
  MentalHealthList.AdjustmentDisorder,
  MentalHealthList.AlcoholAndOtherDrugs,
  MentalHealthList.AngerManagement,
  MentalHealthList.Anxiety,
  MentalHealthList.AttentionDeficitHyperactivityDisorder,
  MentalHealthList.Autism,
  MentalHealthList.BipolarAffectiveDisorder,
  MentalHealthList.BodyDysmorphia,
  MentalHealthList.BorderlinePersonalityDisorder,
  MentalHealthList.Burnout,
  MentalHealthList.Career,
  MentalHealthList.ChronicPain,
  MentalHealthList.Communication,
  MentalHealthList.Confidence,
  MentalHealthList.DepressionAndMood,
  MentalHealthList.DissociativeDisorders,
  MentalHealthList.DomesticViolence,
  MentalHealthList.Dyslexia,
  MentalHealthList.EatingDisorders,
  MentalHealthList.Family,
  MentalHealthList.FertilityAPerinatal,
  MentalHealthList.FinancialStress,
  MentalHealthList.GamblingAddiction,
  MentalHealthList.GenderAndSexualIdentity,
  MentalHealthList.GriefAndBereavement,
  MentalHealthList.HealthAndLifestyle,
  MentalHealthList.InfantSleep,
  MentalHealthList.LifeTransition,
  MentalHealthList.Loneliness,
  MentalHealthList.LowMood,
  MentalHealthList.MeaningValue,
  MentalHealthList.Narcissism,
  MentalHealthList.NewParent,
  MentalHealthList.Ocd,
  MentalHealthList.PainManagement,
  MentalHealthList.PanicAttacks,
  MentalHealthList.ParentTherapy,
  MentalHealthList.Perfectionism,
  MentalHealthList.Performance,
  MentalHealthList.PerinatalAndPostnatalDepressionAndAnxiety,
  MentalHealthList.PersonalityDisorder,
  MentalHealthList.Phobias,
  MentalHealthList.PornographySexualDifficulties,
  MentalHealthList.Ptsd,
  MentalHealthList.RelationshipCounselling,
  MentalHealthList.RiskyBehaviours,
  MentalHealthList.SelfDevelopment,
  MentalHealthList.SelfEsteem,
  MentalHealthList.SexualAbuse,
  MentalHealthList.SexualHealth,
  MentalHealthList.SleepInsomnia,
  MentalHealthList.SmokingCessation,
  MentalHealthList.SocialAnxiety,
  MentalHealthList.StressManagement,
  MentalHealthList.Tinnitus,
  MentalHealthList.TraumaAndPtsd,
  MentalHealthList.Violence,
  MentalHealthList.WeightManagement,
  MentalHealthList.WorkStress,
  MentalHealthList.WorkplaceIncidentTherapy,
  MentalHealthList.WorkplaceRelations
];

export enum AgeRangeList {
  adults = 'adults',
  olderAdults = 'olderAdults',
  teenagers = 'teenagers',
  youth = 'youth'
}

export const NUMBER_AGE_RANGE_LIST_LABELS: Record<AgeRangeList, string> = {
  teenagers: '13-17',
  youth: '18-24',
  adults: '25-64',
  olderAdults: '65+'
};

export enum GenderList {
  woman = 'woman',
  man = 'man',
  transgenderWoman = 'transgenderWoman',
  transgenderMan = 'transgenderMan',
  nonBinary = 'nonBinary',
  agender = 'agender',
  genderNotListed = 'genderNotListed',
  preferNotToState = 'preferNotToState'
}

export const GENDER_LIST_LABELS: Record<GenderList, string> = {
  woman: 'Woman',
  man: 'Man',
  transgenderWoman: 'Transgender Woman',
  transgenderMan: 'Transgender Man',
  nonBinary: 'Non-Binary',
  agender: 'Agender (I don’t identify with any gender)',
  genderNotListed: 'Gender not listed',
  preferNotToState: 'Prefer not to state'
};

export enum AppointmentType {
  InitialConsult = 'initialConsult'
}

export const AppointmentTypeList = [
  {
    id: AppointmentType.InitialConsult,
    label: 'Initial Consult'
  }
];
