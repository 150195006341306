import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import PatientAdHocAssessment from 'pages/PatientAdHocAssessment/PatientAdHocAssessment';

const PsychometricAssessment = () => {
  return (
    <CORDSContentWrapper title={'CORDS - Psychometric'} fullwidth>
      <PatientAdHocAssessment />
    </CORDSContentWrapper>
  );
};

export default PsychometricAssessment;
