import { Modal } from 'antd';
import styles from './AgeRestrictionAlert.module.scss';

interface AgeRestrictionAlertProps {
  open: boolean;
  onClose: () => void;
}

const AgeRestrictionAlert = ({ open, onClose }: AgeRestrictionAlertProps) => {
  return (
    <Modal
      className={styles.modalContainer}
      bodyStyle={{ padding: '40px 20px' }}
      footer={null}
      visible={open}
      onCancel={onClose}
    >
      <div className={styles.container}>
        <span className={styles.title}>Age Restriction</span>
        <div>
          <span>
            Unfortunately, we're not a suitable service for children under 13 years of age. To help you find the the
            most appropriate support, please see below:
          </span>
          <span className={styles.boldText}>If an Emergency - contact 000,</span>
          <span>
            <span className={styles.boldText}>Lifeline</span>&nbsp;
            <a className={styles.text} rel="noreferrer" target={'_blank'} href={'https://www.lifeline.org.au/'}>
              https://www.lifeline.org.au/
            </a>
            ,&nbsp; or
          </span>
          <span>alternatively to help find services, please go to the link below:</span>
          <span>
            <a
              className={styles.text}
              rel="noreferrer"
              target={'_blank'}
              href={'https://www.healthdirect.gov.au/australian-health-services/all-services'}
            >
              https://www.healthdirect.gov.au/australian-health-services/all-services
            </a>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default AgeRestrictionAlert;
