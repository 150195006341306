import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import styles from './GPBookingPage.module.scss';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import GPBooking from './components/GPBooking/GPBooking';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

const GPBookingPage = () => {
  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - Book a GP appointment}`}>
      <div className={styles.container}>
        <SomeoneHealthHeader withPadding withMenu homeLink />
        <SomeoneHealthContentLayout className={styles.contentLayoutWrapper}>
          <div className={styles.welcomeMessage}>GP Appointment</div>
          <GPBooking />
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default GPBookingPage;
