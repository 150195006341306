import styles from './InvoiceViewWithTemplate.module.scss';
import PracticeInfoSection from './components/PracticeInfoSection/PracticeInfoSection';
import { Divider, notification } from 'antd';
import InvoiceInfoSection from './components/InvoiceInfoSection/InvoiceInfoSection';
import ServiceDetailsSection from './components/ServiceDetailsSection/ServiceDetailsSection';
import InvoiceItemSection from './components/InvoiceItemSection/InvoiceItemSection';
import PaymentDetailsSection from './components/PaymentDetailsSection/PaymentDetailsSection';
import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import { LegacyRef, useState } from 'react';
import { postStripeCheckoutSession } from 'utils/http/stripe';
import { getMarkInvoicePaid, getUnmarkInvoicePaid } from 'utils/http/invoice';
import { Invoice, InvoiceStatus } from 'interfaces/Invoice/Invoice';
import { shouldShowStripePayment } from './helpers/shouldShowStripePayment';
import { CLIENT_BRAND, ClientBrand } from 'interfaces/ClientBrand';

interface InvoiceViewWithTemplateProps {
  invoice: Invoice;
  isStripeActive: boolean;
  refetchInvoice: () => void;
  pdfRef?: LegacyRef<HTMLDivElement>;
}

const InvoiceViewWithTemplate = ({ invoice, isStripeActive, refetchInvoice, pdfRef }: InvoiceViewWithTemplateProps) => {
  const [isMarking, setIsMarking] = useState(false);
  const [isPayingOnline, setIsPayingOnline] = useState(false);

  const { _id, invoiceId, status, clinician, practice, footer } = invoice;

  const isClaimInvoice = [
    InvoiceStatus.PendingClaim,
    InvoiceStatus.ClaimSuccess,
    InvoiceStatus.ClaimComplete,
    InvoiceStatus.ClaimRejected
  ].includes(status);
  const showStripePayment =
    isStripeActive && shouldShowStripePayment({ clientBrand: CLIENT_BRAND, status }) && !isClaimInvoice;

  const showServiceDetails =
    invoice?.template?.medicareClaim ||
    invoice?.template?.medicareClaimant ||
    invoice?.template?.medicareReferral ||
    invoice?.template?.medicareServiceProvider;

  const payOnline = async () => {
    setIsPayingOnline(true);
    try {
      const { url } = await (
        await postStripeCheckoutSession(_id, `${window.location.href}?stripePaymentSuccess=1`)
      ).json();
      window.location.href = url;
    } catch (ex) {
      setIsPayingOnline(false);
      notification.error({ message: 'Unable to initiate online checkout session.' });
      console.error(ex);
    }
  };

  const markPaid = async () => {
    setIsMarking(true);
    try {
      await getMarkInvoicePaid(_id);
      notification.success({ message: `Invoice ${invoiceId} successfully marked as paid` });
      refetchInvoice();
    } catch (err) {
      notification.error({ message: 'Unable to mark invoice as paid' });
      console.error(err);
    } finally {
      setIsMarking(false);
    }
  };

  const unmarkPaid = async () => {
    setIsMarking(true);
    try {
      await getUnmarkInvoicePaid(_id);
      notification.success({ message: `Invoice ${invoiceId} successfully unmarked as paid` });
      refetchInvoice();
    } catch (err) {
      notification.error({ message: 'Unable to undo action' });
      console.error(err);
    } finally {
      setIsMarking(false);
    }
  };

  return (
    <div className={styles.container}>
      <div ref={pdfRef}>
        <PracticeInfoSection invoice={invoice} />
        <Divider style={{ margin: 0 }} />
        <InvoiceInfoSection invoice={invoice} />
        <Divider style={{ margin: 0 }} />
        {showServiceDetails && <ServiceDetailsSection invoice={invoice} />}
        <InvoiceItemSection invoice={invoice} />
      </div>
      <Divider style={{ margin: 0 }} />
      {showStripePayment && (
        <div className={styles.payOnlineContainer}>
          <div className={styles.payOnlineContent}>
            <div className={styles.payOnlineDesc}>
              Use our secure checkout to pay via credit card, debit card, Apple Pay or Google Pay
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.payOnlineButton}
                status={isPayingOnline ? 'active' : ''}
                onClick={() => payOnline()}
                disabled={status === InvoiceStatus.ConfirmPaid}
              >
                {status === InvoiceStatus.ConfirmPaid ? 'PAID SECURELY ONLINE' : 'PAY SECURELY ONLINE'}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isClaimInvoice && (
        <div className={styles.claimContainer}>
          <div className={styles.claimInformation}>
            This service benefit is assigned to Medicare Bulk Bill. No fees or other reimbursements are applicable.
          </div>
        </div>
      )}
      <PaymentDetailsSection invoice={invoice} isStripeActive={isStripeActive} />
      {footer && (
        <div
          className={classNames(styles.padding20, styles.textAreaContent)}
          dangerouslySetInnerHTML={{ __html: footer }}
        />
      )}
      {![ClientBrand.HELM, ClientBrand.SOMEONE_HEALTH].includes(CLIENT_BRAND) && (
        <>
          {status === InvoiceStatus.Issued && (
            <div className={styles.ctaContainer}>
              <Button className={styles.ctaMarkButton} status={isMarking ? 'active' : ''} onClick={() => markPaid()}>
                MARK AS PAID
              </Button>
              <div className={styles.ctaDesc}>
                Let {clinician?.name || practice.name} know you have paid this invoice
              </div>
            </div>
          )}
          {status === InvoiceStatus.MarkedPaid && (
            <div className={styles.ctaContainer}>
              <Button
                className={styles.ctaUnmarkButton}
                status={isMarking ? 'active' : ''}
                onClick={() => unmarkPaid()}
              >
                PAID{' '}
                <span style={{ position: 'relative', fontSize: '8px', top: '-2px', left: '4px' }}>CLICK TO UNDO</span>
              </Button>

              <div className={styles.ctaDesc}>
                You’ve signaled this invoice is <strong>paid</strong>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InvoiceViewWithTemplate;
