import { useAuth0 } from '@auth0/auth0-react';
import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { getClientBookingRuleType } from 'utils/http/SchedService/ClientRecords/appointments';

export enum BookingRuleType {
  New = 'new',
  Existing = 'existing'
}

export const useFetchClientBookingRule = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();

  const [clientBookingRule, setClientBookingRule] = useState<BookingRuleType>();
  const [isClientBookingRuleLoading, setIsClientBookingRuleLoading] = useState(false);

  const fetchClientBookingRule = useCallback(async () => {
    if (!isAuthenticated || (user && user['https://tacklit.com/roles'].includes('clinician'))) {
      return;
    }

    setIsClientBookingRuleLoading(true);

    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_API_AUDIENCE
      });

      const { bookingRuleType } = (await (await getClientBookingRuleType(token)).json()) as {
        bookingRuleType: BookingRuleType;
      };

      setClientBookingRule(bookingRuleType);
      setIsClientBookingRuleLoading(false);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to fetch your booking details' });
    }
  }, [getAccessTokenSilently, isAuthenticated, user]);

  useEffect(() => {
    if (!isLoading) {
      fetchClientBookingRule();
    }
  }, [fetchClientBookingRule, isLoading]);

  return { clientBookingRule, isClientBookingRuleLoading };
};
