const PATIENT_CHECK_IN_TOKEN_KEY = 'patient-check-in-token';

export const getPatientToken = () => {
  return localStorage.getItem(PATIENT_CHECK_IN_TOKEN_KEY);
};

export const setPatientToken = (token: string) => {
  localStorage.setItem(PATIENT_CHECK_IN_TOKEN_KEY, token);
};

export const removePatientToken = () => {
  localStorage.removeItem(PATIENT_CHECK_IN_TOKEN_KEY);
};
