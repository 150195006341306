export enum SpecialistTheme {
  Alcohol = 'alcohol',
  Drugs = 'drugs',
  Gambling = 'gambling',
  Emotions = 'emotions',
  Relationship = 'relationship',
  All = 'all'
}

export enum InitialSessionType {
  InitialConsult = 'initialConsult',
  InitialConsultFriendsFamily = 'initialConsult:Friends&Family',
  AdvisorySession = 'advisorySession'
}
