import { InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './Radio.module.scss';
import {
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';

interface RadioOption {
  label: string;
  value: string | number;
}

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  options: RadioOption[];
  labelClass?: string;
  radioClass?: string;
  radioClassName?: any;
  radioLabelClass?: string;
  radioWrapperClass?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  vertical?: boolean;
  variant?: string;
  id?: string;
}

const Radio = ({
  className,
  label,
  name,
  options,
  labelClass,
  radioClass,
  radioLabelClass,
  radioWrapperClass,
  hasError,
  noSpacing,
  value: formValue,
  vertical,
  radioClassName,
  variant,
  id,
  ...props
}: RadioProps) => {
  const radioClasses = classnames(
    hasError && styles.error,
    noSpacing && styles.noSpacing,
    variant ? styles[variant] : styles.radioBtn,
    radioClass
  );

  return (
    <div
      id={id}
      className={classnames(
        className,
        IS_SOMEONE_HEALTH_APP && 'someone-health-theme',
        IS_EASE_APP && 'ease-theme',
        IS_RECHARGE_APP && 'recharge-theme',
        IS_SELECT_APP && 'select-theme'
      )}
    >
      {label && <div className={classnames(styles.label, labelClass)}>{label}</div>}
      <div className={classnames(styles.radio, vertical && styles.vertical, radioWrapperClass)}>
        {options.map(({ label, value }, index) => {
          const isChecked = formValue && value.toString() === formValue;
          return (
            <div className={classnames(radioClasses, radioClassName)} key={index}>
              <input
                type={'radio'}
                id={`radio-${name}-${value}-${id}`}
                name={name}
                value={value}
                checked={value.toString() === formValue || false}
                {...props}
              />
              <label htmlFor={`radio-${name}-${value}-${id}`} className={classnames(styles.label, radioLabelClass)}>
                {label}
                {isChecked && variant === 'button' ? (
                  <span
                    className={classnames(
                      IS_EASE_APP ? 'material-icons-outlined' : 'material-icons',
                      styles.checkedIcon
                    )}
                  >
                    {IS_EASE_APP ? 'check_circle' : 'check'}
                  </span>
                ) : null}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Radio;
