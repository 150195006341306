import { httpClient } from '../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_PATIENT_PROFILE_SERVICE_URL;

export const getPatientDetailsMinified = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })('/patient/minified') as Promise<BentResponse>;

export const verifyEmail = (token: string) => {
  return httpClient('PATCH', url!, 204, { Authorization: `Bearer ${token}` })('/patients/me:verifyEmail');
};
