import classNames from 'classnames';

import styles from './HelmContentLayout.module.scss';
import { CSSProperties } from 'react';

interface HelmContentLayoutProps {
  children?: any;
  className?: string;
  id?: string;
  style?: CSSProperties;
}

const HelmContentLayout = ({ children, className, id, style, ...props }: HelmContentLayoutProps) => {
  return (
    <div className={classNames(styles.contentLayout, className)} id={id} style={style} {...props}>
      {children}
    </div>
  );
};

export default HelmContentLayout;
