import { useEffect, useMemo, useState } from 'react';
import SomeoneHealthMeetTeamMenu from './components/SomeoneHealthMeetTeamMenu/SomeoneHealthMeetTeamMenu';
import styles from './SomeoneHealthMeetTeam.module.scss';
import SomeoneHealthMeetTeamContentBody from './components/SomeoneHealthMeetTeamContentBody/SomeoneHealthMeetTeamContentBody';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { useFetchPractitionerList } from 'SomeoneHealth/pages/PsychologistListing/hooks/getPsychologistList';
import { Skeleton } from 'antd';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';

const FEATURED_PSYCHOLOGISTS_CLINICIAN_IDS = isDevelopmentENV()
  ? [
      'auth0|63eb46d73bd9c33f489106e5', // wanda little
      'auth0|63e61f2001e5dba5ae32b2f5', // hiroshi kenzo
      'auth0|63fe0cf9ea070420c77d81da', // amy lin
      'auth0|63e61a055246d910ef28ebf2' // jonathan mond
    ]
  : [
      'auth0|640c3f7a61321e3bfd5bc1f0', // jacobus van heerden
      'auth0|640c3f785901b450db69b7b0', // louis van der bank
      'auth0|640c407217ccc7185712d6c6', // larissa engel-fiorelli
      'auth0|64182660e19e98fa54bd3a19' // rhys paul
    ];

const SomeoneHealthMeetTeam = () => {
  const someoneHealthClientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage);

  // featured psychologists
  const filterQuery = useMemo(() => ({ clinicianIds: FEATURED_PSYCHOLOGISTS_CLINICIAN_IDS.join(',') }), []);
  const { psychologistList: featuredPsychologistsList, isPractitionerListLoading: isFeaturedPsychologistsListLoading } =
    useFetchPractitionerList({
      clientTimeZone: someoneHealthClientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
      filterQuery
    });

  const [activeId, setActiveId] = useState<string>();

  useEffect(() => {
    setActiveId(featuredPsychologistsList[0]?._id);
  }, [featuredPsychologistsList]);

  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Search over 200 qualified psychologists</div>
      </div>
      <div className={styles.container}>
        {isFeaturedPsychologistsListLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className={styles.menuWrapper}>
              {featuredPsychologistsList.map((clinician, index) => {
                return (
                  <SomeoneHealthMeetTeamMenu
                    key={index}
                    avatar={clinician.avatar}
                    name={clinician.name}
                    // desc={clinician.desc}
                    isSelected={activeId === clinician._id}
                    onSelect={() => setActiveId(clinician._id)}
                  />
                );
              })}
            </div>
            <div className={styles.scrollWrapper}>
              <div className={styles.mobileMenuWrapper}>
                {featuredPsychologistsList.map((clinician, index) => {
                  return (
                    <SomeoneHealthMeetTeamMenu
                      key={index}
                      avatar={clinician.avatar}
                      name={clinician.name}
                      // desc={clinician.desc}
                      mobileCard
                      isSelected={activeId === clinician._id}
                      onSelect={() => setActiveId(clinician._id)}
                    />
                  );
                })}
              </div>
            </div>
            {featuredPsychologistsList.map((clinician, index) => {
              return (
                <SomeoneHealthMeetTeamContentBody
                  key={index}
                  clinicianId={clinician._id}
                  name={clinician.name}
                  clinicianDetails={clinician}
                  isSelected={activeId === clinician._id}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default SomeoneHealthMeetTeam;
