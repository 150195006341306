export enum AppointmentStatusOption {
  Attended = 'Attended',
  DidNotAttend = 'Did Not Attend',
  ClientDidNotAttend = 'Client Did Not Attend',
  ClinicianDidNotAttend = 'Clinician Did Not Attend',
  RescheduledOnce = 'Rescheduled Once',
  RescheduledTwice = 'Rescheduled Twice',
  InvoiceSent = 'Invoice Sent',
  InvoicePaid = 'Invoice Paid',
  DepositPaid = 'Deposit Paid',
  DepositRequired = 'Deposit Required'
}
