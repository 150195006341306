import moment from 'moment';
import styles from './AssessmentWidget.module.scss';
import ScoreChart from './components/ScoreChart/ScoreChart';

interface AssessmentWidgetProps {
  assessmentData?: any;
}

const AssessmentWidget = ({ assessmentData }: AssessmentWidgetProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {
          <div className={styles.headerContainer}>
            <div className={styles.title}>{assessmentData.id}</div>
            <div className={styles.date}>{moment(assessmentData.createdAt).format(`dddd DD MMM [at] H:mm`)}</div>
          </div>
        }
        {assessmentData.charts.map((chartData: any, index: number) => (
          <ScoreChart key={index} chart={chartData} showAlternateColor={assessmentData.id === 'CA'} />
        ))}
      </div>
    </div>
  );
};

export default AssessmentWidget;
