import { BentResponse } from 'bent';
import { httpClient } from '../../../httpClient';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getClinician = (slugUrlOrId: string) =>
  httpClient('GET', url!, 200)(`/clinician/${slugUrlOrId}`) as Promise<BentResponse>;

export const putClinicianClientRecordProfileBackground = (
  token: string,
  clinicianId: string,
  clinicianRecordId: string,
  clinicianProfileId: string,
  body: { background: Record<string, any> }
) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/clinicians/${clinicianId}/client-records/${clinicianRecordId}/client-profiles/${clinicianProfileId}/background`,
    body
  );
