import styles from './SomeoneHealthMeetTeamContentBody.module.scss';
import classNames from 'classnames';
import quoteIcon from '../../../../assets/images/landingPage/Quote.svg';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import ReactTooltip from 'react-tooltip';
import { getRoleLabel } from '../../../PsychologistBonaFides/PsychologistBonaFides';

interface SomeoneHealthMeetTeamContentBodyProps {
  clinicianId: string;
  isSelected: boolean;
  name: string;
  clinicianDetails: PractitionerDetailsInterface;
}

const SomeoneHealthMeetTeamContentBody = ({
  clinicianId,
  isSelected,
  name,
  clinicianDetails
}: SomeoneHealthMeetTeamContentBodyProps) => {
  const navigate = useNavigate();
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();

  const qualificationValue = clinicianDetails.helmControl.bonaFides?.qualifications?.value?.slice(0, 2) ?? [];

  return (
    <div className={styles.container}>
      <div className={classNames(styles.wrapper, !isSelected && styles.hiddenWrapper)}>
        <div
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${clinicianDetails.helmControl.appointmentPhoto})` }}
        />
        <div>
          <img className={styles.quoteMark} src={quoteIcon} alt="Opening quotation mark" />
          <img className={styles.quoteMark} src={quoteIcon} alt="Opening quotation mark" />
        </div>
        <div className={styles.quote}>{clinicianDetails.helmControl.quote}</div>
        <div className={styles.closeQuote}>
          <img className={styles.quoteMark} src={quoteIcon} alt="Closing quotation mark" />
          <img className={styles.quoteMark} src={quoteIcon} alt="Closing quotation mark" />
        </div>
        <div className={styles.detailWrapper}>
          {clinicianDetails.medicare?.role && (
            <div className={styles.detail}>
              <i className={`material-icons ${styles.icon}`}>supervised_user_circle</i>
              <div className={styles.detailLabel}>{getRoleLabel(clinicianDetails.medicare.role)}</div>
              <i data-tip data-for={clinicianDetails._id} className={`material-icons-outlined ${styles.verifiedIcon}`}>
                verified
              </i>
              <ReactTooltip id={clinicianDetails._id} className={styles.tooltip}>
                someone.health psychologists are fully registered and individually approved by our team.
              </ReactTooltip>
            </div>
          )}
          {clinicianDetails.helmControl.bonaFides?.yearsOfExperience?.active && (
            <div className={styles.detail}>
              <i className={`material-icons ${styles.icon}`}>work</i>
              <div className={styles.detailLabel}>
                {clinicianDetails.helmControl.bonaFides.yearsOfExperience.value} Years
              </div>
            </div>
          )}
          {clinicianDetails.helmControl.bonaFides?.qualifications?.active &&
            qualificationValue.map((qualificationObj, index) => (
              <div key={index} className={styles.detail}>
                <div className={`material-icons ${styles.icon}`}>school</div>
                <div className={styles.detailLabel}>{qualificationObj}</div>
              </div>
            ))}
        </div>
        <ButtonSH
          className={styles.navigateButton}
          icon="navigate_next"
          iconPostFix
          onClick={() => navigate(generatePath(PRACTITIONER.DETAILS, { clinicianId }))}
        >
          See {name}'s Profile
        </ButtonSH>
      </div>
    </div>
  );
};

export default SomeoneHealthMeetTeamContentBody;
