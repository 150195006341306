export enum CaWMentalHealthList {
  Anxious = 'anxious',
  Gambling = 'gambling'
}

export const CAW_MENTAL_HEALTH_LIST_LABELS: Record<CaWMentalHealthList, string> = {
  [CaWMentalHealthList.Anxious]: 'Anxious',
  [CaWMentalHealthList.Gambling]: 'Gambling'
};

export enum AppointmentType {
  InitialConsult = 'initialConsult'
}

export const AppointmentTypeList = [
  {
    id: AppointmentType.InitialConsult,
    label: 'Initial Consult'
  }
];
