import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import { useLocation, useParams } from 'react-router-dom';
import { Clinician, useFetchClinician } from 'utils/hooks/clinician';
import styles from './AvailableAppointmentTypes.module.scss';
import AppointmentHeaderV2 from '../components/AppointmentHeaderV2/AppointmentHeaderV2';
import ClinicianInformation from './components/ClinicianInformation/ClinicianInformation';
import AvailableAppointmentTypesList from './components/AvailableAppointmentTypesList/AvailableAppointmentTypesList';
import { useFetchAvailabilityAppointmentTypes } from 'utils/hooks/appointment';
export interface ClinicianAndPatient {
  clinician: Clinician;
  patient: {
    name: string;
    avatar: string;
  };
}

const AvailableAppointmentTypes = () => {
  const { clinicianId = '' } = useParams<{ clinicianId: string }>();
  const isNewClient = useLocation()
    .pathname.split('/')
    .some((path) => path === 'new');
  const { clinician, isClinicianLoading } = useFetchClinician(clinicianId);
  const { availabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } = useFetchAvailabilityAppointmentTypes({
    accountId: clinician?.accountId,
    clinicianId: clinician?._id
  });

  const appointmentTypes = availabilityAppointmentTypes?.filter((appointmentType) =>
    isNewClient ? appointmentType.bookingRules?.new.available : appointmentType.bookingRules?.existing.available
  );

  return (
    <PatientContentLayout>
      {isClinicianLoading || isAvailabilityAppointmentTypesLoading || !clinician || !appointmentTypes ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <AppointmentHeaderV2 backUrl={`/profile/${clinicianId}`} />
          </div>
          <div className={styles.ClinicianInfor}>
            <ClinicianInformation
              profileName={clinician.name}
              profileImage={clinician.avatar}
              listAppointmentLength={appointmentTypes.length}
            />
          </div>
          <div className={styles.divider} />
          <AvailableAppointmentTypesList
            availabilityAppointmentTypes={appointmentTypes}
            clinician={clinician}
            isNewClient={isNewClient}
          />
        </>
      )}
    </PatientContentLayout>
  );
};
export default AvailableAppointmentTypes;
