import { Section } from 'interfaces/Assessment/OnboardingAssessment';

import Question from '../../../Question/Question';

import styles from './Section.module.scss';

interface SectionComponentProps {
  section: Section;
  questionId: string;
  defaultPatientDetails?: { name: string; picture: string };
}

const SectionComponent = ({ section, questionId, defaultPatientDetails }: SectionComponentProps) => {
  const { name, questions, description } = section;

  return (
    <>
      {name && <div className={styles.name} dangerouslySetInnerHTML={{ __html: name }} />}
      {description && <div className={styles.desc} dangerouslySetInnerHTML={{ __html: description }} />}
      {questions.map((question, index) => (
        <Question
          questionId={questionId}
          key={index}
          question={question}
          defaultPatientDetails={defaultPatientDetails}
        />
      ))}
    </>
  );
};

export default SectionComponent;
