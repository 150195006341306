import { Route, Routes } from 'react-router-dom';
import FacilitatedRoutes from '../FacilitatedRoutes';
import SelectRoutes from './SelectRoutes';
import appRoute from '../../components/AppRoute/AppRoute';
import SelectLoginWrapper from 'Select/components/SelectLoginWrapper/SelectLoginWrapper';

const SelectRoutesCollection = () => (
  <Routes>
    {appRoute({ path: '/facilitated/*', component: FacilitatedRoutes, layout: SelectLoginWrapper })}
    <Route path={'/*'} element={<SelectRoutes />} />
  </Routes>
);

export default SelectRoutesCollection;
