import styles from './InfoSetup.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import ButtonPortobello, { ButtonStatusType } from '../../components/ButtonPortobello/ButtonPortobello';
import { usePortobelloToken } from 'Portobello/utils/hooks/usePortobelloToken';
import { AddressForm } from 'components/UpdateProfileContent/components/AddressForm/AddressForm';
import { useCallback, useState } from 'react';
import { PatientAddress } from 'utils/http/PatientProfileService/Patient/patient';
import { postStripePaymentMethod } from 'utils/http/BillingService/stripe';
import { notification } from 'antd';
import { usePortobelloRoutesGenerator } from 'Portobello/utils/path/PortobelloRoutesGenerator';
import EmergencyContactForm, { EmergencyContactField } from './components/EmergencyContactForm/EmergencyContactForm';
import { PortobelloInfoErrorInterface, validationInfoForm } from './constants';
import { postKeyContact, putClientAddress } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { scrollErrorsIntoView } from 'utils/scrollToView';
import { useTranslation } from 'react-i18next';

const InfoSetup = () => {
  const { token } = usePortobelloToken();
  const { SIGNUP } = usePortobelloRoutesGenerator();
  const [t] = useTranslation();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');
  const [addressValue, setAddressValue] = useState<PatientAddress>({});
  const [emergencyContactValue, setEmergencyContactValue] = useState<EmergencyContactField>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    relationship: ''
  });
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [errors, setErrors] = useState<PortobelloInfoErrorInterface>();

  const validateField = useCallback(
    (newVal: PortobelloInfoErrorInterface) => {
      const { validationErrors, cleanValidationError } = validationInfoForm(newVal, t);
      setErrors(validationErrors);
      return cleanValidationError;
    },
    [t]
  );

  const handleAddressAutoComplete = (address: PatientAddress) => {
    setAddressValue((addressValue) => {
      return { ...addressValue, ...address };
    });
  };

  const handleChangeValues = (key: keyof PatientAddress, value: string) => {
    setAddressValue((values) => {
      return { ...values, [key]: value };
    });

    const newAddress = { ...addressValue, [key]: value };
    validateField({ ...emergencyContactValue, ...newAddress });
  };

  const handleChangeEmergencyContact = (value: EmergencyContactField) => {
    setEmergencyContactValue(value);
    validateField({ ...addressValue, ...value });
  };

  const handleSubmitInfo = async () => {
    setCheckValidation(true);

    const validate = validateField({ ...addressValue, ...emergencyContactValue });
    scrollErrorsIntoView(validate);
    const checkFieldHaveError = Object.values(validate).some((value) => value !== '');

    if (checkFieldHaveError || !token) {
      return;
    }
    setButtonStatus('active');
    //call emergency api
    await postKeyContact(token, emergencyContactValue);

    //call address api
    await putClientAddress(token, addressValue);

    try {
      const response = await postStripePaymentMethod({
        token: token,
        payload: {
          returnUrl: `${window.location.origin}${SIGNUP.WELCOME}`,
          cancelUrl: `${window.location.origin}${SIGNUP.INFO_SETUP}`
        }
      });
      setButtonStatus('finished');
      window.location.href = await response.text();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to generate stripe link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <PortobelloHelmetWrapper title={'Portobello Behavioural Health | Additional information setup'}>
      <PortobelloContentLayout className="portobello-theme">
        <div className={styles.headerWrapper}>
          <PortobelloHeader withPadding className={styles.header} />
        </div>
        <div className={styles.container}>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Emergency Contact*</div>
            <div className={styles.desc}>
              To provide you with support we need you to provide the detail of one emergency contact. We would only
              contact this person should we be concerned about your safety. You can add additional contacts, and update
              information in your client profile.
            </div>
            <EmergencyContactForm
              values={emergencyContactValue}
              onChangeValue={handleChangeEmergencyContact}
              checkValidation={checkValidation}
              errors={errors}
            />
          </div>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Address*</div>
            <div className={styles.desc}>
              We need your primary address as part of assessing our ability to offer you the right services
            </div>
            <AddressForm
              values={addressValue}
              errors={checkValidation ? errors : undefined}
              onAddressAutoComplete={handleAddressAutoComplete}
              handleChangeValues={handleChangeValues}
              formClassName={styles.formStyles}
              inputClassName={styles.inputStyles}
            />
          </div>
          <div className={styles.infoBoxContainer}>
            <div className={styles.title}>Add payment details</div>
            <div className={styles.desc}>
              Please provide details of your credit or debit card, it will be encrypted and securely be kept on file.{' '}
              <br />
              <br />
              For clients having standalone therapy this card will be used to take payment following each session. The
              card will not be debited for clients who are case managed or who have coaching. However, we reserve the
              right to debit the card if invoices remain unpaid following all reminders being sent. <br />
              <br />
              You can update your card details at any time through the self service portal.
            </div>
          </div>
          <ButtonPortobello
            status={buttonStatus}
            className={styles.submitButton}
            type="submit"
            onClick={handleSubmitInfo}
          >
            Continue to save my payment details
          </ButtonPortobello>
        </div>
      </PortobelloContentLayout>
      <TacklitFooter darkFooter />
    </PortobelloHelmetWrapper>
  );
};

export default InfoSetup;
