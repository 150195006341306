import { AvailabilityAppointmentTypes, DeliveryType, OtherInstructions } from './hooks/appointment';

// if has PhoneCall return PhoneCall
// if has PhoneCallDialClient return PhoneCallDialClient
export const getAppointmentTypePhoneCallDeliveryMode = (
  deliveryOptions: DeliveryType[]
): DeliveryType.PhoneCall | DeliveryType.PhoneCallDialClient | undefined =>
  (deliveryOptions.includes(DeliveryType.PhoneCall) && DeliveryType.PhoneCall) ||
  (deliveryOptions.includes(DeliveryType.PhoneCallDialClient) && DeliveryType.PhoneCallDialClient) ||
  undefined;

export const checkAppointmentTypeDeliveryOptionAvailability = (
  deliveryOptions: DeliveryType[],
  selectedDeliveryOption: DeliveryType
): boolean => {
  if (selectedDeliveryOption === DeliveryType.PhoneCall) {
    return [DeliveryType.PhoneCall, DeliveryType.PhoneCallDialClient].some((deliveryType) =>
      deliveryOptions.includes(deliveryType)
    );
  }

  return deliveryOptions.includes(selectedDeliveryOption);
};

// return VideoCall if exists
// else if first delivery option is PhoneCallDialClient,
//  if PhoneCall exists in deliveryOptions, return PhoneCall
// return first delivery option
export const getEngageDefaultAppointmentDeliveryType = (deliveryOptions: DeliveryType[]) => {
  const defaultDeliveryType = deliveryOptions.find((item) => item === DeliveryType.VideoCall) || deliveryOptions[0];

  return defaultDeliveryType === DeliveryType.PhoneCallDialClient && deliveryOptions.includes(DeliveryType.PhoneCall)
    ? DeliveryType.PhoneCall
    : defaultDeliveryType;
};

const DeliveryIconMapping: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'face',
  [DeliveryType.PhoneCall]: 'phone_forwarded',
  [DeliveryType.PhoneCallDialClient]: 'phone_forwarded',
  [DeliveryType.VideoCall]: 'video_camera_front',
  [DeliveryType.Other]: 'connect_without_contact'
};

export const generateEngageConsultPreferences = ({
  selectedAppointmentType,
  selectedDeliveryPreference
}: {
  selectedAppointmentType?: AvailabilityAppointmentTypes;
  selectedDeliveryPreference: DeliveryType;
}) => {
  if (!selectedAppointmentType) {
    return [];
  }

  const consultPreferences: DeliveryType[] = [];

  if (selectedAppointmentType.deliveryOptions.some((deliveryOption) => deliveryOption === DeliveryType.VideoCall)) {
    consultPreferences.push(DeliveryType.VideoCall);
  }

  const phoneCallDeliveryMode = getAppointmentTypePhoneCallDeliveryMode(selectedAppointmentType?.deliveryOptions || []);

  if (phoneCallDeliveryMode) {
    consultPreferences.push(phoneCallDeliveryMode);
  }

  if (selectedAppointmentType.deliveryOptions.some((deliveryOption) => deliveryOption === DeliveryType.FaceToFace)) {
    consultPreferences.push(DeliveryType.FaceToFace);
  }

  if (selectedAppointmentType.deliveryOptions.some((deliveryOption) => deliveryOption === DeliveryType.Other)) {
    consultPreferences.push(DeliveryType.Other);
  }

  return consultPreferences.map((item: DeliveryType) => ({
    id: item,
    label: DeliveryIconMapping[item],
    isActive: selectedDeliveryPreference === item,
    isIcon: true
  }));
};

export const DELIVERY_TYPE_LABELS: Record<DeliveryType, string> = {
  [DeliveryType.FaceToFace]: 'Face to face',
  [DeliveryType.PhoneCall]: 'Phone call',
  [DeliveryType.PhoneCallDialClient]: 'Phone call',
  [DeliveryType.VideoCall]: 'Video call',
  [DeliveryType.Other]: 'Other'
};

export const getDeliveryTypeLabel = (deliveryType?: string, otherInstructions?: OtherInstructions) => {
  switch (deliveryType) {
    // use title if exists, fallback to delivery type label if somehow fails
    case DeliveryType.Other:
      return otherInstructions?.title || DELIVERY_TYPE_LABELS[deliveryType];
    case DeliveryType.FaceToFace:
    case DeliveryType.PhoneCall:
    case DeliveryType.PhoneCallDialClient:
    case DeliveryType.VideoCall:
      return DELIVERY_TYPE_LABELS[deliveryType];

    default:
      return '';
  }
};
