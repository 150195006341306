import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import SomeoneHealthHelmetWrapper from '../../components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthHeader from '../../components/SomeoneHealthHeader/SomeoneHealthHeader';
import { SOMEONE_HEALTH_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import { useSomeoneHealthPractitionerUrl } from 'SomeoneHealth/utils/Path/useSomeoneHealthPractitionerUrl';
import { config } from 'config/config';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();
  const timeZone = clientProfileData?.timeZone || config.defaultTimezone;
  const timeZoneLocation = SOMEONE_HEALTH_TIME_ZONE_LIST.find(({ id }) => id === timeZone)?.label;
  const appointmentPath = useSomeoneHealthPractitionerUrl({
    noParams: true
  });

  return (
    <SomeoneHealthHelmetWrapper title="someone.health - Appointments">
      <PatientContentLayout className={styles.container}>
        <div className={styles.contentWrapper}>
          <SomeoneHealthHeader withPadding withMenu homeLink />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>Your Appointment Overview</div>
              {timeZoneLocation && <div className={styles.timeZone}>Time in {timeZoneLocation}</div>}
            </div>
            <AppointmentList
              isPatientProfileLoading={isClientProfileLoading}
              clientProfileTimeZone={clientProfileData?.timeZone}
              className={styles.contentCardShadow}
              newAppointmentPath={appointmentPath}
            />
          </div>
        </div>
      </PatientContentLayout>
    </SomeoneHealthHelmetWrapper>
  );
};

export default AppointmentsOverview;
