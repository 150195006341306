import CheckBox from 'components/CheckBox/CheckBox';
import Loading from 'components/Loading/Loading';
import { useFetchHelmReservedAppointmentsWithTimeZone } from 'helm/utils/hooks/appointment';
import { useContext, useEffect, useRef, useState } from 'react';
import ButtonHelm, { ButtonStatusType } from 'helm/components/ButtonHelm/ButtonHelm';
import PartnerBookingSummaryItem from './components/PartnerBookingSummaryItem/PartnerBookingSummaryItem';
import { reservedHelmPartnerReferral, useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import styles from './PartnerBookingSummary.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { UserContext } from 'utils/UserContextProvider';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import { PARTNER_HELM_SIGN_UP_USER, retrieveUserSession } from 'helm/pages/CreateProfile/helper/userSession';
import { putProcessWithoutPayment } from 'utils/http/appointment';
import { useHelmPartnerRoutesGenerator } from '../../utils/Path/HelmPartnerRoutesGenerator';
import { useHelmToken } from 'helm/utils/hooks/useHelmToken';
import HelmHelmetWrapper from '../../../components/HelmHelmetWrapper/HelmHelmetWrapper';
import HelmHeader from '../../../components/HelmHeader/HelmHeader/HelmHeader';
import HelmContentLayout from '../../../components/HelmContentLayout/HelmContentLayout';
import HelmTimer from '../../../components/HelmTimer/HelmTimer';
import { AU_TIME_ZONE_LIST, helmTimeZoneLocalStorage } from 'utils/constants/timeZone';

const PartnerBookingSummary = () => {
  const clientTimeZone = localStorage.getItem(helmTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const { token } = useHelmToken(PARTNER_HELM_SIGN_UP_USER);
  const navigate = useNavigate();
  const { clientProfile } = useContext(UserContext);

  const { SIGNUP } = useHelmPartnerRoutesGenerator();
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [wisePolicyChecked, setWisePolicyChecked] = useState(false);
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession(PARTNER_HELM_SIGN_UP_USER).clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData(reservedHelmPartnerReferral);
  const { fetching, appointments } = useFetchHelmReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        await fetchClaimingReservation(reserveId, accountId, token);

        const res = await putProcessWithoutPayment(accountId, reserveId, token);

        if (res.statusCode === 204) {
          setConfirmBookingStatus('finished');
          navigate(SIGNUP.CONFIRM_BOOKING);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <HelmHelmetWrapper title={`HELM -  Profile`}>
        <HelmHeader withPadding whiteFont withMenu classNames={styles.header} noLogin />
        <HelmContentLayout className={styles.layoutClassName}>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'helm-theme')}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <div className={styles.greet}>You’re about to confirm the following booking.</div>
                  <div className={styles.subHeader}>
                    <div className={styles.name}>
                      For{' '}
                      <strong>
                        {clientProfile
                          ? `${clientProfile.firstName} ${clientProfile.lastName}`
                          : `${retrieveUserProfile?.[0]?.firstName} ${retrieveUserProfile?.[0]?.lastName}`}
                      </strong>
                    </div>
                  </div>
                  <PartnerBookingSummaryItem
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    containerClassName={styles.summaryContainer}
                    contentClassName={styles.summaryContent}
                  />
                  <div className={styles.cancellationPolicy}>
                    <div className={styles.title}>IMPORTANT INFORMATION</div>
                    <p className={styles.content}>
                      We are providing your information securely to Helm to facilitate the delivery of this appointment.
                      They will provide you with more details before your session commences
                    </p>
                  </div>
                  <CheckBox
                    id="policyAccepted"
                    value={policyAccepted}
                    onChange={(event) => setPolicyAccepted(event.target.checked)}
                    className={styles.checkBox}
                    label="I have read the important information to the client and they have acknowledged and accepted"
                  />
                  <CheckBox
                    id="wisePolicyChecked"
                    value={wisePolicyChecked}
                    onChange={(event) => setWisePolicyChecked(event.target.checked)}
                    className={styles.checkBox}
                    label="My WISE case manager has explained the important information to me and I have acknowledged and accepted"
                  />
                  <ButtonHelm
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={(!policyAccepted && !wisePolicyChecked) || !token}
                    className={styles.submitButton}
                  >
                    Confirm Appointment Booking
                  </ButtonHelm>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <HelmTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                      reversedAppointmentId={reservedHelmPartnerReferral}
                    />
                    <div className={styles.noticeMsg}>
                      <div>To confirm these appointments you need to complete registration within 15 minutes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </HelmContentLayout>
        <TacklitFooter />
      </HelmHelmetWrapper>
    </div>
  );
};

export default PartnerBookingSummary;
