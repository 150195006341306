import { useFetchPublicPracticeProfile } from 'utils/hooks/accounts';
import styles from '../../SignUp/SignUp.module.scss';
import Loading from 'components/Loading/Loading';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SHCreateProfileForm from 'SomeoneHealth/components/SHCreateProfileForm/SHCreateProfileForm';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import OCD from 'SomeoneHealth/assets/images/OCD.png';
import { ReactNode, useState } from 'react';
import { getAuMobile, initialCustomer } from 'SomeoneHealth/components/SHCreateProfileForm/constants';
import { notification } from 'antd';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import { defaultClientTimezone, SOMEONE_HEALTH_TIME_ZONE_LIST } from 'utils/constants/timeZone';
import ErrorMessageWithHelpLink from '../../SignUpWithInvite/components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { UserSessionStorage, storeUserSession } from '../../SignUp/helper/userSession';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { ga4EventPush } from 'utils/GAEvent';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';

const DecoupleGPSignUpPage = () => {
  const { clientProfileData } = useGetClientProfileDetails();
  const clientTimeZone = clientProfileData?.timeZone || defaultClientTimezone;

  const navigate = useNavigate();
  const { SIGNUP_DECOUPLE_GP } = useSomeoneHealthRoutesGenerator();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  const { practiceProfile, isPracticeProfileLoading } = useFetchPublicPracticeProfile('someone-health');

  const onSubmitHandle = async (values: typeof initialCustomer) => {
    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);

    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: practiceProfile?.accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, receiveSMS } = values;
          const dob = moment(values.dateOfBirth).format('DD/MM/YYYY');
          const communicationPreferenceLogic = receiveSMS
            ? CommunicationPreference.NoPreference
            : CommunicationPreference.Email;

          const payloadMassage = {
            clinicianId: '',
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              timeZone: clientTimeZone || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id,
              communicationPreference: communicationPreferenceLogic
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(
            practiceProfile?.accountId || '',
            payloadMassage
          );
          const { clientRecord, authToken }: UserSessionStorage = await callPatientSignUp.json();
          ga4EventPush('onSuccessSignUpNoGPClient', {
            clientId: clientRecord._id
          });
          storeUserSession({ clientRecord, authToken, firstName }, dob);
          setIsSubmitting(false);
          navigate(SIGNUP_DECOUPLE_GP.MEDICARE);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(
          <ErrorMessageWithHelpLink
            isFullProfileType={checkEmailExisted?.response?.isFullProfileType}
            errorMessage="Email address is already used."
          />
        );
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
      );
    }
  };

  return isPracticeProfileLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <SomeoneHealthHelmetWrapper title={'someone.health - Sign up'}>
      {isPracticeProfileLoading ? (
        <Loading />
      ) : (
        <SomeoneHealthContentLayout>
          <SomeoneHealthHeader pinkLogo withPadding classNames={styles.header} loginTextClass={styles.loginTextClass} />
          <div className={styles.container}>
            <div className={styles.leftContent}>
              <SHCreateProfileForm
                isSubmitting={isSubmitting}
                emailErrorMsg={emailErrorMsg}
                mobileNumberErrorMsg={mobileNumberErrorMsg}
                setEmailErrorMsg={setEmailErrorMsg}
                setMobileNumberErrorMsg={setMobileNumberErrorMsg}
                onSubmit={onSubmitHandle}
                customAgeCheck={13}
                subHeader={
                  <>
                    Create your <b>secure account</b> to book a GP appointment for a referral to your preferred
                    psychologist.
                  </>
                }
              />
            </div>
            <div className={styles.rightContent}>
              <div className={styles.topNotch}>
                <img src={OCD} alt={'Someone Health top notch'} />
                <div className={styles.topNotchTitle}>Top-notch privacy and security</div>
                <div className={styles.topNotchDesc}>
                  <b>someone.health</b> was built to exceed all regulatory requirements for privacy and security. All
                  data is stored within Australia and encrypted both at-rest and in-transit, with strict access
                  controls.
                </div>
              </div>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      )}
      <TacklitFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default DecoupleGPSignUpPage;
