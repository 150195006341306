import Button from 'components/Button/Button';
import styles from './SQIntro.module.scss';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import classnames from 'classnames';
import ButtonCaW from '../../../../../../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import ButtonPortobello from '../../../../../../../Portobello/components/ButtonPortobello/ButtonPortobello';

interface SQIntroProps {
  introNote?: string;
  clinicianName: string;
  handleStartSurvey: () => void;
}

const SQIntro = ({ introNote, clinicianName, handleStartSurvey }: SQIntroProps) => {
  return (
    <div className={classnames(IS_CAW_APP && 'caw-theme', styles.introContainer)}>
      {introNote ? (
        <div className={`ql-editor ${styles.introNote}`} dangerouslySetInnerHTML={{ __html: introNote }} />
      ) : (
        <div className={styles.introNote}>
          <div className={styles.welcomeTitle}>Welcome.</div>
          <div>
            Please continue with the survey <strong>{clinicianName}</strong> asked you to complete.
          </div>
          <div>We have saved your answers thus far, you can return and complete whenever you like.</div>
        </div>
      )}
      <div className={styles.footerBtnCard}>
        <div className={styles.returnContent}>
          {IS_SOMEONE_HEALTH_APP ? (
            <ButtonSH className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonSH>
          ) : IS_CAW_APP ? (
            <ButtonCaW className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonCaW>
          ) : IS_EASE_APP ? (
            <ButtonEase className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonEase>
          ) : IS_RECHARGE_APP ? (
            <ButtonRecharge className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonRecharge>
          ) : IS_SELECT_APP ? (
            <ButtonSelect className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonSelect>
          ) : IS_PORTOBELLO_APP ? (
            <ButtonPortobello className={styles.shButton} fullWidth onClick={() => handleStartSurvey()}>
              Get Started
            </ButtonPortobello>
          ) : (
            <Button className={styles.button} variant={'primary'} onClick={() => handleStartSurvey()}>
              Get Started
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SQIntro;
