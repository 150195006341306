import classNames from 'classnames';
import styles from './SHSearchBar.module.scss';
import { useRef } from 'react';

interface SHSearchBarProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  vertical?: boolean;
  icon?: string;
  showIcon?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  ableToClear?: boolean;
}

const SHSearchBar = ({
  value,
  onChange,
  label,
  vertical,
  icon,
  showIcon,
  labelClassName,
  containerClassName,
  inputClassName,
  iconClassName,
  ableToClear = true
}: SHSearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFocusHandle = () => {
    if (inputRef.current && inputRef.current.hasAttribute('readOnly')) {
      inputRef.current.removeAttribute('readOnly');

      // For mobile Safari - virtual keyboard issue
      // Need blur() for the first time use focus on input field
      inputRef.current.blur();
      inputRef.current.focus();
    }
  };

  return (
    <div className={classNames(styles.container, vertical && styles.vertical, containerClassName)}>
      {label && <div className={classNames(styles.label, labelClassName)}>{label}</div>}
      <div className={styles.inputContainer}>
        {showIcon && (
          <i className={classNames('material-icons-outlined', styles.icon, iconClassName)}>{icon || 'search'}</i>
        )}
        <input
          ref={inputRef}
          className={classNames(styles.input, inputClassName)}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          autoComplete="off"
          readOnly
          onFocus={onFocusHandle}
        />
        {ableToClear && value && (
          <button className={styles.clearButton} onClick={() => onChange('')}>
            <i className={classNames('material-icons-outlined', styles.clearIcon)}>backspace</i>
          </button>
        )}
      </div>
    </div>
  );
};

export default SHSearchBar;
