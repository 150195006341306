import styles from './PortobelloWrapper.module.scss';
import classNames from 'classnames';

interface PortobelloWrapperProps {
  children?: any;
  colorBg?: boolean;
}

const PortobelloWrapper = ({ children, colorBg }: PortobelloWrapperProps) => (
  <div className={classNames(styles.container, colorBg && styles.colorBg)}>{children}</div>
);

export default PortobelloWrapper;
