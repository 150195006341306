import classNames from 'classnames';
import styles from './MobilePhoneInput.module.scss';
import { ChangeEvent, FocusEvent, ReactNode, useCallback, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import CountryCodeSelect from './components/CountryCodeSelect/CountryCodeSelect';
import { getMobileNumberCountry, getRawMobileNumber } from './mobileNumber';
import { config } from 'config/config';
import parsePhoneNumber, { CountryCode, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

interface MobilePhoneInputProps {
  id?: string;
  inputClass?: string;
  label: string;
  value: string;
  error?: ReactNode | string;
  onChange: (value: string) => void;
  onError?: (hasError: boolean) => void;
  checkValidation?: boolean;
}

const MobilePhoneInput = ({
  id,
  inputClass,
  label,
  value,
  error: propsError,
  onChange,
  onError,
  checkValidation = true
}: MobilePhoneInputProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    getMobileNumberCountry(value) || config.countryCode.toUpperCase()
  );
  const [rawMobileNumber, setRawMobileNumber] = useState(getRawMobileNumber(value));

  const [error, setError] = useState('');

  const validatePhoneNumber = useCallback(
    (phoneNumber: string, countryCode?: string) => {
      const updateError = (error: string = '') => {
        setError(error);
        onError?.(!!error);
      };

      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, (countryCode as CountryCode) || undefined);

      // parsedPhoneNumber.formatInternational includes country code and removes unnecessary 0 at start if exists
      const formattedNumber = parsedPhoneNumber?.formatInternational().replaceAll(/[^\d+]/g, '') || phoneNumber;

      onChange(formattedNumber);

      if (phoneNumber === '') {
        updateError('Please enter your mobile number');
        return;
      }

      if (parsedPhoneNumber?.country !== countryCode) {
        updateError('Invalid number');
        return;
      }

      const phoneNumberLengthValidation = validatePhoneNumberLength(
        phoneNumber,
        (countryCode as CountryCode) || undefined
      );

      if (phoneNumberLengthValidation) {
        switch (phoneNumberLengthValidation) {
          case 'NOT_A_NUMBER':
            updateError('Please enter numbers only');
            return;
          case 'TOO_LONG':
            updateError('Number is too long');
            return;
          case 'TOO_SHORT':
            updateError('Number is too short');
            return;
          case 'INVALID_COUNTRY':
            console.error(`Error while parsing country code ${countryCode}`);
            updateError('Error while parsing this mobile number with country code, please contact an administrator');
            return;
        }
      }

      // isValidPhoneNumber checks length as well as format, so we check length first
      const isValid = isValidPhoneNumber(phoneNumber, (countryCode as CountryCode) || undefined);

      if (!isValid) {
        updateError('Invalid mobile number');
        return;
      }

      updateError();
    },
    [onChange, onError]
  );

  const handleMobileNumberBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      validatePhoneNumber(e.target.value.replaceAll(/\D/g, ''), selectedCountryCode);
    }
  };

  const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = e.target.value.replaceAll(/\D/g, '');

    setRawMobileNumber(formattedNumber);
    validatePhoneNumber(formattedNumber, selectedCountryCode);
  };

  const hasError = !!(error || propsError);

  return (
    <div className={classNames(styles.container, hasError && checkValidation && styles.error)} id={id}>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={`${id}-input`}>
          {label}
        </label>
        <CountryCodeSelect
          id={id}
          value={selectedCountryCode}
          onChange={(value) => {
            validatePhoneNumber(rawMobileNumber, value);
            setSelectedCountryCode(value);
          }}
        />
      </div>
      <input
        className={classNames(styles.input, inputClass)}
        id={`${id}-input`}
        type="tel"
        placeholder={config.contactMaskNumberWithX}
        value={rawMobileNumber}
        onBlur={handleMobileNumberBlur}
        onChange={handleMobileNumberChange}
      />
      <ErrorMessage error={error || propsError} visible={hasError && checkValidation} />
    </div>
  );
};

export default MobilePhoneInput;
