import { Link } from 'react-router-dom';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';

import styles from './PsychologistDetailsHeader.module.scss';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';

interface PractitionerDetailsHeaderProps {
  practitionerDetails: PractitionerDetailsInterface;
  listingLink: string;
  noLogin?: boolean;
}

const PsychologistDetailsHeader = ({ practitionerDetails, listingLink, noLogin }: PractitionerDetailsHeaderProps) => {
  return (
    <div className={styles.container}>
      <RechargeHeader
        withPadding
        noWrapper
        withMenu
        noLogin={noLogin}
        loginTextClass={styles.headerLoginTextClass}
        whiteFont
        whiteMenu
      />
      <RechargeContentLayout className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.middleWrapper}>
            <div className={styles.headerImageContainer}>
              {practitionerDetails.helmControl?.headerPhoto && (
                <img
                  alt={'heading_photo'}
                  className={styles.headerImage}
                  src={practitionerDetails.helmControl.headerPhoto}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.overlay}>
          <div className={styles.detailsWrapper}>
            <div className={styles.actionWrapper}>
              <Link to={listingLink} className={styles.otherPractitioner}>
                <i className={`material-icons ${styles.icon}`}>arrow_back</i>
                <div className={styles.label}>SEE OTHER COUNSELLORS</div>
              </Link>
            </div>
            <div className={styles.name}>{practitionerDetails.name}</div>
          </div>
        </div>
      </RechargeContentLayout>
    </div>
  );
};

export default PsychologistDetailsHeader;
