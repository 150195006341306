import { ChangeEvent } from 'react';
import { useField } from 'formik';
import { Question } from 'interfaces/Assessment/OnboardingAssessment';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import MaterialInput from 'components/MaterialInput/MaterialInput';

interface FreeTextProps {
  question: Question;
}

const Email = ({ question }: FreeTextProps) => {
  const [{ value }, { error }, { setValue }] = useField(`${question.id}.value`);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <>
      <MaterialInput label={''} id={'email'} value={value} onChange={handleChange} maxLength={200} required />
      <ErrorMessage error={error} visible={!!error} />
    </>
  );
};

export default Email;
