import { httpClient } from 'utils/httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const getSomeoneHealthClinicianListing = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/someone-health/clinicians?${queryParam}`) as Promise<BentResponse>;

export const getSomeoneHealthClinicianDetails = (clinicianId: string) =>
  httpClient('GET', url!, 200)(`/accounts/someone-health/clinicians/${clinicianId}`) as Promise<BentResponse>;

export const getPsychologistsMinified = (queryParam?: string) =>
  httpClient('GET', url!, 200)(`/accounts/someone-health/clinicians/minified?${queryParam}`) as Promise<BentResponse>;
