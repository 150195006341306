import styles from './SelectHeader.module.scss';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LoginAvatar from 'Select/components/LoginAvatar/LoginAvatar';
import SelectContentLayout from '../SelectContentLayout/SelectContentLayout';
import selectLogo from 'Select/assets/images/logo/logo.png';
import { SelectUrl } from 'Select/utils/env/SelectEnv';

interface SelectHeaderProps {
  noWrapper?: boolean;
  whiteFont?: boolean;
  withPadding?: boolean;
  withMenu?: boolean;
  homeLink?: boolean;
  noLogin?: boolean;
  className?: string;
  loginTextClass?: string;
  whiteMenu?: boolean;
}

const SelectHeader = ({
  noWrapper,
  whiteFont,
  withPadding,
  withMenu,
  homeLink,
  noLogin,
  className,
  loginTextClass,
  whiteMenu
}: SelectHeaderProps) => {
  const navigate = useNavigate();

  const handleRedirection = () => {
    if (homeLink) {
      navigate('/');
    } else {
      window.location.href = SelectUrl;
    }
  };

  return (
    <SelectContentLayout
      className={classnames(noWrapper && styles.noWrapperContainer, withPadding && styles.padding, className)}
    >
      <div id={'SelectHeader'} className={styles.logoWrapper}>
        <div className={styles.logoBox} onClick={handleRedirection}>
          <img className={styles.logo} alt={'logo'} src={selectLogo} />
        </div>
        <div className={styles.rightContent}>
          {!noLogin && (
            <LoginAvatar
              whiteFont={whiteFont}
              whiteMenu={whiteMenu}
              withMenu={withMenu}
              loginTextClass={loginTextClass}
            />
          )}
        </div>
      </div>
    </SelectContentLayout>
  );
};

export default SelectHeader;
