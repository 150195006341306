import { useState } from 'react';
import { notification } from 'antd';

import { postConsentFormSendOtp } from 'utils/http/DocumentService/ConsentForm/consentForm';

export const useSendOtp = (consentId: string, setIsConsentFormCompleted: (completed: boolean) => void) => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpSentLoading, setIsOtpSentLoading] = useState(false);

  const sendOtp = async () => {
    setIsOtpSentLoading(true);

    try {
      const request = await postConsentFormSendOtp(consentId);

      if (request.statusCode === 200) {
        setIsOtpSent(true);
      } else if (request.statusCode === 204) {
        setIsConsentFormCompleted(true);
      }
    } catch (ex) {
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to send an OTP to you. Please try again later.'
      });
    }

    setIsOtpSentLoading(false);
  };

  return { isOtpSent, isOtpSentLoading, sendOtp };
};
