import styles from './SuggestionBox.module.scss';
import TaskButton from 'Select/components/TaskButton/TaskButton';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';

interface SuggestionBoxProps {
  bookNewAppointmentUrl: string;
}

const SuggestionBox = ({ bookNewAppointmentUrl }: SuggestionBoxProps) => {
  const { UPDATE_PROFILE, APPOINTMENT_OVERVIEW } = useSelectRoutesGenerator();

  const menuItems = [
    {
      name: 'Book a new psychology appointment',
      url: bookNewAppointmentUrl,
      isExternal: false
    },
    { name: 'View my appointments & invoices', url: APPOINTMENT_OVERVIEW, isExternal: false },
    { name: 'Manage my profile', url: UPDATE_PROFILE, isExternal: false }
  ];

  return (
    <>
      <div className={styles.actionWrapper}>
        <div className={styles.title}>Would you like to...</div>
        <div className={styles.menuItemWrapper}>
          {menuItems.slice(0, 1).map((item, index) => (
            <TaskButton className={styles.taskButton} key={index} item={item} />
          ))}
        </div>
      </div>
      <div className={styles.actionWrapper}>
        <div className={styles.title}>Or...</div>
        <div className={styles.menuItemWrapper}>
          {menuItems.slice(1, 3).map((item, index) => (
            <TaskButton className={styles.taskButton} key={index} item={item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SuggestionBox;
