import { useState, useEffect } from 'react';
import { getGoogleCloudStorageObjects } from 'utils/http/checkIn';

const IS_DEVELOPMENT = process.env.REACT_APP_GAE_PROJECT_ID === 'radiance-284806';

export const useFetchBackgroundImageUrl = (clientId: string, useCheckInBackground: boolean) => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | undefined>(undefined);
  const [isBackgroundImageUrlLoading, setIsBackgroundImageUrlLoading] = useState(true);

  const fetchBackgroundImageUrl = async () => {
    setIsBackgroundImageUrlLoading(true);

    if (useCheckInBackground) {
      const bucketName = `${
        !IS_DEVELOPMENT && process.env.REACT_APP_GAE_REGION ? `${process.env.REACT_APP_GAE_REGION}-` : ''
      }client-backgrounds`;

      try {
        const googleCloudStorageObjects = (await (await getGoogleCloudStorageObjects(bucketName, clientId)).json()) as {
          items: { name: string; size: string }[];
        };

        if (googleCloudStorageObjects && googleCloudStorageObjects.items.length > 0) {
          const clientBackgroundImages = googleCloudStorageObjects.items.filter((item) => Number(item.size) > 0);
          const randomClientBackgroundImage =
            clientBackgroundImages[Math.floor(Math.random() * clientBackgroundImages.length)];
          setBackgroundImageUrl(`https://storage.googleapis.com/${bucketName}/${randomClientBackgroundImage.name}`);
        }
      } catch (ex) {
        console.log(ex);
      }
    }

    setIsBackgroundImageUrlLoading(false);
  };

  useEffect(() => {
    if (clientId && useCheckInBackground) {
      fetchBackgroundImageUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, useCheckInBackground]);

  return { backgroundImageUrl, isBackgroundImageUrlLoading };
};
