import classNames from 'classnames';
import Button from 'components/v2/Button/Button';
import HelmHeader from 'helm/components/HelmHeader/HelmHeader/HelmHeader';
import { useState } from 'react';
import styles from './ProgramPage.module.scss';
import { useParams } from 'react-router-dom';
import alcohol from './contents/alcohol';
import drug from './contents/drug';
import gambling from './contents/gambling';
import anger from './contents/anger';
import HelmHelmetWrapper from 'helm/components/HelmHelmetWrapper/HelmHelmetWrapper';
import { SpecialistTheme } from '../../interfaces/Filter/searchFilter';
import HelmContentLayout from '../../components/HelmContentLayout/HelmContentLayout';

const ProgramPage = () => {
  const [showMore, setShowMore] = useState(false);
  const { theme } = useParams<{ theme?: SpecialistTheme }>();
  const contents =
    theme === SpecialistTheme.Alcohol
      ? alcohol
      : theme === SpecialistTheme.Drugs
      ? drug
      : theme === SpecialistTheme.Gambling
      ? gambling
      : anger;
  const cards = contents.right.cards;
  return (
    <HelmHelmetWrapper title={`HELM - ${theme?.toUpperCase()} Program`}>
      <HelmContentLayout className={styles.headerWrapper}>
        <div className={styles.headerCard}>
          <HelmHeader whiteFont />
        </div>
      </HelmContentLayout>
      <div style={{ position: 'relative' }}>
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <div className={styles.leftContentWrapper}>
              <div className={styles.title}>{contents.left.title}</div>
              <div className={styles.titleUnderline} />
              <div className={classNames(styles.subTitle, showMore && styles.showWhenReadMore)}>
                {contents.left.subTitle.all}
                <span className={styles.showOnTablet}> {contents.left.subTitle.tablet}</span>
                <span className={styles.showOnDesktop}> {contents.left.subTitle.desktop}</span>
                {!showMore && (
                  <span
                    className={styles.readMore}
                    onClick={() => {
                      setShowMore(true);
                    }}
                  >
                    Read more...
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.rightContentWrapper}>
              <div className={styles.title}>{contents.right.title}</div>
              <div className={styles.subTitle}>{contents.right.subTitle}</div>
              <div className={styles.cards}>
                <div className={styles.leftCol}>
                  {[cards[0], cards[2]].map((i, index) => (
                    <div key={index} className={styles.card}>
                      <img src={i.imageUrl} alt={i.title} />
                      <div className={styles.cardTitle}>{i.title}</div>
                      <div className={styles.cardSubTitle}>{i.subTitle}</div>
                      <Button className={styles.cardBtn}>Explore Activity</Button>
                    </div>
                  ))}
                </div>
                <div className={styles.rightCol}>
                  {[cards[1], cards[3]].map((i, index) => (
                    <div key={index} className={styles.card}>
                      <img src={i.imageUrl} alt={i.title} />
                      <div className={styles.cardTitle}>{i.title}</div>
                      <div className={styles.cardSubTitle}>{i.subTitle}</div>
                      <Button className={styles.cardBtn}>Explore Activity</Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.mobileViewCards}>
                {cards.map((i, index) => (
                  <div key={index} className={styles.card}>
                    <div>
                      <img src={i.imageUrl} alt={i.title} />
                    </div>
                    <div>
                      <div className={styles.cardTitle}>{i.title}</div>
                      <div className={styles.cardSubTitle}>{i.subTitle}</div>
                      <Button className={styles.cardBtn}>Explore Activity</Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmHelmetWrapper>
  );
};

export default ProgramPage;
