import classNames from 'classnames';
import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  className?: string;
}

const LoadingSpinner = ({ className }: LoadingSpinnerProps) => {
  return <div className={classNames(styles.loading, className)} />;
};

export default LoadingSpinner;
