import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import styles from './GPBookingSuccessPage.module.scss';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useGetAccountClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { Skeleton } from 'antd';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import { useSomeoneHealthPractitionersUrl } from 'SomeoneHealth/utils/Path/useSomeoneHealthPractitionerUrl';

const GPBookingSuccessPage = () => {
  const navigate = useNavigate();
  const { LOGIN } = useSomeoneHealthRoutesGenerator();
  const { clinicianId } = useReserveGPAppointmentData();
  const psychologistsURL = useSomeoneHealthPractitionersUrl();

  const {
    data: gpDetails,
    isLoading: isGPDetailsLoading,
    isFetching: isGPDetailsFetching
  } = useGetAccountClinicianDetailsByIdQuery({ clinicianId: clinicianId || '' }, { skip: !clinicianId });

  const handleAppointmentRedirection = () => {
    navigate(LOGIN.APPOINTMENT_OVERVIEW);
  };

  return (
    <SomeoneHealthHelmetWrapper title={`someone.health - GP appointment`}>
      <div className={styles.container}>
        <SomeoneHealthHeader withPadding withMenu homeLink />
        <SomeoneHealthContentLayout className={styles.contentLayoutWrapper}>
          <div className={styles.welcomeMessage}>GP Appointment</div>
          <div className={styles.content}>
            <div className={styles.gpInfoWrapper}>
              {isGPDetailsLoading || isGPDetailsFetching ? (
                <div className={styles.gpImageWrapper}>
                  <Skeleton.Input active className={styles.gpImageLoading} />
                </div>
              ) : (
                gpDetails?.avatar && (
                  <div className={styles.gpImageWrapper}>
                    <img className={styles.gpImage} src={gpDetails?.avatar} alt={'gp avatar'} />
                  </div>
                )
              )}
              {isGPDetailsLoading || isGPDetailsFetching ? (
                <Skeleton.Input active className={styles.gpTitleLoading} />
              ) : (
                <div className={styles.gpTitle}>
                  Nice work! Your GP appointment with {gpDetails?.title} {gpDetails?.name} has been confirmed.
                </div>
              )}
            </div>
            <div className={styles.nextInfoWrapper}>
              <div className={styles.nextTitle}>What’s next?</div>
              <div className={styles.nextInfo}>
                Your GP will review the information you have provided and assess your suitability for a Mental Health
                Treatment Plan.
                <br />
                <br />
                In your appointment they will confirm if you are eligible and share a referral with your preferred
                psychologist. If you’re not eligible, your GP will inform you in your appointment and discuss other
                options available to you.
                <br />
                <br />
                Now you have booked in with a GP you can explore{' '}
                <a className={styles.link} href={psychologistsURL} target="_self">
                  our psychologists
                </a>{' '}
                to find the right fit for you.
                <br />
                <br />
                Once you’ve attended your GP appointment you’ll have access to book in a time with your chosen
                psychologist.
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <ButtonSH className={styles.button} onClick={handleAppointmentRedirection}>
                View my appointments
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default GPBookingSuccessPage;
