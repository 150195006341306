import { generatePath } from 'react-router-dom';

export const getHelmPractitionerDetailsPath = ({
  clinicianId,
  counsellorsCalendarDetailsPath,
  practitionerDetailsPath
}: {
  clinicianId: string;
  counsellorsCalendarDetailsPath: string;
  practitionerDetailsPath: string;
}) =>
  generatePath(counsellorsCalendarDetailsPath, {
    clinicianId
  });
