import { ConsentList } from 'pages/ConsentForm/ConsentFormSignUp/ConsentList';
import styles from './ConsentListWrapper.module.scss';
import EaseContentLayout from 'Ease/components/EaseContentLayout/EaseContentLayout';
import EaseHeader from 'Ease/components/EaseHeader/EaseHeader';

const ConsentListWrapper = () => (
  <div className={styles.container}>
    <EaseContentLayout className={`${styles.headerContent} ease-theme`}>
      <div className={styles.headerWrapper}>
        <EaseHeader withPadding withMenu greenMenu />
      </div>
    </EaseContentLayout>
    <EaseContentLayout className="ease-theme">
      <ConsentList />
    </EaseContentLayout>
  </div>
);

export default ConsentListWrapper;
