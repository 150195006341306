import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClinician } from 'utils/http/ClinicianProfileService/Clinician/clinician';
import { useGetClientType } from '../../../utils/hooks/AccountInfo/clientType';
import { getPublicPracticeProfileBySlugUrl } from '../../../utils/http/ClinicianProfileService/Accounts/accounts';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import { Clinician } from '../../../utils/hooks/clinician';
import { useTranslation } from 'react-i18next';
import { setResourceTerminology } from 'i18n/resources/en/terminology';

export interface InvitedCDetailsInterface {
  _id: string;
  accountId: string;
  avatar?: string;
  name?: string;
  slugUrl?: string;
  practice?: {
    name: string;
    mobileNumber: string;
    locations: string[];
    logo: string;
    slugUrl?: string;
    welcomeMessage?: string;
  };
  isKickStart?: boolean;
  brand?: string;
}

export const useFetchInvitedClinicianDetails = (slugUrl: string) => {
  const { isPracticeClient } = useGetClientType();
  const [invitedClinicianDetail, setInvitedClinicianDetail] = useState<InvitedCDetailsInterface>(
    {} as InvitedCDetailsInterface
  );
  const [isInvitedCLoading, setIsInvitedCLoading] = useState(true);

  const navigate = useNavigate();
  const [, i18n] = useTranslation();

  const fetchInvitedClinician = async () => {
    try {
      if (isPracticeClient) {
        const practiceClinicianDetails = (await (
          await getPublicPracticeProfileBySlugUrl(slugUrl)
        ).json()) as PublicPracticeProfile;

        const massageClinicianDetails = {
          _id: 'practice',
          accountId: practiceClinicianDetails.accountId,
          brand: practiceClinicianDetails.brand,
          practice: {
            name: practiceClinicianDetails.name,
            logo: practiceClinicianDetails.logo,
            welcomeMessage: practiceClinicianDetails?.welcomeMessage || ''
          },
          isKickStart: practiceClinicianDetails.isKickStart
        } as InvitedCDetailsInterface;

        setResourceTerminology(i18n, practiceClinicianDetails.accountSettings);
        setInvitedClinicianDetail(massageClinicianDetails);
        setIsInvitedCLoading(false);
      } else {
        const clinicianDetails = (await (await getClinician(slugUrl)).json()) as Clinician;

        const massageClinicianDetails = {
          _id: clinicianDetails._id,
          accountId: clinicianDetails.accountId,
          name: clinicianDetails.name,
          avatar: clinicianDetails.avatar,
          practice: {
            name: clinicianDetails.practice?.name,
            logo: clinicianDetails.practice?.logo,
            mobileNumber: clinicianDetails.practice?.mobileNumber,
            locations: clinicianDetails.practice?.locations,
            slugUrl: clinicianDetails.practice?.slugUrl,
            welcomeMessage: clinicianDetails?.practice?.welcomeMessage || ''
          }
        } as InvitedCDetailsInterface;

        setResourceTerminology(i18n, clinicianDetails.accountSettings);
        setInvitedClinicianDetail(massageClinicianDetails);
        setIsInvitedCLoading(false);
      }
    } catch (ex) {
      console.error(`User redirected from ${window.location.pathname} to /404`);
      navigate('/404');
    }
  };

  useEffect(() => {
    fetchInvitedClinician();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugUrl]);

  return { invitedClinicianDetail, isInvitedCLoading };
};
