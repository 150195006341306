import { Route, Routes } from 'react-router-dom';
import appRoute from 'components/AppRoute/AppRoute';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import { UserContextProvider } from 'utils/UserContextProvider';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import SelectLoginWrapper from 'Select/components/SelectLoginWrapper/SelectLoginWrapper';
import SelectWrapper from 'Select/components/SelectWrapper/SelectWrapper';
import PsychologistListing from 'Select/pages/PsychologistListing/PsychologistListing';
import PsychologistDetails from 'Select/pages/PsychologistDetails/PsychologistDetails';
import SignUp from 'Select/pages/SignUp/SignUp';
import SignUpRiskScreening from 'Select/pages/SignUpRiskScreening/SignUpRiskScreening';
import SignUpBookingConfirmation from 'Select/pages/SignUpBookingConfirmation/SignUpBookingConfirmation';
import LoginPage from 'Select/pages/LoginPage/LoginPage';
import SelectAvatar from 'Select/pages/SelectAvatar/SelectAvatar';
import SelectHomePage from 'Select/pages/SelectHomePage/SelectHomePage';
import SelectActionPage from 'Select/pages/SelectActionPage/SelectActionPage';
import UpdateProfile from 'Select/pages/UpdateProfile/UpdateProfile';
import SignUpCompleteBooking from 'Select/pages/SignUpCompleteBooking/SignUpCompleteBooking';
import PatientCheckIn from 'pages/PatientCheckIn/PatientCheckIn';
import PsychometricList from 'pages/Psychometric/PsychometricList/PsychometricList';
import PatientAdHocAssessment from 'pages/PatientAdHocAssessment/PatientAdHocAssessment';
import MyPsychologist from 'Select/pages/MyPsychologist/MyPsychologist';
import ReferralDetail from 'Select/pages/ReferralDetail/ReferralDetail';
import AppointmentsOverview from 'Select/pages/AppointmentsOverview/AppointmentsOverview';
import Onboarding from 'pages/OnboardingAssessment/Onboarding/Onboarding';
import SurveyListWrapper from 'Select/pages/Survey/SurveyListWrapper/SurveyListWrapper';
import SurveyQuestionWrapper from 'Select/pages/Survey/SurveyQuestionWrapper/SurveyQuestionWrapper';
import ConsentListWrapper from 'Select/pages/ConsentListWrapper/ConsentListWrapper';
import ConsentFormSignUp from 'Select/pages/ConsentFormSignUp/ConsentFormSignUp';
// import InvoiceViewPage from 'pages/InvoiceViewPage/InvoiceViewPage';
// import ReportViewPage from 'pages/ReportViewPage/ReportViewPage';
// import LetterViewPage from 'pages/LetterViewPage/LetterViewPage';

const SelectRoutes = () => {
  const {
    PRACTITIONER,
    SIGNUP,
    LOGIN,

    AVATAR,
    HOME,
    PENDING_ACTIONS,
    UPDATE_PROFILE,
    MY_PSYCHOLOGIST,
    REFERRAL,
    APPOINTMENT_OVERVIEW,
    ONBOARDING,
    CHECKIN,
    SURVEY,
    PSYCHOMETRIC,
    // CHECKIN,
    CONSENT
    // INVOICE,
    // REPORT,
    // LETTER,
  } = useSelectRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: PRACTITIONER.LISTING, component: PsychologistListing, layout: SelectWrapper })}
        {appRoute({
          path: PRACTITIONER.LISTING_WITH_FILTER,
          component: PsychologistListing,
          layout: SelectWrapper
        })}
        {appRoute({
          path: PRACTITIONER.DETAILS,
          component: PsychologistDetails,
          layout: SelectWrapper
        })}
        {appRoute({ path: SIGNUP.BASE, component: SignUp, layout: SelectWrapper })}
        {appRoute({ path: SIGNUP.RISK_SCREENING, component: SignUpRiskScreening, layout: SelectWrapper })}
        {appRoute({ path: SIGNUP.CONFIRM_BOOKING, component: SignUpBookingConfirmation, layout: SelectWrapper })}
        {appRoute({ path: SIGNUP.WELCOME, component: SignUpCompleteBooking, layout: SelectWrapper })}

        {appRoute({ path: PSYCHOMETRIC.BASE, component: PsychometricList, layout: SelectLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: SelectLoginWrapper
        })}
        {appRoute({
          path: SURVEY.BASE,
          component: SurveyListWrapper,
          layout: SelectLoginWrapper
        })}
        {appRoute({
          path: SURVEY.DETAILS,
          component: SurveyQuestionWrapper,
          layout: SelectLoginWrapper
        })}

        {/* {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: REPORT.VIEW,
          component: ReportViewPage,
          layout: SomeoneHealthWrapper
        })}
        {appRoute({
          path: LETTER.VIEW,
          component: LetterViewPage,
          layout: SomeoneHealthWrapper
        })} */}

        <Route element={<AuthLayout hideHelpScout />}>
          {appRoute({ path: LOGIN, component: LoginPage, layout: SelectLoginWrapper })}
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: SelectLoginWrapper })}
          {appRoute({ path: HOME, component: SelectHomePage, layout: SelectLoginWrapper })}
          {appRoute({ path: PENDING_ACTIONS, component: SelectActionPage, layout: SelectLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: SelectLoginWrapper })}
          {appRoute({
            path: APPOINTMENT_OVERVIEW,
            component: AppointmentsOverview,
            layout: SelectLoginWrapper
          })}
          {appRoute({ path: MY_PSYCHOLOGIST, component: MyPsychologist, layout: SelectLoginWrapper })}
          {appRoute({ path: REFERRAL, component: ReferralDetail, layout: SelectLoginWrapper })}

          {appRoute({ path: CHECKIN, component: PatientCheckIn, layout: SelectLoginWrapper })}
          {appRoute({ path: ONBOARDING.BASE, component: Onboarding, layout: SelectLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: SelectLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: SelectLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default SelectRoutes;
