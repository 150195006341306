import { useGetAccessToken } from 'utils/hooks/token';

import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import { useParams } from 'react-router-dom';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import SurveyQuestionForm from './components/SurveyQuestionForm/SurveyQuestionForm';
import { useFetchSurveyQuestion } from './hooks/getSurveyQuestion';
import styles from './SurveyQuestion.module.scss';

const SurveyQuestion = () => {
  const { token } = useGetAccessToken(true);

  const { surveyId = '', type = '' } = useParams<{
    surveyId: string;
    type: string;
  }>();

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { surveyQuestion, isSurveyQuestionLoading, setSurveyQuestion } = useFetchSurveyQuestion(token, surveyId);

  const clinicianName = attachedClinicianDetail?.clinician?.name
    ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
    : attachedClinicianDetail?.practice?.name || '';

  return (
    <div className={styles.content}>
      {isSurveyQuestionLoading || isAttachedClinicianDetailLoading ? (
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      ) : (
        attachedClinicianDetail && (
          <SurveyQuestionForm
            surveyData={surveyQuestion}
            token={token}
            clinicianDetail={attachedClinicianDetail}
            clinicianName={clinicianName}
            setSurveyQuestion={setSurveyQuestion}
            type={type}
          />
        )
      )}
    </div>
  );
};

export default SurveyQuestion;
