import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import styles from './TimeExpireAlert.module.scss';
import classNames from 'classnames';

interface TimeExpireAlertProps {
  modalClassname?: string;
  showExpiredAlert: boolean;
  returnLinkText?: string;
  returnRoute: string;
}

const TimeExpireAlert = ({ modalClassname, showExpiredAlert, returnLinkText, returnRoute }: TimeExpireAlertProps) => {
  return (
    <Modal
      className={classNames(styles.modalContainer, modalClassname)}
      bodyStyle={{ padding: 0 }}
      visible={showExpiredAlert}
      footer={null}
      closable={false}
    >
      <div className={styles.container}>
        <i className={`material-icons-outlined ${styles.infoIcon}`}>info</i>
        <div className={styles.alertContent}>
          <div className={styles.title}>Your appointment hold has timed out.</div>
          <p>
            Because of high demand for our psychologists we can only reserve your appointment time for 15 minutes for
            you to complete your booking. This is to ensure fairness for everyone trying to find an available slot.
          </p>
          <p>
            You may find the same appointment slot is still available, so please go back and select an open appointment.
          </p>
          <Link to={returnRoute} className={styles.link}>
            <span className={styles.text}>{returnLinkText || 'Return to psychologist profile page'}</span>
            <i className={`material-icons-outlined ${styles.backIcon}`}>arrow_back</i>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default TimeExpireAlert;
