import { Helmet, HelmetProvider } from 'react-helmet-async';
import FloatingHelpBtn from '../FloatingHelpBtn/FloatingHelpBtn';
import { CLIENT_BRAND, ClientBrand } from 'interfaces/ClientBrand';

interface SomeoneHealthHelmetWrapperProps {
  title?: string;
  children?: any;
  hubspot?: boolean;
}

const faviconUrl = `${process.env.PUBLIC_URL}/${
  CLIENT_BRAND === ClientBrand.SOMEONE_HEALTH ? 'favSomeoneHealthV2.png' : 'favSomeoneHealth.png'
}`;

const SomeoneHealthHelmetWrapper = ({ children, title, hubspot }: SomeoneHealthHelmetWrapperProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={'someone.health'} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
      <FloatingHelpBtn hubspot={hubspot} />
    </HelmetProvider>
  );
};

export default SomeoneHealthHelmetWrapper;
