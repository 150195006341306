import { ProgramInterface } from 'helm/interfaces/program/program';
import styles from './YourGoals.module.scss';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import YourProgress from './components/YourProgress/YourProgress';
import { useMemo } from 'react';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';
import { useNavigate } from 'react-router-dom';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';

interface YourGoalsProps {
  data: ProgramInterface;
}

const YourGoals = ({ data }: YourGoalsProps) => {
  const navigate = useNavigate();
  const { SMP } = useHelmRoutesGenerator();

  const goals = useMemo(() => {
    return [data.goals?.goal1, data.goals?.goal2, data.goals?.goal3].filter((goal) => !!goal);
  }, [data.goals]);

  const handleGoToSmp = () => {
    navigate(SMP.LISTING);
  };

  return (
    <div className={styles.container}>
      <HelmContentLayout className={styles.wrapper}>
        {goals.length > 0 && (
          <div className={styles.goals}>
            <div className={styles.title}>
              <span>Your Goals</span>
              <ButtonHelm className={styles.titleSmpButton} onClick={handleGoToSmp}>
                View and edit your plan
              </ButtonHelm>
            </div>
            {goals.map((goal, i) => (
              <div className={styles.goalItem} key={i}>
                <i className={`material-icons ${styles.icon}`}>photo_filter</i>
                <div>
                  <div className={styles.goalIndex}>GOAL {i + 1}</div>
                  <div className={styles.goal}>{goal}</div>
                </div>
              </div>
            ))}
            <div className={styles.smpButtonContainer}>
              <ButtonHelm className={styles.smpButton} onClick={handleGoToSmp}>
                View and edit your plan
              </ButtonHelm>
            </div>
          </div>
        )}
        <YourProgress chapters={data.chapters} />
      </HelmContentLayout>
    </div>
  );
};

export default YourGoals;
