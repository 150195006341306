import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import HelmHeader from '../HelmHeader/HelmHeader/HelmHeader';
import HelmLoginWrapper from '../HelmLoginWrapper/HelmLoginWrapper';
import styles from './HelmSmpLayout.module.scss';

interface HelmSmpLayoutProps {
  children?: React.ReactNode;
}

const HelmSmpLayout = ({ children }: HelmSmpLayoutProps) => {
  return (
    <HelmLoginWrapper>
      <PatientContentLayout className={styles.container}>
        <div>
          <HelmHeader logoWithoutText whiteLogo whiteFont withMenu homeLink />
          {children}
        </div>
      </PatientContentLayout>
    </HelmLoginWrapper>
  );
};

export default HelmSmpLayout;
