import styles from './MyPsychologist.module.scss';
import MyPsychologistDetail from './components/MyPsychologistDetail/MyPsychologistDetail';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';

const MyPsychologist = () => {
  return (
    <SelectHelmetWrapper title={'Select Psychology | My Psychologist'}>
      <SelectContentLayout className={`${styles.container} select-theme`}>
        <SelectHeader withPadding withMenu homeLink />
        <MyPsychologistDetail />
      </SelectContentLayout>
    </SelectHelmetWrapper>
  );
};

export default MyPsychologist;
