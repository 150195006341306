import CORDSContentWrapper from 'CORDS/components/CORDSContentWrapper/CORDSContentWrapper';
import styles from './CordsConsentDetails.module.scss';
import AdHocConsentForm from 'pages/ConsentForm/AdHocConsentForm/AdHocConsentForm';

const CordsConsentDetails = () => {
  return (
    <CORDSContentWrapper title={'CORDS - Consent Details'} fullwidth contentClassName={styles.container}>
      <AdHocConsentForm
        containerClassName={styles.container}
        contentClassName={styles.content}
        loadingClassName={styles.loading}
      />
    </CORDSContentWrapper>
  );
};

export default CordsConsentDetails;
