import { useState } from 'react';
import styles from './AddKeyContactModal.module.scss';
import * as Yup from 'yup';
import classNames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Select from 'components/Select/Select';
import HelmMobileMaterialInput from 'helm/components/Input/HelmMobileMaterialInput';
import { useFormError } from 'helm/utils/hooks/validate';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { postKeyContact } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useGetAccessToken } from 'utils/hooks/token';
import { notification } from 'antd';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import classnames from 'classnames';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_HELM_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import ButtonCaW from '../../../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';

export interface KeyContact {
  _id?: string;
  firstName?: string;
  lastName?: string;
  tags?: string[];
  mobileNumber?: string;
  email?: string;
}

export const CONTACT_RELATIONSHIP_OPTIONS = [
  'Partner',
  'Next of Kin',
  'Emergency Contact',
  'Husband',
  'Wife',
  'Son',
  'Daughter',
  'Family Member',
  'Employer',
  'Teacher',
  'Doctor',
  'Care Provider',
  'Bill Payer',
  'Safeguarding',
  'Human Resources',
  'Occupational Health',
  'Nurse',
  'Consent to share data',
  'No consent to share',
  'Pastoral Support Officer',
  'Social Worker',
  'Family Support Worker'
];

const clientProfileValidation = Yup.object().shape({
  firstName: Yup.string().required('Please enter first name'),
  lastName: Yup.string().required('Please enter last name'),
  mobileNumber: Yup.string(),
  email: Yup.string().email('Email must be in valid example@domain.com format.'),
  relationships: Yup.array().test(
    'Check empty',
    'Please enter at least 1 relationship',
    (value) => (value?.length || 0) > 0
  )
});

interface AddKeyContactModalProps {
  onSuccess: () => void;
  onClose: () => void;
}

const AddKeyContactModal = ({ onSuccess, onClose }: AddKeyContactModalProps) => {
  const [values, setValues] = useState<KeyContact>({});
  const { errors, validate } = useFormError<KeyContact>();
  const [submitStatus, setSubmitStatus] = useState<HelmBtnStatus>('');
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const { token } = useGetAccessToken();
  const handleChangeValues = (key: keyof KeyContact, value?: string) => {
    setValues({ ...values, [key]: value });
    validate(clientProfileValidation, { ...values, [key]: value }, key);
  };

  const addKeyContact = async () => {
    setSubmitStatus('active');
    const mobileNumber = values.mobileNumber;
    let mobileNumberValid = true;
    if (mobileNumber) {
      const phoneValidate = await validatePhoneNumber(mobileNumber, true);
      if (!phoneValidate.valid) {
        setInvalidMobileNumber(true);
        mobileNumberValid = false;
        setSubmitStatus('');
      }
    }
    if (mobileNumberValid) {
      try {
        await postKeyContact(token, { ...values, mobileNumber });
        setSubmitStatus('finished');
        setTimeout(() => {
          setSubmitStatus('');
          onSuccess();
          onClose();
        }, 1000);
      } catch (e) {
        console.error(e);
        notification.error({ message: 'Something went wrong while trying to create a new key contact' });
        setSubmitStatus('');
      }
    }
  };

  const isBtnDisable = () => {
    if (!values.firstName || !values.lastName) {
      return true;
    }
    return errors && !!Object.values(errors).find((error) => !!error);
  };

  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={classnames(styles.col, errors?.firstName && styles.fieldError)}>
          <MaterialInput
            label={'First Name'}
            id={'firstNameKeyContact'}
            onChange={(e) => handleChangeValues('firstName', e.target.value)}
            value={values.firstName || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.firstName} visible={!!errors?.firstName} />
        </div>
        <div className={classnames(styles.col, errors?.lastName && styles.fieldError)}>
          <MaterialInput
            label={'Last Name'}
            id={'lastNameKeyContact'}
            onChange={(e) => handleChangeValues('lastName', e.target.value)}
            value={values.lastName || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.lastName} visible={!!errors?.lastName} />
        </div>
        <div className={classNames(styles.col, styles.colLonger)}>
          <Select
            menuPlacement={'top'}
            label={'Tags'}
            labelClass={styles.selectLabel}
            id={'tags'}
            onChange={(values: any) =>
              handleChangeValues(
                'tags',
                values?.map((i: any) => i.value)
              )
            }
            styles={{
              valueContainer: (base) => ({ ...base, paddingLeft: 15, marginTop: 10 }),
              multiValue: (base) => ({
                ...base,
                padding: 0,
                backgroundColor: IS_RECHARGE_APP ? '#806abe' : IS_SOMEONE_HEALTH_APP ? '#502334' : '#2a4670',
                borderRadius: 20,
                color: 'white'
              }),
              menuList: (base) => ({ ...base, ...(IS_SOMEONE_HEALTH_APP && { scrollbarWidth: 'none' }) }),
              option: (base: any, { isFocused, isSelected }) => ({
                ...base,
                ...(IS_SOMEONE_HEALTH_APP &&
                  (isFocused || isSelected) && {
                    backgroundColor: '#EEE5EB',
                    '&:active': {
                      backgroundColor: '#EEE5EB'
                    }
                  })
              })
            }}
            values={CONTACT_RELATIONSHIP_OPTIONS.filter((i) => values.tags?.includes(i))}
            multipleSelect
            maxLength={80}
            autoComplete={'off'}
            className={styles.select}
            options={CONTACT_RELATIONSHIP_OPTIONS.map((i) => ({ value: i, label: i }))}
          />
          <ErrorMessage className={styles.error} error={errors?.tags} visible={!!errors?.tags} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <HelmMobileMaterialInput
            inputClass={styles.mobileInput}
            id={`mobile`}
            name={'mobile'}
            label={'Mobile Number'}
            labelClass={styles.mobileLabel}
            mobilePattern={IS_HELM_APP ? '____ ___ ___' : ''}
            placeholder={IS_HELM_APP ? '(eg: 0482 666 666)' : ''}
            enableCountryCode
            onChange={(e) => {
              invalidMobileNumber && setInvalidMobileNumber(false);
              handleChangeValues('mobileNumber', e.target.value);
            }}
          />
          <ErrorMessage className={styles.error} error={errors?.mobileNumber} visible={!!errors?.mobileNumber} />
          <ErrorMessage className={styles.error} error={'Invalid Phone Number'} visible={invalidMobileNumber} />
        </div>
        <div className={classnames(styles.col, errors?.email && styles.fieldError)}>
          <MaterialInput
            label={'Email'}
            id={'emailKeyContact'}
            onChange={(e) => handleChangeValues('email', e.target.value)}
            value={values.email || ''}
            maxLength={80}
            required
            autoComplete={'off'}
            className={styles.input}
          />
          <ErrorMessage className={styles.error} error={errors?.email} visible={!!errors?.email} />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {IS_CAW_APP ? (
          <ButtonCaW
            className={styles.addContactBtn}
            onClick={() => addKeyContact()}
            disabled={isBtnDisable()}
            status={submitStatus}
            variant={'outlined'}
          >
            Add Key Contact
          </ButtonCaW>
        ) : IS_EASE_APP ? (
          <ButtonEase
            className={styles.addContactBtn}
            onClick={() => addKeyContact()}
            disabled={isBtnDisable()}
            status={submitStatus}
          >
            ADD KEY CONTACT
          </ButtonEase>
        ) : IS_RECHARGE_APP || IS_SELECT_APP ? (
          <Button
            className={styles.addContactBtn}
            onClick={() => addKeyContact()}
            disabled={isBtnDisable()}
            status={submitStatus}
          >
            Add key contact
          </Button>
        ) : IS_SOMEONE_HEALTH_APP ? (
          <ButtonSH
            className={styles.addContactBtn}
            onClick={() => addKeyContact()}
            disabled={isBtnDisable()}
            status={submitStatus}
          >
            Add Key Contact
          </ButtonSH>
        ) : (
          <Button
            className={styles.addContactBtn}
            onClick={() => addKeyContact()}
            disabled={isBtnDisable()}
            status={submitStatus}
            variant={'secondary'}
          >
            Add Key Contact
          </Button>
        )}
        {submitStatus === '' &&
          (IS_CAW_APP ? (
            <ButtonCaW className={styles.cancelBtn} variant={'outlined'} onClick={() => onClose()}>
              Cancel
            </ButtonCaW>
          ) : IS_EASE_APP ? (
            <ButtonEase className={styles.cancelBtn} variant={'outlined'} onClick={() => onClose()}>
              CANCEL
            </ButtonEase>
          ) : (
            <Button className={styles.cancelBtn} variant={'secondary'} onClick={() => onClose()}>
              Cancel
            </Button>
          ))}
      </div>
    </div>
  );
};

export default AddKeyContactModal;
