import demoCard from 'helm/assets/images/programPage/demoCard.png';
import { ProgramContent } from './programContentInterface';

const content: ProgramContent = {
  left: {
    title: 'Anger',
    subTitle: {
      all: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
      tablet: 'magna aliqua. Tincidunt augue interdum velit euismod in',
      desktop:
        'pellentesque massa placerat. Egestas maecenas pharetra convallis posuere. Orci phasellus egestas tellus rutrum tellus pellentesque eu. A diam maecenas sed enim ut sem viverra aliquet eget.'
    }
  },
  right: {
    title: 'Explore our Anger support library',
    subTitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    cards: [
      {
        title: 'Turning Negatives into Positives',
        subTitle: 'Challenge negative self talk and change olds patterns ',
        imageUrl: demoCard
      },
      {
        title: 'Relapse Prevention',
        subTitle: 'Learn about ways to recognise signs of relapse and have a plan in place to help you. ',
        imageUrl: demoCard
      },
      { title: 'Life Balance Wheel', subTitle: 'A balanced wheel rolls smoothly', imageUrl: demoCard },

      {
        title: 'Stages of Change',
        subTitle: 'A short video to help you understand the structure to making lasting changes',
        imageUrl: demoCard
      }
    ]
  }
};

export default content;
