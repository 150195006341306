import classNames from 'classnames';
import { MOMENTJS_FORMAT_DATE } from 'components/AvailableTimeSelect/constants';
import { AvailabilityTimeSlot, NoAvailabilityReason } from 'components/AvailableTimeSelect/Interface';
import Button from 'components/v2/Button/Button';
import { Translation } from 'i18n/types';
import { isEqual } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { getCurrentWeekAppointmentSlots } from '../../helpers/getCurrentWeekAppointmentSlots';
import styles from './DayCard.module.scss';
import { IS_RECHARGE_APP, IS_SOMEONE_HEALTH_APP } from 'utils/hooks/AccountInfo/clientDetails';

const MOMENTJS_FORMAT_TIME = 'HH:mm';
const MOMENTJS_FORMAT_START_TIME_FORMAT = 'hh:mm';
const MOMENTJS_FORMAT_END_TIME_FORMAT = 'hh:mm a';

export interface SelectedAppointmentSlot {
  date: string;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
}

type DayCardProps = {
  isAvailabilitiesViewTypeSimple: boolean;
  data: ReturnType<typeof getCurrentWeekAppointmentSlots>['currentWeekAppointmentSlots'][number];
  selectedAppointmentSlot?: SelectedAppointmentSlot;
  clientTimeZone?: string;
  clinicianTimeZone: string;
  onSelectSlot: (slot: SelectedAppointmentSlot) => void;
};

const noAvailabilityReasonTexts: Record<NoAvailabilityReason, Translation> = {
  [NoAvailabilityReason.ClinicianBusy]: 'calendar.no_availability_reason.clinicion_busy',
  [NoAvailabilityReason.IsPublicHoliday]: 'calendar.no_availability_reason.is_public_holiday',
  [NoAvailabilityReason.MaxForwardAvailability]: 'calendar.no_availability_reason.max_forward_availability',
  [NoAvailabilityReason.MaxSessionReached]: 'calendar.no_availability_reason.max_session_reached',
  [NoAvailabilityReason.MinTimeBufferBeforeBooking]: 'calendar.no_availability_reason.min_time_buffer_before_booking',
  [NoAvailabilityReason.NotInAppointmentSchedule]: 'calendar.no_availability_reason.not_in_appointment_schedule',
  [NoAvailabilityReason.NotInClinicianSchedule]: 'calendar.no_availability_reason.not_in_clinician_schedule',
  [NoAvailabilityReason.NotInRoomSchedule]: 'calendar.no_availability_reason.not_in_room_schedule',
  [NoAvailabilityReason.RoomBusy]: 'calendar.no_availability_reason.room_busy',
  [NoAvailabilityReason.CannotFitGap]: 'calendar.no_availability_reason.cannot_fit_gap',
  [NoAvailabilityReason.TimeHasPassed]: 'calendar.no_availability_reason.time_has_passed'
};

const convertAppointmentTime = (
  date: string,
  time: string,
  clinicianTimeZone: string,
  clientTimeZone: string,
  timeFormat: string
) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${time}`, clinicianTimeZone);
  return momentTz.tz(clinicianStartTimeZone, clientTimeZone).format(timeFormat);
};

const DayCard = ({
  isAvailabilitiesViewTypeSimple,
  selectedAppointmentSlot,
  data,
  clientTimeZone,
  clinicianTimeZone,
  onSelectSlot
}: DayCardProps) => {
  const date = moment(data.date, MOMENTJS_FORMAT_DATE);
  const formattedDate = date.format(MOMENTJS_FORMAT_DATE);

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const [t] = useTranslation();

  const mapTimes = (timeSlots: AvailabilityTimeSlot[]) => {
    const mappedTimeSlots = timeSlots
      .map((timeSlot, index) => {
        if (isAvailabilitiesViewTypeSimple && !timeSlot.isAvailable) {
          return undefined;
        }

        const formattedStartTime = clientTimeZone
          ? convertAppointmentTime(
              formattedDate,
              timeSlot.startTime,
              clinicianTimeZone,
              clientTimeZone,
              MOMENTJS_FORMAT_START_TIME_FORMAT
            )
          : moment(timeSlot.startTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_START_TIME_FORMAT);
        const formattedEndTime = clientTimeZone
          ? convertAppointmentTime(
              formattedDate,
              timeSlot.endTime,
              clinicianTimeZone,
              clientTimeZone,
              MOMENTJS_FORMAT_END_TIME_FORMAT
            )
          : moment(timeSlot.endTime, MOMENTJS_FORMAT_TIME).format(MOMENTJS_FORMAT_END_TIME_FORMAT);

        return (
          <div
            key={index}
            className={styles.badgeWrapper}
            {...(!timeSlot.isAvailable && {
              'data-tip': noAvailabilityReasonTexts[timeSlot.noAvailabilityReason]
                ? t(noAvailabilityReasonTexts[timeSlot.noAvailabilityReason])
                : timeSlot.noAvailabilityReason,
              'data-for': `unavailable-reason-${formattedDate}`
            })}
          >
            <Button
              disabled={!timeSlot.isAvailable}
              key={index}
              variant={IS_RECHARGE_APP ? 'primary' : 'secondary'}
              className={classNames(
                styles.timeButton,
                isEqual(selectedAppointmentSlot, {
                  date: formattedDate,
                  startTime: timeSlot.startTime,
                  endTime: timeSlot.endTime,
                  startDateTime: timeSlot.startDateTime,
                  endDateTime: timeSlot.endDateTime
                }) && styles.active
              )}
              onClick={() => {
                onSelectSlot({
                  date: formattedDate,
                  startTime: timeSlot.startTime,
                  endTime: timeSlot.endTime,
                  startDateTime: timeSlot.startDateTime,
                  endDateTime: timeSlot.endDateTime
                });
              }}
            >
              {`${formattedStartTime} to ${formattedEndTime}`}
            </Button>
          </div>
        );
      })
      .filter((timeComponent) => !!timeComponent);

    if (mappedTimeSlots.length === 0 && isAvailabilitiesViewTypeSimple) {
      return <div className={styles.noAvailability}>No availability</div>;
    }

    return mappedTimeSlots;
  };

  return (
    <div className={classNames(styles.container, IS_SOMEONE_HEALTH_APP && 'someone-health-theme')}>
      <div
        className={classNames(styles.header, {
          [styles.active]: selectedAppointmentSlot?.date === formattedDate
        })}
      >
        <div className={styles.date}>{date.format('dddd,')}</div>
        <div className={styles.date}>{date.format('Do MMMM')}</div>
      </div>
      <div className={styles.body}>
        {'isAvailable' in data && data.isAvailable ? (
          mapTimes(data.timeSlots)
        ) : !isAvailabilitiesViewTypeSimple ? (
          <div
            data-tip={t(
              noAvailabilityReasonTexts[
                'noAvailabilityReason' in data ? data.noAvailabilityReason : NoAvailabilityReason.TimeHasPassed
              ]
            )}
            data-for={`unavailable-reason-${formattedDate}`}
            className={styles.badgeWrapper}
          >
            <Button disabled variant={'secondary'} className={styles.timeButton}>
              No availability
            </Button>
          </div>
        ) : (
          <div className={styles.noAvailability}>No availability</div>
        )}
      </div>
      <ReactTooltip disable={isAvailabilitiesViewTypeSimple} id={`unavailable-reason-${formattedDate}`} />
    </div>
  );
};

export default DayCard;
