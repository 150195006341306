import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_SCHED_SERVICE_URL;

export const getAppointments = (token: string) =>
  httpClient('GET', url!, [200], { Authorization: `Bearer ${token}` })(
    '/client-records/me/appointments'
  ) as Promise<BentResponse>;

export const getClientBookingRuleType = (token: string) =>
  httpClient('GET', url!, 200, { Authorization: `Bearer ${token}` })(
    `/client-records/me/appointments:getBookingRuleType`
  ) as Promise<BentResponse>;
