export const PortobelloContactUrl = 'https://www.portobellobh.com/find-us';

export const PortobelloSiteUrl = 'https://www.portobellobh.com/';

export const PortobelloSupportEmail = 'enquiry@portobellobh.com';
export const PortobelloSupportMobile = '020 3633 2111';

export const PortobelloPrivacyPolicyUrl = 'https://www.portobellobh.com/legal/privacy-policy';

export const portobelloAccountId = process.env.REACT_APP_ACCOUNT_ID_PORTOBELLO || '6711c40a300952660a2f702c';
