import classnames from 'classnames';
import MaterialInput from 'components/MaterialInput/MaterialInput';
import MaterialPhoneInput from 'components/MaterialPhoneInput/MaterialPhoneInput';
import { RELATIONSHIP_OPTIONS } from 'components/ReferralForm/constants';
import MaterialSelect from 'components/Select/MaterialSelect/MaterialSelect';
import styles from './GuardianForm.module.scss';

interface GuardianFormProps {
  renderIf: any;
  formValues: any;
  setFormValues: any;
  errorMessage: any;
}

export const GuardianForm = ({ renderIf, formValues, setFormValues, errorMessage }: GuardianFormProps) => {
  return (
    <>
      {renderIf && (
        <div className={styles.container}>
          <div className={styles.title}>About the parent and guardian</div>
          <div className={classnames(styles.fieldContainer, errorMessage?.guardianFirstName && styles.fieldError)}>
            <MaterialInput
              label={'Parent or Guardian First Name*'}
              id={'guardianFirstName'}
              value={formValues.guardianFirstName}
              onChange={(event) => {
                setFormValues({ ...formValues, guardianFirstName: event.target.value });
              }}
              maxLength={200}
              required
            />
            {errorMessage?.guardianFirstName && (
              <div className={styles.fieldError}>{errorMessage?.guardianFirstName}</div>
            )}
          </div>
          <div className={classnames(styles.fieldContainer, errorMessage?.guardianLastName && styles.fieldError)}>
            <MaterialInput
              label={'Parent or Guardian Last Name*'}
              id={'guardianLastName'}
              value={formValues.guardianLastName}
              onChange={(event) => {
                setFormValues({ ...formValues, guardianLastName: event.target.value });
              }}
              maxLength={200}
              required
            />
            {errorMessage?.guardianLastName && (
              <div className={styles.fieldError}>{errorMessage?.guardianLastName}</div>
            )}
          </div>
          <div className={classnames(styles.fieldContainer, errorMessage?.guardianEmail && styles.fieldError)}>
            <MaterialInput
              label={'Parent or Guardian Email Address*'}
              id={'guardianEmail'}
              value={formValues.guardianEmail}
              onChange={(event) => {
                setFormValues({ ...formValues, guardianEmail: event.target.value });
              }}
              maxLength={200}
              required
            />
            {errorMessage?.guardianEmail && <div className={styles.fieldError}>{errorMessage?.guardianEmail}</div>}
          </div>
          <div className={classnames(styles.fieldContainer, errorMessage?.guardianContactNumber && styles.fieldError)}>
            <MaterialPhoneInput
              id={`guardianContactNumber`}
              label={'Parent or guardian Contact Number*'}
              onChange={(e: string) => {
                setFormValues({ ...formValues, guardianContactNumber: e });
              }}
              value={formValues.guardianContactNumber}
              isError={!!errorMessage?.guardianContactNumber}
            />
            {errorMessage?.guardianContactNumber && (
              <div className={styles.fieldError}>{errorMessage?.guardianContactNumber}</div>
            )}
          </div>
          <div className={styles.selectContainer}>
            <MaterialSelect
              id={'guardianRelationshipToReferral'}
              label={'Relationship*'}
              isSearchable={false}
              optionList={RELATIONSHIP_OPTIONS}
              value={formValues.guardianRelationshipToReferral}
              onChange={(event: string) => {
                setFormValues({ ...formValues, guardianRelationshipToReferral: event });
              }}
              isError={!!errorMessage?.guardianRelationshipToReferral}
            />
            {errorMessage?.guardianRelationshipToReferral && (
              <div className={styles.fieldError}>{errorMessage?.guardianRelationshipToReferral}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
