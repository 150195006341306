import { useAuth0 } from '@auth0/auth0-react';

import styles from './SurveyQuestionWrapper.module.scss';
import SurveyQuestion from 'pages/Survey/SurveyQuestion/SurveyQuestion';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';

const SurveyQuestionWrapper = () => {
  const { user, isAuthenticated } = useAuth0();

  if (
    isAuthenticated &&
    user?.['https://tacklit.com/roles'].includes('clinician') &&
    process.env.REACT_APP_CLINICIAN_DOMAIN
  ) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  return (
    <PortobelloHelmetWrapper title={`Portobello Behavioural Health | Survey`}>
      <PortobelloHeader withPadding withMenu homeLink />
      <div className={styles.contentWrapper}>
        <PortobelloContentLayout className="portobello-theme">
          <SurveyQuestion />
        </PortobelloContentLayout>
      </div>
    </PortobelloHelmetWrapper>
  );
};

export default SurveyQuestionWrapper;
