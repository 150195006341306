import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './SignUp.module.scss';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { storeUserSession, UserSessionStorage } from './helper/userSession';
import { scrollToView } from 'utils/scrollToView';
import Loading from 'components/Loading/Loading';
import { useState, ReactNode, useEffect, useCallback } from 'react';
import { useFetchReservedAppointmentsWithTimeZone } from 'Recharge/utils/hooks/useFetchReservedAppointmentsWithTimeZone';
import RechargeCreateProfileForm from 'Recharge/components/RechargeCreateProfileForm/RechargeCreateProfileForm';
import { getAuMobile, initialCustomer } from 'Recharge/components/RechargeCreateProfileForm/constants';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import moment from 'moment';
import { postPatientSignupWithoutInvitation } from 'utils/http/PatientProfileService/Patient/patient';
import { notification } from 'antd';
import { fetchClaimReservation } from 'Recharge/utils/api/fetchClaimReservation';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import RechargeTimer from 'Recharge/components/RechargeTimer/RechargeTimer';
import ErrorMessageWithHelpLink from './components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import RechargeHelmetWrapper from 'Recharge/components/RechargeHelmetWrapper/RechargeHelmetWrapper';
import RechargeContentLayout from 'Recharge/components/RechargeContentLayout/RechargeContentLayout';
import RechargeHeader from 'Recharge/components/RechargeHeader/RechargeHeader';
import { useFetchPractitionerDetails } from '../PsychologistDetails/hooks/getPsychologistDetails';
import classNames from 'classnames';
import { getReservedAppointmentTimeString } from 'Recharge/utils/timezone';
import { RECHARGE_TIME_ZONE_LIST, rechargeTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { useRechargeDoDOrNonDoDPathGenerator } from 'Recharge/utils/Path/useRechargeDoDOrNonDoDPathGenerator';
import { useRedirectLoginClientNextEngageStep } from 'Recharge/utils/hooks/useRedirectLoginClientNextEngageStep';
import { useRechargeToken } from 'Recharge/utils/hooks/useRechargeToken';

const SignUp = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading } = useAuth0();

  const { isPreparingRedirect, redirectLoginClientNextEngageStep } = useRedirectLoginClientNextEngageStep();
  const { PRACTITIONER, SIGNUP, isDoDPath } = useRechargeDoDOrNonDoDPathGenerator();
  const { accountId, clinicianId, reserveId, appointmentTypeInfo } = useReserveAppointmentData();
  const { token, isInitialised } = useRechargeToken();
  const clientTimeZone = localStorage.getItem(rechargeTimeZoneLocalStorage) || RECHARGE_TIME_ZONE_LIST[0].id;

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  const [isAuthenticatedClientLoading, setIsAuthenticatedClientLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();

  useEffect(() => {
    if (!reserveId || !clinicianId) {
      navigate(PRACTITIONER.LISTING);

      if (clinicianId) {
        const detailsPagePath = generatePath(PRACTITIONER.DETAILS, { clinicianId });
        navigate(detailsPagePath);
      } else {
        navigate(PRACTITIONER.LISTING);
      }
    }
  }, [PRACTITIONER.DETAILS, PRACTITIONER.LISTING, clinicianId, navigate, reserveId]);

  const handleAuthenticatedClient = useCallback(async () => {
    if (accountId && reserveId) {
      setIsAuthenticatedClientLoading(true);

      await fetchClaimReservation({
        accountId: accountId!,
        reserveId: reserveId!,
        authToken: token!,
        shouldGenerateCheckoutSession: false
      });

      await redirectLoginClientNextEngageStep({
        accountId,
        reserveId,
        noLoginRedirectPath: SIGNUP.EMERGENCY_CONTACT
      });
    }
  }, [SIGNUP.EMERGENCY_CONTACT, accountId, redirectLoginClientNextEngageStep, reserveId, token]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && isInitialised && !isPreparingRedirect) {
      handleAuthenticatedClient();
    }
  }, [handleAuthenticatedClient, isAuthenticated, isInitialised, isLoading, isPreparingRedirect]);

  const proceedToNextStep = (value: UserSessionStorage, dob: string) => {
    storeUserSession(value, dob);
    scrollToView('RechargeHeader');
    navigate(SIGNUP.EMERGENCY_CONTACT);
  };

  const onSubmitHandle = async (values: typeof initialCustomer) => {
    setIsSubmitting(true);
    const formatMobile = getAuMobile(values.mobileNumber);
    const phoneValidate = await validatePhoneNumber(formatMobile);
    const checkEmailExisted = await checkEmailAlreadyExisted({
      accountId: accountId!,
      email: values.email
    });

    if (phoneValidate.valid) {
      if (checkEmailExisted?.response?.used === false) {
        try {
          const { email, firstName, lastName, password, communicationPreference, dateOfBirth, hearAboutSource } =
            values;
          const dob = moment(dateOfBirth).format('DD/MM/YYYY');

          const payloadMassage = {
            clinicianId,
            clientRecord: {
              avatar: '',
              mobile: formatMobile,
              email,
              name: firstName,
              password,
              dateOfBirth: dob,
              firstName,
              lastName,
              postcode: '',
              timeZone: clientTimeZone || RECHARGE_TIME_ZONE_LIST[0].id,
              communicationPreference,
              appointmentTypeId: appointmentTypeInfo?.sessionTypeId,
              hearAboutSource,
              ...(isDoDPath && { tag: 'Doctors on Demand' })
            }
          };
          const callPatientSignUp = await postPatientSignupWithoutInvitation(accountId || '', payloadMassage);

          const { clientRecord, authToken } = await callPatientSignUp.json();
          await fetchClaimReservation({
            accountId: accountId!,
            reserveId: reserveId!,
            authToken,
            shouldGenerateCheckoutSession: false
          });
          setIsSubmitting(false);
          proceedToNextStep({ clientRecord, authToken }, dob);
        } catch (ex) {
          setIsSubmitting(false);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        }
      } else {
        setIsSubmitting(false);
        setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." />);
      }
    } else {
      setIsSubmitting(false);
      setMobileNumberErrorMsg(
        <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
      );
    }
  };

  return isLoading || isAuthenticatedClientLoading || isPractitionerDetailsLoading || fetching ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <RechargeHelmetWrapper title={'Recharge Wellness | Sign Up'}>
      <RechargeContentLayout className="recharge-theme">
        <RechargeHeader withPadding classNames={styles.header} lightLogo />
        <div className={styles.container}>
          <div className={styles.leftContent}>
            <RechargeCreateProfileForm
              emailErrorMsg={emailErrorMsg}
              isSubmitting={isSubmitting}
              mobileNumberErrorMsg={mobileNumberErrorMsg}
              onSubmit={onSubmitHandle}
              setEmailErrorMsg={setEmailErrorMsg}
              setMobileNumberErrorMsg={setMobileNumberErrorMsg}
            />
          </div>
          <div className={styles.rightContent}>
            <div className={styles.notice}>
              <div className={styles.noticeMsg}>
                <div>To confirm these appointments you need to complete registration and payment within 15 minutes</div>
              </div>
              <RechargeTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} />
            </div>

            <div className={styles.holdingSlotCard}>
              <div className={styles.practitionerAvatarRow}>
                <div className={styles.leftCol}>
                  <img src={practitionerDetails.avatar} alt="avatar" />
                </div>
                <div className={styles.rightCol}>
                  We are holding your selected appointment slots with{' '}
                  <b>{practitionerDetails.name || 'Recharge Wellness'}</b>.
                </div>
              </div>
              <div className={styles.appointmentsRow}>
                <div className={classNames(styles.leftCol, styles.grayText)}>Holding:</div>
                <div className={styles.rightCol}>
                  {appointments.map((item, index) => (
                    <div key={index}>{getReservedAppointmentTimeString(item)}</div>
                  ))}
                </div>
              </div>
              {appointmentTypeInfo && (
                <div className={styles.appointmentTypeContainer}>
                  <div className={styles.title}>{appointmentTypeInfo.name}</div>
                  <div className={styles.description}>{appointmentTypeInfo.description}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </RechargeContentLayout>
      <TacklitFooter darkFooter />
    </RechargeHelmetWrapper>
  );
};

export default SignUp;
