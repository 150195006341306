import { useField, FieldInputProps } from 'formik';
import TextArea, { TextAreaProps } from './TextArea';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

interface FormikTextAreaProps extends Omit<TextAreaProps, keyof FieldInputProps<string>> {
  name: string;
}

const FormikTextArea = ({ name, ...props }: FormikTextAreaProps) => {
  const [field, { error, touched }] = useField<string>(name);

  return (
    <div>
      <TextArea {...props} {...field} name={name} />
      <ErrorMessage error={error} visible={touched && !!error} />
    </div>
  );
};

export default FormikTextArea;
