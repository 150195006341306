import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import { notification } from 'antd';
import { useGetClientDetails } from 'utils/hooks/AccountInfo/clientDetails';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import queryString from 'query-string';

const FormsPage = () => {
  const { formId = '' } = useParams<{
    formId: string;
  }>();
  const { search } = useLocation();
  const { assignmentId }: { assignmentId?: string } = queryString.parse(search);

  const { clientRecordId, clientProfileId } = useGetClientDetails();
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();

  const postFormSubmit = useCallback((e?: any) => {
    ///paperform event
    if (e && e.detail) {
      //notification, back to home page in 5 seconds
      notification.success({
        message: 'Form submitted successfully',
        description: 'Redirecting to home page in 5 seconds'
      });

      //redirect to home page after 5 seconds
      setTimeout(() => {
        window.location.href = '/';
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (!formId) {
      postFormSubmit();
    }
  }, [postFormSubmit, formId]);

  useEffect(() => {
    if (
      !Array.from(document.body.getElementsByTagName('script')).some(
        (elem) => elem.src === 'https://paperform.co/__embed.min.js'
      )
    ) {
      const script = document.createElement('script');
      script.src = 'https://paperform.co/__embed.min.js';
      document.body.appendChild(script);
    }

    window.addEventListener('PaperformSubmission', postFormSubmit);

    return () => {
      window.removeEventListener('PaperformSubmission', postFormSubmit);
    };
  }, [postFormSubmit]);

  return (
    <PortobelloHelmetWrapper title={'Portobello'}>
      <PortobelloContentLayout>
        <PortobelloHeader withPadding />
        {formId && !isClientProfileLoading && (
          <div
            data-paperform-id={formId}
            data-spinner
            data-prefill={`account_id=${
              clientProfileData?.accountId
            }&client_record_id=${clientRecordId}&client_profile_id=${clientProfileId}${
              assignmentId ? `&assignment_id=${assignmentId}` : ''
            }`}
          />
        )}
      </PortobelloContentLayout>
      <TacklitFooter />
    </PortobelloHelmetWrapper>
  );
};

export default FormsPage;
