import { getBentJsonResponse } from 'utils/httpClient';
import { patchMethod } from '../helper';

export interface CheckEmailAlreadyExistedResponse {
  used: boolean;
  isFullProfileType?: boolean;
}

export const checkEmailAlreadyExisted = async ({
  accountId,
  email
}: {
  accountId: string;
  email: string;
}): Promise<{ statusCode: number; response: CheckEmailAlreadyExistedResponse | null }> => {
  const httpResponse = await patchMethod([200, 403])(`/accounts/${accountId}/client-records:checkEmailAlreadyExisted`, {
    email
  });

  return {
    statusCode: httpResponse.statusCode,
    response:
      httpResponse.statusCode === 200 || httpResponse.statusCode === 403
        ? ((await getBentJsonResponse(httpResponse)) as CheckEmailAlreadyExistedResponse)
        : null
  };
};
