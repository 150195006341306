import styles from './HeaderContent.module.scss';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { ChapterInterface } from 'helm/interfaces/program/program';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import FirstLeftBackground from './FirstLeftBackground.png';
import classNames from 'classnames';
import ButtonHelm from 'helm/components/ButtonHelm/ButtonHelm';
import { getDeliveryTypeLabel } from 'utils/appointment';
import moment from 'moment';
import { checkIsTodayOrYesterday, generateAppointmentLabelAndInstructions } from '../../constants';
import { useMemo } from 'react';

interface HeaderContentProps {
  currentChapter?: ChapterInterface;
  isBeforeFirstSession?: boolean;
  isLoading: boolean;
  onClickToNextActivity: () => void;
}

const HeaderContent = ({
  currentChapter,
  isBeforeFirstSession,
  isLoading,
  onClickToNextActivity
}: HeaderContentProps) => {
  const appointmentDetails = useMemo(
    () =>
      currentChapter?.appointment && {
        details: `${currentChapter.appointment.duration} minutes, ${
          currentChapter.appointment.deliveryType &&
          getDeliveryTypeLabel(currentChapter.appointment.deliveryType, currentChapter.appointment.otherInstructions)
        } booked for ${
          currentChapter.appointment.date && checkIsTodayOrYesterday(currentChapter.appointment.date)
        } ${moment(currentChapter.appointment.startTime, 'hh:mm').format('hh:mmA')}.`,
        instructions: generateAppointmentLabelAndInstructions(currentChapter.appointment).instructions
      },
    [currentChapter?.appointment]
  );

  return (
    <div className={classNames(styles.container, currentChapter && !isBeforeFirstSession && styles.afterFirstSession)}>
      <HelmContentLayout className={styles.wrapper}>
        {currentChapter &&
          (isBeforeFirstSession ? (
            <img
              className={styles.firstLeftBackground}
              alt={`${currentChapter.name} left background`}
              src={FirstLeftBackground}
            />
          ) : (
            <div className={styles.nextSession}>
              Your
              <br />
              Next
              <br />
              Session
            </div>
          ))}
        {isLoading ? (
          <div className={styles.loadingWrapper}>
            <LoadingCircle className={styles.loading} />
          </div>
        ) : (
          currentChapter && (
            <>
              {!isBeforeFirstSession && <div className={styles.nextSessionTitle}>Your Next Session</div>}
              <HelmContentLayout className={isBeforeFirstSession ? styles.beforeFirstSessionContent : styles.content}>
                {isBeforeFirstSession ? (
                  <>
                    <div className={styles.title}>Welcome to the Power to Change Gambling Program</div>
                    <div className={styles.desc}>
                      This program is designed to support people who want to change their gambling behaviour. This
                      6-session program focuses on strategies and skills to support behaviour change. Each session is
                      structured and focused on a particular theme, skill or strategy.
                    </div>
                    <div className={styles.desc}>
                      In each session, you will be asked to complete at least one activity that will be populated into
                      your personal “Power to Change Plan”, which you will come back to and review in the final session.
                      Even if you have discussed or thought about the answers, we encourage you to complete the
                      activities. Writing down your thoughts can make the learning more concrete, supporting you in the
                      change journey. Bringing these together in the final session also gives you the opportunity to see
                      your learning over the Power to Change program and make decisions about your next steps.
                    </div>
                    <ButtonHelm onClick={onClickToNextActivity} className={styles.button}>
                      Start now
                    </ButtonHelm>
                  </>
                ) : (
                  <>
                    <div className={styles.background} style={{ backgroundImage: `url(${currentChapter.image})` }} />
                    <div className={styles.heading}>SESSION {currentChapter.chapterId}</div>
                    <div className={styles.title}>{currentChapter.name}</div>
                    <div className={styles.desc}>{currentChapter.description}</div>
                    {appointmentDetails && (
                      <>
                        <div className={`${styles.heading} ${styles.invertMargin}`}>WHEN</div>
                        <div className={styles.desc}>{appointmentDetails.details}</div>
                        <div className={`${styles.heading} ${styles.invertMargin}`}>HOW TO CONNECT</div>
                        {appointmentDetails.instructions && (
                          <div
                            className={styles.desc}
                            dangerouslySetInnerHTML={{ __html: appointmentDetails.instructions }}
                          />
                        )}
                      </>
                    )}
                    <ButtonHelm onClick={onClickToNextActivity} className={styles.button}>
                      Start this module
                    </ButtonHelm>
                  </>
                )}
              </HelmContentLayout>
            </>
          )
        )}
      </HelmContentLayout>
    </div>
  );
};

export default HeaderContent;
