import { Question, ScaleType } from 'interfaces/Assessment/OnboardingAssessment';
import HexSlider from '../HexSlider/HexSlider';
import { useMemo } from 'react';
import Vote from '../Vote/Vote';
import MultipleChoice from '../MultipleChoice/MultipleChoice';
import Rate from '../Rate/Rate';

interface SlidersWrapperProps {
  question: Question;
  defaultPatientDetails?: { name: string; picture: string };
  questionId?: string;
}

const ScaleWrapper = ({ question, defaultPatientDetails, questionId }: SlidersWrapperProps) => {
  const getSliderComponent = (
    question: Question
  ): ((props: {
    question: Question;
    defaultPatientDetails?: { name: string; picture: string };
    questionId?: string;
  }) => JSX.Element) => {
    switch (question.questionType) {
      default:
        switch (question.scaleType) {
          case ScaleType._1_5:
          case ScaleType._1_10:
            return HexSlider;
          case ScaleType.EmojiRating:
            return Rate;
          case ScaleType.EmojiReaction:
            return Vote;
          case ScaleType.AgreeDisagree:
          case ScaleType.GoodBadRating:
          case ScaleType.Frequency:
          case ScaleType.ImpactOfChange:
          case ScaleType.Likelihood:
          case ScaleType.Priority:
          case ScaleType.Recency:
          case ScaleType.Satisfaction:
            return MultipleChoice;
          default:
            throw new Error(
              `Question type ${question.questionType}, scale type ${question.scaleType || '-'} not supported`
            );
        }
    }
  };

  const InputComponent = useMemo(() => getSliderComponent(question), [question]);

  return (
    <>
      <InputComponent question={question} questionId={questionId} defaultPatientDetails={defaultPatientDetails} />
    </>
  );
};

export default ScaleWrapper;
