import Radio from 'components/Radio/Radio';
import { PublicPracticeProfile } from 'interfaces/Practice/practice';
import PracticeLayout from 'layouts/PracticeLayout/PracticeLayout';
import moment from 'moment';
import AvailabilityListing from 'pages/PatientAppointment/AppointmentBookingCalendar/components/AvailabilityListing/AvailabilityListing';
import { ChangeEvent, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  AppointmentSlot,
  AvailabilityAppointmentTypes,
  DeliveryType,
  OtherInstructions,
  useAvailability
} from 'utils/hooks/appointment';

import styles from './PracticeAvailability.module.scss';
import { defaultClinicianTimezone } from 'utils/constants/timeZone';
import { checkAppointmentTypeDeliveryOptionAvailability, getDeliveryTypeLabel } from 'utils/appointment';

const getDeliveryTypeFilterOptions = (otherInstructions?: OtherInstructions) => [
  { value: DeliveryType.FaceToFace, label: getDeliveryTypeLabel(DeliveryType.FaceToFace) },
  { value: DeliveryType.VideoCall, label: getDeliveryTypeLabel(DeliveryType.VideoCall) },
  // Only using phone call, but should include both phone call types when filtering
  { value: DeliveryType.PhoneCall, label: getDeliveryTypeLabel(DeliveryType.PhoneCall) },
  ...(otherInstructions
    ? [{ value: DeliveryType.Other, label: getDeliveryTypeLabel(DeliveryType.Other, otherInstructions) }]
    : [])
];

const PracticeAvailability = () => {
  const { slugUrl = '' } = useParams<{ slugUrl: string }>();
  const navigate = useNavigate();

  const location = useLocation();
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<
    Required<AppointmentSlot>['deliveryOptions'][number] | ''
  >('');

  const handleDeliveryOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newDeliveryOption = e.target.value as Required<AppointmentSlot>['deliveryOptions'][number];

    if (selectedDeliveryOption === newDeliveryOption) {
      setSelectedDeliveryOption('');
    } else {
      setSelectedDeliveryOption(newDeliveryOption);
    }
  };

  const { practiceProfile, appointmentType } = useMemo(() => {
    const { practiceProfile, appointmentType } =
      (location.state as
        | { practiceProfile: PublicPracticeProfile; appointmentType: AvailabilityAppointmentTypes }
        | undefined) || {};
    if (!practiceProfile || !appointmentType) {
      navigate(`/${slugUrl}/appointment`);
    }
    return { practiceProfile, appointmentType };
  }, [navigate, location.state, slugUrl]);

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailability({
    appointmentTypeId: appointmentType?._id!,
    accountId: practiceProfile?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(5, 'week').format('YYYY-MM-DD')
  });

  const isAvailabilityListEmpty = useMemo(() => {
    if (
      !appointmentAvailability ||
      (selectedDeliveryOption &&
        !checkAppointmentTypeDeliveryOptionAvailability(
          appointmentAvailability.appointmentType.deliveryOptions || [],
          selectedDeliveryOption
        )) ||
      !Object.values(appointmentAvailability.timeSlots).some(({ timeSlots }) => timeSlots && timeSlots.length > 0)
    ) {
      return true;
    }
    return false;
  }, [appointmentAvailability, selectedDeliveryOption]);

  const timezone = practiceProfile?.accountSettings?.timezone || defaultClinicianTimezone;

  return (
    <PracticeLayout>
      {practiceProfile && appointmentType && (
        <>
          <div className={styles.practiceHeader}>
            <div className={styles.offsetLeft}>
              <img src={practiceProfile.logo} className={styles.practiceLogo} alt={'practice logo'} />
              <div className={styles.backBtnWrapper} onClick={() => navigate(-1)}>
                <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
                BACK
              </div>
            </div>
            <div className={styles.practiceName}>{practiceProfile.name}</div>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.title}>Available times for</div>
            <div className={styles.appointmentTypeWrapper}>
              <div className={styles.appointmentName}>{appointmentType.name}</div>
              <div className={styles.filterTitle}>filter</div>
              <div className={styles.filterList}>
                <Radio
                  variant={'button'}
                  options={getDeliveryTypeFilterOptions(appointmentType.otherInstructions)}
                  value={selectedDeliveryOption}
                  onChange={handleDeliveryOptionChange}
                />
              </div>
            </div>
            <AvailabilityListing
              appointmentType={appointmentAvailability?.appointmentType}
              availabilities={appointmentAvailability?.timeSlots}
              practiceProfile={practiceProfile}
              isAvailabilityListEmpty={isAvailabilityListEmpty}
              isAvailabilityListLoading={isAvailabilityListLoading}
              paymentRequired={appointmentType.paymentRequired}
              timezone={timezone}
            />
          </div>
        </>
      )}
    </PracticeLayout>
  );
};

export default PracticeAvailability;
