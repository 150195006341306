import { ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import { InputProps } from 'components/Input/Input';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PhoneInput from 'react-phone-input-2';
import styles from './EaseMobileFormikInput.module.scss';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { getUkMobile } from '../EaseCreateProfileForm/constants';
import { config } from 'config/config';

interface EaseMobileMaterialInputProps extends Omit<InputProps, 'hasError'> {
  name: string;
  mobilePattern?: string;
  error?: string;
  errorClass?: string;
  phonePrefix?: string;
  isMobileValid?: boolean;
  enableCountryCode?: boolean;
}

const isDevEnv = isDevelopmentENV();
const PREFERRED_COUNTRY = config.countryCode;

const EaseMobileMaterialInput = ({
  className,
  name,
  onChange,
  onKeyUp,
  mobilePattern = '___ ___ ___',
  label,
  inputClass,
  phonePrefix,
  isMobileValid = true,
  error,
  errorClass,
  enableCountryCode,
  ...props
}: EaseMobileMaterialInputProps) => {
  const [value, setValue] = useState(phonePrefix);
  const [focus, setFocus] = useState<boolean | null>(null);
  const [touched, setTouched] = useState<boolean>(false);
  const getDisplayValue = (v: string) => {
    if (!v) {
      return mobilePattern;
    }
    let display = mobilePattern;
    v.split('').forEach((element) => {
      display = display.replace('_', element);
    });
    return display;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const containerClasses = classnames(
    styles.container,
    className,
    focus === true && styles.focus,
    touched && !!error && styles.error,
    !touched && styles.notTouched,
    value && styles.haveText
  );
  return (
    <div className={containerClasses}>
      {label && <div className={classnames(styles.label, props.labelClass)}>{label}</div>}
      <input hidden value={value} onChange={handleChange} />
      <div>
        <PhoneInput
          containerClass={classnames(
            enableCountryCode ? styles.enableCountryFlagFrontInput : styles.frontInput,
            styles.input,
            inputClass,
            enableCountryCode && styles.enableCountryFlag
          )}
          inputClass={classnames(enableCountryCode && styles.countryCodeInput)}
          buttonClass={classnames(enableCountryCode && styles.countryCodeDropdown)}
          dropdownClass={classnames(enableCountryCode && styles.countryDropdown)}
          onChange={(e, x, event) => {
            let value = e?.toString() || '';
            if (value.startsWith('9')) {
              value = '';
            }
            setValue(value);
            handleChange({ ...event, target: { ...event.target, name: name, value: getUkMobile(e) } });
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setFocus(false);
            setTouched(true);
          }}
          masks={isDevEnv ? {} : { au: mobilePattern.split('_').join('.') }}
          placeholder={props.placeholder}
          value={value}
          disableCountryCode={!enableCountryCode && !isDevEnv}
          showDropdown={false}
          {...(enableCountryCode &&
            PREFERRED_COUNTRY && {
              country: PREFERRED_COUNTRY,
              preferredCountries: [PREFERRED_COUNTRY]
            })}
        />
        {value && (
          <div className={styles.backInput}>
            {getDisplayValue(value)
              .split('')
              .map((i, index) =>
                /\d/g.test(i) ? (
                  <span key={index} className={styles.noVisibleChar}>
                    {i}
                  </span>
                ) : i === ' ' ? (
                  <span key={index} className={styles.noVisibleChar}>
                    {'.'}
                  </span>
                ) : (
                  <span key={index}>{i}</span>
                )
              )}
          </div>
        )}
      </div>
      {isMobileValid ? (
        <ErrorMessage className={errorClass} error={error} visible={touched && !!error} />
      ) : (
        <ErrorMessage className={errorClass} error={'Invalid mobile number'} visible />
      )}
    </div>
  );
};

export default EaseMobileMaterialInput;
