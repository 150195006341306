import { useRef, useState } from 'react';
import styles from './TimeFilterDropdown.module.scss';
import classnames from 'classnames';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

export interface listInterface {
  id: string;
  label: string;
  desc?: string;
}

interface TimeFilterDropdownProps {
  listData: listInterface[];
  leftListAlign?: boolean;
  className?: string;
  onChange: (value: listInterface) => void;
}

const TimeFilterDropdown = ({ listData, leftListAlign, className, onChange }: TimeFilterDropdownProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [selectedValue, setSelectedValue] = useState(listData[0]);

  const handleCloseMenu = () => {
    setShowList(false);
  };

  const handleSelectButtonClick = () => {
    if (showList) {
      handleCloseMenu();
    } else {
      setShowList(true);
    }
  };

  const clickOutsideHandler = () => {
    handleCloseMenu();
  };

  useOnClickOutside(menuRef, clickOutsideHandler);

  const handleChangeFilter = (val: listInterface) => {
    setSelectedValue(val);
    handleCloseMenu();
    onChange(val);
  };

  return (
    <div ref={menuRef} className={classnames(styles.container, className)}>
      <div className={styles.selectButton} onClick={handleSelectButtonClick}>
        {selectedValue.label}
        <i className={`material-icons ${styles.icon}`}>keyboard_arrow_down</i>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {listData.map((listObj, index) => (
            <div key={index} className={styles.listBox} onClick={() => handleChangeFilter(listObj)}>
              <div
                style={{ textAlign: leftListAlign ? 'left' : listObj?.desc ? 'left' : 'center' }}
                className={styles.title}
              >
                {listObj.label}
              </div>
              <div className={styles.desc}>{listObj.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeFilterDropdown;
