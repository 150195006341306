import Loading from 'components/Loading/Loading';
import { useFetchReservedAppointmentsWithTimeZone } from 'CaW/utils/hooks/appointment';
import { useContext, useEffect, useRef, useState } from 'react';
import Summary from 'CaW/pages/CaWBookingConfirmation/components/Summary/Summary';
import { useReserveAppointmentData } from 'CaW/utils/hooks/localData';
import { useCawUserSession } from '../SignUp/helper/userSession';
import styles from './CaWBookingConfirmation.module.scss';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import { UserContext } from 'utils/UserContextProvider';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { cawTimeZoneLocalStorage } from '../ClinicianListing/ClinicianListing';
import classNames from 'classnames';
import { fetchClaimingReservation } from 'CaW/utils/hooks/useClaimReservationWithoutCheckoutSession';
import CaWHelmetWrapper from '../../components/CaWHelmetWrapper/CaWHelmetWrapper';
import CaWHeader from '../../components/CaWHeader/CaWHeader';
import CaWContentLayout from '../../components/CaWContentLayout/CaWContentLayout';
import CaWTimer from '../../components/CaWTimer/CaWTimer';
import ButtonCaW, { ButtonStatusType } from '../../components/ButtonCaW/ButtonCaW';
import { useCaWToken } from '../../utils/hooks/useCaWToken';
import { useCaWRoutesGenerator } from '../../utils/Path/CaWRoutesGenerator';
import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';
import { AU_TIME_ZONE_LIST } from 'utils/constants/timeZone';

const CaWBookingConfirmation = () => {
  const clientTimeZone = localStorage.getItem(cawTimeZoneLocalStorage) || AU_TIME_ZONE_LIST[0].id;
  const { token } = useCaWToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientProfile } = useContext(UserContext);

  const { retrieveUserSession } = useCawUserSession();
  const { SIGNUP } = useCaWRoutesGenerator();
  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');

  const bookingContentRef = useRef<HTMLDivElement>(null);

  const retrieveUserProfile = retrieveUserSession()?.clientRecord?.clientProfiles || [];
  const { reserveId, accountId } = useReserveAppointmentData();
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentUrl = window.location.origin;

  const refreshAppointmentQuota = () => {
    dispatch(clinicianProfileServicesApiSlice.util.invalidateTags(['ClientRecordsMe']));
  };

  const handleConfirmBooking = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        await fetchClaimingReservation(reserveId, accountId, token);

        const res = await putProcessWithPaymentMethod(
          accountId,
          reserveId,
          { cancelUrl: `${currentUrl}${SIGNUP.CONFIRM_BOOKING}`, returnUrl: `${currentUrl}${SIGNUP.WELCOME_BOOKING}` },
          token
        );

        if (res.statusCode === 200) {
          setConfirmBookingStatus('finished');
          const responseData = await res.json();
          refreshAppointmentQuota();
          if (responseData.checkoutUrl) {
            window.location.href = responseData.checkoutUrl;
          }
        } else if (res.statusCode === 204) {
          setConfirmBookingStatus('finished');
          refreshAppointmentQuota();
          navigate(SIGNUP.WELCOME_BOOKING);
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return (
    <div ref={bookingContentRef}>
      <CaWHelmetWrapper title={'Caraniche at Work - Booking confirmation'}>
        <CaWHeader withPadding withMenu blueMenu />
        <CaWContentLayout className={styles.layoutClassName}>
          {fetching ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div className={classNames(styles.container, 'caw-theme')}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <div className={styles.greet}>
                    Thanks{' '}
                    <div className={styles.name}>
                      {clientProfile ? clientProfile.firstName : retrieveUserProfile?.[0]?.firstName}
                    </div>
                  </div>
                  <Summary
                    isReservedAppointmentFetching={fetching}
                    reservedAppointments={appointments}
                    containerClassName={styles.summaryContainer}
                  />
                  <ButtonCaW
                    status={confirmBookingStatus}
                    onClick={handleConfirmBooking}
                    disabled={!token}
                    className={styles.submitButton}
                  >
                    Confirm your booking
                  </ButtonCaW>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.notice}>
                    <CaWTimer
                      isReservedAppointmentFetching={fetching}
                      reservedAppointments={appointments}
                      onlyText={mobileTimer}
                    />
                    <div className={styles.noticeMsg}>
                      <div>To confirm these appointments you need to complete registration within 15 minutes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </CaWContentLayout>
        <TacklitFooter />
      </CaWHelmetWrapper>
    </div>
  );
};

export default CaWBookingConfirmation;
