import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useEffect, useMemo, useState } from 'react';
import styles from './Booking.module.scss';
import AddGPReferralForm, {
  GPReferral,
  PROVIDE_REFERRAL_DOCUMENTS
} from './components/AddGPReferralForm/AddGPReferralForm';
import MedicareCard from './components/MedicareCard/MedicareCard';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import classNames from 'classnames';
import { MedicareInformation, ReferralDetailUpdateInterface } from 'components/UpdateProfileContent/interface';
import { retrieveUserSession } from 'SomeoneHealth/pages/SignUp/helper/userSession';
import SomeoneHealthContentLayout from '../../components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import { notification } from 'antd';
import {
  putClientMedicare,
  putClientReferralDetail
} from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { useSomeoneHealthToken } from 'SomeoneHealth/utils/hooks/useSomeoneHealthToken';
import { useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useFetchReservedAppointmentsWithTimeZone } from 'SomeoneHealth/utils/hooks/appointment';
import SomeoneHealthTimer from '../SomeoneHealthTimer/SomeoneHealthTimer';
import TacklitFooter from '../../../components/TacklitFooter/TacklitFooter';
import moment from 'moment';
import { debounce } from 'lodash';
import { SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage } from 'utils/constants/timeZone';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import Loading from 'components/Loading/Loading';
import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { useDispatch } from 'react-redux';
import { ga4EventPush } from 'utils/GAEvent';

export const MEDICARE_CARD_DEFAULT_VALUE: MedicareInformation = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  number: undefined,
  irn: undefined,
  expiryDate: ''
};

export const GP_REFERRAL_DEFAULT_VALUE: GPReferral = {
  files: []
};

const Booking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSomeoneHealthToken();
  const { SIGNUP, SIGNUP_DECOUPLE_GP, GP } = useSomeoneHealthRoutesGenerator();
  const { clientProfileData: clientProfile, isClientProfileLoading } = useGetClientProfileDetails();

  const [provideReferralDocuments, setProvideReferralDocuments] = useState(PROVIDE_REFERRAL_DOCUMENTS[0].value);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  const clientTimeZone = localStorage.getItem(someoneHealthTimeZoneLocalStorage) || SOMEONE_HEALTH_TIME_ZONE_LIST[0].id;

  const retrieveUserProfile = retrieveUserSession().clientRecord?.clientProfiles || [];
  const massageMedicareDefaultValue: MedicareInformation = {
    ...MEDICARE_CARD_DEFAULT_VALUE,
    firstName: retrieveUserProfile[0]?.firstName || '',
    lastName: retrieveUserProfile[0]?.lastName || '',
    dateOfBirth: retrieveUserProfile[0]?.dateOfBirth || ''
  };

  const clientProfileData: MedicareInformation = {
    ...massageMedicareDefaultValue,
    ...(clientProfile && {
      firstName: clientProfile.firstName,
      lastName: clientProfile.lastName,
      dateOfBirth: clientProfile.dateOfBirth,
      ...clientProfile.medicare
    })
  };

  const [medicareCard, setMedicareCard] = useState<MedicareInformation>(
    clientProfile ? clientProfileData : massageMedicareDefaultValue
  );
  const [gpReferralValue, setGPReferralValue] = useState(GP_REFERRAL_DEFAULT_VALUE);

  const [isMedicareCardValidating, setIsMedicareValidating] = useState(false);
  const [isMedicareValueChanged, setIsMedicareValueChanged] = useState(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>(
    medicareCard.status || {}
  );
  const reserveAppointmentData = useReserveAppointmentData();
  const { accountId, reserveId } = reserveAppointmentData;
  const { fetching, appointments } = useFetchReservedAppointmentsWithTimeZone({
    reserveId: reserveId!,
    accountId: accountId!,
    timeZone: clientTimeZone
  });

  const [haveGPReferralPlan, setHaveGPReferralPlan] = useState(true);
  const [useSignUpInfoToggle, setUseSignUpInfoToggle] = useState<boolean>(!clientProfile?.medicare);

  const onNextStep = async () => {
    if (clientProfile && (clientProfile.referral || clientProfile.isReferralSentByGP)) {
      navigate(SIGNUP.CONFIRM_BOOKING);
    } else if (token) {
      await submitGPReferral(token);
    }
  };

  const saveMedicareInfo = async (value: MedicareInformation, token: string) => {
    try {
      const response = await putClientMedicare(token, {
        ...value,
        expiryDate: value.expiryDate || null,
        dateOfBirth: moment(value.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
      });
      setIsMedicareValueChanged(false);

      if (response.statusCode === 200) {
        const medicareResponse = await response.json();
        const valueMe = {
          ...medicareResponse,
          dateOfBirth: moment(medicareResponse.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
        };
        setMedicareErrorMessage(medicareResponse.status);
        setMedicareCard(valueMe);
      } else if (response.statusCode === 400) {
        setMedicareErrorMessage({
          medicare: {
            code: 9633
          }
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({ message: 'Something went wrong while trying to update your Medicare information' });
    } finally {
      setIsMedicareValidating(false);
    }
  };

  const callMedicareUpdating = useMemo(
    () =>
      debounce((formValues: MedicareInformation) => {
        if (token) {
          saveMedicareInfo(formValues, token);
        }
      }, 2000),
    // eslint-disable-next-line
    [token]
  );

  useEffect(() => {
    if (isMedicareValueChanged && medicareCard) {
      const { number, irn, expiryDate, dva } = medicareCard;
      if ((number && irn && expiryDate !== '') || dva) {
        setIsMedicareValidating(true);
        callMedicareUpdating(medicareCard);
      }
    }
    // eslint-disable-next-line
  }, [isMedicareValueChanged, medicareCard]);

  const submitGPReferral = async (token: string) => {
    setButtonStatus('active');
    try {
      const payload: ReferralDetailUpdateInterface = {
        files: [],
        isReferredByGP: true,
        isHaveTreatmentPlan: true,
        isGPSentReferral: provideReferralDocuments === 'gp',
        addToReferralGroup: true
      };

      if (gpReferralValue.files) {
        payload.files = await Promise.all(
          gpReferralValue.files.map(
            (file) =>
              uploadDocumentWithEncryption(token, file) as Promise<{
                bucketName: string;
                fileName: string;
                fileUrl: string;
              }>
          )
        );
      }

      const res = await putClientReferralDetail(token, payload);

      if (res.statusCode === 204) {
        notification.success({ message: 'Your referral detail has been saved!' });
        setButtonStatus('finished');
        dispatch(clinicianProfileServicesApiSlice.util.invalidateTags(['ClientRecordsMe']));
        navigate(SIGNUP.CONFIRM_BOOKING);
      } else if (res.statusCode === 400) {
        notification.error({
          message: await res.text(),
          duration: 2,
          closeIcon: <span className="success">OK</span>
        });
      }
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    } catch (e) {
      notification.error({
        message: 'Referral details fail to update',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    setTimeout(() => {
      setButtonStatus('');
    }, 1000);
  };

  const handleNoGPNextStep = () => {
    navigate(clientProfile?.hasSavedCard ? GP.BASE : SIGNUP_DECOUPLE_GP.WELCOME);
    if (!clientProfile && retrieveUserProfile.length > 0) {
      ga4EventPush('onCompleteNewClientRegisterWithNoGPReferral', {
        clientId: retrieveUserProfile[0]._id
      });
    } else {
      ga4EventPush('onCompleteReturningClientRegisterWithNoGPReferral', {
        clientId: clientProfile?._id
      });
    }
  };

  const isCheckMedicareValid =
    medicareCard.status?.medicare?.code !== 0 && medicareCard.status?.medicare?.code !== 9626;

  const checkReferralFilesValid = clientProfile?.referral?.files?.length || gpReferralValue.files.length;

  const isDisabledWithOwnReferral =
    isMedicareCardValidating ||
    isCheckMedicareValid ||
    (!clientProfile?.referral && provideReferralDocuments === 'client' && !checkReferralFilesValid);

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Booking'}>
      <SomeoneHealthHeader pinkLogo withPadding withMenu pinkMenu />
      {isClientProfileLoading ? (
        <Loading />
      ) : (
        <div className={classNames(styles.container, 'someone-health-theme')}>
          <SomeoneHealthContentLayout>
            <div className={styles.medicareContainer}>
              <div className={styles.header}>
                Welcome to <span className={styles.highlight}>someone.health</span>
                <div className={styles.subHeader}>
                  To confirm your appointment you will need your <b>Medicare</b> card and your <b>referral</b>.
                </div>
              </div>
              <MedicareCard
                medicareCardValue={medicareCard}
                setMedicareCardValue={setMedicareCard}
                useSignUpInfoToggle={useSignUpInfoToggle}
                setUseSignUpInfoToggle={() => setUseSignUpInfoToggle(!useSignUpInfoToggle)}
                showCheckToggle={!clientProfile?.medicare}
                isMedicareCardValidating={isMedicareCardValidating}
                medicareErrorMessage={medicareErrorMessage}
                setIsMedicareValueChanged={setIsMedicareValueChanged}
              />
            </div>
            {(!clientProfile || !clientProfile.referral) && (
              <div className={styles.treatmentPlanContainer}>
                <div className={styles.treatmentPlan}>
                  {!clientProfile?.referral ? (
                    <>
                      <div className={styles.label}>Do you have GP Referral and Mental Health Treatment Plan?</div>
                      <ToggleSwitch
                        buttonWidth={323}
                        id={''}
                        checkLabel={'YES'}
                        unCheckLabel={'NO'}
                        isCheckedProps={haveGPReferralPlan}
                        onChangeProps={() => setHaveGPReferralPlan(!haveGPReferralPlan)}
                      />
                    </>
                  ) : (
                    <div className={styles.gpDetailsLabel}>GP Details</div>
                  )}
                </div>
              </div>
            )}
          </SomeoneHealthContentLayout>
          {(!clientProfile || !clientProfile.referral) && (
            <>
              {haveGPReferralPlan && token ? (
                <SomeoneHealthContentLayout>
                  <AddGPReferralForm
                    gpReferralValue={gpReferralValue}
                    files={clientProfile?.referral?.files}
                    provideReferralDocuments={provideReferralDocuments}
                    setProvideReferralDocuments={setProvideReferralDocuments}
                    setGPReferralValue={setGPReferralValue}
                    token={token}
                  />
                </SomeoneHealthContentLayout>
              ) : (
                <div className={styles.noTreatmentPlan}>
                  <SomeoneHealthContentLayout>
                    <div className={styles.noGPDesc}>
                      We can help you get a GP referral - you’ll need one to see a psychologist.
                      <br />
                      <br />
                      We have a team of friendly GPs who are available for bulk billed video consultations to develop a
                      Mental Health Treatment Plan with you and provide the necessary referral.
                      <br />
                      <br />
                      There are usually appointments available with our GPs in the next few days. Once you have attended
                      your bulk billed GP appointment, you’ll be able to come back and book in with your preferred
                      psychologist.
                    </div>
                  </SomeoneHealthContentLayout>
                </div>
              )}
            </>
          )}
          <SomeoneHealthContentLayout>
            <div className={styles.btnContainer}>
              {haveGPReferralPlan ? (
                <ButtonSH
                  icon={'navigate_next'}
                  iconPostFix
                  disabled={isDisabledWithOwnReferral}
                  className={styles.submitButton}
                  onClick={onNextStep}
                  status={buttonStatus}
                >
                  Continue to last step
                </ButtonSH>
              ) : (
                <ButtonSH
                  icon={'navigate_next'}
                  iconPostFix
                  disabled={isMedicareCardValidating || isCheckMedicareValid}
                  className={styles.submitButton}
                  onClick={handleNoGPNextStep}
                  status={buttonStatus}
                >
                  Complete registration and make a GP appointment
                </ButtonSH>
              )}
            </div>
          </SomeoneHealthContentLayout>
        </div>
      )}
      <SomeoneHealthTimer isReservedAppointmentFetching={fetching} reservedAppointments={appointments} hideTimer />
      <TacklitFooter />
    </SomeoneHealthHelmetWrapper>
  );
};

export default Booking;
