/* eslint-disable react/no-multi-comp */
import { Component, FunctionComponent, useEffect } from 'react';
import { Routes, Route, RouteProps } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { getToken } from 'utils/http/authService';
import { getPatientToken, setPatientToken } from 'utils/patientToken';

import NoAuthLayout from 'layouts/NoAuthLayout/NoAuthLayout';
import Loading from 'components/Loading/Loading';

interface PatientRouteProps {
  component: typeof Component | FunctionComponent;
}

const PatientRoute = ({ component: Component, ...props }: PatientRouteProps & RouteProps) => {
  const { getAccessTokenSilently, user } = useAuth0();

  if (user?.['https://tacklit.com/roles'].includes('clinician') && process.env.REACT_APP_CLINICIAN_DOMAIN) {
    window.location.href = process.env.REACT_APP_CLINICIAN_DOMAIN;
  }

  // disabling wall temporarily (?)
  // if (!user.email_verified) {
  //   navigate('/unverified');
  // }

  const checkAndSetPatientToken = async () => {
    const patientToken = getPatientToken();

    if (!patientToken) {
      try {
        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_API_AUDIENCE
        });

        const callGetToken = await getToken(auth0Token);

        const { token } = await callGetToken.json();

        setPatientToken(token);
      } catch (ex) {
        console.error(ex);
      }
    }
  };

  useEffect(() => {
    checkAndSetPatientToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="*" element={<Component {...props} />} />
    </Routes>
  );
};

export default withAuthenticationRequired(PatientRoute, {
  loginOptions: {
    loginType: 'patient'
  },
  onRedirecting: () => (
    <NoAuthLayout>
      <Loading />
    </NoAuthLayout>
  )
});
