import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import * as Yup from 'yup';

export const initialClientRecordOnly = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  mobileNumber: '',
  email: '',
  referralBackground: '',
  officeLocation: '',
  purchaseOrderNo: ''
};

export const getAuMobile = (v: string) =>
  v && !isDevelopmentENV() ? '+61' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`;

export const formDataValidationSchema = (customAgeCheck?: number) => {
  const dobAge = customAgeCheck || 18;

  return Yup.object().shape({
    firstName: Yup.string().required('Please enter client first name'),
    lastName: Yup.string().required('Please enter client last name'),
    email: Yup.string().required('Please enter client email').email('Email must be in valid example@gmail.com format.'),
    dateOfBirth: Yup.string()
      .required('Please choose client date of birth')
      .test('Age check', `Be sure that you're over the age of ${dobAge}`, (dob) => {
        return moment(dob, 'YYYY-MM-DD').add(dobAge, 'years').isBefore(moment());
      }),
    mobileNumber: Yup.string().required('Please enter contact number'),
    officeLocation: Yup.string().required('Please enter the WISE office location'),
    purchaseOrderNo: Yup.string().required('Please enter the purchase order number')
  });
};

export enum FundingStreamType {
  WISE = 'wise',
  MEDICARE = 'medicare',
  NDIS = 'ndis'
}

export const GP_REFERRAL_VALUE = {
  YES: 'yes',
  NO: 'no'
};

export const GP_REFERRAL = [
  {
    value: GP_REFERRAL_VALUE.NO,
    label: 'No'
  },
  {
    value: GP_REFERRAL_VALUE.YES,
    label: 'Yes'
  }
];

export const WISE_OFFICE_LOCATIONS = [
  { label: 'NSW - Ashfield - 2131', value: 'NSW - Ashfield - 2131' },
  { label: 'NSW - Auburn - 2144', value: 'NSW - Auburn - 2144' },
  { label: 'NSW - Bankstown - 2200', value: 'NSW - Bankstown - 2200' },
  { label: 'NSW - Blacktown - 2148', value: 'NSW - Blacktown - 2148' },
  { label: 'NSW - Burwood - 2134', value: 'NSW - Burwood - 2134' },
  { label: 'NSW - Cabramatta - 2166', value: 'NSW - Cabramatta - 2166' },
  { label: 'NSW - Camden - 2570', value: 'NSW - Camden - 2570' },
  { label: 'NSW - Campbelltown - 2560', value: 'NSW - Campbelltown - 2560' },
  { label: 'NSW - Chatswood - 2067', value: 'NSW - Chatswood - 2067' },
  { label: 'NSW - Darlinghurst - 2010', value: 'NSW - Darlinghurst - 2010' },
  { label: 'NSW - Fairfield - 2165', value: 'NSW - Fairfield - 2165' },
  { label: 'NSW - Horningsea Park - 2171', value: 'NSW - Horningsea Park - 2171' },
  { label: 'NSW - Hornsby - 2077', value: 'NSW - Hornsby - 2077' },
  { label: 'NSW - Hurstville - 2220', value: 'NSW - Hurstville - 2220' },
  { label: 'NSW - Lakemba - 2195', value: 'NSW - Lakemba - 2195' },
  { label: 'NSW - Liverpool - 2170', value: 'NSW - Liverpool - 2170' },
  { label: 'NSW - Macquarie Fields - 2564', value: 'NSW - Macquarie Fields - 2564' },
  { label: 'NSW - Marrickville - 2204', value: 'NSW - Marrickville - 2204' },
  { label: 'NSW - Mt Druitt - 2770', value: 'NSW - Mt Druitt - 2770' },
  { label: 'NSW - Parramatta - 2150', value: 'NSW - Parramatta - 2150' },
  { label: 'NSW - Penrith - 2750', value: 'NSW - Penrith - 2750' },
  { label: 'NSW - Revesby - 2212', value: 'NSW - Revesby - 2212' },
  { label: 'NSW - Rouse Hill - 2155', value: 'NSW - Rouse Hill - 2155' },
  { label: 'NSW - Ryde - 2112', value: 'NSW - Ryde - 2112' },
  { label: 'NSW - Springwood - 2777', value: 'NSW - Springwood - 2777' },
  { label: 'NSW - Sutherland - 2232', value: 'NSW - Sutherland - 2232' },
  { label: 'NSW - Windsor - 2756', value: 'NSW - Windsor - 2756' },
  { label: 'NT - Casuarina - 810', value: 'NT - Casuarina - 810' },
  { label: 'NT - Palmerston - 830', value: 'NT - Palmerston - 830' },
  { label: 'QLD - Beenleigh - 4207', value: 'QLD - Beenleigh - 4207' },
  { label: 'QLD - Boonah - 4310', value: 'QLD - Boonah - 4310' },
  { label: 'QLD - Browns Plains - 4118', value: 'QLD - Browns Plains - 4118' },
  { label: 'QLD - Caboolture - 4510', value: 'QLD - Caboolture - 4510' },
  { label: 'QLD - Caloundra - 4551', value: 'QLD - Caloundra - 4551' },
  { label: 'QLD - Chermside - 4032', value: 'QLD - Chermside - 4032' },
  { label: 'QLD - Coolangatta - 4225', value: 'QLD - Coolangatta - 4225' },
  { label: 'QLD - Goodna - 4300', value: 'QLD - Goodna - 4300' },
  { label: 'QLD - Inala - 4077', value: 'QLD - Inala - 4077' },
  { label: 'QLD - Ipswich - 4305', value: 'QLD - Ipswich - 4305' },
  { label: 'QLD - Logan Central - 4114', value: 'QLD - Logan Central - 4114' },
  { label: 'QLD - Maroochydore - 4558', value: 'QLD - Maroochydore - 4558' },
  { label: 'QLD - Murwillumbah - 2484', value: 'QLD - Murwillumbah - 2484' },
  { label: 'QLD - Nambour - 4560', value: 'QLD - Nambour - 4560' },
  { label: 'QLD - Nerang - 4211', value: 'QLD - Nerang - 4211' },
  { label: 'QLD - Noosaville - 4566', value: 'QLD - Noosaville - 4566' },
  { label: 'QLD - Palm Beach - 4221', value: 'QLD - Palm Beach - 4221' },
  { label: 'QLD - Redcliffe - 4020', value: 'QLD - Redcliffe - 4020' },
  { label: 'QLD - Southport - 4215', value: 'QLD - Southport - 4215' },
  { label: 'QLD - Strathpine - 4500', value: 'QLD - Strathpine - 4500' },
  { label: 'QLD - Tweed Heads South - 2486', value: 'QLD - Tweed Heads South - 2486' },
  { label: 'QLD - Upper Coomera - 4209', value: 'QLD - Upper Coomera - 4209' },
  { label: 'QLD - Upper Mount Gravatt - 4122', value: 'QLD - Upper Mount Gravatt - 4122' },
  { label: 'QLD - Woodford - 4514', value: 'QLD - Woodford - 4514' },
  { label: 'SA - Adelaide - 5000', value: 'SA - Adelaide - 5000' },
  { label: 'SA - Barmera - 5345', value: 'SA - Barmera - 5345' },
  { label: 'SA - Berri - 5343', value: 'SA - Berri - 5343' },
  { label: 'SA - Black Forest - 5035', value: 'SA - Black Forest - 5035' },
  { label: 'SA - Bordertown - 5268', value: 'SA - Bordertown - 5268' },
  { label: 'SA - Campbelltown - 5074', value: 'SA - Campbelltown - 5074' },
  { label: 'SA - Clare - 5453', value: 'SA - Clare - 5453' },
  { label: 'SA - Elizabeth - 5112', value: 'SA - Elizabeth - 5112' },
  { label: 'SA - Fullarton - 5063', value: 'SA - Fullarton - 5063' },
  { label: 'SA - Gawler - 5118', value: 'SA - Gawler - 5118' },
  { label: 'SA - Hallett Cove - 5158', value: 'SA - Hallett Cove - 5158' },
  { label: 'SA - Hindmarsh - 5007', value: 'SA - Hindmarsh - 5007' },
  { label: 'SA - Kadina - 5554', value: 'SA - Kadina - 5554' },
  { label: 'SA - Kapunda - 5373', value: 'SA - Kapunda - 5373' },
  { label: 'SA - Kingston - 5275', value: 'SA - Kingston - 5275' },
  { label: 'SA - Lobethal - 5241', value: 'SA - Lobethal - 5241' },
  { label: 'SA - Loxton - 5333', value: 'SA - Loxton - 5333' },
  { label: 'SA - Meadows - 5201', value: 'SA - Meadows - 5201' },
  { label: 'SA - Milang - 5256', value: 'SA - Milang - 5256' },
  { label: 'SA - Mile End - 5031', value: 'SA - Mile End - 5031' },
  { label: 'SA - Millicent - 5280', value: 'SA - Millicent - 5280' },
  { label: 'SA - Modbury - 5092', value: 'SA - Modbury - 5092' },
  { label: 'SA - Mount Barker - 5251', value: 'SA - Mount Barker - 5251' },
  { label: 'SA - Mount Gambier - 5290', value: 'SA - Mount Gambier - 5290' },
  { label: 'SA - Munno Para - 5115', value: 'SA - Munno Para - 5115' },
  { label: 'SA - Naracoorte - 5271', value: 'SA - Naracoorte - 5271' },
  { label: 'SA - Noarlunga - 5168', value: 'SA - Noarlunga - 5168' },
  { label: 'SA - Nuriootpa - 5355', value: 'SA - Nuriootpa - 5355' },
  { label: 'SA - Oakbank - 5243', value: 'SA - Oakbank - 5243' },
  { label: 'SA - Oaklands Park - 5046', value: 'SA - Oaklands Park - 5046' },
  { label: 'SA - Penola - 5277', value: 'SA - Penola - 5277' },
  { label: 'SA - Port Adelaide - 5015', value: 'SA - Port Adelaide - 5015' },
  { label: 'SA - Port Augusta - 5700', value: 'SA - Port Augusta - 5700' },
  { label: 'SA - Port Lincoln - 5606', value: 'SA - Port Lincoln - 5606' },
  { label: 'SA - Renmark - 5341', value: 'SA - Renmark - 5341' },
  { label: 'SA - Robe - 5276', value: 'SA - Robe - 5276' },
  { label: 'SA - Salisbury - 5108', value: 'SA - Salisbury - 5108' },
  { label: 'SA - Strathalbyn - 5255', value: 'SA - Strathalbyn - 5255' },
  { label: 'SA - Tanunda - 5352', value: 'SA - Tanunda - 5352' },
  { label: 'SA - Waikerie - 5330', value: 'SA - Waikerie - 5330' },
  { label: 'SA - Whyalla - 5600', value: 'SA - Whyalla - 5600' },
  { label: 'SA - Woodside - 5244', value: 'SA - Woodside - 5244' },
  { label: 'TAS - Burnie - 7320', value: 'TAS - Burnie - 7320' },
  { label: 'TAS - Campbell Town - 7210', value: 'TAS - Campbell Town - 7210' },
  { label: 'TAS - Deloraine - 7304', value: 'TAS - Deloraine - 7304' },
  { label: 'TAS - Devonport - 7310', value: 'TAS - Devonport - 7310' },
  { label: 'TAS - Gagebrook - 7030', value: 'TAS - Gagebrook - 7030' },
  { label: 'TAS - George Town - 7253', value: 'TAS - George Town - 7253' },
  { label: 'TAS - Glenorchy - 7010', value: 'TAS - Glenorchy - 7010' },
  { label: 'TAS - Hobart - 7000', value: 'TAS - Hobart - 7000' },
  { label: 'TAS - Huonville - 7109', value: 'TAS - Huonville - 7109' },
  { label: 'TAS - Launceston - 7250', value: 'TAS - Launceston - 7250' },
  { label: 'TAS - New Norfolk - 7140', value: 'TAS - New Norfolk - 7140' },
  { label: 'TAS - Queenstown - 7467', value: 'TAS - Queenstown - 7467' },
  { label: 'TAS - Rokeby - 7019', value: 'TAS - Rokeby - 7019' },
  { label: 'TAS - Scottsdale - 7260', value: 'TAS - Scottsdale - 7260' },
  { label: 'TAS - Smithton - 7330', value: 'TAS - Smithton - 7330' },
  { label: 'TAS - Sorell - 7172', value: 'TAS - Sorell - 7172' },
  { label: 'TAS - Ulverstone - 7315', value: 'TAS - Ulverstone - 7315' },
  { label: 'VIC - Aspendale - 3195', value: 'VIC - Aspendale - 3195' },
  { label: 'VIC - Belgrave - 3160', value: 'VIC - Belgrave - 3160' },
  { label: 'VIC - Bendigo - 3550', value: 'VIC - Bendigo - 3550' },
  { label: 'VIC - Box Hill - 3128', value: 'VIC - Box Hill - 3128' },
  { label: 'VIC - Broadmeadows - 3047', value: 'VIC - Broadmeadows - 3047' },
  { label: 'VIC - Cheltenham - 3192', value: 'VIC - Cheltenham - 3192' },
  { label: 'VIC - Craigieburn - 3064', value: 'VIC - Craigieburn - 3064' },
  { label: 'VIC - Cranbourne - 3977', value: 'VIC - Cranbourne - 3977' },
  { label: 'VIC - Dandenong - 3175', value: 'VIC - Dandenong - 3175' },
  { label: 'VIC - Doncaster - 3108', value: 'VIC - Doncaster - 3108' },
  { label: 'VIC - Frankston - 3199', value: 'VIC - Frankston - 3199' },
  { label: 'VIC - Geelong - 3220', value: 'VIC - Geelong - 3220' },
  { label: 'VIC - Glen Waverley - 3150', value: 'VIC - Glen Waverley - 3150' },
  { label: 'VIC - Greensborough - 3088', value: 'VIC - Greensborough - 3088' },
  { label: 'VIC - Hastings - 3915', value: 'VIC - Hastings - 3915' },
  { label: 'VIC - Healesville - 3777', value: 'VIC - Healesville - 3777' },
  { label: 'VIC - Lilydale - 3140', value: 'VIC - Lilydale - 3140' },
  { label: 'VIC - Melbourne - 3000', value: 'VIC - Melbourne - 3000' },
  { label: 'VIC - Moorabbin - 3189', value: 'VIC - Moorabbin - 3189' },
  { label: 'VIC - Morwell - 3840', value: 'VIC - Morwell - 3840' },
  { label: 'VIC - Narre Warren - 3805', value: 'VIC - Narre Warren - 3805' },
  { label: 'VIC - Norlane - 3214', value: 'VIC - Norlane - 3214' },
  { label: 'VIC - Northcote - 3070', value: 'VIC - Northcote - 3070' },
  { label: 'VIC - Oakleigh - 3166', value: 'VIC - Oakleigh - 3166' },
  { label: 'VIC - Pakenham - 3810', value: 'VIC - Pakenham - 3810' },
  { label: 'VIC - Port Melbourne - 3207', value: 'VIC - Port Melbourne - 3207' },
  { label: 'VIC - Prahran - 3181', value: 'VIC - Prahran - 3181' },
  { label: 'VIC - Preston - 3072', value: 'VIC - Preston - 3072' },
  { label: 'VIC - Richmond - 3121', value: 'VIC - Richmond - 3121' },
  { label: 'VIC - Ringwood - 3134', value: 'VIC - Ringwood - 3134' },
  { label: 'VIC - Rosebud - 3939', value: 'VIC - Rosebud - 3939' },
  { label: 'VIC - South Morang - 3752', value: 'VIC - South Morang - 3752' },
  { label: 'VIC - Springvale - 3171', value: 'VIC - Springvale - 3171' },
  { label: 'VIC - St Kilda - 3182', value: 'VIC - St Kilda - 3182' },
  { label: 'VIC - Sunbury - 3429', value: 'VIC - Sunbury - 3429' },
  { label: 'VIC - Torquay - 3228', value: 'VIC - Torquay - 3228' },
  { label: 'VIC - Traralgon - 3844', value: 'VIC - Traralgon - 3844' },
  { label: 'VIC - Tullamarine - 3043', value: 'VIC - Tullamarine - 3043' },
  { label: 'VIC - Wantirna South - 3152', value: 'VIC - Wantirna South - 3152' },
  { label: 'VIC - Warragul - 3820', value: 'VIC - Warragul - 3820' },
  { label: 'VIC - Waurn Ponds - 3216', value: 'VIC - Waurn Ponds - 3216' },
  { label: 'VIC - Whittlesea - 3757', value: 'VIC - Whittlesea - 3757' },
  { label: 'VIC - Wonthaggi - 3995', value: 'VIC - Wonthaggi - 3995' },
  { label: 'VIC - Yarra Junction - 3797', value: 'VIC - Yarra Junction - 3797' }
];
