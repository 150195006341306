import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';

const url = process.env.REACT_APP_CHECK_IN_SERVICE_URL;

export const getOnboardingAssessment = (token: string, clientRecordId: string) =>
  httpClient('GET', url!, [200, 204], { Authorization: `Bearer ${token}` })(
    `/client-records/${clientRecordId}/questions-responses/first-assessment`
  ) as Promise<BentResponse>;

export const putOnboardingResponses = (token: string, questionsResponsesId: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 200, { Authorization: `Bearer ${token}` })(
    `/questions-responses/${questionsResponsesId}`,
    body
  ) as Promise<BentResponse>;

export const putBackgroundResponses = (token: string, questionsResponsesId: string, body: Record<string, any>) =>
  httpClient('PUT', url!, 204, { Authorization: `Bearer ${token}` })(
    `/questions-responses/${questionsResponsesId}/background`,
    body
  ) as Promise<BentResponse>;
