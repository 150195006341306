import { Modal } from 'antd';
import CancellationPolicy from 'Ease/components/CancellationPolicy/CancellationPolicy';
import styles from './CancellationPolicyModal.module.scss';

interface CancellationPolicyModalProps {
  visible: boolean;
  onClose: () => void;
}

const CancellationPolicyModal = ({ visible, onClose }: CancellationPolicyModalProps) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      title={<div className={styles.title}>Cancellation Policy for Initial Appointment</div>}
      onCancel={onClose}
    >
      <CancellationPolicy />
    </Modal>
  );
};
export default CancellationPolicyModal;
