import classNames from 'classnames';
import styles from './NumberSelection.module.scss';

type NumberSelectionProps = {
  value: number | null;
  onChange(value: number): void;
};

const NumberSelection = ({ value, onChange }: NumberSelectionProps) => {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className={styles.container}>
      {options.map((option) => (
        <div className={styles.column}>
          <button
            type="button"
            className={classNames(styles.number, option === value ? styles.active : null)}
            onClick={() => onChange(option)}
          >
            {option}
          </button>
        </div>
      ))}
    </div>
  );
};

export default NumberSelection;
