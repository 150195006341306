import { useState } from 'react';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';

import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import styles from './LandingFilter.module.scss';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import FilterDropdownWrapper from '../components/FilterDropdownWrapper/FilterDropdownWrapper';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';

const LandingFilter = () => {
  const { PRACTITIONER } = useSomeoneHealthRoutesGenerator();
  const navigate = useNavigate();
  const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] = useState<listInterface[]>([]);

  const [selectedAgeRange, setSelectedAgeRange] = useState<listInterface | undefined>();

  const handleMentalHealthChange = (value: listInterface) => {
    const checkIfDuplicate = selectedMentalHealthConcern.some((mhObj) => mhObj.id === value.id);

    const newCollection: listInterface[] = checkIfDuplicate
      ? selectedMentalHealthConcern.filter((mhObj) => mhObj.id !== value.id)
      : [...selectedMentalHealthConcern, value];
    setSelectedMentalHealthConcern(newCollection);
  };

  const handleAgeRangeChange = (value: listInterface | undefined) => {
    setSelectedAgeRange(value);
  };

  const handleClickSearch = () => {
    const newPathCollection = [...selectedMentalHealthConcern, selectedAgeRange];
    const generateFilterPath = newPathCollection
      .map((collectionObj) => collectionObj?.id)
      .filter((obj) => obj !== undefined)
      .join('-');
    const newPath = generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
      filter: generateFilterPath
    });
    navigate(newPath);
  };

  return (
    <SomeoneHealthContentLayout>
      <div className={styles.container}>
        <div className={styles.title}>
          I’d like to talk to a&nbsp;<div className={styles.highLightTitle}>Psychologist</div>&nbsp;who has experience
          in...
        </div>
        <div className={styles.filterWrapper}>
          <FilterDropdownWrapper
            onChangeMentalHealthConcernValue={handleMentalHealthChange}
            mentalHealthConcernValue={selectedMentalHealthConcern}
            onChangeAgeRangeValue={handleAgeRangeChange}
            ageRangeValue={selectedAgeRange}
          />
          <div className={styles.searchSection}>
            <ButtonSH className={styles.searchButton} icon="search" iconPostFix onClick={handleClickSearch}>
              Find my fit
            </ButtonSH>
          </div>
        </div>
      </div>
    </SomeoneHealthContentLayout>
  );
};

export default LandingFilter;
