import ContentLayout from '../../../../components/ContentLayout/ContentLayout';

import styles from './Footer.module.scss';

const Footer = ({ ...props }) => {
  return (
    <div {...props} className={styles.container}>
      <ContentLayout>
        <div className={styles.content}>
          <div>&copy; {new Date().getFullYear()} Tacklit, all rights reserved</div>
          <div>
            Contact us at{' '}
            <a className={styles.link} href="mailto:hello@tacklit.com">
              hello@tacklit.com
            </a>
          </div>
        </div>
      </ContentLayout>
    </div>
  );
};

export default Footer;
