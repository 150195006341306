import styles from './IP6YourProgram.module.scss';
import IP6ProgramCard from './components/IP6ProgramCard/IP6ProgramCard';
import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import { useEffect, useRef, useState } from 'react';
import { ProgramInterface } from 'helm/interfaces/program/program';
import classnames from 'classnames';
import IP6HomeWorkCard from './components/IP6HomeWorkCard/IP6HomeWorkCard';
import { scrollToView } from 'utils/scrollToView';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { getActiveChapter } from '../IP6YourProgress/components/IP6ProgressBox/components/ProgressContentBody/components/NextActivityContent/NextActivityContent';

interface IP6ProgramProps {
  data: ProgramInterface;
}

const oneCardDistance = 420;

const IP6YourProgram = ({ data }: IP6ProgramProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { IP6 } = useHelmRoutesGenerator();
  const { session }: { session?: string } = queryString.parse(location.search);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        setScrollPosition(el.scrollLeft + e.deltaY);
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      setMaxScrollWidth(el.scrollWidth - el.clientWidth);
    }
  }, [scrollRef, screenWidth]);

  useEffect(() => {
    const el = scrollRef.current;
    if (session && el) {
      scrollToView(session, true, false, true);
      setTimeout(() => {
        setScrollPosition(el.scrollLeft);
      }, 1000);
      navigate(IP6.DETAILS);
    }
  }, [session, IP6, navigate]);

  const scroll = (step: number) => {
    let scrollAmount = 0;
    const el = scrollRef.current;
    const slideTimer = setInterval(() => {
      if (el) {
        setScrollPosition(el.scrollLeft + step);
        el.scrollTo({
          left: el.scrollLeft + step,
          behavior: 'smooth'
        });
        scrollAmount += Math.abs(step);
        if (scrollAmount >= 100) {
          clearInterval(slideTimer);
        }
      }
    }, 100);
  };

  const singleDotWidth = maxScrollWidth / 3;

  const handleDotNavigate = (dotId: number) => {
    const calculatedDotWidth = Math.ceil(singleDotWidth * (dotId - 1));
    const el = scrollRef.current;
    if (el) {
      setScrollPosition(calculatedDotWidth);
      el.scrollTo({
        left: calculatedDotWidth,
        behavior: 'smooth'
      });
    }
  };

  const getScrollActive = (dotId: number) => {
    const minDot = Math.ceil(singleDotWidth * (dotId - 1));
    const maxDot = Math.ceil(singleDotWidth * dotId);
    return scrollPosition >= minDot && scrollPosition < maxDot;
  };

  const { activeChapter, checkPreActivityExistOrCompleted } = getActiveChapter(data);

  return (
    <div id={'yourProgram'} className={styles.container}>
      <HelmContentLayout className={styles.titleWrapper}>
        <div className={styles.title}>Your Program</div>
      </HelmContentLayout>
      <div className={styles.programContainer}>
        <div ref={scrollRef} className={styles.programWrapper}>
          {data.chapters.map((obj, i) => (
            <div id={obj.chapterId.toString()} key={i} className={styles.programCard}>
              {obj.sessionActivities.pre.length > 0 && (
                <IP6HomeWorkCard title={'PRE-SESSION ACTIVITIES'} activitiesData={obj.sessionActivities.pre} />
              )}
              <IP6ProgramCard
                activeCard={
                  !data.isUnlockAll &&
                  !checkPreActivityExistOrCompleted &&
                  !activeChapter?.completed &&
                  activeChapter?.chapterId === obj.chapterId
                }
                id={(i + 1).toString()}
                chapterData={obj}
              />
              {obj.sessionActivities.post.length > 0 && (
                <IP6HomeWorkCard title={'POST-SESSION ACTIVITIES'} activitiesData={obj.sessionActivities.post} />
              )}
            </div>
          ))}
        </div>
        <div
          className={classnames(styles.leftScrollButton, scrollPosition <= 0 && styles.hideButton)}
          onClick={() => scroll(-oneCardDistance)}
        >
          <div className={`material-icons ${styles.arrowButton}`}>keyboard_arrow_left</div>
        </div>
        <div
          className={classnames(styles.rightScrollButton, scrollPosition >= maxScrollWidth - 50 && styles.hideButton)}
          onClick={() => scroll(oneCardDistance)}
        >
          <div className={`material-icons ${styles.arrowButton}`}>keyboard_arrow_right</div>
        </div>
      </div>
      <div className={styles.dotWrapper}>
        <div
          onClick={() => handleDotNavigate(1)}
          className={getScrollActive(1) || scrollPosition <= 0 ? styles.dotActive : styles.dot}
        />
        <div onClick={() => handleDotNavigate(2)} className={getScrollActive(2) ? styles.dotActive : styles.dot} />
        <div
          onClick={() => handleDotNavigate(3)}
          className={getScrollActive(3) || scrollPosition >= maxScrollWidth ? styles.dotActive : styles.dot}
        />
      </div>
    </div>
  );
};

export default IP6YourProgram;
