import HelmLayout from 'helm/components/HelmLayout/HelmLayout';
import AppointmentsOverview from 'helm/pages/AppointmentsOverview/AppointmentsOverview';
import CheckoutPage from 'helm/pages/CreateProfile/CheckoutPage/CheckoutPage';
import CreateProfile from 'helm/pages/CreateProfile/CreateProfile';
import JoinWaitlist from 'helm/pages/CreateProfile/JoinWaitlist/JoinWaitlist';
import JoinWaitlistResult from 'helm/pages/CreateProfile/JoinWaitlistResult/JoinWaitlistResult';
import SignUpResult from 'helm/pages/CreateProfile/SignUpResult/SignUpResult';
import IP6Page from 'helm/pages/IP6Page/IP6Page';
import MicroJournalInsights from 'helm/pages/MicroJournalInsights/MicroJournalInsights';
import CounsellorCalendarDetails from 'helm/pages/PractitionerDetails/CounsellorCalendarDetails';
import PractitionerDetails from 'helm/pages/PractitionerDetails/PractitionerDetails';
import PractitionerListing from 'helm/pages/PractitionerListing/PractitionerListing';
import ProgramPage from 'helm/pages/ProgramPage/ProgramPage';
import ReferralDetail from 'helm/pages/ReferralDetail/ReferralDetail';
import SelectAvatar from 'helm/pages/SelectAvatar/SelectAvatar';
import ConsentFormSignUp from 'helm/pages/Task/ConsentFormSignUp/ConsentFormSignUp';
import UpdateProfile from 'helm/pages/UpdateProfile/UpdateProfile';
import Onboarding from 'pages/OnboardingAssessment/Onboarding/Onboarding';
import PatientCheckIn from 'pages/PatientCheckIn/PatientCheckIn';
import PsychometricList from 'pages/Psychometric/PsychometricList/PsychometricList';
import SMPDetailsPage from 'pages/SMP/pages/SMPDetailsPage/SMPDetailsPage';
import SMPListPage from 'pages/SMP/pages/SMPListPage/SMPListPage';
import SMPNewPage from 'pages/SMP/pages/SMPNewPage/SMPNewPage';
import SMPPreviewPage from 'pages/SMP/pages/SMPPreviewPage/SMPPreviewPage';
import { Route, Routes } from 'react-router-dom';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import appRoute from '../components/AppRoute/AppRoute';
import HelmLoginWrapper from '../helm/components/HelmLoginWrapper/HelmLoginWrapper';
import HelmWrapper from '../helm/components/HelmWrapper/HelmWrapper';
import HelmHomePage from '../helm/pages/HelmHomePage/HelmHomePage';
import HelmSurveyListWrapper from '../helm/pages/Survey/HelmSurveyListWrapper/HelmSurveyListWrapper';
import HelmSurveyQuestionWrapper from '../helm/pages/Survey/HelmSurveyQuestionWrapper/HelmSurveyQuestionWrapper';
import { useHelmRoutesGenerator } from '../helm/utils/Path/HelmRoutesGenerator';
import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import { UserContextProvider } from '../utils/UserContextProvider';
import ConsentListWrapper from 'helm/pages/ConsentListWrapper/ConsentListWrapper';
import { ClientBrand } from '../interfaces/ClientBrand';
import InvoiceViewPage from 'pages/InvoiceViewPage/InvoiceViewPage';
import PatientAdHocAssessment from 'pages/PatientAdHocAssessment/PatientAdHocAssessment';
import P2CGambling from 'helm/pages/P2CGambling/P2CGambling';
import HelmSmpLayout from 'helm/components/HelmSmpLayout/HelmSmpLayout';

const isDevEnv = isDevelopmentENV();

const HelmRoutes = () => {
  const {
    AVATAR,
    CHECKIN,
    CHECKOUT,
    CONSENT,
    CONTENT,
    COUNSELLORS_CALENDAR,
    HOME,
    INVOICE,
    IP6,
    LOGIN,
    MICRO_JOURNAL_INSIGHTS,
    ONBOARDING,
    P2C_GAMBLING,
    PRACTITIONER,
    PSYCHOMETRIC,
    REFERRAL,
    SIGNUP,
    SMP,
    SURVEY,
    UPDATE_PROFILE,
    WAITLIST_SUCCESS,
    WAITLIST
  } = useHelmRoutesGenerator();

  return (
    <UserContextProvider>
      <Routes>
        {appRoute({ path: PRACTITIONER.LISTING, component: PractitionerListing, layout: HelmWrapper })}
        {appRoute({ path: PRACTITIONER.DETAILS, component: PractitionerDetails, layout: HelmWrapper })}
        {appRoute({ path: COUNSELLORS_CALENDAR.DETAILS, component: CounsellorCalendarDetails, layout: HelmWrapper })}
        {appRoute({ path: CHECKOUT, component: CheckoutPage, layout: HelmLoginWrapper })}
        {appRoute({ path: SIGNUP.BASE, component: CreateProfile, layout: HelmLoginWrapper })}
        {appRoute({ path: SIGNUP.WELCOME, component: SignUpResult, layout: HelmLoginWrapper })}
        {appRoute({ path: WAITLIST, component: JoinWaitlist, layout: HelmLoginWrapper })}
        {appRoute({ path: WAITLIST_SUCCESS, component: JoinWaitlistResult, layout: HelmLoginWrapper })}
        {appRoute({
          path: PSYCHOMETRIC.DETAILS,
          component: PatientAdHocAssessment,
          layout: HelmLoginWrapper,
          title: 'HELM - Psychometric',
          brand: ClientBrand.HELM
        })}
        {appRoute({
          path: INVOICE.VIEW,
          component: InvoiceViewPage,
          layout: HelmWrapper,
          title: 'HELM - invoice',
          brand: ClientBrand.HELM
        })}
        <Route element={<AuthLayout />}>
          {isDevEnv && <Route path={SMP.PREVIEW} element={<SMPPreviewPage />} />}
          {appRoute({
            path: SMP.LISTING,
            component: SMPListPage,
            layout: HelmSmpLayout,
            title: 'HELM - SMP',
            brand: ClientBrand.HELM
          })}
          {appRoute({
            path: SMP.NEW,
            component: SMPNewPage,
            layout: HelmSmpLayout,
            title: 'HELM - SMP',
            brand: ClientBrand.HELM
          })}
          {appRoute({
            path: SMP.DETAILS,
            component: SMPDetailsPage,
            layout: HelmSmpLayout,
            title: 'HELM - SMP',
            brand: ClientBrand.HELM
          })}
          {appRoute({ path: CONTENT, component: ProgramPage, layout: HelmLoginWrapper })}
          {appRoute({ path: AVATAR, component: SelectAvatar, layout: HelmLoginWrapper })}
          {appRoute({ path: UPDATE_PROFILE, component: UpdateProfile, layout: HelmLoginWrapper })}
          {appRoute({ path: LOGIN.APPOINTMENT_OVERVIEW, component: AppointmentsOverview, layout: HelmLoginWrapper })}
          {appRoute({
            path: PSYCHOMETRIC.BASE,
            component: PsychometricList,
            layout: HelmLoginWrapper,
            title: 'HELM - Psychometric',
            brand: ClientBrand.HELM
          })}
          {appRoute({
            path: ONBOARDING.BASE,
            component: Onboarding,
            layout: HelmLoginWrapper,
            title: 'HELM - Onboarding',
            brand: ClientBrand.HELM
          })}
          {appRoute({
            path: REFERRAL,
            component: ReferralDetail,
            layout: HelmLoginWrapper,
            title: 'HELM - Referral',
            brand: ClientBrand.HELM
          })}
          {appRoute({
            path: CHECKIN,
            component: PatientCheckIn,
            layout: HelmLoginWrapper,
            title: 'HELM - Check in',
            brand: ClientBrand.HELM
          })}
          {appRoute({ path: SURVEY.BASE, component: HelmSurveyListWrapper, layout: HelmLoginWrapper })}
          {appRoute({ path: SURVEY.DETAILS, component: HelmSurveyQuestionWrapper, layout: HelmLoginWrapper })}
          {appRoute({ path: CONSENT.BASE, component: ConsentListWrapper, layout: HelmLoginWrapper })}
          {appRoute({ path: CONSENT.DETAILS, component: ConsentFormSignUp, layout: HelmLoginWrapper })}
          {appRoute({ path: IP6.DETAILS, component: IP6Page, layout: HelmLoginWrapper })}
          {appRoute({ path: P2C_GAMBLING, component: P2CGambling, layout: HelmLoginWrapper })}
          {appRoute({ path: MICRO_JOURNAL_INSIGHTS, component: MicroJournalInsights, layout: HelmLayout })}
          {appRoute({ path: HOME, component: HelmHomePage, layout: HelmLoginWrapper })}
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default HelmRoutes;
