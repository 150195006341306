import { useNavigate } from 'react-router-dom';
import styles from './AppointmentHeaderV2.module.scss';
import logo from 'assets/images/tacklit-logo-blue.svg';
interface AppointmentHeaderV2Props {
  backUrl: string;
}
const AppointmentHeaderV2 = ({ backUrl }: AppointmentHeaderV2Props) => {
  const navigate = useNavigate();
  const onClickBackBtn = () => {
    navigate(backUrl);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <img alt={'tacklit logo'} className={styles.logo} src={logo} />
        </div>
        <div className={styles.backBtnWrapper} onClick={() => onClickBackBtn()}>
          <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
          BACK
        </div>
      </div>
    </>
  );
};

export default AppointmentHeaderV2;
