import styles from './IP6ProgramCard.module.scss';
import { ChapterInterface, ProgramAppointment } from 'helm/interfaces/program/program';
import moment from 'moment';
import { checkIsTodayOrYesterday } from '../../../IP6YourProgress/components/IP6ProgressBox/components/ProgressContentBody/components/NextActivityContent/NextActivityContent';
import HowToConnect from '../../../IP6YourProgress/components/IP6ProgressBox/components/ProgressContentBody/components/NextActivityContent/HowToConnect/HowToConnect';
import Button from 'components/v2/Button/Button';
import classnames from 'classnames';
import { getDeliveryTypeLabel } from 'utils/appointment';

interface IP6ProgramCardProps {
  id: string;
  chapterData: ChapterInterface;
  activeCard: boolean;
}

const generateDesc = (appointmentData: ProgramAppointment, completed: boolean, chapterId?: number) => {
  return completed
    ? `Completed via
          ${
            appointmentData.deliveryType &&
            getDeliveryTypeLabel(appointmentData.deliveryType, appointmentData.otherInstructions)
          } on ${appointmentData.date && checkIsTodayOrYesterday(appointmentData.date)}.`
    : `${chapterId ? `Session ${chapterId},` : ''} ${appointmentData.duration} minutes,
          ${
            appointmentData.deliveryType &&
            getDeliveryTypeLabel(appointmentData.deliveryType, appointmentData.otherInstructions)
          } booked
          for ${appointmentData.date && checkIsTodayOrYesterday(appointmentData.date)}
          ${moment(appointmentData.startTime, 'hh:mm').format('hh:mmA')}.`;
};

const IP6ProgramCard = ({ id, chapterData, activeCard }: IP6ProgramCardProps) => (
  <div
    className={classnames(
      chapterData.completed ? styles.containerComplete : styles.container,
      styles.noPaddingBottom,
      activeCard && styles.activeBorder
    )}
  >
    <div className={styles.programWrapper}>
      <div className={styles.title}>{activeCard ? 'NEXT SESSION' : `SESSION ${id}`}</div>
      {chapterData.appointment && (
        <div className={styles.sessionDesc}>
          {generateDesc(chapterData.appointment, chapterData.completed, activeCard ? chapterData.chapterId : undefined)}
        </div>
      )}
      <div className={styles.imageWrapper}>
        <img className={styles.image} alt={chapterData.name} src={chapterData.image} />
      </div>
      <div className={styles.chaptersBox}>
        <div className={styles.chapterTitle}>{chapterData.name}</div>
        <div className={styles.chapterDesc}>{chapterData.description}</div>
      </div>
    </div>
    {!chapterData.completed ? (
      <>
        {chapterData.link && (
          <div className={styles.connectWrapper}>
            {chapterData.appointment && <HowToConnect appointmentData={chapterData.appointment} />}
            <div className={styles.moduleButtonWrapper}>
              <Button onClick={() => (window.location.href = chapterData.link || '')} className={styles.moduleButton}>
                Start this module
              </Button>
            </div>
          </div>
        )}
      </>
    ) : (
      <div className={styles.transparentWrapper}>
        <div className={styles.moduleButtonWrapper}>
          <Button
            variant={'secondary'}
            onClick={() => (window.location.href = chapterData.link || '')}
            className={styles.moduleButton}
          >
            Revisit this content
          </Button>
        </div>
      </div>
    )}
  </div>
);

export default IP6ProgramCard;
