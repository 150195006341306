import styles from './SelectLoginWrapper.module.scss';

interface SelectLoginWrapperProps {
  children?: any;
}

const SelectLoginWrapper = ({ children }: SelectLoginWrapperProps) => (
  <div className={styles.container}>
    <div className={styles.background1} />
    <div className={styles.background2} />
    <div className={styles.content}>{children}</div>
  </div>
);

export default SelectLoginWrapper;
