import { useEffect, useState } from 'react';

import styles from './PsychologistTimeSlot.module.scss';
import { PractitionerDetailsInterface } from 'interfaces/PublicProfile/Practitioner/practitioner';
import StepTitle from './components/StepTitle/StepTitle';
import AppointmentTypeCard from './components/AppointmentTypeCard/AppointmentTypeCard';
import Calendar, { displayMonth } from './components/Calendar/Calendar';
import BookingSession from './components/BookingSession/BookingSession';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AvailabilityAppointmentTypes,
  DeliveryType,
  TimeSlotsWithDateInterface,
  useAvailabilityWithTimeZone,
  useFetchAvailabilityAppointmentTypes
} from 'utils/hooks/appointment';
import { notification, Skeleton } from 'antd';
import { scrollToView } from 'utils/scrollToView';
import { postReservedAppointment } from 'utils/http/appointment';
import { useSetReserveAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { massageTimeSlotReverse } from 'Select/pages/PsychologistListing/hooks/getPsychologistList';
import NoAvailableMessageBox from 'Select/components/NoAvailableMessageBox/NoAvailableMessageBox';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import { isSlotSelected } from './components/Calendar/components/SlotPicker/SlotPicker';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { SelectContactUrl, SelectDefaultTimezone } from 'Select/utils/env/SelectEnv';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';
import { useSelectToken } from 'Select/utils/hooks/useSelectToken';
import AppointmentTypeFullCard from './components/AppointmentTypeFullCard/AppointmentTypeFullCard';
import { fetchClaimReservation } from 'Select/utils/api/fetchClaimReservation';
import { useRedirectLoginClientNextEngageStep } from 'Select/utils/hooks/useRedirectLoginClientNextEngageStep';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { listInterface } from './components/Calendar/components/TimeFilterDropdown/TimeFilterDropdown';
import { getEngageDefaultAppointmentDeliveryType } from 'utils/appointment';

const deliveryFilter: listInterface[] = [
  {
    id: DeliveryType.VideoCall,
    label: 'Video Call'
  },
  {
    id: DeliveryType.FaceToFace,
    label: 'Face to face'
  },
  {
    id: DeliveryType.PhoneCall,
    label: 'Phone Call'
  },
  {
    id: DeliveryType.Other,
    label: 'Other'
  }
];

export interface TimeSlotBooking {
  accountId: string;
  sessionTypeId: string;
  slots: TimeSlotsWithDateInterface[];
  returnUrl: string;
  cancelUrl: string;
}

export const massageDateTimeFromSourceTzToTargetTz = ({
  date,
  startTime,
  endTime,
  sourceTz,
  targetTZ
}: {
  date: string;
  startTime: string;
  endTime: string;
  sourceTz: string;
  targetTZ: string;
}) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, targetTZ);
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, 'YYYY-MM-DD hh:mmA', sourceTz);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, targetTZ);
  return {
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA')
  };
};

interface PsychologistTimeSlotProps {
  psychologistDetails: PractitionerDetailsInterface;
  onShowWaitlistForm: () => void;
}

const PsychologistTimeSlot = ({ psychologistDetails, onShowWaitlistForm }: PsychologistTimeSlotProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { setReserveAppointmentData } = useSetReserveAppointmentData();
  const { token } = useSelectToken();
  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();

  const { redirectLoginClientNextEngageStep } = useRedirectLoginClientNextEngageStep();

  const { availabilityAppointmentTypes: fullAvailabilityAppointmentTypes, isAvailabilityAppointmentTypesLoading } =
    useFetchAvailabilityAppointmentTypes({
      accountId: psychologistDetails.accountId,
      clinicianId: psychologistDetails._id
    });
  const [availabilityAppointmentTypes, setAvailabilityAppointmentTypes] = useState<AvailabilityAppointmentTypes[]>([]);
  const [isNewClient, setIsNewClient] = useState<boolean>();
  const { LOGIN, SIGNUP } = useSelectRoutesGenerator();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { selectedDateTime }: { selectedDateTime?: string } = queryString.parse(search);

  const [deliveryTypeList, setDeliveryTypeList] = useState<listInterface[]>(deliveryFilter);
  const [deliveryTypeFilter, setDeliveryTypeFilter] = useState<DeliveryType>();
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<AvailabilityAppointmentTypes | undefined>();
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType>(DeliveryType.VideoCall);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<TimeSlotsWithDateInterface[]>([]);
  const [isInitParamsUsed, setIsInitParamsUsed] = useState(false);
  const [isProcessingReservation, setIsProcessingReservation] = useState(false);

  const handleChangeAppointmentType = (valItem: AvailabilityAppointmentTypes) => {
    if (valItem.name !== selectedAppointmentType?.name) {
      setSelectedTimeSlots([]);
    }
    setSelectedAppointmentType(valItem);
    setSelectedDeliveryType(getEngageDefaultAppointmentDeliveryType(valItem?.deliveryOptions || []));
  };

  const { appointmentAvailability, isAvailabilityListLoading } = useAvailabilityWithTimeZone({
    appointmentTypeId: selectedAppointmentType?._id!,
    accountId: psychologistDetails?.accountId!,
    from: moment().format('YYYY-MM-DD'),
    to: moment().add(displayMonth, 'month').format('YYYY-MM-DD'),
    clinicianId: psychologistDetails._id,
    timeZone: SelectDefaultTimezone,
    clinicianTimeZone: psychologistDetails.workTimeZone
  });

  useEffect(() => {
    if (!isAvailabilityAppointmentTypesLoading && fullAvailabilityAppointmentTypes) {
      const newFilteredAppointmentType = fullAvailabilityAppointmentTypes.filter(
        ({ bookingRules }: AvailabilityAppointmentTypes) =>
          isNewClient ? bookingRules?.new.available : bookingRules?.existing.available
      );

      const appointmentType = newFilteredAppointmentType?.[0];
      setSelectedAppointmentType(appointmentType);
      const allAppointmentType: string[] = [];
      newFilteredAppointmentType?.map((obj) => obj.deliveryOptions.map((typeObj) => allAppointmentType.push(typeObj)));
      const uniqueDeliveryType = [...new Set(allAppointmentType)];
      const deliveryTypeFilter: listInterface[] = deliveryFilter.filter((obj) => uniqueDeliveryType.includes(obj.id));
      const defaultDeliveryType = getEngageDefaultAppointmentDeliveryType(appointmentType?.deliveryOptions || []);
      setSelectedDeliveryType(defaultDeliveryType);
      setDeliveryTypeFilter(defaultDeliveryType);
      setDeliveryTypeList(deliveryTypeFilter);
      setAvailabilityAppointmentTypes(newFilteredAppointmentType);
    }
  }, [isAvailabilityAppointmentTypesLoading, isNewClient, fullAvailabilityAppointmentTypes]);

  useEffect(() => {
    if (!isAvailabilityListLoading && appointmentAvailability && !isInitParamsUsed) {
      const [date, startTime, endTime] = selectedDateTime?.split(',') || [];
      if (date && startTime && endTime) {
        const getTimeSlotInfo = appointmentAvailability.timeSlots.find(
          (item) => item.date === date && item.startTime === startTime && item.endTime === endTime
        );
        getTimeSlotInfo && setSelectedTimeSlots([getTimeSlotInfo]);
      }
      setIsInitParamsUsed(true);
    }
  }, [appointmentAvailability, isAvailabilityListLoading, selectedDateTime, isInitParamsUsed]);

  useEffect(() => {
    if (!isClientBookingRuleLoading) {
      const checkNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;
      setIsNewClient(checkNewClient);
    }
  }, [clientBookingRule, isAuthenticated, isClientBookingRuleLoading]);

  useEffect(() => {
    if (selectedDateTime) {
      scrollToView('SelectTimeSlot');
    }
  }, [selectedDateTime]);

  const handleSelectDeliveryType = (val: DeliveryType) => {
    setDeliveryTypeFilter(val);
    setSelectedTimeSlots([]);

    if (!selectedAppointmentType?.deliveryOptions.includes(val)) {
      const filterByDeliveryType = availabilityAppointmentTypes?.filter((item) => item.deliveryOptions.includes(val));
      setSelectedAppointmentType(filterByDeliveryType?.[0]);
    }
  };

  const onContinue = async () => {
    if (selectedAppointmentType) {
      setIsProcessingReservation(true);
      try {
        const payload = {
          isNewClient: clientBookingRule === BookingRuleType.New || !isAuthenticated,
          slots: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, SelectDefaultTimezone)),
          deliveryType: selectedDeliveryType,
          appointmentTypeId: selectedAppointmentType._id,
          clinicianId: psychologistDetails._id
        };
        const res = await postReservedAppointment(psychologistDetails.accountId, payload);
        if (res.statusCode === 200) {
          const reservedAppointmentData = await res.json();
          setReserveAppointmentData({
            reserveId: reservedAppointmentData.reserveId,
            clinicianId: reservedAppointmentData.clinicianId,
            accountId: psychologistDetails.accountId,
            appointmentTypeInfo: {
              name: selectedAppointmentType.name || '',
              description: selectedAppointmentType.description || '',
              rate: selectedAppointmentType.rate || 0,
              isAdvisory: false,
              deliveryType: selectedDeliveryType,
              otherInstructions: selectedAppointmentType.otherInstructions,
              timeSlot: selectedTimeSlots.map((item) => massageTimeSlotReverse(item, SelectDefaultTimezone)),
              sessionTypeId: selectedAppointmentType._id
            },
            programId: selectedAppointmentType.programId,
            psychologistName: psychologistDetails.name,
            clientBookingRule
          });
          if (isAuthenticated) {
            // claim here got login user
            await fetchClaimReservation({
              accountId: psychologistDetails.accountId,
              reserveId: reservedAppointmentData.reserveId,
              authToken: token!,
              shouldGenerateCheckoutSession: false
            });

            redirectLoginClientNextEngageStep({
              noLoginRedirectPath: SIGNUP.RISK_SCREENING
            });
          } else {
            navigate(SIGNUP.BASE);
          }
          scrollToView('SelectHeader');
        } else if (res.statusCode === 409) {
          const conflictAppointmentData = await res.json();
          const conflictSlotList: TimeSlotsWithDateInterface[] = conflictAppointmentData.conflictingSlots || [];

          const timeSlotWithStatus = selectedTimeSlots.map((appointmentListObj) => {
            const startTimeSlot = moment(appointmentListObj.startTime, 'hh:mmA').format('HH:mm');
            const endTimeSlot = moment(appointmentListObj.endTime, 'hh:mmA').format('HH:mm');

            const isConflictSlot = conflictSlotList.some(
              (slotObj) =>
                slotObj.date === appointmentListObj.date &&
                slotObj.startTime === startTimeSlot &&
                slotObj.endTime === endTimeSlot
            );

            return {
              ...appointmentListObj,
              isConflict: isConflictSlot
            };
          });
          setSelectedTimeSlots(timeSlotWithStatus);
        }
      } catch (ex) {
        console.error(ex);
        notification.error({ message: 'Something went wrong while trying to reserve the appointment' });
      }
      setIsProcessingReservation(false);
    }
  };

  const onTimeSlotsChange = (val: TimeSlotsWithDateInterface) => {
    if (!isSlotSelected({ selectedSlots: selectedTimeSlots, slot: val })) {
      setSelectedTimeSlots([...selectedTimeSlots, val]);
    } else onRemoveSelectedSlot(val);
  };

  const onRemoveSelectedSlot = (slot: TimeSlotsWithDateInterface) => {
    const index = selectedTimeSlots.findIndex(
      (selectedSlot) =>
        selectedSlot.date === slot.date &&
        selectedSlot.startTime === slot.startTime &&
        selectedSlot.endTime === slot.endTime
    );
    if (index !== undefined && index >= 0) {
      selectedTimeSlots.splice(index, 1);
      setSelectedTimeSlots?.([...selectedTimeSlots]);
    }
  };

  const checkSignUpClaimForSameClinician =
    !isAuthenticated ||
    !attachedClinicianDetail?.clinician ||
    attachedClinicianDetail.clinician._id === psychologistDetails._id;

  const isNewClientHasNoSlots =
    !isAuthenticated &&
    psychologistDetails.helmControl?.shouldUseCaseLoad &&
    (psychologistDetails.caseLoad?.remainingSlots || 0) <= 0;

  return (
    <div id={'SelectTimeSlot'}>
      {isNewClientHasNoSlots ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${psychologistDetails.name}?`}
            desc={
              <span>
                I am sorry but I have no appointments available to book online right now, you can{' '}
                <a href={SelectContactUrl}>contact</a> the office for more availability. Alternatively you can add
                yourself to the waiting list and a member of our team will be in touch within 1 working day to discuss
                your requirements.
              </span>
            }
            buttonLabel={'Join Waitlist'}
            onClick={onShowWaitlistForm}
          />
        </div>
      ) : isAttachedClinicianDetailLoading && isAuthenticated ? (
        <div className={styles.noAppointmentContainer}>
          <LoadingDot />
        </div>
      ) : !checkSignUpClaimForSameClinician ? (
        <div className={styles.noAppointmentContainer}>
          <NoAvailableMessageBox
            title={`Want to book with ${psychologistDetails.name}?`}
            desc={
              'As you are an existing customer please contact our support team to discuss working with a new counsellor'
            }
            buttonLabel={'Contact Support'}
            onClick={() => (window.location.href = SelectContactUrl)}
          />
        </div>
      ) : (
        <>
          <div className={styles.appointmentSlotContainer}>
            <SelectContentLayout>
              {availabilityAppointmentTypes.length <= 0 && !isAvailabilityAppointmentTypesLoading ? (
                <div className={styles.noAppointmentContainer}>
                  <NoAvailableMessageBox
                    title={`Want to book with ${psychologistDetails.name}?`}
                    desc={
                      <span>
                        I am sorry but I have no appointments available to book online right now, you can{' '}
                        <a href={SelectContactUrl}>contact</a> the office for more availability. Alternatively you can
                        add yourself to the waiting list and a member of our team will be in touch within 1 working day
                        to discuss your requirements.
                      </span>
                    }
                    buttonLabel={`Join waitlist`}
                    onClick={onShowWaitlistForm}
                  />
                </div>
              ) : (
                <div className={styles.content}>
                  <div className={styles.header}>
                    <div className={styles.leftContent}>
                      <div className={styles.titleWrapper}>
                        <div className={styles.title}>Book an appointment with me</div>
                        <div className={styles.desc} onClick={() => scrollToView('SelectFAQ')}>
                          <div className={styles.text}>Have a question? Read our FAQs</div>
                          <i className={`material-icons ${styles.arrowIcon}`}>arrow_downward</i>
                        </div>
                      </div>
                      {psychologistDetails.helmControl?.appointmentPhoto && (
                        <img
                          className={styles.profile}
                          alt={'profile'}
                          src={psychologistDetails.helmControl.appointmentPhoto}
                        />
                      )}
                      {!isAuthenticated && (
                        <div
                          onClick={() =>
                            loginWithRedirect({
                              loginType: 'patient',
                              redirectUri:
                                (process.env.REACT_APP_CLIENT_DOMAIN_SELECT || 'https://my.selectpsychology.co.uk') +
                                LOGIN,
                              appState: { returnTo: window.location.pathname }
                            })
                          }
                          className={styles.loginIfExistingClient}
                        >
                          <div className={styles.text}>EXISTING CLIENT OF {psychologistDetails.name}? LOGIN</div>
                          <i className={`material-icons ${styles.arrowIcon}`}>arrow_forward</i>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.appointmentContentWrapper}>
                    <div>
                      {!isAvailabilityAppointmentTypesLoading && availabilityAppointmentTypes.length !== 1 && (
                        <StepTitle number={'1'} title={'Select appointment'} />
                      )}
                      <div className={styles.appointmentType}>
                        {isAvailabilityAppointmentTypesLoading ? (
                          <div className={styles.loadingWrapper}>
                            <Skeleton className={styles.loading} active />
                          </div>
                        ) : availabilityAppointmentTypes.length === 1 ? (
                          <AppointmentTypeFullCard
                            appointmentTypeData={availabilityAppointmentTypes[0]}
                            onClick={() =>
                              !isAvailabilityListLoading && handleChangeAppointmentType(availabilityAppointmentTypes[0])
                            }
                            disable={isAvailabilityListLoading}
                          />
                        ) : (
                          availabilityAppointmentTypes
                            .filter(
                              (item) =>
                                !deliveryTypeFilter ||
                                item.deliveryOptions.includes(deliveryTypeFilter) ||
                                (selectedDeliveryType === DeliveryType.PhoneCall &&
                                  item.deliveryOptions.includes(DeliveryType.PhoneCallDialClient))
                            )
                            .map((item, index) => (
                              <AppointmentTypeCard
                                key={index}
                                appointmentTypeData={item}
                                onClick={() => !isAvailabilityListLoading && handleChangeAppointmentType(item)}
                                selected={selectedAppointmentType?.name === item.name}
                                disable={isAvailabilityListLoading}
                              />
                            ))
                        )}
                      </div>
                    </div>
                    <div>
                      <StepTitle
                        number={
                          !isAvailabilityAppointmentTypesLoading && availabilityAppointmentTypes.length !== 1 ? '2' : ''
                        }
                        title={'Select day and time'}
                      />
                      <Calendar
                        onSlotClick={onTimeSlotsChange}
                        timeSlots={appointmentAvailability?.timeSlots || []}
                        remainSlots={(selectedAppointmentType?.slotCount || 1) - selectedTimeSlots.length}
                        selectedTimeSlots={selectedTimeSlots}
                        isAvailabilityListLoading={isAvailabilityListLoading}
                        deliveryFilter={deliveryTypeList}
                        onDeliveryFilterChange={handleSelectDeliveryType}
                        onShowWaitlistForm={onShowWaitlistForm}
                      />
                    </div>
                  </div>
                </div>
              )}
            </SelectContentLayout>
          </div>
          {availabilityAppointmentTypes.length > 0 && (
            <BookingSession
              selectedAppointmentType={selectedAppointmentType}
              selectedTimeSlots={selectedTimeSlots}
              isAvailabilityListLoading={isAvailabilityListLoading}
              isProcessingReservation={isProcessingReservation}
              onContinue={onContinue}
              onRemoveSelectedSlot={onRemoveSelectedSlot}
              selectedDeliveryPreference={selectedDeliveryType}
              onChangeDeliveryPreference={(value) => setSelectedDeliveryType(value)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PsychologistTimeSlot;
