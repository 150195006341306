import * as yup from 'yup';
import { BasicDetailsErrorInterface } from '../../../SignUpFormInterface';
import moment from 'moment';
import { checkPhoneNumber } from 'utils/http/NotifService/phoneValidator';
import { notification } from 'antd';
import i18n from 'i18n';

const basicDetailsFormSchema = () =>
  yup.object().shape({
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    postcode: yup.string().required('Please enter your postcode'),
    dateOfBirth: yup
      .string()
      .required('Please enter your date of birth')
      .test('Date of Birth', 'Date of Birth must be a valid date in the DD/MM/YYYY format!', (value) => {
        return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
      }),
    mobileNumber: yup
      .string()
      .min(12, i18n.t('validation.client_mobile_number.min', { count: 10 }))
      .required(i18n.t('validation.client_mobile_number.required'))
  });

export const validationBasicDetailsForm = (clientVal: BasicDetailsErrorInterface) => {
  const validationErrors = {
    firstName: '',
    lastName: '',
    postcode: '',
    dateOfBirth: '',
    mobileNumber: ''
  } as BasicDetailsErrorInterface;
  try {
    basicDetailsFormSchema().validateSync(clientVal, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.map((error: any) => {
        if (error.path) {
          // @ts-ignore
          validationErrors[error.path] = error.message;
        }
        return validationErrors;
      });
      return validationErrors;
    }
  }
};

export const validatePhoneNumber = async (phoneNumber: string, noErrorPopup?: boolean) => {
  try {
    const { valid } = await (await checkPhoneNumber(phoneNumber)).json();
    return {
      phoneNumber,
      valid
    };
  } catch (ex) {
    console.error(ex);
    if (!noErrorPopup) {
      notification.error({
        message: 'Mobile number is invalid',
        duration: 2,
        closeIcon: <span className="success">OK</span>
      });
    }
    return {
      phoneNumber,
      valid: false
    };
  }
};
