import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useCawUserSession } from 'CaW/pages/SignUp/helper/userSession';

export const useCaWToken = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { retrieveUserSession } = useCawUserSession();

  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        (async () => {
          const loginToken = await getAccessTokenSilently({
            audience: process.env.REACT_APP_API_AUDIENCE
          });
          setToken(loginToken);
        })();
      } else {
        const storedTempAuthToken = retrieveUserSession()?.authToken;
        if (storedTempAuthToken) {
          setToken(storedTempAuthToken);
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, retrieveUserSession]);

  return { token };
};
