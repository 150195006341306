export const parseAUGooglePlaceAddress = (place: google.maps.places.PlaceResult | undefined | null) => {
  const line1Candidates: {
    subpremise?: string;
    streetNumber?: string;
    route?: string;
  } = {};
  let state = '';
  let suburb = '';
  let postcode = '';
  let country = '';

  for (const component of place?.address_components || []) {
    const componentType = component.types[0];
    switch (componentType) {
      case '"subpremise"': {
        line1Candidates.subpremise = component.short_name;
        break;
      }

      case 'street_number': {
        line1Candidates.streetNumber = component.long_name;
        break;
      }

      case 'route': {
        line1Candidates.route = component.short_name;
        break;
      }

      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case 'locality': {
        suburb = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        state = component.short_name;
        break;
      }

      // if uk then always use postal_town, ignore administrative_area_level_1
      case 'postal_town': {
        state = component.short_name;
        break;
      }

      case 'country': {
        country = component.long_name;
        break;
      }
    }
  }
  const newAddress = {
    line1:
      place?.name ||
      `${line1Candidates.subpremise || ''}${line1Candidates.subpremise && ' '}${line1Candidates.streetNumber || ''}${
        line1Candidates.streetNumber && ' '
      }${line1Candidates.route || ''}`,
    line2: '',
    state,
    suburb,
    postcode,
    country
  };

  return {
    description: place?.formatted_address ?? '',
    details: newAddress
  };
};

export const parseUKGooglePlaceAddress = (place: google.maps.places.PlaceResult | undefined | null) => {
  const line1Candidates: {
    subpremise?: string;
    streetNumber?: string;
    route?: string;
  } = {};
  let state = '';
  let suburb = '';
  let postcode = '';
  let country = '';

  for (const component of place?.address_components || []) {
    const componentType = component.types[0];
    switch (componentType) {
      case '"subpremise"': {
        line1Candidates.subpremise = component.short_name;
        break;
      }

      case 'street_number': {
        line1Candidates.streetNumber = component.long_name;
        break;
      }

      case 'route': {
        line1Candidates.route = component.short_name;
        break;
      }

      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case 'administrative_area_level_2': {
        state = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        country = component.short_name;
        break;
      }

      case 'postal_town': {
        suburb = component.short_name;
        break;
      }

      case 'country': {
        if (!country) {
          country = component.long_name;
        }
        break;
      }
    }
  }
  const newAddress = {
    line1:
      place?.name ||
      `${line1Candidates.subpremise || ''}${line1Candidates.subpremise && ' '}${line1Candidates.streetNumber || ''}${
        line1Candidates.streetNumber && ' '
      }${line1Candidates.route || ''}`,
    line2: '',
    state,
    suburb,
    postcode,
    country
  };

  return {
    description: place?.formatted_address ?? '',
    details: newAddress
  };
};
