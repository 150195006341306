import styles from './Welcome.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import PortobelloHelmetWrapper from 'Portobello/components/Layout/PortobelloHelmetWrapper/PortobelloHelmetWrapper';
import PortobelloContentLayout from 'Portobello/components/Layout/PortobelloContentLayout/PortobelloContentLayout';
import PortobelloHeader from 'Portobello/components/Layout/PortobelloHeader/PortobelloHeader';
import ButtonPortobello from 'Portobello/components/ButtonPortobello/ButtonPortobello';
import { usePortobelloRoutesGenerator } from 'Portobello/utils/path/PortobelloRoutesGenerator';
import { useNavigate } from 'react-router-dom';
import { PortobelloPrivacyPolicyUrl, PortobelloSupportMobile } from 'Portobello/utils/env/PortobelloEnv';

const Welcome = () => {
  const navigate = useNavigate();
  const { HOME } = usePortobelloRoutesGenerator();

  return (
    <PortobelloHelmetWrapper title={'Portobello Behavioural Health | Welcome to Portobello'}>
      <PortobelloContentLayout className={styles.containerWrapper}>
        <div className={styles.headerWrapper}>
          <PortobelloHeader withPadding className={styles.header} />
        </div>
        <div className={styles.container}>
          <div className={styles.title}>Final step</div>
          <div className={styles.infoContainer}>
            <div className={styles.heading}>Welcome to the Portobello Behavioural Health's secure client portal.</div>
            <div className={styles.desc}>
              In order to get you fully registered you need to complete the following forms before your first
              appointment. If completing forms is something you struggle with, or you experience any technical
              difficulties, please email us at{' '}
              <a className={styles.link} href={'mailto:admin@portobellobh.com'} target="_blank" rel="noreferrer">
                admin@portobellobh.com
              </a>{' '}
              or call {PortobelloSupportMobile} to discuss how we can support you. <br />
              <br />
              Please note that if forms have not been completed before your first session the beginning of the
              appointment will need to be spent completing these forms. <br />
              <br />
              For further information about Portobello Behavioural Health's data handling policy please{' '}
              <a className={styles.link} target={'_blank'} href={PortobelloPrivacyPolicyUrl} rel={'noreferrer'}>
                see here
              </a>
            </div>
          </div>
          <div className={styles.taskContainer}>
            <div className={styles.heading}>Forms to complete in your account:</div>
            <div className={styles.tasks}>
              <ul>
                <li>
                  <div className={styles.circle} />
                  <div className={styles.liText}>New Client Information Form</div>
                </li>
                <li>
                  <div className={styles.circle} />
                  <div className={styles.liText}>Consent forms</div>
                </li>
              </ul>
            </div>
            <ButtonPortobello
              className={styles.submitButton}
              fullWidth
              type="submit"
              onClick={() => {
                navigate(HOME);
              }}
            >
              Go to your account
            </ButtonPortobello>
          </div>
        </div>
      </PortobelloContentLayout>
      <TacklitFooter darkFooter />
    </PortobelloHelmetWrapper>
  );
};

export default Welcome;
