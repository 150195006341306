import { notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import {
  availabilitiesInterface,
  nextAvailability,
  PractitionerDetailsInterface,
  timeSlotsInterface
} from 'interfaces/PublicProfile/Practitioner/practitioner';
import { PractitionerQueryParam } from '../PractitionerListing';
import momentTz from 'moment-timezone';
import { TimeSlotsWithDateInterface } from 'utils/hooks/appointment';
import { getHelmClinicianListing } from 'helm/utils/http/ClinicianProfileService/Helm/helm';
import { MOMENTJS_FORMAT_DATE } from '../../../../components/AvailableTimeSelect/constants';

const massageTimeSlot = (timeSlotList: timeSlotsInterface[], clientTimeZone: string) =>
  timeSlotList.map((timeSlotObj) => {
    const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone).format('hh:mmA');
    const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone).format('hh:mmA');
    return {
      startTime: clientStartTimeZone,
      endTime: clientEndTimeZone
    };
  });

export const massageTimeSlotObj = (
  timeSlotObj: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone);
  const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone);

  return {
    isAvailable: timeSlotObj.isAvailable,
    date: clientStartTimeZone.format(MOMENTJS_FORMAT_DATE),
    endDate: clientEndTimeZone.format(MOMENTJS_FORMAT_DATE),
    startTime: clientStartTimeZone.format('hh:mmA'),
    endTime: clientEndTimeZone.format('hh:mmA'),
    startDateTime: timeSlotObj.startDateTime,
    endDateTime: timeSlotObj.endDateTime,
    clinicianId: timeSlotObj.clinicianId
  };
};

export const massageTimeSlotReverse = (
  timeSlot: TimeSlotsWithDateInterface,
  clientTimeZone: string
): TimeSlotsWithDateInterface => {
  const clientStartTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.startTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();
  const clientEndTimeZone = momentTz
    .tz(`${timeSlot.date} ${timeSlot.endTime}`, 'YYYY-MM-DD hh:mmA', clientTimeZone)
    .utc();

  return {
    isAvailable: timeSlot.isAvailable,
    date: clientStartTimeZone.format(MOMENTJS_FORMAT_DATE),
    endDate: clientEndTimeZone.format(MOMENTJS_FORMAT_DATE),
    startTime: clientStartTimeZone.format('HH:mm'),
    endTime: clientEndTimeZone.format('HH:mm'),
    startDateTime: clientStartTimeZone.toDate(),
    endDateTime: clientEndTimeZone.toDate()
  };
};

export const useFetchPractitionerList = (filter: PractitionerQueryParam, clientTimeZone: string) => {
  const [practitionerList, setPractitionerList] = useState<PractitionerDetailsInterface[]>([]);
  const [advisorList, setAdvisorList] = useState<PractitionerDetailsInterface[]>([]);
  const [isPractitionerListLoading, setIsPractitionerListLoading] = useState(true);

  const fetchPractitionerList = useCallback(async () => {
    const massagePractitionerList = (practitionerList: PractitionerDetailsInterface[]) => {
      const massageAvailabilities = (availabilitiesList: availabilitiesInterface[]) =>
        availabilitiesList.map((availabilitiesObj) => ({
          ...availabilitiesObj,
          timeSlots: massageTimeSlot(availabilitiesObj.timeSlots, clientTimeZone)
        }));
      const massageNextAvailabilities = (nextAvailabilitiesList: nextAvailability[]) =>
        nextAvailabilitiesList.map((nextAvailabilitiesObj) => ({
          ...nextAvailabilitiesObj,
          availabilities: massageAvailabilities(nextAvailabilitiesObj.availabilities)
        }));

      const massageData: PractitionerDetailsInterface[] = practitionerList.map((practitionerListObj) => ({
        ...practitionerListObj,
        nextAvailabilities: massageNextAvailabilities(practitionerListObj.nextAvailabilities || [])
      }));

      return massageData;
    };

    try {
      setIsPractitionerListLoading(true);
      const { clinicians }: { clinicians: PractitionerDetailsInterface[] } = await (
        await getHelmClinicianListing()
      ).json();
      const massageData: PractitionerDetailsInterface[] = massagePractitionerList(clinicians);

      const counsellorList: PractitionerDetailsInterface[] = massageData.filter(
        (practitionerObj) => !practitionerObj.helmControl.isAdvisor
      );

      const AdvisorList: PractitionerDetailsInterface[] = massageData.filter(
        (practitionerObj) => practitionerObj.helmControl.isAdvisor
      );

      setPractitionerList(counsellorList);
      setAdvisorList(AdvisorList);
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong while trying to get helm practitioner list' });
    }
    setIsPractitionerListLoading(false);
  }, [clientTimeZone]);

  useEffect(() => {
    fetchPractitionerList();
  }, [fetchPractitionerList]);

  return { practitionerList, advisorList, isPractitionerListLoading, fetchPractitionerList };
};
