import moment from 'moment';
import { MOMENTJS_FORMAT_DATE } from '../components/AvailableTimeSelect/constants';

/**
 * Get an array of string dates from a string date.
 *
 * @param date - String date (YYYY-MM-DD)
 * @param before - Number of days before a date
 * @param after - Number of days after a date
 * @param exclusive - Whether to exclude the specific date
 * @returns Array of string dates (YYYY-MM-DD)
 *
 * @see {@link generateDatesfromDates}
 */
export const generateDatesfromDate = ({
  date,
  before = 0,
  after = 0,
  exclusive
}: {
  date: string;
  before?: number;
  after?: number;
  exclusive?: boolean;
}) => {
  const specificDate = moment(date); // The specific date

  const numDaysBefore = before; // Number of days before the specific date
  const numDaysAfter = after; // Number of days after the specific date

  const datesBefore = [];
  const datesAfter = [];

  // Populate the array with dates before the specific date
  for (let i = numDaysBefore; i > 0; i--) {
    const date = moment(specificDate).subtract(i, 'days');
    datesBefore.push(date.format('YYYY-MM-DD'));
  }

  // Populate the array with dates after the specific date
  for (let i = 1; i <= numDaysAfter; i++) {
    const date = moment(specificDate).add(i, 'days');
    datesAfter.push(date.format('YYYY-MM-DD'));
  }

  return [...datesBefore, ...(exclusive ? [] : [date]), ...datesAfter];
};

/**
 * Get an array of string dates from an array of string dates.
 *
 * @param dates - Array of string dates (YYYY-MM-DD)
 * @param before - Number of days before a date
 * @param after - Number of days after a date
 * @param exclusive - Whether to exclude the specific dates
 * @returns Array of string dates (YYYY-MM-DD)
 *
 * @see {@link generateDatesfromDate}
 */
export const generateDatesfromDates = ({
  after,
  before,
  dates,
  exclusive
}: {
  dates: string[];
  before?: number;
  after?: number;
  exclusive?: boolean;
}) => {
  let combinedDates: string[] = [];

  dates.forEach((date) => {
    combinedDates = [...combinedDates, ...generateDatesfromDate({ date, before, after, exclusive })];
  });

  return combinedDates;
};

export const formatEndDate = (endDateTime: Date): string => {
  return moment(endDateTime, MOMENTJS_FORMAT_DATE).format(MOMENTJS_FORMAT_DATE);
};

export const formatInputDatePicker = (value: string, event: KeyboardEvent) => {
  const rawValue = value;
  const sanitizedValue = rawValue.replace(/\D/g, '');
  let formattedValue = sanitizedValue
    .replace(/^(\d{0,2})/, '$1')
    .replace(/^(\d{2})(\d{0,2})/, '$1/$2')
    .replace(/^(\d{2})\/(\d{2})(\d{0,4})/, '$1/$2/$3');
  if (event.key === 'Backspace' || event.key === 'Delete') {
    formattedValue = formattedValue.replace(/\/$/, '');
  }
  return formattedValue;
};
