import { useEffect, useState } from 'react';

import styles from './PractitionerImageSlideshow.module.scss';

import { PractitionerDetailsInterface } from '../../interfaces/PublicProfile/Practitioner/practitioner';
import classnames from 'classnames';

interface PractitionerImageSlideshowProps {
  slidePhoto: PractitionerDetailsInterface['helmControl']['additionalPhotos'];
  classNames?: string;
}

const delay = 5000;

const PractitionerImageSlideshow = ({ slidePhoto, classNames }: PractitionerImageSlideshowProps) => {
  const [imageNo, setImageNo] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(
      () => setImageNo((prevIndex) => (prevIndex === slidePhoto.length - 1 ? 0 : prevIndex + 1)),
      delay
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [slidePhoto, imageNo]);

  return (
    <div className={classnames(styles.container, classNames)}>
      <div
        className={styles.slideshowSlider}
        style={imageNo !== 0 ? { transform: `translate3d(${-imageNo * 100}%, 0, 0)` } : {}}
      >
        {slidePhoto.map((imageObj, index) => (
          <div className={styles.slide} key={index}>
            <div className={styles.backgroundPhoto} style={{ backgroundImage: `url(${imageObj})` }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PractitionerImageSlideshow;
