import { useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { message, notification } from 'antd';
import { get } from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';

import { useFetchClinician } from 'utils/hooks/clinician';
import { getAppointmentById, submitRescheduleApproval } from 'utils/http/appointment';

import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import Header from './components/Header/Header';
import Map from './components/Map/Map';
import CallInstructions from './components/CallInstructions/CallInstructions';

import styles from './index.module.scss';
import { AppointmentSlot, DeliveryType } from 'utils/hooks/appointment';
import { useTranslation } from 'react-i18next';

const useFetchAppointment = (appointmentId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [fetching, setFetching] = useState(false);
  const [appointment, setAppointment] = useState<AppointmentSlot>();

  const fetchAppointment = useCallback(async () => {
    setFetching(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    try {
      const response = (await getAppointmentById(appointmentId, token)) as {
        [key: string]: any;
        [key: number]: any;
      };

      const data = (await response.json()) as AppointmentSlot;
      setAppointment(data);
      setFetching(false);
    } catch (e) {
      console.error(`Something went wrong while fetching appoinment detail`);
      console.error(e);

      notification.error({ message: 'Something went wrong while trying to fetch your appointment' });
    }
  }, [appointmentId, getAccessTokenSilently]);

  useEffect(() => {
    fetchAppointment();
  }, [appointmentId, fetchAppointment]);

  return { fetching, appointment };
};

const useApproveAppointment = () => {
  const [approving, setApproving] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const approveAppointment = async (appointmentId: string) => {
    setApproving(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    try {
      await submitRescheduleApproval({ appointmentId, approve: true }, token);
    } catch (e) {
      setApproving(false);
      throw e;
    }

    setApproving(false);
  };

  return { approving, approveAppointment };
};

const useRebookAppointment = () => {
  const [rebooking, setRebooking] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const rebookAppointment = async (appointmentId: string) => {
    setRebooking(true);
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });

    try {
      await submitRescheduleApproval({ appointmentId, approve: false }, token);
    } catch (e) {
      setRebooking(false);
      throw e;
    }
    setRebooking(false);
  };

  return { rebooking, rebookAppointment };
};

const RescheduleApproval = () => {
  const { id, clinicianId } = useParams() as { id: string; clinicianId: string };
  const navigate = useNavigate();
  const [t] = useTranslation();

  const { fetching, appointment } = useFetchAppointment(id);
  const { approving, approveAppointment } = useApproveAppointment();
  const { rebooking, rebookAppointment } = useRebookAppointment();
  const { clinician } = useFetchClinician(clinicianId);

  const handleApprove = async () => {
    try {
      await approveAppointment(id);
      message.success(`Appointment confirmed`);

      navigate(`/${clinicianId}/appointment/confirmed`, {
        state: {
          appointment,
          patient: { name: get(appointment, 'name'), profileImg: get(appointment, 'avatar') },
          clinician
        }
      });
    } catch (e) {
      console.error(e);
      message.error(get(e, 'message', 'Something went wrong while trying to approve the reschedule'));
    }
  };

  const handleRebook = async () => {
    try {
      await rebookAppointment(id);
      message.success(t('appointment.appointment_cancelled'));

      navigate(`/${clinicianId}/appointment`);
    } catch (e) {
      console.error(e);
      message.error(get(e, 'message', 'Something went wrong while trying to rebook the appointment'));
    }
  };

  return (
    <div className={styles.container}>
      <Header isLoading={fetching} appointment={appointment} clinician={clinician} />

      <div className={styles.summaryDetailWrapper}>
        {appointment ? (
          appointment.deliveryType === DeliveryType.FaceToFace ? (
            <Map address={appointment.faceToFaceLocation} />
          ) : (
            <CallInstructions
              deliveryType={appointment.deliveryType}
              videoCallInstructions={appointment.videoCallInstructions}
              phoneCallInstructions={appointment.phoneCallInstructions}
              phoneCallDialClientInstructions={appointment.phoneCallDialClientInstructions}
              otherInstructions={appointment.otherInstructions}
            />
          )
        ) : null}
      </div>
      {appointment && appointment.reschedulingStatus === 'RESCHEDULING' && (
        <div className={styles.btnWrapper}>
          <div className={styles.btnBox}>
            <Button variant="primary" disabled={fetching || rebooking} onClick={handleRebook}>
              {rebooking ? <Loading /> : 'Rebook'}
            </Button>
          </div>
          <div className={styles.btnBox}>
            <Button variant="primary" disabled={fetching || approving} onClick={handleApprove}>
              {approving ? <Loading /> : 'Approve'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RescheduleApproval;
