import { cawEnvironment } from 'CaW/utils/CaWEnv/cawEnv';
import styles from './privacy.module.scss';

const CaWPrivacy = () => {
  const { CaWPrivacyPolicy } = cawEnvironment();

  return (
    <div>
      <div className={styles.title}>Collection Notice</div>
      <p>
        The information you provide to Caraniche, including any personal information as defined in the Privacy Act 1988
        (Cth) (Privacy Act) and information about actual or attempted access to our online platform (including our
        websites and applications) will be used by Caraniche for the purposes of:
        <ul>
          <li>providing information and services to you;</li>
          <li>ensuring that the website and application are working as they should;</li>
          <li>and marketing.</li>
        </ul>
      </p>
      <p>
        We may also use your Personal Information for secondary purposes closely related to the primary purpose, in
        circumstances where you would reasonably expect such use or disclosure (e.g. to investigate complaints).
      </p>
      <p>
        Caraniche may share this information with third parties (healthcare service providers, community service
        providers, government agencies, and/or funding agencies) where you consent to the use or disclosure for the
        purpose of facilitating care and to enable the payment for services; and where required or authorised by law.
      </p>
      <p>If Caraniche does not collect this information, it may impact the outcome of the services provided.</p>
      <p>
        Information about the collection, use, disclosure and storage of personal information by Caraniche, and
        information about how to contact us, is available in our <a href={CaWPrivacyPolicy}>Privacy Statement</a>. This
        includes information on how you can access and seek corrections to your personal information and about
        complaints procedures.
      </p>
    </div>
  );
};

export default CaWPrivacy;
