import styles from './SomeoneHealthWrapper.module.scss';

interface SomeoneHealthWrapperProps {
  children?: any;
}

const SomeoneHealthWrapper = ({ children }: SomeoneHealthWrapperProps) => (
  <div className={styles.container}>{children}</div>
);

export default SomeoneHealthWrapper;
