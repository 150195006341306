import { httpClient } from '../../../httpClient';
import { BentResponse } from 'bent';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';

const url = process.env.REACT_APP_CLINICIAN_PROFILE_SERVICE_URL;

export const postCheckEmailInvite = (accountId: string, payload: Record<string, any>) =>
  httpClient(
    'POST',
    url!,
    200
  )(`/accounts/${accountId}/client-records:checkEmailInvite`, payload) as Promise<BentResponse>;

export const getPublicPracticeProfileBySlugUrl = (slugUrl: string) =>
  httpClient('GET', url!, 200)(`/public/accounts/${slugUrl}/practiceInfo`) as Promise<BentResponse>;

interface MainInfoProps {
  firstName: string;
  lastName: string;
}

interface OptionalInfoProps {
  email?: string;
  mobileNumber?: string;
  dateOfBirth?: string;
  postcode?: string;
  relationship?: string;
  isForMySelf?: boolean;
}

export interface ReferralDetails extends MainInfoProps, OptionalInfoProps {}
export interface ReferrerDetails extends MainInfoProps, OptionalInfoProps {}
export interface GuardianDetails extends MainInfoProps, OptionalInfoProps {}
export interface OtherContactDetails extends MainInfoProps, OptionalInfoProps {}
export interface PartnerDetails extends MainInfoProps, OptionalInfoProps {}

export interface CreateReferralProspectPayload {
  clinicianAuth0Id?: string;
  firstName: string;
  lastName: string;
  email?: string;
  mobileNumber?: string;
  dateOfBirth?: string;
  postcode?: string;
  referralSource?: string;
  referralSourceTags?: string[];
  referralSourceName?: string;

  // NOTE: Use for referral detail from referral source.
  referralDetail?: string;
  note?: {
    title: string;
    body: string;
  };
  captchaChallenge: string;

  // adult | child | couple
  referralRecordType?: string;
  isForMySelf?: boolean;

  // otherContactRelationship?: string;
  contactMethod?: string;

  referrerData?: ReferrerDetails;
  guardianData?: GuardianDetails;
  otherContactData?: OtherContactDetails;
  partnerData?: PartnerDetails;
  isUseGuardianInformation?: string;
  communicationPreference?: CommunicationPreference;
}

interface CheckEmailMobileNumberInvitePayload {
  email: string;
  mobileNumber: string;
  shouldSendOtp?: boolean;
}

export const postReferral = (accountId: string, body: CreateReferralProspectPayload) =>
  httpClient('POST', url!, 201)(`/accounts/${accountId}/referral`, body) as Promise<BentResponse>;

export const patchCheckEmailUsed = (payload: Record<string, any>) =>
  httpClient('PATCH', url!, 200)(`/client-records:checkExistedEmail`, payload) as Promise<BentResponse>;

export const putCheckEmailAlreadyUsed = (token: string, payload: Record<string, any>) =>
  httpClient('POST', url!, [204, 409], { Authorization: `Bearer ${token}` })(
    `/client-records:checkEmailAlreadyUsed`,
    payload
  ) as Promise<BentResponse>;

export const checkEmailMobileNumberInvite = (accountId: string, body: CheckEmailMobileNumberInvitePayload) =>
  httpClient(
    'POST',
    url!,
    200
  )(`/accounts/${accountId}/client-records:checkEmailMobileNumberInvite`, body) as Promise<BentResponse>;

export const postCheckCaseId = (accountId: string, body: any) =>
  httpClient(
    'POST',
    url!,
    [204, 409]
  )(`/accounts/${accountId}/client-records:checkCaseIdAvailability`, body) as Promise<BentResponse>;
