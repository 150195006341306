import { useEffect, useState } from 'react';
import queryString from 'query-string';
import styles from './PartnerPsychologistDetails.module.scss';
import { useFetchPractitionerDetails } from './hooks/getPsychologistDetails';
import PsychologistDetailsHeader from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistDetailsHeader/PsychologistDetailsHeader';
import PsychologistBonaFides from 'SomeoneHealth/components/PsychologistBonaFides/PsychologistBonaFides';
import PsychologistSpecialisation from 'SomeoneHealth/pages/PsychologistListing/components/PsychologistSpecialisation/PsychologistSpecialisation';
import PsychologistQuote from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistQuote/PsychologistQuote';
import PsychologistTimeSlot from './components/PsychologistTimeSlot/PsychologistTimeSlot';
import PsychologistWelcomeNote from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistWelcomeNote/PsychologistWelcomeNote';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToView } from 'utils/scrollToView';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import SomeoneHealthFooter from 'SomeoneHealth/components/SomeoneHealthFooter/SomeoneHealthFooter';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import ButtonSH from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import PsychologistExtraService from 'SomeoneHealth/pages/PsychologistDetails/components/PsychologistExtraService/PsychologistExtraService';
import PractitionerImageSlideshow from 'components/PractitionerImageSlideshow/PractitionerImageSlideshow';
import { MENTAL_HEALTH_LIST_LABELS, MentalHealthList } from 'SomeoneHealth/interfaces/mentalHealth';
import { Modal } from 'antd';
import JoinWaitlistForm from 'SomeoneHealth/components/JoinWaitlistForm/JoinWaitlistForm';
import { useSomeoneHealthPartnerRoutesGenerator } from '../../utils/Path/SomeoneHealthPartnerRoutesGenerator';

export interface PsychologistQueryParam {
  timeSlotSection?: boolean;
}

const PartnerPsychologistDetails = () => {
  const location = useLocation();
  const queryParam = location.search;
  const { clinicianId } = useParams<{ clinicianId: string }>();
  const { PRACTITIONER } = useSomeoneHealthPartnerRoutesGenerator();

  const backToListingLink = `${PRACTITIONER.LISTING}${queryParam ? `${queryParam}` : ''}`;

  const { timeSlotSection }: PsychologistQueryParam = queryString.parse(queryParam);

  const { practitionerDetails, isPractitionerDetailsLoading } = useFetchPractitionerDetails(clinicianId!);
  const practitionerName = practitionerDetails.name;
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    if (timeSlotSection) {
      scrollToView('SomeoneHealthTimeSlot');
    }
  }, [timeSlotSection, isPractitionerDetailsLoading]);

  return (
    <SomeoneHealthHelmetWrapper
      title={`someone.health - ${practitionerName ? `${practitionerName} Profile` : 'psychologist profile'}`}
      hubspot
    >
      {isPractitionerDetailsLoading ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <>
          <PsychologistDetailsHeader
            practitionerDetails={practitionerDetails}
            listingLink={backToListingLink}
            noLogin
          />
          <div className={styles.container}>
            <SomeoneHealthContentLayout>
              <div className={styles.detailsContentWrapper}>
                {/* Left content */}
                <div className={styles.leftContent}>
                  <PsychologistWelcomeNote practitionerDetails={practitionerDetails} />

                  {/* Just show on mobile view */}
                  <div className={styles.appointmentBtnWrapper}>
                    <ButtonSH onClick={() => scrollToView('SomeoneHealthTimeSlot')} className={styles.appointmentBtn}>
                      Schedule Appointment
                    </ButtonSH>
                  </div>
                  {practitionerDetails.helmControl?.primarySpecialisations &&
                    practitionerDetails.helmControl.primarySpecialisations.length > 0 && (
                      <div>
                        <PsychologistSpecialisation
                          label={'MAIN AREAS OF PRACTICE'}
                          specialisationsDetails={practitionerDetails.helmControl.primarySpecialisations.map(
                            (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                          )}
                          badgeClassName={styles.mainAreaBadge}
                        />
                      </div>
                    )}
                  {practitionerDetails.helmControl?.secondarySpecialisations &&
                    practitionerDetails.helmControl.secondarySpecialisations.length > 0 && (
                      <div>
                        <PsychologistSpecialisation
                          label={'I CAN ALSO HELP WITH'}
                          specialisationsDetails={practitionerDetails.helmControl.secondarySpecialisations.map(
                            (item) => MENTAL_HEALTH_LIST_LABELS[item as MentalHealthList]
                          )}
                          badgeClassName={styles.secondaryAreaBadge}
                        />
                      </div>
                    )}
                  {practitionerDetails.helmControl?.styles && practitionerDetails.helmControl.styles.length > 0 && (
                    <div>
                      <PsychologistSpecialisation
                        label={'TRAINED IN'}
                        specialisationsDetails={practitionerDetails.helmControl.styles}
                        badgeClassName={styles.techniqueBadge}
                      />
                    </div>
                  )}
                </div>

                {/* Right content */}
                <div className={styles.rightContent}>
                  {practitionerDetails.helmControl?.additionalPhotos?.length > 0 && (
                    <div className={styles.additionalPhoto}>
                      <div className={styles.additionalPhotoCard}>
                        <PractitionerImageSlideshow
                          classNames={styles.slideShow}
                          slidePhoto={practitionerDetails.helmControl.additionalPhotos}
                        />
                      </div>
                    </div>
                  )}
                  <div className={styles.detailsWrapper}>
                    {/* Just show on desktop view */}
                    <div className={styles.appointmentBtnWrapper}>
                      <ButtonSH onClick={() => scrollToView('SomeoneHealthTimeSlot')} className={styles.appointmentBtn}>
                        Schedule Appointment
                      </ButtonSH>
                    </div>
                    {practitionerDetails.helmControl?.bonaFides && (
                      <PsychologistBonaFides
                        role={practitionerDetails.medicare?.role}
                        helmControl={practitionerDetails.helmControl}
                      />
                    )}
                    {practitionerDetails.helmControl?.fundingMethods &&
                      practitionerDetails.helmControl.fundingMethods?.length > 0 && (
                        <PsychologistExtraService
                          label={'FUNDING METHODS ACCEPTED'}
                          icon={'credit_card'}
                          servicesAvailable={practitionerDetails.helmControl.fundingMethods}
                        />
                      )}
                    {practitionerDetails.helmControl?.servicesAvailable &&
                      practitionerDetails.helmControl.servicesAvailable?.length > 0 && (
                        <PsychologistExtraService
                          label={'SERVICES AVAILABLE'}
                          icon={'summarize'}
                          servicesAvailable={practitionerDetails.helmControl.servicesAvailable}
                        />
                      )}
                  </div>
                </div>
              </div>
            </SomeoneHealthContentLayout>
          </div>
          {practitionerDetails.helmControl?.quote && (
            <PsychologistQuote quote={practitionerDetails.helmControl.quote} />
          )}
          <PsychologistTimeSlot
            helmDetails={practitionerDetails}
            onShowWaitlistForm={() => setShowWaitlistForm(true)}
          />
          <SomeoneHealthFooter />
        </>
      )}

      {/* Join waitlist modal */}
      <Modal
        width={700}
        visible={showWaitlistForm}
        onCancel={() => {
          setShowWaitlistForm(false);
        }}
        bodyStyle={{ padding: '40px 80px', top: 50 }}
        footer={null}
        destroyOnClose
      >
        <JoinWaitlistForm
          onComplete={() => {
            setShowWaitlistForm(false);
          }}
          practitionerDetails={practitionerDetails}
        />
      </Modal>
    </SomeoneHealthHelmetWrapper>
  );
};

export default PartnerPsychologistDetails;
