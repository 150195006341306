import HelmContentLayout from 'helm/components/HelmContentLayout/HelmContentLayout';
import styles from './WhatToExpect.module.scss';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';

const videoSource = isDevelopmentENV()
  ? 'https://storage.googleapis.com/tacklit-static-files-dev/helm/Power%20To%20Change.mp4'
  : 'https://storage.googleapis.com/tacklit-static-files-prod-au/helm/Power%20To%20Change.mp4';

const WhatToExpect = () => {
  return (
    <HelmContentLayout className={styles.container}>
      <div className={styles.title}>What to expect</div>
      <div className={styles.content}>
        Helm’s Power to Change program has been designed with people like you in mind. It provides targeted support for
        alcohol use.
      </div>
      <div className={styles.content}>
        In this six-session program you’ll learn about what triggers your drinking, how alcohol affects your body and
        brain, and why change isn’t easy. Your counsellor will work with you to identify the changes you want to make,
        whether that’s cutting down, reducing the impact, or stopping altogether - it’s up to you.
      </div>
      <div className={styles.content}>
        The Power to Change is an online program that provides some simple strategies to help achieve your goals. There
        are short online activities to complete between each session that will guide the discussion with your counsellor
        when you meet. You’ll book 6 x 40-minute sessions at a time that suits you and you can access the program
        remotely from any location. The 6 session program costs $300 which you pay for when booking. There are no
        Medicare rebates available for this service.
      </div>
      <div className={styles.content}>
        It’s an important first step, and depending on your situation, may be all you need to achieve your goals, while
        others might require further support.
      </div>
      <video className={styles.video} controls src={videoSource} />
    </HelmContentLayout>
  );
};

export default WhatToExpect;
