import { useAuth0 } from '@auth0/auth0-react';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import { Modal, notification } from 'antd';
import classnames from 'classnames';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import LoadingDot from 'components/LoadingDot/LoadingDot';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { useFormError } from 'helm/utils/hooks/validate';
import { ClientRecordType } from 'interfaces/Clients/clientsRecord';
import Personalisation from 'pages/SignUp/SignUpForm/components/Personalisation/Personalisation';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { clinicianProfileServicesApiSlice } from 'redux/services/clinicianProfileServicesApiSlice';
import { isClientTimeZoneEnabled } from 'utils/featureToggle/featureToggle';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import { useGetAccessToken } from 'utils/hooks/token';
import { checkEmailAlreadyExisted } from 'utils/http/ClinicianProfileService/Accounts/checkEmailAlreadyExisted';
import { deleteKeyContact } from 'utils/http/ClinicianProfileService/ClientRecords/clientRecords';
import { postPatientAvatar, putPatient } from 'utils/http/PatientProfileService/Patient/patient';
import * as Yup from 'yup';
import ButtonCaW from '../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonSH from '../../SomeoneHealth/components/ButtonSH/ButtonSH';
import styles from './UpdateProfileContent.module.scss';
import AddKeyContactModal, { KeyContact } from './components/AddKeyContactModal/AddKeyContactModal';
import MedicareInformation from './components/MedicareInformation/MedicareInformation';
import PaymentDetails from './components/PaymentDetails/PaymentDetails';
import PersonalDetailForm, {
  OnAddressAutoCompleteHandler,
  PersonalDetailFormFields
} from './components/PersonalDetailForm/PersonalDetailForm';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import ClientKeyContactDetails from './components/ClientKeyContactDetails/ClientKeyContactDetails';
import moment from 'moment';
import { configNZ } from 'config/configNZ';
import { config } from 'config/config';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { TIMEZONE_OPTIONS } from 'utils/constants/timeZone';
import ButtonPortobello from '../../Portobello/components/ButtonPortobello/ButtonPortobello';

export const validateData = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
  theme: Yup.string().required('Please select the main focus area for you'),
  dateOfBirth: Yup.string()
    .required('Please choose your date of birth')
    .test('Age check', "Be sure that you're over the age of 18", (dob) =>
      moment(dob, 'DD/MM/YYYY').add(18, 'years').isBefore(moment())
    ),
  mobileNumber: Yup.string().required('Please enter your mobile number')
});

const updateProfileValidation = Yup.object()
  .shape({
    name: Yup.string().required('Please enter your preferred name')
  })
  .concat(validateData);

const isBase64PNG = (value: string) => value.startsWith('data:image/png;base64,');

interface UpdateProfileContentProps {
  classNames?: string;
  contentClassName?: string;
}

const UpdateProfileContent = ({ classNames, contentClassName }: UpdateProfileContentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const { token } = useGetAccessToken();
  const [values, setValues] = useState<PersonalDetailFormFields>({});
  const [initEmail, setInitEmail] = useState<string | undefined>('');
  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const { attachedClinicianDetail, isAttachedClinicianDetailLoading } = useGetAttachedClinicianDetails();
  const { data: practiceInfoData } = useGetPracticeInfoQuery(
    { slugUrlOrAccountId: attachedClinicianDetail?.practice?.slugUrl || '' },
    { skip: !attachedClinicianDetail?.practice?.slugUrl }
  );

  const isTimeZoneEnabled =
    (TIMEZONE_OPTIONS.some((timezoneObj) => timezoneObj.value === practiceInfoData?.accountSettings?.timezone) ||
      !practiceInfoData?.accountSettings?.timezone) &&
    isClientTimeZoneEnabled;

  const reloadPatientProfile = () => {
    dispatch(clinicianProfileServicesApiSlice.util.invalidateTags(['ClientRecordsMe']));
  };

  const removeKeyContact = (keyContact: KeyContact) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this contact?',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: async () => {
        try {
          if (keyContact._id) {
            await deleteKeyContact(token, keyContact._id);
            reloadPatientProfile();
          }
        } catch (e) {
          console.error(e);
          notification.error({ message: 'Unable to remove key contact' });
        }
      }
    });
  };

  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();

  const isNZClient = useMemo(
    () => attachedClinicianDetail?.practice?.country === configNZ.countryCode,
    [attachedClinicianDetail]
  );

  useEffect(() => {
    if (!isClientProfileLoading && clientProfileData && !isAttachedClinicianDetailLoading && attachedClinicianDetail) {
      setValues({
        ...clientProfileData,
        name: user?.name,
        ...(isClientTimeZoneEnabled && {
          timeZone:
            clientProfileData.timeZone ||
            (isNZClient
              ? configNZ.defaultTimezone
              : attachedClinicianDetail.clinician?.accountSettings?.timezone || config.defaultTimezone)
        }),
        ...clientProfileData.address
      });
      setInitEmail(clientProfileData?.email);
    }
  }, [
    isClientProfileLoading,
    clientProfileData,
    user?.name,
    isAttachedClinicianDetailLoading,
    attachedClinicianDetail,
    isNZClient
  ]);

  const [submitState, setSubmitState] = useState<HelmBtnStatus>('');
  const [isUsedEmail, setIsUsedEmail] = useState(false);
  const { errors, validate } = useFormError<PersonalDetailFormFields>();

  const handleChangeValues = (key: keyof PersonalDetailFormFields, value: string) => {
    setValues((values) => {
      const newState = { ...values, [key]: value };
      validate(updateProfileValidation, newState, key);
      return newState;
    });
  };

  const handleAddressAutoComplete: OnAddressAutoCompleteHandler = (address) => {
    setValues((values) => {
      const newState = { ...values, ...address };
      Object.keys(address ?? {}).forEach((key) => {
        validate(updateProfileValidation, newState, key as keyof PersonalDetailFormFields);
      });
      return newState;
    });
  };

  const handleWarningBeforeSave = () => {
    Modal.confirm({
      title: 'Changing email will require you to verify and login again. Are you sure you want to change?',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: () => {
        handleOnSubmit();
      }
    });
  };

  const handleOnSubmit = async () => {
    setSubmitState('active');
    try {
      let isEmailUsed = false;
      if (initEmail !== values.email) {
        const checkEmailExisted = values.email
          ? await checkEmailAlreadyExisted({
              accountId: attachedClinicianDetail?.accountId!,
              email: values.email
            })
          : undefined;

        isEmailUsed = checkEmailExisted?.response?.used !== false;
      }
      if (!isEmailUsed) {
        const avatarUrl =
          values.avatar && isBase64PNG(values.avatar)
            ? await (await postPatientAvatar({ avatar: values.avatar })).text()
            : values.avatar;

        const clientPayload = {
          firstName: values.firstName,
          lastName: values.lastName,
          name: values.name,
          dateOfBirth: values.dateOfBirth,
          mobileNumber: values.mobileNumber,
          timeZone: values.timeZone,
          avatar: avatarUrl,
          ...(initEmail !== values.email && {
            email: values.email
          }),
          address: {
            line1: values.line1,
            line2: values.line2,
            country: values.country,
            state: values.state,
            suburb: values.suburb,
            postcode: values.postcode
          }
        };

        await putPatient(token, clientPayload);

        setSubmitState('finished');
        getAccessTokenSilently({ ignoreCache: true });
        if (initEmail !== values.email) {
          navigate(0);
        }
        setInitEmail(values.email);
        reloadPatientProfile();
      } else {
        setIsUsedEmail(true);
        notification.error({ message: 'Email already in use!' });
      }
    } catch (ex) {
      console.error(ex);
      notification.error({ message: 'Something went wrong!', duration: 3000 });
    } finally {
      setTimeout(() => {
        setSubmitState('');
      }, 1000);
    }
  };

  const isBtnDisable = () => {
    if (
      !values.email ||
      !values.firstName ||
      !values.lastName ||
      !values.name ||
      !values.dateOfBirth ||
      submitState !== ''
    ) {
      return true;
    }
    return errors && !!Object.values(errors).find((error) => !!error);
  };

  let avatarSelectorValue: string;
  const onSelectAvatar = () => {
    handleChangeValues('avatar', avatarSelectorValue);
    setShowAvatarModal(false);
  };

  return (
    <div className={classnames(styles.container, classNames)}>
      {isClientProfileLoading || !clientProfileData || isAttachedClinicianDetailLoading || !attachedClinicianDetail ? (
        <div className={styles.loading}>
          <LoadingDot />
        </div>
      ) : (
        <div className={classnames(styles.content, contentClassName)}>
          <div className={styles.title}>
            Your Profile
            {IS_CAW_APP ? (
              <ButtonCaW
                className={styles.saveButton}
                onClick={initEmail !== values.email ? handleWarningBeforeSave : handleOnSubmit}
                disabled={isBtnDisable()}
                status={submitState}
              >
                Save Profile
              </ButtonCaW>
            ) : IS_EASE_APP ? (
              <ButtonEase
                className={styles.saveButton}
                onClick={initEmail !== values.email ? handleWarningBeforeSave : handleOnSubmit}
                disabled={isBtnDisable()}
                status={submitState}
              >
                SAVE PROFILE
              </ButtonEase>
            ) : IS_SOMEONE_HEALTH_APP ? (
              <ButtonSH
                className={styles.saveButton}
                onClick={initEmail !== values.email ? handleWarningBeforeSave : handleOnSubmit}
                disabled={isBtnDisable()}
                status={submitState}
              >
                Save Profile
              </ButtonSH>
            ) : IS_PORTOBELLO_APP ? (
              <ButtonPortobello
                className={styles.saveButton}
                onClick={initEmail !== values.email ? handleWarningBeforeSave : handleOnSubmit}
                disabled={isBtnDisable()}
                status={submitState}
              >
                Save Profile
              </ButtonPortobello>
            ) : (
              <Button
                className={styles.saveButton}
                onClick={initEmail !== values.email ? handleWarningBeforeSave : handleOnSubmit}
                disabled={isBtnDisable()}
                status={submitState}
              >
                Save Profile
              </Button>
            )}
          </div>
          <div className={styles.detail}>
            <Modal
              title={null}
              footer={null}
              className={styles.avatarModal}
              width={800}
              visible={showAvatarModal}
              onCancel={() => setShowAvatarModal(false)}
              wrapClassName={classnames(IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme')}
            >
              <Personalisation
                checkValidation={false}
                title={<div className={styles.avatarTitle}>Choose new avatar</div>}
                description={' '}
                personalisationValue={{ nickname: '', avatar: values.avatar || '' }}
                onChangePersonalisationField={(value) => (avatarSelectorValue = value.avatar)}
                inputContainerClass={styles.avatarNicknameInput}
              />
              <div className={styles.avatarFooter}>
                {IS_PORTOBELLO_APP ? (
                  <ButtonPortobello onClick={onSelectAvatar}>Select</ButtonPortobello>
                ) : (
                  <Button onClick={onSelectAvatar}>Select</Button>
                )}
              </div>
            </Modal>
            <div className={styles.avatar} onClick={() => setShowAvatarModal(true)}>
              <ClientProfileAvatar avatarUrl={values?.avatar || user?.picture} />
            </div>
            <PersonalDetailForm
              values={values}
              errors={errors}
              handleChangeValues={handleChangeValues}
              onAddressAutoComplete={handleAddressAutoComplete}
              isClientTimeZoneEnabled={isTimeZoneEnabled}
              isUsedEmail={isUsedEmail}
              setIsUsedEmail={setIsUsedEmail}
            />
          </div>
          {clientProfileData.isAccountMedicareEnabled && clientProfileData.recordType === ClientRecordType.Adult && (
            <MedicareInformation patientProfile={clientProfileData} />
          )}
          {(IS_EASE_APP || IS_RECHARGE_APP || IS_SELECT_APP || IS_PORTOBELLO_APP || IS_SOMEONE_HEALTH_APP) && (
            <PaymentDetails />
          )}
          <div className={styles.contact}>
            <div className={styles.title}>Your Key Contact</div>
            <div className={styles.description}>
              Add contact information of <span className={styles.highlight}>up to 5</span> of your parents or related
              care team members.
            </div>
            {clientProfileData.keyClientContacts && (
              <div className={styles.contactList}>
                {clientProfileData.keyClientContacts.map((keyClientContact, index) => (
                  <ClientKeyContactDetails
                    key={index}
                    clientKeyContact={keyClientContact}
                    onRemove={() => removeKeyContact(keyClientContact)}
                  />
                ))}
              </div>
            )}
            {(!clientProfileData.keyClientContacts || clientProfileData.keyClientContacts.length < 5) &&
              !showAddContactModal && (
                <div className={styles.addKeyBtnWrapper}>
                  {IS_SOMEONE_HEALTH_APP ? (
                    <ButtonSH
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      Add New Key Contact
                    </ButtonSH>
                  ) : IS_CAW_APP ? (
                    <ButtonCaW
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      Add New Key Contact
                    </ButtonCaW>
                  ) : IS_EASE_APP ? (
                    <ButtonEase
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      ADD / REMOVE KEY CONTACTS
                    </ButtonEase>
                  ) : IS_RECHARGE_APP ? (
                    <ButtonRecharge
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      ADD / REMOVE KEY CONTACTS
                    </ButtonRecharge>
                  ) : IS_SELECT_APP ? (
                    <ButtonSelect
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      ADD / REMOVE KEY CONTACTS
                    </ButtonSelect>
                  ) : IS_PORTOBELLO_APP ? (
                    <ButtonPortobello
                      variant={'outlined'}
                      onClick={() => setShowAddContactModal(true)}
                      icon={'add_circle_outline'}
                    >
                      ADD / REMOVE KEY CONTACTS
                    </ButtonPortobello>
                  ) : (
                    <Button className={styles.addContactBtn} onClick={() => setShowAddContactModal(true)}>
                      <div>
                        <i className="material-icons">add_circle_outline</i>
                        Add New Key Contact
                      </div>
                    </Button>
                  )}
                </div>
              )}
            {showAddContactModal && (
              <AddKeyContactModal onSuccess={reloadPatientProfile} onClose={() => setShowAddContactModal(false)} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateProfileContent;
