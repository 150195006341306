import { useAuth0 } from '@auth0/auth0-react';
import { MouseEvent as ReactMouseEvent } from 'react';

import { FirstAssessment } from 'components/PatientQuestionForm/AssessmentInterfaces';
import Button from 'components/Button/Button';

import styles from './CORDSGateway.module.scss';
import PatientContentLayout from 'components/PatientContentLayoutV2/PatientContentLayout';
import { IS_CORDS_APP } from 'utils/hooks/AccountInfo/clientDetails';

interface CORDSGatewayProps {
  state: 'done' | 'saveAndExit' | 'welcomeBack';
  onReturnToAssessment: () => void;
  assessment?: FirstAssessment;
  practiceName?: string;
  clinicianAvatar?: string;
  clinicianName?: string;
  isAdHocAssessment404: boolean;
  isQueryParamToken?: boolean;
  userInfo?: {
    clientRecordId: string;
    initials: string;
    initialsBackgroundColor: string;
    childFirstName?: string;
  };
}

const CORDSGateway = ({
  state,
  onReturnToAssessment,
  assessment,
  clinicianAvatar,
  clinicianName,
  isAdHocAssessment404,
  isQueryParamToken,
  userInfo
}: CORDSGatewayProps) => {
  const { user } = useAuth0();

  const buttonHandle = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onReturnToAssessment();
  };

  const clinicalAssessmentsTitle =
    (IS_CORDS_APP && assessment?.assessment?.clinicalAssessments?.[0]?.metadata?.cords?.shortTitle) ||
    assessment?.assessment?.clinicalAssessments?.[0]?.title ||
    'Questionnaire';

  return (
    <div className={styles.container}>
      {state === 'welcomeBack' && (
        <PatientContentLayout className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>Hi {isQueryParamToken ? userInfo?.initials : user?.name}.</div>
            <div className={styles.description}>
              {isAdHocAssessment404 ? (
                'This assessment has been cancelled.'
              ) : (
                <>
                  Please fill in the <b>{clinicalAssessmentsTitle}. </b>
                  Request to complete assessment by:
                </>
              )}
            </div>
            {!isAdHocAssessment404 && (
              <>
                {(clinicianAvatar || clinicianName) && (
                  <div className={styles.clinicianInfo}>
                    {clinicianAvatar && (
                      <img className={styles.clinicianAvatar} src={clinicianAvatar} alt="Clinician avatar" />
                    )}
                    {clinicianName && <span className={styles.clinicianName}>{clinicianName}</span>}
                  </div>
                )}
                {userInfo?.childFirstName && <p>For {userInfo.childFirstName}</p>}
                {assessment?.note && <p>{assessment.note}</p>}
                <div className={styles.buttonWrapper}>
                  <Button className={styles.mainButton} variant="primary" onClick={buttonHandle}>
                    Let's Begin <i className="material-icons-outlined">arrow_circle_right</i>
                  </Button>
                </div>
              </>
            )}
          </div>
        </PatientContentLayout>
      )}
      {state === 'done' && (
        <div className={styles.thankYouContent}>
          <svg
            width="280"
            height="280"
            viewBox="0 0 280 280"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.checkIcon}
          >
            <circle opacity="0.1" cx="140" cy="140" r="140" fill="#FFF7E5" />
            <circle opacity="0.2" cx="140" cy="140" r="120" fill="#FFF7E5" />
            <circle opacity="0.3" cx="140" cy="140" r="100" fill="#FFF7E5" />
            <circle opacity="0.5" cx="140" cy="140" r="80" fill="#FFF7E5" />
            <circle opacity="0.8" cx="140" cy="140" r="60" fill="#FFF7E5" />
            <path
              d="M140 90C112.4 90 90 112.4 90 140C90 167.6 112.4 190 140 190C167.6 190 190 167.6 190 140C190 112.4 167.6 90 140 90ZM140 180C117.95 180 100 162.05 100 140C100 117.95 117.95 100 140 100C162.05 100 180 117.95 180 140C180 162.05 162.05 180 140 180ZM162.95 117.9L130 150.85L117.05 137.95L110 145L130 165L170 125L162.95 117.9Z"
              fill="#1B365D"
            />
          </svg>
          <div className={styles.thankYouBigText}>Thank you for your response </div>
          <div className={styles.thankYouText}>
            We have sent your responses to {clinicianName}.<br />
            They will discuss the result with you.
          </div>
        </div>
      )}
    </div>
  );
};

export default CORDSGateway;
